/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-09-06 11:02:28
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/order-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '订单管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/order_manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '储值订单',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/order_manager/store-order',
      filePath: 'plugin/order-manager/view/store-value-order-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '包厢消费单',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/order_manager/box-bill',
      filePath: 'plugin/order-manager/view/box-bill/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '线下预定明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/order_manager/reservation-list',
      filePath: 'plugin/order-manager/view/reservation-list/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/orderManager/reservationList`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '小程序预定明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/order_manager/mini-program-reservation',
      filePath: 'plugin/order-manager/view/miniprogram-subscribe/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/orderManager/miniProgramReservation`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '美大预定明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/order_manager/meida-order-detail',
      filePath: 'plugin/order-manager/view/meidaOrderDetail/Index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/orderManager/meidaOrderDetail`,
      meta: {
        keepAlive: true,
      },
    },
  ],
}

export default linCmsUiRouter
