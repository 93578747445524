<template>
  <el-dialog title="编辑会员" v-model="dialogVisible" width="40%" :before-close="handleClose">
    <el-form :model="updateMemberInfo" ref="formRef" :rules="rules">
      <el-form-item label="会员卡号" label-width="100px" required>
        <el-input disabled v-model="updateMemberInfo.code"></el-input>
      </el-form-item>
      <el-form-item label="会员等级" label-width="100px" required>
        <el-select v-model="updateMemberInfo.levelCode" disabled>
          <el-option v-for="(item, index) in levels" :label="item.label" :value="item.value" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="是否启用" label-width="100px">
        <el-switch
          active-color="#13ce66"
          inactive-color="#ff4949"
          v-model="updateMemberInfo.status"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
      <el-form-item label="姓名" label-width="100px">
        <el-input v-model="updateMemberInfo.userName" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="微信昵称" label-width="100px">
        <el-input disabled v-model="updateMemberInfo.wxUnionid"></el-input>
      </el-form-item>
      <el-form-item label="性别" label-width="100px">
        <el-radio-group v-model="updateMemberInfo.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="生日" label-width="100px" prop="birthday">
        <el-date-picker
          v-model="updateMemberInfo.birthday"
          type="date"
          placeholder="选择生日"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
      <el-form-item label="手机号" label-width="100px">
        <el-input v-model="updateMemberInfo.telephone" disabled></el-input>
      </el-form-item>
      <el-form-item label="累计积分" label-width="100px">
        <el-input-number
          disabled
          v-model="updateMemberInfo.points"
          :precision="0"
          :min="0"
          :controls="false"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="当前积分" label-width="100px">
        <el-input-number
          disabled
          v-model="updateMemberInfo.points"
          :precision="0"
          :min="0"
          :controls="false"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="会员标签" label-width="100px">
        <el-select
          v-model="updateMemberInfo.customerTagsCode"
          multiple
          placeholder="请选择"
          clearable
          style="width: 100%;"
        >
          <el-option v-for="item in customerTagsOptions" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, defineProps, defineEmit, onMounted } from 'vue'
import API from '../../service/index'
import { checkphone } from '@/validator/index.js'

let props = defineProps({
  /**更新会员信息 */
  updateMemberInfo: {
    type: Object,
    default: {},
  },
})
const emit = defineEmit(['handleChangeVisible'])
let dialogVisible = ref(true)

/**
 * 关闭弹出框
 */
const handleClose = () => {
  emit('handleChangeVisible', false)
}

//会员卡等级
const levels = ref([])
/**
 * 查询level数据
 */
const querylevels = async () => {
  let res = await API.queryLevels({
    levelName: '',
    pageSize: 100,
    pageNum: 1,
  })
  if (res.code === '200') {
    levels.value = res.data.records.map(item => ({
      label: item.name,
      value: item.code,
    }))
  } else {
    ElMessage.error(res.message)
  }
}

onMounted(() => {
  querylevels()
})

const formRef = ref(null)

const rules = {
  telephone: [{ required: true, validator: checkphone, trigger: 'change' }],
  birthday: [{ required: true, message: '请选择会员生日', trigger: 'change' }],
}

const handleCommit = async () => {
  await formRef.value.validate()
  let params = {
    ...props.updateMemberInfo,
    customerTagsCode: props.updateMemberInfo.customerTagsCode.join(','),
    customerTagsName: customerTagsOptions.value
      .filter(item => props.updateMemberInfo.customerTagsCode.includes(item.value))
      .map(item => item.label)
      .join(','),
  }
  let res = await API.customerUpdate(params)
  if (res.code === '200') {
    ElMessage.success('修改成功')
    handleClose()
  } else {
    ElMessage.error(res.msg)
  }
}

const customerTagsOptions = ref([
  {
    value: '101',
    label: '外营卡-实名',
  },
  {
    value: '102',
    label: '内营卡-实名',
  },
])
</script>

<style scoped lang="scss">
.dialog-footer {
  display: block;
  text-align: right;
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
</style>
