<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-09-19 12:11:53
 * @FilePath: /saas/src/plugin/bbtotal_manager/component/component.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <div>组件: LinCmsUitest</div>
    <div><img :src="logo" alt="logo" /></div>
  </div>
</template>

<script>
import logo from '../assets/image/logo.png'

export default {
  name: 'LinCmsUiTest',
  components: {},
  data() {
    return {
      logo,
    }
  },
  props: {
    // 组件属性定义, 参考 https://cn.vuejs.org/v2/guide/components-props.html#Prop-验证
    // propName: {
    //  type: Array,
    //   default: () => [],
    // },
  },
  // 如果需要支持 v-model 需要进行如下设置, 参考 https://cn.vuejs.org/v2/guide/components-custom-events.html#自定义组件的-v-model
  // model: {
  //   prop: 'tags',
  //   event: 'change',
  // },
  // 计算属性设置
  computed: {},
  // 数据变更监听
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss"></style>
