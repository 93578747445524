<!--
 * @Author: your name
 * @Date: 2022-04-21 14:28:35
 * @LastEditTime: 2022-05-23 10:19:55
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/component/extendDrawer.vue
-->
<template>
  <div>
    <el-drawer
      v-model="extendDrawer"
      :with-header="false"
      size="70%"
      :direction="direction"
      :before-close="handleClose"
    >
      <div class="extend-detail-info ">
        <div class="info-left">
          <h1>门店分配</h1>
          <div class="info-line"></div>
          <div class="info-imgs">
            <h2 class="info-title">门店选择</h2>
            <MdTree :checkitems="checkdata" @mdChange="SelectMds" ref="mdtree" />
          </div>
        </div>
        <div class="info-right">
          <p class="info-title">门店确认</p>
          <div class="address-choose-content">
            <div v-for="(item, key) in allShopList" :key="key">
              <h2>{{ key }}</h2>
              <el-tag
                v-for="(tag, index) in item"
                :key="tag"
                class="mx-1 tags-right"
                closable
                :disable-transitions="false"
                @close="deleteTag(index, key, tag.code)"
              >
                {{ tag.label }}
              </el-tag>
            </div>
          </div>
          <el-button class="info-cancel" @click="extendDrawer = false">取消</el-button>
          <el-button type="primary" class="info-confirm" @click="confirmExtend">分配</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import MdTree from '../../../component/base/storeTree/index.vue'
import { ElMessage } from 'element-plus'
export default {
  components: { MdTree },
  props: {
    checkdata: {
      type: Array,
      default: [],
    },
    extendDrawer: {
      type: Boolean,
      default: false,
    },
    infoDetail: {
      type: Object,
      default: {},
    },
    getTableData: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      data: [],
      days: '',
      dynamicTags: [],
      currentSkuCode: '',
      extendDrawer: false,
      allShopList: {},
      storeCodeListStr: [],
    }
  },
  watch: {
    checkdata(newval) {
      this.storeCodeListStr = newval
      setTimeout(() => {
        this.$refs.mdtree.setCheckedKeys(this.storeCodeListStr)
        this.$refs.mdtree.handleCheck()
      }, 200)
      this.$nextTick(() => {
        console.log(this.storeCodeListStr)
      })
    },
  },
  mounted() {},
  methods: {
    SelectMds(listValue, listStr) {
      this.allShopList = listValue
      this.storeCodeListStr = listStr
    },
    confirmExtend() {
      let list = []
      for (let key in this.allShopList) {
        this.allShopList[key].map(elItem => {
          list.push(elItem.code)
        })
      }
      this.$emit('confirmassign', list)
    },
    deleteTag(thisIndex, key, code) {
      this.allShopList[key] = this.allShopList[key].filter((item, index) => index !== thisIndex)
      if (this.allShopList[key].length === 0) {
        delete this.allShopList[key]
      }
      this.storeCodeListStr = this.storeCodeListStr.filter(item => item !== code)
      this.$refs.mdtree.setCheckedKeys(this.storeCodeListStr)
    },
  },
}
</script>
<style lang="scss" scoped>
.extend-detail-info {
  display: flex;
  position: relative;

  .info-left,
  .info-right {
    height: 100vh;
    flex: 1;
    box-sizing: border-box;
  }

  .info-right {
    position: relative;

    h1 {
      height: 97px;
      line-height: 97px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }

    .close-icon {
      position: absolute;
      top: 20px;
      right: 20px;
    }

    background-color: #f8faff;
    box-sizing: border-box;
    padding-right: 40px;
    padding-left: 40px;

    .info-title {
      box-sizing: border-box;
      margin-bottom: 24px;
      width: 588px;
      height: 40px;
      line-height: 40px;
      background: #f0f2f5;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding-left: 24px;
      color: #2e2f31;
      -webkit-text-stroke: 1px #979797;
    }

    .info-days {
      padding-left: 20px;
      margin-bottom: 20px;

      span {
        display: inline-block;
        width: 64px;
        margin-right: 18px;
      }
    }

    .address-choose-content {
      margin-left: 20px;
      overflow-y: auto;
      height: calc(100vh - 140px);

      h2 {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6d7278;
        line-height: 25px;
        margin-bottom: 16px;
      }

      .tags-right {
        margin-right: 32px;
        margin-bottom: 16px;
      }
    }
    .info-cancel {
      position: fixed;
      bottom: 24px;
      right: 180px;
      width: 121px;
      height: 40px;
    }
    .info-confirm {
      position: fixed;
      bottom: 24px;
      right: 40px;
      width: 121px;
      height: 40px;
    }
  }

  .info-left {
    width: 588px;
    box-sizing: border-box;
    padding-left: 40px;
    height: 100vh;

    h1 {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      height: 73px;
      line-height: 73px;
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .info-list {
      margin-bottom: 40px;

      li {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
    }

    .info-title {
      box-sizing: border-box;
      margin-bottom: 24px;
      width: 588px;
      height: 40px;
      line-height: 40px;
      background: #f0f2f5;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding-left: 24px;
      color: #2e2f31;
      -webkit-text-stroke: 1px #979797;
    }
  }
}
</style>
