/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-09-01 10:26:02
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '包厢管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/room/',
  route: '/room-manager',
  order: null,
  inNav: true,
  children: [
    // {
    //   title: '包厢价格',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/room-manager/room-price-manager',
    //   filePath: 'plugin/room-manager/view/room-price-manager/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: false, // 不需要缓存
    //   },
    // },
    // {
    //   title: '门店包厢管理',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/room-manager/room-box-manager',
    //   filePath: 'plugin/room-manager/view/room-box-manager/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: false, // 不需要缓存
    //   },
    // },
    // {
    //   title: '门店场次管理',
    //   type: 'view',
    //   name: 'shopSessionManager',
    //   route: '/room-manager/shop-session-manager',
    //   filePath: 'plugin/room-manager/view/shop-session-manager/index.vue',
    //   inNav: true,
    //   meta: {
    //     keepAlive: true, // 不需要缓存
    //   },
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   children: [
    //     {
    //       title: '{:name} 详情',
    //       type: 'view',
    //       name: 'shopSessionManagerInfoDetail',
    //       route: '/room-manager/shop-session-manager/infoDetail',
    //       filePath: 'plugin/room-manager/view/shop-session-manager/infoDetail.vue',
    //       inNav: false,
    //       icon: 'iconfont icon-upload',
    //       permission: null,
    //       meta: {
    //         keepAlive: false, // 不需要缓存
    //       },
    //     },
    //   ],
    // },
    // {
    //   title: '门店预定管理',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/room-manager/shop-order-manager',
    //   filePath: 'plugin/room-manager/view/shop-order-manager/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true, // 不需要缓存
    //   },
    // },
    {
      title: '包厢类型',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/room-manager/roomclass-manager',
      filePath: 'plugin/room-manager/view/room-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
  ],
}

export default linCmsUiRouter
