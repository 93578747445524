<template>
  <el-dialog
    :title="'code' in updateRowMsg ? '修改优惠券' : '新增优惠券'"
    v-model="dialogVisible"
    width="70%"
    :before-close="handleClose"
  >
    <el-form :model="updateRowMsg" ref="formRef" :rules="rules">
      <el-form-item label="优惠券名称" prop="name">
        <el-input placeholder="请输入名称" v-model="updateRowMsg.name" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="使用场景" prop="name">
        <tabBar
          :data="usedScenario"
          @clickTab="clickTestZwjTab"
          :activeTab="updateRowMsg.usedScenario"
          :disabled="'code' in updateRowMsg ? true : false"
        ></tabBar>
      </el-form-item>
      <el-form-item label="优惠方案" prop="scheme">
        <tabBar
          :data="coupouTypes[updateRowMsg.usedScenario]"
          @clickTab="clickCoupouTab"
          :activeTab="updateRowMsg.type"
        ></tabBar>
        <div class="tab-des">
          <el-input :placeholder="coupouDes" v-model="updateRowMsg.scheme" @change="changeScheme">
            <template #prefix>
              {{ coupouprefix }}
            </template>
            <template #suffix>
              {{ coupouSuffix }}
            </template>
          </el-input>
        </div>
      </el-form-item>
      <el-form-item label="使用条件" class="is-required" v-if="updateRowMsg.usedScenario == 2" prop="minimumCharge">
        <div style="display: flex;align-items: center;height: 40px;">
          <el-radio-group v-model="updateRowMsg.usedCondition" style="width: auto;">
            <el-radio v-model="radio" :label="0">不限</el-radio>
            <el-radio v-model="radio" :label="1">限制</el-radio>
          </el-radio-group>
          <div v-if="updateRowMsg.usedCondition == 1" style="display: flex;margin-left: 20px;">
            <p style="width: 150px;">预定单金额满</p>
            <el-input v-model="updateRowMsg.minimumCharge" placeholder="1～9999">
              <template #suffix>
                元
              </template>
            </el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="指定门店可用">
        <el-button
          :disabled="!authMenus.designatedStore"
          :type="updateRowMsg.isFixedStore == 1 ? 'primary' : ''"
          @click="updateRowMsg.isFixedStore = 1"
          >指定门店</el-button
        >
        <el-button
          :disabled="!authMenus.allStores"
          :type="updateRowMsg.isFixedStore != 1 ? 'primary' : ''"
          @click="updateRowMsg.isFixedStore = 0"
          style="margin-left: 0px;"
          >全部门店</el-button
        >
      </el-form-item>
      <el-form-item
        label="适用门店"
        label-width="120px"
        prop="attemptStore"
        v-if="authMenus.designatedStore && updateRowMsg.isFixedStore === 1"
      >
        <FilterShop :storeData="updateRowMsg.attemptStore" @changeStore="changeStore" />
      </el-form-item>
      <el-form-item label="使用范围" required>
        <tabBar :data="useRange" :activeTab="updateRowMsg.usedRange" @clickTab="clickUseRange"></tabBar>
      </el-form-item>
      <el-form-item label="适用包厢类型" required v-if="showUseBoxtypes" prop="roomTypeCode">
        <el-select v-model="updateRowMsg.roomTypeCode">
          <el-option v-for="(item, index) in boxTypes" :key="index" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="可选商品" v-if="isHssProduct" required prop="skuInfoList">
        <el-button @click="openProductDialog(2, updateRowMsg.skuInfoList)" style="margin-bottom: 10px"
          >添加商品</el-button
        >
        <div>
          <el-table :data="pageData" empty-text="暂无商品">
            <el-table-column label="商品图片" align="center">
              <template #default="scope">
                <div style="height: 30px;display: flex;justify-content: center;">
                  <img :src="scope.row.pic" style="width: 30px;" />
                </div>
              </template>
            </el-table-column>
            <el-table-column show-overflow-tooltip label="商品名称" prop="name" align="center"></el-table-column>
            <el-table-column prop="spec" label="规格" />
            <el-table-column label="单位" prop="unit" align="center"></el-table-column>
            <el-table-column label="数量" align="center">
              <template v-slot="scope">
                <el-input-number v-model="scope.row.num" size="small" :min="1" :max="99"></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="{row}">
                <el-button @click="deleteEnableSelectProduct(row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-model:current-page="pageParams.pageNum"
            v-model:page-size="pageParams.pageSize"
            :small="small"
            :page-sizes="[5, 10, 20, 30]"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageParams.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
          <span class="toast"> 在可选商品中任意选择1种，享受优惠价</span>
        </div>
      </el-form-item>
      <el-form-item label="有效期" prop="termOfValidity">
        <tabBar :data="validity" @clickTab="clickValidityTab" :activeTab="updateRowMsg.termOfValidity"></tabBar>
        <div v-if="updateRowMsg.termOfValidity === 1" class="tab-des">
          <el-date-picker
            v-model="updateRowMsg.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="YYYY-MM-DD HH:mm:ss"
            :size="size"
            :disabled-date="disabledDate"
            :default-time="defaultTime"
          >
          </el-date-picker>
        </div>
        <div v-if="updateRowMsg.termOfValidity === 2" class="tab-des">
          <div style="display: flex;">
            <label>领取后</label>
            <el-input placeholder="请输入整数，例如：10" v-model="updateRowMsg.received" :maxlength="4">
              <template #suffix>
                天
              </template>
            </el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item :label="updateRowMsg.usedScenario == 2 ? '可预定星期' : '使用星期'" required>
        <tabBar :data="weeks" @clickTab="clickWeekTab" :multiple="true" :activeTab="updateRowMsg.dayOfWeek"></tabBar>
      </el-form-item>
      <el-form-item required :label="updateRowMsg.usedScenario == 2 ? '可预定时段' : '使用时段'">
        <p style="color: red;">注：全天指当天8:00~次日8:00</p>
        <div v-for="item in updateRowMsg.dayOfWeekDetailsList" :key="item">
          {{ weeksMap[item.dayOfWeek] }}
          <div style="display: inline-block; width: 200px;margin-left: 30px;">
            <el-radio-group v-model="item.isAllDay">
              <el-radio v-model="radio" :label="1">全天</el-radio>
              <el-radio v-model="radio" :label="0">限时</el-radio>
            </el-radio-group>
          </div>
          <div v-if="item.isAllDay == 0">
            <div
              v-for="(time, index) in days"
              @click="clickDay(item, index, time)"
              :class="{ activeTime: index >= item.startTimeIndex && index <= item.endTimeIndex }"
              class="day-times"
            >
              {{ time }}
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item required :label="updateRowMsg.usedScenario == 2 ? '节假日不可预定' : '节假日不可用'">
        <el-switch v-model="updateRowMsg.isHolidaysExcepted"></el-switch>
      </el-form-item>
      <el-form-item label="使用场景" prop="usedChannel">
        <el-select v-model="updateRowMsg.usedChannel" placeholder="请选择" clearable>
          <el-option label="通用" :value="1" />
          <el-option label="仅券包" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="最低消费" required>
        <p>不限</p>
      </el-form-item>
      <el-form-item label="发放数量" required>
        <p>不限</p>
      </el-form-item>
      <el-form-item label="领取规则" required>
        <el-radio-group v-model="updateRowMsg.limitType">
          <el-radio :label="1">不限</el-radio>
          <el-radio :label="6"
            >每人限领
            <el-input-number
              v-if="updateRowMsg.limitType == 6"
              :precision="0"
              :min="0"
              :max="9999"
              :controls="false"
              placeholder="请输入整数"
              v-model="updateRowMsg.limitValue"
              style="width: 100px;margin-left: 10px;"
            ></el-input-number>
          </el-radio>
          <el-radio :label="3">
            每人每周限领
            <el-input-number
              v-if="updateRowMsg.limitType == 3"
              :precision="0"
              :min="0"
              :max="9999"
              :controls="false"
              placeholder="请输入整数"
              v-model="updateRowMsg.limitValue1"
              style="width: 100px;margin-left: 10px;"
            ></el-input-number
          ></el-radio>
        </el-radio-group>
        <span style="font-size: 12px;color: #ccc"
          >仅限制小程序的自助、广告、活动领券，不含会员充值、升级赠券、券包售卖</span
        >
      </el-form-item>

      <el-form-item label="用户自助领券">
        <el-switch v-model="updateRowMsg.userSelfCoupon" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item label="优惠券说明">
        <el-input
          placeholder="请输入优惠券使用说明"
          v-model="updateRowMsg.remarks"
          type="textarea"
          maxlength="200"
          show-word-limit
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <SelectShops
    v-if="shopDialogVisible"
    :dialogVisible="dialogVisible"
    :toastMsg="toastMsg"
    @update-dialogVisible="rectiveProducts"
    :hasPros="hasPros"
  ></SelectShops>
</template>

<script setup>
import FilterShop from '../../component/filter-shop.vue'
import SelectShops from '../../../merchant-manager/view/group-list/SelectShops.vue'
import tabBar from '../../component/tab-bar.vue'
import { ref, defineEmit, defineProps, reactive, watchEffect, onMounted, nextTick } from 'vue'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'

const defaultTime = [new Date(2000, 1, 1, 8, 0, 0)] // '12:00:00'

/**
 * 改变门店
 * @param {*} data
 */
const changeStore = ({ isAll, data = [], isOneClick }) => {
  if (isOneClick) {
    props.updateRowMsg.attemptStore = data
    if (data.length > 0) {
      nextTick(() => {
        formRef.value.clearValidate('attemptStore')
      })
    }

    return
  }
  if (isAll) {
    data.forEach(item => {
      let index = props.updateRowMsg.attemptStore.findIndex(i => i === item)
      if (index === -1) {
        props.updateRowMsg.attemptStore.push(item)
      }
    })
  } else {
    data.forEach(item => {
      let index = props.updateRowMsg.attemptStore.findIndex(i => i === item)
      props.updateRowMsg.attemptStore.splice(index, 1)
    })
  }
  nextTick(() => {
    formRef.value.clearValidate('attemptStore')
  })
}

const boxTypes = ref([])
//获取所有包厢类型
const queryboxTypes = () => {
  API.getParentStoreRoomTypeList().then(res => {
    if (res.code === '200') {
      res.data.unshift({
        name: '所有包厢类型',
        code: -1,
      })
      boxTypes.value = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}
onMounted(() => {
  queryboxTypes()
})
//分页参数
const pageParams = reactive({
  pageNum: 1,
  pageSize: 5,
  total: 0,
})
//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
}
const handleCurrentChange = val => {
  pageParams.pageNum = val
}
const pageData = ref([]) //分页数据
watchEffect(() => {
  let start = pageParams.pageSize * (pageParams.pageNum - 1)
  let end = start + pageParams.pageSize
  pageData.value = props.updateRowMsg.skuInfoList.slice(start, end)
  pageParams.total = props.updateRowMsg.skuInfoList.length
})
//日期选择器设置
const disabledDate = time => {
  return time.getTime() < Date.now()
}
/**
 * 父容器传过来的参数
 */
const props = defineProps({
  updateRowMsg: {
    type: Object,
    default: {},
  },
  authMenus: {
    type: Object,
    default: {},
  },
})

/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['handleCloseDialog'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

/** 使用场景 */
const usedScenario = [
  {
    code: 1,
    name: '收银结账',
  },
  {
    code: 2,
    name: '小程序预定',
  },
]

/**优惠方案 */
const coupouTypes = {
  1: [
    {
      code: 3,
      name: '抵时长',
      des: '请输入整数，例如：10',
      suffix: '分钟',
      prefix: '',
      useRange: [
        {
          code: 1,
          name: '仅房费',
        },
      ],
      hasUseBoxType: false,
    },
    {
      code: 4,
      name: '优惠价',
      des: '请输入整数，例如：10',
      suffix: '元',
      prefix: '',
      useRange: [
        {
          code: 2,
          name: '仅商品',
        },
      ],
      hasUseBoxType: false,
    },
    {
      code: 1,
      name: '折扣',
      des: '例如填写9表示打9折',
      suffix: '折',
      prefix: '',
      useRange: [
        {
          code: 3,
          name: '房费和商品',
        },
        {
          code: 1,
          name: '仅房费',
        },
        {
          code: 2,
          name: '仅商品',
        },
      ],
    },
  ],
  2: [
    {
      code: 1,
      name: '折扣',
      des: '0.1～9.9',
      suffix: '折',
      useRange: [
        {
          code: 3,
          name: '仅小程序预定',
        },
      ],
      hasUseBoxType: false,
    },
    {
      code: 2,
      name: '优惠金额',
      des: '1～9999',
      suffix: '元',
      prefix: '减',
      useRange: [
        {
          code: 3,
          name: '仅小程序预定',
        },
      ],
      hasUseBoxType: false,
    },
  ],
}
const coupouDes = ref() // 优惠方案输入框描述
const coupouSuffix = ref() // 优惠方案输入框后缀
const coupouprefix = ref() // 优惠方案输入框前缀
const useRange = ref() //使用范围
const isHssProduct = ref(false) // 是否有商品

/**
 * 是否展示商品
 */
const isShowProduct = code => {
  if (props.updateRowMsg.usedScenario == 1 && (code === 3 || code === 2)) {
    isHssProduct.value = true
  } else {
    isHssProduct.value = false
  }
}
const showUseBoxtypes = ref(false)
// 是否展示包厢类型选择
const isShowBoxTypes = current => {
  showUseBoxtypes.value = current.hasUseBoxType
}

watchEffect(() => {
  let current = coupouTypes[props.updateRowMsg.usedScenario]?.find(item => item.code === props.updateRowMsg.type)
  if (!current) return
  if (props.updateRowMsg.usedScenario == 2) {
    showUseBoxtypes.value = false
    isHssProduct.value = false
  }

  useRange.value = current.useRange
  coupouDes.value = current.des
  coupouSuffix.value = current.suffix
  coupouprefix.value = current.prefix
  isShowProduct(props.updateRowMsg.usedRange)
  isShowBoxTypes(current)
})

/**
 * 点击优惠方案tab
 * @param {object} activeTab
 */
const clickCoupouTab = ({ code, name, des, suffix, prefix, useRange: uR }) => {
  coupouDes.value = des
  coupouSuffix.value = suffix
  coupouprefix.value = prefix
  useRange.value = uR
  props.updateRowMsg.type = code
  props.updateRowMsg.usedRange = uR[0].code
  props.updateRowMsg.scheme = ''
  isShowProduct(uR[0].code)
  isShowBoxTypes(code)
}

/**
 * 点击使用场景
 * @param {object} activeTab
 */
const clickTestZwjTab = data => {
  props.updateRowMsg.usedScenario = data.code
  props.updateRowMsg.type = 3
  props.updateRowMsg.usedCondition = 0
  let current = coupouTypes[props.updateRowMsg.usedScenario][0]

  clickCoupouTab(current)
}

/**
 * 点击使用范围
 * @param {*} param0
 */
const clickUseRange = ({ code }) => {
  props.updateRowMsg.usedRange = code
  isShowProduct(code)
}

/**有效期 */
const validity = [
  {
    code: 1,
    name: '固定有效期',
    type: 'time',
  },
  {
    code: 2,
    name: '相对有效期',
    type: 'input',
  },
  {
    code: 3,
    name: '领取后当月有效',
    type: 'none',
  },
]
/**
 * 点击有效期tab栏
 * @param {object} param0
 */
const clickValidityTab = ({ code }) => {
  if (code === 1) {
    props.updateRowMsg.time = ['', '']
  } else {
    props.updateRowMsg.received = ''
  }
  props.updateRowMsg.termOfValidity = code
  formRef.value.clearValidate('termOfValidity')
}

const days = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4, 5, 6, 7, 8]

const clickDay = (data, daysIndex, time) => {
  if (!data.startTime || data.startTimeIndex != data.endTimeIndex) {
    data.startTime = time
    data.startTimeIndex = daysIndex
    data.endTime = time
    data.endTimeIndex = daysIndex
    return
  }
  if (daysIndex > data.endTimeIndex) {
    data.endTime = time
    data.endTimeIndex = daysIndex
    return
  }
  if (daysIndex < data.startTimeIndex) {
    data.startTime = time
    data.startTimeIndex = daysIndex
    return
  }
}

/**星期 */
const weeks = [
  { name: '一', code: 2 },
  { name: '二', code: 3 },
  { name: '三', code: 4 },
  { name: '四', code: 5 },
  { name: '五', code: 6 },
  { name: '六', code: 7 },
  { name: '日', code: 1 },
]
const weeksMap = {
  1: '星期日',
  2: '星期一',
  3: '星期二',
  4: '星期三',
  5: '星期四',
  6: '星期五',
  7: '星期六',
}
/**
 * 点击星期tab栏
 * @param {object} week
 */
const clickWeekTab = data => {
  props.updateRowMsg.dayOfWeek = data
  if (data.length > props.updateRowMsg.dayOfWeekDetailsList.length) {
    let newtab
    data.forEach(i => {
      let a = props.updateRowMsg.dayOfWeekDetailsList.findIndex(b => b.dayOfWeek == i)
      if (a == -1) {
        newtab = i
        return
      }
    })
    props.updateRowMsg.dayOfWeekDetailsList.push({
      dayOfWeek: newtab,
      isAllDay: 1,
      position: newtab == 1 ? 8 : newtab,
    })
    props.updateRowMsg.dayOfWeekDetailsList = props.updateRowMsg.dayOfWeekDetailsList.sort(
      (a, b) => a.position - b.position,
    )
  }
  if (data.length < props.updateRowMsg.dayOfWeekDetailsList.length) {
    let newtab
    props.updateRowMsg.dayOfWeekDetailsList.forEach(i => {
      let a = data.findIndex(b => b == i.dayOfWeek)
      if (a == -1) {
        newtab = i.dayOfWeek
        return
      }
    })
    let deleteIndex = props.updateRowMsg.dayOfWeekDetailsList.findIndex(a => a.dayOfWeek == newtab)
    props.updateRowMsg.dayOfWeekDetailsList.splice(deleteIndex, 1)
  }
}

// 例点商品
const mustSelectPruducts = ref([])

/**
 * 删除例点商品
 * @param {object} product
 */
const deleteMustSelectProduct = ({ code }) => {
  let deleteIndex = mustSelectPruducts.value.findIndex(p => p.code === code)
  mustSelectPruducts.value.splice(deleteIndex, 1)
}

// 可选商品
const enableSelectPruducts = ref([])

/**
 * 删除可选商品
 * @param {object} product
 */
const deleteEnableSelectProduct = ({ code }) => {
  ElMessageBox.confirm('确认删除当前商品吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let deleteIndex = props.updateRowMsg.skuInfoList.findIndex(p => p.code === code)
    props.updateRowMsg.skuInfoList.splice(deleteIndex, 1)
  })
}

// 选择商品
const shopDialogVisible = ref(false) //商品选择组件展示
const toastMsg = ref('') // toast提示
const currentSelectProductSource = ref('') //当前选择商品的源 可选or例点

/**
 * 是否展示选择商品dialog
 */
const isShowProductDialog = val => {
  shopDialogVisible.value = val
}

const hasPros = ref([])
/**
 * 打开选择商品的dialog
 * @param {string} type 类型 1:必须商品 2:可选商品
 */
const openProductDialog = (type, hasPro) => {
  hasPros.value = hasPro || []

  currentSelectProductSource.value = type
  switch (type) {
    case 1:
      toastMsg.value = '商品已入例点商品清单'
      break
    case 2:
      toastMsg.value = '商品已入可选商品清单'
      break
    default:
      break
  }
  isShowProductDialog(true)
}

/**
 * 接受新数据
 * @param {object} item 选择的商品
 */
const rectiveProducts = ({ selectSkus }) => {
  isShowProductDialog(false)
  if (currentSelectProductSource.value === 1) {
    injectNewProduct(mustSelectPruducts.value, selectSkus)
  } else {
    injectNewProduct(props.updateRowMsg.skuInfoList, selectSkus)
  }
}

/**
 * 注入新数据
 * @param {array} oldProducts  老数据
 * @param {array} newProducts  新数据
 */
const injectNewProduct = (oldProducts, newProducts) => {
  newProducts.forEach(n => {
    let old = oldProducts.find(o => o.code === n.code)
    if (old) {
    } else {
      oldProducts.push({
        ...n,
        num: n.count,
      })
    }
  })
  // oldProducts = newProducts
}

/**
 * 监控scheme改变
 */
const changeScheme = scheme => {
  let zeroReg = scheme.match(/(0+)?(0\.|\d)/)
  let zeroLength
  if (Array.isArray(zeroReg)) {
    zeroLength = zeroReg[1]?.length || 0
  } else {
    zeroLength = 0
  }
  props.updateRowMsg.scheme = scheme.slice(zeroLength)
}

/**
 * 校验scheme
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
var validateScheme = (rule, value, callback) => {
  console.log(props.updateRowMsg.type)
  console.log(value)

  if (value === '') {
    return callback(new Error('请输入优惠方案值'))
  } else {
    if (props.updateRowMsg.type === 3 && !/^\d+$/.test(value)) {
      return callback(new Error('请输入正整数'))
    }
    if (props.updateRowMsg.type === 3 && value > 9999) {
      return callback(new Error('请输入小于等于9999的整数'))
    }
    if (props.updateRowMsg.type === 4 && !/^\d+$/.test(value)) {
      return callback(new Error('请输入整数'))
    }
    if (props.updateRowMsg.type === 4 && value > 999999) {
      return callback(new Error('请输入小于等于999999的整数'))
    }
    if (props.updateRowMsg.type === 2) {
      if (!/^\d+$/.test(value)) {
        return callback(new Error('请输入正整数'))
      }
      if (value > 9999 || value < 1) return callback(new Error('请输入大于0小于等于9999的整数'))
    }
    if (props.updateRowMsg.type === 1 && !/^\d+(\.\d)?$/.test(value)) {
      return callback(new Error('请输入数字，小数点保留一位'))
    }
    if (props.updateRowMsg.type === 1) {
      if (value >= 10 || value <= 0) return callback(new Error('请输入大于0小于10的数'))
    }
    callback()
  }
}

const validateTermOfValidity = (rule, value, callback) => {
  const notHasTime = !props.updateRowMsg.time || props.updateRowMsg.time[0] === '' || props.updateRowMsg.time[1] === ''
  const notHasReceived = !props.updateRowMsg.received || !/^\d+$/.test(props.updateRowMsg.received)
  if (props.updateRowMsg.termOfValidity === 1 && notHasTime) {
    callback(new Error('请输入时间'))
  }
  if (props.updateRowMsg.termOfValidity === 2 && notHasReceived) {
    callback(new Error('请输入整数'))
  }
  callback()
}

const validateSkuInfoList = (rule, value, callback) => {
  if (!props.updateRowMsg.skuInfoList || props.updateRowMsg.skuInfoList.length === 0) {
    return callback(new Error('请选择可选商品'))
  }
  callback()
}

const validateFixedStores = (rule, value, callback) => {
  if (props.updateRowMsg.isFixedStore === 1 && props.updateRowMsg.attemptStore.length === 0) {
    return callback(new Error('请至少选择一家门店'))
  }
  callback()
}
const validateMinimumCharge = (rule, value, callback) => {
  if (props.updateRowMsg.usedCondition === 1 && !/^\d+$/.test(props.updateRowMsg.minimumCharge)) {
    return callback(new Error('请输入正整数'))
  }
  if (props.updateRowMsg.usedCondition === 1 && props.updateRowMsg.minimumCharge < 1) {
    return callback(new Error('请输入大于0的整数'))
  }
  if (props.updateRowMsg.usedCondition === 1 && props.updateRowMsg.minimumCharge > 9999) {
    return callback(new Error('请输入小于等于9999的整数'))
  }
  callback()
}

//规则
const formRef = ref(null)
const rules = {
  name: [{ required: true, message: '请输入优惠券名称', trigger: 'change' }],
  roomTypeCode: [{ required: true, message: '请选择包厢类型', trigger: 'change' }],
  scheme: [{ required: true, validator: validateScheme, trigger: 'change' }],
  skuInfoList: [{ required: true, validator: validateSkuInfoList, trigger: 'change' }],
  termOfValidity: [{ required: true, validator: validateTermOfValidity, trigger: 'change' }],
  attemptStore: [{ validator: validateFixedStores, trigger: 'change' }],
  usedChannel: [{ required: true, message: '请选择', trigger: 'change' }],
  minimumCharge: [{ required: true, validator: validateMinimumCharge, trigger: 'change' }],
}
const checkTimeWeek = params => {
  for (let i = 0; i < params.dayOfWeekDetailsList.length; i++) {
    let a = params.dayOfWeekDetailsList[i]
    if (a.isAllDay == 1) {
      a.startTime = '8:00'
      a.endTime = '8:00'
      a.isNextDay = 1
    } else {
      if (a.startTimeIndex == a.endTimeIndex) {
        ElMessage.error(`请选择${weeksMap[a.dayOfWeek]}的时间段`)
        return
      }
      a.startTime += ':00'
      a.endTime += ':00'
      if (a.endTimeIndex >= 17) {
        a.isNextDay = 1
      } else {
        a.isNextDay = 0
      }
    }
    delete params.startTimeIndex
    delete params.endTimeIndex
  }

  return params
}

/**
 * 提交
 */
const handleCommit = async () => {
  try {
    if (!props.authMenus.designatedStore && !props.authMenus.allStores) {
      ElMessage.warning('暂无指定门店操作权限')
      return
    }
    if (!props.authMenus.designatedStore && props.updateRowMsg.isFixedStore === 1) {
      return ElMessage.warning('指定门店请选择全部门店')
    }
    await formRef.value.validate()

    ElMessageBox.confirm(
      `
    <p>修改以下信息后，已发放的该优惠券会被同步修改，请谨慎操作</p>
    <p>-优惠券名称</p>
    <p>-优惠价商品及数量</p>
    <p>-使用星期、使用时段</p>
    <p>-抵时长</p>
    <p>-优惠券说明</p>
    `,
      '操作确认',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
      },
    ).then(async () => {
      let params = Object.assign({}, props.updateRowMsg)

      if (!(params = checkTimeWeek(params))) {
        return
      }
      params = {
        ...params,
        received: parseInt(params.received),
        isHolidaysExcepted: params.isHolidaysExcepted ? 1 : 0,
        dayOfWeek: JSON.stringify(params.dayOfWeek),
        validityStart: params.time[0],
        validityEnd: params.time[1],
        skuCodeList: JSON.stringify(params.skuInfoList.map(item => ({ skuCode: item.code, num: item.num }))),
        attemptStore: params.isFixedStore === 1 ? JSON.stringify(params.attemptStore) : -1,
        limitValue: params.limitType == 3 ? params.limitValue1 : params.limitType == 1 ? -1 : params.limitValue,
        minimumCharge: params.usedCondition == 0 ? -1 : params.minimumCharge,
      }
      delete params.time
      delete params.skuInfoList

      let res
      if ('code' in params) {
        res = await API.updateCouponRule(params)
      } else {
        res = await API.createCouponRule(params)
      }

      if (res.code === '200') {
        ElMessage.success('操作成功')
        handleClose()
      } else {
        ElMessage.error(res.msg)
      }
    })
  } catch (error) {
    ElMessage.warning('请完成必要信息填写')
  }
}
</script>

<style scoped lang="scss">
.day-times {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  &:last-child {
    border-right: 1px solid #ccc;
  }
}
.activeTime {
  background-color: #409eff;
  color: white;
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 130px !important;
  }
}
// tab栏下方的控件
.tab-des {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  ::v-deep(.el-date-editor) {
    width: 100%;
  }
  ::deep(.el-input__suffix) {
    width: 40px !important;
  }
  ::v-deep(.el-input__inner) {
    text-align: right;
    padding-right: 40px !important;
  }
  ::v-deep(.el-input-number) {
    width: 100%;
  }
  label {
    width: 70px;
    margin-right: 5px;
  }
  .toast {
    color: #ccc;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
  ::v-deep(.el-input__inner) {
    text-align: auto;
    padding-right: auto !important;
  }
}

// 底部按钮区
.dialog-footer {
  .el-button {
    width: 100px;
  }
}
</style>
