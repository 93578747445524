<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-tabs v-model="data.type" class="demo-tabs" @tab-click="searchLists">
      <el-tab-pane label="商品" :name="1"> </el-tab-pane>
      <el-tab-pane label="易耗品" :name="4"> </el-tab-pane>
    </el-tabs>
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;">
      <el-form-item label="商品名称">
        <el-input
          placeholder="请输商品名称"
          v-model="data.productName"
          clearable
          class="input-search-tel"
          @keyup.enter="searchLists"
        ></el-input>
      </el-form-item>
      <el-form-item label="门店编号">
        <el-input
          placeholder="请输门店编号"
          v-model="data.storeCode"
          clearable
          class="input-search-tel"
          @keyup.enter="searchLists"
        ></el-input>
      </el-form-item>
      <el-form-item label="门店名称">
        <el-input
          placeholder="请输门店名称"
          v-model="data.storeName"
          clearable
          class="input-search-tel"
          @keyup.enter="searchLists"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="searchLists">
          查询
        </el-button>
        <el-button @click="resetData">
          重置
        </el-button>
        <el-button @click="exportData" v-if="data.authMenus.export">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="data.tableData"
      style="width: 96%; margin-left: 2%;margin-top: 10px;"
      class="table-content"
      v-loading.fullscreen.lock="data.loading"
      :empty-text="!data.storeCode && !data.storeName ? '请输入门店编号或名称后查询数据' : '暂无数据'"
      element-loading-text="正在火速加载数据..."
    >
      <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
      <el-table-column label="商品图片" prop="pic" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;">
            <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
          </div>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="商品名称" prop="productName" align="center" />
      <el-table-column show-overflow-tooltip label="商品分类" prop="categoryName" align="center" />
      <el-table-column show-overflow-tooltip label="商品规格" prop="spec" align="center" />
      <el-table-column show-overflow-tooltip label="成本(元)" prop="averageFree" align="center" />
      <el-table-column show-overflow-tooltip label="库存数量" prop="stock" align="center" />
      <el-table-column show-overflow-tooltip label="最小单位" prop="unit" align="center" />
      <el-table-column show-overflow-tooltip label="门店编号" prop="storeCode" align="center">
        <template #default="scope">
          <text>{{ scope.row.storeCode || '-' }}</text>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="门店名称" prop="storeName" align="center">
        <template #default="scope">
          <text>{{ scope.row.storeName || '-' }}</text>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template #default="scope" fixed="right">
          <el-button type="text" @click="showDetail(scope.row)" v-if="data.authMenus.query">库存明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <InventoryMsg
    :params="data.params"
    v-if="data.dialogVisible"
    @update-dialogVisible="updateDialogVisible"
    :dialogVisible="data.dialogVisible"
  ></InventoryMsg>
</template>

<script setup>
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import InventoryMsg from './inventory-msg/Index.vue'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import { post } from '@/lin/plugin/axios'
import { createMoveBall } from '@/utils/createMoveBall.js'

const data = reactive({
  productName: undefined,
  storeCode: undefined,
  storeName: undefined,
  type: 1,

  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  dialogVisible: false,
  unitConfig: {},
  fullscreenLoading: false,
  params: null,
})
const activeName = ref('商品')

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  // queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const showDetail = item => {
  data.params = {
    productCode: item.productCode,
    storeCode: item.storeCode,
  }
  data.dialogVisible = true
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

const resetData = () => {
  data.storeCode = undefined
  data.productName = undefined
  data.storeName = undefined
  searchLists()
}

const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  if (!data.storeName && !data.storeCode) {
    ElMessage.warning('请输入门店编号或名称后查询数据')
    data.tableData = []
    data.total = 0
    return
  }
  data.fullscreenLoading = true
  let params = {
    storeCode: data.storeCode,
    productName: data.productName,
    storeName: data.storeName,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    type: data.type,
  }
  params = generateParam(params)
  //占坑
  getTableData(params, 'COMMODITY_INVENTORY_DETAILS')
}

/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}

// 获取报表数据
const getTableData = async (params, type) => {
  data.fullscreenLoading = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    data.tableData = res.data.items
    data.total = res.data.total
  } else {
    data.tableData = []
    data.total = 0
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryLists()
  }
}

const exportData = async event => {
  if (!data.storeName && !data.storeCode) {
    ElMessage.warning('请输入门店编号或名称后导出数据')
    return
  }
  let params = {
    storeCode: data.storeCode,
    productName: data.productName,
    storeName: data.storeName,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    type: data.type,
  }
  data.fullscreenLoading = true
  createMoveBall(event)
  const param = generateParam(params)
  let res = await post(`/shadow/export/download?type=COMMODITY_INVENTORY_DETAILS`, param).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
