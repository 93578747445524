<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
      <el-form-item label="营业时间">
        <el-date-picker
          value-format="YYYY-MM-DD HH:mm:ss"
          v-model="data.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="data.defaultTime"
          clearable
        />
      </el-form-item>
      <el-form-item label="门店区域">
        <el-cascader
          v-model="data.regionCodeList"
          :options="areaOptions"
          clearable
          filterable
          @change="changeArea()"
          :show-all-levels="false"
          collapse-tags
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="门店">
        <el-select-v2
          v-model="data.storeCodeList"
          :options="data.storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          style="width: 250px;"
          multiple
          filterable
          collapse-tags
          collapse-tags-tooltip
        />
      </el-form-item>
      <el-form-item label="班次">
        <el-select v-model="data.banci" placeholder="请选择" clearable>
          <el-option lable="早" value="早" />
          <el-option lable="中" value="中" />
          <el-option lable="晚" value="晚" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="searchLists" type="primary">
          查询
        </el-button>
        <el-button @click="resetData">
          重置
        </el-button>
        <el-button @click="exportData">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="data.tableData"
      :span-method="objectSpanMethod"
      border
      style="width: 96%; margin-left: 2%;"
      :emptyText="data.storeCodeList ? '暂无数据' : '暂无数据'"
    >
      <el-table-column width="180">
        <el-table-column show-overflow-tooltip prop="banci" label="班次销售统计" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="date" label="日期" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="week" label="星期" width="120"> </el-table-column>
      </el-table-column>
      <el-table-column label="商品收入">
        <el-table-column show-overflow-tooltip prop="goodsReceiveMoney" label="应收" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="goodsActualMoney" label="实收" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="净收" width="120"> </el-table-column>
      </el-table-column>
      <el-table-column label="包厢收入">
        <el-table-column show-overflow-tooltip prop="roomReceiveMoney" label="应收" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="roomActualMoney" label="实收" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="roomJingMoney" label="净收" width="120"> </el-table-column>
      </el-table-column>
      <el-table-column label="会员充值">
        <el-table-column show-overflow-tooltip prop="custReceiveMoney" label="应收" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="custActualMoney" label="实收" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="custJingMoney" label="净收" width="120"> </el-table-column>
      </el-table-column>
      <el-table-column label="支付方式">
        <el-table-column show-overflow-tooltip prop="weixin" label="微信" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="zhifuPay" label="支付宝" width="120"> </el-table-column>
      </el-table-column>
      <el-table-column>
        <el-table-column show-overflow-tooltip prop="custBenjin" label="会员卡本金支付" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="custZenjin" label="支会卡赠金支付" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="cash" label="现金" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="meituan" label="美团记账" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="douyinjizhang" label="抖音记账" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="leishiCust" label="雷石会员" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="leishiDisCount" label="雷石优惠券" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="newSystemDisCount" label="新系统优惠券" width="120">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="neibuGuazhang" label="内部挂账" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="zhaodaimianshou" label="招待免收" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="managerGroupZhaodai" label="利通取酒" width="120">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="kejiCust" label="可即会员" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="pos" label="POS" width="120"> </el-table-column>
      </el-table-column>
      <el-table-column label="客户来源">
        <el-table-column show-overflow-tooltip prop="meiDa" label="美大" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="douyinSource" label="抖音" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="miniProgram" label="小程序预定" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="phoneReview" label="电话预定" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="weixinReview" label="微信预定" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="dianzhangReview" label="店长端预定" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="otherReview" label="其他来源预定" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="daoDianReview" label="到店" width="120"> </el-table-column>
      </el-table-column>
      <el-table-column>
        <el-table-column show-overflow-tooltip prop="openCount" label="开台批数" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="allReceiveMoney" label="应收合计" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="averageMoney" label="平均单价" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="allActualMoney" label="实收合计" width="120"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="allJingMoney" label="净收合计" width="120"> </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { downLoad } from '../../../../../lin/util/util'
import API from '../../../service/api'
import { ElMessage } from 'element-plus'
import { reactive, onMounted, nextTick, ref } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
}

const props = defineProps({
  auths: {},
})
const data = reactive({
  storeCodeList: [],
  banci: '',
  time: [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ],
  authMenus: {},
  tableData: [],
  fullscreenLoading: false,
  storeList: [],
  regionCodeList: [],
  areaCode: [],
  defaultTime: new Date(2000, 1, 1, 8, 0, 0), // '12:00:00'
})

const route = useRoute()

onMounted(() => {
  route.meta.msg = props.auths
  operationAuth(route, data.authMenus)
  getStores()
  queryLists()
  getAreas()
})

//重置时间
const resetDate = () => {
  data.time = [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ]
}

const getStores = async () => {
  let res = await API.getStoreListByName({ list: data.areaCode })
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.banci = ''
  data.storeCodeList = []
  data.regionCodeList = []
  data.areaCode = []
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
  resetDate()
  searchLists()
}

const exportData = async () => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  let model = {}
  model.startTime = data.time[0]
  model.endTime = data.time[1]
  model.storeCodeList = data.storeCodeList
  model.regionCodeList = data.areaCode

  model.banci = data.banci
  let url = '/report/banci/exportClassReport'
  const res = await API.exportReport({ url, data: model })
  downLoad(res, '班次营业情况.xlsx')
}

const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.fullscreenLoading = true
  let params = {
    banci: data.banci,
    storeCodeList: data.storeCodeList,
    regionCodeList: data.areaCode,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  API.banciReport(params)
    .then(res => {
      if (res.code === '200') {
        data.tableData = Object.freeze(res.data)
        data.total = res.data.length
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.fullscreenLoading = false
    })
}

const objectSpanMethod = ({ row, column, rowIndex, columnIndex }) => {
  let splitRowIndex = data.total / (data.banci ? 1 : 4)
  if (columnIndex === 0) {
    if (rowIndex % splitRowIndex === 0) {
      return {
        rowspan: splitRowIndex,
        colspan: 1,
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
</style>
