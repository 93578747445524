<template>
  <el-dialog
    v-model="visible"
    :title="'code' in accountMsg ? '修改门店类型' : '增加门店类型'"
    width="40%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form :model="accountMsg" label-width="120px">
      <el-form-item label="分类名称" placeholder="请填写类型名称">
        <el-input v-model="accountMsg.categoryName" :disabled="'code' in accountMsg" :maxlength="20" />
      </el-form-item>
      <el-form-item label="分类类型">
        <el-table :data="accountMsg.typesArray">
          <el-table-column label="类型名称" prop="name" align="center">
            <template #default="{row}">
              <el-input v-model="row.name" :maxlength="20"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{row}">
              <el-button @click="deleteRow(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="add-type">
          <el-button @click="addType" type="primary">添加类型</el-button>
        </div>
      </el-form-item>
      <el-form-item>
        <div class="foot-btn">
          <el-button type="primary" @click="onSubmit">保存</el-button>
          <el-button @click="backtrack">返回</el-button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { defineComponent, computed } from 'vue'
import API from '../../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    accountMsg: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })

    /**
     * 删除一行
     * @param {object} row
     */
    const deleteRow = row => {
      ElMessageBox.confirm('确认删除当前配置吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        let res = await API.selectByTypeName({ typeName: row.name })
        if (res.code === '200') {
          let { typesArray } = props.accountMsg
          let deleteIndex = typesArray.findIndex(a => a.name === row.name)
          typesArray.splice(deleteIndex, 1)
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    /**
     * 添加类型
     */
    const addType = () => {
      let { typesArray } = props.accountMsg
      typesArray.push({ name: '' })
    }

    const onSubmit = async () => {
      if (props.accountMsg.typesArray.find(item => !item.name)) {
        ElMessage.warning('类型名称不为空')
        return
      }
      let params = Object.assign({}, props.accountMsg)
      params = {
        ...params,
        typeName: params.typesArray.map(item => item.name).join('、'),
        commonList: params.typesArray,
      }
      delete params.typesArray
      if ('code' in params) {
        let { code, msg } = await API.storeTypeUpdateInfoByCode(params)
        if (code === '200') {
          ElMessage.success('更新成功')
        } else {
          ElMessage.error(msg)
        }
        ctx.emit('update-dialogVisible', false)
      }
    }
    const backtrack = () => {
      ctx.emit('update-dialogVisible', false)
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', false)
    }
    return {
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      deleteRow,
      addType,
    }
  },
})
</script>

<style scoped lang="scss">
.add-type {
  text-align: right;
  margin-top: 10px;
}
.foot-btn {
  text-align: right;
  .el-button {
    width: 100px;
  }
}
</style>
