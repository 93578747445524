<template>
  <el-select
    v-model="value"
    filterable
    remote
    reserve-keyword
    :clearable="true"
    @change="selectmd"
    placeholder="请输入关键词"
    :remote-method="remoteMethod"
    :loading="loading"
  >
    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
  </el-select>
</template>

<script>
import Xjhzzmd from '../../model/xjgzzmd.js'
export default {
  data() {
    return {
      options: [],
      value: '',
      list: [],
      loading: false,
    }
  },
  mounted() {
    this.getAllGeos()
  },
  methods: {
    selectmd(data) {
      const selecitem = this.list.filter(item => item.value === data)
      this.$emit('xchange', selecitem)
      this.value = ''
    },
    async getAllGeos() {
      const res = await Xjhzzmd.getAllLastGeoId()
      if (res.code == 200) {
        this.list = res.result.map(item => {
          return { value: item.id, label: item.name }
        })
        console.log(this.list)
      }
    },
    remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        setTimeout(() => {
          this.loading = false
          this.options = this.list.filter(item => {
            return item.label.indexOf(query) > -1
          })
        }, 200)
      } else {
        this.options = []
      }
    },
  },
}
</script>
