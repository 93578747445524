<template>
  <el-dialog
    v-model="visible"
    :title="'regionCode' in needUpdateInfo ? '修改门店区域' : '新增门店区域'"
    width="58%"
    :before-close="handleClose"
  >
    <el-form :model="needUpdateInfo" ref="formRef" :rules="rules">
      <el-form-item label="区域名称" label-width="120px" prop="regionName">
        <el-input placeholder="请输入" v-model="needUpdateInfo.regionName" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="区域门店" label-width="120px" prop="storeCodeList">
        <span style="color: red;"
          >一个门店只能绑定一个区域，若某门店已被其他区域绑定，则保存后该门店会从原区域移除</span
        >
        <FilterShop :selectStoreData="needUpdateInfo.storeCodeList" @changeStore="changeStore" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveCustomerSourceConfig">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import FilterShop from '../../component/filter-shop.vue'
import API from '../../service/api'
import { ref, defineProps, defineEmit, reactive } from 'vue'
import { ElMessage } from 'element-plus'

const props = defineProps({
  /*需要操作的会员卡信息 */
  needUpdateInfo: {
    type: Object,
    default: {},
  },
})

const emit = defineEmit(['handleCloseDialog'])

const visible = ref(true)

/**
 * 关闭弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

/**
 * 改变门店
 * @param {*} data
 */
const changeStore = data => {
  props.needUpdateInfo.storeCodeList = data
}

const validateFixedStores = (rule, value, callback) => {
  if (props.needUpdateInfo.storeCodeList.length === 0) {
    return callback(new Error('请至少选择一家门店'))
  }
  callback()
}

//校验规则
const formRef = ref(null)
const rules = {
  storeCodeList: [{ validator: validateFixedStores, trigger: 'change' }],
  regionName: [{ required: true, message: '请填写门店名称', trigger: 'change' }],
}
/**
 * 保存客户来源配置
 */
const saveCustomerSourceConfig = async () => {
  await formRef.value.validate()
  let info = generatorSaveData()
  let res
  if ('regionCode' in props.needUpdateInfo) {
    res = await API.updateRegionList(info)
  } else {
    res = await API.saveRegionList(info)
  }
  if (res.code !== '200') {
    ElMessage.error(res.msg)
  } else {
    ElMessage.success('操作成功')
  }
  handleClose()
}

/**
 * 生成数据
 */
const generatorSaveData = () => {
  return {
    ...props.needUpdateInfo,
    storeCodeList: props.needUpdateInfo.storeCodeList.map(item => ({
      storeCode: item,
    })),
  }
}
</script>

<style scoped lang="scss">
.customer-source-jc {
  p {
    margin-top: 5px;
    color: #bdc3c7;
    font-size: 12px;
  }
}
.remark {
  color: #e67e22;
  font-size: 12px;
}
.dialog-footer {
  button {
    width: 100px;
  }
}
</style>
