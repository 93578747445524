<template>
  <div style=";display: flex;flex-direction: column;align-items: center;">
    <div style="width: 500px;margin-top: 20px;">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="会员手机号">
          <el-input v-model="searchForm.telephone" placeholder="请输入完整会员手机号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <P style="text-align: center;">会员({{ searchForm.telephone }})的优惠券：</P>
      <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;margin-top: 20px;">
        <el-radio-button :label="1">未使用</el-radio-button>
        <el-radio-button :label="2">已使用</el-radio-button>
        <el-radio-button :label="3">已过期</el-radio-button>
      </el-radio-group>
      <div
        v-if="pageData.tableData.length > 0"
        style="max-height: 500px; overflow-y: auto;display: flex; flex-direction: column; align-items: center;margin-bottom: 20px;"
      >
        <div
          style="display: flex;width: 520px;margin-bottom: 10px;padding: 0px 10px;justify-content: center;align-items: center;"
          v-for="(item, index) in pageData.tableData"
          :key="index"
        >
          <div style="border: 1px solid #ccc; border-radius: 10px; width: 400px; height: 180px;">
            <div style="position: relative;padding: 20px;border-bottom: 1px dashed #ccc;">
              <div style="display: flex; justify-content: space-between;padding-top: 10px;">
                <div style="height: 50px;display: flex; flex-direction: column;justify-content: space-around;">
                  <p>{{ item.ruleName }}</p>
                  <p>有效期：{{ item.usedTimeBegin.split(' ')[0] }} ~ {{ item.usedTimeEnd.split(' ')[0] }}</p>
                </div>
                <div
                  style="height: 50px;display: flex; flex-direction: column;justify-content: space-around;color: red;"
                >
                  <p style="text-align: right;">
                    {{ item.couponRuleScheme }}{{ pageData.couponRuleSchemes[item.couponRuleType] }}
                  </p>
                  <p style="text-align: right;">{{ item.minimumCharge == -1 ? '无门槛' : item.minimumCharge }}</p>
                </div>
              </div>
              <div
                style="position:absolute; top: 0px; left: 0px;padding: 7px;color: white;background-color: #ae3c21;border-bottom-right-radius: 6px;border-top-left-radius: 6px;"
              >
                {{ pageData.couponRuleTypes[item.couponRuleType] }}
              </div>
            </div>
            <div style="padding:10px 20px;font-size: 12px;line-height: 22px;">
              {{ item.info }}
            </div>
          </div>
          <div style="margin-left: 20px;" v-show="tabPosition == 1">
            <el-button size="small" @click="handleCancle(item)">手动核销</el-button>
          </div>
        </div>
      </div>
      <div v-else style="text-align: center;margin-bottom: 20px;">
        暂无优惠券
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="searchForm.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchForm.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script setup>
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive, watch } from 'vue'

const searchForm = reactive({
  telephone: '',
  total: 0,
  pageSize: 10,
  pageNum: 0,
})
const tabPosition = ref(1)
watch(tabPosition, () => {
  queryListByPage()
})

const pageData = reactive({
  tableData: [],
  couponRuleTypes: {
    1: '折扣券',
    2: '金额券',
    3: '时长券',
    4: '优惠券',
  },
  couponRuleSchemes: {
    1: '%',
    2: '元',
    3: 'min',
    4: '元',
  },
  weeks: {
    2: '一',
    3: '二',
    4: '三',
    5: '四',
    6: '五',
    7: '六',
    1: '日',
  },
})

const handleCancle = async item => {
  ElMessageBox.confirm('确认手动核销该优惠券?', '确认操作', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.useCustomerCoupon({ code: item.code })
    if (res.code == 200) {
      ElMessage.success('操作成功')
      onSubmit()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const onSubmit = () => {
  searchForm.pageNum = 0
  searchForm.pageSize = 10
  queryListByPage()
}

const queryListByPage = async () => {
  let res = await API.queryCouponByUserCode({
    pageNum: searchForm.pageNum,
    pageSize: searchForm.pageSize,
    telephone: searchForm.telephone,
    state: tabPosition.value,
  })
  if (res.code == 200) {
    searchForm.total = res?.data?.total || 0
    pageData.tableData =
      res?.data?.records.map(item => ({
        ...item,
        isSpread: false,
        info: `使用范围：仅${item.usedRange == 1 ? '房费' : '商品'}；限${JSON.parse(item.dayOfWeek)
          ?.map(item1 => '星期' + pageData.weeks[item1])
          .join('，')}可用；${
          item.usedTimeRange == -1 ? '全时段可用' : item.usedTimeRange[0] + '-' + item.usedTimeRange[1]
        }；${item.isHolidaysExcepted == 0 ? '节假日可用' : '节假日不可用'}`,
      })) || []
  } else {
    pageData.tableData = []
    ElMessage.error(res.msg)
  }
}

const handleCurrentChange = val => {
  searchForm.pageNum = val
  queryListByPage()
}
const handleSizeChange = val => {
  searchForm.pageSize = val
  queryListByPage()
}
</script>

<style lang="scss" scoped>
.el-radio-group {
  text-align: center;
}
</style>
