<!--
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-05-09 16:06:36
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/component/layout/breadcrumb.vue
-->
<template>
  <div class="nav-title">
    <a class="item" v-for="(item, index) in titleArr" style="cursor: default;" :key="index">
      <p>{{ item }}</p>
    </a>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    titleArr() {
      const breadcrumbList = this.$store.getters.getStageInfo(this.$route.name)
      return breadcrumbList
        .map(item => {
          let title = item.title
          const query = this.$route.query
          if (title.includes('{:name}') && query && query.name) {
            title = title.replace('{:name}', query.name)
          }
          return title
        })
        .filter(item => !!item)
    },
  },
}
</script>

<style lang="scss">
.nav-title {
  display: flex;
  align-items: center;
  font-size: 14px;

  .item {
    i {
      margin-right: 4px;
    }

    display: flex;
    align-items: center;
    padding-right: 18px;
    position: relative;
    color: $right-side-font-color;

    &:after {
      content: '/';
      position: absolute;
      top: 0;
      right: 6px;
    }
  }

  .item:last-child {
    color: $theme;
    padding-right: 0;

    &:after {
      content: '';
    }
  }
}
</style>
