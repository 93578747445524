import _axios, { post, get, _delete } from '@/lin/plugin/axios'

class CsMng {
  //获取门店列表
  async getStoreListByName(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']

    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }
  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }
  //班次营业情况
  async banciReport(data) {
    const res = await post(`/report/banci/queryClassReport`, data)
    return res
  }
  async exportReport({ url, data }) {
    const res = await _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }
}

export default new CsMng()
