<template>
  <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 2% 0  2%;">
    <el-form-item label="套餐名称">
      <el-input placeholder="请输入套餐名称" v-model="data.comboName"></el-input>
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        v-model="data.regionCode"
        :options="areaOptions"
        clearable
        filterable
        @change="changeArea()"
        :show-all-levels="false"
        placeholder="请选择门店区域"
        collapse-tags
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCode"
        :options="data.shopList"
        placeholder="请选择门店"
        class="search-select userSelect"
        style="width: 300px;"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
      />
    </el-form-item>
    <el-form-item label="包含商品">
      <el-select-v2
        v-model="data.product"
        :options="data.articleList"
        placeholder="请选择商品"
        class="search-select userSelect"
        style="width: 300px;"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
      />
    </el-form-item>
    <el-form-item label="下发将来门店">
      <el-select v-model="data.isSales">
        <el-option label="自动开启售卖" :value="1" />
        <el-option label="禁止开启售卖" :value="0" />
      </el-select>
    </el-form-item>
    <el-form-item label="套餐场景">
      <el-select v-model="data.isConsumeFree">
        <el-option label="套餐" :value="0" />
        <el-option label="套免" :value="1" />
      </el-select>
    </el-form-item>
    <el-form-item label="套餐备注">
      <el-input placeholder="商品备注" v-model="data.remarks" class="input-search-tel"></el-input>
    </el-form-item>

    <el-form-item label="售卖状态">
      <el-select v-model="data.isShelves" placeholder="请选择状态">
        <el-option label="上架" :value="1" />
        <el-option label="下架" :value="0" />
        <el-option label="全部" :value="-1" />
      </el-select>
    </el-form-item>
    <el-form-item label="供货状态">
      <el-select v-model="data.isSupply" placeholder="请选择状态">
        <el-option label="正常供货" :value="1" />
        <el-option label="停止供货" :value="0" />
        <el-option label="全部" :value="-1" />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button @click="searchLists"
        ><el-icon><Search /></el-icon>查询</el-button
      >
      <el-button @click="resetParams" v-if="data.authMenus.query">
        <el-icon><Delete /></el-icon>
        重置
      </el-button>
      <el-button @click="addShop" v-if="data.authMenus.query">
        新增
      </el-button>
      <el-button @click="downTemplate" v-if="data.authMenus.downloadTemplate">
        下载模版
      </el-button>
      <el-button style="padding:0px" v-if="data.authMenus.import">
        <el-upload action="#" :on-change="uploadFile" :show-file-list="false" :auto-upload="false">
          <el-button>导入</el-button>
        </el-upload>
      </el-button>

      <el-dropdown style="margin-left:10px" v-if="data.authMenus.batchDelivery">
        <el-button>
          批量操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="handleBatchDelivery" v-if="data.authMenus.batchDelivery"
              >批量下发</el-dropdown-item
            >
            <!-- <el-dropdown-item @click="handleBatchUpdate">批量替换</el-dropdown-item> -->
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="data.loadingText"
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" />
    <el-table-column label="套餐图片">
      <template #default="scope">
        <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="套餐名称" prop="name" align="center" />
    <el-table-column show-overflow-tooltip label="套餐规格" prop="spec" align="center" />
    <el-table-column show-overflow-tooltip label="指导价(元)" prop="price" align="center" />
    <el-table-column show-overflow-tooltip label="最小单位" prop="unit" align="center" />
    <el-table-column show-overflow-tooltip label="单品数量" prop="skuCount" align="center" />
    <el-table-column show-overflow-tooltip label="投放渠道" prop="channel" align="center" />
    <el-table-column show-overflow-tooltip label="下发门店数" prop="storeCount" align="center" />
    <el-table-column show-overflow-tooltip label="更新时间" prop="updateTime" align="center" />
    <el-table-column show-overflow-tooltip label="售卖状态" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.isShelves === 1">上架</el-tag>
        <el-tag type="error" v-else>下架</el-tag>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="供货状态" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.isSupply === 1">正常供货</el-tag>
        <el-tag type="error" v-else>停止供货</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="250" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="typeOperation(1, scope.row)" v-if="data.authMenus.query">详情</el-button>
        <el-button type="text" @click="typeOperation(2, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <el-button
          type="text"
          @click="typeOperation(3, scope.row)"
          v-if="scope.row.isShelves === 1 && data.authMenus.update"
        >
          下架
        </el-button>
        <el-button
          type="text"
          @click="typeOperation(3, scope.row)"
          v-if="scope.row.isShelves !== 1 && data.authMenus.update"
        >
          上架</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <!-- 新增 -->
  <AddCombo
    v-if="data.addProductVisible"
    :dialogVisible="data.addProductVisible"
    :specRowMsg="data.groupRowMsg"
    :units="data.units"
    :isEdit="data.isEdit"
    :channelList="data.channelList"
    @update-dialogVisible="updateAddProductVisible"
  ></AddCombo>
  <!-- 批量替换 -->
  <Batchupdate
    v-if="data.batchUpdateVisible"
    :dialogVisible="data.batchUpdateVisible"
    @update-dialogVisible="batchUpdateVisible"
  ></Batchupdate>
  <!-- 批量下发 -->
  <BatchDelivery
    v-if="data.batchDeliveryVisible"
    :productType="3"
    :propData="data"
    :dialogVisible="data.batchDeliveryVisible"
    @update-dialogVisible="batchDeliveryVisible"
  ></BatchDelivery>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref, reactive, onMounted, computed, nextTick } from 'vue'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/api'
import AddCombo from './AddCombo.vue'
import Batchupdate from './Batchupdate.vue'
import BatchDelivery from '../../component/BatchDelivery.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCode = []
  console.log(data.regionCode)
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

//下载模版
const downTemplate = async () => {
  let a = document.createElement('a')
  a.href =
    'https://oss-file-center.oss-cn-hangzhou.aliyuncs.com/template/%E5%A5%97%E9%A4%90%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
  a.download = '单品列表模版.xlsx'
  a.click()
}

//批量下发
const handleBatchDelivery = () => {
  data.batchDeliveryVisible = true
}

//批量修改
const handleBatchUpdate = () => {
  data.batchUpdateVisible = true
}

//上传文件
const uploadFile = file => {
  API.inputBatchComboFile({ inputFile: file.raw }).then(res => {
    if (res.code === '200') {
      ElMessage({
        message: '上传成功',
        type: 'success',
      })
      searchLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const data = reactive({
  authMenus: {},
  storeCode: [],
  comboName: undefined,
  channel: undefined,
  isConsumeFree: undefined,
  product: undefined,
  isSales: '',
  remarks: '',
  regionCode: [],
  areaCode: [],
  product: [],

  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  addProductVisible: false,
  batchUpdateVisible: false,
  batchDeliveryVisible: false,
  groupRowMsg: {},
  shopList: [],
  articleList: [],
  units: [],
  channelList: [],
  isEdit: true,
  loading: false,
  loadingText: '正在火速加载数据...',
  // productList: [],
})
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  getShops()
  getChannelList()
  selectDictListAll()
  getAreas()
  getArticles()
})
const getArticles = () => {
  API.selectProductAll({ type: 1 }).then(res => {
    if (res.code === '200') {
      data.articleList = res.data.map(item => ({
        value: item.code,
        label: item.name,
      }))
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const getShops = () => {
  API.getStoreList({ list: data.areaCode }).then(res => {
    if (res.code === '200') {
      data.shopList = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//获取单位
const selectDictListAll = () => {
  API.selectDictListAll().then(res => {
    if (res.code === '200') {
      data.units = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const getChannelList = () => {
  API.getChannelList(null).then(res => {
    if (res.code === '200') {
      data.channelList = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const resetParams = () => {
  data.storeCode = []
  data.comboName = undefined
  data.isShelves = -1
  data.isSupply = -1
  data.remarks = undefined
  data.regionCode = []
  data.areaCode = []
  data.isSales = ''
  data.isConsumeFree = ''
  data.product = []
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let queryParams = {
    comboName: data.comboName,
    store: data.storeCode,
    isSales: data.isSales,
    regionCode: data.areaCode,
    remarks: data.remarks,
    isConsumeFree: data.isConsumeFree,
    current: data.currentPage,
    pageSize: data.pageSize,
    product: data.product,
  }
  if (data.isShelves !== -1) {
    queryParams.isShelves = data.isShelves
  }
  if (data.isSupply !== -1) {
    queryParams.isSupply = data.isSupply
  }
  API.selectComboPage(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))

  // API.selectComboPageCodes(queryParams).then(res => {
  //   data.productList = res.data
  // })
}

const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}

//添加
const addShop = () => {
  data.groupRowMsg = {
    storeCodeList: [],
    skuArrayContentResp: [],
    price: '',
    price1: 1,
    price2: 2,
    shops: _.cloneDeep(data.shopList),
    isStock: true,
    isRecommend: false,
    isMinConsume: false,
    isConsumeFree: 0,
    isSales: 1,
    isShelves: 1,
    isSupply: 1,
    channel: data.channelList.map(item => item.name),
    requiredContents: [],
    optionContents: [],
    comboType: 1,
    couponPackageVOList: [],
  }

  data.isEdit = true
  data.addProductVisible = true
}
const deleteProRow = rowMsg => {
  ElMessageBox.confirm('确认删除当前商品吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      API.deleteComboByCode({ code: rowMsg.code }).then(res => {
        if (res.code === '200') {
          ElMessage.success('删除成功')
          queryLists({})
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '删除失败',
      })
    })
}
const stopOrStartRow = rowMsg => {
  API.updateComboIsShelves({ code: rowMsg.code, isShelves: rowMsg.isShelves === 1 ? 0 : 1 }).then(res => {
    if (res.code === '200') {
      ElMessage.success('更新成功')
      searchLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const editProType = rowMsg => {
  API.selectComboItem({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseFloat(res.data.price.split('~')[0])
        res.data.price2 = parseFloat(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }

      data.groupRowMsg = {
        shops: _.cloneDeep(data.shopList),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        channel: res.data.channel.split(','),
        couponPackageVOList: res.data.couponPackageVOList ?? [],
      }
      data.addProductVisible = true
      data.isEdit = true
    } else {
      ElMessage.error(res.error)
    }
  })
}
const showProType = rowMsg => {
  API.selectComboItem({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseFloat(res.data.price.split('~')[0])
        res.data.price2 = parseFloat(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }

      data.groupRowMsg = {
        shops: _.cloneDeep(data.shopList),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        channel: res.data.channel.split(','),
      }
      data.addProductVisible = true
      data.isEdit = false
    } else {
      ElMessage.error(res.error)
    }
  })
}
//操作商品列表数据
const typeOperation = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showProType(rowMsg)
      break
    case 2:
      editProType(rowMsg)
      break
    case 3:
      stopOrStartRow(rowMsg)
      break
  }
}

const updateAddProductVisible = ({ update, search }) => {
  data.addProductVisible = update
  if (search) {
    searchLists()
  }
}

const batchUpdateVisible = ({ update, search }) => {
  data.batchUpdateVisible = update
  if (search) {
    searchLists()
  }
}

const batchDeliveryVisible = ({ update, search }) => {
  data.batchDeliveryVisible = update
  if (search) {
    searchLists()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}

.product-list-top {
  width: 96%;
  margin-top: 20px;
  margin-left: 2%;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    .title {
      width: 100px;
      margin-left: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: right;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
