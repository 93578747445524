<template>
  <div style="height:100%;">
    <el-container>
      <el-aside :width="sidebarWidth" class="aside">
        <sidebar :isCollapse="isCollapse" :is-phone="isPhone"></sidebar>
      </el-aside>
      <el-container>
        <el-header class="header" style="height: 95px">
          <!-- <el-header class="header" >  -->
          <div class="left">
            <div class="operate" :style="{ height: height }">
              <nav-bar></nav-bar>
            </div>
            <el-collapse-transition>
              <reuse-tab ref="reuse"></reuse-tab>
            </el-collapse-transition>
          </div>
        </el-header>
        <el-main ref="main">
          <menu-tab></menu-tab>
          <app-main ref="appMain"></app-main>
        </el-main>
        <DownloadCenter />
        <back-top :right="50" :bottom="20" :fontSize="34"></back-top>
      </el-container>
      <div class="sidenav-mask" :class="{ show: isPhone && isCollapse }" @click="changeSlidebarState"></div>
    </el-container>
  </div>
</template>

<script>
import emitter from 'lin/util/emitter'
import { NavBar, Sidebar, AppMain, ReuseTab, MenuTab, BackTop, DownloadCenter } from '@/component/layout'

const navBarHeight = 66 // header高度
const reuseTabHeight = 70 // 历史记录栏高度
const marginHeight = 20 // 历史记录栏与舞台的间距
const sidebarWidth = '210px'
const totalHeight = navBarHeight + reuseTabHeight + marginHeight

export default {
  components: {
    NavBar,
    Sidebar,
    AppMain,
    ReuseTab,
    MenuTab,
    BackTop,
    DownloadCenter,
  },
  data() {
    return {
      isCollapse: false, // 左侧菜单栏是否折叠
      sidebarWidth, // 左侧菜单栏展开的宽度
      clientWidth: 0, // 页面宽度
      clientHeight: 0, // 页面高度
      foldState: false, // 控制左侧菜单栏按键
      isPhone: false,
      height: '80px',
    }
  },
  mounted() {
    this.setResize()
    if (this.clientWidth < 500) {
      this.isPhone = true
    } else {
      this.isPhone = false
      // 检测屏幕宽度, 确定默认是否展开
      if (this.clientWidth <= 768) {
        emitter.emit('removeSidebarSearch')
        this.isCollapse = true
      } else {
        this.isCollapse = false
        emitter.emit('showSidebarSearch')
      }
    }
    // 监测屏幕宽度 折叠左侧菜单栏
    window.onresize = () => {
      this.setResize()
      if (this.clientWidth <= 500) {
        this.isPhone = true
      } else if (this.clientWidth <= 800) {
        this.isPhone = false
      }

      // if (_this.clientWidth <= 768) {
      //   // 页面宽度 768
      //   if (_this.isCollapse === false) {
      //     _this.eventBus.$emit('removeSidebarSearch')
      //     _this.isCollapse = true
      //   }
      // } else if (_this.isCollapse === true) {
      //   _this.eventBus.$emit('showSidebarSearch')
      //   _this.isCollapse = false
      // }
    }

    emitter.on('noReuse', () => {
      this.height = '86px'
    })
    emitter.on('hasReuse', () => {
      this.height = '45px'
    })
  },
  computed: {
    elMenuCollapse() {
      if (this.isPhone) {
        return false
      }

      return this.isCollapse
    },
    asideStyle() {
      const style = {}
      if (this.isPhone) {
        style.position = 'absolute'
        style.height = `${this.clientHeight}px`
        style.zIndex = 12
        if (this.isCollapse === false) {
          style.transform = `translateX(-${sidebarWidth})`
        } else {
          style.transform = 'translateX(0)'
        }
      }
      return style
    },
  },
  methods: {
    // 控制菜单折叠
    changeSlidebarState() {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        emitter.emit('removeSidebarSearch')
      } else {
        emitter.emit('showSidebarSearch')
      }
      this.foldState = !this.foldState
    },
    // 响应页面的宽度高度变化
    setResize() {
      this.clientHeight = document.body.clientHeight
      this.clientWidth = document.body.clientWidth
      // this.$nextTick(() => {
      //   // this.$refs.appMain.$el.style.minHeight = `${this.clientHeight - totalHeight + 20}px`
      //   this.$refs.appMain.$el.style.height = '100%'
      // })
    },
  },
  watch: {
    isCollapse() {
      if (this.isPhone) {
        // 手机模式
        this.sidebarWidth = sidebarWidth
        if (this.isCollapse === false) {
          this.transX = 0
        } else {
          this.transX = -1 * sidebarWidth
        }
      } else {
        this.transX = 0
        this.sidebarWidth = this.isCollapse === false ? sidebarWidth : '64px'
      }
    },
    $route() {
      this.showBackTop = false
      if (this.scrollY <= 70) {
        // MenuTab组件高度
        this.backTop()
      }
      if (this.isPhone && this.isCollapse) {
        this.changeSlidebarState()
      }
    },
  },

  beforeUnmount() {
    emitter.off('noReuse')
    emitter.off('hasReuse')
  },
}
</script>

<style lang="scss" scoped>
.aside {
  background: url('../../assets/image/menubk.png');
  background-size: 120% 100%;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

.header {
  padding: 0;
  background: $header-background;
  // height: $header-height !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 6px 0px rgba(190, 204, 216, 0.4);
  border-bottom: 1px solid rgba(190, 204, 216, 0.4);

  .left {
    height: 100%;
    width: 100%;

    .operate {
      display: flex;
      align-items: center;
      background: $header-background;
      padding-left: 20px;
      height: 80px;

      .iconfont {
        font-size: 16px;
        font-weight: 500;
        color: $right-side-font-color;
        cursor: pointer;
        transform: rotate(0deg);
        transition: all 0.3s linear;
        margin-right: 10px;

        &:hover {
          color: #3963bc;
        }
      }

      .rotate {
        transform: rotate(180deg);
        transition: all 0.3s linear;
      }
    }
  }

  .right-info {
    display: flex;
    align-items: center;
  }
}

.el-main {
  overflow-y: auto;
  position: relative;
  padding: 0;
}

.backTop {
  position: fixed;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  right: 50px;
  bottom: 50px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  z-index: 99;
  background: #fff;

  .iconfont {
    font-size: 36px;
  }
}

.sidenav-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: none;
  cursor: pointer;

  &.show {
    display: block;
  }
}
</style>
