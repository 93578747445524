<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-07-22 10:46:51
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/salesStatisticsReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :total="totalRecords"
      :model="model"
      :isPagination="true"
      :emptyText="emptyTableText"
      :getTableData="getMaterialStockTraceStatistics"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'
import { ElMessage } from 'element-plus'

export default {
  props: {
    auths: {},
  },
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodeList: [],
        goodsCategory: '',
        goodsName: '',
        operationType: '',
        houseName: '',
        operationType: '',
        statisticCode: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'storeName',
          label: '门店名称',
          width: '150px',
        },
        {
          prop: 'statisticName',
          label: '商品统计分类',
          width: '140px',
        },
        {
          prop: 'goodsCategory',
          label: '商品分类',
          width: '100px',
        },
        {
          prop: 'goodsName',
          label: '商品名称',
          width: '150px',
        },
        {
          prop: 'houseName',
          label: '仓库',
        },
        {
          prop: 'unit',
          label: '单位',
        },
        {
          prop: 'beforeStock',
          label: '期初库存数量',
          width: '150px',
        },
        {
          prop: 'beforeAverageFree',
          label: '期初库存成本单价',
          width: '180px',
        },
        {
          prop: 'beforeAverageFreeLastValue',
          label: '期初库存成本',
          width: '150px',
        },
        {
          prop: 'afterStock',
          label: '期末库存数量',
          width: '150px',
        },
        {
          prop: 'afterAverageFree',
          label: '期末库存成本单价',
          width: '180px',
        },
        {
          prop: 'afterAverageFreeLastValue',
          label: '期末库存成本',
          width: '150px',
        },
        {
          prop: 'beforeCunCount',
          label: '期初寄存数量',
          width: '150px',
        },
        {
          prop: 'afterCunCount',
          label: '期末寄存数量',
          width: '150px',
        },
        {
          prop: 'jiecunMoney',
          label: '结存金额',
          width: '100px',
        },
        {
          prop: 'purchaseCount',
          label: '进货数量',
          width: '100px',
        },
        {
          prop: 'jinhuoDanjia',
          label: '进货单价',
          width: '100px',
        },
        {
          prop: 'jinhuoChenben',
          label: '进货成本',
          width: '100px',
        },
        {
          prop: 'salesCount',
          label: '销售数量',
          width: '100px',
        },
        {
          prop: 'saleDanjia',
          label: '销售单价',
          width: '100px',
        },
        {
          prop: 'saleAllMoney',
          label: '销售总额',
          width: '100px',
        },
        {
          prop: 'saleCostDanjia',
          label: '销售成本单价',
          width: '150px',
        },
        {
          prop: 'saleCost',
          label: '销售成本',
          width: '100px',
        },
        {
          prop: 'diaoboRukuCount',
          label: '调拨入库数量',
          width: '150px',
        },
        {
          prop: 'diaoboRukuMoney',
          label: '调拨入库成本',
          width: '150px',
        },
        {
          prop: 'diaoboChukuCount',
          label: '调拨出库数量',
          width: '150px',
        },
        {
          prop: 'diaoboChukuMoney',
          label: '调拨出库成本',
          width: '150px',
        },
        {
          prop: 'tuiCount',
          label: '退货数量',
          width: '100px',
        },
        {
          prop: 'tuiCost',
          label: '退货成本',
          width: '100px',
        },
        {
          prop: 'checkMoreCount',
          label: '盘盈数量',
          width: '100px',
        },
        {
          prop: 'panyinMoney',
          label: '盘盈金额',
          width: '100px',
        },
        {
          prop: 'checkLoseCount',
          label: '盘亏数量',
          width: '100px',
        },
        {
          prop: 'pankuiMoney',
          label: '盘亏金额',
          width: '100px',
        },
        {
          prop: 'storageWineCount',
          label: '存酒数量',
          width: '100px',
        },
        {
          prop: 'cunjiuMoney',
          label: '存酒金额',
          width: '100px',
        },
        {
          prop: 'takeWineCount',
          label: '取酒数量',
          width: '100px',
        },
        {
          prop: 'qujiuMoney',
          label: '取酒金额',
          width: '100px',
        },
        {
          prop: 'confiscationCount',
          label: '充公入库数量',
          width: '150px',
        },
        {
          prop: 'chongGongMoney',
          label: '充公入库金额',
          width: '150px',
        },

        {
          prop: 'threeMonthAverage',
          label: '近3个月销量日均值',
          width: '180px',
        },
        {
          prop: 'dangqianKucunCount',
          label: '当前库存数',
          width: '120px',
        },
        {
          prop: 'dangqianKucunDanjiaMoney',
          label: '当前库存成本单价',
          width: '180px',
        },
        {
          prop: 'dangqianKucunMoney',
          label: '当前库存成本',
          width: '150px',
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '商品分类',
          type: 'select',
          placeholder: '请选择商品分类',

          typeProp: 'select',
          children: 'option',
          prop: 'goodsCategory',
          options: [],
        },
        {
          label: '仓库',
          placeholder: '请选择仓库',

          labelWidth: '50px',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'houseName',
          options: [
            {
              label: '总仓库',
              value: '总仓库',
            },
            {
              label: '超市仓库',
              value: '超市仓',
            },
            {
              label: '存酒仓库',
              value: '存酒仓库',
            },
          ],
        },
        {
          label: '商品统计分类',
          placeholder: '请选择商品统计分类',

          labelWidth: '100px',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'statisticCode',
          options: [
            {
              label: '超市',
              value: 1,
            },
            {
              label: '水吧',
              value: 3,
            },
            {
              label: '厨房',
              value: 2,
            },
          ],
        },
        { label: '商品名称', type: 'input', prop: 'goodsName', placeholder: '请输入商品名称' },

        {
          label: '营业日期',
          type: 'date-picker',
          labelWidth: '100px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '导出',
          type: 'exportButton',
          url: '/report/export/reportMaterialStockTraceStatistics',
          auth: 'export',
        },
      ],
    }
  },
  async mounted() {
    this.$route.meta.msg = this.auths
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      await this.getStoreList()
      // this.getMaterialStockTraceStatistics(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
      api
        .selectByType({
          pageSize: 100,
          current: 1,
          type: 1,
        })
        .then(res => {
          this.searchform[2].options = res.data.records.map(item => {
            return {
              value: item.code,
              label: item.name,
            }
          })
        })
    },
    getMaterialStockTraceStatistics(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return
      }
      this.$refs.search.fullscreenLoading = true
      api
        .getMaterialStockTraceStatistics(model)
        .then(res => {
          this.tableData = res.data.records || []
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
          this.setEmptyDataText()
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    parentSearch(param) {
      const { goodsCategory, goodsName, storeCodeList, houseName, statisticCode, regionCodeList } = param
      this.model = {
        goodsCategory,
        goodsName,
        storeCodeList,
        regionCodeList,
        houseName,
        statisticCode,
        pageNum: this.model.pageNum,
        pageSize: this.model.pageSize,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.getMaterialStockTraceStatistics(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { goodsCategory, goodsName, storeCodeList, houseName, statisticCode, regionCodeList } = param
      this.model = {
        goodsCategory,
        goodsName,
        storeCodeList,
        regionCodeList,
        houseName,
        statisticCode,
        pageNum: this.model.pageNum,
        pageSize: this.model.pageSize,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      if (!this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '进销存统计.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;
}
</style>
