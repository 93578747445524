<template>
  <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 2% 0  2%;">
    <el-form-item label="供应商名称">
      <el-input placeholder="请输入供应商名称" v-model="data.supplierName"></el-input>
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        v-model="data.regionCodeList"
        :options="areaOptions"
        clearable
        placeholder="请选择门店区域"
        filterable
        @change="changeArea()"
        :show-all-levels="false"
        collapse-tags
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCodeList"
        :options="data.shopLists"
        placeholder="请选择门店"
        class="search-select userSelect"
        style="width: 300px;"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
      />
    </el-form-item>
    <el-form-item label="商品">
      <el-select-v2
        v-model="data.skuCodeList"
        :options="data.articleList"
        placeholder="请选择商品"
        class="search-select userSelect1"
        style="width: 300px;"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
      />
    </el-form-item>

    <el-form-item>
      <el-button type="primary" @click="queryShops">搜索</el-button>
      <el-button @click="resetParams">
        重置
      </el-button>
      <el-button @click="addShop" v-if="data.authMenus.add">
        新增
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column show-overflow-tooltip label="序号" align="center" type="index" :index="indexfn" />
    <el-table-column show-overflow-tooltip prop="supplierName" label="供应商名称" align="center" />
    <el-table-column show-overflow-tooltip prop="contacts" label="联系人" align="center" />
    <el-table-column show-overflow-tooltip prop="phone" label="联系电话" align="center" />
    <el-table-column show-overflow-tooltip label="供货类型" align="center">
      <template #default="{row}">
        <text v-if="JSON.parse(row.supplyType).includes(1)">商品</text>
        <text v-if="JSON.parse(row.supplyType).includes(4)">易耗品</text>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="结账类型" align="center">
      <template #default="{row}">
        <text v-if="row.checkoutType == 1">月结</text>
        <text v-else>现款</text>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="supplyGoodsCount" label="供货商品数量" align="center" />
    <el-table-column show-overflow-tooltip prop="supplyStoreCount" label="供货门店数量" align="center" />
    <el-table-column show-overflow-tooltip prop="updateTime" label="更新时间" align="center" />
    <el-table-column show-overflow-tooltip prop="state" label="状态" align="center">
      <template #default="scope">
        <el-tag type="success" v-if="scope.row.state === 10">正常使用</el-tag>
        <el-tag type="error" v-else>禁用</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="350" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="showDetail(1, scope.row)">详情</el-button>
        <el-button type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <el-button type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.update">供货商品</el-button>
        <el-button type="text" @click="showDetail(4, scope.row)" v-if="data.authMenus.update"> 供货门店</el-button>
        <el-button type="text" @click="showDetail(5, scope.row)">{{
          scope.row.state == 0 ? '启用' : '停用'
        }}</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddProvider
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :needEditMsg="data.needEditMsg"
    @update-dialogVisible="updateDialogVisible"
    :shopLists="shopLists"
    :isEdit="data.isEdit"
  ></AddProvider>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import clearSelect from '@/lin/util/clearSelect2.js'
import _ from 'lodash'
import { reactive, onMounted, toRaw, computed, nextTick, ref } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter, useRoute } from 'vue-router'
import AddProvider from './AddProvider.vue'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

const data = reactive({
  authMenus: {},
  storeCodeList: [],
  areaCode: [],
  regionCodeList: [],
  storeName: undefined,
  supplierName: undefined,
  skuName: undefined,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  dialogVisible: false,
  needEditMsg: {},
  isEdit: false,
  shopLists: [],
  articleList: [],
  skuCodeList: [],
  loading: false,
})

const router = useRouter()
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryShops()
  getShops()
  getArticles()
  getAreas()
})

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
const getArticles = () => {
  API.selectProductAll({ type: 1 }).then(res => {
    if (res.code === '200') {
      data.articleList = res.data.map(item => ({
        value: item.code,
        label: item.name,
      }))
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const getShops = () => {
  API.getStoreList({ list: data.areaCode }).then(res => {
    if (res.code === '200') {
      data.shopLists = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const resetParams = () => {
  data.supplierName = undefined
  data.regionCodeList = []
  data.areaCode = []
  data.areaCode = []
  data.storeCodeList = []
  data.skuCodeList = []
  nextTick(() => {
    clearSelect('userSelect')
    clearSelect('userSelect1')
    getShops()
  })
  queryShops()
}

// 获取门店列表数据
const searchList = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let query = {
    supplierName: data.supplierName,
    skuCodeList: data.skuCodeList,
    storeCodeList: data.storeCodeList,
    regionCodeList: data.areaCode,
    current: data.currentPage,
    pageSize: data.pageSize,
    storeName: data.storeName,
  }
  API.selectSupplierPage(query)
    .then(res => {
      if (res.code == '200') {
        data.tableData = res.data?.list || []
        data.total = res.data?.total || 0
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}
const queryShops = () => {
  data.currentPage = 1
  searchList()
}

//添加
const addShop = () => {
  data.needEditMsg = {
    supplyType: [],
    selectedOptions: [],
    supplierAccountList: [{}],
    supplierGeoList: [{ regionList: [] }, { regionList: [] }],
    isGeo: 1,
    cooperateType: 2,
  }
  data.isEdit = true
  data.dialogVisible = true
}
const toArticleManager = rowMsg => {
  let params = {
    supplierName: rowMsg.supplierName,
    code: rowMsg.code,
  }
  let supplyType = JSON.parse(rowMsg.supplyType)
  if (supplyType.length == 1) {
    if (supplyType[0] == 1) {
      params.supplyType = '商品'
    } else {
      params.supplyType = '易耗品'
    }
  } else {
    params.supplyType = '商品 / 易耗品'
  }
  sessionStorage.setItem('purchase-info', JSON.stringify(params))
  router.push(`/purchase-manager/ArticleManager`)
}
const toShopManager = rowMsg => {
  let params = {
    supplierName: rowMsg.supplierName,
    code: rowMsg.code,
  }
  let supplyType = JSON.parse(rowMsg.supplyType)
  if (supplyType.length == 1) {
    if (supplyType[0] == 1) {
      params.supplyType = '商品'
    } else {
      params.supplyType = '易耗品'
    }
  } else {
    params.supplyType = '商品 / 易耗品'
  }
  sessionStorage.setItem('purchase-info', JSON.stringify(params))
  router.push(`/purchase-manager/ShopManager`)
}
const editRow = rowMsg => {
  API.querySupplierDetails({ code: rowMsg.code }).then(res => {
    if (res?.code == 200) {
      data.isEdit = true
      res.data.supplyType = JSON.parse(res.data.supplyType)
      if (!res.data.supplierGeoList || res.data.supplierGeoList.length == 0) {
        res.data.supplierGeoList = [{ regionList: [] }]
      }
      if (!res.data.supplierAccountList || res.data.supplierAccountList.length == 0) {
        res.data.supplierAccountList = [{}]
      }

      console.log(res.data.supplierGeoList)
      data.needEditMsg = res.data
      data.dialogVisible = true
    }
  })
}
const showMsg = rowMsg => {
  API.querySupplierDetails({ code: rowMsg.code }).then(res => {
    if (res?.code == 200) {
      data.isEdit = false
      res.data.supplyType = JSON.parse(res.data.supplyType)
      if (!res.data.supplierGeoList || res.data.supplierGeoList.length == 0) {
        res.data.supplierGeoList = [{ regionList: [] }]
      }
      if (!res.data.supplierAccountList || res.data.supplierAccountList.length == 0) {
        res.data.supplierAccountList = [{}]
      }
      data.needEditMsg = res.data
      data.dialogVisible = true
    }
  })
}

//停用/启用
const supplierEnable = rowMsg => {
  API.supplierEnable({
    code: rowMsg.code,
    state: rowMsg.state == 10 ? 0 : 10,
  }).then(res => {
    searchList()
  })
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showMsg(rowMsg)
      break
    case 2:
      editRow(rowMsg)
      break
    case 3:
      toArticleManager(rowMsg)
      break
    case 4:
      toShopManager(rowMsg)
      break
    case 5:
      supplierEnable(rowMsg)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryShops()
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchList()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchList()
}
const handleSelectionChange = val => {
  data.deleteRow = val
}
//删除门店
const deleteShopManager = () => {
  ElMessageBox.confirm('确认删除当前所选门店吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      API.deleteStoreMsg({ delete: toRaw(data.deleteRow) }).then(res => {
        ElMessage.success('删除成功')
        searchList()
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '删除失败',
      })
    })
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
