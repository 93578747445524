<template>
  <div class="table-search-tab" style="height: 76px;line-height: 76px;">
    <div class="search-left">
      <span>门店账号</span>
      <el-input placeholder="请输入用户名" v-model="data.userName" class="input-search-tel"></el-input>
      <el-input placeholder="请输入姓名" v-model="data.name" class="input-search-tel"></el-input>
      <el-select v-model="data.selectShop" class="search-select" placeholder="选择门店">
        <el-option v-for="item in shopList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button type="primary">查询</el-button>
    </div>
    <div class="search-right">
      <el-button type="primary" @click="addAccountNumber"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
      <el-button type="primary">
        <el-icon><Delete /></el-icon>
        删除
      </el-button>
    </div>
  </div>
  <el-table :data="data.tableData" style="width: 96%; margin-left: 2%;" class="table-content">
    <el-table-column type="selection" width="55" align="center" />
    <el-table-column type="index" label="序号" align="center" />
    <el-table-column prop="userName" label="用户名" align="center" />
    <el-table-column prop="name" label="姓名" align="center" />
    <el-table-column prop="sex" label="性别" align="center" />
    <el-table-column prop="phone" label="手机号" align="center" />
    <el-table-column prop="email" label="邮箱" align="center" />
    <el-table-column prop="role" label="所属角色" align="center" />
    <el-table-column prop="shop" label="所属门店" align="center" />
    <el-table-column prop="updateTime" label="更新时间" align="center" />
    <el-table-column prop="state" label="状态" align="center">
      <template #default="scope">
        <div v-if="scope.row.state === 1" style="background-color: grey;">启用</div>
        <div v-else style="background-color: yellow;">停用</div>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="250" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="showDetail(1, scope.row)">详情</el-button>
        <el-button type="text" @click="showDetail(2, scope.row)">修改</el-button>
        <el-button type="text" @click="showDetail(3, scope.row)">停用</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddAccountNumber
    :dialogVisible="data.dialogVisible"
    :accountMsg="data.accountMsg"
    :isEdit="data.isEdit"
    @update-dialogVisible="updateDialogVisible"
  ></AddAccountNumber>
</template>

<script setup>
import { reactive } from 'vue'
import AddAccountNumber from './addAccountNumber/index.vue'
const data = reactive({
  selectShop: undefined,
  userName: undefined,
  name: undefined,
  tableData: [
    { name: 1, state: 1 },
    { name: 2, state: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
    { name: 2 },
  ],
  pageSize: 100,
  currentPage: 1,
  total: 1000,
  dialogVisible: false,
  accountMsg: null,
  isEdit: false,
})
const shopList = [{ lable: '苏州店', value: '苏州店' }]

//添加
const addAccountNumber = () => {
  data.dialogVisible = true
  data.isEdit = true
}
const showRow = rowMsg => {
  data.isEdit = false
  data.accountMsg = rowMsg
  addAccountNumber()
}
const editRow = rowMsg => {
  data.isEdit = true
  data.accountMsg = rowMsg
  addAccountNumber()
}
const deleteRow = () => {}
//操作数据
const showDetail = (index, rowMsg) => {
  switch (index) {
    case 1:
      showRow(rowMsg)
      break
    case 2:
      editRow(rowMsg)
      break
    case 3:
      deleteRow()
      break
  }
}

const updateDialogVisible = isShow => {
  data.dialogVisible = isShow
}
//分页
const handleSizeChange = () => {}
const handleCurrentChange = () => {}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    span {
      width: 100px;
      margin-left: 20px;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
