const TableHead = [
  {
    prop: 'supplierName',
    label: '企业名称',
  },
  {
    prop: 'contacts',
    label: '联系人',
  },
  {
    prop: 'cellphone',
    label: '联系电话',
  },

  {
    prop: 'paymentAccountType',
    label: '支付方式',
  },
  {
    prop: 'billType',
    label: '是否开票',
  },
  {
    prop: 'operate',
    label: '操作',
    width: 280,
    formhidden: true,
  },
]

export default TableHead
