<template>
  <el-table :data="data.tableData" style=";" class="table-content">
    <el-table-column prop="categoryName" label="分类名称" align="center" />
    <el-table-column prop="typeName" label="分类类型" align="center" />
    <el-table-column label="操作" width="250" align="center">
      <template #default="scope" fixed="right">
        <el-button type="text" @click="showDetail(1, scope.row)" v-if="authMenus.update">编辑</el-button>
        <!-- <el-button type="text" @click="showDetail(2, scope.row)" v-if="authMenus.delete">删除</el-button> -->
      </template>
    </el-table-column>
  </el-table>
  <AddType
    :dialogVisible="data.dialogVisible"
    :accountMsg="data.accountMsg"
    @update-dialogVisible="updateDialogVisible"
    v-if="data.dialogVisible"
  ></AddType>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import AddType from './appType/index.vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'

const data = reactive({
  tableData: [],
  dialogVisible: false,
  accountMsg: null,
})

/**
 * 获取表单数据
 */
const getTableDatas = async () => {
  if (authMenus.value.query) {
    let res = await API.storeTypeSelectInfoPage()
    if (res.code === '200') {
      data.tableData = res.data
    }
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

const authMenus = ref([])
const route = useRoute()
onMounted(() => {
  operationAuth(route, authMenus.value)
  getTableDatas()
})

/**
 * 编辑
 * @param {*} rowMsg
 */
const editRow = rowMsg => {
  data.accountMsg = {
    ...rowMsg,
    typesArray: rowMsg.typeName.split('、').map(item => ({ name: item, code: item })),
  }
  data.dialogVisible = true
}

/**
 * 删除
 * @param {*} param0
 */
const deleteRow = async rowMsg => {
  ElMessageBox.confirm('确认删除当前门店分类吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let { code, msg } = await API.storeTypeDeleteByCode({ code: rowMsg.code })
    if (code === '200') {
      ElMessage.success('删除成功')
    } else {
      ElMessage.error(msg)
    }
  })
}
//操作数据
const showDetail = (index, rowMsg) => {
  switch (index) {
    case 1:
      editRow(rowMsg)
      break
    case 2:
      deleteRow(rowMsg)
      break
  }
}

const updateDialogVisible = isShow => {
  data.dialogVisible = isShow
  getTableDatas()
}
</script>

<style lang="scss" scoped>
.table-content {
  width: 96%;
  margin: 2% 30px 2% 0px;
}
</style>
