<template>
  <el-dialog v-model="visible" :title="title" width="40%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="unitConfig" label-width="120px" :rules="rules" ref="elForm">
      <el-form-item label="单位名称" prop="name">
        <el-input v-model="unitConfig.name" placeholder="请输入名称" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="unitConfig.remarks" />
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref } from 'vue'
import { ElMessage } from 'element-plus'

import API from '../../service/api'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    unitConfig: Object,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })
    const title = computed(() => {
      return Reflect.has(props.unitConfig, 'code') ? '修改单位配置' : '添加单位配置'
    })
    const rules = reactive({
      name: [{ required: true, message: '请填写单位名称', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          if (Reflect.has(props.unitConfig, 'code')) {
            update()
          } else {
            add()
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)
    const add = () => {
      API.saveDict(props.unitConfig).then(res => {
        if (res.code === '200') {
          ElMessage.success('保存成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const update = () => {
      API.updateDict(props.unitConfig).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      title,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      rules,
      elForm,
      handleSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
</style>
