<!--
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-07-16 17:39:14
 * @LastEditTime: 2024-07-25 11:01:14
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/plugin/cms-manager/view/cover-manager/index.vue
 * @Description:
-->
<template>
  <div class="cover-manager">
    <!-- 版本信息 -->
    <div class="version">
      <!-- <div class="version-item " @click="handleClickTab(1)" :class="{ active: activeTab == 1 ? true : false }">
        V1.0
      </div>
      <div class="version-item" @click="handleClickTab(2)" :class="{ active: activeTab == 2 ? true : false }">V2.0</div> -->
      <div>
        <el-button
          style="width: 200px;border-radius: 10px;position: absolute;right: 20px;"
          type="primary"
          @click="handleSave"
          v-if="authMenus.add"
          >保存</el-button
        >
      </div>
    </div>

    <!-- 内容区 -->
    <div v-for="item in list" :key="item.id">
      <el-card style="margin-bottom: 30px;">
        <div style="display: flex;overflow-x: auto; ">
          <div class="cover-manager-item" style="margin-top: 20px;">
            <div style="color:#2780f1">主标题</div>
            <div><el-input v-model="item.name"></el-input></div>
            <div style="color:#2780f1">副标题</div>
            <div><el-input v-model="item.otherName"></el-input></div>
            <div style="color:#2780f1">点歌屏图片</div>
            <div>
              <el-upload
                v-loading="item.miniLoading"
                accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP,.pdf"
                list-type="picture"
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :http-request="params => changeImage(params, item, 'pic', 'miniLoading')"
              >
                <img v-if="item.pic" :src="item.pic" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </div>
            <div style="color:#2780f1">小程序封面</div>
            <div>
              <el-upload
                accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP,.pdf"
                list-type="picture"
                class="avatar-uploader"
                action="#"
                :show-file-list="false"
                :http-request="params => changeImage(params, item, 'miniPic', 'posLoading')"
                v-loading="item.posLoading"
              >
                <img v-if="item.miniPic" :src="item.miniPic" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </div>
          </div>

          <div class="cover-manager-item" v-for="childItem in item.list" :key="childItem.id">
            <el-card class="item-card">
              <div style="color:#2780f1">主标题</div>
              <div><el-input v-model="childItem.name"></el-input></div>
              <div style="color:#2780f1">副标题</div>
              <div><el-input v-model="childItem.otherName"></el-input></div>
              <div style="color:#2780f1">点歌屏图片</div>
              <div>
                <el-upload
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP,.pdf"
                  list-type="picture"
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="params => changeImage(params, childItem, 'pic', 'posLoading')"
                >
                  <img v-if="childItem.pic" :src="childItem.pic" class="avatar" />
                  <el-icon v-else class="avatar-uploader-icon">
                    <Plus />
                  </el-icon>
                </el-upload>
              </div>
              <div style="color:#2780f1">小程序封面</div>
              <div>
                <el-upload
                  accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP,.pdf"
                  list-type="picture"
                  class="avatar-uploader"
                  action="#"
                  :show-file-list="false"
                  :http-request="params => changeImage(params, childItem, 'miniPic', 'posLoading')"
                  v-loading="loading"
                >
                  <img v-if="childItem.miniPic" :src="childItem.miniPic" class="avatar" />
                  <el-icon v-else class="avatar-uploader-icon">
                    <Plus />
                  </el-icon>
                </el-upload>
              </div>
            </el-card>
            <div>
              <el-checkbox
                v-model="childItem.isDrama"
                :true-label="1"
                :false-label="0"
                @change="handleChange(childItem, item)"
                >是否剧本杀</el-checkbox
              >
            </div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { headers, client } from '../../../../lin/util/uploadImg.js'

const DIR = 'cover/'

export default {
  data() {
    return {
      authMenus: {},
      loading: false,
      list: [],
      activeTab: 2,
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    this.handleClickTab(2)
  },
  methods: {
    async handleSave() {
      let params = []
      const user = JSON.parse(localStorage.getItem('vuex'))
      let username = `${user.user.userInfo.userSaasUserVO.userName}(${user.user.userInfo.userSaasUserVO.cellPhone})`
      this.list.forEach(i => {
        params.push({
          id: i.id,
          code: i.code,
          type: i.type,
          name: i.name,
          otherName: i.otherName,
          pic: i.pic,
          miniPic: i.miniPic,
          isDrama: i.isDrama,
          version: this.activeTab == 1 ? 0 : 1,
          updateUser: username,
          sort: i.sort,
        })
        i.list.forEach(a => {
          params.push({
            id: a.id,
            code: a.code,
            type: a.type,
            name: a.name,
            otherName: a.otherName,
            pic: a.pic,
            miniPic: a.miniPic,
            isDrama: a.isDrama,
            version: this.activeTab == 1 ? 0 : 1,
            updateUser: username,
            sort: a.sort,
          })
        })
      })
      let res
      if (this.activeTab == 1) {
        res = await API.updateOrSaveOne(params)
      } else {
        res = await API.updateOrSave(params)
      }
      if (res.code == 200) {
        ElMessage.success('保存成功')
      } else {
        ElMessage.error(res.msg)
      }
    },
    handleClickTab(activeTab) {
      this.activeTab = activeTab
      this.list = []
      this.queryVodSongMenusAll()
    },
    handleChange(child, data) {
      if (child.isDrama == 0) {
        child.isDrama = 0
        return
      }
      data.list.forEach(a => {
        a.isDrama = 0
      })
      child.isDrama = 1
    },
    async queryVodSongMenusAll() {
      let res
      if (this.activeTab == 1) {
        res = await API.queryVodSongMenusAll()
      } else {
        res = await API.queryCoverAll()
      }
      if (Number(res.code) == 200) {
        res.data = res.data?.map(item => {
          item.posLoading = false
          item.miniLoading = false
          item.list = item.list.map(a => ({
            ...a,
            posLoading: false,
            miniLoading: false,
          }))
          return item
        })
        this.list = res.data || []
      } else {
        ElMessage.error(res.msg)
      }
    },

    changeImage(file, item, propName, loadName) {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      item[loadName] = true
      item[propName] = ''
      let filesSplit = file.file.name.split('.')
      let fileName =
        file.file.name.substring(0, file.file.name.length - filesSplit[filesSplit.length - 1].length - 1) +
        '_' +
        Date.now() +
        '.' +
        filesSplit[filesSplit.length - 1]
      client()
        .put(DIR + fileName, file.file, { headers })
        .then(res => {
          item[propName] = res.res.requestUrls[0]
        })
        .finally(() => {
          item[loadName] = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.cover-manager {
  padding: 20px;

  .version {
    display: flex;
    padding: 10px;
    margin-bottom: 30px;
    .version-item {
      width: 150px;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      background-color: #fff;
      overflow: hidden;
      color: #303133;
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .active {
      background-color: #2780f1;
      color: white;
    }
  }

  .cover-manager-item {
    width: 200px;
    padding: 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ccc;

    &:first-child,
    &:nth-child(9) {
      border-right: 1px solid #ccc;
    }

    & > div {
      margin-bottom: 10px;
      text-align: center;
    }
    .avatar-uploader {
      img {
        height: 138px;
        width: 138px;
      }
      ::v-deep .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        border-color: #a1baff;

        .avatar-uploader-icon {
          font-size: 40px;
          color: #8c939d;
          height: 138px;
          width: 138px;
          line-height: 138px;
          text-align: center;
        }
      }
    }
  }
}

::v-deep .el-loading-spinner {
  transform: translateY(-50%);
}
.item-card .el-card__body {
  & > div {
    margin-bottom: 10px;
  }
}
</style>
