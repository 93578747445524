<template>
  <el-dialog v-model="visible" title="包厢消费单" width="70%" :before-close="handleClose">
    <div>
      <el-divider />
      <el-form
        :inline="true"
        :model="data"
        label-width="120px"
        style="width: 96%; margin-left: 2%;margin-top: 10px; display: flex; justify-content: space-between;height: 34px;"
      >
        <el-form-item label="场次号:">
          <p style="width: 150px;">{{ data.respData.openCode }}</p>
        </el-form-item>
        <el-form-item label="包厢号:">
          <p style="width: 140px;">{{ data.boxInfo.roomName }}</p>
        </el-form-item>
        <el-form-item label="包厢类型:">
          <p style="width: 140px;">{{ data.boxInfo.roomTypeName }}</p>
        </el-form-item>
      </el-form>
      <el-form
        :inline="true"
        :model="data"
        label-width="120px"
        style="width: 96%; margin-left: 2%; display: flex; justify-content: space-between;height: 34px;"
      >
        <el-form-item label="开台时间:">
          <p style="width: 150px;">{{ data.boxInfo.startTime }}</p>
        </el-form-item>
        <el-form-item label="关房时间:">
          <p style="width: 140px;">{{ data.boxInfo.closeTime }}</p>
        </el-form-item>
        <el-form-item label="使用时长(分钟):">
          <p style="width: 140px;">{{ data.boxInfo.usedTime }}</p>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 50px;">
      <el-text class="mx-1" size="large">包厢订单</el-text>
      <el-divider />
      <el-table
        :data="data.boxsTable"
        style="width: 96%; margin-left: 2%;margin-top: 10px;"
        :highlight-current-row="true"
        :default-expand-all="false"
        v-loading.fullscreen.lock="data.loading"
      >
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="结账单号" prop="billCode" align="center" width="200" />
        <el-table-column label="订单号" prop="code" align="center" width="200" />
        <el-table-column label="消费项目" prop="schemeName" align="center" width="200" />
        <el-table-column label="包厢号" prop="roomName" align="center" />
        <el-table-column label="会员" align="center" width="200">
          <template #default="scope">
            <div v-if="scope.row.userCustomerInfo && scope.row.userCustomerInfo.length !== 0">
              <p v-for="(item, index) in scope.row.userCustomerInfo" :key="index">
                {{ item.customerName }}-{{ item.telephone }}
              </p>
            </div>
            <div v-else>
              暂无会员
            </div>
          </template>
        </el-table-column>
        <el-table-column label="消费时段" prop="spec" align="center" width="300">
          <template #default="{row }"> {{ row.originalStartTime }}~{{ row.originalEndTime }} </template>
        </el-table-column>
        <el-table-column label="订单金额(元)" prop="receivableAmount" align="center" />
        <el-table-column label="下单时间" prop="createTime" align="center" width="150" />
        <el-table-column label="支付状态" align="center">
          <template #default="scope">
            {{ data.orderStatusList[scope.row.orderStatus] }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="支付方式" prop="methodName" align="center">
          <template #default="scope">
            <div v-if="scope.row.payMethodVOList && scope.row.payMethodVOList.length !== 0">
              <p v-for="(item, index) in scope.row.payMethodVOList" :key="index">
                {{ item.methodName }}
              </p>
            </div>
            <div v-else>
              暂无支付方式
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
    <div style="margin-top: 50px;">
      <el-text class="mx-1" size="large">商品订单</el-text>
      <el-divider />
      <el-table :data="data.goodsTable" style="width: 96%; margin-left: 2%;margin-top: 10px;" class="table-content">
        <el-table-column type="expand">
          <template #default="props">
            <el-table :data="props.row.reOrderGoodsItemsVOList" style="width: 100%">
              <el-table-column label="商品图片" width="100" align="center">
                <template #default="scope">
                  <div style="display: flex; justify-content: center;">
                    <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="商品名称" prop="productName" align="center" />
              <el-table-column label="商品单价(元)" prop="retailPrice" align="center" />
              <el-table-column label="数量" prop="count" align="center" />
              <el-table-column label="小计(元)" prop="actualAmount" align="center" />
            </el-table>
          </template>
        </el-table-column>
        <el-table-column label="序号" align="center" type="index"></el-table-column>
        <el-table-column label="结账单号" prop="billCode" align="center" width="200" />
        <el-table-column label="订单号" prop="code" align="center" width="200" />
        <el-table-column label="包厢号" prop="roomName" align="center" />
        <el-table-column label="会员" prop="spec" align="center" width="200">
          <template #default="scope">
            <div v-if="scope.row.userCustomerInfo && scope.row.userCustomerInfo.length !== 0">
              <p v-for="(item, index) in scope.row.userCustomerInfo" :key="index">
                {{ item.customerName }}-{{ item.telephone }}
              </p>
            </div>
            <div v-else>
              暂无会员
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单金额(元)" prop="receivableAmount" align="center" />
        <el-table-column label="下单时间" prop="createTime" align="center" width="150" />
        <el-table-column label="支付状态" align="center">
          <template #default="scope">
            {{ data.orderStatusList[scope.row.orderStatus] }}
          </template>
        </el-table-column>
        <!-- <el-table-column label="支付方式" prop="methodName" align="center">
          <template #default="scope">
            <div v-if="scope.row.payMethodVOList && scope.row.payMethodVOList.length !== 0">
              <p v-for="(item, index) in scope.row.payMethodVOList" :key="index">
                {{ item.methodName }}
              </p>
            </div>
            <div v-else>
              暂无支付方式
            </div>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
import { ref, reactive, defineComponent, computed, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  name: 'AddShop',
  props: {
    dialogVisible: Boolean,
    rowMsg: Object,
  },
  setup(props, ctx) {
    const data = reactive({
      respData: {},
      boxsTable: [],
      boxInfo: {},
      goodsTable: [],
      loading: false,
      payStatusList: {
        0: '未支付',
        1: '支付完成',
        2: '部分支付',
      },
      orderStatusList: {
        1: '已结',
        2: '未结',
        3: '已退',
        4: '取消',
        5: '部分退单',
      },
    })

    onMounted(() => {
      orderOpenDetails()
    })

    const orderOpenDetails = () => {
      data.loading = true
      API.orderOpenDetails(props.rowMsg)
        .then(res => {
          if (res.code === '200') {
            data.respData = res.data
            data.boxInfo = data.respData.reOrderOpenBaseInfoVO || {}
            data.boxsTable = data.respData.reOrderOpenRoomInfoVO?.reOrderRoomVoList || []
            data.goodsTable = data.respData.reOrderOpenGoodsInfoVO?.reOrderGoodsVOList || []
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => {
          data.loading = false
        })
    }

    const visible = computed(() => {
      return props.dialogVisible
    })
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      handleClose,
      visible,
      data,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
