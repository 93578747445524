<template>
  <el-dialog v-model="dialogVisible" title="组合版本管理" width="70%">
    <div class="version_contanier">
      <el-card class="combine" :body-style="{ padding: '0px' }">
        <img
          src="https://shadow.elemecdn.com/app/element/hamburger.9cf7b091-55e9-11e9-a976-7f4d0b07eef6.png"
          class="image"
        />
        <div style="padding: 14px">
          <span>Yummy hamburger</span>
          <div class="bottom">
            <time class="time">{{ currentDate }}</time>
            <el-button type="text" class="button">Operating</el-button>
          </div>
        </div>
      </el-card>
    </div>
  </el-dialog>
</template>

<script>
import MerchantAPI from '../service/merchant.js'
export default {
  name: 'combine_versin',
  props: {},
  components: {},
  data() {
    return {
      dialogVisible: false,
    }
  },

  computed: {},
  watch: {},
  mounted() {},
  methods: {
    openCombineVersion() {
      this.dialogVisible = true
    },
  },
}
</script>

<style scoped lang="scss">
.combine {
  width: 180px;
  height: 240px;
}
</style>
