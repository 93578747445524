<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:35:19
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-11-21 14:50:30
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/addSupplierLeft.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="addSupplier-form-content">
    <el-form
      :model="form"
      label-width="80px"
      ref="ruleFormRef"
      class="form-content"
      label-position="left"
      :rules="rules"
      :hide-required-asterisk="true"
    >
      <div :class="isModal ? 'form-isModal' : ''">
        <span class="form-title">供应商信息</span>
        <el-form-item label="供应商名称" prop="supplierName">
          <el-input v-model="form.supplierName" />
        </el-form-item>
        <el-form-item label="供应商简称">
          <el-input v-model="form.supplierShortName" />
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input v-model="form.contacts" />
        </el-form-item>
        <el-form-item label="手机号" prop="cellphone">
          <el-input v-model="form.cellphone" />
        </el-form-item>
        <span class="form-title">银行账户信息备份</span>
        <el-radio-group v-model="form.paymentAccountType" class="account-info">
          <el-radio :label="1">公户</el-radio>
          <el-radio :label="2">私户</el-radio>
        </el-radio-group>
        <el-form-item label="开户名称" prop="accountname">
          <el-input v-model="form.accountname" />
        </el-form-item>
        <el-form-item label="开户银行" prop="bankname">
          <el-input v-model="form.bankname" />
        </el-form-item>
        <el-form-item label="开户行地址" prop="address">
          <el-input v-model="form.address" />
        </el-form-item>
        <el-form-item label="卡号" prop="account">
          <el-input v-model="form.account" />
        </el-form-item>
        <p class="certificate-photo"><span>证件图片</span>（800*800比例，文件大小不超过**k）</p>
        <span class="certificate-hint">请上传您的营业执照、食品流通许可证和检疫证明</span>
        <ul class="upload-imgList">
          <li v-for="(item, index) in certificateDictList">
            <el-form-item label-width="0px">
              <el-upload
                list-type="picture"
                :action="baseUrl + '/adminChain/supplier/uploadFile'"
                :headers="uploadHeaders"
                :on-success="
                  function(response) {
                    return handleBusinessPic(response, index)
                  }
                "
                :show-file-list="false"
                class="avatar-uploader"
              >
                <img v-if="item.src" :src="item.src" class="avatar" />
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
              <p>{{ item.name }}</p>
            </el-form-item>
          </li>
        </ul>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="form.remark" />
        </el-form-item>
      </div>
      <el-button type="primary" @click="editSupplier" v-if="isModal" class="confirm-btn">确认</el-button>
    </el-form>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import Config from '@/config'
import api from '../../service/index'
import _ from 'lodash'
let reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/
const checkTel = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入手机号码'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号码'))
  } else {
    callback()
  }
}
export default {
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    certificatePics: {
      type: Function,
      default: () => {},
    },
    baseInfo: {
      type: Function,
      default: () => {},
    },
    bankInfo: {
      type: Function,
      default: () => {},
    },
    code: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      form: {
        account: '',
        accountname: '',
        address: '',
        bankname: '',
        cellphone: '',
        certificatePics: '',
        contacts: '',
        freight: 0,
        miniPrice: 0,
        paymentAccountType: 1,
        remark: '',
        supplierName: '',
        supplierShortName: '',
      },
      baseUrl: Config.baseURL || '',
      uploadHeaders: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      },
      rules: {
        cellphone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: checkTel, trigger: 'blur' },
        ],
        contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        account: [{ required: true, message: '请输入卡号', trigger: 'blur' }],
        bankname: [{ required: true, message: '请输入开户银行', trigger: 'blur' }],
        address: [{ required: true, message: '请输入开户行地址', trigger: 'blur' }],
        remark: [{ required: true, message: '请输入备注', trigger: 'blur' }],
        supplierName: [{ required: true, message: '请输入供应商名称', trigger: 'blur' }],
        accountname: [{ required: true, message: '请输入开户名称', trigger: 'blur' }],
      },
      certificateDictList: [],
    }
  },
  mounted() {
    if (!this.isModal) {
      this.getCertificateDictList()
    }
    if (this.isModal) {
      this.editInfo()
    }
  },
  methods: {
    getCertificateDictList() {
      api.getCertificateDictList().then(res => {
        this.certificateDictList = res.data
        this.certificateDictList.map(item => {
          item.src = ''
          return item
        })
      })
    },
    editInfo() {
      this.form.account = this.baseInfo.accountInfo.account
      this.form.address = this.baseInfo.accountInfo.address
      this.form.bankname = this.baseInfo.accountInfo.bankname
      this.form.accountname = this.baseInfo.accountInfo.accountname
      const { cellphone, contacts, supplierName, remark, paymentAccountType } = this.baseInfo
      this.form = { ...this.form, cellphone, contacts, supplierName, remark, paymentAccountType }
      this.certificateDictList = _.cloneDeep(this.baseInfo.certificatePics) //深拷贝一份，以免影响值
    },
    handleBusinessPic(response, index) {
      if (response.code == '200') {
        this.certificateDictList[index].src = response.data.path
      }
    },
    submitForm() {
      this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          this.$emit('validateForm', this.form, this.certificateDictList)
        } else {
        }
      })
    },
    editSupplier() {
      this.$refs.ruleFormRef.validate((valid, fields) => {
        if (valid) {
          let param = {
            code: this.code,
            accountInfo: {
              account: this.form.account,
              accountname: this.form.accountname,
              address: this.form.address,
              bankname: this.form.bankname,
            },
            cellphone: this.form.cellphone,
            certificatePics: this.certificateDictList,
            contacts: this.form.contacts,
            freight: 0,
            miniPrice: 0,
            paymentAccountType: this.form.paymentAccountType,
            remark: this.form.remark,
            supplierName: this.form.supplierName,
            supplierShortName: this.form.supplierShortName,
          }
          api.updateSupplier(param).then(res => {
            console.log('res :>> ', res)
            if (res.code === '200') {
              ElMessage({
                type: 'success',
                message: `修改供应商成功`,
              })
              this.$router.go(-1)
            }
          })
        } else {
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.addSupplier-form-content {
  .form-content {
    position: relative;
    height: 100% !important;

    .confirm-btn {
      position: absolute;
      right: 0px;
      width: 121px;
      height: 40px;
      background: #1989fa;
      border-radius: 4px;
    }

    .form-isModal {
      height: calc(100vh - 200px);
      overflow-y: auto;
    }

    ::v-deep .el-form-item {
      margin-bottom: 0px !important;
    }

    .form-title {
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      margin-bottom: 20px;
    }

    .addSupplier-btn {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }

    .certificate-photo {
      span {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
      }
    }

    .certificate-hint {
      display: block;
      font-size: 12px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ff4242;
      margin-bottom: 20px;
    }
  }

  .account-info {
    margin-bottom: 20px;
  }

  ::v-deep .el-upload {
    width: 130px;
    height: 130px;
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 130px;
      width: 130px;
      line-height: 130px;
      text-align: center;
    }
  }

  .certificate-photo {
    margin-bottom: 6px;

    span {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
    }
  }

  .certificate-hint {
    display: block;
    font-size: 12px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #ff4242;
    margin-bottom: 20px;
  }

  .upload-imgList {
    li {
      display: inline-block;
      margin-right: 20px;

      p {
        text-align: center;
      }
    }
  }
}
</style>
