<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-09-11 15:15:22
 * @FilePath: /saas-pc/src/plugin/push-money/view/cardMoney.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportPaymentOrderDay" reportName="日支付账单"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportPaymentOrderDay"
      :emptyText="'暂无数据'"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../component/base/searchbar/index.vue'
import Table from '../component/table.vue'
import api from '../service/api'
import { downLoad } from '../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        type: '',
        orderCode: '',
        salesName: '',
        telephone: '',
        userName: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'storeCode',
          label: '交易门店编号',
          width: '120',
        },
        {
          prop: 'storeName',
          label: '交易门店',
          width: '150',
        },
        {
          prop: 'openStoreCode',
          label: '开卡门店',
        },
        {
          prop: 'orderCode',
          label: '订单号',
          width: '200',
        },
        {
          prop: 'source',
          label: '订单来源',
          width: '150',
        },
        {
          prop: 'type',
          label: '储值卡类型',
          width: '130',
        },
        {
          prop: 'userCode',
          label: '会员卡号',
          width: '200',
        },
        {
          prop: 'userName',
          label: '会员姓名',
          width: '130',
        },
        {
          prop: 'telephone',
          label: '会员电话',
          width: '150',
        },

        {
          prop: 'roomCode',
          label: '储值包厢',
          width: '150',
        },
        {
          prop: 'receivableAmount',
          label: '应收金额',
          width: '150',
        },
        {
          prop: 'actualAmount',
          label: '实收金额',
          width: '150',
        },
        {
          prop: 'netReceiptsAmount',
          label: '净收金额',
          width: '150',
        },
        {
          prop: 'presentMoney',
          label: '赠送金额',
          width: '150',
        },
        {
          prop: 'payType',
          label: '支付方式',
          width: '150',
        },
        {
          prop: 'payPrice',
          label: '支付金额',
          width: '150',
        },

        {
          prop: 'rate',
          label: '提成比例',
          width: '150',
          formatter: val => {
            return val.rate ? val.rate + '%' : ''
          },
        },
        {
          prop: 'amount',
          label: '提成总额',
          width: '150',
        },
        {
          prop: 'staffAmount',
          label: '员工提成金额（60%）',
          width: '180',
        },
        {
          prop: 'storeAmount',
          label: '门店提成金额（40%）',
          width: '180',
        },
        {
          prop: 'salesperson',
          label: '销售员',
        },
        {
          prop: 'salesPhone',
          label: '销售员电话',
          width: '150',
        },
        {
          prop: 'orderCreatedTime',
          label: '订单生成时间',
          width: '200',
        },
        {
          prop: 'payTime',
          label: '支付时间',
          width: '200',
        },
      ],
      searchform: [
        {
          label: '营业日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodes',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },

        { label: '会员电话', type: 'input', prop: 'telephone', placeholder: '请输入会员电话' },
        {
          label: '会员姓名',
          type: 'input',
          labelWidth: '120px',
          prop: 'userName',
          placeholder: '请输入会员姓名',
        },
        {
          label: '订单号',
          type: 'input',
          labelWidth: '120px',
          prop: 'orderCode',
          placeholder: '请输入订单号',
        },
        {
          label: '销售员',
          type: 'input',
          labelWidth: '120px',
          prop: 'salesName',
          placeholder: '请输入销售员',
        },
        {
          label: '储值卡类型',
          placeholder: '请选择储值卡类型',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'type',
          options: [
            { label: '通用储值卡', value: 1 },
            { value: 2, label: '门店储值卡' },
          ],
          filterable: true,
        },
        { label: '导出', type: 'exportButton', url: '/report/commission/exportSalesCommissionDetail', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[1].options = res.data
      })
    },
    reportPaymentOrderDay(model) {
      this.mergeTotalData(model.pageNum, '储值卡提成', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return Promise.reject('条件不满足')
      }
    },
    parentSearch(param) {
      const { orderCode, salesName, telephone, type, userName, storeCodes, regionCodeList } = param
      this.model = { orderCode, salesName, telephone, type, userName, storeCodes, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      this.mergeTotalData(this.model.pageNum, '储值卡提成', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }

      const { orderCode, salesName, telephone, type, userName, storeCodes, regionCodeList } = param
      this.model = { orderCode, salesName, telephone, type, userName, storeCodes, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      if (!this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      this.exportData('储值卡提成', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
