<template>
  <div class="container">
    <el-row>
      <el-col :span="treespan">
        <xjh-zztree ref="zzjgtree" @treenodechage="changenode" @ViewData="Viewmdlist"></xjh-zztree>
      </el-col>

      <el-col style="padding: 0px 0px 0px 50px;position:relative;" :span="24 - treespan">
        <i
          @click="collapse"
          style="color:#577DF4"
          :class="['iconfont', 'collapsemenu', treespan ? 'el-icon-caret-left' : 'el-icon-caret-right']"
        ></i>
        <el-row style="line-height: 60px">
          <el-col :span="8">
            <el-input
              @clear="getMdList()"
              :clearable="true"
              style="width: 250px"
              v-model="listQuery.name"
              placeholder="请输入店铺名称"
            ></el-input>
          </el-col>
          <el-col style="text-align: right" :span="16">
            <el-button
              icon="el-icon-plus"
              @click="opendrawerform"
              v-if="mark == 1"
              style="background: #34bfa3; color: #ffffff; margin-right: 20px"
              >新增{{ areaname.length > 20 ? areaname.substr(0, 20) + '...' : areaname }}门店</el-button
            >
          </el-col>
        </el-row>
        <Table
          :tableData="tableData"
          :tableHead="tableHead"
          :isUseSlot="false"
          :checkbox="showcheck"
          :page="listQuery.page"
          :showIndex="false"
          :limit="listQuery.limit"
          v-loading="listLoading"
          @selectionchange="selectmds"
          :slotKeys="['characterType', 'sizeType', 'zhpf']"
        >
          <template #characterType="{ data }">
            {{ chartypecom(data.row.characterType) }}
          </template>
          <template #zhpf="{ data }">
            {{ data.row.zhpf }}
          </template>

          <template #sizeType="{ data }">
            {{ sizeTypecom(data.row.characterType) }}
          </template>

          <template #operate="{ data }">
            <el-button
              type="text"
              style="color: rgb(52, 191, 163); padding: 0 3px"
              icon="el-icon-search"
              @click="viewcomment(data)"
              >评论</el-button
            >
            <el-button type="text" style="padding: 0 3px" icon="el-icon-edit" @click="handleEdit(data)">编辑</el-button>
            <el-button type="text" style="padding: 0 3px; color: red" icon="el-icon-delete" @click="deleteMd(data)"
              >删除</el-button
            >
          </template>
        </Table>
        <el-pagination
          @current-change="handleCurrentChange"
          style="margin-top: 30px"
          background
          v-show="listQuery.total > 0"
          layout="total,prev, pager, next"
          :total="listQuery.total"
        >
        </el-pagination>
      </el-col>
    </el-row>
    <Drawerform :title="drawertitle" @Submit="SaveData" :formList="rowdata" ref="formDelg"></Drawerform>
    <Drawerform :submitshow="false" :title="commentTitle" :isDisabled="true" direction="rtl" ref="commentDelg">
      <template #content="">
        <div class="comment_intro">
          <span><b>总评论数:</b>{{ commentstotal }}</span>
          <span style="margin-left:40px;"
            ><b>综合评分:</b>
            <el-rate
              v-model="currentcomment.row.zhpf"
              disabled
              show-score
              text-color="#ff9900"
              score-template="{value}"
            >
            </el-rate>
          </span>
        </div>
        <ul class="infinite-comment-list" v-infinite-scroll="loadcomment" style="overflow: auto">
          <li v-for="com in comments" :key="com.id" class="infinite-list-item">
            <div class="zhpf">
              {{ com.zhpf }}{{ com.userName ? com.userName : '暂无' }}
              <el-rate v-model="com.star" disabled show-score text-color="#ff9900" score-template="{value}"> </el-rate>
            </div>
            <div style="margin:10px 0px 0px 0px;line-height:25px;font-size:12px;">
              {{ com.comment ? com.comment : '暂无' }}
            </div>
            <div>
              <el-image
                class="image_contanier"
                v-for="pic in com.picUrls"
                :preview-src-list="com.picUrls"
                :src="pic"
                :key="pic"
                lazy
              >
                <template #error>
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </template>
              </el-image>
            </div>
            <div class="bottomli">
              <span>查看次数:{{ com.readCnt }}</span
              ><span>评论时间:{{ com.commentTime }}</span>
            </div>
          </li>
          <div v-if="commentsnew.length == 0" class="tips">
            ～我也是有底线的～
          </div>
        </ul>
      </template>
    </Drawerform>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref, onMounted, nextTick, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { ElMessageBox } from 'element-plus'
import { XjhZztree } from '../../component/xjhcomponent'
import { Table, Drawerform } from '@/component/base'
import RemoteSelect from '../../component/remoteselect/index.vue'
import { convertTime } from '@/utils/Util.js'
import tablehead from './model.js'
import XjhMdManager from '../../model/mdmanager.js'

export default defineComponent({
  components: {
    XjhZztree,
    Table,
    Drawerform,
    RemoteSelect,
  },

  setup(props, context) {
    const listLoading = ref(false)
    const currentcomment = ref({})
    const showcheck = ref(false)
    const fenpeitext = ref('门店分配')
    const drawertitle = ref('新增门店')
    const count = ref(0)
    const treespan = ref(5)
    const commentTitle = ref('')
    const commentpage = ref(0)
    const comments = ref([])
    let commentstotal = ref(0)

    let areaname = ref('全国')
    let mark = ref(1)

    const listQuery = reactive({
      page: 1,
      limit: 10,
      name: '',
      mark: 2,
      geoId: 1,
      total: 0,
    })
    const formDelg = ref()
    const commentDelg = ref()
    const areaselect = ref()
    const commentsnew = ref([])
    const zzjgtree = ref()
    const mergeids = ref([])
    let rowdata = ref({})
    let tableHead = ref(tablehead)
    let tableData = ref([])
    const options = ref('')
    rowdata.value = tableHead.value

    const opendrawerform = () => {
      drawertitle.value = '新增门店'
      formDelg.value.openDrawer()
      formDelg.value.setFormFields(false)
    }

    const handleEdit = data => {
      drawertitle.value = '编辑门店'
      formDelg.value.openDrawer()

      rowdata.value = Object.assign(tableHead.value, data)
      formDelg.value.setFormFields(true)
    }

    const multichery = () => {
      showcheck.value = !showcheck.value
      if (showcheck.value) {
        fenpeitext.value = '取消门店分配'
      } else {
        fenpeitext.value = '门店分配'
      }
    }

    const selectmds = selection => {
      selection.map(md => {
        mergeids.value.push(md.id)
      })
    }
    // watch(listQuery, () => {
    //   alert(2);
    //   getMdList()
    // })

    const changenode = name => {
      areaname.value = name
    }

    const getMdList = async () => {
      listLoading.value = true
      const res = await XjhMdManager.getStoreList(listQuery)
      if (res.code == 200) {
        listQuery.total = res.result.totalCount
        res.result.list.map((item, index) => {
          // item.storend=areaname.value+'第'+(listQuery.limit*(listQuery.page-1)+index+1)+'家门店'
          item.zhpf = item.mtScore ? item.mtScore / 10 : 0
          return item
        })
        tableData.value = res.result.list
        listLoading.value = false
      }
    }

    const sizeTypecom = computed(() => {
      return value => {
        switch (value) {
          case 1:
            return 'MINI店'
          case 2:
            return 'MAX店'
        }
      }
    })

    const handleCurrentChange = page => {
      listQuery.page = page
      // console.log(page)
      getMdList()
    }

    const chartypecom = computed(() => {
      return value => {
        switch (value) {
          case 1:
            return '加盟'
          case 2:
            return '直营'
          case 3:
            return '直营(合作)'
        }
      }
    })

    const collapse = () => {
      if (treespan.value > 0) {
        treespan.value = 0
      } else {
        treespan.value = 5
      }
    }

    const deleteMd = data => {
      ElMessageBox.confirm('此操作将永久删除该门店, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const res = await XjhMdManager.deleteStore({
          id: data.row.id,
        })
        if (res.code == 200) {
          ElMessage.success({
            message: '删除门店成功！',
            type: 'success',
          })
          // zzjgtree.value.removechild(data.row.id)
          zzjgtree.value.refreshtree()
          getMdList()
        }
      })
    }

    const closedrawer = () => {
      formDelg.value.closeDialog()
    }

    const SaveData = async form => {
      let res
      // if(form.createTime){
      //   form.createTime=convertTime(form.createTime)
      // }
      if (form.id) {
        res = await XjhMdManager.updateStore(form)
      } else {
        form.geoId = listQuery.geoId
        res = await XjhMdManager.addStore(form)
      }
      if (res.code == 200) {
        getMdList()
        ElMessage.success({
          message: form.id ? '恭喜你，更新门店成功' : '恭喜你，创建门店成功',
          type: 'success',
        })
        closedrawer()
        zzjgtree.value.refreshtree()
      } else {
        getMdList()
        ElMessage.error(res.message)
      }
    }

    const loadcomment = async () => {
      // setTimeout(async()=>{
      commentpage.value++
      const res = await XjhMdManager.getStoreCommentList({
        mdid: currentcomment.value.row.mtbh,
        page: commentpage.value,
      })
      if (res.code == 200) {
        res.result.list.map(com => {
          if (com.picUrls) {
            com.picUrls = com.picUrls.split(',')
            console.log(com.picUrls)
          }

          com.star = com.star / 10
          return com
        })
        commentsnew.value = res.result.list
        comments.value = comments.value.concat(res.result.list)
        commentstotal.value = res.result.totalCount
      }
    }

    const viewcomment = data => {
      currentcomment.value = data
      comments.value = []
      commentpage.value = 0
      commentstotal.value = 0
      loadcomment()
      commentTitle.value = data.row.fullName
      commentDelg.value.openDrawer()
    }

    const Viewmdlist = node => {
      listQuery.geoId = node.id
      areaname.value = node.name
      mark.value = node.mark
      getMdList()
    }

    onMounted(() => {
      getMdList()
    })

    return {
      count,
      listLoading,
      treespan,
      listQuery,
      tableHead,
      tableData,
      formDelg,
      commentDelg,
      areaselect,
      showcheck,
      chartypecom,
      currentcomment,
      commentstotal,
      fenpeitext,
      mark,
      rowdata,
      drawertitle,
      commentTitle,
      sizeTypecom,
      options,
      zzjgtree,
      areaname,
      comments,
      commentsnew,
      changenode,
      Viewmdlist,
      handleEdit,
      SaveData,
      loadcomment,
      deleteMd,
      collapse,
      selectmds,
      getMdList,
      opendrawerform,
      multichery,
      viewcomment,
      handleCurrentChange,
    }
  },
})
</script>

<style lang="scss" scoped>
.container {
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    // background-color: #4c70e2 !important;
  }
  .tips {
    color: gray;
    text-align: center;
    position: relative;
    top: 15px;
  }
  .collapsemenu {
    position: absolute;
    top: 50%;
    font-size: 24px;
    left: 20px;
    z-index: 100;
  }
  .title {
    height: 59px;
    line-height: 59px;
    color: $parent-title-color;
    font-size: 16px;
    font-weight: 500;
    text-indent: 40px;
    border-bottom: 1px solid #dae1ec;

    .back {
      float: right;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .comment_intro {
    padding: 20px;
  }

  ::v-deep .el-rate {
    display: inline-block;
  }

  .infinite-comment-list {
    list-style: none;
    height: calc(100vh - 120px);
    padding: 20px;
    margin: 0;
    li {
      padding: 10px;
      border-bottom: 1px solid #dedede;
      .image_contanier {
        width: 210px;
        border-radius: 5px;
        height: 140px;
        margin: 5px;
      }
      .bottomli {
        display: flex;
        font-size: 14px;
        margin-top: 5px;
        color: gray;
        justify-content: space-between;
      }
    }
  }

  .wrap {
    padding: 20px;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #ffbe4d;
  }

  .submit {
    float: left;
  }
}
</style>
