<template>
  <div style="margin:40px;">
    <el-upload class="avatar-uploader" action="https://jsonplaceholder.typicode.com/posts/" :show-file-list="false">
      <img v-if="imageUrl" :src="imageUrl" class="avatar" />
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
  </div>
</template>

<script>
export default {
  name: 'uploader',
  components: {},
  data() {
    return {}
  },
  props: {
    imgsuploads: {
      type: Array,
      default: [],
    },
  },

  computed: {},
  watch: {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.avatar-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 178px;
      height: 178px;
      line-height: 178px;
      text-align: center;
    }
  }
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
