<template>
  <div class="shortLinkManager">
    <div class="flex">
      <div class="search">
        <div>链接名称:</div>
        <el-input v-model="name" style="width: 200px;margin: 0 10px;" clearable></el-input>
        <el-button type="primary" @click="handleSearch">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
      </div>
      <el-button @click="handleEdit(0)">新增</el-button>
    </div>
    <el-table :data="tableData" style="width: 100%;margin-top: 20px;">
      <template v-for="item in tableHead" :key="item.props">
        <el-table-column :prop="item.props" :label="item.label" :width="item.width" align="center">
          <template #default="{ row }">
            <div v-if="item.props">{{ row[item.props] }}</div>
            <div v-if="item.props == 'button'" class="setting">
              <div v-if="row.status !== '已作废'" @click="handleEdit(1, row)">编辑</div>
              <div v-if="row.status !== '已作废'" @click="handleEdit(2, row)">详情</div>
              <div v-if="row.status !== '已作废'" @click="handleEdit(3, row)">作废</div>
              <div v-if="row.status == '已作废'" @click="handleEdit(4, row)">启用</div>
            </div>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <Pagination :pagination="pagination" @handleChange="handleChangePage"></Pagination>
    <el-dialog v-model="dialogVisible" :title="title" width="500" :destroy-on-close="true" :before-close="handleClose">
      <el-form
        ref="ruleFormRef"
        style="max-width: 600px"
        :model="formData"
        :rules="rules"
        label-width="200px"
        label-position="right"
        class="demo-ruleForm"
        :size="formSize"
        status-icon
      >
        <el-form-item label="链接名称" prop="name">
          <el-input v-model="formData.name" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="关联页面" prop="path">
          <el-input v-model="formData.path" :disabled="isDisabled" />
        </el-form-item>
        <el-form-item label="页面参数" prop="content">
          <el-input v-model="formData.content" :disabled="isDisabled" />
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="handleClose">取消</el-button>
          <el-button type="primary" @click="handleSubmit()">
            保存
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '@/plugin/cms-manager/service/api.js'
import Pagination from '@/component/base/Pagination1/index'
export default {
  components: { Pagination },
  data() {
    return {
      name: '',
      title: '新增链接',
      dialogVisible: false,
      formData: {},
      tableData: [],
      tableHead: [
        {
          props: 'name',
          label: '链接名称',
        },
        {
          props: 'path',
          label: '关联页面',
        },
        {
          props: 'content',
          label: '页面参数',
        },
        {
          props: 'code',
          label: '短链接',
        },
        {
          props: 'status',
          label: '状态',
        },
        {
          props: 'button',
          label: '操作',
        },
      ],
      rules: {
        name: [{ required: true, message: '请输入链接名称', trigger: 'blur' }],
        path: [{ required: true, message: '请输入关联页面', trigger: 'blur' }],
      },
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        pageCount: 5,
        pageSizes: [10, 20, 30, 40, 50],
      },
    }
  },
  mounted() {
    this.queryList()
  },
  methods: {
    // 查询
    handleSearch: _.throttle(function() {
      this.pagination.pageNum = 1
      this.queryList()
    }, 1000),
    // 重置
    handleReset: _.throttle(function() {
      this.name = ''
      this.pagination.pageNum = 1
      this.queryList()
    }, 1000),
    queryList() {
      const { pageNum, pageSize } = this.pagination
      let params = { pageNum, pageSize }
      params.criteria = { name: this.name }
      API.queryLink(params).then(res => {
        if (res.code == '200') {
          this.tableData = res?.data?.items || []
          this.pagination.total = res?.data?.total || 0
        } else {
          ElMessage.error(res.msg)
        }
      })
    },
    handleChangePage(pages) {
      Object.assign(this.pagination, pages)
      this.queryList()
    },
    handleClose() {
      this.dialogVisible = false
      this.$refs.ruleFormRef.resetFields()
      this.formData = {}
    },
    async handleSubmit() {
      if (this.title == '链接详情') {
        this.dialogVisible = false
        return
      }
      // title: '新增链接' || '编辑链接'
      await this.$refs.ruleFormRef.validate(async (valid, fields) => {
        if (valid) {
          let res
          const params = { ...this.formData }
          if (this.title == '新增链接') {
            res = await API.addLink(params)
          } else {
            res = await API.updateLink(params)
          }
          if (res.code == '200') {
            this.dialogVisible = false
            this.pagination.pageNum = 1
            if (this.title == '新增链接') {
              ElMessage.success('新增成功')
            } else {
              ElMessage.success('更新成功')
            }
            this.queryList()
          } else {
            ElMessage.error(res.msg)
          }
        } else {
          console.log('error submit!', fields)
        }
      })
    },
    async handleEdit(val, row) {
      if (val == 3 || val == 4) {
        ElMessageBox.confirm(`确认${val == 3 ? '作废' : '启用'}该链接？`, '操作确认', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(async () => {
            const parmas = {
              code: row.code.split('/')[1],
              status: row.status == '正常' ? 1 : 0,
            }
            const res = await API.updateStatus(parmas)
            if (res.code == '200') {
              this.queryList()
              ElMessage.success(`${val == 3 ? '作废成功' : '启用成功'}`)
            }
          })
          .catch(() => {
            ElMessage.info(`${val == 3 ? '作废取消' : '启用取消'}`)
          })
      } else {
        const enmu = {
          0: '新增链接',
          1: '编辑链接',
          2: '链接详情',
        }
        this.title = enmu[val]
        this.dialogVisible = true
        if (val == 0) {
          this.formData = {}
          return
        }
        const { name, path, content, code } = row
        this.formData = { name, path, content, code: code.split('/')[1] }
      }
    },
  },
  computed: {
    isDisabled() {
      if (this.title == '链接详情') {
        return true
      } else {
        return false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.shortLinkManager {
  margin: 20px;
  .flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .el-button {
      width: 80px;
    }
  }
  .search {
    display: flex;
    align-items: center;
    .el-button {
      width: 80px;
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: center;
    .el-button {
      width: 200px;
    }
  }
  .setting {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #02a7f0;
  }
  .setting :nth-child(n + 1) {
    margin-right: 12px;
  }
}
</style>
