<template>
  <div class="searchheader">
    <el-form :inline="true" ref="formRef" label-width="80px" class="demo-ruleForm" label-position="left">
      <el-form-item
        v-for="(vi, i) of searchform"
        :key="i"
        label-position="left"
        :label="vi.label || ''"
        :prop="vi.prop"
        :inline="true"
        :label-width="vi.labelWidth"
      >
        <component
          v-if="vi.type !== 'radio-group' && vi.type"
          :is="`el-${vi.type}`"
          :placeholder="vi.placeholder"
          :type="vi.typeProp || 'text'"
          :multiple="vi.multiple"
          :size="vi.size"
          :autosize="{ minRows: 3, maxRows: 5 }"
          :clearable="!vi.unclearable"
          :active-text="vi.activeText"
          :props="vi.props || {}"
          :inactive-text="vi.inactiveText"
          :options="vi.options"
          :disabled="vi.disabled"
          :maxlength="vi.maxlength"
          :minlength="vi.minlength"
          :max="vi.max"
          :min="vi.min"
          :is-range="vi.isRange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :format="vi.dataFormate"
          :value-format="vi.dataFormate"
          :show-password="vi.showPassword"
          :show-word-limit="vi.showWordLimit"
          :active-value="vi.activeValue"
          :default-value="vi.defaultValue"
          :filterable="vi.filterable"
          :inactive-value="vi.inactiveValue"
          v-model="ruleForm[vi.prop]"
        >
          <template v-if="vi.children">
            <component
              :is="`el-${vi.children}`"
              v-for="(item, index) of vi.options"
              :key="`${i}_${ind}_${index}`"
              :value="item.value"
              :label="item.label"
            >
            </component>
          </template>
        </component>
        <el-radio-group v-if="vi.type === 'radio-group'" v-model="ruleForm[vi.prop]">
          <template v-for="(item, index) of vi.options">
            <component
              :is="`el-${vi.children}`"
              v-if="vi.children"
              :key="`${i}_${ind}_${index}`"
              :value="item.value"
              :label="item.label"
            >
            </component>
          </template>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" v-if="searchButton.isSearch">查询</el-button>
        <el-button type="" @click="resetForm" v-if="searchButton.isReset">重置</el-button>
        <el-button type="primary" @click="exportData" v-if="searchButton.isExport">导出</el-button>
      </el-form-item>
      <el-button type="primary" @click="add" style="float:right" v-if="searchButton.isAdd">新增</el-button>
    </el-form>
  </div>
</template>
<script>
import { reactive } from '@vue/reactivity'
import { ref, nextTick } from 'vue'

export default {
  props: {
    searchform: {
      type: Array,
      default: [],
    },
    searchButton: {
      type: Object,
      default: () => {
        return {
          isSearch: true,
          isReset: true,
          isExport: true,
          isAdd: true,
        }
      },
    },
  },
  data() {
    return {}
  },
  watch: {
    ruleForm() {
      this.$emit('childSearch', this.ruleForm)
    },
  },
  setup(props, { emit }) {
    let ruleForm = reactive({})
    props.searchform?.map(item => {
      if (item.type) {
        if (item.modelValue) {
          ruleForm[item.prop] = item.modelValue
        }
        if (item.fetch && typeof item.fetch === 'function') {
          item.options = item.fetch()
        }
      }
    })
    const formRef = ref(null)

    const modal = ref(false)
    const onSubmit = () => {
      //提交
      emit('childSearch', ruleForm)
    }
    const resetForm = () => {
      nextTick(() => {
        formRef?.value.resetFields()
        props.searchform?.map(item => {
          if (item.type) {
            ruleForm[item.prop] = ''
            if (item.fetch && typeof item.fetch === 'function') {
              item.options = item.fetch()
            }
          }
        })
        emit('childSearch', ruleForm)
      })
    }

    const exportData = () => {
      emit('childExport', true)
    }

    const add = () => {
      console.log('addData :>> ', 12)
      modal.value = true
      emit('add', modal.value)
    }
    return { ruleForm, onSubmit, resetForm, formRef, exportData, add, modal }
  },
}
</script>
<style lang="scss" scoped>
.searchheader {
  box-sizing: border-box;
  padding-top: 20px;
  // height: 65px;
  // line-height: 65px;
  width: 100%;
  display: block;

  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
