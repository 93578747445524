<!--
 * @Author: your name
 * @Date: 2022-04-19 17:20:09
 * @LastEditTime: 2022-11-24 10:43:01
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/component/tableSearchTab.vue
-->
<template>
  <div class="table-search-tab">
    <el-form :inline="true" :model="formModel" class="demo-form-inline" label-width="80px" label-position="left">
      <el-form-item label="门店选择">
        <el-select
          v-model="formModel.storeCode"
          filterable
          remote
          reserve-keyword
          placeholder="选择门店"
          clearable
          class="search-select"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option v-for="item in storeList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="商品分类" v-if="categoryType">
        <el-select
          v-model="formModel.type"
          filterable
          remote
          reserve-keyword
          placeholder="商品分类"
          clearable
          class="search-select"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option v-for="item in categoryList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称" v-if="categoryType">
        <el-input v-model="formModel.skuName" placeholder="商品名称" />
      </el-form-item>
      <el-form-item label="订单号">
        <el-input v-model="formModel.code" placeholder="订单号" />
      </el-form-item>
      <el-form-item label="包厢类型">
        <el-select
          v-model="formModel.roomTypeName"
          remote
          reserve-keyword
          placeholder="包厢类型"
          clearable
          class="search-select"
          :loading="loading"
        >
          <el-option v-for="item in roomTypeList" :key="item.name" :label="item.name" :value="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="包厢">
        <el-input v-model="formModel.roomName" placeholder="包厢" />
      </el-form-item>
      <el-form-item label="消费项目">
        <el-input v-model="formModel.comboCodeName" placeholder="消费项目" />
      </el-form-item>
      <el-form-item label="开台单号">
        <el-input v-model="formModel.orderOpenCode" placeholder="开台单号" />
      </el-form-item>
      <el-form-item label="单号">
        <el-input v-model="formModel.skuName" placeholder="单号" />
      </el-form-item>
      <el-form-item label="订单类型">
        <el-input v-model="formModel.skuName" placeholder="订单类型" />
      </el-form-item>
      <el-form-item label="订单号">
        <el-input v-model="formModel.skuName" placeholder="订单号" />
      </el-form-item>
      <el-form-item label="会员等级">
        <el-select
          v-model="formModel.type"
          remote
          reserve-keyword
          placeholder="会员等级"
          clearable
          class="search-select"
          :loading="loading"
        >
          <el-option v-for="item in levelList" :key="item.name" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="操作类型">
        <el-select
          v-model="formModel.operationType"
          remote
          reserve-keyword
          placeholder="操作类型"
          clearable
          class="search-select"
          :loading="loading"
        >
          <el-option v-for="item in channelList" :key="item.name" :label="item.name" :value="item.name" />
        </el-select>
      </el-form-item>
      <el-form-item label="网络支付流水号" label-width="120px">
        <el-input v-model="formModel.payCode" placeholder="网络支付流水号" />
      </el-form-item>
      <el-form-item label="支付方式">
        <el-select
          v-model="formModel.payType"
          remote
          reserve-keyword
          placeholder="支付方式"
          clearable
          class="search-select"
          :loading="loading"
        >
          <el-option v-for="item in paymentList" :key="item.name" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="支付状态">
        <el-select
          v-model="formModel.status"
          filterable
          remote
          reserve-keyword
          placeholder="支付状态"
          clearable
          class="search-select"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="是否退款">
        <el-select
          v-model="formModel.type"
          filterable
          remote
          reserve-keyword
          placeholder="是否退款"
          clearable
          class="search-select"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option v-for="item in refundorNot" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="供应商">
        <el-input v-model="formModel.supplierName" placeholder="供应商" />
      </el-form-item>
      <el-form-item label="入库类型">
        <el-select
          v-model="formModel.channelName"
          filterable
          remote
          reserve-keyword
          placeholder="入库类型"
          clearable
          class="search-select"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option v-for="item in storageList" :key="item.value" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="出库类型">
        <el-select
          v-model="formModel.channelName"
          filterable
          remote
          reserve-keyword
          placeholder="出库类型"
          clearable
          class="search-select"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option v-for="item in deliveryList" :key="item.value" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item label="日期" v-if="!['结存酒统计', '实时库存'].includes(reportName)">
        <el-date-picker
          v-model="formModel.queryDate"
          :type="reportName === '月支付账单' ? 'monthrange' : 'daterange'"
          start-placeholder="起始日期"
          :format="reportName === '月支付账单' ? 'YYYY-MM' : 'YYYY-MM-DD'"
          end-placeholder="结束日期"
          :default-value="defaultDate"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchTableData">查询</el-button>
        <el-button plain @click="reset">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import moment from 'moment'
import api from '../service/index'
export default {
  props: {
    getTableData: {
      type: Function,
      default: null,
    },
    model: {
      type: Object,
      default: {},
    },
    reportName: {
      tyoe: String,
      default: '',
    },
  },
  data() {
    return {
      formModel: {
        queryDate: '', //日期
        storeCode: '', //门店
        type: '', //商品分类
        skuName: '', //商品名称
        code: '', //订单号
        roomName: '', //包厢名称
        comboCodeName: '', //消费项目
        orderOpenCode: '', //开台单号
        operationType: '', //操作类型
        payCode: '', //网络支付流水号
        roomTypeName: '', //包厢类型
        supplierName: '', //供应商
        status: '', //支付状态
        channelName: '', //入库类型
      },
      storeList: [],
      defaultDate: '',
      categoryList: [],
      categoryList: [],
      refundorNot: [
        { label: '是', value: 1 },
        { value: 0, label: '否' },
      ], //是否退款
      roomTypeList: [], //包厢类型
      levelList: [],
      channelList: [],
      statusList: [
        { label: '支付成功', value: 1 },
        { value: 2, label: '支付失败' },
        { value: 3, label: '支付中' },
      ],
      paymentList: [],
      storageList: [], //入库列表
      deliveryList: [], //出库列表
      categoryType: false, //商品分类 商品名称显示
      orderCode: '', //订单号
    }
  },
  mounted() {
    if (
      ![
        '包厢收入明细',
        '账单明细',
        '办卡储值明细',
        '办卡储值统计',
        '会员卡消费明细',
        '会员卡消费统计',
        '总分店会员分账统计',
        '日支付账单',
        '月支付账单',
      ].includes(this.reportName)
    ) {
      this.categoryType = true
    }
    if (this.reportName === '存取酒统计') {
      this.formModel.queryDate = [this.model.startTime, this.model.endTime]
    }
    if (['月支付账单', '预过期酒统计'].includes(this.reportName)) {
      this.formModel.storeCode = this.model.storeCode
      this.formModel.queryDate = [this.model.startTime, this.model.endTime]
    }
    this.getStoreList()
  },
  methods: {
    getStoreList() {
      api.getStoreListByName({ storeName: this.storeCode }).then(res => {
        //门店接口
        this.storeList = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
      api.getCategoryList(2).then(res => {
        //商品分类接口
        this.categoryList = res.data.map(item => {
          return {
            value: item.code,
            label: item.name,
          }
        })
      })
      api.getStoreRoomTypeList2().then(res => {
        //包厢类型接口
        this.roomTypeList = res.data
      })
      api.getLevelList().then(res => {
        this.levelList = res.data
      })
      api.channelList().then(res => {
        this.channelList = res.data
      })
      api.paymentList().then(res => {
        this.paymentList = res.data
      })
      api.stockList(1).then(res => {
        this.storageList = res.data
      })
      api.stockList(2).then(res => {
        this.deliveryList = res.data
      })
    },
    searchTableData() {
      this.model.storeCode = this.formModel.storeCode
      this.model.pageNum = 1
      if (!['结存酒统计', '实时库存'].includes(this.reportName)) {
        this.model.startTime =
          this.formModel.queryDate && moment(this.formModel.queryDate[0]).format('YYYY-MM-DD HH:mm:ss')
        this.model.endTime =
          this.formModel.queryDate && moment(this.formModel.queryDate[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      if (this.reportName === '月支付账单') {
        this.model.startTime = this.formModel.queryDate && moment(this.formModel.queryDate[0]).format('YYYY-MM')
        this.model.endTime = this.formModel.queryDate && moment(this.formModel.queryDate[1]).format('YYYY-MM')
      }
      this.getTableData(this.model)
    },
    reset() {
      this.formModel.storeCode = ''
      this.formModel.queryDate = ''
      this.model.pageNum = 1
      switch (this.reportName) {
        case '预过期酒统计':
          this.model.endTime =
            moment()
              .add(7, 'days')
              .format('YYYY-MM-DD') + ' 00:00:00'
          this.model.startTime = moment(new Date()).format('YYYY-MM-DD') + ' 00:00:00'
          this.formModel.storeCode = this.model.storeCode
          this.formModel.queryDate = [this.model.startTime, this.model.endTime]
          this.model.storeCode = 'K003'
          break
        case '月支付账单':
          this.model.endTime = moment(new Date())
            .add(1, 'M')
            .format('YYYY-MM')
          this.model.startTime = moment(new Date()).format('YYYY-MM')
          this.formModel.storeCode = this.model.storeCode
          this.formModel.queryDate = [this.model.startTime, this.model.endTime]
          this.model.storeCode = 'K003'
          break
        case '实时库存':
          this.model.storeCode = ''
          break
        case '结存酒统计':
          this.model.storeCode = ''
          break
        default:
          this.model.startTime = ''
          this.model.endTime = ''
          this.model.storeCode = ''
          break
      }
      this.getTableData(this.model)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  box-sizing: border-box;
  padding-top: 20px;
  // display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;

  .search-left {
    width: 60%;

    .search-btn {
      margin-left: 20px;
    }

    .input-with-select {
      width: 340px;
      margin-left: 24px;
      margin-right: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }
}
</style>
