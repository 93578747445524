/*
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-09-09 10:18:34
 * @LastEditTime: 2024-10-30 09:56:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/mixins/splitTotalRowData.js
 * @Description:
 */

//表单类型枚举
const TYPE_ENUMS = {
  商品销售统计: 'COMMODITY_SALE_STATISTICS',
  套餐销售统计: 'COMMODITY_COMBO_SALE_STATISTICS',
  商品收入明细: 'COMMODITY_INCOME_DETAILS',
  套餐收入明细: 'COMMODITY_COMBO_INCOME_DETAILS',

  办卡储值明细: 'STORED_CARD_RECHARGE_DETAILS',
  办卡储值统计: 'STORED_CARD_RECHARGE_STATISTICS',
  会员卡消费明细: 'STORED_CARD_CONSUME_DETAILS',
  会员卡消费统计: 'STORED_CARD_CONSUME_STATISTICS',
  总分店会员分账统计: 'STORED_CARD_SCHEMA_STATISTICS',

  营收分析表: 'STORE_REVENUE_ANALYSIS',
  跨营业日结账报表: 'CROSS_SETTLE_DETAILS',
  包厢收入明细: 'ROOM_INCOME_DETAILS',

  进销存明细: 'PURCHASE_SALE_STOCK_DETAILS',
  进销存统计: 'PURCHASE_SALE_STOCK_STATISTICS',
  供应商采购明细: 'COMMODITY_PURCHASE_DETAILS',
  供应商采购统计: 'COMMODITY_PURCHASE_STATISTICS',
  消费日支付账单: 'PAYMENT_CONSUME_DETAILS',
  储值日支付账单: 'PAYMENT_STORED_DETAILS',
  预定单日支付账单: 'PAYMENT_RESERVE_DETAILS',
  月支付账单: 'PAYMENT_MONTH_STATISTICS',
  商品盘点明细: 'COMMODITY_INVENTORY_DETAILS',
  账单明细: 'ROOM_OPEN_BILL_DETAILS',
  美大验券记录: 'MEITUAN_COUPON_VERIFICATION_DETAILS',
  场次收入明细: 'ROOM_OPEN_INCOME_DETAILS',
  优惠券使用报表: 'XJH_COUPON_USE_DETAILS',
  优惠券领取报表: 'XJH_COUPON_DRAW_DETAILS',
  消费流水结算明细: 'BILL_CONSUME_DETAILS',
  储值流水结算明细: 'BILL_STORED_DETAILS',
  结算统计: 'STORE_BILL_STATISTICS',
  储值卡提成: 'COMMISSION_STORED_RECHARGE',
  商品销售提成: 'COMMISSION_COMMODITY_SALE',
  短信报表统计: 'SMS_SEND_STATISTICS',
  短信发送明细: 'SMS_SEND_DETAILS',
  储值订单: 'ORDER_RECHARGE',
  包厢消费单: 'ORDER_ROOM_CONSUME',
  线下预定明细: 'STORE_RESERVE_DETAILS',
  美大预定明细: 'MEITUAN_RESERVE_DETAILS',
  小程序预定明细: 'USER_RESERVE_DETAILS',

  大区营业月报: 'REGION_REVENUE_MONTH_REPORT',
  大区营业日报: 'REGION_REVENUE_DAILY_REPORT',

  积分变动明细: 'POINTS_CHANGE_DETAILS',
  会员点歌管理: 'ROOM_SONG_DETAILS',

  利通会员消费明细: 'LITONG_CARD_CONSUMER_DETAILS',
  利通会员消费统计: 'LITONG_CARD_CONSUMER_STATISTICS',
  会员列表: 'MEMBER_LIST'
}

import { post } from '@/lin/plugin/axios'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
import { createMoveBall } from '@/utils/createMoveBall.js'

export default {
  data() {
    return {
      totalRowData: {}, //合计
    }
  },
  methods: {
    /**
     * @description: 合并合计数据
     * @param {*} pageNum 页码
     * @param {*} verifyParam 参数校验
     * @return {*}
     */
    async mergeTotalData(pageNum, type, verifyModel, notHasTotalRowData) {
      //校验
      if (typeof verifyModel === 'function') {
        await verifyModel()
      }
      let params = this.generateParam()

      if (notHasTotalRowData) {
        this.tableData = []
        return this.getTableData(params, TYPE_ENUMS[type])
      }

      //占坑
      this.tableData = [{ name: '合计' }]

      //生成参数

      this.getTableData(params, TYPE_ENUMS[type])

      if (pageNum > 1) {
        return this.tableData.splice(0, 1, this.totalRowData)
      }
      this.getTotalRowRequest(params, TYPE_ENUMS[type])
    },

    /**
     * @description: 生成请求参数
     * @param {*} type
     * @return {*}
     */
    generateParam() {
      let params = {
        pageNum: this.model.pageNum,
        pageSize: this.model.pageSize,
      }
      let model = _.cloneDeep(this.model)
      model.regions = model.regionCodeList || model.regionCodes
      model.stores = model.storeCodes || model.storeCodeList
      delete model.pageNum
      delete model.pageSize
      delete model.regionCodeList
      delete model.regionCodes
      delete model.storeCodes
      delete model.storeCodeList
      params.criteria = JSON.stringify(model)
      return params
    },

    // 获取报表数据
    async getTableData(params, type) {
      this.$refs.search.fullscreenLoading = true
      let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
        this.$refs.search.fullscreenLoading = false
      })
      if (res.code == 200) {
        this.tableData.push(...(res.data.items ?? []))
        this.totalRecords = res.data.total
        this.totalPage = res.data.pages
        this.setEmptyDataText()
      } else {
        this.tableData = []
        this.totalPage = 0
        this.totalRecords = 0
      }
    },

    /**
     * @description: 获取合计请求数据
     * @return {*}
     */
    async getTotalRowRequest(params, type) {
      let res = await post(`/shadow/report/summary?type=${type}`, params)
      if (res.code == 200) {
        this.totalRowData = res.data ?? {}
        this.tableData.splice(0, 1, this.totalRowData)
      } else {
        this.tableData.splice(0, 1)
      }
    },

    /**
     * @description: 导出
     * @param {*} type
     * @return {*}
     */
    async exportData(type, event) {
      createMoveBall(event)
      let params = this.generateParam()
      let res = await post(`/shadow/export/download?type=${TYPE_ENUMS[type]}`, params).finally(() => {
        this.$refs.search.fullscreenLoading = false
      })
      if (res.code == 200) {
        ElMessage.success('导出成功')
      } else {
        ElMessage.error('导出失败')
      }
    },
  },
}
