<template>
  <el-dialog
    :title="'code' in updateRowMsg ? '修改赠品组' : '新增赠品组'"
    v-model="dialogVisible"
    width="50%"
    :before-close="handleClose"
  >
    <el-form>
      <el-form-item label="赠品组商品">
        <el-button @click="openProductDialog" v-if="!('code' in updateRowMsg)">添加商品</el-button>
        <div class="tab-des">
          <el-table :data="pageData" empty-text="暂无商品">
            <el-table-column show-overflow-tooltip label="商品" prop="productName" align="center"></el-table-column>
            <template v-for="(item, index) in headerTitles" :key="index">
              <el-table-column :label="item + '级门店赠送'" align="center" show-overflow-tooltip>
                <template #default="scope">
                  <el-input-number
                    :min="0"
                    :max="9999"
                    v-model="scope.row[item]"
                    size="small"
                    :controls="false"
                    :precision="0"
                  ></el-input-number>
                </template>
              </el-table-column>
            </template>
            <el-table-column label="操作" align="center" v-if="!('code' in updateRowMsg)">
              <template #default="{row}">
                <el-button @click="deleteGiftGroup(row)">移除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form-item>
    </el-form>
    <el-pagination
      v-model:current-page="pageParams.pageNum"
      v-model:page-size="pageParams.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageParams.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleSubmit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <SelectShops
    v-if="shopDialogVisible"
    :dialogVisible="dialogVisible"
    :toastMsg="toastMsg"
    @update-dialogVisible="rectiveProducts"
  ></SelectShops>
</template>

<script setup>
import _ from 'lodash'
import SelectShops from '../../../merchant-manager/view/group-list/SelectShops.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, defineEmit, defineProps, reactive, watchEffect, onMounted } from 'vue'
import API from '../../service/api'
/**
 * 父容器传过来的参数
 */
const props = defineProps({
  updateRowMsg: {
    type: Object,
    default: () => ({ levelCommon: [] }),
  },
})

/**
 * table 头
 */
const headerTitles = ref([])
const getTableHeader = async () => {
  let { data } = await API.getTableHeader()
  headerTitles.value = data
}
onMounted(() => {
  getTableHeader()
})

//分页参数
const pageParams = reactive({
  pageNum: 1,
  pageSize: 5,
  total: 0,
})
const pageData = ref([]) //分页数据
watchEffect(() => {
  let start = pageParams.pageSize * (pageParams.pageNum - 1)
  let end = start + pageParams.pageSize
  pageData.value = props.updateRowMsg.levelCommon.slice(start, end)
  pageParams.total = props.updateRowMsg.levelCommon.length
})

/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['handleCloseDialog'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

const handleSubmit = _.throttle(
  () => {
    handleCommit()
  },
  1000,
  { trailing: false },
)

const handleCommit = async () => {
  let res = {}

  if ('code' in props.updateRowMsg) {
    let row = props.updateRowMsg.levelCommon[0]
    let levelCommon = {}
    Object.keys(headerTitles.value).forEach(key => {
      levelCommon[key] = row[key]
    })
    let paramsItem = {
      levelCommon,
      categoryCode: row.categoryCode,
      categoryName: row.categoryName,
      productName: row.productName,
      code: row?.code || '',
    }
    res = await API.updateInfoByCode(paramsItem)
  } else {
    let params = []
    props.updateRowMsg.levelCommon.forEach(item => {
      let levelCommon = {}
      Object.keys(headerTitles.value).forEach(key => {
        levelCommon[key] = item[key]
      })
      let paramsItem = {
        levelCommon,
        categoryCode: item.categoryCode,
        categoryName: item.categoryName,
        productName: item.productName,
        productCode: item.productCode,
        code: item?.code || '',
      }
      params.push(paramsItem)
    })
    res = await API.saveInfo(params)
  }
  if (res.code === '200') {
    ElMessage.success('操作成功')
  } else {
    ElMessage.error(res.msg)
  }
  handleClose()
}

/**
 * 删除可选商品
 * @param {object} product
 */
const deleteGiftGroup = ({ code }) => {
  ElMessageBox.confirm('确认删除当前赠品吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let deleteIndex = props.updateRowMsg.levelCommon.findIndex(p => p.code === code)
    props.updateRowMsg.levelCommon.splice(deleteIndex, 1)
  })
}

// 选择商品
const shopDialogVisible = ref(false) //商品选择组件展示
const toastMsg = ref('') // toast提示

/**
 * 是否展示选择商品dialog
 */
const isShowProductDialog = val => {
  shopDialogVisible.value = val
}
/**
 * 打开选择商品的dialog
 */
const openProductDialog = () => {
  toastMsg.value = '商品已入赠品组商品清单'
  isShowProductDialog(true)
}

/**
 * 接受新数据
 * @param {object} item 选择的商品
 */
const rectiveProducts = ({ selectSkus }) => {
  isShowProductDialog(false)
  injectNewProduct(props.updateRowMsg.levelCommon, selectSkus)
}

/**
 * 注入新数据
 * @param {array} oldProducts  老数据
 * @param {array} newProducts  新数据
 */
const injectNewProduct = (oldProducts, newProducts) => {
  let levelObj = {}
  Object.keys(headerTitles.value).forEach(key => {
    levelObj[key] = 1
  })
  newProducts.forEach(n => {
    let old = oldProducts.find(o => o.code === n.code)
    if (!old) {
      oldProducts.push({
        ...levelObj,
        productName: n.name,
        categoryCode: n.categoryCode1,
        categoryName: n.categoryName,
        productCode: n.code,
      })
    }
  })
}
//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
}
const handleCurrentChange = val => {
  pageParams.pageNum = val
}
</script>

<style scoped lang="scss">
::v-deep(.el-form-item__content) {
  width: 90%;
}
// tab栏下方的控件
.tab-des {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  ::v-deep(.el-date-editor) {
    width: 100%;
  }
  ::v-deep(.el-input-number) {
    width: 100%;
  }

  label {
    width: 70px;
    margin-right: 5px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 10px;
}
// 底部按钮区
.dialog-footer {
  .el-button {
    width: 100px;
  }
}
</style>
