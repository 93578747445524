<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-06-26 12:28:16
 * @FilePath: /saas-pc/src/plugin/settlement-manager/view/report_module/settlementStatistics.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportPaymentOrderDay" reportName="日支付账单"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportPaymentOrderDay"
      :emptyText="emptyTableText"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import _Row from 'element-plus/lib/el-row'
import { downLoad } from '../../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        monthStart: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        monthEnd: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodeList: [],
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'storeCode',
          label: '门店号',
          width: '100',
        },
        {
          prop: 'storeName',
          label: '门店名称',
          width: '150',
        },
        {
          prop: 'month',
          label: '结算周期',
          width: '200',
        },
        {
          prop: 'consumeSettToHQAmount',
          label: '结算至总部金额',
          width: '200',
        },
        {
          prop: 'consumeSettToStoreRevenue',
          label: '结算口径门店营收',
          width: '200',
        },
        {
          prop: 'equityAmount',
          label: '权益金',
        },
        {
          prop: 'storePayTraceFlowAmount',
          label: '消费流水门店应收金额',
          width: '220',
        },

        {
          prop: 'storeCardsStoredAmount',
          label: '门店卡充值金额',
          width: '200',
        },
        {
          prop: 'storeCardsConsumeAmount',
          label: '门店卡消费金额',
          width: '200',
        },
        {
          prop: 'storeCardsSecurityAmount',
          label: '门店卡净增金额押金',
          width: '200',
        },
        {
          prop: 'storeReceivableAmount',
          label: '门店应收总金额',
          width: '180',
        },
      ],
      searchform: [
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodes',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '结算周期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'monthrange',
          isRange: true,
          dataFormate: 'YYYY-MM',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .format('YYYY-MM'),
            dayjs().format('YYYY-MM'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/settStatistics/export', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportPaymentOrderDay(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    // async changeArea(areas) {
    //   let res = await api.getStoreListByName({ list: areas })
    //   this.searchform[2].options = res.data.map(item => {
    //     return { value: item.code, label: item.code + '--' + item.name }
    //   })
    // },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[0].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      // api.getCheckArea().then(res => {
      //   this.searchform[0].options = res.data
      // })
    },
    reportPaymentOrderDay(model) {
      this.$refs.search.fullscreenLoading = true
      api
        .settStatisticsPageList(model)
        .then(res => {
          this.tableData = res.data?.records || []
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
          this.setEmptyDataText()
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    parentSearch(param) {
      const { storeCodes } = param
      this.model = { storeCodes }
      this.model.monthStart = param.time ? param.time[0] : ''
      this.model.monthEnd = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.reportPaymentOrderDay(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { storeCodes } = param
      this.model = { storeCodes }
      this.model.monthStart = param.time ? param.time[0] : ''
      this.model.monthEnd = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '统计结算.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
