<!--
 * @Author: your name
 * @Date: 2022-04-21 21:26:18
 * @LastEditTime: 2022-12-21 17:23:22
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/view/member-storedValue-manager/index.vue
-->
<template>
  <div class="member-storingWine-manager">
    <SearchBar
      :authMenus="authMenus"
      :openExtendModal="openExtendModal"
      :getTableData="getTableData"
      :model="model"
      :columnsList="columnsList"
      searchTypes="isStoredValue"
    />
    <el-table
      :data="tableData"
      style="width: 100%"
      class="el-table-header-gap table-content"
      :default-sort="{ prop: 'payTime', order: 'descending' }"
    >
      <el-table-column prop="storeName" label="门店" width="180" />
      <el-table-column prop="code" label="订单号" width="180" />
      <el-table-column prop="userName" label="用户名" />
      <el-table-column prop="telephone" label="手机号" />
      <el-table-column prop="payPrice" label="储值金额" sortable />
      <el-table-column prop="payType" label="支付方式" :filters="payTypeColumns" :filter-method="filterPaytype">
        <template #default="scope">
          <div>
            {{ payTypeList[scope.row.payType] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="订单状态" :filters="statusColumns" :filter-method="filterStatus">
        <template #default="scope">
          <div>
            {{ statusList[scope.row.status] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="payTime" label="支付时间" sortable />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)" v-if="authMenus.query">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :authMenus="authMenus"
      :totalRecords="totalRecords"
      :totalPage="totalPage"
      :model="model"
      :getTableData="getTableData"
    />
    <el-drawer
      v-model="infoModal"
      title="烟雨-1637263627"
      :with-header="false"
      size="35%"
      :direction="direction"
      :before-close="handleClose"
      id="else-title"
    >
      <div class="order-detail-item">
        <h1>
          订单详情
          <el-icon :size="20" color="#BFBFBF" class="close-icon" @click="infoModal = false">
            <close />
          </el-icon>
        </h1>
        <div class="info-line"></div>
        <ul>
          <li>门店：</li>
          <li>{{ recordsDetail.storeName }}</li>
          <li>订单号：</li>
          <li>{{ recordsDetail.code }}</li>
          <li>小票号：</li>
          <li>{{ recordsDetail.ticketNo }}</li>
          <li>类型：</li>
          <li>{{ recordsDetail.type === 1 ? '储蓄卡' : '' }}</li>
          <li>状态：</li>
          <li>{{ statusList[recordsDetail.status] }}</li>
          <li>充值类型：</li>
          <li>{{ recordsDetail.onSaleName }}</li>
          <li>会员号：</li>
          <li>{{ recordsDetail.userCode }}</li>
          <li>实付：</li>
          <li>¥{{ recordsDetail.payPrice }}</li>
          <li>支付方式：</li>
          <li>{{ payTypeList[recordsDetail.payType] }}</li>
          <li>支付时间：</li>
          <li>{{ recordsDetail.payTime }}</li>
        </ul>
        <el-button class="return-money" @click="returnMoney">退款</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import SearchBar from '../../component/tableSearchTab.vue'
import Pagination from '../../component/pagination.vue'
import ExtendDrawer from '../../component/extendDrawer.vue'
import api from '../../service/index'
import { payTypeList, statusList, payTypeColumns, statusColumns } from '../../../../constants/config'
export default {
  components: { SearchBar, Pagination, ExtendDrawer },
  data() {
    return {
      authMenus: {},
      searchDate: '',
      options: [
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
        },
      ],
      tableData: [],
      input2: '',
      background: false,
      infoModal: false, //详情Modal，
      extendModal: false,
      extendDrawer: false,
      model: {
        pageNum: 1,
        pageSize: 10,
        storeCode: 'K0000',
        telephone: '',
      },
      payTypeList: payTypeList,
      statusList: statusList,
      payTypeColumns: payTypeColumns,
      statusColumns: statusColumns,
      recordsDetail: {},
      totalRecords: null, //总共数据条数
      totalPage: null, //总共页数
      columnsList: [
        { value: 'storeName', label: '门店' },
        { value: 'code', label: '订单号' },
        { value: 'userName', label: '用户名' },
        { value: 'telephone', label: '手机号' },
        { value: 'payPrice', label: '储值金额' },
        { value: 'payType', label: '支付方式' },
        { value: 'status', label: '订单状态' },
        { value: 'payTime', label: '支付时间' },
      ],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    this.getTableData(this.model)
  },
  methods: {
    getTableData(model) {
      if (this.authMenus.query) {
        api.getCardsStoredRecordPages(model).then(res => {
          this.tableData = res.data.records
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
        })
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    showDetail(index, row) {
      this.infoModal = true
      api.getCardsStoredRecordDetails({ code: row.code }).then(res => {
        this.recordsDetail = res.data
      })
    },
    handleClose() {
      this.infoModal = false
    },
    closeExtendModal() {
      this.extendModal = false
    },
    openExtendModal() {
      this.extendModal = true
    },
    filterPaytype(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    filterStatus(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    async returnMoney() {
      let param = {
        orderCode: this.recordsDetail.code,
        orderType: this.recordsDetail.orderType || 3,
        payCode: this.recordsDetail.payCode,
        payPrice: this.recordsDetail.payPrice,
        relationCode: '',
      }
      const res = await api.refundMoney(param)
      if (res.code != '200' || res.code != 200) {
        ElMessage({
          showClose: true,
          message: res.msg,
          type: 'error',
        })
        return
      }
      this.infoModal = false
      ElMessage({
        showClose: true,
        message: '退款成功',
        type: 'success',
      })
      this.getTableData(this.model)
    },
  },
}
</script>
<style lang="scss" scoped>
.member-storingWine-manager {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .table-content {
    height: 100%;
    overflow: auto;
  }
  .isConfirm-extend {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .shop-order-search {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 41px;

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }
  }

  .order-detail-item {
    width: 588px;
    margin: auto;

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .info-title {
      box-sizing: border-box;
      margin-bottom: 24px;
      width: 588px;
      height: 40px;
      line-height: 40px;
      background: #f0f2f5;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding-left: 24px;
      color: #2e2f31;
      -webkit-text-stroke: 1px #979797;
    }

    .info-num {
      margin-left: 20px;
      margin-bottom: 30px;
    }

    ul {
      li {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        height: 26px;
        line-height: 26px;
      }

      li:nth-child(2n-1) {
        color: #666666;
      }

      li:nth-child(2n) {
        color: #222222;
        margin-bottom: 24px;
      }
    }

    .return-money {
      position: fixed;
      bottom: 24px;
      width: 121px;
      background-color: #f66c6a;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      right: 40px;
      color: #ffffff;
    }
  }
}
</style>
