/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-05-20 10:42:02
 * @FilePath: /saas/src/plugin/member_manager/simulation/movie.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import api from '../service/index'
export const levelList = () => {
  api.getLevelList().then(res => {
    return res.data
  })
}
