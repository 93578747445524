/*
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-07-18 15:24:51
 * @LastEditTime: 2024-07-24 11:21:25
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/plugin/cms-manager/service/api.js
 * @Description:
 */
import _axios, { post, get, requestUrl } from '@/lin/plugin/axios'
import axios from 'axios'

class CsMng {
  //封面管理-更新-v2.0
  async updateOrSave(data) {
    return await requestUrl({
      url: '/back/cms/menus/save',
      method: 'post',
      data: data,
    })
  }
  // async updateOrSave(data) {
  //   return await post(`/open/updateOrSave`, data)
  // }
  //封面管理-查询-v2.0
  async queryCoverAll(data) {
    return await requestUrl({
      url: '/back/cms/menus/find-all',
      method: 'post',
      data: data,
    })
  }
  // async queryCoverAll(data) {
  //   return await post(`/open/queryVodSongMenusNewAll`, data)
  // }
  //封面管理-查询-v1.0
  async queryVodSongMenusAll(data) {
    return await post(`/open/vod/queryVodSongMenusAll`, data)
  }
  //封面管理-保存-v1.0
  async updateOrSaveOne(data) {
    return await post(`/open/vod/updateOrSave`, data)
  }

  //歌曲

  //歌曲查询
  async queryVoSongList(data) {
    return await requestUrl({
      url: '/back/cms/songs/page',
      method: 'post',
      data: data,
    })
  }
  // async queryVoSongList(data) {
  //   return await post(`/open/vod/queryVoSongList`, data)
  // }

  //歌曲查询列表--分类列表
  async queryOpenMenu(data) {
    return await requestUrl({
      url: '/back/cms/songs/find-all',
      method: 'post',
      data: data,
    })
  }
  // async queryOpenMenu(data) {
  //   return await post(`/open/vod/queryOpenMenu`, data)
  // }

  //歌曲查询详情
  async queryVodSongDetail(data) {
    return await requestUrl({
      url: '/back/cms/songs/info',
      method: 'post',
      params: data,
    })
  }
  //歌曲修改
  async updateOrSaveByVodSong(data) {
    return await requestUrl({
      url: '/back/cms/songs/update',
      method: 'post',
      data: data,
    })
  }
  // async updateOrSaveByVodSong(data) {
  //   return await post(`/open/vod/updateOrSaveByVodSong`, data)
  // }
  //歌曲删除
  // async deleteVodSong(data) {
  //   return await post(`/open/vod/deleteVodSong`, data)
  // }
  async deleteVodSong(data) {
    return await requestUrl({
      url: '/back/cms/songs/delete',
      method: 'post',
      // data: data,
      params: data,
    })
  }

  // 新增短链
  async addLink(data) {
    return await requestUrl({
      url: `/back/cms/link-short/save`,
      method: 'post',
      data: data,
    })
  }
  // 编辑短链
  async updateLink(data) {
    return await requestUrl({
      url: `/back/cms/link-short/update`,
      method: 'post',
      data: data,
    })
  }
  // 查询短链内容
  async queryLink(data) {
    return await requestUrl({
      url: `/back/cms/link-short/page`,
      method: 'post',
      data: data,
    })
  }
  // 作废短链
  async updateStatus(data) {
    return await requestUrl({
      url: `/back/cms/link-short/update-status`,
      method: 'post',
      params: data,
    })
  }
  // 资源模式管理-查询
  async queryMenusModelResource(data) {
    return await requestUrl({
      url: '/back/cms/model/find-all',
      method: 'post',
      data: data,
    })
  }
  // 资源模式管理-编辑
  async saveMenusModelResource(data) {
    return await requestUrl({
      url: '/back/cms/model/save',
      method: 'post',
      data: data,
    })
  }
}

export default new CsMng()
