<!--
 * @Author: your name
 * @Date: 2022-04-22 13:49:30
 * @LastEditTime: 2022-08-28 22:17:31
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/component/base/backHeader/goBack.vue
-->
<template>
  <div class="header" @click="handleBack">
    <el-icon :size="20" color="rgba(0, 0, 0, 0.25)" class="close-icon"> <arrow-left /></el-icon><span>返回</span>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    activeName: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleBack() {
      this.$router.back()
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  width: 1568px;
  height: 56px;
  line-height: 60px;
  margin: auto;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  .close-icon {
    cursor: pointer;
  }
  span {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6d7278;
    margin-left: 30px;
    cursor: pointer;
  }
}
</style>
