<template>
  <el-dialog
    v-model="visible"
    :title="'code' in needUpdateInfo ? '修改版本' : '新增版本'"
    width="58%"
    :before-close="handleClose"
  >
    <el-form
      :model="needUpdateInfo"
      ref="formRef"
      :rules="rules"
      v-loading.fullscreen.lock="upload.loading"
      :element-loading-text="upload.loadText"
    >
      <el-form-item label="版本号" label-width="120px" prop="versionCode">
        <el-input placeholder="请输入版本号" v-model="needUpdateInfo.versionCode" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="版本压缩包" label-width="120px" prop="url">
        <div style="display: flex;align-items: center;">
          <el-upload
            class="upload-demo"
            action="#"
            :on-change="changeImage"
            :file-list="upload.fileList"
            accept=".apk"
            :auto-upload="false"
            show-file-list
            :multiple="false"
            :on-remove="handleRemove"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>

          <el-tag
            v-if="'code' in needUpdateInfo"
            v-for="tag in tags"
            :key="tag.name"
            closable
            @close="handleCloseTag(tag)"
            style="margin-left: 30px;"
          >
            {{ needUpdateInfo.name }}
          </el-tag>
        </div>
      </el-form-item>
      <el-form-item label="更新内容" label-width="120px" prop="remarks">
        <el-input
          placeholder="请输入"
          v-model="needUpdateInfo.remarks"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 4 }"
        ></el-input>
      </el-form-item>
      <el-form-item label="发布策略" label-width="120px" prop="storeCodeList">
        <el-radio-group v-model="needUpdateInfo.type">
          <el-radio :label="0">全量发布</el-radio>
          <el-radio :label="1">部分发布</el-radio>
        </el-radio-group>
        <FilterShop
          v-if="needUpdateInfo.type == 1"
          :storeData="needUpdateInfo.storeCodeList"
          @changeStore="changeStore"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveCustomerSourceConfig" :disabled="!needEdit">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { headers, posClient } from '../../../../lin/util/uploadImg.js'
import FilterShop from '../../component/filter-shop.vue'
import API from '../../service/index'
import { ref, defineProps, defineEmit, reactive } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'

const props = defineProps({
  needUpdateInfo: {
    type: Object,
    default: {},
  },
  needEdit: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmit(['handleCloseDialog'])

const visible = ref(true)

/**
 * 关闭弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

const tags = ref([1])
const handleCloseTag = () => {
  ElMessageBox.confirm('确认删除当前版本吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    props.needUpdateInfo.url = ''
    props.needUpdateInfo.name = ''
    tags.value = []
  })
}
/**
 * 改变门店
 * @param {*} data
 */
const changeStore = ({ isAll, data = [], isOneClick }) => {
  if (isOneClick) {
    props.needUpdateInfo.storeCodeList = data
    return
  }
  if (isAll) {
    data.forEach(item => {
      let index = props.needUpdateInfo.storeCodeList.findIndex(i => i === item)
      if (index === -1) {
        props.needUpdateInfo.storeCodeList.push(item)
      }
    })
  } else {
    data.forEach(item => {
      let index = props.needUpdateInfo.storeCodeList.findIndex(i => i === item)
      props.needUpdateInfo.storeCodeList.splice(index, 1)
    })
  }
  nextTick(() => {
    formRef.value.clearValidate('storeCodeList')
  })
}

const validateFixedStores = (rule, value, callback) => {
  if (props.needUpdateInfo.type == 1 && props.needUpdateInfo.storeCodeList.length === 0) {
    return callback(new Error('请至少选择一家门店'))
  }
  callback()
}

//校验规则
const formRef = ref(null)
const rules = {
  storeCodeList: [{ validator: validateFixedStores, trigger: 'change' }],
  versionCode: [{ required: true, message: '请填写版本号', trigger: 'change' }],
  url: [{ required: true, message: '请上传版本', trigger: 'change' }],
}
/**
 * 保存客户来源配置
 */
const saveCustomerSourceConfig = async () => {
  await formRef.value.validate()
  let info = generatorSaveData()
  let res
  if ('code' in props.needUpdateInfo) {
    res = await API.updateVersionApk(info)
  } else {
    res = await API.insertVersionApk(info)
  }
  if (res.code !== '200') {
    ElMessage.error(res.msg)
  } else {
    ElMessage.success('操作成功')
  }
  handleClose()
}

/**
 * 生成数据
 */
const generatorSaveData = () => {
  return {
    ...props.needUpdateInfo,
    storeCodeList: JSON.stringify(props.needUpdateInfo.storeCodeList),
  }
}

const upload = reactive({
  loadText: '',
  loading: false,
  fileList: [],
})

const changeImage = (file, fileList) => {
  if (fileList.length > 1) {
    fileList.pop()
    return ElMessage.error('最多只能上传一个')
  }
  // let size = file.size
  // if (size > 100 * 1024 * 1024) {
  //   ElMessage.info('上传图片小于100M')
  //   return
  // }
  upload.loadText = '正在火速上传中...'
  upload.loading = true
  // let fileName = file.name.split('.')[0] + Date.now() + '.' + file.name.split('.')[1]
  let filesSplit = file.name.split('.')
  let fileName =
    file.name.substring(0, file.name.length - filesSplit[filesSplit.length - 1].length - 1) +
    '_' +
    Date.now() +
    '.' +
    filesSplit[filesSplit.length - 1]
  posClient()
    .put(fileName, file.raw, { headers })
    .then(res => {
      props.needUpdateInfo.url = res.res.requestUrls[0]
      props.needUpdateInfo.name = file.name
      formRef.value.clearValidate('url')
    })
    .finally(() => {
      upload.loading = false
    })
}
const handleRemove = async () => {
  try {
    // 填写Object完整路径。Object完整路径中不能包含Bucket名称，例:upload/2023-02/b35aae063be1e198207bc8dc778.jpg
    // await client().delete(props.needUpdateInfo.url)
    // ElMessage.success('删除成功')
    props.needUpdateInfo.url = ''
  } catch (error) {
    console.log(error)
    ElMessage.error('删除失败')
  }
}
</script>

<style scoped lang="scss">
.customer-source-jc {
  p {
    margin-top: 5px;
    color: #bdc3c7;
    font-size: 12px;
  }
}
.remark {
  color: #e67e22;
  font-size: 12px;
}
.dialog-footer {
  button {
    width: 100px;
  }
}
</style>
