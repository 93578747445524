<template>
  <div class="table-search-tab" style="height: 76px;line-height: 76px;">
    <div class="search-left">
      <span>角色名称</span>
      <el-input
        clearable
        placeholder="请输入角色名称"
        v-model="data.roleName"
        class="input-search-tel"
        @change="searchLists({ currentPage: 1 })"
      ></el-input>
      <el-button type="primary" @click="searchLists({ currentPage: 1 })" v-if="data.authMenus.query">查询</el-button>
    </div>
    <div class="search-right">
      <el-button type="primary" @click="addRole" v-if="data.authMenus.add"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
    </div>
  </div>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" />
    <el-table-column prop="id" label="角色ID" align="center" show-overflow-tooltip />
    <el-table-column prop="roleName" label="角色名称" align="center" show-overflow-tooltip />
    <el-table-column prop="comment" label="备注" align="center" show-overflow-tooltip />
    <el-table-column label="操作" width="300" align="center">
      <template #default="scope">
        <el-button link type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.query">详情</el-button>
        <el-button link type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update">修改</el-button>
        <el-button link type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.delete">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddRole
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :roleMsg="data.roleMsg"
    :isEdit="data.isEdit"
    :isSelectMenus="data.isSelectMenus"
    :isSelectMenusNew="data.isSelectMenusNew"
    :menus="data.menus"
    @update-dialogVisible="updateDialogVisible"
  ></AddRole>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { reactive, onMounted, computed } from 'vue'
import AddRole from './addRole/index'
import _ from 'lodash'
import API from '../../service/api'
import { useRoute } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus'

const data = reactive({
  selectShop: undefined,
  roleName: undefined,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 100,
  dialogVisible: false,
  roleMsg: {},
  menus: [],
  isSelectMenus: [],
  isSelectMenusNew: [],
  isEdit: false,
  authMenus: {},
  loading: false,
})
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists({})
  searchMenu()
})

const searchMenu = () => {
  API.getAuthMenuPage(null).then(res => {
    if (res.code === '200') {
      data.menus = res.data || []
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const searchLists = ({ currentPage }) => {
  if (currentPage) {
    data.currentPage = currentPage
  }
  if (data.authMenus.query) {
    data.loading = true
    let query = {
      roleName: data.roleName,
      pageNum: currentPage || data.currentPage,
      pageSize: data.pageSize,
    }

    API.getAuthRolePage(query).then(res => {
      if (res.code === '200') {
        data.tableData = res.data.records
        data.total = res.data.total
        data.loading = false
      } else {
        ElMessage.error(res.msg)
        data.loading = false
      }
    })
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
//添加
const addRole = () => {
  data.roleMsg = { limitSaveInfoBo: {} }
  data.isSelectMenus = []
  data.isSelectMenusNew = []
  data.isEdit = true
  data.dialogVisible = true
}
const showRow = (id, code) => {
  Promise.all([API.infoRole(id), API.getResourceAllList({}, { role: code })]).then(values => {
    data.roleMsg = {
      ...values[0].data,
      limitSaveInfoBo: values[0].data.limitSelectInfoVO,
    }
    data.menus = values[0].data.authMenusNodes
    data.isSelectMenus = []
    ;(values[0].data.authMenus || []).forEach(item => {
      if (item.menuType === 'F') {
        //只添加按钮
        data.isSelectMenus.push(item.menuCode)
      }
    })
    data.isEdit = true
    data.dialogVisible = true

    //新的
    data.isSelectMenusNew = values[1].data.filter(a => a.selected).map(a => a.code)
  })
}
const editRow = (id, code) => {
  Promise.all([API.infoRole(id), API.getResourceAllList({}, { role: code })])
    .then(values => {
      data.roleMsg = {
        ...values[0].data,
        limitSaveInfoBo: values[0].data.limitSelectInfoVO,
      }
      data.menus = values[0].data.authMenusNodes
      data.isSelectMenus = []
      ;(values[0].data.authMenus || []).forEach(item => {
        if (item.menuType === 'F') {
          //只添加按钮
          data.isSelectMenus.push(item.menuCode)
        }
      })
      data.isEdit = true
      data.dialogVisible = true

      //新的
      data.isSelectMenusNew = values[1].data.filter(a => a.selected).map(a => a.code)
    })
    .catch(err => {
      ElMessage.error(err || '请求错误')
    })
}
const deleteRow = id => {
  ElMessageBox.confirm('确认删除当前角色吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    API.deleteRole([id]).then(res => {
      if (res.code === '200') {
        ElMessage.success('删除成功')
        searchLists({})
      } else {
        ElMessage.error(res.msg)
      }
    })
  })
}

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
//操作数据
const showDetail = (index, rowMsg) => {
  switch (index) {
    case 1:
      showRow(rowMsg.id, rowMsg.roleCode)
      break
    case 2:
      editRow(rowMsg.id, rowMsg.roleCode)
      break
    case 3:
      deleteRow(rowMsg.id)
  }
}

const updateDialogVisible = ({ search, update }) => {
  data.dialogVisible = update
  if (search) {
    searchLists({})
  }
}
//分页
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists({})
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists({})
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    span {
      width: 100px;
      margin-left: 20px;
      text-align: right;
    }
    .input-with-select {
      width: 340px;
      margin-left: 10px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
