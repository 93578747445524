<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-26 09:38:03
 * @FilePath: /saas/src/plugin/finance-reconciliation/view/report_module/cjmxReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportPaymentOrderDay" reportName="日支付账单"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportPaymentOrderDay"
      :emptyText="'暂无数据'"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../component/base/searchbar/index.vue'
import Table from '../component/table.vue'
import api from '../service/api'
import { downLoad } from '../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodeList: [],
        orderCode: '',
        payCode: '',
        payTypeList: [],
        status: '',
        isRefund: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'storeName',
          label: '门店编号',
        },
        {
          prop: 'storeName',
          label: '门店名称',
        },
        {
          prop: 'openCode',
          label: '包厢类型',
          width: '200px',
        },
        {
          prop: 'orderCode',
          label: '包厢',
          width: '200px',
        },
        {
          prop: 'payCode',
          label: '消费项目',
        },
        {
          prop: 'payTypeTxt',
          label: '应收',
        },
        {
          prop: 'statusTxt',
          label: '优惠',
        },

        {
          prop: 'payPrice',
          label: '抹零',
        },
        {
          prop: 'serviceCharge',
          label: '实收',
        },
        {
          prop: 'settledAmount',
          label: '净收',
        },
        {
          prop: 'createTime',
          label: '订单号',
          width: '180px',
        },
        {
          prop: 'createTime',
          label: '账单号',
          width: '180px',
        },
        {
          prop: 'createTime',
          label: '场次号',
          width: '180px',
        },
        {
          prop: 'createTime',
          label: '支付时间',
          width: '180px',
        },
        {
          prop: 'createTime',
          label: '开台人',
          width: '180px',
        },
        {
          prop: 'createTime',
          label: '提成比例',
          width: '180px',
        },
        {
          prop: 'createTime',
          label: '提成金额',
          width: '180px',
        },
      ],
      searchform: [
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '包厢类型',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'roomTypeCode',
          placeholder: '请选择包厢类型',
          options: [],
        },
        { label: '包厢', type: 'input', prop: 'orderCode', placeholder: '请输入包厢' },
        {
          label: '消费项目',
          type: 'input',
          labelWidth: '120px',
          prop: 'payCode',
          placeholder: '请输入消费项目',
        },
        {
          label: '场次号',
          type: 'input',
          labelWidth: '120px',
          prop: 'payCode',
          placeholder: '请输入场次号',
        },
        {
          label: '账单号',
          type: 'input',
          labelWidth: '120px',
          prop: 'payCode',
          placeholder: '请输入账单号',
        },
        {
          label: '订单号',
          type: 'input',
          labelWidth: '120px',
          prop: 'payCode',
          placeholder: '请输入订单号',
        },
        {
          label: '开台人',
          type: 'input',
          labelWidth: '120px',
          prop: 'payCode',
          placeholder: '请输入开台人',
        },

        { label: '导出', type: 'exportButton', url: '/report/export/reportOrderPayList', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportPaymentOrderDay(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[1].options = res.data
      })
      api.getStoreRoomTypeList2().then(res => {
        //包厢类型接口
        this.searchform[3].options = res.data.map(item => {
          return {
            value: item.code,
            label: item.name,
          }
        })
      })
    },
    reportPaymentOrderDay(model) {
      this.$refs.search.fullscreenLoading = true
      api
        .reportPaymentOrderDay(model)
        .then(res => {
          this.tableData = res.data.records
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    parentSearch(param) {
      const { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode } = param
      this.model = { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      this.reportPaymentOrderDay(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode } = param
      this.model = { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '日支付账单.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
