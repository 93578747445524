<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-10-23 12:07:49
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/comboIncomeReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportComboIncomeDetail" reportName="套餐收入明细"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      tHeight="100%"
      :emptyText="emptyTableText"
      :getTableData="reportComboIncomeDetail"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
// import { downLoad } from '../../../../lin/util/util'
import { ElMessage } from 'element-plus'
import { createMoveBall } from '@/utils/createMoveBall.js'
export default {
  props: {
    auths: Object,
    reportType: {
      type: String,
      default: '',
    },
    reportTypeName: {
      type: String,
      default: '',
    },
  },
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodeList: [],
        type: '',
        skuName: '',
        code: '',
        roomCode: '',
        billCode: '',
        openCode: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'storeCode',
          label: '门店编号',
          width: '100px',
        },
        {
          prop: 'storeName',
          label: '门店名称',
          width: '150px',
        },
        {
          prop: 'productName',
          label: '商品名称',
          width: '150px',
        },
        {
          prop: 'isConsumeFree',
          label: '套餐场景',
          width: '100px',
        },
        {
          prop: 'price',
          label: '售卖价格',
          width: '150px',
        },
        {
          prop: 'count',
          label: '销量',
        },
        {
          prop: 'receivableAmount',
          label: '应收',
        },
        {
          prop: 'discountAmount',
          label: '优惠',
        },
        {
          prop: 'wipeZeroAmount',
          label: '抹零',
        },
        {
          prop: 'actualAmount',
          label: '实收',
        },
        {
          prop: 'netIncomeFee',
          label: '净收',
          width: '140px',
        },
        {
          prop: 'roomName',
          label: '包厢',
        },
        {
          prop: 'orderGoodsCode',
          label: '订单号',
          width: '200px',
        },
        {
          prop: 'billCode',
          label: '账单号',
          width: '200px',
        },
        {
          prop: 'openCode',
          label: '场次号',
          width: '200px',
        },
        {
          prop: 'payTime',
          label: '支付时间',
          width: '180px',
        },
        // {
        //   prop: 'startTime',
        //   label: '开台时间',
        // },
        {
          prop: 'waiter',
          label: '收银员',
          width: '140px',
        },
        {
          prop: 'goodsCost',
          label: '成本',
        },
        {
          prop: 'profit',
          label: '毛利(净收-成本)',
          width: '180px',
        },
        {
          prop: 'percent',
          label: '毛利率(净收-成本)/净收',
          width: '200px',
        },
        {
          prop: 'comboType',
          label: '套餐内容',
        },
      ],
      pagination: {},
      searchform: [
        {
          label: '日期',
          labelWidth: '50px',
          type: 'date-picker',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '套餐场景',
          placeholder: '请选择套餐场景',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'isConsumeFree',
          options: [
            {
              label: '套餐',
              value: 0,
            },
            {
              label: '套免',
              value: 1,
            },
          ],
        },
        { label: '商品名称', type: 'input', prop: 'skuName', placeholder: '请输入商品名称' },
        { label: '订单号', type: 'input', prop: 'code', placeholder: '请输入订单号' },
        { label: '账单号', type: 'input', prop: 'billCode', placeholder: '请输入账单号', labelWidth: '70px' },
        { label: '场次号', type: 'input', prop: 'openCode', placeholder: '请输入场次号', labelWidth: '70px' },
        { label: '包厢', labelWidth: '50px', type: 'input', prop: 'roomCode', placeholder: '请输入包厢' },
        {
          label: '套餐内容',
          placeholder: '请选择套餐内容',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'comboType',
          options: [
            {
              label: '商品',
              value: 1,
            },
            {
              label: '券包',
              value: 2,
            },
          ],
        },
        {
          label: '导出',
          type: 'exportButton',
          url: '/reportDownloadCenter/reportDownloadInfo/exportReport',
          auth: 'export',
        },
      ],
    }
  },
  async mounted() {
    this.$route.meta.msg = this.auths
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      await this.getStoreList()
      // this.reportComboIncomeDetail(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      api.getCheckArea().then(res => {
        this.searchform[1].options = res.data
      })
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    reportComboIncomeDetail(model) {
      this.mergeTotalData(model.pageNum, '套餐收入明细', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!model.code && !model.billCode && !model.openCode) {
        if (!this.getDays(model.startTime, model.endTime)) {
          return Promise.reject('条件不满足')
        }
      }
    },
    parentSearch(param) {
      const {
        type,
        skuName,
        storeCodeList,
        code,
        roomCode,
        openCode,
        billCode,
        regionCodeList,
        comboType,
        isConsumeFree,
      } = param
      this.model = {
        type,
        skuName,
        storeCodeList,
        code,
        roomCode,
        openCode,
        billCode,
        regionCodeList,
        comboType,
        isConsumeFree,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '套餐收入明细', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const {
        type,
        skuName,
        storeCodeList,
        code,
        roomCode,
        openCode,
        billCode,
        regionCodeList,
        comboType,
        isConsumeFree,
      } = param
      this.model = {
        type,
        skuName,
        storeCodeList,
        code,
        roomCode,
        openCode,
        billCode,
        regionCodeList,
        comboType,
        isConsumeFree,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!this.model.code && !this.model.billCode && !this.model.openCode) {
        if (!this.getDays(this.model.startTime, this.model.endTime)) {
          this.$refs.search.fullscreenLoading = false
          return
        }
      }
      this.exportData('套餐收入明细', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;
}
</style>
