/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-10-12 16:39:35
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/member_manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '会员管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/cashmanager/',
  route: '/member_manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '优惠券',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/member_manager/member_card_coupou',
      filePath: 'plugin/member_manager/view/member-card-coupou-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '会员卡等级',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/member_manager/member_card_level',
      filePath: 'plugin/member_manager/view/member-card-level-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '充值套餐',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/member_manager/top-up-package',
      filePath: 'plugin/member_manager/view/top-up-package-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '会员列表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/member_manager/member_info',
      filePath: 'plugin/member_manager/view/member-information-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '储值活动管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/member_manager/stored-value-activity-manager',
      filePath: 'plugin/member_manager/view/stored-value-activity-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '券包',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/member_manager/place-card-manager',
      filePath: 'plugin/member_manager/view/place-card-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '会员点歌管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/member_manager/member-song-management',
      filePath: 'plugin/member_manager/view/member-song-management/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
