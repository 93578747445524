<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-tabs v-model="data.type" class="demo-tabs" @tab-click="searchLists">
      <el-tab-pane label="商品" :name="1"> </el-tab-pane>
      <el-tab-pane label="易耗品" :name="4"> </el-tab-pane>
    </el-tabs>
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;">
      <el-form-item label="门店区域">
        <el-cascader
          v-model="data.regionCode"
          :options="areaOptions"
          clearable
          filterable
          collapse-tags
          placeholder="请选择门店区域"
          collapse-tags-tooltip
          @change="changeArea()"
          :show-all-levels="false"
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="门店">
        <el-select-v2
          v-model="data.storeCode"
          :options="data.shops"
          filterable
          placeholder="请选择门店"
          class="search-select userSelect"
          multiple
          collapse-tags
          style="width: 300px;"
          collapse-tags-tooltip
        />
      </el-form-item>
      <el-form-item label="盘点仓库">
        <el-select v-model="data.houseName">
          <el-option label="总仓库" value="总仓库"></el-option>
          <el-option label="超市仓库" value="超市仓库"></el-option>
          <el-option label="存酒仓库" value="存酒仓库"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="盘点单据号">
        <el-input clearable placeholder="请输入盘点单据号" v-model="data.batchCode" class="input-search-tel"></el-input>
      </el-form-item>
      <el-form-item label="操作人">
        <el-input clearable placeholder="请输入操作人" v-model="data.userName" class="input-search-tel"></el-input>
      </el-form-item>
      <el-form-item label="经手人">
        <el-input clearable placeholder="请输经手人" v-model="data.agentName" class="input-search-tel"></el-input>
      </el-form-item>
      <el-form-item>
        <el-date-picker
          v-model="data.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="盘点日期"
          end-placeholder="盘点日期"
          size="small"
          clearable
          value-format="YYYY-MM-DD HH:mm:ss"
          @change="searchLists"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="searchLists" type="primary">
          查询
        </el-button>
        <el-button @click="resetDate">
          重置
        </el-button>
        <el-button @click="exportData" v-if="data.authMenus.export">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="data.tableData" style="width: 96%; margin-left: 2%;margin-top: 10px;" class="table-content">
      <el-table-column label="序号" align="center" type="index" :index="indexfn" width="100"></el-table-column>
      <el-table-column show-overflow-tooltip label="盘点单据号" prop="batchCode" align="center" width="250" />
      <el-table-column show-overflow-tooltip label="盘点日期" align="center" width="200">
        <template #default="scope">
          {{ scope.row.createTime.split(' ')[0] }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="仓库" prop="houseName" align="center" />
      <el-table-column show-overflow-tooltip label="盈亏数量" prop="profitCount" align="center" width="100" />
      <el-table-column show-overflow-tooltip label="盈亏金额(元)" prop="profitFree" align="center" width="200" />
      <el-table-column show-overflow-tooltip label="门店编号" prop="storeCode" align="center" width="100" />
      <el-table-column show-overflow-tooltip label="门店名称" prop="storeName" align="center" width="100" />
      <el-table-column show-overflow-tooltip label="经手人" prop="agentName" align="center" />
      <el-table-column show-overflow-tooltip label="操作人" prop="userName" align="center" />
      <el-table-column show-overflow-tooltip label="盘点时间" prop="createTime" align="center" width="200" />
      <el-table-column show-overflow-tooltip label="备注信息" prop="description" align="center" width="100" />
      <el-table-column label="操作" width="250" align="center">
        <template #default="scope" fixed="right">
          <el-button type="text" @click="showDetail(scope.row)" v-if="data.authMenus.query">盘点明细</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="data.pageNum"
      v-model:page-size="data.pageSize"
      :small="small"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <ShowGoodsRecords
    :rowMsg="data.rowMsg"
    :params="data.params"
    v-if="data.dialogVisible"
    @update-dialogVisible="updateDialogVisible"
    :dialogVisible="data.dialogVisible"
  ></ShowGoodsRecords>
</template>

<script setup>
import clearSelect from '@/lin/util/clearSelect2.js'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import ShowGoodsRecords from './show-goods-records.vue'
import { ref, reactive, computed, onMounted, nextTick } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import { post } from '@/lin/plugin/axios'
import { createMoveBall } from '@/utils/createMoveBall.js'
const data = reactive({
  type: 1,
  storeCode: [],
  regionCode: [],
  areaCode: [],
  time: [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ],
  houseName: '',
  batchCode: '',
  userName: '',
  agentName: '',
  authMenus: {},
  tableData: [],
  pageSize: 10,
  pageNum: 1,
  total: 10,
  dialogVisible: false,
  rowMsg: {},
  fullscreenLoading: false,
  params: {},
  shops: [],
})
const activeName = ref('商品')

const route = useRoute()

const changeArea = () => {
  data.storeCode = []
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}
const getShops = () => {
  API.getStoreList({ list: data.areaCode }).then(res => {
    if (res.code === '200') {
      data.shops = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
  getAreas()
  getShops()
})
const indexfn = computed(() => {
  return (data.pageNum - 1) * data.pageSize + 1
})

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}

const resetDate = () => {
  data.houseName = undefined
  data.batchCode = undefined
  data.userName = undefined
  data.agentName = undefined
  data.regionCode = []
  data.areaCode = []
  data.storeCode = []
  data.time = [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ]
  data.pageNum = 1
  data.pageSize = 10
  searchLists()
}

const exportData = async event => {
  let params = {
    stores: data.storeCode,
    regions: data.areaCode,
    houseName: data.houseName,
    batchCode: data.batchCode,
    userName: data.userName,
    agentName: data.agentName,
    type: data.type,
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }

  data.fullscreenLoading = true
  createMoveBall(event)
  const param = generateParam(params)
  let res = await post(`/shadow/export/download?type=COMMODITY_STOCKTAK_DETAILS`, param).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}

const showDetail = row => {
  let params = {
    storeCodeList: data.storeCode,
    regionCodeList: data.areaCode,
    houseName: data.houseName,
    batchCode: row.batchCode,
    userName: data.userName,
    agentName: data.agentName,
    type: data.type,
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  data.rowMsg = row
  data.params = params
  data.dialogVisible = true
}

const searchLists = () => {
  data.pageNum = 1
  queryLists()
}
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }

  let params = {
    stores: data.storeCode,
    regions: data.areaCode,
    houseName: data.houseName,
    batchCode: data.batchCode,
    userName: data.userName,
    agentName: data.agentName,
    type: data.type,
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  params = generateParam(params)
  //占坑
  getTableData(params, 'COMMODITY_STOCKTAK_DETAILS')
}
/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}

// 获取报表数据
const getTableData = async (params, type) => {
  data.fullscreenLoading = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    data.tableData = res.data.items
    data.total = res.data.total
  } else {
    data.tableData = []
    data.total = 0
  }
}
const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryLists()
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.pageNum = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
</style>
