<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-07-25 11:08:18
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-16 16:44:55
 * @FilePath: /saas/src/plugin/operation_manager/view/businessData/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="business-data-box">
    <div class="search-content-box">
      <span class="search-title">门店选择</span>
      <el-select
        v-model="model.storeCode"
        filterable
        remote
        reserve-keyword
        placeholder="选择门店"
        clearable
        @change="changeStore"
        class="search-select"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option v-for="item in storeList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <span class="search-title">日期</span>
      <!-- <el-date-picker v-model="model.dateTime" type="date" placeholder="选择日期" :size="size"  @change="changeDateTime"/> -->
      <el-date-picker
        v-model="dateTime"
        @change="changeDateTime"
        type="daterange"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      />
    </div>
    <div class="business-data-top">
      <div class="business-data-left">
        <div class="left-item">
          <p>营业额总汇</p>
          <h2>¥{{ businessData.totalAmount }}</h2>
          <p>优惠折扣：{{ businessData.discountAmount }}</p>
        </div>
        <span class="border-right-line"></span>
        <div>
          <p>包厢实收</p>
          <h2>¥{{ businessData.roomAmount }}</h2>
          <p>优惠折扣：{{ businessData.roomDiscountAmount }}</p>
        </div>
        <div>
          <p>商品实收</p>
          <h2>¥{{ businessData.goodsAmount }}</h2>
          <p>优惠折扣：{{ businessData.goodsDiscountAmount }}</p>
        </div>
        <div>
          <p>充值实收</p>
          <h2>¥{{ businessData.storedAmount }}</h2>
          <p>优惠折扣：{{ businessData.storedGiftAmount }}</p>
        </div>
      </div>
      <div class="business-data-center"></div>
      <div class="business-data-right">
        <div id="circle-percentage1" class="circle-percentage"></div>
      </div>
    </div>
    <div class="revenue-data-box">
      <div class="revenue-data-left">
        <h2 class="title">
          <span></span>
          营收数据
        </h2>
        <div class="revenue-circle">
          <div id="main" class="circle-percentage"></div>
          <div class="canvas-lengend">
            <div class="lengend-list" v-for="(item, index) in paymentList">
              <div class="lengend-circle" :style="filterColor(item.name, 'bg')"></div>
              <div class="lengend-text" :style="filterColor(item.name, 'font')">
                <span class="name-item">{{ item.name }}：</span>
                <span>¥{{ item.fee }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="revenue-data-center"></div>
      <div class="revenue-data-right">
        <h2 class="title">
          <span></span>
          会员营收数据
        </h2>
        <div id="drawpie3" style="width: 260px; height: 280px;"></div>
        <div class="member-info-list">
          <div class="member-info-left">
            <div>
              <p>{{ customerData.customerCount }}</p>
              <p>总会员</p>
            </div>
            <div>
              <p>¥{{ customerData.storedAmount }}</p>
              <p>充值金额</p>
            </div>
          </div>
          <div class="member-info-right">
            <div>
              <p>{{ customerData.increaseCustomerCount }}</p>
              <p>新增会员</p>
            </div>
            <div>
              <p>{{ customerData.storedCount }}</p>
              <p>充值人数</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="business-data-table1">
      <el-table :data="memberRevenueList" style="width: 100%" height="268px" @filter-change="filterTagTable">
        <el-table-column prop="storeName" label="门店" />
        <el-table-column prop="userName" label="会员名" />
        <el-table-column prop="telephone" label="手机号" />
        <el-table-column prop="levelCode" label="等级">
          <template #default="scope">
            <div>
              {{ getLevelName(scope.row.levelCode) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="fee" label="消费金额" />
        <el-table-column prop="methodName" label="支付方式" />
      </el-table>
      <el-pagination
        v-model:currentPage="memberData.pageNum"
        :page-size="10"
        :small="small"
        :page-count="memberData.totalPage"
        :disabled="disabled"
        class="pagination-style"
        layout="total, prev, pager, next"
        :total="memberData.totalRecords"
        @size-change="handleSizeChangeMember"
        @current-change="handleCurrentChangeMember"
      />
    </div> -->
    <!-- <div class="business-data-table2">
      <el-table :data="stockList" style="width: 100%" height="288px" @filter-change="filterTagTable">
        <el-table-column prop="categoryName" label="品类" />
        <el-table-column prop="skuName" label="商品名称" />
        <el-table-column prop="unit" label="单位" />
        <el-table-column prop="retailPrice" label="单价">
          <template #default="scope">
            <div>
              {{ getLevelName(scope.row.levelCode) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="sellStock" label="数量" />
        <el-table-column prop="stock" label="当前库存" />
      </el-table>
      <el-pagination
        v-model:currentPage="stockData.pageNum"
        :page-size="10"
        :small="small"
        :page-count="stockData.totalPage"
        :disabled="disabled"
        class="pagination-style"
        layout="total, prev, pager, next"
        :total="stockData.totalRecords"
        @size-change="handleSizeChangeStock"
        @current-change="handleCurrentChangeStock"
        style="margin-bottom: 50px;"
      />
    </div> -->
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import * as echarts from 'echarts'
import Pagination from '../../component/pagination.vue'
import apiStore from '../../../room-manager/service/index'
import api from '../../service/index'
import moment from 'moment'
export default {
  components: { Pagination },
  data() {
    return {
      authMenus: {},
      storeList: [],
      storeCode: '',
      businessDataList: [],
      tableData: [],
      totalRecords: null,
      totalPage: null,
      model: {},
      payList: [
        {
          name: '微信',
          payType: 'wx',
        },
        {
          name: '雷石',
          payType: 'ls',
        },
        {
          name: '支付宝',
          payType: 'zfb',
        },
        {
          name: '美团',
          payType: 'mtjz',
        },
        {
          name: '现金',
          payType: 'xj',
        },
        {
          name: '挂账',
          payType: 'gz',
        },
      ],
      moneyList: [
        { name: '包厢实收', value: 0, itemStyle: { color: '#2963E1' } },
        { name: '商品实收', value: 0, itemStyle: { color: '#FFD640' } },
        { name: '充值实收', value: 0, itemStyle: { color: '#14C946' } },
      ],
      memberList: [
        { value: 0, name: '本金', money: '', itemStyle: { color: '#51D3CC' } },
        { value: 0, name: '赠金', money: '', itemStyle: { color: '#537FFF' } },
      ],
      dateTime: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      model: {
        beginTime: moment(new Date()).format('YYYY-MM-DD'),
        endTime: moment(new Date()).format('YYYY-MM-DD'),
        storeCode: '',
      },
      businessData: {},
      customerData: {},
      memberData: {
        pageNum: 1,
        pageSize: 10,
        totalRecords: null,
        totalPage: null,
        totalAmount: null,
      },
      memberRevenueList: [],
      stockData: {
        pageNum: 1,
        pageSize: 10,
        totalRecords: null,
        totalPage: null,
      },
      stockList: [],
      paymentList: [],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      this.getInfo(this.model)
      this.getLevelList()
      this.getTableData(this.model)
      this.getStockList(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getInfo(model) {
      api.business(model).then(res => {
        this.businessData = res.data
        this.moneyList[1].value = res.data.goodsPercent || 0
        this.moneyList[0].value = res.data.roomPercent || 0
        this.moneyList[2].value = res.data.storedPercent || 0
        this.getPercentage()
      })
      api.customer(model).then(res => {
        this.customerData = res.data
        this.memberList[0].value = res.data.roomPercent
        this.memberList[1].value = res.data.goodsPercent
        this.memberList[0].money = res.data.roomAmount
        this.memberList[1].money = res.data.goodsAmount
        this.memberData.totalAmount = res.data.totalAmount
        this.drawPie3()
      })
      api.payment(model).then(res => {
        this.paymentList = res.data.map(item => {
          return { ...item, name: item.methodName, value: item.percent, itemStyle: this.getItemStyle(item.methodName) }
        })
        this.getRevenue()
      })
    },
    getItemStyle(name) {
      switch (name) {
        case '微信':
          return { color: '#50B674' }
          break
        case '雷石':
          return { color: '#4C6085' }
          break
        case '余额':
          return { color: '#4BBDFF' }
          break
        case '美团':
          return { color: '#FFBD21' }
          break
        case '现金':
          return { color: '#FF6C6C' }
          break
        case '挂账':
          return { color: '#734EFF' }
          break
      }
    },
    getTableData(model) {
      api.customerList({ ...model, pageNum: this.memberData.pageNum, pageSize: this.memberData.pageSize }).then(res => {
        this.memberRevenueList = res.data.records
        this.memberData.totalRecords = res.data.totalRecords
        this.memberData.totalPage = res.data.totalPage
      })
    },
    handleSizeChangeMember(val) {
      if (this.authMenus.query) {
        this.memberData.pageNum = val
        this.getTableData(this.model)
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    handleCurrentChangeMember(val) {
      if (this.authMenus.query) {
        this.memberData.pageNum = val
        if (this.memberData.totalPage >= 1) {
          this.getTableData(this.model)
        }
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    handleSizeChangeStock(val) {
      if (this.authMenus.query) {
        this.stockData.pageNum = val
        this.getStockList(this.model)
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    handleCurrentChangeStock(val) {
      if (this.authMenus.query) {
        this.stockData.pageNum = val
        if (this.stockData.totalPage >= 1) {
          this.getStockList(this.model)
        }
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    getStockList(model) {
      api.stockList({ ...model, pageNum: this.stockData.pageNum, pageSize: this.stockData.pageSize }).then(res => {
        this.stockList = res.data.records
        this.stockData.totalRecords = res.data.totalRecords
        this.stockData.totalPage = res.data.totalPage
      })
    },
    getStoreList() {
      apiStore.getStoreListByName({ storeName: this.storeCode }).then(res => {
        this.storeList = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
    },
    changeDateTime(val) {
      if (this.authMenus.query) {
        this.model.beginTime = moment(val[0]).format('YYYY-MM-DD')
        this.model.endTime = moment(val[1]).format('YYYY-MM-DD')
        this.memberData.pageNum = 1
        this.stockData.pageNum = 1
        this.getInfo(this.model)
        this.getTableData(this.model)
        this.getStockList(this.model)
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    changeStore(val) {
      if (this.authMenus.query) {
        this.model.storeCode = val
        this.memberData.pageNum = 1
        this.stockData.pageNum = 1
        this.getInfo(this.model)
        this.getTableData(this.model)
        this.getStockList(this.model)
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    getLevelList() {
      api.getLevelList().then(res => {
        this.levelList = res.data.map(item => {
          return { text: item.name, value: item.code }
        })
      })
    },
    getLevelName(level) {
      let obj = this.levelList.find(item => item.value === level)
      return obj && obj.text
    },
    getRevenue() {
      var chartDom = document.getElementById('main')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: '营收数据',
            type: 'pie',
            radius: ['70%', '95%'],
            center: ['50%', '50%'],
            // avoidLabelOverlap: false,
            label: {
              show: true,
              position: 'center',
              formatter: item => {
                return `{percent|${item.percent}%}\n{name|${item.name}}`
              },
              align: 'center',
              color: '#ffffff',
              verticalAlign: 'middle',
              // 此处重点，字体大小设置为0
              textStyle: {
                fontSize: '0',
              },
            },
            emphasis: {
              label: {
                show: true,
                rich: {
                  percent: {
                    fontSize: 40,
                    color: function(data) {},
                  },
                  name: {
                    fontSize: 20,
                    color: function(data) {},
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: this.paymentList,
          },
        ],
      }

      option && myChart.setOption(option)
    },
    filterColor(status, type) {
      switch (status) {
        case '微信':
          return type === 'bg' ? 'background-color: #50B674' : 'color: #50B674'
          break
        case '现金':
          return type === 'bg' ? 'background-color: #FF6C6C' : 'color: #FF6C6C'
          break
        case '挂账':
          return type === 'bg' ? 'background-color: #734EFF' : 'color: #734EFF'
          break
        case '支付宝':
          return type === 'bg' ? 'background-color: #3670FF' : 'color: #3670FF'
          break
        case '余额':
          return type === 'bg' ? 'background-color: #4BBDFF' : 'color: #4BBDFF'
          break
        case '美团':
          return type === 'bg' ? 'background-color: #FFBD21' : 'color: #FFBD21'
          break
        case '雷石':
          return type === 'bg' ? 'background-color: #4C6085' : 'color: #4C6085'
          break
      }
    },
    getPercentage() {
      var chartDom1 = document.getElementById('circle-percentage1')
      var myChart1 = echarts.init(chartDom1)
      var option1

      option1 = {
        tooltip: {
          trigger: 'none',
        },
        legend: {
          type: 'plain',
          show: true,
          orient: 'vertical',
          right: 30,
          top: '30%',
          icon: 'circle',
          itemHeight: 10,
          align: 'left',
          padding: [0, 20, 50, 0],
          width: '50%',
          textStyle: {
            fontSize: 16,
          },
          formatter: params => {
            //格式化数据的函数
            for (let a = 0; a < this.moneyList.length; a++) {
              // this.pieXY  这个数据中有名称和次数\
              if (this.moneyList[a].name == params) {
                //两个名称进行对比，取出对应的次数
                return params + ' :  ' + this.moneyList[a].value + '%' //然后return你需要的legend格式即可
              }
            }
          },
        },

        series: [
          {
            left: 0,
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '90%'],
            center: ['44%', '50%'],
            avoidLabelOverlap: false,
            top: 20,
            right: '45%',
            bottom: 20,
            left: 0,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '20',
                fontWeight: 'bold',
              },
            },
            labelLine: {
              show: false,
            },
            data: this.moneyList,
          },
        ],
      }

      option1 && myChart1.setOption(option1)
    },
    getTotalAmount() {
      return '¥' + this.memberData.totalAmount + '\n会员消费'
    },
    drawPie3() {
      var chartDom2 = document.getElementById('drawpie3')
      var myChart2 = echarts.init(chartDom2)
      var option2

      option2 = {
        color: ['#51D3CC', '#537FFF'],
        tooltip: {
          trigger: 'none',
        },
        graphic: [
          {
            type: 'text',
            id: 'text1',
            top: '38%',
            left: 'center',
            style: {
              text: '¥' + this.memberData.totalAmount,
              fill: '#393939',
              fontSize: 30,
              fontWeight: 'normal',
            },
          },
          {
            type: 'text',
            id: 'text3',
            top: '50%',
            left: 'center',
            style: {
              text: '会员消费',
              fill: '#949494',
              fontSize: 18,
              fontWeight: 'normal',
            },
          },
        ],
        legend: {
          width: '100%',
          bottom: '0%',
          overflow: 'break',
          icon: 'circle',
          textStyle: {
            fontSize: 14,
            //在rich中给formatter添加个别字体颜色
          },
          formatter: params => {
            //格式化数据的函数
            for (let a = 0; a < this.memberList.length; a++) {
              // this.pieXY  这个数据中有名称和次数
              if (this.memberList[a].name == params) {
                //两个名称进行对比，取出对应的次数
                return params + ' :  ¥' + this.memberList[a].money //然后return你需要的legend格式即可
              }
            }
          },
        },
        series: [
          {
            left: 0,
            name: 'Access From',
            type: 'pie',
            radius: ['60%', '80%'],
            center: ['50%', '50%'],
            avoidLabelOverlap: false,
            top: 0,
            // right: '10%',
            bottom: 20,
            left: 0,
            label: {
              normal: {
                show: false,
                position: 'center',
                formatter: this.getTotalAmount,
                textStyle: {
                  fontSize: 30,
                  color: '#393939',
                },
                rich: {
                  x: {
                    fontSize: 18,
                    fontFamily: 'Microsoft YaHei',
                    borderColor: '#449933',
                    borderRadius: 4,
                  },
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: this.memberList,
          },
        ],
      }

      option2 && myChart2.setOption(option2)
    },
  },
}
</script>

<style lang="scss" scoped>
.business-data-box {
  box-sizing: border-box;
  padding: 16px 42px;
  clear: both;

  .pagination-style {
    float: right;
    padding: 10px 0px;
  }

  .search-content-box {
    .search-title {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-right: 12px;
    }

    .search-select {
      margin-right: 26px;
    }
  }

  .business-data-top {
    display: flex;
    margin-top: 25px;
    height: 245px;
    border-radius: 6px;

    .business-data-left {
      width: 1062px;
      background: #ffffff;
      display: flex;
      flex-direction: row;

      div:first-child {
        padding-right: 34px;
        padding-top: 56px;

        h2 {
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #393939;
        }
      }

      .border-right-line {
        display: inline-block;
        width: 1px;
        height: 158px;
        margin-top: 46px;
        background: linear-gradient(to top, #eaeaea, #dedede, #eaeaea);
      }

      div {
        box-sizing: border-box;
        padding-left: 29px;
        padding-top: 56px;
        flex: 1;

        p {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #949494;
        }

        p:last-child {
          font-size: 16px;
        }

        h2 {
          font-size: 30px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #393939;
          margin: 20px 0px;
        }
      }
    }

    .business-data-center {
      width: 30px;
    }

    .business-data-right {
      flex: 1;
      background: #ffffff;
      display: flex;

      .circle-percentage {
        height: 245px;
        width: 100%;
      }

      .canvas-lengend {
        flex: 1;
        display: flex;
        // flex-wrap: wrap;
        padding-left: 30px;

        .lengend-list {
          width: 50%;
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          .lengend-circle {
            display: inline-block;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            margin-right: 20px;
          }

          .lengend-text {
            flex: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;

            .name-item {
              width: 30%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
          }
        }
      }
    }
  }

  .revenue-data-box {
    display: flex;
    flex-direction: row;
    margin-top: 30px;
    margin-bottom: 30px;

    .revenue-data-center {
      width: 30px;
    }

    .revenue-data-left {
      width: 930px;
      height: 448px;
      background: #ffffff;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 31px 17px;

      .revenue-circle {
        width: 100%;
        height: 310px;
        display: flex;
        align-items: center;

        .circle-percentage {
          width: 40%;
          height: 310px;
        }

        .canvas-lengend {
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          padding-left: 30px;

          .lengend-list {
            width: 50%;
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            .lengend-circle {
              display: inline-block;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              margin-right: 20px;
            }

            .lengend-text {
              flex: 1;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;

              .name-item {
                width: 30%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
              }
            }
          }
        }
      }

      .title {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #303030;
        margin-bottom: 40px;

        span {
          display: inline-block;
          width: 6px;
          height: 20px;
          background: #2d65e3;
          border-radius: 3px;
          margin-right: 12px;
        }
      }
    }

    .revenue-data-right {
      flex: 1;
      background: #ffffff;
      border-radius: 6px;
      box-sizing: border-box;
      padding: 31px 17px;
      position: relative;

      .member-info-list {
        display: flex;
        position: absolute;
        right: 51px;
        top: 138px;

        div {
          flex: 1;

          p:first-child {
            font-size: 20px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #1b1b1b;
            margin-bottom: 10px;
          }

          p:last-child {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #949494;
            margin-bottom: 60px;
          }
        }

        width: 250px;
      }

      .title {
        font-size: 20px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #303030;

        span {
          display: inline-block;
          width: 6px;
          height: 20px;
          background: #2d65e3;
          border-radius: 3px;
          margin-right: 12px;
        }
      }
    }
  }

  .business-data-table2 {
    margin-top: 30px;
  }
}
</style>
