<template>
  <div class="table-search-tab" style="height: 76px;line-height: 76px;">
    <div class="search-left">
      <el-input
        placeholder="请输入姓名"
        v-model="data.userName"
        class="input-search-tel"
        @keyup.enter="searchLists({ currentPage: 1 })"
      ></el-input>
      <el-input
        placeholder="请输入手机号"
        v-model="data.cellPhone"
        class="input-search-tel"
        @keyup.enter="searchLists({ currentPage: 1 })"
      ></el-input>
      <el-cascader
        ref="orgSelect"
        :options="data.orgs"
        :props="defaultProp"
        placeholder="请选择组织"
        multiple
        clearable
        filterable
        v-model="data.orgIds"
        style="width:400px"
        collapse-tags
        :collapse-tags-tooltip="true"
        @change="searchLists({ currentPage: 1 })"
      />
      <el-select-v2
        class="userSelect"
        v-model="data.roleCode"
        :options="data.roles"
        placeholder="请选择角色"
        style="width: 400px;margin-left: 20px;"
        multiple
        clearable
        filterable
        collapse-tags
        :collapse-tags-tooltip="true"
        @change="searchLists({ currentPage: 1 })"
      />
      <el-button
        type="primary"
        class="search-button"
        style="margin-left: 20px;"
        v-if="data.authMenus.query"
        @click="searchLists({ currentPage: 1 })"
        >查询</el-button
      >
      <el-button class="search-button" @click="resetQuery">重置</el-button>
    </div>
    <div class="search-right">
      <el-button type="primary" @click="addUser" v-if="data.authMenus.add"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
    </div>
  </div>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" />
    <el-table-column prop="userName" label="姓名" align="center" show-overflow-tooltip />
    <el-table-column label="性别" align="center" width="50">
      <template #default="scope">
        {{ scope.row.gender === 1 ? '男' : '女' }}
      </template>
    </el-table-column>
    <el-table-column prop="cellPhone" label="手机号" align="center" show-overflow-tooltip />
    <el-table-column prop="staffId" label="工号" align="center" show-overflow-tooltip />
    <el-table-column prop="email" label="邮箱" align="center" show-overflow-tooltip />
    <el-table-column label="所属组织" align="center" width="220px">
      <template #default="scope">
        <el-tag
          v-for="(item, index) in scope.row.authOrg"
          :key="index"
          style="cursor: pointer; margin-right: 10px; margin-top: 5px;margin-bottom: 5px; max-width: 200px;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
          :title="item.name"
        >
          {{ item.name }}</el-tag
        >
      </template>
    </el-table-column>
    <el-table-column label="用户角色" align="center" width="220px">
      <template #default="scope">
        <el-tag
          v-for="(item, index) in scope.row.authRoles"
          type="success"
          :key="index"
          :title="item.roleName"
          style="cursor: pointer; margin-right: 10px; margin-top: 5px;margin-bottom: 5px; max-width: 200px;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
        >
          {{ item.roleName }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="updateTime" label="更新时间" align="center" show-overflow-tooltip />
    <el-table-column label="状态" align="center">
      <template #default="scope">
        <el-tag :type="scope.row.state === 10 ? 'success' : 'error'">{{
          scope.row.state === 10 ? '启用' : '停用'
        }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="300" align="center">
      <template #default="scope">
        <el-button link type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update">修改</el-button>
        <el-button type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.update">{{
          scope.row.state === 10 ? '停用' : '启用'
        }}</el-button>
        <el-button link type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.resetPwd"
          >重置密码</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddUser
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :userMsg="data.userMsg"
    :isEdit="data.isEdit"
    :roles="data.roles"
    :orgs="data.orgs"
    :stores="data.stores"
    @update-dialogVisible="updateDialogVisible"
  ></AddUser>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import clearSelect from '@/lin/util/clearSelect2.js'
import _ from 'lodash'
import { reactive, computed, onMounted, ref } from 'vue'
import AddUser from './addUser/index.vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { nextTick } from 'process'
import { useRoute } from 'vue-router'
import resetPasswordVue from '../../../../view/center/resetPassword.vue'

const data = reactive({
  userName: undefined,
  cellPhone: undefined,
  orgIds: [],
  roleCode: [],
  roles: [],
  orgs: [],
  stores: [],
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 100,
  dialogVisible: false,
  userMsg: { roleCode: [], orgIds: [], stores: [], regionalList: [] },
  isEdit: false,
  authMenus: {},
  loading: false,
})
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists({})
  getRoles()
  getOrganizationalByParentCode()
  getAreaOrganizational()
})

//获取组织
const getOrganizationalByParentCode = () => {
  API.getOrganizationalByParentCode({ isOnlyEnable: 1, parentCode: 0 }).then(res => {
    if (res.code === '200') {
      data.orgs = res.data.map(item => ({
        label: item.name,
        value: item.code,
        ...item,
      }))
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//获取门店
const getAreaOrganizational = () => {
  API.getAreaOrganizational({ isOnlyEnable: 1 }).then(res => {
    if (res.code === '200') {
      data.stores = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const orgSelect = ref(null)
const resetQuery = () => {
  data.userName = undefined
  data.cellPhone = undefined
  data.orgIds.length = 0
  data.roleCode.length = 0
  nextTick(() => {
    clearSelect('userSelect')
    orgSelect.value.panel.clearCheckedNodes()
  })
  searchLists({})
}

const searchLists = ({ currentPage }) => {
  if (currentPage) {
    data.currentPage = currentPage
  }
  if (data.authMenus.query) {
    data.loading = true
    let query = {
      userName: data.userName,
      cellPhone: data.cellPhone,
      orgIds: data.orgIds.map(a => a[a.length - 1]).join(','),
      roleCode: data.roleCode,
      pageNum: currentPage || data.currentPage,
      pageSize: data.pageSize,
    }

    API.getAuthUserPage(query).then(res => {
      if (res.code === '200') {
        data.tableData = res.data.records
        data.total = res.data.total
        data.loading = false
      } else {
        ElMessage.error(res.msg)
        data.loading = false
      }
    })
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

const defaultProp = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  checkStrictly: true,
  multiple: true,
  filterable: true,
})

//获取角色列表
const getRoles = () => {
  API.getAuthRolePage({ pageSize: -1 }).then(res => {
    if (res.code === '200') {
      data.roles = res.data.records.map(item => ({
        label: item.roleName,
        value: item.roleCode,
        ...item,
      }))
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

//添加
const addUser = () => {
  data.userMsg = { roleCode: [], orgIds: [], stores: [], regionalList: [], gender: 1, state: 10, myPassWord: 123456 }
  data.dialogVisible = true
  data.isEdit = true
}
const showRow = rowMsg => {
  data.isEdit = false
  data.userMsg = rowMsg
  data.dialogVisible = true
}
const editRow = rowMsg => {
  data.isEdit = true
  data.userMsg = rowMsg
  data.dialogVisible = true
}
const deleteRow = rowMsg => {
  ElMessageBox.confirm('确认删除当前用户吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    API.deleteUser([rowMsg.id]).then(res => {
      if (res.code === '200') {
        ElMessage.success('删除成功')
        searchLists({})
      } else {
        ElMessage.error(res.msg)
      }
    })
  })
}

const startOrStopRow = rowMsg => {
  API.updateState({ code: rowMsg.code, id: rowMsg.id, state: rowMsg.state === 10 ? 0 : 10 }).then(res => {
    if (res.code === '200') {
      ElMessage.success('更新成功')
      searchLists({})
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const resetPassword = rowMsg => {
  API.resetSaasUserSecret({ userCode: rowMsg.code }).then(res => {
    if (res.code === '200') {
      ElMessage.success('密码重置成功')
    } else {
      ElMessage.error(res.msg)
    }
  })
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  rowMsg.roleCode = []
  rowMsg.orgs = []
  rowMsg.stores = []
  rowMsg.authRoles?.forEach(item => rowMsg.roleCode.push(item.roleCode))
  rowMsg.authOrg?.forEach(item => rowMsg.orgs.push(item.code))
  rowMsg.authStores?.forEach(item => rowMsg.stores.push(item.code))
  switch (index) {
    case 1:
      resetPassword(rowMsg)
      break
    case 2:
      if (data.authMenus.update) {
        editRow(rowMsg)
      } else {
        ElMessage.warning('抱歉，你没有改权限')
      }
      break
    case 3:
      if (data.authMenus.update) {
        startOrStopRow(rowMsg)
      } else {
        ElMessage.warning('抱歉，你没有改权限')
      }
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    searchLists({})
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists({})
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists({})
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 70%;
    display: flex;
    align-items: center;
    span {
      width: 150px;
      margin-left: 20px;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-right: 20px;
      &:first-child {
        margin-left: 20px;
      }
    }
    .search-button {
      width: 144px;
      height: 25px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
    .search-button {
      width: 144px;
      height: 25px;
    }
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
