<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="三方物料编号">
      <el-input placeholder="物料编号" v-model="data.materielCode" clearable class="input-search-tel"></el-input>
    </el-form-item>

    <el-form-item label="三方物料名称">
      <el-input placeholder="物料名称" v-model="data.materielName" clearable class="input-search-tel"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" type="primary">
        查询
      </el-button>
      <el-button @click="resetFilter">
        重置
      </el-button>
      <el-button @click="mySyncData"> 自定义同步物料 </el-button>
      <el-button @click="addUnitConfig"> 全量同步物料 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="data.loadText"
  >
    <el-table-column label="序号" width="100" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column prop="spuName" width="200" label="关联商品名称" align="center" />
    <el-table-column prop="skuName" width="200" label="关联单品名称" align="center" />
    <el-table-column prop="skuSpecification" width="200" label="关联单品规格" align="center" />
    <el-table-column prop="materielCode" width="200" label="三方物料编号" align="center" />
    <el-table-column prop="categoryCode" width="200" label="后台类目编号" align="center" />
    <el-table-column prop="materielName" width="200" label="三方物料名称" align="center" />
    <el-table-column prop="specification" width="100" label="规格" align="center" />
    <el-table-column prop="conversionRatio" width="100" label="换算比例" align="center" />
    <el-table-column prop="brandNames" width="100" label="品牌" align="center" />
    <el-table-column prop="unitName" width="100" label="主单位" align="center" />
    <el-table-column prop="sellingMaterials" width="200" label="销售物料编码" align="center" />
    <el-table-column prop="sellingMaterialsName" width="200" label="销售物料名称" align="center" />
    <el-table-column prop="assistMeasurementUnitName" width="100" label="辅单位" align="center" />
    <el-table-column prop="remark" width="200" label="备注" align="center" />
    <el-table-column prop="attributesName" width="200" label="物资属性名称" align="center" />
    <el-table-column prop="statusName" width="100" label="状态" align="center" />
    <el-table-column prop="attributeTypeName" width="200" label="物料属性类型" align="center" />
    <el-table-column width="200" label="最近同步时间" align="center">
      <template #default="scope">
        <span>{{ scope.row.startTime }}~{{ scope.row.endTime }}</span>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />

  <!--  重新计算-->
  <el-dialog title="数据同步" v-model="data.dialogVisible" width="600px" class="dialog-form">
    <el-form label-width="80px" class="dialog-form" :model="syncData">
      <p style="color: red;margin-bottom: 10px;">{{ data.des }}</p>
      <el-form-item label="同步时间">
        <el-date-picker
          v-model="data.asyncData.time"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
          :disabled="data.isAllUpdate"
        >
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button type="primary" @click="handleSureSync">确 定</el-button>
      <el-button @click="data.dialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import API from '../../service/api'
import { reactive, onMounted, computed } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'

const data = reactive({
  materielCode: undefined,
  materielName: undefined,
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  dialogVisible: false,
  loading: false,
  loadText: '正在火速加载数据...',
  des: '',
  isAllUpdate: false,
  asyncData: {
    time: [
      dayjs()
        .set('hour', 8)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD'),
      dayjs()
        .set('hour', 8)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD'),
    ],
  },
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}
const resetFilter = () => {
  data.materielCode = undefined
  data.materielName = undefined
  searchLists()
}

const queryLists = () => {
  data.loading = true
  data.loadText = '正在火速加载数据...'
  if (data.authMenus.query) {
    let queryParams = {
      pageSize: data.pageSize,
      pageNum: data.currentPage,
      materielCode: data.materielCode,
      materielName: data.materielName,
    }
    API.querySupplierMaterielInfo(queryParams)
      .then(res => {
        if (res.code === '200') {
          data.loading = false
          data.tableData = res.data.records
          data.total = res.data.total
        } else {
          ElMessage.error(res.msg)
        }
      })
      .finally(() => (data.loading = false))
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//数据同步
const handleSureSync = async () => {
  if (!Array.isArray(data.asyncData.time)) {
    return ElMessage.error('请选择同步时间')
  }

  data.dialogVisible = false

  data.loading = true
  data.loadText = '正在火速处理数据...'
  try {
    let res = await API.syncSupplierMateriel({
      startTime: data.asyncData.time[0],
      endTime: data.asyncData.time[1],
    })
    if (res.code == 200) {
      ElMessage.success('操作成功')
    } else {
      ElMessage.error(res.msg)
    }
  } finally {
    data.loading = false
  }
}

//全量同步
const addUnitConfig = async () => {
  data.asyncData = {
    time: [dayjs('2024-01-01').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
  }
  data.isAllUpdate = true
  data.des = '即将从三方同步全量物料数据，耗时可能较长，是否继续？'
  data.dialogVisible = true
}

//自定义同步
const mySyncData = () => {
  data.asyncData = {
    time: [
      dayjs()
        .set('hour', 8)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD'),
      dayjs()
        .set('hour', 8)
        .set('minute', 0)
        .set('second', 0)
        .format('YYYY-MM-DD'),
    ],
  }
  data.isAllUpdate = false
  data.des = '即将同步更新时间在此范围内的物料数据'
  data.dialogVisible = true
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  justify-content: flex-end;

  .search-right {
    margin-right: 20px;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}

.dialog-footer {
  display: flex;
  justify-content: center;
  .el-button {
    width: 100px;
    &:first-child {
      margin-right: 10px;
    }
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
