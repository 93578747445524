<!--
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-07-17 10:08:23
 * @LastEditTime: 2024-07-25 12:39:13
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/plugin/cms-manager/view/song-manager/index.vue
 * @Description:
-->
<template>
  <div class="song-page">
    <!-- 版本信息 -->
    <!-- <div class="version">
      <div class="version-item " @click="handleClickTab(0)" :class="{ active: activeTab == 0 ? true : false }">
        V1.0
      </div>
      <div class="version-item" @click="handleClickTab(1)" :class="{ active: activeTab == 1 ? true : false }">V2.0</div>
    </div> -->

    <!-- 歌曲类型 -->
    <div class="song-top">
      <div class="song-titles">
        <div
          class="song-title-item"
          v-for="item in menus"
          :class="{ active: item.menuCode == activeMenuCode ? true : false }"
          :key="item.menuCode"
          @click="handleClickMenu(item)"
        >
          <div>{{ item.menuName }}</div>
        </div>
      </div>
      <div class="song-btn" @click="handleAdd" v-if="authMenus.add">
        新增
      </div>
    </div>

    <!-- 表格数据 -->
    <el-table :data="songs">
      <el-table-column label="歌曲名称" prop="name" align="center"></el-table-column>
      <el-table-column label="雷石歌曲ID" prop="tsCode" align="center"></el-table-column>
      <el-table-column label="封面图片" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;">
            <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="是否推荐" prop="isRecommend" align="center"> </el-table-column>
      <el-table-column label="排序" prop="sort" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button type="text" @click="handleUpdateSong(scope.row)" v-if="authMenus.query">编辑</el-button>
          <el-button type="text" @click="handleDeleteSong(scope.row)" v-if="authMenus.delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />

    <AddOrUpdate
      :proRowMsg="proRowMsg"
      :dialogVisible="dialogVisible"
      :menus="menus"
      @update-dialogVisible="updateDialogVisible"
      :version="activeTab"
    ></AddOrUpdate>
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import API from '../../service/api'
import AddOrUpdate from './addOrUpdate.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
export default {
  components: {
    AddOrUpdate,
  },
  data() {
    return {
      authMenus: {},
      activeTab: 0,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      proRowMsg: {},
      menus: [],
      songs: [],
      activeMenuCode: '',
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    await this.queryOpenMenu()
    this.queryLists()
  },
  methods: {
    async handleClickTab(activeTab) {
      this.activeTab = activeTab
      this.clearPage()
      await this.queryOpenMenu()
      this.queryLists()
    },
    clearPage() {
      this.currentPage = 1
    },
    handleClickMenu(data) {
      this.clearPage()
      this.activeMenuCode = data.menuCode
      this.queryLists()
    },
    async queryOpenMenu() {
      let res = await API.queryOpenMenu()
      if (res.code == 200) {
        // this.menus = res.data
        this.menus = res.data.map(ii => {
          return Object.assign(ii, {
            menuCode: ii.code,
            menuName: ii.name,
          })
        })
        this.activeMenuCode = res.data[0].menuCode
      }
    },
    async queryLists() {
      // const params = {
      //   menuCode: this.activeMenuCode,
      //   pageSize: this.pageSize,
      //   pageNum: this.currentPage,
      //   version: this.activeTab,
      // }
      const params = {
        criteria: { menusCode: this.activeMenuCode },
        pageSize: this.pageSize,
        pageNum: this.currentPage,
      }
      let res = await API.queryVoSongList(params)
      if (res.code == 200) {
        this.songs = res.data.items.map(item => ({
          ...item,
          isRecommend: item.isRecommend == 1 ? '是' : '否',
        }))
        this.total = res.data.total
      }
    },
    updateDialogVisible({ update, search }) {
      this.dialogVisible = update
      if (search) {
        this.queryLists()
      }
    },
    handleAdd() {
      this.dialogVisible = true
      this.proRowMsg = {}
    },
    async handleUpdateSong(rowMsg) {
      this.dialogVisible = true
      let res = await API.queryVodSongDetail({ id: rowMsg.id })
      if (res.code == 200) {
        this.proRowMsg = res.data
      } else {
        ElMessage.error(res.msg)
      }
    },
    handleDeleteSong(rowMsg) {
      ElMessageBox.confirm('确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          API.deleteVodSong({
            id: rowMsg.id,
          }).then(res => {
            if (res.code === '200') {
              ElMessage.success('删除成功')
              this.queryLists()
            } else {
              ElMessage.error(res.msg)
            }
          })
        })
        .catch(() => {})
    },
    //分页
    handleSizeChange(val) {
      this.pageSize = val
      this.queryLists()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.queryLists()
    },
  },
}
</script>

<style lang="scss" scoped>
.song-page {
  padding: 20px;

  .version {
    display: flex;
    .version-item {
      width: 150px;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      background-color: #fff;
      overflow: hidden;
      color: #303133;
      transition: 0.3s;
      cursor: pointer;
      &:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      }
    }
    .active {
      background-color: #2780f1;
      color: white;
    }
  }

  .song-top {
    display: flex;
    justify-content: space-between;
    .song-titles {
      display: flex;
      flex-wrap: wrap;
      .song-title-item {
        width: 100px;
        padding: 10px;
        box-sizing: border-box;
        margin-bottom: 10px;
        text-align: center;
        font-size: 14px;
        border-radius: 4px;
        border: 1px solid #ebeef5;
        background-color: #fff;
        overflow: hidden;
        color: #303133;
        transition: 0.3s;
        cursor: pointer;
        &:hover {
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        }
      }

      .active {
        background-color: #2780f1;
        color: white;
      }
    }
    .song-btn {
      width: 100px;
      height: 36px;
      padding: 10px;
      box-sizing: border-box;
      margin-bottom: 10px;
      text-align: center;
      font-size: 14px;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      background-color: #2780f1;
      overflow: hidden;
      color: white;
      cursor: pointer;
    }
  }
  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
