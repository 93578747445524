const JyPzCmsRouter = {
  route: null,
  name: null,
  title: '门店管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/cashmanager/',
  order: null,
  inNav: true,
  children: [
    {
      title: '门店区域管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/jypz_manager/mdqy-manager',
      filePath: 'plugin/jypz_manager/view/xjypzgl/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default JyPzCmsRouter
