<template>
  <div class="customer-source">
    <!-- 搜索条件 -->
    <el-form :inline="true">
      <el-form-item label="操作人">
        <el-input placeholder="请输入操作人" v-model="filterTerm.createUserName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="selectInfoPage" v-if="authMenus.query">查询</el-button>
        <el-button @click="resetFilter">重置</el-button>
        <el-button @click="addCustomerSource" v-if="authMenus.add" type="primary">新增发券</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格数据 -->
    <el-table :data="tableData">
      <el-table-column label="优惠券名称" prop="couponNames" align="center"></el-table-column>
      <el-table-column label="发放手机号" prop="telephoneCount" align="center"></el-table-column>
      <el-table-column label="操作人" prop="createUserName" align="center"></el-table-column>
      <el-table-column label="发券时间" prop="createTime" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button type="text" @click="updateCustomerSource(scope.row)" v-if="authMenus.query">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="filterTerm.pageNum"
      v-model:page-size="filterTerm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="filterTerm.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdate
    :authMenus="authMenus"
    v-if="dialogVisible"
    :updateRowMsg="needUpdateInfo"
    @handleCloseDialog="handleCloseOrOpenDialog"
    :isEdit="isEdit"
  ></addOrUpdate>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { onMounted, reactive, ref, toRaw } from 'vue'
import { useRoute } from 'vue-router'
import addOrUpdate from './update.vue'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
const authMenus = ref([])
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  console.log(toRaw(authMenus.value))

  selectInfoPage()
})
/** 需要更新的数据---dialog展示框展示*/
const needUpdateInfo = ref({})
const dialogVisible = ref(false)
/**
 * 关闭or打开dialog展示框
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  selectInfoPage()
}

/**过滤条件 */
const filterTerm = reactive({
  createUserName: '',
  pageNum: 1,
  pageSize: 10,
  total: 0,
})

/**
 * 重制
 */
const resetFilter = () => {
  filterTerm.createUserName = ''
  selectInfoPage()
}

/**
 * 获取表格信息
 */
const selectInfoPage = async () => {
  if (authMenus.value.query) {
    let res = await API.pageSendCouponLog(filterTerm)
    if (res.code === '200') {
      tableData.value = res.data.records
      filterTerm.total = res.data.totalRecords
    } else {
      ElMessage.error(res.msg)
    }
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
const isEdit = ref(false)
/**
 * 新增
 */
const addCustomerSource = () => {
  needUpdateInfo.value = { sendCoupnMapList: [], storeCode: '' }
  if (!authMenus.value.headCoupon && !authMenus.value.storeCoupon) {
    return ElMessage.warning('抱歉，请先设置短信发送方权限后发送短信')
  }
  if (authMenus.value.headCoupon) {
    needUpdateInfo.value.type = 1
  } else if (authMenus.value.storeCoupon) {
    needUpdateInfo.value.type = 2
  }

  isEdit.value = true
  handleCloseOrOpenDialog(true)
}
/**
 * 详情
 */
const updateCustomerSource = async data => {
  let res = await API.querySendCouponLogDetails({
    code: data.code,
  })
  if (res.code == 200) {
    needUpdateInfo.value = {
      sendCoupnMapList: res.data.ruleVOList,
      telephones: res.data.telephones,
      type: res.data.type,
      storeCode: res.data.storeCode,
    }
    isEdit.value = false
    handleCloseOrOpenDialog(true)
  } else {
    ElMessage.error(res.msg)
  }
}

/**
 * 表单数据
 */
const tableData = ref([])

//分页
const handleSizeChange = val => {
  filterTerm.pageSize = val
  selectInfoPage()
}
const handleCurrentChange = val => {
  filterTerm.pageNum = val
  selectInfoPage()
}
</script>

<style scoped lang="scss">
.customer-source {
  padding: 20px 10px;
  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
