<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="分类名称">
      <el-input
        placeholder="请输入名称"
        v-model="data.typeName"
        style="margin-left: 10px; width: 200px;"
        @keyup.enter="queryLists()"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-button @click="queryLists">
        查询
      </el-button>
      <el-button @click="reset">
        重置
      </el-button>
      <el-button type="primary" @click="addShop" v-if="data.authMenus.add"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column prop="name" label="分类名称" align="center" />
    <el-table-column prop="sort" label="显示排序(数字小优先显示)" align="center" />
    <el-table-column label="操作" width="250" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <!-- <el-button type="text" @click="showDetail(2, scope.row)">删除</el-button> -->
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddPerishableArticlesTypes
    :dialogVisible="data.dialogVisible"
    :articlesRowMsg="data.articlesRowMsg"
    :isEdit="data.isEdit"
    @update-dialogVisible="updateDialogVisible"
  ></AddPerishableArticlesTypes>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { reactive, onMounted, computed, toRaw } from 'vue'
import AddPerishableArticlesTypes from './AddPerishableArticlesTypes.vue'
const data = reactive({
  authMenus: {},
  typeName: undefined,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 100,
  dialogVisible: false,
  articlesRowMsg: {},
  isEdit: false,
  loading: false,
})
const route = useRoute()
onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
})

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const reset = () => {
  data.typeName = undefined
  queryLists()
}
const queryLists = () => {
  data.currentPage = 1
  searchLists()
}
const searchLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let query = {
    typeName: data.typeName,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    type: 4,
  }

  API.selectByType(query)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

//添加
const addShop = () => {
  data.dialogVisible = true
  data.articlesRowMsg = {
    sort: 1,
  }
  data.isEdit = true
}
const editRow = rowMsg => {
  data.isEdit = true
  data.articlesRowMsg = rowMsg
  data.dialogVisible = true
}
const deleteRow = rowMsg => {
  ElMessageBox.confirm('确认删除当前所选的商品分类吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    API.deleteByCode({ code: rowMsg.code }).then(res => {
      if (res.code === '200') {
        ElMessage.success('删除成功')
        searchLists()
      } else {
        ElMessage.error(res.msg)
      }
    })
  })
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      editRow(rowMsg)
      break
    case 2:
      deleteRow(rowMsg)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    searchLists()
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  .search-left {
    display: flex;
    align-items: center;
    .title {
      text-align: right;
      width: 100px;
      margin-left: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
