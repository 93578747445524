<template>
  <div class="searchheader" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form ref="SearchForm" :model="fform" label-width="100px" label-position="right">
      <el-row :gutter="10">
        <el-col v-for="item in formItemData" :key="item.prop" :span="item.span || 8">
          <!-- 输入框组件 -->
          <el-form-item :label="item.label">
            <template v-if="item.type === 'input'">
              <el-input style="width:100%" v-model="fform[item.prop]" :placeholder="item.placeholder" />
            </template>
            <!-- 日期组件 -->
            <template v-else-if="item.type === 'datetime'">
              <el-date-picker
                v-model="fform[item.prop]"
                value-format="YYYY-MM-DD HH:mm:ss"
                type="datetime"
                placeholder="选择时间"
                style="width:100%"
              />
            </template>
            <template v-else-if="item.type === 'datetimerange'">
              <el-date-picker
                v-model="fform[item.prop]"
                type="datetimerange"
                value-format="YYYY-MM-DD HH:mm:ss"
                style="width:100%;"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </template>
            <template v-else-if="item.type === 'select'">
              <el-select v-model="fform[item.prop]" placeholder="请选择" style="width:100%">
                <el-option v-for="s in item.selectDatas" :key="s.value" :label="s.label" :value="s.value" />
              </el-select>
            </template>
            <template v-else>
              <slot name="item" />
            </template>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="   ">
            <slot name="button" />
            <el-button type="primary" v-if="authMenus.query" @click="handleSearch">查询</el-button>
            <el-button type="" @click="handleReset">重置</el-button>
            <el-button v-if="authMenus.export" @click="handleExport">导出</el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'SearchForm',
  props: {
    fullscreenLoading: {
      type: Boolean,
      default: false,
    },
    formItemData: {
      type: Array,
      default: () => {
        return []
      },
    },
    form: {
      type: Object,
      default: () => {
        return {}
      },
    },
    authMenus: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      fform: {},
    }
  },
  watch: {
    fform: {
      handler(nv) {
        // this.fform = nv
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    this.handleReset()
  },
  methods: {
    handleReset() {
      this.formItemData.forEach(ele => {
        Object.assign(this.fform, {
          [ele.prop]: ele.value,
        })
      })
      this.$emit('reset', this.fform)
    },
    handleSearch() {
      this.$emit('search', this.fform)
    },
    handleExport() {
      this.$emit('export', this.fform)
    },
  },
}
</script>

<style lang="scss" scoped>
.searchheader {
  box-sizing: border-box;
  padding-top: 20px;
  // height: 65px;
  // line-height: 65px;
  width: 100%;
  display: block;

  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
    .el-form-item__label {
      width: 105px !important;
    }
    .el-date-editor .el-range-input {
      width: 58% !important;
    }
  }
}
</style>
