<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="营业收入项" name="revenueReceiptsAuth" v-if="isShowReport('revenueReceiptsAuth')">
      <revenueReceipts :auths="authType.revenueReceiptsAuth" />
    </el-tab-pane>
    <el-tab-pane label="成本项" name="costItemAuth" v-if="isShowReport('costItemAuth')">
      <costItem :auths="authType.costItemAuth" />
    </el-tab-pane>
    <el-tab-pane label="利润项" name="topLineItemAuth" v-if="isShowReport('topLineItemAuth')">
      <topLineItem :auths="authType.topLineItemAuth" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import revenueReceipts from '../report_module/revenueReceipts.vue'
import costItem from '../report_module/costItem.vue'
import topLineItem from '../report_module/topLineItem.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  revenueReceiptsAuth: {}, // 营业收入项
  costItemAuth: {}, // 成本项
  topLineItemAuth: {}, //利润项
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.revenueReceiptsAuth = authAllMenus.find(a => a.url === 'revenueReceiptsAuth') || { childrens: [] }
  authType.costItemAuth = authAllMenus.find(a => a.url === 'costItemAuth') || { childrens: [] }
  authType.topLineItemAuth = authAllMenus.find(a => a.url === 'topLineItemAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsIncomeAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
