<template>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column prop="name" label="出库类型名称" align="center" />
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import API from '../../service/api'
import { reactive, onMounted, computed } from 'vue'
import { useRoute } from 'vue-router'

const data = reactive({
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryOutTypes()
})

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

//  查询出库类型
const queryOutTypes = () => {
  data.loading = true
  if (data.authMenus.query) {
    let queryParams = {
      pageSize: data.pageSize,
      current: data.currentPage,
      type: 1,
    }
    API.selectChannel(queryParams)
      .then(res => {
        if (res.code === '200') {
          data.loading = false
          data.tableData = res.data.records
          data.total = res.data.total
        } else {
          ElMessage.error(res.msg)
        }
      })
      .finally(() => (data.loading = false))
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryOutTypes()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryOutTypes()
}
</script>

<style scoped>
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
.table-content >>> tr {
  line-height: 40px;
  height: 60px;
}
</style>
