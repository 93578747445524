<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="分类名称">
      <el-input v-model="data.typeName" placeholder="请填写分类名称" @keyup.enter="resetQuery" />
    </el-form-item>
    <el-form-item>
      <el-button @click="resetQuery">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
      <el-button type="primary" @click="addShop" v-if="data.authMenus.add"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%; margin-top:10px"
    class="table-content"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column label="商品图片" prop="pic" align="center">
      <template #default="scope">
        <div style="display: flex; justify-content: center;">
          <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
        </div>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="name" label="分类名称" align="center" />
    <el-table-column show-overflow-tooltip prop="sort" label="显示排序(数字小优先显示)" align="center" />
    <el-table-column show-overflow-tooltip prop="parentName" label="统计分类" align="center" />
    <el-table-column show-overflow-tooltip label="投放渠道" prop="channel" align="center" />
    <el-table-column label="操作" width="250" align="center">
      <template #default="scope" fixed="right">
        <el-button type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <!-- <el-button type="text" @click="showDetail(2, scope.row)" v-if="!data.authMenus.update">
          {{ scope.row.isShelves === 1 ? '下架' : '上架' }}
        </el-button> -->
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddmerchantType
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :shopType="data.shopType"
    :isEdit="data.isEdit"
    :channelList="data.channelList"
    @update-dialogVisible="updateDialogVisible"
  ></AddmerchantType>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import API from '../../service/api'
import { reactive, onMounted, computed } from 'vue'
import AddmerchantType from './AddmerchantType.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'

const data = reactive({
  authMenus: {},
  isShelves: -1,
  typeName: undefined,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  dialogVisible: false,
  shopType: {},
  isEdit: false,
  channelList: [],
  loading: false,
})

const route = useRoute()
onMounted(() => {
  operationAuth(route, data.authMenus)
  resetQuery()
  getChannelList()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
const getChannelList = () => {
  API.getChannelList(null).then(res => {
    if (res.code === '200') {
      data.channelList = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const resetData = () => {
  data.typeName = undefined
  data.isShelves = -1
  resetQuery()
}

const resetQuery = () => {
  data.currentPage = 1
  queryProductLists()
}

//  查询所有商品类型
const queryProductLists = () => {
  if (data.authMenus.query) {
    data.loading = true
    let queryParams = {
      typeName: data.typeName,
      pageSize: data.pageSize,
      current: data.currentPage,
      type: 1,
    }
    if (data.isShelves !== -1) {
      queryParams.isShelves = data.isShelves
    }
    API.selectByType(queryParams)
      .then(res => {
        if (res.code === '200') {
          data.loading = false
          data.tableData = res.data.records
          data.total = res.data.total
        } else {
          ElMessage.error(res.msg)
        }
      })
      .finally(() => (data.loading = false))
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//添加
const addShop = () => {
  data.dialogVisible = true
  data.isEdit = true
  data.shopType = { sort: 1, channel: [] }
}
const startOrStopRow = rowMsg => {
  API.updateIsShelves({ code: rowMsg.code, isShelves: rowMsg.isShelves === 1 ? 0 : 1 }).then(res => {
    if (res.code === '200') {
      ElMessage.success('更新成功')
      queryProductLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const editRow = rowMsg => {
  data.isEdit = true
  data.shopType = rowMsg
  data.shopType.channel = rowMsg.channel.split(',')
  data.dialogVisible = true
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      editRow(rowMsg)
      break
    case 2:
      startOrStopRow(rowMsg)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryProductLists()
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryProductLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryProductLists()
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  justify-content: flex-end;

  .search-right {
    margin-right: 20px;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
