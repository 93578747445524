/*
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-08-07 12:11:31
 * @LastEditTime: 2024-09-06 11:00:46
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/plugin/order-manager/service/api.js
 * @Description:
 */
import _axios, { post, get } from '@/lin/plugin/axios'
import axios from 'axios'

class CsMng {
  //获取门店区域列表
  async getStoreCodeList(data) {
    return await get(`/adminChain/store/saas/getStoreCodeList`, data)
  }
  //获取门店列表-分页
  async getStoreListPages(data) {
    return await post(`/adminChain/store/saas/getStoreListPages`, data)
  }
  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1,
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  //获取门店列表
  async getStoreListByName(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']
    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //删除门店信息
  async deleteStoreMsg(data) {
    return await _axios.delete(`/adminChain/store/saas/deleteStoreStore/${data}`)
  }

  //获取地区地址省市区三级嵌套列表
  async getAreaWithChildList(data) {
    return await get('/adminChain/store/saas/getAreaWithChildList', data)
  }

  //获取门店详情
  async getStoreStoreByCode(data) {
    return await get('/adminChain/store/saas/getStoreStoreByCode', data)
  }

  //新增门店
  async addStoreStore(data) {
    return await post(`/adminChain/store/saas/addStoreStore`, data)
  }

  //  编辑门店
  async modifyStoreStore(data) {
    return await post(`/adminChain/store/saas/modifyStoreStore`, data)
  }

  //  查询预约记录列表
  async getReserveNoteList(data) {
    return await post(`/adminChain/storeReserveNote/getReserveNoteList`, data)
  }
  //  查询预约记录列表
  async getStoreReserveNoteListByPages(data) {
    return await post(`/adminChain/storeReserveNote/getStoreReserveNoteListByPages`, data)
  }

  // saas端包厢场次单详情
  async orderOpenDetails(data) {
    return await post(`/adminChain/order/orderOpenDetails`, data)
  }

  // saas端包厢场次单列表
  async orderOpenList(data) {
    return await post(`/adminChain/order/orderOpenList`, data)
  }

  // 查询充值订单列表
  async getCardsStoredRecordPages(data) {
    return await get(`/adminChain/order/getCardsStoredRecordPages`, data)
  }

  // 查询小程序预定单明细
  async getMiniReserveOrders(data) {
    return await post(`/adminChain/order/getMiniReserveOrders`, data)
  }
  // 美大预订单明细
  async getMeituanReserveOrders(data) {
    return await post(`/adminChain/order/getMeituanReserveOrders`, data)
  }

  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1,
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  //获取门店列表
  async getDianpuList(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']
    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }
  // 导出
  async exportReport({ url, data }) {
    const res = await _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  async selectByType(data) {
    return await get(`/adminChain/goods/saas/category/selectByType`, data)
  }
}

export default new CsMng()
