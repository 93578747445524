import _axios, { post, get, put } from '@/lin/plugin/axios'

class XjgZzMd {
  async getAllGeo() {
    const res = await get(`storeGeo/getStoreGeoByCode/1`)
    return res
  }
  async addGeo(data) {
    const res = await post(`storeGeo/add`, data)
    return res
  }

  async deleteGeo(data) {
    const res = await post(`storeGeo/delete`, data)
    return res
  }

  async updateGeo(data) {
    const res = await post('storeGeo/update', data)
    return res
  }
  async getAllLastGeoId() {
    const res = await get('storeGeo/getChildTree')
    return res
  }
}

export default new XjgZzMd()
