/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-09-09 10:46:10
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/main.js
 */
import 'dayjs/locale/zh-cn'
import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn'
import * as ElIcons from '@element-plus/icons-vue'
import VXETable from 'vxe-table'

import '@/config/global'
import 'lin/plugin'
import 'lib-flexible'
import { filters } from 'lin/filter'
import permissionDirective from 'lin/directive/authorize'

import App from '@/app.vue'
import store from '@/store'
import router from '@/router'

import LinNotify from '@/component/notify'
import LIcon from '@/component/base/icon/lin-icon'
import StickyTop from '@/component/base/sticky-top/sticky-top'
import SourceCode from '@/component/base/source-code/source-code'
import 'vxe-table/lib/style.css'

import '@/assets/style/index.scss'
import 'element-plus/lib/theme-chalk/index.css'
import '@/assets/style/realize/element-variable.scss'
// import '@/assets/font/iconfont.css'
import './assets/font/demo.css'
import clearTableDataMixin from './mixins/clearTableData'
import splitTotalRowDataMixin from './mixins/splitTotalRowData'

// main.js (需要先引入 Vue 和 Element-UI 依赖库, 并在 <el-table></el-table> 组件下使用该组件)

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(LinNotify, {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
})
//字体图标icon
for (const iconName in ElIcons) {
  app.component(iconName, ElIcons[iconName])
}

app.use(VXETable)

//混入
app.mixin(clearTableDataMixin)
app.mixin(splitTotalRowDataMixin)
// base 组件注册
app.component('l-icon', LIcon)
app.component('sticky-top', StickyTop)
app.component('source-code', SourceCode)

app.config.globalProperties.$filters = filters

app.directive('permission', permissionDirective)

app.mount('#app')

// 设置 App 实例
window.App = app

// const { worker } = require('./mocks/browser')
// worker.start()
