<template>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
    <el-form-item label="营业时间">
      <el-date-picker
        value-format="YYYY-MM-DD HH:mm:ss"
        v-model="data.time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :default-time="data.defaultTime"
        clearable
      />
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        v-model="data.regionCodeList"
        :options="areaOptions"
        clearable
        filterable
        placeholder="请选择门店区域"
        @change="changeArea()"
        :show-all-levels="false"
        collapse-tags
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCodeList"
        :options="data.storeList"
        placeholder="请选择门店"
        class="search-select userSelect"
        style="width: 250px;"
        multiple
        filterable
        collapse-tags
        collapse-tags-tooltip
      />
    </el-form-item>

    <el-form-item>
      <el-button @click="searchLists" type="primary">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
      <el-button @click="exportData" v-if="data.authMenus.export">
        导出
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    :span-method="objectSpanMethod"
    border
    style="width: 96%; margin-left: 2%;"
    :emptyText="emptyTableText"
  >
    <el-table-column width="180">
      <el-table-column show-overflow-tooltip prop="banci" label="日期" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="date" label="类型" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="week" label="类别" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="custBenjin" label="应收" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="custZenjin" label="优惠" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="cash" label="会员卡" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="meituan" label="抹零" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="douyinjizhang" label="实收" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="leishiCust" label="净收" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="leishiDisCount" label="净收分账" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="newSystemDisCount" label="净收(分账后)" width="120" />
      <el-table-column show-overflow-tooltip prop="neibuGuazhang" label="商家优惠" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="zhaodaimianshou" label="会员优惠" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="managerGroupZhaodai" label="优惠券" width="120" />
      <el-table-column show-overflow-tooltip prop="kejiCust" label="赠送金额" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="pos" label="赠送时长" width="120"> </el-table-column>
    </el-table-column>

    <el-table-column label="支付方式">
      <el-table-column show-overflow-tooltip prop="weixin" label="微信" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="zhifuPay" label="支付宝" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="zhifuPay" label="会员卡本金支付" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="zhifuPay" label="会员卡赠金支付" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="zhifuPay" label="美团记账" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="zhifuPay" label="银行卡" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="zhifuPay" label="美大代金" width="120"> </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script setup>
import dayjs from 'dayjs'
import { downLoad } from '../../../../lin/util/util'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive, computed, onMounted, nextTick } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
}
const props = defineProps({
  auths: {},
})

const data = reactive({
  storeCodeList: [],
  time: [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ],
  authMenus: {},
  tableData: [],
  loading: false,
  storeList: [],
  regionCodeList: [],
  areaCode: [],
  defaultTime: new Date(2000, 1, 1, 8, 0, 0), // '12:00:00'
})

const route = useRoute()

onMounted(() => {
  route.meta.msg = props.auths
  operationAuth(route, data.authMenus)
  getStores()
  // queryLists()
  getAreas()
})

//重置时间
const resetDate = () => {
  data.time = [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ]
}

const getStores = async areas => {
  let res = await API.getStoreListByName({ list: data.areaCode })
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.storeCodeList = []
  data.regionCodeList = []
  data.areaCode = []
  nextTick(() => {
    clearSelect('userSelect')
  })
  resetDate()
  searchLists()
}

const exportData = async () => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  let model = {}
  model.startTime = data.time[0]
  model.endTime = data.time[1]
  model.storeCodeList = data.storeCodeList
  model.regionCodeList = data.areaCode
  model.banci = data.banci

  if (!getDays(model.startTime, model.endTime)) {
    return
  }
  let url = '/report/banci/exportBanciReport'
  const res = await API.exportReport({ url, data: model })
  downLoad(res, '营业日报明细.xlsx')
}
const getDays = (sDate1, sDate2) => {
  if (!sDate1 || !sDate2) {
    ElMessage.warning('请设置查询时间')
    return false
  }

  const DAY = 32
  //sDate1和sDate2是2002-12-18格式
  var dateSpan, tempDate, iDays
  sDate1 = Date.parse(sDate1)
  sDate2 = Date.parse(sDate2)
  dateSpan = sDate2 - sDate1
  dateSpan = Math.abs(dateSpan)
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000)) + 1
  if (iDays > DAY) {
    ElMessage.warning('查询或导出时间范围不能超过一个月')
    return false
  }
  return true
}
const emptyTableText = ref('请先设置查询条件，再点击查询数据')
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  let params = {
    banci: data.banci,
    storeCodeList: data.storeCodeList,
    regionCodeList: data.areaCode,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  if (!getDays(params.startTime, params.endTime)) {
    return
  }
  data.loading = true

  API.banciReport(params)
    .then(res => {
      if (res.code === '200') {
        data.tableData = res.data || []
        data.total = res.data.length || 0
        emptyTableText.value = data.tableData.length == 0 ? '暂无数据' : ''
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}

const objectSpanMethod = ({ row, column, rowIndex, columnIndex }) => {
  let splitRowIndex = 5
  if (columnIndex === 0) {
    if (rowIndex !== 0 && (rowIndex - 1) % splitRowIndex === 0) {
      return {
        rowspan: splitRowIndex,
        colspan: 1,
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}

::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
</style>
