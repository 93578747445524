<!--
 * @Author: your name
 * @Date: 2022-04-20 13:09:23
 * @LastEditTime: 2022-12-14 10:22:18
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/shop-member-manager/index.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-04-20 10:24:59
 * @LastEditTime: 2022-04-20 13:08:19
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/shop-order-manager/index.vue
-->
<template>
  <div class="shop-order-manager">
    <SearchBar />
    <div>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="date" label="门店" width="180" />
        <el-table-column prop="name" label="来源" width="180" />
        <el-table-column prop="address" label="场次号" />
        <el-table-column prop="address" label="用户名" />
        <el-table-column prop="address" label="手机号" />
        <el-table-column prop="address" label="包厢名称" />
        <el-table-column prop="address" label="支付时间" />
        <el-table-column label="操作">
          <template #default="scope">
            <el-button type="text" @click="showDetail(scope.$index, scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <Pagination />
    </div>
    <Pagination />
    <el-drawer
      v-model="infoModal"
      title="苏州信投店-A02/小包"
      size="35%"
      :direction="direction"
      :before-close="handleClose"
    >
      <div class="order-detail-item">
        <div class="info-line"></div>
        <h2 class="info-title">预定信息</h2>
        <ul>
          <li><span>手机号：</span>16731516553</li>
          <li><span>昵称：</span>曾女士</li>
          <li><span>预定来源：</span>大众点评</li>
          <li><span>套餐名称：</span>美团258元3小时欢唱</li>
          <li><span>消费时段：</span>2022年11月11日 18:00-21:00</li>
          <li><span>支付时间：</span>2022-11-21 17:00:08</li>
        </ul>
        <el-button type="primary" class="info-confirm">确定</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import SearchBar from '../../component/tableSearchTab.vue'
import Pagination from '../../component/pagination.vue'
export default {
  components: { SearchBar, Pagination },
  data() {
    return {
      searchDate: '',
      options: [
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
        },
      ],
      tableData: [
        {
          date: '2016-05-03',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-02',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-04',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
        {
          date: '2016-05-01',
          name: 'Tom',
          address: 'No. 189, Grove St, Los Angeles',
        },
      ],
      input2: '',
      background: false,
      infoModal: true, //详情Modal
    }
  },
  methods: {
    search() {
      console.log(1)
    },
    showDetail(index, row) {
      this.infoModal = true
    },
    handleClose() {
      this.infoModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.shop-order-manager {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;

  ::v-deep .el-table {
    tr > th:first-child,
    tr > td:first-child {
      left: 24px;
    }

    tr > th:last-child,
    tr > td:last-child {
      right: 24px;
    }

    tr > th:last-child .cell {
      padding-left: 26px;
    }

    .el-table__header {
      background-color: #f5f7fa;
    }
  }
  .shop-order-search {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 41px;

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }
  }

  ::v-deep .el-drawer__body {
    margin-top: -75px;
  }

  .order-detail-item {
    width: 588px;
    margin: auto;
    margin-top: 73px;

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .info-title {
      box-sizing: border-box;
      margin-bottom: 24px;
      width: 588px;
      height: 40px;
      line-height: 40px;
      background: #f0f2f5;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding-left: 24px;
      color: #2e2f31;
      -webkit-text-stroke: 1px #979797;
    }

    ul {
      padding-left: 20px;
      li {
        height: 26px;
        line-height: 26px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;

        span {
          color: #6d7278;
        }
      }
    }
    .info-confirm {
      position: fixed;
      bottom: 24px;
      right: 40px;
      width: 121px;
      height: 40px;
      background-color: #f66c6a !important;
      border: none !important;
    }
  }
}
</style>
