/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-07-25 11:07:44
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '提成管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/pushMoney',
  order: null,
  inNav: true,
  children: [
    // {
    //   title: '房费提成',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/pushMoney/roomMoney',
    //   filePath: 'plugin/push-money/view/roomMoney.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: false,
    //   },
    // },
    {
      title: '商品提成',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/pushMoney/articleMoney',
      filePath: 'plugin/push-money/view/articleMoney.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '储值卡提成',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/pushMoney/cardMoney',
      filePath: 'plugin/push-money/view/cardMoney.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '商品销售提成',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/pushMoney/productSales',
      filePath: 'plugin/push-money/view/productSales.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
  ],
}

export default linCmsUiRouter
