<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportGoodsIncomeDetail"
      :emptyText="'暂无数据'"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '@/component/base/searchbar/index'
import Table from './component/Table.vue'
import api from '../../service/index'
export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodeList: [],
        regionCodeList: [],
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        { label: '门店名称', prop: 'storeName', align: 'center' },
        { label: '会员姓名', prop: 'userName', align: 'center' },
        { label: '会员电话', prop: 'userTelephone', align: 'center' },
        { label: '场次号', prop: 'sourceCode', align: 'center', width: '200px' },
        { label: '包厢', prop: 'roomName', align: 'center' },
        { label: '歌曲名称', prop: 'songName', align: 'center' },
        { label: '歌手', prop: 'singerName', align: 'center' },
        { label: '雷石歌曲ID', prop: 'songId', align: 'center' },
        { label: '点歌时间', prop: 'createdDate', align: 'center', width: '200px' },
      ],
      searchform: [
        {
          label: '营业日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
          // disabledDate: (time) => {
          //   if(!this.model.startTime){
          //     return false;
          //   }
          //   const startDate = new Date(new Date(this.model.startTime).getTime() - 1 * 24 * 60 * 60 * 1000);
          //   const endDateLimit = new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);
          //   return time.getTime() > endDateLimit || time.getTime() < startDate.getTime();
          // }
        },
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '会员电话',
          type: 'input',
          labelWidth: '120px',
          prop: 'userTelephone',
          placeholder: '请输入会员电话',
        },
        {
          label: '会员姓名',
          type: 'input',
          labelWidth: '120px',
          prop: 'userName',
          placeholder: '请输入会员姓名',
        },
        {
          label: '场次号',
          type: 'input',
          labelWidth: '120px',
          prop: 'sourceCode',
          placeholder: '请输入场次号',
        },
        {
          label: '包厢',
          type: 'input',
          labelWidth: '120px',
          prop: 'roomName',
          placeholder: '请输入包厢',
        },
        {
          label: '歌曲名称',
          type: 'input',
          labelWidth: '120px',
          prop: 'songName',
          placeholder: '请输入歌曲名称',
        },
        {
          label: '歌手',
          type: 'input',
          labelWidth: '120px',
          prop: 'singerName',
          placeholder: '请输入歌手',
        },
        { label: '导出', type: 'exportButton', url: '/shadow/export/download?type=ROOM_SONG_DETAILS', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportPaymentOrderDay(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    resetForm() {},
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[1].options = res.data
      })
      api
        .selectByType({
          pageSize: 100,
          current: 1,
          type: 1,
        })
        .then(res => {
          this.searchform[3].options = res.data.records.map(item => {
            return {
              value: item.code,
              label: item.name,
            }
          })
        })
    },
    reportGoodsIncomeDetail(model) {
      this.mergeTotalData(
        model.pageNum,
        '会员点歌管理',
        () => {
          return this.verifyModel(model)
        },
        true,
      )
    },
    reportPaymentOrderDay(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return
      }
      let searchData = {
        pageNum: model.pageNum,
        pageSize: model.pageSize,
        criteria: JSON.stringify(model),
      }
      this.$refs.search.fullscreenLoading = true
      api
        .querySongDetail(searchData)
        .then(res => {
          this.tableData = res.data.items
          this.totalRecords = res.data.total
          this.totalPage = res.data.pages
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    parentSearch(param) {
      const {
        userTelephone,
        userName,
        sourceCode,
        roomName,
        songName,
        singerName,
        storeCodeList,
        regionCodeList,
      } = param
      this.model = {
        userTelephone,
        userName,
        sourceCode,
        roomName,
        songName,
        singerName,
        storeCodeList,
        regionCodeList,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(
        this.model.pageNum,
        '会员点歌管理',
        () => {
          return this.verifyModel(this.model)
        },
        true,
      )
    },
    verifyModel(model) {
      if (
        !model.sourceCode &&
        !model.songName &&
        !model.userName &&
        !model.userTelephone &&
        !this.getDays(model.startTime, model.endTime)
      ) {
        return Promise.reject('条件不满足')
      }
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const {
        userTelephone,
        userName,
        sourceCode,
        roomName,
        songName,
        singerName,
        storeCodeList,
        regionCodeList,
      } = param
      this.model = {
        userTelephone,
        userName,
        sourceCode,
        roomName,
        songName,
        singerName,
        storeCodeList,
        regionCodeList,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.exportData('会员点歌管理', event)
      this.$refs.search.fullscreenLoading = false
      // downLoad(res, '商品销售提成.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
