<template>
  <el-dialog v-model="visible" title="采购订单" width="70%" :before-close="handleClose">
    <div>
      <el-divider />
      <el-form
        :inline="true"
        :model="data"
        label-width="120px"
        style="width: 96%; margin-left: 2%;margin-top: 10px; height: 34px;"
      >
        <el-form-item label="供应商名称:" style="width: 30%;">
          <p style="max-width: 160px;">{{ data.respData.supplierName }}</p>
        </el-form-item>
        <el-form-item label="门店编号:" style="width: 30%;">
          <p style="max-width: 160px;">{{ data.respData.storeCode }}</p>
        </el-form-item>
        <el-form-item label="门店名称:" style="width: 30%;">
          <p style="max-width: 160px;">{{ data.respData.storeName }}</p>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="data" label-width="120px" style="width: 96%; margin-left: 2%;height: 34px;">
        <el-form-item label="订单号:" style="width: 30%;">
          <p style="max-width: 160px;">{{ data.respData.code }}</p>
        </el-form-item>
        <el-form-item label="下单时间:" style="width: 30%;">
          <p style="max-width: 160px;">{{ data.respData.createTime }}</p>
        </el-form-item>
        <el-form-item label="结账类型:" style="width: 30%;">
          <p style="max-width: 160px;">{{ data.respData.checkoutType == 1 ? '月结' : '现结' }}</p>
        </el-form-item>
      </el-form>
      <el-form :inline="true" :model="data" label-width="120px" style="width: 96%; margin-left: 2%; height: 34px;">
        <el-form-item label="订单状态:" style="width: 30%;">
          <p style="width: 160px;">{{ data.orderstatus[data.respData.status] }}</p>
        </el-form-item>
        <el-form-item
          label="物流单号:"
          style="width: 30%;"
          v-if="data.respData.status == 5 || data.respData.status == 8"
        >
          <p style="width: 160px;">{{ data.respData.startTime }}</p>
        </el-form-item>
        <el-form-item label="拒单原因:" style="width: 30%;" v-if="data.respData.status == 6">
          <p style="max-width: 160px;">{{ data.respData.startTime }}</p>
        </el-form-item>
        <el-form-item label="申请原因:" style="width: 30%;" v-if="data.respData.status == 2">
          <p style="max-width: 160px;">{{ data.respData.startTime }}</p>
        </el-form-item>
        <el-form-item label="取消原因:" style="width: 30%;" v-if="data.respData.status == 7">
          <p style="max-width: 160px;">{{ data.respData.remarks }}</p>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="data.tableData" style="width: 94%; margin-left: 2%;">
      <el-table-column label="序号" align="center" type="index" :index="indexfn" />
      <el-table-column label="商品图片" width="100" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;">
            <img :src="scope.row.skuPic" alt="" style="width:30px; height: 30px;" />
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="skuName" label="商品名称" align="center" />
      <el-table-column prop="spec" label="商品规格" align="center" />
      <el-table-column prop="purchaseUnit" label="采购单位" align="center" />
      <el-table-column prop="miniUnit" label="最小单位" align="center" />
      <el-table-column prop="adoptPinThan" label="采购比(1:N)" align="center" />
      <el-table-column prop="price" label="采购价(元)" align="center" />
      <el-table-column prop="num" label="采购数量" align="center" />
      <el-table-column prop="miniUnitCount" label="最小单位数量" align="center" />
    </el-table>
    <el-pagination
      style="margin-right: 6%;"
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
import { ref, reactive, defineComponent, computed, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  name: 'AddShop',
  props: {
    dialogVisible: Boolean,
    rowMsg: Object,
  },
  setup(props, ctx) {
    const data = reactive({
      respData: {},
      boxInfo: {},
      loading: false,
      tableData: [{}],
      pageSize: 10,
      currentPage: 1,
      total: 100,
      orderstatus: {
        1: '待接单',
        2: '申请退单',
        3: '待发货',
        5: '待收货',
        6: '已拒单',
        7: '已取消',
        8: '已完成',
      },
    })

    onMounted(() => {
      queryShops()
    })

    const searchList = () => {
      let query = {
        code: props.rowMsg.code,
        current: data.currentPage,
        pageSize: data.pageSize,
      }
      API.queryDetails(query).then(res => {
        if (res.code == '200') {
          data.respData = res.data
          data.tableData = res.data.purchaseDetailsVOList.records
          data.total = res.data.purchaseDetailsVOList.total
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const queryShops = () => {
      data.currentPage = 1
      searchList()
    }

    const visible = computed(() => {
      return props.dialogVisible
    })
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const indexfn = computed(() => {
      return (data.currentPage - 1) * data.pageSize + 1
    })

    //分页
    const handleSizeChange = val => {
      console.log(11)
      data.pageSize = val
      searchList()
    }
    const handleCurrentChange = val => {
      data.currentPage = val
      searchList()
    }

    return {
      handleClose,
      visible,
      data,
      indexfn,
      handleSizeChange,
      handleCurrentChange,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
