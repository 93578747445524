<template>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
    <el-form-item label="会员手机号">
      <el-input
        placeholder="请输入会员手机号"
        v-model="data.telephone"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="会员卡号">
      <el-input
        placeholder="请输入会员卡号后六位"
        v-model="data.userCode"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="商品名称">
      <el-input
        placeholder="请输入商品名称"
        v-model="data.goodsName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店编号">
      <el-input
        placeholder="请输入门店编号"
        v-model="data.storeCode"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店名称">
      <el-input
        placeholder="请输入门店名称"
        v-model="data.storeName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column label="商品图片" prop="pic" width="100" align="center">
      <template #default="scope">
        <div style="display: flex; justify-content: center;">
          <img :src="scope.row.skuPic" alt="" style="width:30px; height: 30px;" />
        </div>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="商品名称" prop="skuName" align="center" />
    <el-table-column show-overflow-tooltip label="商品规格" prop="spec" align="center" />
    <el-table-column show-overflow-tooltip label="会员姓名" prop="userName" align="center" />
    <el-table-column show-overflow-tooltip label="会员昵称" prop="nickName" align="center" />
    <el-table-column show-overflow-tooltip label="会员手机号" prop="telephone" align="center" />
    <el-table-column show-overflow-tooltip label="会员卡号" prop="vipCardCode" align="center" />
    <el-table-column show-overflow-tooltip label="存酒数量" prop="num" align="center" />
    <el-table-column show-overflow-tooltip label="存酒单位" prop="unit" align="center" />
    <el-table-column show-overflow-tooltip label="存酒有效期" prop="expireTime" align="center" />
    <el-table-column show-overflow-tooltip label="存酒时间" prop="createTime" align="center" />
    <el-table-column show-overflow-tooltip label="门店编号" prop="storeCode" align="center" />
    <el-table-column show-overflow-tooltip label="门店名称" prop="storeName" align="center" />
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import API from '../../service/api'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import { ElMessage, ElMessageBox } from 'element-plus'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
const data = reactive({
  goodsName: undefined,
  storeCode: undefined,
  storeName: undefined,
  telephone: undefined,
  userCode: undefined,
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const resetData = () => {
  data.goodsName = undefined
  data.storeCode = undefined
  data.storeName = undefined
  data.telephone = undefined
  data.userCode = undefined
  searchLists()
}

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let params = {
    storeCode: data.storeCode,
    storeName: data.storeName,
    goodsName: data.goodsName,
    telephone: data.telephone,
    userCode: data.userCode,
    type: 1,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }

  API.getStorageWineActualTime(params)
    .then(res => {
      if (res.code == '200') {
        data.tableData = res.data.records
        data.total = res.data.totalRecords
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
