<template>
  <div class="run-data">
    <div class="run-data-content">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="门店" prop="storeCode">
          <el-select-v2
            v-model="form.storeCode"
            :options="shops"
            placeholder="请选择门店"
            filterable
            clearable
            style="width: 300px;"
          />
        </el-form-item>
      </el-form>
    </div>
    <div style="display:flex">
      <el-button type="primary" style="width: 200px;" @click="handleSyncGoodsAndType">同步商品及分类</el-button>
      <el-button type="primary" style="width: 200px;" @click="handleSyncGift">同步赠品组</el-button>
    </div>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/index'
export default {
  data() {
    return {
      shops: [],
      form: {
        storeCode: '',
      },
      rules: {
        storeCode: [
          {
            required: true,
            message: '请选择门店',
            trigger: 'change',
          },
        ],
      },
    }
  },
  mounted() {
    this.getShops()
  },
  methods: {
    getShops() {
      API.getStoreList().then(res => {
        if (res.code === '200') {
          this.shops = res.data.map(item => {
            return { value: item.code, label: item.code + '--' + item.name }
          })
        } else {
          ElMessage.error(res.msg)
        }
      })
    },
    //同步商品及分类
    handleSyncGoodsAndType() {},
    //同步赠品组
    handleSyncGift() {},
  },
}
</script>
<style lang="scss" scoped>
.run-data {
  width: 100%;
  display: flex;
  padding: 30px 0px 0px 0px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  .run-data-content {
    width: 600px;
    .el-form-item {
      ::v-deep .el-form-item__label {
        width: 150px !important;
      }
      ::v-deep .el-select-v2 {
        .el-select-v2__placeholder {
          display: block;
          overflow: hidden;
          width: 160px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
