<template>
  <el-dialog
    v-model="visible"
    :title="specRowMsg.code ? (isEdit ? '修改组合品' : '详情') : '新增组合品'"
    width="70%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="specRowMsg"
      label-width="150px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="组合图片" prop="pic">
        <el-upload
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage"
        >
          <img v-if="specRowMsg.pic" :src="specRowMsg.pic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>

      <el-form-item label="组合名称" prop="name">
        <el-input v-model="specRowMsg.name" style="width:70%" placeholder="请填写组合名称" />
      </el-form-item>
      <el-form-item label="组合规格">
        <el-input v-model="specRowMsg.spec" style="width:70%" placeholder="请填写规格" />
      </el-form-item>
      <el-form-item label="指导价(元)" prop="price2">
        <div style="display: flex;">
          <el-input v-model="specRowMsg.price1" style="width: 30%; margin-right: 10px;" placeholder="支持2位小数" />～
          <el-input v-model="specRowMsg.price2" style="width: 30%; margin-left: 10px;" placeholder="支持2位小数" />
        </div>
      </el-form-item>
      <el-form-item label="最低毛利率(%)" prop="minimum">
        <el-input v-model="specRowMsg.minimum" style="width:70%" placeholder="支持2位小数" />
      </el-form-item>
      <el-form-item label="最小单位" prop="unit">
        <el-select v-model="specRowMsg.unit" placeholder="请选择最小单位">
          <el-option :label="item.name" :value="item.name" v-for="(item, index) in units" :key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="必选商品" prop="skuArrayContentResp">
        <el-button @click="addShops">选择商品</el-button>
        <el-card
          style=" margin-top: 10px; overflow-x: auto;width: 85%"
          v-if="specRowMsg.skuArrayContentResp && specRowMsg.skuArrayContentResp.length !== 0"
        >
          <el-table :data="specRowMsg.skuArrayContentResp" style="width: 100%" height="200">
            <el-table-column label="序号" align="center" type="index" />
            <el-table-column prop="name" label="商品名称" align="center" />
            <el-table-column label="商品图片">
              <template #default="scope" align="center">
                <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
              </template>
            </el-table-column>
            <el-table-column prop="spec" label="规格" />
            <el-table-column prop="price" label="指导价(元)" />
            <el-table-column prop="unit" label="最小单位" />
            <el-table-column prop="productTypeName" label="商品类型" />
            <el-table-column label="数量" align="center" width="150">
              <template #default="scope">
                <el-input-number v-model="scope.row.count" :min="1" :max="1000" />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button type="text" @click="deleteShop(scope.row.code)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-form-item>

      <el-form-item
        label="下发现存门店"
        prop="storeCodeList"
        v-loading="form.loading1"
        element-loading-text="正在火速加载门店数据..."
      >
        <el-card shadow="never">
          <el-form :inline="true" :model="form" style="height: 30px;">
            <el-form-item>
              <el-checkbox v-model="checked1" label="Option 1" size="large" @change="changeSelect">全选</el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-cascader
                v-model="form.geoCode"
                :options="form.shopLists"
                :props="form.optionsProps"
                :show-all-levels="false"
                filterable
                clearable
                collapse-tags
                collapse-tags-tooltip
                placeholder="选择门店区域"
                @change="getShops"
              />
            </el-form-item>
            <el-form-item>
              <el-input v-model="form.searchShop" placeholder="搜索门店" @keyup.enter="getShops" />
            </el-form-item>
            <el-form-item>
              <el-button @click="getShops">搜索</el-button>
            </el-form-item>
          </el-form>
          <el-divider border-style="double" />
          <div class="shopsShow" v-if="specRowMsg.shops.length !== 0">
            <el-checkbox-group v-model="specRowMsg.storeCodeList">
              <el-checkbox
                :label="item.value"
                size="large"
                style="margin-right: 10px; margin-left:0px"
                border
                v-for="(item, index) in specRowMsg.shops"
                :key="index"
                >{{ item.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <el-empty description="当前区域暂无门店，请重新选择区域" :image-size="200" v-else />
        </el-card>
      </el-form-item>
      <el-form-item label="下发将来门店" prop="isSales">
        <el-radio-group v-model="specRowMsg.isSales">
          <el-radio :label="1">自动开启售卖</el-radio>
          <el-radio :label="0">禁止自动售卖</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="是否设为推荐">
        <el-switch v-model="specRowMsg.isRecommend" />
      </el-form-item>
      <el-form-item label="是否计入低消">
        <el-switch v-model="specRowMsg.isMinConsume" />
      </el-form-item>

      <el-form-item label="投放渠道" prop="channel">
        <el-checkbox-group v-model="specRowMsg.channel">
          <el-checkbox :label="item.name" size="large" v-for="item in channelList">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="售卖状态" prop="isShelves">
        <el-radio-group v-model="specRowMsg.isShelves">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="0">下架(不论是否有库存，均下架停止售卖)</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="供货状态" prop="isSupply">
        <el-radio-group v-model="specRowMsg.isSupply">
          <el-radio :label="1">正常供货</el-radio>
          <el-radio :label="0">停止供货(现有库存，售完为止)</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 10px;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;" :disabled="!isEdit">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
    <SelectShops
      v-if="form.addProductVisible"
      :dialogVisible="form.addProductVisible"
      @update-dialogVisible="updateAddProductVisible"
    ></SelectShops>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { reactive, defineComponent, computed, ref, toRaw, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import SelectShops from './SelectShops.vue'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
    units: Array,
    channelList: Array,
    isEdit: Boolean,
  },
  components: {
    SelectShops,
  },
  setup(props, ctx) {
    const form = reactive({
      loadText: '正在火速加载数据...',
      img1: [],
      searchShop: '',
      addProductVisible: false,
      shopLists: [],
      geoCode: [],
      optionsProps: {
        label: 'name',
        children: 'child',
        value: 'code',
        checkStrictly: true,
      },
      loading: false,
      loading1: false,
    })
    const checked1 = ref(false)

    const visible = computed(() => {
      return props.dialogVisible
    })

    onMounted(() => {
      getArea()
    })

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const getArea = () => {
      API.getArea({ isOnlyEnable: 1 }).then(res => {
        if (res.code === '200') {
          form.shopLists = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const addShops = () => {
      form.addProductVisible = true
    }

    const deleteShop = item => {
      let index = props.specRowMsg.skuArrayContentResp.findIndex(a => a.code == item)
      props.specRowMsg.skuArrayContentResp.splice(index, 1)
    }

    //  获取门店
    const getShops = () => {
      form.loading1 = true
      let params = {
        name: form.searchShop,
      }
      if (Array.isArray(toRaw(form.geoCode))) {
        params.geoCode = form.geoCode[form.geoCode.length - 1]
      }
      API.getStoreList(params)
        .then(res => {
          if (res.code === '200') {
            checked1.value = false
            form.loading1 = false
            props.specRowMsg.shops = res.data.map(item => ({
              label: item.name,
              value: item.code,
            }))
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => (form.loading1 = false))
    }
    //改变门店选择
    const changeSelect = a => {
      if (a) {
        props.specRowMsg.shops.forEach(item => {
          props.specRowMsg.storeCodeList.push(item.value)
        })
      } else {
        props.specRowMsg.storeCodeList = []
      }
    }

    const changeImage = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img1.push(file)
      let fileName = file.file.name + new Date()
      props.specRowMsg.pic = ''
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.specRowMsg.pic = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }

    const add = params => {
      API.saveSkuArray(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const update = params => {
      API.updateSkuArrayByCode(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const checkprice = (rule, value, callback) => {
      if (!props.specRowMsg.price1 || !props.specRowMsg.price2) {
        return callback(new Error('指导价必填'))
      }

      if (isNaN(toRaw(props.specRowMsg.price1)) || isNaN(toRaw(props.specRowMsg.price1))) {
        return callback(new Error('指导价必须是数字'))
      }
      if (parseInt(toRaw(props.specRowMsg.price1)) > parseInt(toRaw(props.specRowMsg.price2))) {
        return callback(new Error('指导价后面必须大于前面'))
      } else {
        callback()
      }
    }
    const checkMiniNum = (rule, value, callback) => {
      if (!props.specRowMsg.minimum) {
        callback()
      } else if (!/^[0-9]{1,2}(\.[0-9]{0,2})?$/.test(toRaw(props.specRowMsg.minimum))) {
        return callback(new Error('最低毛利率是数字，且最多保留两位'))
      } else {
        callback()
      }
    }
    const rules = reactive({
      pic: [{ required: true, message: '请上传组合图片', trigger: 'blur' }],
      name: [{ required: true, message: '请填写组合品名称', trigger: 'blur' }],
      spec: [{ required: true, message: '请填写商品规格', trigger: 'blur' }],
      unit: [{ required: true, message: '请选择最小单位', trigger: 'blur' }],
      unitLay: [{ required: true, message: '请选择进货单位', trigger: 'blur' }],
      caseSpec: [{ required: true, message: '请填写进货比率', trigger: 'blur' }],
      price2: [{ required: true, trigger: 'blur', validator: checkprice }],
      minimum: [{ required: false, trigger: 'blur', validator: checkMiniNum }],
      channel: [{ required: true, message: '请选择投放渠道', trigger: 'blur' }],
      isSales: [{ required: true, message: '请选择下发将来门店方式', trigger: 'blur' }],
      storeCodeList: [{ required: true, message: '请选择下发现存门店', trigger: 'blur' }],
      skuArrayContentResp: [{ required: true, message: '请选择必选商品', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let params = {
            ...props.specRowMsg,
            price: toRaw(props.specRowMsg.price1) + '~' + toRaw(props.specRowMsg.price2),
            channel: props.specRowMsg.channel.join(','),
            isMinConsume: props.specRowMsg.isMinConsume ? 1 : 0,
            isRecommend: props.specRowMsg.isRecommend ? 1 : 0,
            isStock: props.specRowMsg.isStock ? 1 : 0,
          }
          if (Reflect.has(props.specRowMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    const updateAddProductVisible = ({ update, selectSkus = [], isUpdate }) => {
      form.addProductVisible = update
      if (isUpdate) {
        // props.specRowMsg.skuArrayContentResp = selectSkus
        selectSkus.forEach(item => {
          let ishasItem = props.specRowMsg.skuArrayContentResp.find(formItem => formItem.code === item.code)
          if (!ishasItem) {
            props.specRowMsg.skuArrayContentResp.push(item)
          }
        })
      }
    }
    return {
      deleteShop,
      checked1,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      changeImage,
      getShops,
      changeSelect,
      rules,
      elForm,
      addShops,
      updateAddProductVisible,
      handleSubmit,
    }
  },
})
</script>
<style lang="scss" scoped>
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
