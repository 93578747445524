const linCmsUiRouter = {
  route: null,
  name: null,
  title: '收银管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/cashmanager/',
  order: null,
  inNav: true,
  children: [
    {
      title: '网络支付设置',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/cash_manager/webpay-manager',
      filePath: 'plugin/cash_manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '支付方式管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/cash_manager/payway-manager',
      filePath: 'plugin/cash_manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '客户来源管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/cash_manager/customsource-manager',
      filePath: 'plugin/cash_manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '开票设置',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/cash_manager/billset-manager',
      filePath: 'plugin/cash_manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
