<template>
  <div class="liquor_retrievalCode">
    <el-card class="box-card">
      <div class="content_react">
        <text class="content_react_title">存取酒校验</text>
        <el-form :model="form">
          <el-form-item label="手机号:" class="is-required">
            <el-input type="text" placeholder="请输入手机号" v-model="form.phone" :maxlength="20" />
          </el-form-item>
          <el-form-item label="验证码:" v-show="form.isGetCode">
            {{ form.smsCode }}
          </el-form-item>
          <el-form-item label="获取时间:" v-show="form.isGetCode">
            {{ form.sendTime }}
          </el-form-item>
        </el-form>

        <div class="toast" v-show="form.isGetCode">注：验证码有效期十分钟</div>
      </div>
      <div class="btn">
        <el-button type="primary" @click="handleClick">
          获取验证码
        </el-button>
      </div>
    </el-card>
  </div>
</template>

<script>
import API from '../../service/index'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
export default {
  data() {
    return {
      form: {
        isGetCode: false,
        phone: '',
        smsCode: '',
        sendTime: '',
      },
    }
  },
  methods: {
    handleClick: _.throttle(
      async function() {
        if (!this.form.phone?.trim()) {
          return ElMessage.error('请输入手机号')
        }
        let res = await API.sendWineSmsCode({
          telephone: this.form.phone.trim(),
        })
        if (res.code == 200) {
          this.form.isGetCode = true
          this.form.sendTime = res.data.sendTime
          this.form.smsCode = res.data.smsCode
          return ElMessage.success('发送成功')
        } else {
          return ElMessage.error(res.msg)
        }
      },
      2000,
      {
        trailing: false,
      },
    ),
  },
}
</script>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
.liquor_retrievalCode {
  height: 100%;
  display: flex;
  overflow: hidden;
  justify-content: center;
  .box-card {
    width: 60%;
    margin-top: 30px;
    .content_react {
      margin: 40px auto;
      width: 400px;
      height: 400%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .content_react_title {
        margin-bottom: 20px;
      }

      .toast {
        margin-top: 10px;
        color: red;
      }
    }
    .btn {
      margin-bottom: 20px;
      text-align: center;
      height: 40px;
    }
  }
}
</style>
