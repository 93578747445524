<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="商品名称">
      <el-input placeholder="请输商品名称" v-model="data.spuName" class="input-search-tel" clearable></el-input>
    </el-form-item>
    <el-form-item label="单品名称">
      <el-input placeholder="请输单品名称" v-model="data.skuName" class="input-search-tel" clearable></el-input>
    </el-form-item>
    <el-form-item label="商品分类">
      <el-select-v2
        v-model="data.categoryCode"
        :options="data.productTypes"
        placeholder="请选择"
        filterable
        clearable
      />
    </el-form-item>
    <el-form-item label="下发将来门店">
      <el-select v-model="data.isSales">
        <el-option label="自动开启售卖" :value="1" />
        <el-option label="禁止开启售卖" :value="0" />
      </el-select>
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        ref="casRef"
        v-model="data.regionCode"
        :options="areaOptions"
        clearable
        filterable
        collapse-tags
        placeholder="请选择门店区域"
        collapse-tags-tooltip
        @change="changeArea()"
        :show-all-levels="false"
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCode"
        :options="data.shops"
        filterable
        placeholder="请选择门店"
        class="search-select userSelect"
        multiple
        collapse-tags
        style="width: 300px;"
        collapse-tags-tooltip
      />
    </el-form-item>
    <el-form-item label="备注">
      <el-input placeholder="商品备注" v-model="data.remarks" class="input-search-tel" clearable></el-input>
    </el-form-item>
    <el-form-item label="投放渠道">
      <el-select v-model="data.channel" class="search-select" placeholder="选择渠道" clearable>
        <el-option v-for="item in data.channelList" :key="item.name" :label="item.name" :value="item.name" />
      </el-select>
    </el-form-item>

    <el-form-item label="售卖状态">
      <el-select v-model="data.isShelves" placeholder="请选择状态">
        <el-option label="上架" :value="1" />
        <el-option label="下架" :value="0" />
        <el-option label="全部" :value="-1" />
      </el-select>
    </el-form-item>
    <el-form-item label="供货状态">
      <el-select v-model="data.isSupply" placeholder="请选择状态">
        <el-option label="正常供货" :value="1" />
        <el-option label="停止供货" :value="0" />
        <el-option label="全部" :value="-1" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
      <el-button @click="addProduct" v-if="data.authMenus.add"> 新增商品 </el-button>
      <el-button @click="downTemplate" v-if="data.authMenus.downloadTemplate">
        下载模版
      </el-button>
      <el-button style="padding:0px" v-if="data.authMenus.import">
        <el-upload action="#" :on-change="uploadFile" :show-file-list="false" :auto-upload="false">
          <el-button>导入</el-button>
        </el-upload>
      </el-button>
      <el-button @click="exportTable" v-if="data.authMenus.export"> 导出 </el-button>

      <el-dropdown style="margin-left:10px" v-if="data.authMenus.batchDelivery">
        <el-button>
          批量操作<el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>

        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item @click="handleBatchDelivery" v-if="data.authMenus.batchDelivery"
              >批量下发</el-dropdown-item
            >
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    :default-expand-all="false"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
  >
    <el-table-column type="expand">
      <template #default="props">
        <el-table :data="props.row.skuDtoList" style="width: 100%">
          <el-table-column label="单品图片" prop="pic" align="center">
            <template #default="scope">
              <div style="display: flex; justify-content: center;">
                <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
              </div>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="单品名称" prop="name" align="center" />
          <el-table-column show-overflow-tooltip label="单品规格" prop="spec" align="center" />
          <el-table-column show-overflow-tooltip label="指导价(元)" prop="price" align="center" />
          <el-table-column show-overflow-tooltip label="最小单位" prop="unit" align="center" />
          <el-table-column show-overflow-tooltip label="投放渠道" prop="channel" align="center" />
          <el-table-column show-overflow-tooltip label="下发门店数" prop="storeCount" align="center" />
          <el-table-column show-overflow-tooltip label="更新时间" prop="updateTime" align="center" />
          <el-table-column show-overflow-tooltip label="售卖状态" align="center">
            <template #default="scope">
              <el-tag v-if="scope.row.isShelves === 1">上架</el-tag>
              <el-tag type="error" v-else>下架</el-tag>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip label="供货状态" align="center">
            <template #default="scope">
              <el-tag v-if="scope.row.isSupply === 1">正常供货</el-tag>
              <el-tag type="error" v-else>停止供货</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template #default="scope">
              <el-button type="text" @click="specOperation(1, scope.row)" v-if="data.authMenus.query">详情</el-button>
              <el-button type="text" @click="specOperation(2, scope.row)" v-if="data.authMenus.update">修改</el-button>
              <el-button
                type="text"
                @click="specOperation(3, scope.row)"
                v-if="scope.row.isShelves === 1 && data.authMenus.update"
              >
                下架
              </el-button>
              <el-button
                type="text"
                @click="specOperation(3, scope.row)"
                v-if="scope.row.isShelves !== 1 && data.authMenus.update"
              >
                上架</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
    </el-table-column>
    <el-table-column type="index" label="序号" align="center" />
    <el-table-column label="商品图片" prop="pic" align="center">
      <template #default="scope">
        <div style="display: flex; justify-content: center;">
          <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
        </div>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="spuName" label="商品名称" align="center" />
    <el-table-column show-overflow-tooltip prop="specCount" label="多规格数量" align="center" />
    <el-table-column show-overflow-tooltip prop="categoryName" label="商品分类" align="center" />
    <el-table-column show-overflow-tooltip label="商品属性" align="center" width="100">
      <template #default="scope">
        <span v-if="scope.row.attribute == 1">外采销售</span>
        <span v-if="scope.row.attribute == 2">自制销售</span>
        <span v-if="scope.row.attribute == 3">只做采购</span>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="updateTime" label="更新时间" align="center" />
    <el-table-column label="操作" width="200" align="center">
      <template #default="scope">
        <el-button type="text" @click="typeOperation(1, scope.row)" v-if="data.authMenus.add">新增规格</el-button>
        <el-button type="text" @click="typeOperation(2, scope.row)" v-if="data.authMenus.update">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddParentProduct
    v-if="data.addParentProductVisible"
    :dialogVisible="data.addParentProductVisible"
    :proRowMsg="data.proRowMsg"
    :protypes="data.productTypes"
    @update-dialogVisible="updateAddParentProductVisible"
  ></AddParentProduct>
  <AddProductSpec
    v-if="data.addProductSpecVisible"
    :dialogVisible="data.addProductSpecVisible"
    :specRowMsg="data.specRowMsg"
    :units="data.units"
    :isEdit="data.isEdit"
    :channelList="data.channelList"
    @update-dialogVisible="updateAddProductSpecVisible"
  ></AddProductSpec>
  <!-- 批量下发 -->
  <BatchDelivery
    v-if="data.batchDeliveryVisible"
    :productType="1"
    :propData="data"
    :dialogVisible="data.batchDeliveryVisible"
    @update-dialogVisible="batchDeliveryVisible"
  ></BatchDelivery>
</template>

<script setup>
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/api'
import { reactive, onMounted, ref, nextTick } from 'vue'
import AddParentProduct from './AddParentProduct.vue'
import AddProductSpec from './AddProductSpec.vue'
import BatchDelivery from '../../component/BatchDelivery.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { downLoad } from '@/lin/util/util'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const casRef = ref()
const changeArea = () => {
  data.storeCode = []
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

//导出
const exportTable = async () => {
  data.loading = true
  data.loadingText = '正在火速下载数据...'
  let queryParams = {
    categoryCode: data.categoryCode, //分类
    // channel: data.channel, //通道
    spuName: data.spuName, //商品名称
    skuName: data.skuName,
    storeCode: data.storeCode,
    current: data.currentPage,
    pageSize: data.pageSize,
    remarks: data.remarks,
    regionCode: data.areaCode,
    isSales: data.isSales,
  }
  if (data.isShelves !== -1) {
    queryParams.isShelves = data.isShelves
  }
  if (data.isSupply !== -1) {
    queryParams.isSupply = data.isSupply
  }
  let url = '/adminChain/goods/saas/outputBatchProductFile'
  const res = await API.exportReport({ url, data: queryParams })
  data.loading = false
  const reader = new FileReader()
  reader.readAsText(res.data, 'utf-8')
  reader.onload = function() {
    try {
      const t = JSON.parse(reader.result) // 这里就得到了json
      ElMessage({
        message: t.msg,
        type: 'error',
      })
    } catch (error) {
      downLoad(res, '单品列表.xlsx')
    }
  }
}

//下载模版
const downTemplate = async () => {
  let a = document.createElement('a')
  a.href =
    'https://oss-file-center.oss-cn-hangzhou.aliyuncs.com/template/%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E7%89%88.xlsx'
  a.download = '单品列表模版.xlsx'
  a.click()
}

//批量下发
const handleBatchDelivery = () => {
  data.batchDeliveryVisible = true
}

//上传文件
const uploadFile = file => {
  API.inputBatchProductFile({ inputFile: file.raw }).then(res => {
    if (res.code === '200') {
      ElMessage({
        message: '上传成功',
        type: 'success',
      })
      searchLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const data = reactive({
  authMenus: {},
  productTypes: [],
  spuName: undefined,
  skuName: undefined,
  channel: undefined,
  storeCode: [],
  isSales: '',
  regionCode: [],
  remarks: '',
  areaCode: [],
  isShelves: -1,
  isSupply: -1,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  addParentProductVisible: false,
  addProductSpecVisible: false,
  batchDeliveryVisible: false,
  specRowMsg: {
    price: '',
    price1: '',
    price2: '',
  },
  proRowMsg: {},
  categoryCode: undefined,
  units: [],
  shops: [],
  channelList: [],
  isEdit: false,
  loading: false,
  loadingText: '正在火速加载数据...',
  // productList: [],
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryAllShopTyps()
  searchLists()
  selectDictListAll()
  getShops()
  getChannelList()
  getAreas()
})

const getShops = () => {
  API.getStoreList({ list: data.areaCode }).then(res => {
    if (res.code === '200') {
      data.shops = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const getChannelList = () => {
  API.getChannelList(null).then(res => {
    if (res.code === '200') {
      data.channelList = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//获取单位
const selectDictListAll = () => {
  API.selectDictListAll().then(res => {
    if (res.code === '200') {
      data.units = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//查询所有商品类型
const queryAllShopTyps = () => {
  API.selectListByType({ type: 1 }).then(res => {
    if (res.code === '200') {
      data.productTypes = res.data.map(item => {
        return {
          label: item.name,
          value: item.code,
        }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const reset = () => {
  data.spuName = undefined
  data.skuName = undefined
  data.channel = undefined
  data.storeCode = []
  data.isShelves = -1
  data.isSupply = -1
  data.categoryCode = undefined
  data.remarks = undefined
  data.regionCode = []
  data.areaCode = []
  data.isSales = ''
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    categoryCode: data.categoryCode, //分类
    channel: data.channel, //通道
    spuName: data.spuName, //商品名称
    skuName: data.skuName,
    storeCode: data.storeCode,
    current: data.currentPage,
    pageSize: data.pageSize,
    remarks: data.remarks,
    regionCode: data.areaCode,
    isSales: data.isSales,
  }
  if (data.isShelves !== -1) {
    queryParams.isShelves = data.isShelves
  }
  if (data.isSupply !== -1) {
    queryParams.isSupply = data.isSupply
  }
  API.selectSpuList(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))

  // API.selectSpuListCodes(queryParams).then(res => {
  //   data.productList = res.data
  // })
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}

//添加
const addProduct = () => {
  data.proRowMsg = {}
  data.addParentProductVisible = true
}

const editProType = rowMsg => {
  data.proRowMsg = rowMsg
  data.addParentProductVisible = true
}

//操作商品列表数据

const typeOperation = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      addProSpec(rowMsg)
      break
    case 2:
      editProType(rowMsg)
      break
  }
}

const addProSpec = rowMsg => {
  data.specRowMsg = {
    pic: rowMsg.pic,
    picLay: rowMsg.pic,
    storeCodeList: [],
    spuCode: rowMsg.code,
    categoryCode1: rowMsg.categoryCode,
    price: '',
    price1: 1,
    price2: 2,
    shops: _.cloneDeep(data.shops),
    isStock: true,
    isRecommend: false,
    isMinConsume: false,
    isSales: 1,
    isShelves: 1,
    isSupply: 1,
    channel: data.channelList.map(item => item.name),
    attribute: rowMsg.attribute,
    materielCode: '',
  }
  data.addProductSpecVisible = true
  data.isEdit = true
}

const editProSpec = rowMsg => {
  API.selectDetails({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseFloat(res.data.price.split('~')[0])
        res.data.price2 = parseFloat(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }

      data.specRowMsg = {
        shops: _.cloneDeep(data.shops),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        channel: res.data.channel.split(','),
        caseSpec: parseInt(res.data.caseSpec),
        attribute: rowMsg.attribute,
        materielCode: res.data.materielCode || '',
      }
      data.addProductSpecVisible = true
      data.isEdit = true
    } else {
      ElMessage.error(res.error)
    }
  })
}
const showProSpec = (rowMsg, parentRow) => {
  API.selectDetails({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseFloat(res.data.price.split('~')[0])
        res.data.price2 = parseFloat(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }

      data.specRowMsg = {
        shops: _.cloneDeep(data.shops),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        channel: res.data.channel.split(','),
        caseSpec: parseInt(res.data.caseSpec),
      }
      data.addProductSpecVisible = true
      data.isEdit = false
    } else {
      ElMessage.error(res.error)
    }
  })
}

const shopOrStartRow = rowMsg => {
  if (rowMsg.isShelves === 1) {
    ElMessageBox.confirm('下架当前商品将会下架与该商品绑定的组合套餐,确认下架吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      API.updateIsShelvesNoType({ code: rowMsg.code, isShelves: rowMsg.isShelves === 1 ? 0 : 1 }).then(res => {
        if (res.code === '200') {
          ElMessage.success('更新成功')
          queryLists()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
  } else {
    API.updateIsShelvesNoType({ code: rowMsg.code, isShelves: rowMsg.isShelves === 1 ? 0 : 1 }).then(res => {
      if (res.code === '200') {
        ElMessage.success('更新成功')
        queryLists()
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

const specOperation = (index, rowMsg, parentRow) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showProSpec(rowMsg, parentRow)
      break
    case 2:
      editProSpec(rowMsg, parentRow)
      break
    case 3:
      shopOrStartRow(rowMsg)
      break
  }
}

const updateAddParentProductVisible = ({ update, search }) => {
  data.addParentProductVisible = update
  if (search) {
    queryLists()
  }
}
const updateAddProductSpecVisible = ({ update, search }) => {
  data.addProductSpecVisible = update
  if (search) {
    queryLists()
  }
}
const batchDeliveryVisible = ({ update, search }) => {
  data.batchDeliveryVisible = update
  if (search) {
    queryLists()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
