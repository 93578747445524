<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="商品收入明细" name="goodsIncomeAuth" v-if="isShowReport('goodsIncomeAuth')">
      <goods-income-report
        :reportType="reportType"
        :reportTypeName="reportTypeName"
        :auths="authType.goodsIncomeAuth"
      />
    </el-tab-pane>
    <el-tab-pane label="套餐收入明细" name="comboIncomeAuth" v-if="isShowReport('comboIncomeAuth')">
      <combo-income-report
        :reportType="reportType"
        :reportTypeName="reportTypeName"
        :auths="authType.comboIncomeAuth"
      />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import goodsIncomeReport from '../report_module/goodsIncomeReport'
import comboIncomeReport from '../report_module/comboIncomeReport'
import { onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import Api from '@/api/index.js'

const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  goodsIncomeAuth: {}, // 商品收入明细
  comboIncomeAuth: {}, // 套餐收入明细
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})
let reportType = ref('')
let reportTypeName = ref('')
onMounted(() => {
  // getEnmuList()
})
const EnmuList = reactive({
  goodsIncomeAuth: '商品收入明细',
  comboIncomeAuth: '套餐收入明细',
})
const getEnmuList = async () => {
  const res = await Api.getEnmuList()
  if (res.code === '200') {
    localStorage.setItem('excelEnmu', JSON.stringify(res?.data) || {})
    reportType.value = res.data[EnmuList[activeName.value]]
    reportTypeName.value = EnmuList[activeName.value]
  }
}
const handleClick = tab => {
  // getEnmuList()
}
/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.goodsIncomeAuth = authAllMenus.find(a => a.url === 'goodsIncomeAuth') || { childrens: [] }
  authType.comboIncomeAuth = authAllMenus.find(a => a.url === 'comboIncomeAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsIncomeAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
