<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="券包编号">
      <el-input
        placeholder="请输入券包编号"
        v-model="data.couponPackageCode"
        class="input-search-tel"
        clearable
      ></el-input>
    </el-form-item>
    <el-form-item label="券包名称">
      <el-input
        placeholder="请输入券包名称"
        v-model="data.couponPackageName"
        class="input-search-tel"
        clearable
      ></el-input>
    </el-form-item>

    <el-form-item label="包含优惠券">
      <el-select-v2 v-model="data.ruleCode" :options="data.coupons" filterable placeholder="请选择优惠券" clearable />
    </el-form-item>
    <el-form-item label="售卖状态">
      <el-select v-model="data.status">
        <el-option label="上架" :value="0" />
        <el-option label="下架" :value="1" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
      <el-button @click="typeOperation(1, {})" v-if="data.authMenus.add"> 新增 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    :default-expand-all="false"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
  >
    <el-table-column show-overflow-tooltip prop="code" label="券包编号" align="center" width="200" />
    <el-table-column show-overflow-tooltip prop="name" label="券包名称" align="center" />
    <el-table-column show-overflow-tooltip prop="containCount" label="包含优惠券种类" align="center" />
    <el-table-column show-overflow-tooltip prop="limitNum" label="个人限买数量" align="center">
      <template #default="scope">
        {{ scope.row.limitNum == -1 ? '不限制' : scope.row.limitNum }}
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="售卖状态" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.status === 0">上架</el-tag>
        <el-tag v-else type="error">下架</el-tag>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" align="center" />
    <el-table-column show-overflow-tooltip prop="updateTime" label="更新时间" align="center" />
    <el-table-column show-overflow-tooltip prop="updateUser" label="操作人" align="center" />
    <el-table-column label="操作" width="300" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="typeOperation(2, scope.row)" v-if="data.authMenus.query">详情</el-button>
        <el-button type="text" @click="typeOperation(4, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <el-button type="text" @click="typeOperation(3, scope.row)" v-if="data.authMenus.delete">
          {{ scope.row.status === 0 ? '下架' : '上架' }}
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddOrUpdate
    v-if="data.addOrUpdateVisible"
    :updateRowMsg="data.proRowMsg"
    :isEdit="data.isEdit"
    @handleCloseDialog="updateAddParentProductVisible"
  ></AddOrUpdate>
</template>

<script setup>
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/index'
import { reactive, onMounted, ref, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import AddOrUpdate from './add-or-update.vue'

const data = reactive({
  authMenus: {},
  coupons: [],
  coupon: '',
  isEdit: true,

  status: '',
  ruleCode: '',
  couponPackageCode: '',
  couponPackageName: '',
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  addOrUpdateVisible: false,
  proRowMsg: {},
  loading: false,
  loadingText: '正在火速加载数据...',
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  getCoupons()
  queryLists()
})

const getCoupons = () => {
  let params = {
    pageSize: 10000,
    pageNum: 1,
    isOnlyAll: 0,
  }
  API.selectCouponPage(params).then(res => {
    if (res.code === '200') {
      data.coupons = res.data.records.map(item => {
        return {
          label: item.name,
          value: item.code,
        }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const reset = () => {
  data.couponPackageCode = ''
  data.couponPackageName = ''
  data.ruleCode = ''
  data.status = ''
  data.currentPage = 1
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    couponPackageCode: data.couponPackageCode,
    couponPackageName: data.couponPackageName,
    ruleCode: data.ruleCode,
    status: data.status,
  }
  API.selectCouponPackagePage(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records.map(a => {
          a.couponRuleList = a.couponRuleList.map(b => ({
            ...b,
            name: b.ruleName,
            code: b.ruleCode,
            attemptStore: a.attemptStore,
          }))
          return a
        })
        data.total = res.data.totalRecords
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}

const editProType = rowMsg => {
  data.proRowMsg = {
    ...rowMsg,
    limitNum: rowMsg.limitNum == -1 ? '' : rowMsg.limitNum,
  }
  data.addOrUpdateVisible = true
  data.isEdit = true
}

const showInfo = rowMsg => {
  data.proRowMsg = {
    ...rowMsg,
    limitNum: rowMsg.limitNum == -1 ? '' : rowMsg.limitNum,
  }

  data.isEdit = false
  data.addOrUpdateVisible = true
}

//操作商品列表数据

const typeOperation = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      addProSpec(rowMsg)
      break
    case 2:
      editProType(rowMsg)
      break
    case 3:
      deleteProType(rowMsg)
      break
    case 4:
      showInfo(rowMsg)
      break
  }
}
const deleteProType = rowMsg => {
  ElMessageBox.confirm(`确认${rowMsg.status === 1 ? '上架' : '下架'}当前券包吗`, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.UpOrDownShelves({ code: rowMsg.code, status: rowMsg.status == 0 ? 1 : 0 })
    if (res.code === '200') {
      ElMessage.success('操作成功')
    } else {
      ElMessage.error(res.msg)
    }
    queryLists()
  })
}
const addProSpec = rowMsg => {
  data.proRowMsg = {
    couponRuleList: [],
    status: 0,
  }
  data.isEdit = false
  data.addOrUpdateVisible = true
}

const updateAddParentProductVisible = ({ update, search }) => {
  data.addOrUpdateVisible = update
  if (search) {
    queryLists()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 130px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
