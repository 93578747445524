<template>
  <div class="resource-management">
    <div class="tips">
      <div class="title">
        仅针对开通2.0资源门店，1.0资源门店不支持该模式，该配置决定小程序播控显示的模式入口，最多可展示4个
      </div>
      <el-button class="save-btn" type="primary" size="large" @click="handleSave">保存</el-button>
    </div>
    <div class="content-list">
      <div class="content" v-for="(item, index) in resourceList" :key="index">
        <div class="title">{{ item.menusName }}</div>
        <div class="switch">
          <div>是否启用</div>
          <el-switch
            v-model="item.isEnable"
            :active-value="0"
            :inactive-value="1"
            :disabled="item.menusName === '剧本杀'"
            size="large"
          />
        </div>
        <div class="sortable">
          <div>排序</div>
          <el-input-number v-model="item.sort" :min="1" :max="99" :step="1" step-strictly @change="handleChange" />
        </div>
        <div class="upload" v-for="(ii, inx) in uploadList" :key="inx">
          <div class="video-title">{{ ii.uploadTitle }}</div>
          <el-upload
            ref="myUpload"
            action="#"
            accept=".ts"
            :disabled="item.menusName === '剧本杀'"
            :http-request="params => changeImage(params, item, ii.imageUrl, index, inx, ii.imageUrl)"
            list-type="picture-card"
            :show-file-list="false"
            :class="[item.menusName === '剧本杀' ? 'hiddenStyle' : '']"
            v-loading="item[ii.imageUrl] ? item[ii.imageUrl + 'Loading'] : false"
            element-loading-text="正在解析资源中..."
          >
            <div v-if="item[ii.imageUrl]" class="image">
              <div :id="'video-place-' + index + '-' + inx"></div>
            </div>
            <el-icon v-else class="avatar-uploader-icon"><Plus /></el-icon>
          </el-upload>
          <i
            @click="handleDelete(item, ii.imageUrl)"
            v-if="item[ii.imageUrl]"
            class="el-icon-delete"
            style="
            font-size: 16px;
              position: absolute;
              cursor: pointer;
              right: 0px;
              top: 0px;"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import parseTs from '../../../../lin/util/parseTs.js'
import { headers, client } from '../../../../lin/util/uploadImg.js'
import Api from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
const DIR = 'resource/'
export default {
  data() {
    return {
      isSave: true,
      resourceList: [],
      uploadLoading: [],
      uploadList: [
        { uploadTitle: '片头视频.ts', imageUrl: 'head' },
        { uploadTitle: '片尾视频.ts', imageUrl: 'tail' },
      ],
    }
  },
  created() {
    this.queryResource()
  },
  methods: {
    reUploadVideo(item, imageUrlProp) {
      console.log('点击覆盖')
      const uploadComponent = this.$refs.myUpload
      uploadComponent.$el.click()
    },
    async queryResource() {
      const res = await Api.queryMenusModelResource()
      if (res.code == '200') {
        this.resourceList = res.data.map((a, index) => ({
          ...a,
          headLoading: true,
          tailLoading: true,
          index,
          textUrl: '',
        }))
      }
      let index = 0
      for await (let item of this.resourceList) {
        if (item.head) {
          await parseTs(item.head, 'video-place-' + index + '-' + 0, item, 'head')
        }
        if (item.tail) {
          await parseTs(item.tail, 'video-place-' + index + '-' + 1, item, 'tail')
        }
        index++
      }
    },
    changeImage(file, item, propName, index, inx, loadingPropName) {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传素材需要小于100M！')
        return
      }
      item[propName] = ''
      this.isSave = false
      // 上传前
      let filesSplit = file.file.name.split('.')
      this.uploadLoading.push(filesSplit[0])
      item[loadingPropName + 'Loading'] = true
      ElMessage.info('上传中，请稍后...')
      let fileName = Date.now() + '.' + filesSplit[filesSplit.length - 1]
      client()
        .put(DIR + fileName, file.file, { headers })
        .then(res => {
          // 上传完成后
          this.uploadLoading = this.uploadLoading.filter(item => item !== filesSplit[0])
          if (this.uploadLoading.length == 0) {
            this.isSave = true
          }
          item[propName] = res.res.requestUrls[0]
          ElMessage.success('上传成功，正在解析中，请稍后...')
          console.log(item)

          parseTs(res.res.requestUrls[0], 'video-place-' + index + '-' + inx, item, loadingPropName)
        })
        .finally(() => {})
    },
    async handleSave() {
      if (!this.isSave) {
        ElMessage.error('正在上传视频，请稍后保存')
        return
      }
      const list = this.resourceList.map(item => {
        return {
          id: item.id,
          sort: item.sort,
          isEnable: item.isEnable,
          head: item.head,
          tail: item.tail,
        }
      })
      const res = await Api.saveMenusModelResource(list)
      if (res.code == '200') {
        ElMessage.success(res.msg || '保存成功')
        // this.queryResource()
      } else {
        ElMessage.error('保存失败')
      }
    },

    handleDelete(data, propName) {
      ElMessageBox.confirm('确认要删除当前视频吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        data[propName] = ''
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.resource-management {
  margin: 20px;
  font-size: 13px;
  background-color: #f8faff;
  .content-list {
    display: flex;
    overflow-x: scroll;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 30px 0;
  }
  .tips {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0;
    margin-bottom: 20px;
    .title {
      color: red;
      font-size: 14px;
      font-weight: 600;
    }
    .save-btn {
      width: 120px;
    }
  }
  .content:nth-child(n) {
    margin: 0 30px;
  }
  .content {
    width: 260px;
    .title {
      width: 230px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 0.005208rem solid #d0c8c8;
    }
    .switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;
    }
    .sortable {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px 0;
      ::v-deep .el-input-number {
        width: 136px;
      }
    }
    .upload {
      position: relative;
      .video-title {
        text-align: center;
        margin-bottom: 20px;
      }
      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        // width: 230px;
        // height: 180px;
      }
      ::v-deep .el-upload--picture-card {
        position: relative;
        width: 230px !important;
        height: 160px !important;
        line-height: 160px !important;
      }
    }
    .upload:nth-child(n) {
      margin: 30px 0;
    }
    .hiddenStyle {
      ::v-deep .el-upload--picture-card {
        background-color: #e6e6e6;
      }
    }
  }
}
</style>
