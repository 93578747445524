<template>
  <el-dialog v-model="dialogVisible" width="40%" :before-close="handleClose">
    <div style="margin-bottom: 20px;">
      <span>会员手机号：{{ updateMemberInfo.telephone }}</span>
      <span style="margin-left: 50px;">会员姓名：{{ updateMemberInfo.userName }}</span>
    </div>
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="activateCardStore" label="卡类型"> </el-table-column>
      <el-table-column prop="allAmount" label="余额"> </el-table-column>
      <el-table-column prop="amount" label="本金余额"> </el-table-column>
      <el-table-column prop="giftAmount" label="赠金余额"> </el-table-column>
      <el-table-column prop="lockTotalAmount" label="不可用余额"> </el-table-column>
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleClose">
          关闭
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmit, onMounted, reactive } from 'vue'
import API from '../../service/index'

let props = defineProps({
  updateMemberInfo: {
    type: Object,
    default: {},
  },
})
const emit = defineEmit(['handleShowPointsVisible'])
let dialogVisible = ref(true)

/**
 * 关闭弹出框
 */
const handleClose = () => {
  emit('handleShowPointsVisible', false)
}

const tableData = ref([])
const queryPoints = async () => {
  let res = await API.queryStoreCardListByCustomerCode({
    customerCode: props.updateMemberInfo.code,
  })
  if (res.code === '200') {
    tableData.value = res.data?.map(item => ({
      ...item,
      amount: (item.amount / 100).toFixed(2),
      giftAmount: (item.giftAmount / 100).toFixed(2),
      lockTotalAmount: (item.lockTotalAmount / 100).toFixed(2),
      allAmount: ((item.giftAmount + item.amount + item.lockTotalAmount) / 100).toFixed(2),
      activateCardStore: item.activateCardStore + '门店卡',
    }))
  } else {
    ElMessage.error(res.message)
  }
}

onMounted(() => {
  queryPoints()
})
</script>

<style scoped lang="scss">
.dialog-footer {
  display: block;
  text-align: right;
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
</style>
