<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
      <el-form-item label="营业时间">
        <el-date-picker
          value-format="YYYY-MM-DD"
          v-model="data.time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="data.defaultTime"
          clearable
        />
      </el-form-item>
      <el-form-item label="门店区域">
        <el-cascader
          v-model="data.regionCode"
          :options="areaOptions"
          clearable
          filterable
          @change="changeArea()"
          :show-all-levels="false"
          collapse-tags
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="门店">
        <el-select-v2
          v-model="data.storeCode"
          :options="data.storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          style="width: 250px;"
          multiple
          filterable
          collapse-tags
          collapse-tags-tooltip
        />
      </el-form-item>
      <el-form-item label="班次">
        <el-select v-model="data.classCode" filterable placeholder="请选择" clearable>
          <el-option label="白天场" :value="1" />
          <el-option label="黄金场" :value="2" />
          <el-option label="午夜场" :value="3" />
        </el-select>
      </el-form-item>
      <el-form-item label="包厢类型">
        <el-select v-model="data.roomTypeCode" filterable placeholder="请选择" clearable>
          <el-option v-for="item in data.roomTypes" :key="item.id" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button @click="searchLists" type="primary">
          查询
        </el-button>
        <el-button @click="resetData">
          重置
        </el-button>
        <el-button @click="exportData($event)">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="data.tableData" border style="width: 96%; margin-left: 2%;" :emptyText="emptyTableText">
      <el-table-column width="180">
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="businessDay" label="营业日期">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="storeCode" label="门店编码">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="storeName" label="门店名称">
        </el-table-column>
      </el-table-column>

      <el-table-column label="营业收入">
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="150px"
          prop="totalSettleAmount"
          label="营业额(结算)"
        >
          <template #header="row">
            营业额(结算)
            <el-tooltip placement="top-start">
              <template #content
                >营业额(结算) = 消费收入- 门店储值 <br />
                卡支付 + 门店储值卡充值
              </template>

              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="totalAmount">
          <template #header>
            消费总计
            <el-tooltip placement="top-start">
              <template #content> 消费总计 = 包厢费收入+超市收入<br />+厨房收入+水吧收入+其他收入 </template>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="roomAmount" label="包厢费收入">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="superMarketAmount" label="超市收入">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="kitchenAmount" label="厨房收入">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="waterBarAmount" label="水吧收入">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="otherAmount" label="其他收入">
        </el-table-column>
      </el-table-column>
      <el-table-column label="待结账">
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="noSettleBatchAmount"
          label="待结账金额"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="noSettleBatchCount"
          label="待结账批次"
        >
        </el-table-column>
      </el-table-column>
      <el-table-column label="营业收入-储值">
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="commonStoredPay"
          label="通用储值卡支付"
        >
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="commonStored" label="通用储值卡充值">
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="storeStoredPay"
          label="门店储值卡支付"
        >
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="storeStored" label="门店储值卡充值">
        </el-table-column>
      </el-table-column>
      <el-table-column label="待客批次-包厢类型">
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="totalRoomCount" label="批次总计">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="countMiniRoom" label="迷你包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="countSmallRoom" label="小包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="countCentreRoom" label="中包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="countBigRoom" label="大包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="countPtRoom" label="PT包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="countPresidentRoom" label="总统包">
        </el-table-column>
      </el-table-column>
      <el-table-column label="包厢收入-包厢类型">
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="amountMiniRoom" label="迷你包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="amountSmallRoom" label="小包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="amountCentreRoom" label="中包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="amountBigRoom" label="大包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="amountPtRoom" label="PT包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="amountPresidentRoom" label="总统包">
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="amountNoRoom" label="非包厢收入">
        </el-table-column>
      </el-table-column>
      <el-table-column label="客单价">
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="customerTotalAmount" label="总计">
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="customerRoomAmount"
          label="包厢费收入"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="customerSuperMarketAmount"
          label="超市收入"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="customerKitchenAmount"
          label="厨房收入"
        >
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="customerWaterBarAmount"
          label="水吧收入"
        >
        </el-table-column>
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="customerOtherAmount" label="其他收入">
        </el-table-column>
      </el-table-column>
      <el-table-column label="收入占比">
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="roomAmountProportion"
          label="包厢费收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="roomMiniRoom"
          label="迷你包包厢收入"
        />
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="roomSmallRoom" label="小包包厢收入" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="roomCentreRoom"
          label="中包包厢收入"
        />
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="roomBigRoom" label="大包包厢收入" />
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="roomPtRoom" label="PT包包厢收入" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="roomPresidentRoom"
          label="总统包包厢收入"
        />

        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="superMarketMiniRoom"
          label="超市收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="superMarketSmallRoom"
          label="迷你包超市收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="superMarketSmallRoom"
          label="小包超市收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="superMarketCentreRoom"
          label="中包超市收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="superMarketBigRoom"
          label="大包超市收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="superMarketPtRoom"
          label="PT包超市收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="superMarketPresidentRoom"
          label="总统包超市收入"
        />

        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="kitchenAmountProportion"
          label="厨房收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="kitchenMiniRoom"
          label="迷你包厨房收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="kitchenSmallRoom"
          label="小包厨房收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="kitchenCentreRoom"
          label="中包厨房收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="kitchenBigRoom"
          label="大包厨房收入"
        />
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="kitchenPtRoom" label="PT包厨房收入" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="kitchenPresidentRoom"
          label="总统包厨房收入"
        />

        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="waterBarAmountProportion"
          label="水吧收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="waterBarMiniRoom"
          label="迷你包水吧收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="waterBarSmallRoom"
          label="小包水吧收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="waterBarCentreRoom"
          label="中包水吧收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="waterBarBigRoom"
          label="大包水吧收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="waterBarPtRoom"
          label="PT包水吧收入"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="100px"
          prop="waterBarPresidentRoom"
          label="总统包水吧收入"
        />

        <el-table-column show-overflow-tooltip width="120px" prop="otherAmountProportion" label="其他收入占比" />
      </el-table-column>
      <el-table-column label="收款方式">
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="wx" label="微信" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="ali" label="支付宝" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="xianjin" label="现金" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="commonStoredCardsPrincipalFee"
          label="通用储值卡本金"
        />

        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="commonStoredCardsGiftFeeFee"
          label="通用储值卡赠金"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="storeStoredCardsPrincipalFee"
          label=" 门店储值卡本金"
        />

        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="storeStoredCardsGiftFeeFee"
          label="门店储值卡赠金"
        />
        <!-- <el-table-column show-overflow-tooltip width="120px" prop="otherAmountProportion" label="雷石会员" /> -->
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="meida" label="美大支付" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="douyin" label="抖音支付" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="leishiCoupon" label="雷石优惠券" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="newSystemCoupon"
          label="新系统优惠券"
        />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="neibuguazhang" label="内部挂账" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="zhaodaimianshou" label="招待免收" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="guanlizuzhaodai" label="利通取酒" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="kejihuiyuan" label="可即会员" />
        <el-table-column align="center" show-overflow-tooltip width="120px" prop="pos" label="POS" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="litongCustomerBenjin"
          label="利通会员本金"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="120px"
          prop="litongCustomerZengjin"
          label="利通会员赠金"
        />
        <el-table-column align="center" show-overflow-tooltip width="100px" prop="giftCard" label="礼品卡" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          width="200px"
          prop="yudingWechat"
          label="预订单微信支付"
        />
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import { post } from '@/lin/plugin/axios'
import clearSelect from '@/lin/util/clearSelect2.js'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { reactive, onMounted, ref, nextTick } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import Api from '@/api/index.js'
import { createMoveBall } from '@/utils/createMoveBall.js'
const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCode = []
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
}

const props = defineProps({
  auths: {},
})
const data = reactive({
  defaultTime: new Date(2000, 1, 1, 8, 0, 0), // '12:00:00'
  storeCode: [],
  classCode: '',
  roomTypeCode: '',
  totalRowData: {},
  time: [
    dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD'),
    dayjs()
      .subtract(1, 'day')
      .format('YYYY-MM-DD'),
  ],
  regionCode: [],
  roomTypes: [],
  peopleSources: [],
  areaCode: [],
  tableData: [],
  fullscreenLoading: false,
  storeList: [],
  productTypes: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  authMenus: {},
  payMents: [],
})

const route = useRoute()

onMounted(async () => {
  route.meta.msg = props.auths
  operationAuth(route, data.authMenus)
  getParentStoreRoomTypeList()
  getStores()
  getAreas()
  getPeopleSource()
  // getEnmuList()
})
const getEnmuList = async () => {
  const res = await Api.getEnmuList()
  if (res.code === '200') {
    localStorage.setItem('excelEnmu', JSON.stringify(res?.data) || {})
  }
}

const getParentStoreRoomTypeList = async () => {
  let res = await API.getParentStoreRoomTypeList()
  if (res.code === '200') {
    data.roomTypes = res.data
  } else {
    ElMessage.error(res.msg)
  }
}

const getPeopleSource = async () => {
  let res = await API.getPeopleSource({
    currentPage: 1,
    pageSize: 999,
  })
  if (res.code === '200') {
    data.peopleSources = res.data.records
  } else {
    ElMessage.error(res.msg)
  }
}

const getStores = async () => {
  let res = await API.getStoreListByName({ list: data.areaCode })
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.time = [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD'),
  ]
  data.pageSize = 10
  data.pageNum = 1
  data.classCode = undefined
  data.roomTypeCode = undefined
  data.storeCode = []
  data.regionCode = []
  data.areaCode = []
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
  searchLists()
}

/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}

const exportData = async event => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }

  data.fullscreenLoading = true

  let model = {}
  if (!data.time) {
    model.startTime = ''
    model.endTime = ''
  } else {
    model.startTime = data.time[0]
    model.endTime = data.time[1]
  }
  model.stores = data.storeCode
  model.roomTypeCode = data.roomTypeCode
  model.classCode = data.classCode
  model.regions = data.areaCode
  model.pageSize = data.pageSize
  model.pageNum = data.currentPage

  if (!getDays(model.startTime, model.endTime)) {
    data.fullscreenLoading = false
    return
  }
  createMoveBall(event)
  const param = generateParam(model)
  let res = await post(`/shadow/export/download?type=STORE_REVENUE_ANALYSIS`, param).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}
const getDays = (sDate1, sDate2) => {
  if (!sDate1 || !sDate2) {
    ElMessage.warning('请设置查询时间')
    return false
  }

  const DAY = 32
  //sDate1和sDate2是2002-12-18格式
  var dateSpan, tempDate, iDays
  sDate1 = Date.parse(sDate1)
  sDate2 = Date.parse(sDate2)
  dateSpan = sDate2 - sDate1
  dateSpan = Math.abs(dateSpan)
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000)) + 1
  if (iDays > DAY) {
    ElMessage.warning('查询或导出时间范围不能超过一个月')
    return false
  }
  return true
}
const emptyTableText = ref('请先设置查询条件，再点击查询数据')
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  let params = {
    stores: data.storeCode,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    roomTypeCode: data.roomTypeCode,
    classCode: data.classCode,
    regions: data.areaCode,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  if (!getDays(params.startTime, params.endTime)) {
    return
  }
  data.fullscreenLoading = true
  params = generateParam(params)

  //占坑
  data.tableData = [{ name: '合计' }]

  getTableData(params, 'STORE_REVENUE_ANALYSIS')

  if (params.pageNum > 1) {
    return data.tableData.splice(0, 1, data.totalRowData)
  }
  getTotalRowRequest(params, 'STORE_REVENUE_ANALYSIS')
}

// 获取报表数据
const getTableData = async (params, type) => {
  data.fullscreenLoading = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    data.tableData.push(...(res.data.items ?? []))
    data.total = res.data.total
    emptyTableText.value = data.tableData.length == 0 ? '暂无数据' : ''
  } else {
    data.tableData = []
    data.total = 0
  }
}

/**
 * @description: 获取合计请求数据
 * @return {*}
 */
const getTotalRowRequest = async (params, type) => {
  let res = await post(`/shadow/report/summary?type=${type}`, params)
  if (res.code == 200) {
    data.totalRowData = res.data ?? {}
    data.tableData.splice(0, 1, data.totalRowData)
  } else {
    data.tableData.splice(0, 1)
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}

::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 30px;
}
</style>
