/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-11-11 11:18:42
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/operation_manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '运维管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/operation_manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '包厢自检管理',
      type: 'view',
      name: 'boxCheckManager',
      route: '/operation_manager/boxCheckManager',
      filePath: 'plugin/operation_manager/view/boxCheckManager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '数据重跑',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/reRunData',
      filePath: 'plugin/operation_manager/view/reRunData/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '商品同步',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/syncGoods',
      filePath: 'plugin/operation_manager/view/syncGoods/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '核销优惠券',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/cancelCoupou',
      filePath: 'plugin/operation_manager/view/coupous_cancel/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '版本管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/versionManager',
      filePath: 'plugin/operation_manager/view/version_manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '修改手机号',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/updatePhone',
      filePath: 'plugin/operation_manager/view/update_phone/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '存取酒校验',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/liquorRetrievalCode',
      filePath: 'plugin/operation_manager/view/liquor_retrievalCode/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '发券助手',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/voucherAssistant',
      filePath: 'plugin/operation_manager/view/voucher_assistant/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '设备管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/deviceManager',
      filePath: 'plugin/operation_manager/view/device_manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '利通会员迁移',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/operation_manager/ltMemberMigration',
      filePath: 'plugin/operation_manager/view/ltMemberMigration/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
