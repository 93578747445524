<!--
 * @Author: your name
 * @Date: 2022-04-22 13:25:02
 * @LastEditTime: 2022-06-23 11:32:43
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/view/resetPassword/resetPassword.vue
-->
<template>
  <div class="reset-password-box">
    <HeaderBack />
    <div class="resetPassword-content">
      <el-steps :space="200" :active="stepNum" simple>
        <el-step title="修改密码" icon="iconfont icon-a-1-xuanzhong" />
        <el-step title="更改完成" icon="iconfont icon-a-2-weixuanzhong" />
      </el-steps>
      <div class="form-model">
        <!-- <h1 class="reset-title">修改密码</h1> -->
        <div style="margin: 20px 0px; text-align: left; color: red;">
          密码规则：6-16位，必须为字母和数字的组合
        </div>
        <el-form
          label-position="left"
          label-width="80px"
          :model="model"
          ref="formRef"
          :rules="rules"
          :hide-required-asterisk="true"
        >
          <el-form-item label="新密码" prop="password">
            <el-input type="password" v-model="model.password" />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input type="password" v-model="model.confirmPassword" />
          </el-form-item>
          <el-button type="primary" class="confirm-btn" @click="onSubmit">确认 ></el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBack from '../../component/base/backHeader/goBack.vue'
import { ElMessage } from 'element-plus'
import api from '../login/api/index'
export default {
  components: { HeaderBack },
  data() {
    return {
      model: {
        password: '',
        confirmPassword: '',
        stepNum: 0,
      },
      rules: {
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.model.password) {
                callback(new Error('两次密码输入不一致！'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },
  methods: {
    onSubmit() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          api.changeSaasUserSecret({ password: this.model.password }).then(res => {
            this.stepNum = 1
            if (res.code == '200') {
              ElMessage({
                type: 'success',
                message: '修改成功',
              })
              setTimeout(() => {
                localStorage.clear()
                this.$router.push('/login')
              }, 1000)
            } else {
              ElMessage.error(res.msg)
            }
          })
        } else {
          console.log('error submit!')
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 87px !important;
  }
}
.reset-password-box {
  width: 1568px;
  height: 888px;
  background: #ffffff;
  border-radius: 8px;
  margin: auto;
  margin-top: 40px;

  .resetPassword-content {
    width: 524px;
    height: 300px;
    margin: auto;
  }

  .form-model {
    width: 80%;
    margin: auto;

    input {
    }

    .reset-title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
      margin-top: 80px;
      margin-bottom: 20px;
    }

    .confirm-btn {
      width: 100%;
      margin: auto !important;
      border-radius: 8px;
      opacity: 0.65;
    }
  }
}
</style>
