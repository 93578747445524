<!-- 营业日报-->
// todo 日报汇总开发
<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <!-- <el-tab-pane label="日报汇总" name="preferentialDetailAtuth" v-if="isShowReport('preferentialDetailAtuth')">
      <preferential-detail :auths="authType.preferentialDetailAtuth" />
    </el-tab-pane> -->
    <el-tab-pane label="日报明细" name="dailyDetailAuth" v-if="isShowReport('dailyDetailAuth')">
      <daily-detail :auths="authType.dailyDetailAuth" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import dailyDetail from '../report_module/dailyDetail.vue'
// import giftDetails from '../report_module/gift-details.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  preferentialDetailAtuth: {}, // 优惠明细
  dailyDetailAuth: {}, // 日报明细
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.preferentialDetailAtuth = authAllMenus.find(a => a.url === 'preferentialDetailAtuth') || { childrens: [] }
  authType.dailyDetailAuth = authAllMenus.find(a => a.url === 'dailyDetailAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'preferentialDetailAtuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
