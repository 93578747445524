<template>
  <el-form :inline="true" style="width: 96%; margin-left: 2%;margin-top:20px">
    <el-form-item label="门店编号">
      <el-input
        placeholder="请输入门店编号"
        v-model="data.code"
        class="input-search-tel"
        clearable
        @keyup.enter="queryShops"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店名称">
      <el-input
        clearable
        placeholder="请输入门店名称"
        v-model="data.name"
        class="input-search-tel"
        @keyup.enter="queryShops"
      ></el-input>
    </el-form-item>
    <el-form-item label="缴费类型">
      <el-select v-model="data.feeType" placeholder="请选择" clearable>
        <el-option :value="1" label="首次缴费">首次缴费</el-option>
        <el-option :value="2" label="续费">续费</el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="queryShops" v-if="data.authMenus.query">查询</el-button>
      <el-button @click="resetQuery">重置</el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" width="120" />
    <el-table-column prop="storeCode" label="门店编号" align="center" />
    <el-table-column prop="storeName" label="门店名称" align="center" width="120" />
    <el-table-column prop="feeAmount" label="缴费金额" align="center" width="120" />
    <el-table-column label="缴费类型" align="center" width="120">
      <template #default="{ row }">
        {{ row.feeType == 1 ? '首次缴费' : '续费' }}
      </template>
    </el-table-column>
    <el-table-column prop="onlineTime" label="上线时间" align="center" width="120" />
    <el-table-column prop="beforeExpiredTime" label="缴费前到期时间" align="center" width="150" />
    <el-table-column prop="afterExpiredTime" label="缴费后到期时间" align="center" width="150" />
    <el-table-column prop="createName" label="登记人" align="center" width="120" />
    <el-table-column prop="createTime" label="登记时间" align="center" width="120" />

    <el-table-column label="附件" align="center" width="120">
      <template #default="{ row }">
        <!-- <a :href="row.voucherPic" style=" cursor: pointer;color: cadetblue;">查看</a>
         -->
        <text v-if="row.voucherPic" @click="handleClickImage(row)" style=" cursor: pointer;color: cadetblue;"
          >查看</text
        >
      </template>
    </el-table-column>
  </el-table>

  <!-- 图片展示框 -->
  <el-dialog v-model="data.clickImageDialogVisible">
    <el-image :src="data.clickImageDialogImageUrl" fit="cover" alt="暂无附件" v-if="data.clickImageDialogImageUrl" />
    <div v-else style="text-align: center;">
      暂无附件
    </div>
  </el-dialog>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import { reactive, onMounted, toRaw, computed, ref } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'

const data = reactive({
  code: undefined,
  name: undefined,
  feeType: undefined,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  authMenus: {},
  loading: false,
  clickImageDialogVisible: false,
  clickImageDialogImageUrl: '',
})
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)

  queryShops()
})

const handleClickImage = row => {
  data.clickImageDialogVisible = true
  data.clickImageDialogImageUrl = row.voucherPic
}

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

//重置
const resetQuery = () => {
  data.code = undefined
  data.name = undefined
  data.feeType = undefined

  queryShops()
}

// 获取门店列表数据
const searchLists = () => {
  if (data.authMenus.query) {
    data.loading = true
    let query = {
      code: data.code,
      name: data.name,
      feeType: data.feeType,
      pageNum: data.currentPage,
      pageSize: data.pageSize,
    }

    API.subscriptionOrderList(query).then(res => {
      if (res.code === '200') {
        data.tableData = res.data.records || []
        data.total = res.data.totalRecords
        data.loading = false
      } else {
        ElMessage.error(res.msg)
        data.loading = false
      }
    })
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
const queryShops = () => {
  if (data.authMenus.query) {
    data.currentPage = 1
    searchLists()
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists()
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    .title {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      width: 200px;
      margin-left: 20px;
      margin-right: 10px;
      text-align: right;
    }

    .input-with-select {
      width: 340px;
    }

    .input-search-tel {
      width: 300px;
    }

    .search-button {
      margin-left: 20px;
      width: 144px;
      height: 25px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 10px;
    margin-right: 20px;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
