import _axios, { post, get, put } from '@/lin/plugin/axios'
import axios from 'axios'


class CsMng {
  //商品列表
  async checkChargeToInventory(data) {
    return await post(`/adminChain/goods/saas/goodsSku/checkChargeToInventory`, data)
  }
  async selectByType(data) {
    return await get(`/adminChain/goods/saas/category/selectByType`, data)
  }
  async updateCategory(data) {
    return await post(`/adminChain/goods/saas/category/updateCategory`, data)
  }
  async deleteByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/category/deleteByCode`, { params: data })
  }
  async createCategory(data) {
    return await post(`/adminChain/goods/saas/category/createCategory`, data)
  }
  async getSelectList(data) {
    return await get(`/adminChain/goods/saas/statisticsType/selectList`, data)
  }
  async selectListByType(data) {
    return await get(`/adminChain/goods/saas/category/selectListByType`, data)
  }
  async updateIsShelves(data) {
    return await get(`/adminChain/goods/saas/category/updateIsShelves`, data)
  }

  async updateIsShelvesNoType(data) {
    return await get(`adminChain/goods/saas/goodsSku/updateIsShelves`, data)
  }
  //单位配置
  async selectDictList(data) {
    return await get(`/adminChain/goods/saas/dict/selectDictList`, data)
  }
  async deleteDict(data) {
    return await _axios.delete(`/adminChain/goods/saas/dict/deleteDict`, { params: data })
  }
  async saveDict(data) {
    return await post(`/adminChain/goods/saas/dict/saveDict`, data)
  }
  async selectDictListAll(data) {
    return await get(`/adminChain/goods/saas/dict/selectDictListAll`, data)
  }
  async updateDict(data) {
    return await post(`/adminChain/goods/saas/dict/updateDict`, data)
  }

  //出入库类型
  async selectChannel(data) {
    return await get(`/adminChain/goods/saas/stockChannel/selectChannel`, data)
  }

  //易耗品商店列表
  async selectConsumables(data) {
    return await post(`/adminChain/goods/saas/goodsSku/selectConsumables`, data)
  }

  //商品列表 -- 一级操作
  async selectSpuList(data) {
    return await post(`/adminChain/goods/saas/goodsSpu/selectSpuList`, data)
  }
  async saveSpuInfo(data) {
    return await post(`/adminChain/goods/saas/goodsSpu/saveSpuInfo`, data)
  }
  async updateSpuInfo(data) {
    return await post(`/adminChain/goods/saas/goodsSpu/updateSpuInfo`, data)
  }
  async deleteSpuInfoByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/goodsSpu/deleteSpuInfoByCode`, { params: data })
  }
  async uploadImags(data) {
    return await post('/adminChain/goods/saas/material/upload', data, { 'Content-type': 'multipart/form-data' })
  }

  //门店
  async getStoreList(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']

    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }


  //商品门店二级
  async saveSkuOne(data) {
    return await post('/adminChain/goods/saas/goodsSku/saveSkuOne', data)
  }
  async updateSkuByCode(data) {
    return await post('/adminChain/goods/saas/goodsSku/updateSkuByCode', data)
  }
  async selectDetails(data) {
    return await get('/adminChain/goods/saas/goodsSku/selectDetails', data)
  }
  async deleteSkuByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/goodsSku/deleteSkuByCode`, { params: data })
  }

  //供应商信息
  async selectSupplierPage(data) {
    return await get('/adminChain/supplier/saas/supplier/selectSupplierPage', data)
  }
  async saveSupplierInfo(data) {
    return await post('/adminChain/supplier/saas/supplier/saveSupplierInfo', data)
  }
  async updateSupplierInfo(data) {
    return await post('/adminChain/supplier/saas/supplier/updateSupplierInfo', data)
  }

  //渠道
  async getChannelList(data) {
    return await get('/adminChain/goods/saas/getChannelList', data)
  }

  //组合
  async selectSkuArrayPage(data) {
    return await post('/adminChain/goods/saas/goodsArray/selectSkuArrayPage', data)
  }
  async saveSkuArray(data) {
    return await post('/adminChain/goods/saas/goodsArray/saveSkuArray', data)
  }
  async updateSkuArrayByCode(data) {
    return await post('/adminChain/goods/saas/goodsArray/updateSkuArrayByCode', data)
  }
  async deleteComboByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/goodsCombo/deleteComboByCode`, { params: data })
  }
  async selectBelow(data) {
    return await get('/adminChain/goods/saas/goodsCombo/selectBelow', data)
  }
  async selectBelowProductSkuArray(data) {
    return await get('/adminChain/goods/saas/goodsArray/selectBelowProductSkuArray', data)
  }
  async updateSkuArrayIsShelves(data) {
    return await get('/adminChain/goods/saas/goodsSku/updateSkuArrayIsShelves', data)
  }
  async selectSkuArrayItem(data) {
    return await get('/adminChain/goods/saas/goodsArray/selectSkuArrayItem', data)
  }

  //套餐
  async selectComboPage(data) {
    return await post('/adminChain/goods/saas/goodsCombo/selectComboPage', data)
  }
  async createComboInfo(data) {
    return await post('/adminChain/goods/saas/goodsCombo/createComboInfo', data)
  }
  async updateComboInfo(data) {
    return await post('/adminChain/goods/saas/goodsCombo/updateComboInfo', data)
  }
  async updateComboIsShelves(data) {
    return await get('/adminChain/goods/saas/goodsCombo/updateComboIsShelves', data)
  }
  async selectBelowProductCombo(data) {
    return await get('/adminChain/goods/saas/goodsCombo/selectBelowProductCombo', data)
  }
  async selectComboItem(data) {
    return await get('/adminChain/goods/saas/goodsCombo/selectComboItem', data)
  }
  //地区
  async getArea(data) {
    return await get('/adminChain/organizational/saas/getArea', data)
  }

  //商品批量下发前置查询
  async selectSpuListCodes(data) {
    return await post('/adminChain/goods/saas/goodsSpu/selectSpuListCodes', data)
  }

  //组织
  async getCheckAreaOrganizational(data) {
    return await get('/adminChain/organizational/saas/getCheckAreaOrganizational', data)
  }

  //查询门店区域关联门店详情
  getRegionList(data) {
    return get('/adminChain/store/saas/storeRegionConfig/getRegionList', data)
  }

  //商品导入
  inputBatchProductFile(data) {
    return post('/adminChain/goods/saas/inputBatchProductFile', data)
  }

  //套餐导入
  inputBatchComboFile(data) {
    return post('/adminChain/goods/saas/inputBatchComboFile', data)
  }

  //获取全部商品
  selectProductAll(data) {
    return post('/adminChain/goods/saas/goodsSku/selectProductAll', data)
  }

  //商品下发
  skuBatchInStore(data) {
    return post('/adminChain/goods/saas/goodsSku/skuBatchInStore', data)
  }

  //套餐下发
  selectComboPageCodes(data) {
    return post('/adminChain/goods/saas/goodsCombo/selectComboPageCodes', data)
  }



  exportReport({ url, data }) {
    return _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
  }

  //物料管理
  querySupplierMaterielInfo(data) {
    return post('/adminChain/materiel/querySupplierMaterielInfo', data)
  }

  queryMaterielBindingInfo(data) {
    return post('/adminChain/materiel/queryMaterielBindingInfo', data)
  }

  syncSupplierMateriel(data) {
    return post('/adminChain/materiel/syncSupplierMateriel', data)
  }
  //空瓶管理

  //空瓶物料信息
  queryEmptyBottleMaterielBindingInfo(data) {
    return post('/adminChain/materiel/queryEmptyBottleMaterielBindingInfo', data)
  }
  //空瓶信息管理
  queryGoodsEmptyBottleInfo(data) {
    return post('/adminChain/goodsEmptyBottle/queryGoodsEmptyBottleInfo', data)
  }
  //新增空瓶信息
  saveTbGoodsEmptyBottle(data) {
    return post('/adminChain/goodsEmptyBottle/saveTbGoodsEmptyBottle', data)
  }
  //编辑空瓶信息
  updateTbGoodsEmptyBottle(data) {
    return post('/adminChain/goodsEmptyBottle/updateTbGoodsEmptyBottle', data)
  }

  //查看空瓶信息
  queryTbGoodsEmptyBottleDetail(data) {
    return post('/adminChain/goodsEmptyBottle/queryTbGoodsEmptyBottleDetail', data)
  }

  selectCouponPackagePage(data) {
    return post('/adminChain/coupon/saas/package/selectCouponPackagePage', data)
  }

  selectCouponPackagePageByCombo(data) {
    return post('/adminChain/coupon/saas/package/selectCouponPackagePageByCombo', data)
  }
}

export default new CsMng()
