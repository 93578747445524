<template>
  <el-dialog v-model="visible" title="角色管理" width="45%" :before-close="backtrack">
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="roleMsg"
      label-width="120px"
      :rules="rules"
      ref="elForm"
      v-loading="loading"
      element-loading-text="正在火速更新数据,请勿关闭。。。"
    >
      <el-form-item label="角色名称" prop="roleName">
        <el-input v-model="roleMsg.roleName" placeholder="请填写角色名称" style="width:75%" />
      </el-form-item>
      <el-form-item label="功能权限">
        <el-tree
          ref="tree"
          :data="menus"
          :props="defaultProps"
          show-checkbox
          node-key="menuCode"
          :default-checked-keys="isSelectMenus"
          :height="300"
        />
      </el-form-item>
      <el-form-item label="星系统权限">
        <el-tree
          ref="treeNew"
          :data="menusNew"
          :props="defaultPropsNew"
          show-checkbox
          node-key="code"
          :default-checked-keys="isSelectMenusNew"
          :height="300"
        />
      </el-form-item>
      <el-form-item label="参与提成">
        <el-checkbox v-model="roleMsg.isCommission" :true-label="1" :false-label="0"></el-checkbox>
      </el-form-item>

      <!-- 额度权限-->
      <el-form-item label="额度权限" class="quota-auth">
        <el-form label-width="150px" :rules="rules" ref="authElForm" :model="roleMsg.limitSaveInfoBo">
          <el-form-item label="月赠送时长限额" prop="monthDuration">
            <el-input v-model="roleMsg.limitSaveInfoBo.monthDuration" placeholder="请输入" :maxlength="4">
              <template #suffix>
                分钟/月
              </template>
            </el-input>
            <span class="remark">每月一号重置</span>
          </el-form-item>

          <el-form-item label="每台赠送时长限额" prop="oneDuration">
            <el-input v-model="roleMsg.limitSaveInfoBo.oneDuration" placeholder="请输入" :maxlength="4">
              <template #suffix>
                分钟/台
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="月赠送商品限额" prop="productLimit">
            <el-input v-model="roleMsg.limitSaveInfoBo.productLimit" placeholder="请输入" :maxlength="4">
              <template #suffix>
                元/月
              </template>
            </el-input>
            <span class="remark">每月一号重置</span>
          </el-form-item>
          <el-form-item label="每台赠送商品限额" prop="oneLimit">
            <el-input v-model="roleMsg.limitSaveInfoBo.oneLimit" placeholder="请输入" style="width:60%" :maxlength="4">
              <template #suffix>
                元/台
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="减免折扣免单总额" prop="monthOrder">
            <el-input v-model="roleMsg.limitSaveInfoBo.monthOrder" placeholder="请输入" :maxlength="4">
              <template #suffix>
                元/月
              </template>
            </el-input>
            <span class="remark">每月一号重置</span>
          </el-form-item>
          <el-form-item label="最低折扣" prop="discount">
            <el-input v-model="roleMsg.limitSaveInfoBo.discount" placeholder="请输入" style="width:60%">
              <template #suffix>
                折
              </template>
            </el-input>
          </el-form-item>
        </el-form>
      </el-form-item>

      <el-form-item label="备注">
        <el-input
          v-model="roleMsg.comment"
          type="textarea"
          placeholder="请填写备注"
          style="width:75%"
          maxlength="200"
          show-word-limit
        />
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" :disabled="!isEdit" style="width:100px">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="backtrack" style="width:100px">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, onMounted, watchEffect } from 'vue'
import API from '../../../service/api'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    roleMsg: Object,
    isSelectMenus: Array,
    isSelectMenusNew: Array,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })
    const defaultProps = {
      value: 'id',
      label: 'menuName',
      children: 'childrens',
    }
    const defaultPropsNew = {
      value: 'code',
      label: 'name',
      children: 'children',
      parentId: 'parentCode',
    }

    const xzgRuleAuthCodes = ref([])
    const originXzgRuleAuthCodes = ref([])

    const flattenTree = (tree, flattened = []) => {
      tree.forEach(node => {
        flattened.push(node)
        if (node.childrens) {
          flattenTree(node.childrens, flattened)
        }
      })
      return flattened
    }

    let xzgAuth = props?.roleMsg?.authMenusNodes?.find(a => a.menuName == '星掌柜') ?? []
    let authCodeArr = []
    flattenTree(xzgAuth?.childrens ?? [], authCodeArr)
    authCodeArr = authCodeArr.map(a => a.menuCode)
    authCodeArr.push(xzgAuth.menuCode)
    xzgRuleAuthCodes.value.push(...authCodeArr)
    originXzgRuleAuthCodes.value.push(...(props?.roleMsg?.authMenus?.map(a => a.menuCode) || []))

    //获取两个数组的差集
    const getChangeAuthRuleCode = (newAuths, oldAuths) => {
      const difference1 = newAuths.filter(element => !oldAuths.includes(element))
      const difference2 = oldAuths.filter(element => !newAuths.includes(element))
      return [].concat(difference1, difference2)
    }

    const isChangeXzgAuth = () => {
      let changeAuth = getChangeAuthRuleCode(
        originXzgRuleAuthCodes.value,
        props?.roleMsg?.menuCodes?.map(a => a.menuCode),
      )
      return changeAuth.some(a => {
        if (xzgRuleAuthCodes.value.includes(a)) {
          return true
        }
        return false
      })
    }

    onMounted(() => {
      searchMenu()
      getResourceAllTree()
    })

    const tree = ref(null)
    const treeNew = ref(null)
    const loading = ref(false)
    const elForm = ref(null)
    const menus = ref(null)
    const menusNew = ref(null)
    const authElForm = ref(null)

    const validateNumber = (_, value, callback) => {
      if (!value) {
        callback()
      } else if (!/^\d+(\.\d+)?$/.test(value)) {
        callback(new Error('请输入数字'))
      } else {
        callback()
      }
    }

    const validateDiscount = (_, value, callback) => {
      if (!value) {
        callback()
      } else if (!/^\d+(\.\d)?$/.test(value)) {
        callback(new Error('请输入0~10，支持1位小数'))
      } else if (value > 10) {
        callback(new Error('请输入0~10，支持1位小数'))
      }
      callback()
    }

    const rules = reactive({
      roleName: [{ required: true, message: '请填写角色名称', trigger: 'blur' }],
      discount: [{ validator: validateDiscount, trigger: 'change' }],
      monthDuration: [{ validator: validateNumber, trigger: 'change' }],
      monthOrder: [{ validator: validateNumber, trigger: 'change' }],
      oneDuration: [{ validator: validateNumber, trigger: 'change' }],
      oneLimit: [{ validator: validateNumber, trigger: 'change' }],
      productLimit: [{ validator: validateNumber, trigger: 'change' }],
    })

    const searchMenu = () => {
      API.getAuthMenuPage(null).then(res => {
        if (res.code === '200') {
          menus.value = res.data || []
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const getResourceAllTree = () => {
      API.getResourceAllTree(null).then(res => {
        if (res.code === '200') {
          menusNew.value = res.data || []
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const add = async data => {
      let res = await API.saveRole(data).finally(() => {
        loading.value = false
      })
      if (res.code === '200') {
        let resNew = await API.resourceRoleUpdate({
          master: res.data,
          relations: treeNew.value.getCheckedKeys(),
        })
        if (resNew.code == 200) {
          ElMessage.success('添加成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      } else {
        ElMessage.error(res.msg)
      }
    }

    const update = async data => {
      let res = await API.updateRole(data).finally(() => (loading.value = false))
      if (res.code == 200) {
        let resNew = await API.resourceRoleUpdate({
          master: data.roleCode,
          relations: treeNew.value.getCheckedKeys(),
        })
        if (resNew.code == 200) {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(resNew.msg)
        }
      } else {
        ElMessage.error(res.msg)
      }
    }
    const onSubmit = async () => {
      try {
        await elForm.value.validate()
        await authElForm.value.validate()
        props.roleMsg.limitSaveInfoBo.roleCode = props.roleMsg.roleCode
        props.roleMsg.menuCodes = tree.value.getCheckedKeys().map(item => ({ menuCode: item }))
        const halfCheckedKeys = tree.value.getHalfCheckedKeys()
        halfCheckedKeys.forEach(item => {
          props.roleMsg.menuCodes.push({ menuCode: item })
        })
        loading.value = true
        if (Reflect.has(props.roleMsg, 'id')) {
          props.roleMsg.isXZGAuthTag = isChangeXzgAuth() ? 1 : 0
          update(props.roleMsg)
        } else {
          add(props.roleMsg)
        }
      } catch (e) {
        console.log(e)
        ElMessage.info('请填写完成必要信息')
      }
    }
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      onSubmit,
      backtrack,
      defaultProps,
      visible,
      tree,
      rules,
      elForm,
      authElForm,
      loading,
      handleSubmit,
      menus,
      defaultPropsNew,
      menusNew,
      treeNew,
    }
  },
})
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
.quota-auth {
  flex-direction: column;
  ::v-deep(.el-form) {
    padding-left: 100px;

    .el-input {
      width: 60%;
    }
    .remark {
      margin-left: 10px;
      color: #ccc;
    }
    .el-form-item__label {
      width: 150px !important;
    }
  }
}
</style>
