<template>
  <div class="contanier">
    <el-form
      :model="merchantform"
      label-width="5vw"
      ref="combineform"
      label-position="center"
      :rules="rules"
      :inline="true"
    >
      <h2 class="titleborder">图片信息</h2>
      <div>
        <div class="uploadimg_contanier">
          <el-upload
            list-type="picture"
            :headers="headerObj"
            class="avatar-uploader"
            :action="baseUrl + '/adminChain/goods/material/upload'"
            :show-file-list="false"
            :on-success="handleSkuPic"
          >
            <img v-if="merchantform.pic" :src="merchantform.pic" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
          <div style="line-height:30px">封面图</div>
        </div>
        <div class="uploadimg_contanier">
          <el-upload
            class="avatar-uploader"
            :headers="headerObj"
            :action="baseUrl + '/adminChain/goods/material/upload'"
            :show-file-list="false"
            :on-success="handleDetailPic"
          >
            <img v-if="merchantform.picDetail" :src="merchantform.picDetail" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
          <div style="line-height:30px">详情图</div>
        </div>
        <div class="uploadimg_contanier">
          <el-upload
            class="avatar-uploader"
            :headers="headerObj"
            :action="baseUrl + '/adminChain/goods/material/upload'"
            :show-file-list="false"
            :on-success="handleAdsPic"
          >
            <img v-if="merchantform.picAds" :src="merchantform.picAds" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
          <div style="line-height:30px">广告图</div>
        </div>
      </div>
      <h2 class="titleborder" style="margin-top: 15px"><span>基本信息</span></h2>
      <el-form-item prop="name" label="商品名称">
        <el-input :style="{ width: formitemwidth }" v-model="merchantform.name" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item prop="price" label="指导价">
        <el-input-number
          :min="0"
          :style="{ width: formitemwidth }"
          v-model="merchantform.price"
          placeholder="请输入商品指导价"
        >
        </el-input-number>
      </el-form-item>

      <el-form-item prop="categoryCode1" label="品类">
        <el-select v-model="merchantform.categoryCode1" :style="{ width: formitemwidth }" placeholder="请选择品类">
          <el-option v-for="item in categries" :key="item.code" :label="item.name" :value="item.code"> </el-option>
        </el-select>
      </el-form-item>
      <h2 class="titleborder">
        <div>
          <span>必选商品</span>
          <el-button class="btnmanu" @click="addnewcombine" type="primary">
            <el-icon style="vertical-align: middle">
              <Plus />
            </el-icon>
            <span style="font-size: 12px; vertical-align: middle"> 新增 </span>
          </el-button>
        </div>
      </h2>
      <el-form-item>
        <div v-for="(requireitem, index) in merchantform.skus" :key="index" class="bxmerchant">
          <span>{{ requireitem.name }}</span>
          <span>
            <span>
              <el-input-number :min="1" v-model="requireitem.count" size="small" />
            </span>
            <el-icon
              @click="deleterequre(index)"
              :size="15"
              color="red"
              style="position: relative; top: 3px; margin-left: 10px"
            >
              <Delete />
            </el-icon>
          </span>
        </div>
      </el-form-item>

      <h2 class="titleborder">组合描述</h2>
      <el-form-item>
        <el-input
          resize="none"
          v-model="merchantform.description"
          style="width: 35vw"
          type="textarea"
          :rows="4"
          placeholder="请输入套餐描述"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <h2 class="titleborder"><span>套餐配置</span></h2>
    <div class="merchantcheck">
      <el-checkbox v-model="merchantform.isPresent">是否支持商家赠送</el-checkbox>
      <el-checkbox v-model="merchantform.isMinConsume">是否计入低销</el-checkbox>
    </div>
    <el-dialog v-model="tcchoose_dialogVisible" title="新增可选方案" top="5vh" width="30%">
      <el-form>
        <el-form-item prop="tcform.name" label="名称">
          <el-input v-model="tcform.name" placeholder="请输入套餐名称" />
        </el-form-item>
        <el-form-item prop="tcform.num" label="数量">
          <el-input-number :min="1" v-model="tcform.num" size="small" />
        </el-form-item>
        <el-form-item prop="tcform.type" label="类型">
          <el-radio-group v-model="tcform.type">
            <el-radio :label="1">数量</el-radio>
            <el-radio :label="2">方案</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>

      <template #footer>
        <span class="dialog-footer">
          <el-button @click="tcchoose_dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="confirmchoose">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog v-model="dialogVisible" title="选择商品" top="5vh" width="72%">
      <el-input
        @input="searchprds"
        style="margin: 20px 0px 20px 0px; width: 200px"
        v-model="prdparam.keyword"
        clearable
        placeholder="请输入商品名称进行搜索"
      ></el-input>
      <el-select @change="searchprds" v-model="prdparam.categoryCode" style="margin-left:10px" placeholder="请选择类别">
        <el-option key="" label="全部" value="" />
        <el-option v-for="item in prdtypes" :key="item.code" :label="item.name" :value="item.code" />
      </el-select>
      <div
        v-infinite-scroll="loadmerchant"
        v-loading="loading"
        class="infinite-list"
        style="overflow: auto; height: 60vh"
      >
        <div v-for="(prd, index) in products" :key="index" class="merchant_contanier">
          <el-image lazy fit="fill" style="width: 60px; height: 60px;border-radius:5px" :src="prd.pic" />
          <div class="merchantinfo">
            <div style="font-size:14px;font-weight:bold;margin-bottom:10px;">{{ prd.name }}</div>
            <div class="merchantinfo_check">
              <span>售卖单位:{{ prd.unit }}</span>
              <el-checkbox v-model="prd.checked" @change="selectprd(prd)" label="是否选择" size="large" />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="cancel">取消</el-button>
          <el-button type="primary" @click="confirm">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <div class="operate">
      <el-button @click="cancelSubmit" style="width: 150px">取消</el-button>
      <el-button style="width: 150px; margin-left: 20px" type="primary" @click="onSubmit">保存</el-button>
    </div>
  </div>
</template>

<script>
import { CircleCloseFilled, Edit, Check, Message, Star, Delete } from '@element-plus/icons-vue'
import XAPI from '../service/merchant.js'
import { ElMessage } from 'element-plus'
import Config from '@/config'
export default {
  name: 'merchant',
  components: { CircleCloseFilled },
  data() {
    return {
      dialogVisible: false,
      tcchoose_dialogVisible: false,
      props: { multiple: true },
      dialogVisible: false,
      loading: false,
      headerObj: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      },
      baseUrl: Config.baseURL || '',
      count: 0,
      tcform: {
        name: '',
        type: '',
        num: 1,
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品指导价',
            trigger: 'blur',
          },
        ],
        categoryCode1: [
          {
            required: true,
            message: '请选择商品所属品类',
            trigger: 'blur',
          },
        ],
      },

      merchantform: {
        categoryCode1: '',
        description: '',
        isMinConsume: 0,
        isPresent: 0,
        isRecommend: 0,
        name: '',
        skus: [],
        pic: '',
        picAds: '',
        picDetail: '',
        price: 0,
        reducedPrice: 0,
        retailPrice: 0,
        storeCode: '',
      },
      formitemwidth: '12vw',
      prdtypes: [],
      inputVisible: false,
      inputValue: '',
      categries: [],
      products: [],
      selectprds: [],
      currentIndex: -1,
      type: 1, //1.必选商品 2.可选商品
      prdparam: {
        keyword: '',
        categoryCode: '',
        pageNum: 0,
        pageSize: 20,
        status: 1,
        storeCode: 'K0000',
      },
    }
  },
  props: {
    imgsuploads: {
      type: Array,
      default: [],
    },
    skucode: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.getCategroyList()
  },
  methods: {
    resetFields() {
      this.getCategroyList()
      this.merchantform = {
        categoryCode1: '',
        description: '',
        isMinConsume: 0,
        isPresent: 0,
        isRecommend: 0,
        name: '',
        skus: [],
        pic: '',
        picAds: '',
        picDetail: '',
        price: 0,
        reducedPrice: 0,
        retailPrice: 0,
        storeCode: '',
      }
    },
    getTcinfo() {
      XAPI.getSkuArray({
        skuArrayCode: this.skucode,
        storeCode: 'K0000',
      }).then(res => {
        if (res.code == '200') {
          this.merchantform = res.data
          this.merchantform.isMinConsume = this.merchantform.isMinConsume == 1 ? true : false
          this.merchantform.isPresent = this.merchantform.isPresent == 1 ? true : false
        }
      })
    },
    onSubmit() {
      this.merchantform.isMinConsume = this.merchantform.isMinConsume ? 1 : 0
      this.merchantform.isPresent = this.merchantform.isPresent ? 1 : 0
      if (this.merchantform.skus.length == 0) {
        ElMessage({
          message: '请选择必选品',
          type: 'warning',
        })
        return
      }
      this.$refs['combineform'].validate(valid => {
        if (valid) {
          if (this.skucode) {
            XAPI.updateSkuArray(this.merchantform).then(res => {
              if (res.code == '200') {
                this.resetFields()
                this.$emit('close')
              }
            })
          } else {
            XAPI.addSkuArray(this.merchantform).then(res => {
              if (res.code == '200') {
                this.resetFields()
                this.$emit('close')
              }
            })
          }
        }
      })
    },
    showtcplan() {
      this.tcform = {
        name: '',
        type: '',
        num: 1,
      }
      this.tcchoose_dialogVisible = true
    },
    handleSkuPic(file) {
      if (file.code == '200') {
        this.merchantform.pic = file.data.url
      }
    },
    handleAdsPic(file) {
      if (file.code == '200') {
        this.merchantform.picAds = file.data.url
      }
    },
    handleDetailPic(file) {
      if (file.code == '200') {
        this.merchantform.picDetail = file.data.url
      }
    },
    //添加可选方案
    confirmchoose() {
      this.merchantform.optionContents.push({
        name: this.tcform.name,
        selectCount: this.tcform.num,
        type: this.tcform.type,
        products: [],
      })
      this.tcchoose_dialogVisible = false
    },
    confirm(index) {
      this.dialogVisible = false
      this.selectprds.forEach(item => {
        this.merchantform.skus.push({
          code: item.code,
          name: item.name,
          count: 1,
          productType: item.productType,
        })
      })
    },
    //必选商品删除指定商品
    deleterequre(index) {
      this.$confirm('删除该商品吗?', {
        title: '提示',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.merchantform.skus.splice(index, 1)
      })
    },
    cancel() {
      this.dialogVisible = false
      this.selectprds = []
    },
    selectprd(prd) {
      if (prd.checked) {
        this.selectprds.push(prd)
      } else {
        this.selectprds.splice(
          this.selectprds.findIndex(item => item.code == prd.code),
          1,
        )
      }
    },
    getCategroyList() {
      XAPI.getCategoryList(1).then(res => {
        if (res.code == '200') {
          this.categries = res.data
        }
      })
    },
    getmerchants() {
      this.loading = true
      XAPI.getSkuListLimit(this.prdparam).then(res => {
        this.loading = false
        if (res.code == '200') {
          if (res.data.records.length > 0) {
            res.data.records.map(item => {
              item.checked = false
              return item
            })
            this.products = this.products.concat(res.data.records)
          }
        }
      })
    },
    addnewcombine(index) {
      this.dialogVisible = true
      this.products = []
      this.selectprds = []
      this.currentIndex = index

      this.prdparam.pageNum = 0
      this.loadmerchant()
      this.getCategories()
    },
    searchprds() {
      this.products = []
      this.prdparam.pageNum = 0
      this.getmerchants()
    },
    getCategories() {
      XAPI.getCategoryList(2).then(res => {
        if (res.code == '200') {
          this.prdtypes = res.data
        }
      })
    },
    loadmerchant() {
      this.prdparam.pageNum++
      this.getmerchants()
    },
    cancelSubmit() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
.contanier {
  margin: 25px 10px;
  height: calc(100vh - 150px);
  overflow-y: auto;

  .uploadimg_contanier {
    text-align: center;
    display: inline-block;
    width: 200px;
  }

  .avatar-uploader {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-color: #a1baff;

      .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        height: 138px;
        width: 138px;
        line-height: 138px;
        text-align: center;
      }
    }
  }

  .merchant_contanier {
    width: 400px;
    height: 100px;
    display: inline-block;
    margin: 5px;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);

    .merchantinfo {
      display: inline-block;
      height: 60px;
      position: relative;
      width: 300px;
      top: -20px;
      left: 5px;

      .merchantinfo_check {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .operate {
    text-align: center;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }

  .tcdz {
    font-size: 20px;
    color: #5384ff;
    font-weight: bold;
  }

  .bxmerchant {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    width: 35vw;
    padding: 5px 0px;
  }

  .bxmerchant2 {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #dedede;
    width: calc(35vw - 20px);
    padding: 5px 0px;
  }

  .tcxztitle {
    margin-left: 10px;
    color: #1989fa;
  }

  .closebtn {
    position: absolute;
    top: 0px;
    right: 0px;
  }

  .combine_merchant {
    display: inline-block;
    margin-left: 1.5vw;
    margin-bottom: 10px;
    width: 11vw;
  }

  .newcombine_contanier {
    width: 200px;

    .avatar-uploader {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      width: 120px;
      height: 120px;
      text-align: center;
      margin-top: 70px;
      margin-left: 40px;
      border-color: #a1baff;

      .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        line-height: 120px;
        text-align: center;
      }
    }
  }

  ::v-deep .el-textarea__inner {
    border-color: #a1baff;
  }

  .avatar {
    width: 158px;
    height: 158px;
    display: block;
  }

  .merchantcheck {
    margin-left: 1.5vw;
    display: flex;
    flex-wrap: nowrap;
  }

  .inputjh {
    display: inline-block;
    width: 400px;
    margin-left: 30px;
  }

  .tag {
    cursor: pointer;
  }

  ::v-deep .el-tag {
    margin-right: 10px;
  }

  h2 {
    padding: 5px 10px;
    margin-bottom: 15px;
  }

  .titleborder {
    border-left: 5px solid #5384ff;
    position: relative;

    .btnmanu {
      position: absolute;
      right: 20px;
      top: -5px;
    }
  }
}
</style>
