<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-13 14:42:21 1
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-09-01 19:33:29
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/infoDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="supply-infoDetail-box">
    <GoBack />
    <div class="content-box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane v-for="(item, index) in tabsList" :label="item" :name="item">
          <div v-if="item === '基本资料'" class="base-info-content">
            <div class="base-info-title">
              <h1>
                基本资料
                <el-button type="primary" class="amend-btn" @click="editInfo">修改资料</el-button>
              </h1>
            </div>
            <ul class="base-info-ul">
              <li><span>供应商名称：</span>{{ baseInfo.supplierName }}</li>
              <li><span>联系人：</span>{{ baseInfo.contacts }}</li>
              <li><span>手机号：</span>{{ baseInfo.cellphone }}</li>
              <li>
                <span>状态：</span>
                <el-switch
                  v-model="statusSwitch"
                  :active-text="statusSwitch ? '启用' : '禁用'"
                  :class="statusSwitch ? 'isOpen' : ''"
                  @change="changeSwitch"
                />
              </li>
              <li><span>是否合规：</span>{{ baseInfo.isCompliance === 0 ? '不合规' : '合规' }}</li>
            </ul>
            <div class="account-info-content">
              <h1>
                银行账户信息
                <a @click="addAccount(1)">添加收款账号</a>
              </h1>
              <el-table :data="baseInfo.bankAccountList" style="width: 100%" class="el-table-header-gap">
                <el-table-column prop="paymentAccountType" label="账户类型">
                  <template #default="scope">
                    <div>
                      {{ scope.row.paymentAccountType === 1 ? '公户' : '私户' }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="bankname" label="开户行" />
                <el-table-column prop="accountname" label="开户名称" />
                <el-table-column prop="address" label="开户行地址" />
                <el-table-column prop="account" label="卡号" />
                <el-table-column prop="createTime" label="添加时间" />
                <el-table-column prop="remark" label="备注" />
                <el-table-column label="操作">
                  <template #default="scope">
                    <el-button type="text" @click="editAccount(scope.$index, scope.row, 2)">编辑</el-button>
                    <el-button type="text" class="delete-style" @click="deleteAccount(scope.$index, scope.row)"
                      >删除
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="img-info-content">
              <h1>证件图片</h1>
              <ul>
                <li v-for="item in baseInfo.certificatePics">
                  <el-image class="el-image" :src="item.src" :preview-src-list="imgfilter(baseInfo.certificatePics)" />
                  <p>{{ item.name }}</p>
                </li>
              </ul>
            </div>
            <p class="remark-item">备注：{{ baseInfo.remark }}</p>
          </div>
          <SupplyShop v-if="item === '供应门店'" :code="$route.query.id" :activeName="activeName" />
          <SupplyCategory v-if="item === '供应品类'" :code="$route.query.id" :supplierName="$route.query.name" />
          <SupplyCommodity v-if="item === '供应商品'" :code="$route.query.id" :supplierName="$route.query.name" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <el-drawer
    v-model="infoModal"
    title="苏州信投店-A02/小包"
    size="35%"
    :direction="direction"
    :with-header="false"
    :before-close="handleClose"
  >
    <div class="edit-modal">
      <h1>
        修改资料<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="infoModal = false">
          <close />
        </el-icon>
      </h1>
      <div class="info-line"></div>
      <AddSupplier
        :isModal="true"
        ref="editSupplier"
        :baseInfo="baseInfo"
        :bankInfo="bankInfo"
        :certificatePics="certificatePicsList"
        :code="$route.query.id"
      />
    </div>
  </el-drawer>
  <elDrawerVue ref="accountRef" title="添加收款账号">
    <el-form
      :model="form"
      :rules="rules"
      label-width="80px"
      ref="ruleFormRef"
      class="form-content"
      label-position="left"
      :hide-required-asterisk="true"
    >
      <el-form-item label="账户类型">
        <el-radio-group v-model="form.paymentAccountType" class="account-info">
          <el-radio :label="1">公户</el-radio>
          <el-radio :label="2">私户</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item prop="accountname" label="开户名称">
        <el-input v-model="form.accountname" placeholder="请输入开户名称" />
      </el-form-item>
      <el-form-item prop="bankname" label="开户银行">
        <el-input v-model="form.bankname" placeholder="请输入开户银行" />
      </el-form-item>
      <el-form-item label="开户行地址" prop="address">
        <el-input v-model="form.address" placeholder="请输入开户行地址" />
      </el-form-item>
      <el-form-item label="卡号" prop="account">
        <el-input v-model="form.account" placeholder="请输入卡号" />
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="form.remark" />
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="addAccount" class="confirm-accountBtn">确认</el-button>
  </elDrawerVue>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import GoBack from '../../../../component/base/backHeader/goBack.vue'
import SupplyShop from './supplyShop.vue'
import AddSupplier from './addSupplierLeft.vue'
import SupplyCategory from './supplyCategory.vue'
import SupplyCommodity from './supplyCommodity.vue'
import elDrawerVue from '../../../../component/base/el-Drawer/el-drawer.vue'
import api from '../../service/index'
export default {
  components: { GoBack, AddSupplier, SupplyShop, SupplyCategory, SupplyCommodity, elDrawerVue },
  data() {
    return {
      tabsList: ['基本资料', '供应门店', '供应品类', '供应商品'],
      activeName: '基本资料',
      statusSwitch: false,
      infoModal: false,
      baseInfo: {},
      bankInfo: {},
      certificatePicsList: [],
      form: {
        account: '',
        accountname: '',
        address: '',
        bankname: '',
        paymentAccountType: 1,
        remark: '',
        supplierCode: '',
      },
      rules: {
        accountname: [
          {
            required: true,
            message: '请输入开户名称',
            trigger: 'blur',
          },
        ],
        bankname: [
          {
            required: true,
            message: '请输入开户银行',
            trigger: 'blur',
          },
        ],
        address: [
          {
            required: true,
            message: '请输入开户行地址',
            trigger: 'blur',
          },
        ],
        account: [
          {
            required: true,
            message: '请输入卡号',
            trigger: 'blur',
          },
        ],
      },
      isEditNum: null,
    }
  },
  mounted() {
    this.getBaseInfo()
    let activeName = localStorage.getItem('activeName') || '基本资料'
    this.activeName = activeName
  },
  methods: {
    editInfo() {
      this.infoModal = true
    },
    handleClick(tab, event) {
      this.activeName = tab.props.name
      localStorage.setItem('activeName', this.activeName)
    },
    async getBaseInfo() {
      const baseInfo = await api.supplierGetOne({ code: this.$route.query.id })
      this.baseInfo = baseInfo.data
      this.bankInfo = baseInfo.data.accountInfo
      this.statusSwitch = this.baseInfo.state === 10 ? true : false
      this.supplierName = baseInfo.data.supplierName
    },
    imgfilter(imgobj) {
      let imgs = []
      imgobj.forEach(obj => {
        imgs.push(obj.src)
      })
      return imgs
    },
    changeSwitch(val) {
      let param = {
        code: this.$route.query.id,
        state: this.statusSwitch ? 10 : 0,
      }
      api.updateSupplier(param).then(res => {
        if (res.code === '200') {
          ElMessage({
            type: 'success',
            message: `修改成功`,
          })
        }
      })
    },
    editAccount(index, row, val) {
      this.$refs.accountRef.detailModal = true
      this.form = { ...row }
    },
    addAccount() {
      this.$refs.accountRef.detailModal = true
      this.form.supplierCode = this.$route.query.id
      this.$refs['ruleFormRef'].validate(vaild => {
        if (vaild) {
          if (this.form.code) {
            api.updateBankAccount(this.form).then(res => {
              if (res.code === '200') {
                ElMessage.success('修改成功')
                this.$refs.accountRef.detailModal = false
                this.getBaseInfo()
              }
            })
          } else {
            api.addBankAccount(this.form).then(res => {
              if (res.code === '200') {
                ElMessage.success('新增成功')
                this.getBaseInfo()
                this.$refs.accountRef.detailModal = false
              }
            })
          }
        } else {
          return false
        }
      })
    },
    deleteAccount(index, item) {
      api.deleteBankAccount(item.code).then(res => {
        ElMessage.success('删除成功')
        this.getBaseInfo()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.form-content {
  width: 400px;
}

.confirm-accountBtn {
  width: 121px;
  height: 40px;
  background: #1989fa;
  border-radius: 4px;
  position: fixed;
  right: 40px;
  bottom: 40px;
}

.supply-infoDetail-box {
  .content-box {
    width: 1568px;
    height: 872px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px;
    padding: 27px 40px;
    margin: auto;

    .base-info-content {
      ::v-deep .el-switch__label {
        span {
          margin-left: 10px;
        }
      }
      .delete-style {
        color: #ff5959;
      }
      .isOpen {
        ::v-deep .el-switch__label {
          span {
            color: #60c220;
          }
        }
      }

      ::v-deep .el-switch.is-checked .el-switch__core .el-switch__action {
        left: 65% !important;
      }

      .base-info-title {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        height: 60px;
        line-height: 60px;

        .amend-btn {
          float: right;
        }
      }

      .info-ul,
      .base-info-ul {
        display: flex;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #f5f7fa;
        padding-bottom: 32px;

        li {
          flex: 1;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;

          span:first-child {
            margin-right: 20px;
          }
        }
      }

      h1 {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        height: 60px;
        line-height: 60px;

        a {
          float: right;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1989fa;
        }
      }

      .account-info-content {
        ul {
          display: flex;
          height: 40px;
          line-height: 40px;

          li {
            flex: 1;
            font-size: 16px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;

            span {
              margin-right: 20px;
            }
          }
        }
      }

      .img-info-content {
        height: 400px;

        ul {
          li {
            display: inline-block;
            width: 180px;
            height: 180px;
            border-radius: 8px;
            background-color: red;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            margin-right: 60px;

            .el-image {
              width: 180px;
              height: 180px;
              border-radius: 8px;
              margin-bottom: 12px;
            }
          }
        }
      }
    }

    .remark-item {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }
}

.edit-modal {
  padding: 0px 40px;

  h1 {
    height: 73px;
    line-height: 73px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    position: relative;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 0px;
      cursor: pointer;
    }
  }

  .info-line {
    width: 588px;
    height: 1px;
    background-color: #ebeef5;
    margin-bottom: 25px;
  }
}
</style>
