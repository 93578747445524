<template>
  <el-dialog v-model="visible" width="60%" :before-close="handleClose">
    <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 10px 0  10px;">
      <el-form-item label="商品名称">
        <el-input
          clearable
          placeholder="请输入商品名称"
          v-model="data.productName"
          class="input-search-tel"
          @change="selectBelowProductSkuArray({ currentPage: 1 })"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-select
          clearable
          v-model="data.categoryCode"
          class="search-select"
          placeholder="选择商品类型"
          @change="selectBelowProductSkuArray({ currentPage: 1 })"
          style="width: 200px;"
        >
          <el-option v-for="item in data.selectLists" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="selectBelowProductSkuArray({ currentPage: 1 })">查询</el-button>
        <el-button @click="backtrack">取消</el-button>
        <el-button @click="onsubmit" type="primary">确定</el-button>
      </el-form-item>
    </el-form>

    <el-empty
      description="暂无数据"
      :image-size="100"
      v-if="data.skuListsSelect.length === 0 && !data.productName && !data.categoryCode"
    />
    <div
      v-if="data.skuListsSelect.length !== 0 || data.productName || data.categoryCode"
      style="display: flex; flex-wrap: wrap; max-height: 500px; overflow-y: auto;"
    >
      <el-table :data="data.skuListsSelect" style="width: 100%" ref="elTable">
        <!-- <el-table-column align="center" width="50">
          <template #header>
            <el-checkbox v-model="data.checkAll" @change="handleCheckAllChange"></el-checkbox>
          </template>
          <template #default="{row}">
            <el-checkbox v-model="row.checked" @change="handleCheckAllOne(row)" />
          </template>
        </el-table-column> -->
        <el-table-column label="序号" align="center" type="index" :index="indexfn" />
        <el-table-column label="商品图片" prop="pic" width="100" align="center">
          <template #default="scope">
            <div style="display: flex; justify-content: center;">
              <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="商品名称" align="center" />
        <el-table-column prop="spec" label="规格" />
        <el-table-column prop="price" label="指导价(元)" />
        <el-table-column prop="unit" label="最小单位" />
        <el-table-column prop="productTypeName" label="商品类型" />
        <el-table-column label="数量" align="center" width="200">
          <template #default="scope">
            <el-button v-if="!scope.row.count || scope.row.count == 0" @click="addPro(scope.row)">
              <i class="el-icon-plus"></i
            ></el-button>
            <el-input-number v-else v-model="scope.row.count" :min="0" :max="1000" @change="changeCount(scope.row)" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
      <el-pagination
        v-if="data.skuListsSelect.length !== 0"
        v-model:current-page="data.currentPage"
        v-model:page-size="data.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="data.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, toRaw, onMounted, watchEffect } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    type: Number,
    hasPros: Array,
  },
  setup(props, ctx) {
    const data = reactive({
      checkAll: false,
      categoryCode: undefined,
      productName: undefined,
      currentPage: 1,
      pageSize: 5,
      total: 100,
      selectLists: [],
      skuLists: [],
      skuListsSelect: [],
    })

    const hasSelectSkuList = computed(() => {
      return props.hasPros
    })
    const visible = computed(() => {
      return props.dialogVisible
    })
    const elTable = ref(null)

    const indexfn = computed(() => {
      return (data.currentPage - 1) * data.pageSize + 1
    })

    onMounted(() => {
      selectBelowProductSkuArray({})
      selectListByType()
    })

    watchEffect(() => {
      let start = (data.currentPage - 1) * data.pageSize
      let end = data.currentPage * data.pageSize

      let newArr = data.skuLists.slice(start, end)
      data.skuListsSelect = [...newArr]
    })

    //缓存历史选过的商品
    const cacheOldPro = () => {
      let hasSelect = data.skuLists.filter(item => item.count && item.count > 0)
      if (hasSelect.length > 10) {
        ElMessage.warning('最多支持10种商品')
        return false
      }
      hasSelect.forEach(item => {
        let a = hasSelectSkuList.value.find(b => b.code == item.code)
        if (a) {
          a.count = item.count
        } else {
          hasSelectSkuList.value.push(item)
        }
      })
      return true
    }

    const selectBelowProductSkuArray = ({ currentPage }) => {
      if (currentPage) {
        data.currentPage = currentPage
      }
      let params = {
        categoryCode: data.categoryCode,
        productName: data.productName,
        range: 1,
      }
      cacheOldPro()

      API.selectBelowProductCombo(params).then(res => {
        if (res.code === '200') {
          res.data.forEach(a => {
            let b = hasSelectSkuList.value.find(b => b.code == a.code)
            if (b) {
              a.count = b.count
            } else {
              a.count = 0
            }
          })
          data.skuLists = res.data
          data.total = res.data.length
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const selectListByType = () => {
      API.selectBelow({ type: 1, range: 1 }).then(res => {
        if (res.code === '200') {
          data.selectLists = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const onsubmit = () => {
      if (cacheOldPro()) {
        ElMessage.success('商品已入清单')
        handleClose()
      }
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', {
        update: false,
        selectSkus: hasSelectSkuList.value.filter(item => item.count && item.count > 0),
        isUpdate: true,
      })
    }
    const handleSizeChange = val => {
      data.pageSize = val
    }
    const handleCurrentChange = val => {
      data.currentPage = val
    }

    const addPro = row => {
      row.count = 1
    }

    const changeCount = row => {
      if (row.count == 0) {
        let isHasProIndex = hasSelectSkuList.value.findIndex(item => item.code == row.code)
        if (isHasProIndex != -1) {
          hasSelectSkuList.value.splice(isHasProIndex, 1)
        }
      }
    }
    return {
      changeCount,
      addPro,
      handleSizeChange,
      handleCurrentChange,
      selectBelowProductSkuArray,
      data,
      handleClose,
      backtrack,
      visible,
      onsubmit,
      indexfn,
      elTable,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-input-number {
  width: 100% !important;
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
</style>
