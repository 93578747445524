<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-07-16 17:17:03
 * @FilePath: /saas-pc/src/plugin/push-money/view/articleMoney.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportPaymentOrderDay" reportName="日支付账单"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportPaymentOrderDay"
      :emptyText="'暂无数据'"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../component/base/searchbar/index.vue'
import Table from '../component/table.vue'
import api from '../service/api'
import { downLoad } from '../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodes: [],
        billCode: '',
        deliveryName: '',
        openCode: '',
        orderCode: '',
        salesName: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'storeCode',
          label: '门店编号',
          width: '100',
        },
        {
          prop: 'storeName',
          label: '门店名称',
          width: '200',
        },
        {
          prop: 'receivableAmount',
          label: '应收',
          width: '100',
        },
        {
          prop: 'discountAmount',
          label: '优惠',
          width: '100',
        },
        {
          prop: 'wipeZeroAmount',
          label: '抹零',
          width: '100',
        },
        {
          prop: 'actualAmount',
          label: '实收',
          width: '100',
        },
        {
          prop: 'netReceiptsAmount',
          label: '净收',
          width: '100',
        },
        {
          prop: 'goodsCode',
          label: '商品订单号',
          width: '200',
        },
        {
          prop: 'billCode',
          label: '账单号',
          width: '200',
        },
        {
          prop: 'openCode',
          label: '场次号',
          width: '200',
        },
        {
          prop: 'orderCreatedTime',
          label: '订单生成时间',
          width: '200',
        },
        {
          prop: 'payTime',
          label: '支付时间',
          width: '200',
        },

        {
          prop: 'openName',
          label: '开台人',
        },
        {
          prop: 'salesperson',
          label: '销售员',
        },
        {
          prop: 'salesPhone',
          label: '销售员电话',
          width: '150',
        },
        {
          prop: 'goodsRate',
          label: '商品销售提成比例',
          width: '200',
          formatter: val => {
            return val.storeCode != '合计' ? val.goodsRate + '%' : ''
          },
        },
        {
          prop: 'goodsAmount',
          label: '商品销售提成金额',
          width: '200',
          // formatter: val => {
          //   return val.storeCode != '合计' ? val.goodsAmount : ''
          // },
        },
        {
          prop: 'deliveryName',
          label: '送达人',
          formatter: val => {
            return !val.deliveryRate ? '' : val.deliveryName
          },
        },
        // {
        //   prop: 'deliveryPhone',
        //   label: '送达人电话',
        //   width: '150',
        // },
        {
          prop: 'deliveryRate',
          label: '商品送达提成比例',
          width: '200',
          formatter: val => {
            return val.storeCode != '合计' ? (!val.deliveryRate ? '' : val.deliveryRate + '%') : ''
          },
        },
        {
          prop: 'deliveryAmount',
          label: '商品送达提成金额',
          width: '200',
          formatter: val => {
            return val.storeCode != '合计' ? (!val.deliveryRate ? '' : val.deliveryAmount) : val.deliveryAmount
          },
        },
      ],
      searchform: [
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodes',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '场次号',
          type: 'input',
          labelWidth: '120px',
          prop: 'openCode',
          placeholder: '请输入场次号',
        },
        {
          label: '账单号',
          type: 'input',
          labelWidth: '120px',
          prop: 'billCode',
          placeholder: '请输入账单号',
        },
        {
          label: '订单号',
          type: 'input',
          labelWidth: '120px',
          prop: 'orderCode',
          placeholder: '请输入订单号',
        },
        {
          label: '销售员',
          type: 'input',
          labelWidth: '120px',
          prop: 'salesName',
          placeholder: '请输入销售员',
        },
        {
          label: '送达人',
          type: 'input',
          labelWidth: '120px',
          prop: 'deliveryName',
          placeholder: '请输入送达人',
        },
        { label: '导出', type: 'exportButton', url: '/report/commission/goods/export', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportPaymentOrderDay(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[1].options = res.data
      })
      api
        .selectByType({
          pageSize: 100,
          current: 1,
          type: 1,
        })
        .then(res => {
          this.searchform[3].options = res.data.records.map(item => {
            return {
              value: item.code,
              label: item.name,
            }
          })
        })
    },
    reportPaymentOrderDay(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return
      }
      this.$refs.search.fullscreenLoading = true
      api
        .commissonGoods(model)
        .then(res => {
          this.tableData = res.data.records
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    parentSearch(param) {
      const { billCode, deliveryName, openCode, orderCode, salesName, storeCodes, regionCodeList } = param
      this.model = { billCode, deliveryName, openCode, orderCode, salesName, storeCodes, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      this.reportPaymentOrderDay(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }

      const { billCode, deliveryName, openCode, orderCode, salesName, storeCodes, regionCodeList } = param
      this.model = { billCode, deliveryName, openCode, orderCode, salesName, storeCodes, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      if (!this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '商品提成.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
