<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-06-24 12:00:05
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/billReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <!-- <tableSearchTab :model="model" :getTableData="reportBillDetail" reportName="账单明细" /> -->
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="'暂无数据'"
      :getTableData="reportBillDetail"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
// import tableSearchTab from '../../component/tableSearchTab.vue'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        roomCode: '',
        comboCodeName: '',
        type: '',
        billCode: '',
        openCode: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'comboCodeName',
          label: '消费项目',
          width: '180px',
        },
        // {
        //   prop: 'code',
        //   label: '单号',
        //   width: '180px',

        // },
        {
          prop: 'billCode',
          label: '账单号',
          width: '200px',
        },
        {
          prop: 'openCode',
          label: '场次号',
          width: '200px',
        },
        {
          prop: 'roomCode',
          label: '包厢',
        },
        {
          prop: 'payPrice',
          label: '应收',
          width: '140px',
        },
        {
          prop: 'discountAmount',
          label: '优惠',
          width: '140px',
        },
        {
          prop: 'wipeZeroAmount',
          label: '抹零',
          width: '140px',
        },
        {
          prop: 'actualFee',
          label: '实收',
          width: '140px',
        },
        {
          prop: 'netIncomeFee',
          label: '净收',
          width: '140px',
        },
        {
          prop: 'nickName',
          label: '会员姓名',
          width: '130px',
        },
        {
          prop: 'telephone',
          label: '手机号',
          width: '180px',
        },
        {
          prop: 'type',
          label: '订单类型（包厢/商品）',
          width: '200px',
        },
        {
          prop: 'createTime',
          label: '下单时间',
          width: '180px',
        },
        {
          prop: 'paymentTime',
          label: '结账时间',
          width: '180px',
        },
        {
          prop: 'openStatus',
          label: '开台状态',
          width: '130px',
        },
        {
          prop: 'openStartTime',
          label: '开台时间',
          width: '180px',
        },
        {
          prop: 'openEndTime',
          label: '关台时间',
          width: '180px',
        },
        {
          prop: 'alipay',
          label: '支付宝',
        },
        {
          prop: 'wechat',
          label: '微信',
        },

        {
          prop: 'principalFee',
          label: '会员卡本金',
          width: '130px',
        },
        {
          prop: 'giftFee',
          label: '会员卡赠金',
          width: '130px',
        },
        // {
        //   prop: 'hangBill',
        //   label: '挂账',
        // },
        {
          prop: 'cash',
          label: '现金',
        },
        {
          prop: 'meituan',
          label: '美团',
        },
        {
          prop: 'leishi',
          label: '雷石',
        },
        {
          prop: 'stoneCoupon',
          label: '雷石优惠券',
          width: '130px',
        },
        {
          prop: 'dianping',
          label: '大众点评',
          width: '130px',
        },
        {
          prop: 'freeHospitality',
          label: '招待免收',
          width: '130px',
        },
        {
          prop: 'kj',
          label: '可即会员',
          width: '130px',
        },
        {
          prop: 'newCoupon',
          label: '新系统优惠券',
          width: '130px',
        },
        // {
        //   prop: 'vipCard',
        //   label: '储值卡',
        // },
        {
          prop: 'tiktok',
          label: '抖音',
        },
        {
          prop: 'teamHospitality',
          label: '利通取酒',
          width: '130px',
        },
        {
          prop: 'posCard',
          label: 'pos刷卡',
          width: '140px',
        },
        {
          prop: 'pos',
          label: 'POS',
          width: '140px',
        },
        // {
        //   prop: 'prepaid',
        //   label: '预付费',
        // },
        // {
        //   prop: 'combe',
        //   label: '组合支付',
        // },
        {
          prop: 'leiShiPrincipalFee',
          label: '利通会员本金',
          width: '130px',
        },
        {
          prop: 'leiShiGiftFee',
          label: '利通会员赠金',
          width: '130px',
        },
        {
          prop: 'hangBill',
          label: '内部挂账',
          width: '130px',
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '包厢',
          type: 'input',
          prop: 'roomCode',
          typeProp: 'select',
          placeholder: '请输入包厢',
          labelWidth: '50px',
        },
        { label: '账单号', type: 'input', prop: 'billCode', placeholder: '请输入账单号' },
        { label: '场次号', type: 'input', prop: 'openCode', placeholder: '请输入场次号' },
        { label: '消费项目', type: 'input', prop: 'comboCodeName', placeholder: '请输入消费项目' },
        {
          label: '订单类型',
          type: 'select',
          typeProp: 'type',
          children: 'option',
          placeholder: '请选择订单类型',
          prop: 'type',
          options: [
            { label: '包厢', value: '包厢' },
            { label: '超市', value: '超市' },
          ],
        },
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/export/reportBillDetail', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportBillDetail(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })

      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
    },
    reportBillDetail(model) {
      this.$refs.search.fullscreenLoading = true
      api
        .reportBillDetail(model)
        .then(res => {
          this.tableData = res?.data?.records || 0
          this.totalRecords = res?.data?.totalRecords || []
          this.totalPage = res?.data?.totalPage || 0
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    parentSearch(param) {
      const { type, roomCode, comboCodeName, storeCodeList, billCode, openCode, regionCodeList } = param
      this.model = { type, roomCode, comboCodeName, storeCodeList, billCode, openCode, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.reportBillDetail(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { type, roomCode, comboCodeName, storeCodeList, billCode, openCode, regionCodeList } = param
      this.model = { type, roomCode, comboCodeName, storeCodeList, billCode, openCode, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '账单明细.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
