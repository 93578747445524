<!--
 * @Author: your name
 * @Date: 2022-05-07 01:14:20
 * @LastEditTime: 2022-08-30 16:02:25
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/addSupplier.vue
-->
<template>
  <div class="addSupplier-content-box">
    <GoBack />
    <div class="content-box">
      <div v-if="!firstStep">
        <BindingGoods
          :categoryListWithMatterList="categoryListWithMatterList"
          :supplierCode="supplierCode"
          ref="bindGoods"
          :supplierName="supplierName"
          bindType="add"
        />
      </div>
      <div v-else>
        <el-form :model="form" label-width="80px" class="form-content" label-position="left">
          <el-row>
            <el-col :span="10" class="row-left">
              <AddSupplier ref="addSupplierForm" @validateForm="validateForm" />
            </el-col>
            <el-col :span="14" class="row-right">
              <span class="form-title">绑定品类</span>
              <el-checkbox-group v-model="form.type" class="beverages-list" @change="handleCategory">
                <el-checkbox v-for="item in categoryList" :label="item.name" :key="item.code" name="type" />
              </el-checkbox-group>
              <span class="form-title">品类确认</span>
              <div v-for="(item, index) in checkBoxCategory">
                <el-tag class="mx-1" closable type="" @close="deleteTag(item)">
                  {{ item.name }}
                </el-tag>
                <el-row>
                  <el-col :span="6">
                    <el-radio-group v-model="item.taxState" class="tax-point-list">
                      <el-radio :label="1">含税</el-radio>
                      <el-radio :label="0">不含税</el-radio>
                    </el-radio-group>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item label="税点" label-width="50px">
                      <el-input-number v-model="item.taxPoint" :precision="0" :max="20" :min="0" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
              <el-button type="primary" @click="onSubmit" class="addSupplier-btn">确认，下一步</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- <el-button type="primary" class="confirm-edit" v-if="!firstStep">确认修改</el-button> -->
    </div>
  </div>
</template>
<script>
import GoBack from '../../../../component/base/backHeader/goBack.vue'
import AddSupplier from './addSupplierLeft.vue'
import BindingGoods from './bindingGoods.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../service/index'
import _ from 'lodash'
export default {
  components: { GoBack, AddSupplier, BindingGoods },
  data() {
    return {
      activeName: 'first',
      checkBeer: '',
      tags: [],
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      categoryList: [],
      checkBoxCategory: [],
      firstStep: true,
      categoryListWithMatterList: [],
      supplierCode: '',
      supplierName: '',
    }
  },
  mounted() {
    this.getCategoryList()
    // api.getCategoryListWithMatterList({ supplierCode: '1532191019573682178' }).then(res => {
    //     this.categoryListWithMatterList = res.data
    //       })
  },
  methods: {
    handleClick(tab, event) {},
    getCategoryList() {
      api.getCategoryList().then(res => {
        this.categoryList = res.data
      })
    },
    handleCategory(categories) {
      let categries = []
      categories.forEach(category => {
        categries.push(this.categoryList.filter(item => item.name === category)[0])
      })
      this.checkBoxCategory = categries
      this.checkBoxCategory.map(item => {
        item.taxPoint = item.taxPoint || ''
        item.taxState = item.taxState || 0
        item.categoryCode1 = item.code
        return item
      })
    },
    deleteTag(item) {
      // this.checkBoxCategory.splice(this.checkBoxCategory.filter(categroy => categroy.name === item.name)[0], 1)
      // this.form.type.splice(this.form.type.filter(formtye => formtye === item.name)[0], 1)

      const spliceIndex = this.checkBoxCategory.findIndex(categroy => categroy.name === item.name)
      this.checkBoxCategory.splice(spliceIndex, 1)
      this.form.type.splice(spliceIndex, 1)
    },
    onSubmit() {
      this.$refs.addSupplierForm.submitForm()
      this.validateForm()
    },
    async validateForm(form, certificateDictList) {
      if (form) {
        if (this.checkBoxCategory.length !== 0) {
          let categoryList = []
          let obj = {}
          this.checkBoxCategory.map(item => {
            obj.categoryCode1 = item.code
            obj.taxPoint = item.taxPoint
            obj.taxState = item.taxState
            categoryList.push(obj)
          })
        }
        let param = {
          accountInfo: {
            account: form.account,
            accountname: form.accountname,
            address: form.address,
            bankname: form.bankname,
          },
          categoryList: this.checkBoxCategory,
          cellphone: form.cellphone,
          certificatePics: certificateDictList,
          contacts: form.contacts,
          freight: 0,
          miniPrice: 0,
          paymentAccountType: form.paymentAccountType,
          remark: form.remark,
          supplierName: form.supplierName,
          supplierShortName: form.supplierShortName,
        }
        const res = await api.addSupplier(param)
        if (res.code === '200') {
          this.firstStep = false
          this.supplierCode = res.data.code
          this.supplierName = res.data.supplierName
          api.getCategoryListWithMatterList({ supplierCode: this.supplierCode }).then(val => {
            this.categoryListWithMatterList = val.data
          })
          // this.$router.go(-1)
        } else {
          ElMessage({
            type: 'error',
            message: res.msg,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.addSupplier-content-box {
  .content-box {
    width: 1568px;
    height: 875px;
    overflow: auto;
    background: #ffffff;
    border-radius: 8px;
    margin: auto;
    box-sizing: border-box;
    padding: 24px 40px 24px 40px;
    position: relative;

    .form-content {
      position: relative;

      ::v-deep .el-form-item {
        margin-bottom: 0px !important;
      }

      .addSupplier-btn {
        position: fixed;
        right: 110px;
        bottom: 50px;
      }

      .row-left {
        padding-right: 100px;
        border-right: 1px solid #ebeef5;

        .account-info {
          margin-bottom: 20px;
        }

        .certificate-imgList {
          margin-bottom: 20px;
        }

        .certificate-photo {
          margin-bottom: 6px;

          span {
            font-size: 16px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #222222;
          }
        }

        .certificate-hint {
          display: block;
          font-size: 12px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #ff4242;
          margin-bottom: 20px;
        }
      }

      .row-right {
        padding-left: 100px;

        .tax-point-list {
          line-height: 52px !important;
        }

        .beverages-list {
          margin-bottom: 20px;

          .el-checkbox {
            margin-bottom: 10px;
          }
        }
      }

      .form-title {
        display: block;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        margin-bottom: 20px;
      }

      .avatar-uploader .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }

      .avatar-uploader .el-upload {
        border: 1px dashed var(--el-border-color);
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        transition: var(--el-transition-duration-fast);
      }

      .avatar-uploader .el-upload:hover {
        border-color: var(--el-color-primary);
      }

      .el-icon.avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        text-align: center;
      }
    }

    .confirm-edit {
      position: absolute;
      right: 40px;
      bottom: 27px;
    }
  }
}
</style>
