/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-10-22 09:58:21
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/stage-config.js
 */

const linCmsUiRouter = {
  route: null,
  name: null,
  title: '财务管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/finance/',
  route: '/finance-reconciliation',
  order: null,
  inNav: true,
  children: [
    {
      title: '商品销售统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/goodsSellReport',
      filePath: 'plugin/finance-reconciliation/view/report/goodsSellReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/goodsSalesStatistics/goodsSellReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '营收分析表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/revenueAnalyse',
      filePath: 'plugin/finance-reconciliation/view/report/revenue-analyse.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/revenueAnalyse/revenueAnalyse`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '门店利润表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/costOfRevenue',
      filePath: 'plugin/finance-reconciliation/view/report/costOfRevenue.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/storeProfitStatement/revenueReceipts`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '商品收入明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/goodsIncomeReport',
      filePath: 'plugin/finance-reconciliation/view/report/goodsIncomeReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/merchandiseRevenueDetail/goodsIncomeReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '优惠赠送',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/special-offer',
      filePath: 'plugin/finance-reconciliation/view/report/special-offer.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '营业日报',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/daily-business',
      filePath: 'plugin/finance-reconciliation/view/report/daily-business.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    // {
    //   title: '营收分析表',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/finance-reconciliation/revenue-analyse',
    //   filePath: 'plugin/finance-reconciliation/view/report_module/revenue-analyse.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true,
    //   },
    // },
    {
      title: '场次收入明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/detailsOfSessionRevenue',
      filePath: 'plugin/finance-reconciliation/view/report_module/detailsOfSessionRevenue.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/detailsOfSessionRevenue`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '优惠券报表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/couponsReport',
      filePath: 'plugin/finance-reconciliation/view/report/couponsReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/couponsReport/couponsUsageDetails`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '包厢收入明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/roomIncomeReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/roomIncomeReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/roomIncomeReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '账单明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/billReportV2',
      filePath: 'plugin/finance-reconciliation/view/report_module/billReportV2.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/billReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '消费流水结算明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/consumptionSettlementAccountDetails',
      filePath: 'plugin/finance-reconciliation/view/report_module/consumptionSettlementAccountDetails.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '储值流水结算明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/storedSettlementAccountDetails',
      filePath: 'plugin/finance-reconciliation/view/report_module/storedSettlementAccountDetails.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '结算统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/settlementStatistics',
      filePath: 'plugin/finance-reconciliation/view/report_module/settlementStatistics.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '办卡储值明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/storedCardReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/storedCardReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '办卡储值统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/storedCardStatisticsReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/storedCardStatisticsReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '会员报表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/customerCardReport',
      filePath: 'plugin/finance-reconciliation/view/report/customerReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/memberReport/storedCardReport`,
      meta: {
        keepAlive: true,
      },
    },

    {
      title: '存取酒报表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/storageWineReport',
      filePath: 'plugin/finance-reconciliation/view/report/storageWineReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },

    {
      title: '进销存报表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/getMaterialStockTraceReport',
      filePath: 'plugin/finance-reconciliation/view/report/getMaterialStockTraceReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/getMaterialStockTraceReport/getMaterialStockTraceReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '日支付账单',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/dailyBill',
      filePath: 'plugin/finance-reconciliation/view/report/dailyBill.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/dailyBill/paymentOrderDayReport`,
      meta: {
        keepAlive: true,
      },
    },
    // {
    //   title: '消费日支付账单',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/finance-reconciliation/paymentOrderDayReport',
    //   filePath: 'plugin/finance-reconciliation/view/report_module/paymentOrderDayReport.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true,
    //   },
    // },
    {
      title: '月支付账单',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/getBillMonthReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/getBillMonthReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/getBillMonthReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '门店营业月报',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/monthlyStoreBusReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/monthlyStoreBusReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '门店营业日报',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/dayStoreBusReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/dayStoreBusReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '指标剔除日志',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/IndicatorCullingLog',
      filePath: 'plugin/finance-reconciliation/view/report_module/IndicatorCullingLog.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '供应商采购明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/providerOrderDetail',
      filePath: 'plugin/finance-reconciliation/view/report_module/provider-orderdetail.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },

    {
      title: '供应商采购统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/providerOrderplan',
      filePath: 'plugin/finance-reconciliation/view/report_module/provider-orderplan.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },

    {
      title: '商品盘点明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/goodsInventoryDetail',
      filePath: 'plugin/finance-reconciliation/view/report_module/goodsInventoryDetail.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    // {
    //   title: '储值日支付账单',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/finance-reconciliation/storedValuePaymentJournal',
    //   filePath: 'plugin/finance-reconciliation/view/report_module/storedValuePaymentJournal.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true,
    //   },
    // },
    // {
    //   title: '预订单日支付账单',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/finance-reconciliation/reverseOrderDayReport',
    //   filePath: 'plugin/finance-reconciliation/view/report_module/reverseOrderDayReport.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true,
    //   },
    // },
    {
      title: '大区营业月报',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/monthlyAreaBusReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/monthlyAreaBusReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/monthlyBusReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '大区营业日报',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/dayAreaBusReport',
      filePath: 'plugin/finance-reconciliation/view/report_module/dayAreaBusReport.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/dayBusReport`,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '美大验券记录',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/finance-reconciliation/americanSecRecords',
      filePath: 'plugin/finance-reconciliation/view/report_module/americanSecRecords.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      isLink: `${process.env.VUE_APP_ADMIN_URL}/finance/americanSecRecords`,
      meta: {
        keepAlive: true,
      },
    },
  ],
}

export default linCmsUiRouter
