<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-10-26 11:45:42
 * @FilePath: /saas-pc/src/plugin/points-manager/view/pointChangeDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="emptyTableText"
      :getTableData="reportBillDetail"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../component/base/searchbar/index.vue'
import Table from '../component/table.vue'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        changeTimeBegin: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        changeTimeEnd: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        telephone: '',
        relationType: '',
        relationValue: '',
        itemNo: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'userName',
          label: '会员姓名',
        },
        {
          prop: 'telephone',
          label: '会员手机号',
        },
        {
          prop: 'points',
          label: '变动积分',
        },
        {
          prop: 'relationType',
          label: '变动原因',
        },
        {
          prop: 'relationValue',
          label: '关联流水',
          width: '160px',
        },
        {
          prop: 'itemNo',
          label: '兑换商品id',
        },
        {
          prop: 'itemName',
          label: '兑换商品名称',
        },
        {
          prop: 'changeTime',
          label: '变动时间',
        },
      ],
      searchform: [
        { label: '会员手机号', type: 'input', prop: 'telephone', placeholder: '请输入会员手机号' },

        {
          label: '变动原因',
          children: 'option',
          type: 'select',
          prop: 'relationType',
          typeProp: 'select',
          placeholder: '请选择变动原因',
          labelWidth: '50px',
          options: [
            { label: '充值', value: 1 },
            { label: '充值退款', value: 2 },
            { value: 3, label: '消费' },
            { value: 4, label: '消费退款' },
            { value: 5, label: '积分商城兑换' },
          ],
        },
        { label: '关联流水', type: 'input', prop: 'relationValue', placeholder: '请输入关联流水' },
        { label: '兑换商品id', type: 'input', prop: 'itemNo', placeholder: '请输入兑换商品id' },
        {
          label: '变动时间',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
  },
  methods: {
    reportBillDetail(model) {
      this.mergeTotalData(model.pageNum, '积分变动明细', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!model.billCode && !model.openCode) {
        if (!this.getDays(model.changeTimeBegin, model.changeTimeEnd)) {
          return Promise.reject('条件不满足')
        }
      }
    },
    parentSearch(param) {
      const { telephone, relationType, relationValue, itemNo } = param
      this.model = { telephone, relationType, relationValue, itemNo }
      this.model.changeTimeBegin = param.time ? param.time[0] : ''
      this.model.changeTimeEnd = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '积分变动明细', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { telephone, relationType, relationValue, itemNo } = param
      this.model = { telephone, relationType, relationValue, itemNo }
      this.model.changeTimeBegin = param.time ? param.time[0] : ''
      this.model.changeTimeEnd = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10

      if (!this.model.billCode && !this.model.openCode) {
        if (!this.getDays(this.model.changeTimeBegin, this.model.changeTimeEnd)) {
          this.$refs.search.fullscreenLoading = false
          return
        }
      }

      this.exportData('积分变动明细', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
