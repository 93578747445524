<!--
 * @Author: your name
 * @Date: 2022-04-21 19:41:11
 * @LastEditTime: 2024-06-26 12:44:29
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/component/base/searchbar/index.vue
-->
<template>
  <div class="searchheader" v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form :inline="true" ref="formRef" label-width="80px" class="demo-ruleForm" label-position="left">
      <el-form-item
        v-for="(vi, i) of searchform"
        :key="i"
        label-position="left"
        :label="vi.type !== 'exportButton' ? vi.label || '' : ''"
        :prop="vi.prop"
        :inline="true"
        :label-width="vi.labelWidth"
      >
        <el-select-v2
          v-if="vi.type == 'select-v2'"
          v-model="ruleForm[vi.prop]"
          :options="vi.options"
          :placeholder="vi.placeholder"
          filterable
          clearable
          collapse-tags
          collapse-tags-tooltip
          class="search-select userSelect"
          style="width: 300px;"
          :multiple="vi.multiple"
        />
        <el-cascader
          ref="casRef"
          v-if="vi.type == 'cascader'"
          v-model="ruleForm[vi.prop]"
          :options="vi.options"
          filterable
          clearable
          :placeholder="vi.placeholder"
          @change="changeArea(vi.prop)"
          :show-all-levels="false"
          collapse-tags
          collapse-tags-tooltip
          :props="vi.props"
        ></el-cascader>
        <component
          v-if="
            vi.type !== 'radio-group' &&
              vi.type &&
              vi.type !== 'exportButton' &&
              vi.type !== 'cascader' &&
              vi.type !== 'select-v2'
          "
          :is="`el-${vi.type}`"
          :placeholder="vi.placeholder"
          :type="vi.typeProp || 'text'"
          :multiple="vi.multiple"
          :size="vi.size"
          collapse-tags
          :style="vi.style"
          :autosize="{ minRows: 3, maxRows: 5 }"
          :clearable="!vi.unclearable"
          :active-text="vi.activeText"
          :props="vi.props || {}"
          :inactive-text="vi.inactiveText"
          :options="vi.options"
          :disabled="vi.disabled"
          :maxlength="vi.maxlength"
          :minlength="vi.minlength"
          :max="vi.max"
          :min="vi.min"
          :is-range="vi.isRange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          :format="vi.dataFormate"
          :value-format="vi.dataFormate"
          :show-password="vi.showPassword"
          :show-word-limit="vi.showWordLimit"
          :active-value="vi.activeValue"
          :default-value="vi.defaultValue"
          :filterable="vi.filterable"
          :inactive-value="vi.inactiveValue"
          v-model="ruleForm[vi.prop]"
          :default-time="defaultTime"
        >
          <template v-if="vi.children">
            <component
              :is="`el-${vi.children}`"
              v-for="(item, index) of vi.options"
              :key="`${i}_${ind}_${index}`"
              :value="item.value"
              :label="item.label"
            >
            </component>
          </template>
        </component>
        <el-radio-group v-if="vi.type === 'radio-group'" v-model="ruleForm[vi.prop]">
          <template v-for="(item, index) of vi.options">
            <component
              :is="`el-${vi.children}`"
              v-if="vi.children"
              :key="`${i}_${ind}_${index}`"
              :value="item.value"
              :label="item.label"
            >
            </component>
          </template>
        </el-radio-group>

        <StoreFilter v-if="vi.type === 'storeFilter'" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" v-if="authMenus.query">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="" @click="resetForm">重置</el-button>
      </el-form-item>
      <el-form-item>
        <el-button
          @click="exportReport($event)"
          v-if="
            searchform.find(item => item.type == 'exportButton') &&
              authMenus[searchform.find(item => item.type == 'exportButton').auth]
          "
          >{{ searchform.find(item => item.type == 'exportButton').label }}</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import StoreFilter from '@/component/base/store-filter'
import { reactive } from '@vue/reactivity'
import { ref, nextTick, toRaw, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import clearSelect from '@/lin/util/clearSelect2.js'

export default {
  props: {
    authMenus: {
      type: Object,
      default: {},
    },
    searchform: {
      type: Array,
      default: [],
    },
    isShowToast: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    StoreFilter,
  },
  watch: {
    ruleForm() {
      this.$emit('childSearch', this.ruleForm)
    },
  },
  setup(props, { emit }) {
    const defaultTime = new Date(2000, 1, 1, 8, 0, 0) // '12:00:00'
    let fullscreenLoading = ref(false)
    let ruleForm = reactive({})
    watchEffect(() => {
      props.searchform?.map(item => {
        if (item.type) {
          if (item.modelValue) {
            ruleForm[item.prop] = item.modelValue
          }
          if (item.fetch && typeof item.fetch === 'function') {
            item.options = item.fetch()
          }
        }
      })
    })
    const formRef = ref(null)
    const onSubmit = () => {
      const data = {
        ...ruleForm,
        regionCodeList: ruleForm['regionCodeList']?.map(item => item[item.length - 1]) || [],
      }
      emit('childSearch', data)
    }
    const resetForm = () => {
      nextTick(() => {
        formRef?.value.resetFields()
        props.searchform?.map(item => {
          if (item.type) {
            if (item.modelValue) {
              ruleForm[item.prop] = item.modelValue
            } else {
              // if (item.prop == 'regionCodeList') {
              //   ruleForm[item.prop] = []
              // } else {
              //   ruleForm[item.prop] = ''
              // }
              delete ruleForm[item.prop]
            }
            if (item.fetch && typeof item.fetch === 'function') {
              item.options = item.fetch()
            }
          }
        })
        nextTick(() => {
          clearSelect('userSelect')
        })
        emit('childSearch', ruleForm)
        emit('changeArea', [])
      })
    }

    const exportReport = event => {
      fullscreenLoading.value = true
      const data = {
        ...ruleForm,
        regionCodeList: ruleForm['regionCodeList']?.map(item => item[item.length - 1]) || [],
      }
      emit('exportReport', data, event)
    }

    const casRef = ref()
    const changeArea = prop => {
      ruleForm.storeCodeList = []
      ruleForm.storeCodes = []
      nextTick(() => {
        clearSelect('userSelect')
      })
      emit(
        'changeArea',
        ruleForm[prop].map(item => item[item.length - 1]),
      )
    }

    return {
      ruleForm,
      onSubmit,
      resetForm,
      formRef,
      exportReport,
      fullscreenLoading,
      defaultTime,
      changeArea,
      casRef,
    }
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-form-item__content {
  display: flex;
}

::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}

::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}

::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.searchheader {
  box-sizing: border-box;
  padding-top: 20px;
  // height: 65px;
  // line-height: 65px;
  width: 100%;
  display: block;

  ::v-deep .el-form-item {
    margin-bottom: 0 !important;
  }
}
</style>
