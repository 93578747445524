/*
 * @Author: your name
 * @Date: 2022-04-21 19:41:11
 * @LastEditTime: 2022-08-29 09:58:42
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/supplier-manager/stage-config.js
 */
const linCmsUiRouter = {
  // route: null,
  // name: null,
  // title: '供应链管理',
  // type: 'folder',
  // icon: 'iconfont icon-jiemiansheji',
  // filePath: 'view/stock/',
  // order: null,
  // inNav: true,
  // children: [
  //   {
  //     title: '供应商信息',
  //     type: 'view',
  //     name: 'ImgsUploadDemo',
  //     route: '/supplyChain-manager/supplierManagement',
  //     filePath: 'plugin/supplyChain-manager/view/supplier-management/index.vue',
  //     inNav: true,
  //     icon: 'iconfont icon-upload',
  //     permission: null,
  //     meta: {
  //       keepAlive: true,
  //     },
  //     children: [
  //       {
  //         title: '新增供应商',
  //         type: 'view',
  //         name: 'supplyChainManager',
  //         route: '/supplyChain-manager/supplierManagement/addSupplier',
  //         filePath: 'plugin/supplyChain-manager/view/supplier-management/addSupplier.vue',
  //         inNav: false,
  //         icon: 'iconfont icon-upload',
  //         permission: null,
  //       },
  //       {
  //         title: '供应商品',
  //         type: 'view',
  //         name: 'supplyChainManager',
  //         route: '/supplyChain-manager/supplierManagement/modifyGoods',
  //         filePath: 'plugin/supplyChain-manager/view/supplier-management/modifyGoods.vue',
  //         inNav: false,
  //         icon: 'iconfont icon-upload',
  //         permission: null,
  //       },
  //       // {
  //       //   title: '新增供应商',
  //       //   type: 'view',
  //       //   name: 'supplyChainManager',
  //       //   route: '/supplyChain-manager/supplierManagement/addSupplier',
  //       //   filePath: 'plugin/supplyChain-manager/view/supplier-management/a.vue',
  //       //   inNav: false,
  //       //   icon: 'iconfont icon-upload',
  //       //   permission: null,
  //       // },
  //       {
  //         title: '绑定门店',
  //         type: 'view',
  //         name: 'supplyChainManagerbindingStore',
  //         route: '/supplyChain-manager/supplierManagement/bindingStore',
  //         filePath: 'plugin/supplyChain-manager/view/supplier-management/bindingStore.vue',
  //         inNav: false,
  //         icon: 'iconfont icon-upload',
  //         permission: null,
  //       },
  //       {
  //         title: '{:name} 详情',
  //         type: 'view',
  //         name: 'supplyChainManagerInfoDetail',
  //         route: '/supplyChain-manager/supplierManagement/infoDetail',
  //         filePath: 'plugin/supplyChain-manager/view/supplier-management/infoDetail.vue',
  //         inNav: false,
  //         icon: 'iconfont icon-upload',
  //         permission: null,
  //       },
  //     ],
  //   },
  //   {
  //     title: '供应商采购明细',
  //     type: 'view',
  //     name: 'ImgsUploadDemo',
  //     route: '/supplyChain-manager/purchase-details',
  //     filePath: 'plugin/supplyChain-manager/view/purchase-details/index.vue',
  //     inNav: true,
  //     icon: 'iconfont icon-upload',
  //     permission: null,
  //     meta: {
  //       keepAlive: false,
  //     },
  //   },
  //   {
  //     title: '供应商账单',
  //     type: 'view',
  //     name: 'ImgsUploadDemo',
  //     route: '/supplyChain-manager/supplier-bill',
  //     filePath: 'plugin/supplyChain-manager/view/supplier-bill/index.vue',
  //     inNav: true,
  //     icon: 'iconfont icon-upload',
  //     permission: null,
  //     meta: {
  //       keepAlive: false,
  //     },
  //   },
  //   // {
  //   //   title: '订单管理',
  //   //   type: 'view',
  //   //   name: 'ImgsUploadDemo',
  //   //   route: '/stock-manager/realkddb-manager',
  //   //   filePath: 'plugin/stock-manager/view/table/table.vue',
  //   //   inNav: true,
  //   //   icon: 'iconfont icon-upload',
  //   //   permission: null,
  //   // },
  // ],
}

export default linCmsUiRouter
