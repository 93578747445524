<template>
  <el-dialog v-model="visible" title="用户管理" width="50%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="userMsg"
      label-width="120px"
      ref="elform"
      :rules="rules"
      v-loading="loading"
      element-loading-text="正在火速更新数据,请勿关闭。。。"
    >
      <el-form-item label="姓名" placeholder="请填写姓名" prop="userName">
        <el-input v-model="userMsg.userName" maxlength="30" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="userMsg.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="工号" placeholder="请填写工号">
        <el-input v-model="userMsg.staffId" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="手机号" placeholder="请填写手机号" prop="cellPhone">
        <el-input v-model="userMsg.cellPhone" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="登陆密码" placeholder="请填写登陆密码" prop="myPassWord" v-if="!isAdd()">
        <el-input v-model="userMsg.myPassWord" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="邮箱地址" placeholder="请填写邮箱地址">
        <el-input v-model="userMsg.email" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="所属组织" prop="orgs">
        <el-cascader
          :options="orgs"
          :props="defaultProp"
          clearable
          v-model="userMsg.orgs"
          style="width:70%"
          size="small"
          tag-type="success"
          collapse-tags
          filterable
        />
      </el-form-item>
      <el-form-item label="备注:" style="color:red">
        <p>管理门店决定用户能查看哪些门店的数据，请务必准确分配</p>
      </el-form-item>
      <el-form-item label="管理门店" prop="stores">
        <el-tree
          ref="tree"
          :data="stores"
          show-checkbox
          node-key="code"
          :default-checked-keys="userMsg.stores"
          :props="storeProp"
          @check-change="checkChange"
        >
          <template #default="{ node, data }">
            <span class="custom-tree-node">
              <span>{{ node.label }}</span>
              <span v-if="data.isMatch">
                <a style="position: absolute; right: 10px;">
                  <el-switch v-model="data.isRegional" @change="handleSwitch(node, data)" /><span
                    style="margin-left: 20px;"
                    >区域负责</span
                  >
                </a>
              </span>
            </span>
          </template>
        </el-tree>
      </el-form-item>
      <el-form-item label="所属角色" prop="roleCode">
        <el-select-v2
          v-model="userMsg.roleCode"
          :options="roles"
          placeholder="请选择角色"
          style="width:70%"
          multiple
          clearable
          tag-type="success"
          filterable
        />
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="userMsg.state">
          <el-radio :label="10">启用</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" :disabled="!isEdit" style="width:100px">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="handleClose" style="width:100px">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { checkphone } from '@/validator/index.js'
import { reactive, defineComponent, computed, ref, onMounted, nextTick } from 'vue'
import API from '../../../service/api'
import _ from 'lodash'
import { ElMessage } from 'element-plus'
import dayjs from 'dayjs'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    userMsg: Object,
    roles: Array,
    stores: Array,
    orgs: Array,
  },
  setup(props, ctx) {
    const form = reactive({
      options: [
        {
          value: '苏州',
          label: '苏州',
          children: [
            {
              value: '苏州信投店',
              label: '苏州信投店',
            },
            {
              value: '苏州奥体店',
              label: '苏州奥体店',
            },
          ],
        },
      ],
      dynamicTags: [],
    })

    const originROles = [...props.userMsg.roleCode]
    const isChangeRoleCode = () => {
      let originStr = JSON.stringify(originROles.sort())
      let newStr = JSON.stringify(props.userMsg.roleCode.sort())
      return originStr == newStr
    }

    function traverseTree(nodes, matchKey, matchValue) {
      if (matchValue?.length < 1) {
        nodes.forEach(node => {
          node.isRegional = false
          node.isMatch = true
        })
      }
      let codes = props.userMsg.regionalList?.map(item => item.userCode == props.userMsg.code)
      if (!codes) return
      // 如果当前用户有权限，继续往下走
      nodes.forEach(node => {
        node.isMatch = true
        node.isRegional = false
        // 默认全部关闭
        matchValue?.forEach(ii => {
          if (ii.regionalCode == node[matchKey]) {
            console.log('code123', ii.regionalCode, node[matchKey])
            node.isRegional = ii.isRegional == 1 ? true : false
          }
        })
      })
    }
    // const filterData = () => {
    //   // 默认给每个大区新增一个开关
    //   let codes = props.userMsg.regionalList?.map(item => item.userCode)
    //   props.stores = props.stores.map(ii => {
    //     // 如果 list 没有值 默认关闭
    //     if(props.userMsg.regionalList?.length < 1){
    //       return Object.assign(ii, {isMatch: true, isRegional:false})
    //     }else{
    //       // 如果list 中有值，判断当前userCode是否在list中，不在默认关
    //       if(codes?.includes(props.userMsg.code)){
    //         return Object.assign(ii, {isMatch: true, isRegional: ii.isRegional == 1 ? true : false})
    //       }else{
    //         return Object.assign(ii, {isMatch: true, isRegional: false})
    //       }
    //     }
    //   })
    // }
    onMounted(() => {
      nextTick(() => {
        const regionalList = props.userMsg.regionalList || []
        console.log('🚀 ~ regionalList:', regionalList)
        traverseTree(props.stores, 'code', regionalList)
      })
      // filterData()
      // 如果list 中没有，打开的时候新增一条数据进去
    })
    const loading = ref(false)
    const defaultProp = reactive({
      label: 'name',
      value: 'code',
      children: 'child',
      checkStrictly: true,
      multiple: true,
    })
    const storeProp = reactive({
      label: 'name',
      value: 'code',
      children: 'child',
      // checkStrictly: true,
      // multiple: true,
    })
    // const getLashNode = (a, b) => {
    //   if(a.children)
    // }

    const tree = ref(null)

    const checkChange = (a, b) => {
      props.userMsg.stores = tree.value.getCheckedNodes(true).map(item => ({ storeCode: item.code }))
    }

    const isAdd = () => {
      return Reflect.has(props.userMsg, 'id')
    }

    const elform = ref(null)
    const rules = reactive({
      userName: [{ required: true, message: '请填写姓名', trigger: 'change' }],
      myPassWord: [{ required: true, message: '请填写密码', trigger: 'change' }],
      roleCode: [{ required: true, message: '请选择角色', trigger: 'change' }],
      stores: [{ required: true, message: '管理门店决定用户能查看哪些门店的数据，请务必准确分配', trigger: 'change' }],
      orgs: [{ required: true, message: '请选择组织', trigger: 'change' }],
      cellPhone: [{ required: true, trigger: 'change', message: '请填写手机号' }],
    })
    const visible = computed(() => {
      return props.dialogVisible
    })

    const add = data => {
      data.passWord = data.myPassWord
      API.saveUser(data)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('添加成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const update = data => {
      API.updateUser(data)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('编辑成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const onSubmit = () => {
      validFormTop()
    }

    const validFormTop = async formTop => {
      if (!elform) return
      await elform.value.validate((valid, fields) => {
        if (valid) {
          loading.value = true
          let saveData = _.cloneDeep(props.userMsg)
          saveData.roleCodes = props.userMsg.roleCode?.map(item => ({ roleCode: item }))
          saveData.storeCodes = tree.value.getCheckedNodes(true).map(item => ({ storeCode: item.code }))
          let arr = []
          props.userMsg.orgs.forEach(item => {
            arr.push(item[item.length - 1])
          })
          saveData.orgIds = arr.join(',')
          saveData.cellPhone = saveData.cellPhone.trim()
          saveData.regionalList = [...orginList, ...regionalList]
          if (Reflect.has(saveData, 'id')) {
            saveData.isChangeRole = isChangeRoleCode() ? 0 : 1
            update(saveData)
          } else {
            add(saveData)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    let regionalList = reactive([])
    let orginList = reactive([])
    const handleSwitch = (node, data) => {
      console.log('🚀 ~ node, data:', node, data)
      orginList = _.cloneDeep(props.userMsg.regionalList) || []
      // 查找相同code的索引
      const index = regionalList.findIndex(item => item.regionalCode === data.code)
      const ii = orginList.findIndex(item => item.regionalCode === data.code)

      // 如果找到了相同的code，则先删除
      if (index !== -1) {
        regionalList.splice(index, 1)
      }
      if (ii !== -1) {
        orginList.splice(ii, 1)
      }
      // 将当前数据添加到数组中
      regionalList.push({
        regionalCode: data.code,
        userCode: props.userMsg.code || JSON.parse(localStorage.getItem('vuex')).user.userId,
        isRegional: data.isRegional ? 1 : 0,
      })
      regionalList = [...regionalList]
      console.log('🚀 ~ regionalList:', regionalList, orginList)
    }
    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      elform,
      form,
      onSubmit,
      handleClose,
      props: { multiple: true },
      visible,
      defaultProp,
      storeProp,
      rules,
      tree,
      loading,
      handleSubmit,
      isAdd,
      checkChange,
      handleSwitch,
    }
  },
})
</script>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
.no-autofill-pwd {
  ::v-deep .el-input__inner {
    -webkit-text-security: disc !important;
  }
}
</style>
