<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCode"
        :options="data.shops"
        filterable
        placeholder="请选择门店"
        class="search-select userSelect"
        collapse-tags
        style="width: 300px;"
        collapse-tags-tooltip
      />
    </el-form-item>

    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
      <el-button @click="addMigration"> 新增迁移 </el-button>
      <el-button @click="addOnesMigration"> 指定利通卡迁移 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
  >
    <el-table-column prop="storeCode" label="门店编号" align="center" />
    <el-table-column prop="storeName" label="门店名称" align="center" />
    <el-table-column prop="latestTime" label="开始迁移时间" align="center" />
    <el-table-column prop="finishTime" label="迁移完成时间" align="center" />
    <el-table-column prop="remark" label="备注" align="center" />

    <el-table-column label="操作" width="400" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="addRemark(scope.row)">添加备注</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <!-- 新增迁移 -->
  <el-dialog v-model="addMigrationVisible" width="40%" center title="新增迁移">
    <el-form :inline="true" :model="addMigrationData" style="margin-top:10px;padding: 10px 10px 0  20px;">
      <el-form-item label="门店">
        <el-select-v2
          v-model="addMigrationData.storeCode"
          :options="data.shops"
          filterable
          placeholder="请选择门店"
          class="search-select userSelect"
          collapse-tags
          style="width: 300px;"
          collapse-tags-tooltip
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addMigrationVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSureMigrator(reCalculateRef)">开始迁移</el-button>
    </span>
  </el-dialog>
  <!-- 添加备注 -->
  <el-dialog v-model="addRemarkVisible" width="40%" center title="添加备注">
    <el-form :inline="true" :model="addRemarkData" style="margin-top:10px;padding: 10px 10px 0  20px;">
      <el-form-item label="备注" style="width: 100%;">
        <el-input v-model="addRemarkData.remark" placeholder="请输入备注" style="width: 100%;" type="textarea" />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addRemarkVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSubmitRemark(reCalculateRef)">确 定</el-button>
    </span>
  </el-dialog>
  <!-- 指定利通卡迁移 -->
  <el-dialog v-model="addOnesMigrationVisible" width="40%" center title="指定利通卡迁移">
    <el-form :inline="true" :model="addOnesMigrationData" style="margin-top:10px;padding: 10px 10px 0  20px;">
      <el-form-item label="门店">
        <el-select-v2
          v-model="addOnesMigrationData.storeCode"
          :options="data.shops"
          filterable
          placeholder="请选择门店"
          class="search-select userSelect"
          collapse-tags
          style="width: 300px;"
          collapse-tags-tooltip
        />
      </el-form-item>
      <el-form-item label="卡号" style="width: 100%;">
        <el-input
          v-model="addOnesMigrationData.ltCardNos"
          placeholder="请输入卡号，多个卡号用英文逗号隔开，一次最多100个卡号"
          style="width: 100%;"
          type="textarea"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="addOnesMigrationVisible = false">取 消</el-button>
      <el-button type="primary" @click="handleSureOnesMigrator()">开始迁移</el-button>
    </span>
  </el-dialog>
</template>

<script setup>
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/index'
import { reactive, onMounted, ref, nextTick } from 'vue'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import clearSelect from '@/lin/util/clearSelect2.js'

const data = reactive({
  authMenus: {},
  productTypes: [],
  storeCode: '',
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  shops: [],
  loading: false,
  loadingText: '正在火速加载数据...',
})

const addMigrationVisible = ref(false)
const addMigrationData = reactive({
  storeCode: '',
})

const addMigration = () => {
  addMigrationVisible.value = true
}

const addOnesMigrationVisible = ref(false)
const addOnesMigrationData = reactive({
  storeCode: '',
  ltCardNos: '',
})

const addOnesMigration = () => {
  addOnesMigrationVisible.value = true
  addOnesMigrationData.ltCardNos = ''
  addOnesMigrationData.storeCode = ''
}
/**
 * @description: 开始指定迁移
 * @return {*}
 */
const handleSureOnesMigrator = () => {
  API.litongSyncUsersData({
    storeCode: addOnesMigrationData.storeCode,
    ltCardNos: Array.from(new Set(addOnesMigrationData.ltCardNos.replaceAll('，', ',').split(','))).join(','),
  }).then(res => {
    if (res.code === '200') {
      if (res.data.status == 1) {
        ElNotification({
          title: '迁移失败',
          message: `当前迁移失败的卡有：${res.data.failList.join(',')}`,
          duration: 10000,
        })
      } else {
        ElMessage.success('操作成功')
        addOnesMigrationVisible.value = false
      }
      searchLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

/**
 * @description: 开始迁移
 * @return {*}
 */
const handleSureMigrator = () => {
  API.litongSyncData({
    storeCode: addMigrationData.storeCode,
  }).then(res => {
    if (res.code === '200') {
      ElMessage.success('操作成功')
      addMigrationVisible.value = false
      searchLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const addRemarkVisible = ref(false)
const remarkRow = ref({})
const addRemarkData = reactive({
  remark: '',
})

const addRemark = data => {
  remarkRow.value = data
  addRemarkData.remark = data.remark
  addRemarkVisible.value = true
}
/**
 * @description: 添加备注
 * @return {*}
 */
const handleSubmitRemark = () => {
  API.litongRemark({
    storeCode: remarkRow.value.storeCode,
    remark: addRemarkData.remark,
  }).then(res => {
    if (res.code === '200') {
      ElMessage.success('添加成功')
      addRemarkVisible.value = false
      searchLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  getShops()
})

const getShops = () => {
  API.getStoreList({}).then(res => {
    if (res.code === '200') {
      data.shops = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const reset = () => {
  data.storeCode = []
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    storeCode: data.storeCode,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }
  API.litongPageList(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data?.records ?? []
        data.total = res.data?.totalRecords ?? 0
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

const specOperation = async (type, data, tag) => {}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
.dialog-footer {
  text-align: right;
  display: block;
}
</style>
