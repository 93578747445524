<template>
  <el-dialog v-model="dialogVisible" title="选择组合商品" top="5vh" width="70%">
    <div style="padding: 10px 0px">
      商品名:<el-input size="mini" style="width: 10vw" placeholder="请输入商品名称"></el-input> 商品分类:<el-select
        @change="loadmerchant"
        size="mini"
        v-model="categoryCode"
        placeholder="请选择商品分类"
      >
        <el-option label="全部" value=""></el-option>
        <el-option v-for="item in categories" :key="item.code" :label="item.name" :value="item.code"> </el-option>
      </el-select>
    </div>
    <div class="merchant_cotanier" style="overflow: auto; height: 65vh">
      <el-card
        v-for="(merchant, index) in merchants"
        class="merchant"
        :key="index"
        :body-style="{ padding: '0px', position: 'relative', width: '11vw' }"
      >
        <el-image fit="cover" :src="merchant.pic" lazy>
          <template #error>
            <div class="image-slot">
              <el-empty :image-size="100" description="暂无图片"></el-empty>
            </div>
          </template>
        </el-image>
        <span class="merchant_name">{{ merchant.name }}</span>
        <div class="bottom">
          <el-input-number v-model="merchant.count" size="mini" placeholder="数量"></el-input-number>
          <el-icon
            @click="selectmerchant(merchant.code)"
            style="top: 5px; margin-left: 5px"
            :color="merchant.checked ? '#FF922E' : '#d4d4d4'"
            size="20"
            ><circle-check-filled
          /></el-icon>
        </div>
      </el-card>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button>取消</el-button>
        <el-button type="primary" @click="choosemer">选择</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import MerchantAPI from '../service/merchant.js'
import { CircleCloseFilled, CircleCheckFilled } from '@element-plus/icons-vue'
export default {
  name: 'merchant_choose',
  components: { CircleCloseFilled, CircleCheckFilled },
  data() {
    return {
      dialogVisible: false,
      categories: [],
      categoryCode: '',
      merchants: [],
      choosemerchant: [],
    }
  },
  mounted() {
    this.getCategories()
    this.loadmerchant()
  },
  methods: {
    choosemer() {
      this.dialogVisible = false
      this.$emit('chosemer', this.choosemerchant)
    },
    showdialog() {
      this.dialogVisible = true
    },
    selectmerchant(code) {
      this.merchants.map(mer => {
        if (mer.code == code) {
          if (mer.checked) {
            mer.checked = false
            this.choosemerchant.forEach((item, index) => {
              if (item.code == mer.code) {
                this.choosemerchant.splice(index, 1)
                reutrn
              }
            })
          } else {
            if (!mer.count) {
              mer.count = 1
            }
            mer.checked = true
            this.choosemerchant.push(mer)
          }
          return mer
        }
      })
    },
    getCategories() {
      MerchantAPI.getCategoryList(2).then(res => {
        if (res.code == '200') {
          this.categories = res.data
        }
      })
    },
    loadmerchant() {
      MerchantAPI.getSkuList({
        categoryCode: this.categoryCode,
        storeCode: 'K0000',
      }).then(res => {
        if (res.code == '200') {
          this.merchants = res.data
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.merchant_cotanier {
  height: 60vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .merchant {
    width: 10vw;
    height: 25vh;
    margin: 10px 0 0 15px;
    text-align: center;
    .merchant_name {
      display: block;
      height: 40px;
      line-height: 20px;
      width: 10vw;
      text-align: center;
      padding-top: 10px;
      margin: 0px 0px 0px -1px;
    }
    .bottom {
      text-align: center;
      padding: 0px 5px;
      margin: 0px 0px 0px -5px;
    }
    ::v-deep .el-image__inner {
      width: 100%;
      height: 16vh;
    }
    ::v-deep .el-empty {
      width: 100%;
      height: 16vh;
    }
  }
}
</style>
