import _axios, { post, get, put } from '@/lin/plugin/axios'

class MdManager {
  async getStoreList(data) {
    const res = await post(`store/list`, data)
    return res
  }
  async addStore(data) {
    const res = await post(`store/add`, data)
    return res
  }

  async deleteStore(data) {
    const res = await post(`store/delete`, data)
    return res
  }

  async updateStore(data) {
    const res = await post('store/update', data)
    return res
  }

  async batchStoreGeoId(data) {
    const res = await post('store/batchStoreGeoId ', data)
    return res
  }

  async getStoreCommentList(data) {
    const res = await get('storeComment/getStoreCommentList', data)
    return res
  }
}

export default new MdManager()
