const checkphone = (rule, value, callback) => {
  const phoneReg = /^1[3|4|5|7|8|9][0-9]{9}$/
  if (!value) {
    return callback(new Error('电话号码不能为空'))
  }
  if (!Number.isInteger(+value)) {
    callback(new Error('请输入数字值'))
  } else {
    if (phoneReg.test(value)) {
      callback()
    } else {
      callback(new Error('电话号码格式不正确'))
    }
  }
}

const checkshopnum = (rule, value, callback) => {
  const phoneReg = /^K\d{3}$/
  if (phoneReg.test(value)) {
    callback()
  } else {
    callback(new Error('请输入正确编号格式'))
  }
}

const checknbumber = (rule, value, callback) => {
  if (value < 0) {
    return callback(new Error('不能为负数'))
  } else {
    callback()
  }
}

export { checkphone, checknbumber, checkshopnum }
