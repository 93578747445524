<template>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
    <el-form-item label="场次号">
      <el-input
        placeholder="请输入场次号"
        v-model="data.openCode"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="包厢号">
      <el-input
        placeholder="请输入包厢号"
        v-model="data.roomName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店编号">
      <el-input
        placeholder="请输入门店编号"
        v-model="data.storeCode"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-date-picker
        @change="searchLists"
        value-format="YYYY-MM-DD"
        v-model="data.time"
        type="daterange"
        range-separator="至"
        start-placeholder="开台日期"
        end-placeholder="关台日期"
      />
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column show-overflow-tooltip label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column show-overflow-tooltip label="场次号" prop="code" align="center" width="150" />
    <el-table-column show-overflow-tooltip label="门店编号" prop="storeCode" align="center" />
    <el-table-column show-overflow-tooltip label="包厢号" prop="roomName" align="center" />
    <el-table-column show-overflow-tooltip label="包厢类型" prop="roomTypeName" align="center" />
    <el-table-column show-overflow-tooltip label="开台时间" prop="startTime" align="center" />
    <el-table-column show-overflow-tooltip label="关房时间" prop="endTime" align="center" />
    <el-table-column show-overflow-tooltip label="使用时长(分钟)" prop="openTime" align="center" />
    <el-table-column show-overflow-tooltip label="包厢订单数" prop="roomOrderCount" align="center" />
    <el-table-column show-overflow-tooltip label="商品订单数" prop="goodsOrderCount" align="center" />
    <el-table-column show-overflow-tooltip label="场次金额(元)" prop="totalAmount" align="center" />
    <el-table-column show-overflow-tooltip label="优惠金额(元)" align="center">
      <template #default="scope">
        0
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="应收金额(元)" prop="totalAmount" align="center" />
    <el-table-column show-overflow-tooltip label="实收金额(元)" prop="realAmount" align="center" />
    <el-table-column show-overflow-tooltip label="消费门店" prop="storeName" align="center" />
    <el-table-column show-overflow-tooltip label="结账状态" prop="settlementStatus" align="center">
      <template #default="scope">
        <text v-if="scope.row.settlementStatus != 2"> {{ data.settlementStatusList[scope.row.settlementStatus] }}</text>
        <text v-if="scope.row.settlementStatus == 2"> 已取消</text>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="100" align="center">
      <template #default="scope" fixed="right">
        <el-button type="text" @click="showDetail(scope.row)" v-if="data.authMenus.query">消费明细</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <BoxBillMsg
    :rowMsg="data.rowMsg"
    v-if="data.dialogVisible"
    @update-dialogVisible="updateDialogVisible"
    :dialogVisible="data.dialogVisible"
  ></BoxBillMsg>
</template>

<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/api'
import BoxBillMsg from './box-bill-msg.vue'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
const data = reactive({
  openCode: undefined,
  roomName: undefined,
  storeCode: undefined,
  time: ['', ''],
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
  rowMsg: {},
  settlementStatusList: {
    0: '未结',
    1: '已结',
  },
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryLists()
  }
}
const searchLists = () => {
  data.currentPage = 1
  queryLists()
}
const resetData = () => {
  data.openCode = undefined
  data.roomName = undefined
  data.storeCode = undefined
  data.time = ['', '']
  searchLists()
}

const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let params = {
    openCode: data.openCode,
    roomName: data.roomName,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    storeCode: data.storeCode,
  }

  if (!data.time) {
    params.beginTime = ''
    params.endTime = ''
  } else {
    params.beginTime = data.time[0]
    params.endTime = data.time[1]
  }
  API.orderOpenList(params)
    .then(res => {
      if (res.code === '200') {
        data.tableData = res.data.records
        data.total = res.data.totalRecords
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}
const showDetail = item => {
  data.rowMsg = _.cloneDeep(item)
  data.rowMsg.openCode = data.rowMsg.code
  data.dialogVisible = true
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
