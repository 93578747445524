<!--
 * @Author: your name
 * @Date: 2022-04-21 17:06:59
 * @LastEditTime: 2022-07-12 18:12:17
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/shop-session-manager/infoDetail.vue
-->
<template>
  <div class="record-order-detail">
    <GoBack />
    <div class="detail-content-box">
      <ul class="shop-info-item">
        <li><span>用户名：</span>{{ userInfoData.nickName }}</li>
        <li><span>手机号：</span>{{ userInfoData.telephone }}</li>
        <li><span>存酒门店：</span>{{ userInfoData.storeName }}</li>
        <li><span>等级：</span>{{ userInfoData.levelName }}</li>
        <li><span>在存数量：</span>{{ userInfoData.wineCount }}</li>
      </ul>
      <div class="table-title">
        <div>存取明细</div>
        <div>
          <el-date-picker
            v-model="searchDate"
            type="datetimerange"
            start-placeholder="起始日期"
            format="YYYY-MM-DD"
            end-placeholder="结束日期"
            style="margin-right: 20px;"
            @change="selectDate"
          />
          <!-- <el-button type="primary">数据导出</el-button> -->
        </div>
      </div>
      <div class="table-content">
        <el-table :data="tableData" style="width: 100%" class="el-table-header-gap">
          <el-table-column prop="createTime" label="存取时间" />
          <el-table-column prop="type" label="状态">
            <template #default="scope">
              <div
                :class="scope.row.type === 1 ? 'storing-wine' : scope.row.type === 2 ? 'another-wine' : 'extend-wine'"
              >
                {{ scope.row.type === 1 ? '存酒' : scope.row.type === 2 ? '取酒' : '续存' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="数量" />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="text" @click="showDetail(scope.$index, scope.row)">商品详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-pagination
      v-model:currentPage="model.pageNum"
      :page-size="10"
      :small="small"
      :page-count="totalPage"
      :disabled="disabled"
      class="pagination-style"
      layout="total, prev, pager, next"
      :total="totalRecords"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <el-drawer v-model="detailModal" :with-header="false" width="35%">
    <div id="drawer-content-box" class="drawer-content-box">
      <h1>
        {{ rowDetail.createTime
        }}<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="detailModal = false">
          <close />
        </el-icon>
      </h1>
      <p class="info-title">{{ rowDetail.type === 1 ? '存酒' : rowDetail.type === 2 ? '取酒' : '续存' }}详情</p>
      <ul>
        <li v-for="item in rowDetail.contentList">
          <span>{{ item.skuName }}</span
          ><span>{{ rowDetail.type === 1 || rowDetail.type !== 2 ? '+' : '-' }}{{ item.num }}{{ item.unit }}</span>
        </li>
      </ul>
    </div>
  </el-drawer>
</template>
<script>
import GoBack from '../../../../component/base/backHeader/goBack.vue'

import api from '../../service/index'
import moment from 'moment'

export default {
  components: { GoBack },
  data() {
    return {
      tableData: [],
      detailModal: false,
      model: {
        endTime: '',
        expireEndTime: '',
        expireStartTime: '',
        groupCode: this.$route.query.id,
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        storeCode: '',
        telephone: '',
        type: 0,
      },
      rowDetail: null,
      searchDate: '',
      totalRecords: null,
      totalRecords: null,
      totalPage: null,
      userInfoData: {
        nickName: '',
        telephone: '',
        storeCode: '',
        storeName: '',
        levelName: '',
        wineCount: 0,
      },
    }
  },
  mounted() {
    this.getDetail(this.model)
    this.getUserInfo()
  },
  methods: {
    getDetail(model) {
      api.getWineLogListPages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    getUserInfo() {
      api.getCustomerUserByTelephone({ telephone: this.$route.query.telephone }).then(res => {
        if (res.data === null) return
        this.userInfoData = res.data
      })
    },
    showDetail(index, row) {
      this.detailModal = true
      this.rowDetail = row
    },
    selectDate(val) {
      if (val) {
        this.model.startTime = moment(val[0]).format('YYYY-MM-DD HH:mm:ss')
        this.model.endTime = moment(val[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.model.startTime = ''
        this.model.endTime = ''
      }

      this.model.pageNum = 1
      this.getDetail(this.model)
    },
    handleSizeChange(val) {
      this.model.pageNum = val
      this.getDetail(this.model)
    },
    handleCurrentChange(val) {
      this.model.pageNum = val
      this.getDetail(this.model)
    },
  },
}
</script>
<style lang="scss" scoped>
.record-order-detail {
  // padding-left: 40px;

  h1 {
    width: 1568px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6d7278;
    position: relative;

    span {
      position: absolute;
      top: 4px;
    }
  }

  .pagination-style {
    text-align: right;
    width: 1568px;
    margin: auto;
    margin-top: 20px;
  }

  .detail-content-box {
    width: 1568px;
    height: 825px;
    background: #ffffff;
    border-radius: 8px;
    margin: auto;

    .shop-info-item {
      height: 66px;
      line-height: 66px;
      width: 1481px;
      margin: auto;
      border: 1px solid #f5f7fa;
      display: flex;

      li {
        flex: 1;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;

        span:first-child {
          margin-right: 24px;
        }

        .color-blue {
          color: #3f77ee;
        }
      }
    }

    .table-title {
      width: 1481px;
      margin: auto;
      display: flex;
      height: 80px;
      line-height: 80px;

      div {
        flex: 1;
      }

      div:first-child {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #281c1c;
      }

      div:last-child {
        text-align: right;
      }
    }

    .table-content {
      width: 1481px;
      margin: auto;

      .storing-wine {
        color: #44d7b6;
      }

      .another-wine {
        color: #ff9160;
      }

      .extend-wine {
        color: #4177ee;
      }
    }
  }
}

.drawer-content-box {
  h1 {
    position: relative;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 0px;
      cursor: pointer;
    }
  }

  .info-title {
    box-sizing: border-box;
    margin-bottom: 24px;
    height: 40px;
    line-height: 40px;
    background: #f0f2f5;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    padding-left: 24px;
    color: #2e2f31;
    -webkit-text-stroke: 1px #979797;
    margin-top: 20px;
  }

  .content-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 20px 0px;
  }

  ul {
    padding-left: 20px;

    li {
      height: 26px;
      line-height: 26px;
      font-size: 16px;
      font-family: D-DIN, D;
      font-weight: normal;
      color: #222222;

      span:last-child {
        width: 100px !important;
        display: inline-block;
        text-align: right;
        float: right;
      }
    }
  }
}
</style>
