<template>
  <div style=";display: flex;flex-direction: column;align-items: center;">
    <div style="width: 500px;margin-top: 20px;">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="会员手机号">
          <el-input v-model="searchForm.telephone" placeholder="请输入完整会员手机号" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div
        v-if="pageData.tableData.length > 0"
        style="max-height: 500px; overflow-y: auto;display: flex; flex-direction: column; align-items: center;margin-bottom: 20px;"
      >
        <div
          style="display: flex;width: 520px;margin-bottom: 10px;padding: 0px 10px;justify-content: center;align-items: center;"
          v-for="(item, index) in pageData.tableData"
          :key="index"
        >
          <el-card style="width: 400px;">
            <div style="border: 1rpx ;">
              <div style="line-height: 30px;">
                <label style="width: 70px; display: inline-block;text-align-last: right;margin-right: 10px;"
                  >会员姓名:</label
                ><span>{{ item.userName }}</span>
              </div>
              <div style="line-height: 30px;">
                <label style="width: 70px; display: inline-block;text-align-last: right;margin-right: 10px;"
                  >手机号:</label
                ><span>{{ item.telephone }}</span
                ><span
                  style="margin-left: 30px;cursor: pointer;text-decoration: underline;color: cadetblue;"
                  @click="updateUserInfo(item)"
                  >编辑</span
                >
              </div>
              <div style="line-height: 30px;">
                <label style="width: 70px; display: inline-block;text-align-last: right;margin-right: 10px;"
                  >性别:</label
                ><span>{{ item.gender == 1 ? '男' : '女' }}</span>
              </div>
              <div style="line-height: 30px;">
                <label style="width: 70px; display: inline-block;text-align-last: right;margin-right: 10px;"
                  >会员余额:</label
                ><span>{{ item.totalAmount }}</span>
              </div>
              <div style="line-height: 30px;">
                <label style="width: 70px; display: inline-block;text-align-last: right;margin-right: 10px;"
                  >注册门店:</label
                ><span>{{ item.storeCode + '_' + item.storeName }}</span>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div v-else style="text-align: center;margin-bottom: 20px;">
        暂无会员
      </div>
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="searchForm.pageNum"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="searchForm.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchForm.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
  <UpdatePhone
    v-if="pageData.dialogVisible"
    @update-dialogVisible="handleUpdateDialogVisible"
    :userMsg="pageData.userInfo"
    :dialogVisible="pageData.dialogVisible"
  >
  </UpdatePhone>
</template>

<script setup>
import API from '../../service/index'
import UpdatePhone from './update.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive, watch } from 'vue'

const searchForm = reactive({
  telephone: '',
  total: 0,
  pageSize: 10,
  pageNum: 0,
})
const tabPosition = ref(1)
watch(tabPosition, () => {
  queryListByPage()
})

const pageData = reactive({
  tableData: [],
  userInfo: {},
  dialogVisible: false,
})

const handleUpdateDialogVisible = ({ update, search }) => {
  pageData.dialogVisible = update

  if (search) {
    queryListByPage()
  }
}
const updateUserInfo = data => {
  pageData.userInfo = {
    ...data,
    newTelephone: data.telephone,
  }
  pageData.dialogVisible = true
}

const onSubmit = () => {
  searchForm.pageNum = 0
  searchForm.pageSize = 10
  queryListByPage()
}

const queryListByPage = async () => {
  let res = await API.getMemberList({
    pageNum: searchForm.pageNum,
    pageSize: searchForm.pageSize,
    telephone: searchForm.telephone,
  })
  if (res.code == 200) {
    pageData.tableData = res?.data?.records || []
    searchForm.total = res.data?.totalRecords || 0
  } else {
    pageData.tableData = []
    ElMessage.error(res.msg)
  }
}

const handleCurrentChange = val => {
  searchForm.pageNum = val
  queryListByPage()
}
const handleSizeChange = val => {
  searchForm.pageSize = val
  queryListByPage()
}
</script>

<style lang="scss" scoped>
.el-radio-group {
  text-align: center;
}
</style>
