<!--
 * @Author: your name
 * @Date: 2022-04-20 10:24:59
 * @LastEditTime: 2022-12-14 15:57:00
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/shop-order-manager/index.vue
-->
<template>
  <div class="shop-order-manager">
    <SearchBar :authMenus="authMenus" :getTableData="getTableData" :model="model" searchType="order-session" />
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap ">
      <el-table-column prop="storeName" label="门店" width="180" />
      <el-table-column prop="soure" label="来源" width="180" :filters="sourceColumns" :filter-method="filterSource">
        <template #default="scope">
          <div>
            {{ sourceList[scope.row.soure] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="预定号" />
      <el-table-column prop="userName" label="用户名" />
      <el-table-column prop="telephone" label="手机号" />
      <el-table-column prop="roomName" label="包厢名称" />
      <el-table-column prop="payTime" label="支付时间" sortable />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" v-if="authMenus.query" @click="showDetail(scope.$index, scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :authMenus="authMenus"
      :totalRecords="totalRecords"
      :totalPage="totalPage"
      :model="model"
      :getTableData="getTableData"
    />
    <Pagination />
    <el-drawer
      v-model="infoModal"
      title="苏州信投店-A02/小包"
      size="35%"
      :direction="direction"
      :with-header="false"
      :before-close="handleClose"
    >
      <div class="order-detail-item">
        <h1>
          {{ recordsDetail.storeName }}-{{ recordsDetail.roomName }}/{{ recordsDetail.roomTypeName
          }}<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="infoModal = false">
            <close />
          </el-icon>
        </h1>
        <div class="info-line"></div>
        <h2 class="info-title">预定信息</h2>
        <ul>
          <li><span>手机号：</span>{{ recordsDetail.telephone }}</li>
          <li><span>昵称：</span>{{ recordsDetail.userName }}</li>
          <li><span>预定来源：</span>{{ sourceList[recordsDetail.soure] }}</li>
          <li><span>套餐名称：</span>{{ recordsDetail.priceSchemeName }}</li>
          <li>
            <span>消费时段：</span>{{ recordsDetail.reserveDate }} {{ recordsDetail.startTime }}-{{
              recordsDetail.endTime
            }}
          </li>
          <li><span>支付时间：</span>{{ recordsDetail.payTime }}</li>
        </ul>
        <el-button type="primary" class="info-confirm" @click="returnMoney">退款</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import SearchBar from '../../component/tableSearchTab.vue'
import Pagination from '../../component/pagination.vue'
import { ElMessage } from 'element-plus'
import api from '../../service/index'
import moment from 'moment'
import { sourceList, sourceColumns } from '../../../../constants/config'
export default {
  components: { SearchBar, Pagination },
  data() {
    return {
      authMenus: {},
      tableData: [],
      infoModal: false, //详情Modal
      model: {
        pageNum: 1,
        pageSize: 10,
        roomName: '',
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        storeCode: '',
        telephone: '',
      },
      recordsDetail: {},
      totalRecords: null, //总共数据条数
      totalPage: null, //总共页数
      sourceList: sourceList,
      sourceColumns: sourceColumns,
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getTableData(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getTableData(model) {
      api.getAdminChainReserveList(model).then(res => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
        } else {
          ElMessage({
            message: res.msg,
            type: 'error',
          })
        }
      })
    },
    async showDetail(index, row) {
      this.infoModal = true
      const res = await api.getOne({ code: row.code })
      this.recordsDetail = res.data
    },
    handleClose() {
      this.infoModal = false
    },
    async returnMoney() {
      let param = {
        orderCode: this.recordsDetail.code,
        orderType: this.recordsDetail.orderType || 1,
        payCode: this.recordsDetail.payCode,
        payPrice: this.recordsDetail.payPrice,
        relationCode: '',
      }
      const res = await api.refundMoney(param)
      if (res.code != '200' || res.code != 200) {
        ElMessage({
          showClose: true,
          message: res.msg,
          type: 'error',
        })
        return
      }
      this.infoModal = false
      ElMessage({
        showClose: true,
        message: '退款成功',
        type: 'success',
      })
      this.getTableData(this.model)
    },
    filterSource(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
  },
}
</script>
<style lang="scss" scoped>
.shop-order-manager {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .shop-order-search {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 41px;

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }
  }

  .order-detail-item {
    width: 588px;
    margin: auto;

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .close-icon {
        position: absolute;
        top: 20px;
        right: 0px;
        cursor: pointer;
      }
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .info-title {
      box-sizing: border-box;
      margin-bottom: 24px;
      width: 588px;
      height: 40px;
      line-height: 40px;
      background: #f0f2f5;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding-left: 24px;
      color: #2e2f31;
      -webkit-text-stroke: 1px #979797;
    }

    ul {
      padding-left: 20px;

      li {
        height: 26px;
        line-height: 26px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;

        span {
          color: #6d7278;
        }
      }
    }

    .info-confirm {
      position: fixed;
      bottom: 24px;
      right: 40px;
      width: 121px;
      height: 40px;
      background-color: #f66c6a !important;
      border: none !important;
    }
  }
}
</style>
