// src/mocks/handlers.js
import { rest } from 'msw'

export const handlers = [
  //门店管理
  rest.get('/adminChain/store/saas/getStoreCodeList', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [
          {
            value: '西北一区',
            label: '西北一区',
            children: [
              {
                value: '江苏店',
                label: '江苏店',
              },
              {
                value: '苏州店',
                label: '苏州店',
              },
            ],
          },
          {
            value: '西北二区',
            label: '西北二区',
            children: [
              {
                value: '江苏店',
                label: '江苏店',
              },
              {
                value: '苏州店',
                label: '苏州店',
              },
              {
                value: '广州店',
                label: '广州店',
              },
            ],
          },
        ],
        msg: 'string',
      }),
    )
  }),
  rest.post('/adminChain/store/saas/getStoreListPages', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: {
          currentPage: 1,
          totalPage: 10,
          totalRecords: 100,
          records: [
            {
              address: '信投大厦',
              code: 'code', //门店编号
              name: 'name', //门店名称
              characterType: '1', //门店类型
              area: '东北', //门店区域
              storeShortName: 'toreShortName',
              testOperateTime: '2023-02-10', //试运营开业日
              openTime: '2023-02-11', //'开业日'?全政
            },
            {
              code: 'code1', //门店编号
              name: 'name1', //门店名称
              characterType: '2', //门店类型
              area: '东北1', //门店区域
              address: '  address1', //地址，
              testOperateTime: '2023-02-08', //试运营开业日
              openTime: '2023-02-09', //'开业日'?全政
            },
          ],
        },
        msg: 'string',
      }),
    )
  }),
  rest.delete('/adminChain/store/saas/deleteStoreStore', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        msg: 'string',
      }),
    )
  }),
  rest.get('/adminChain/store/saas/getAreaWithChildList', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [
          {
            code: '1100',
            name: '江苏省',
            children: [
              {
                code: '12000',
                name: '苏州市',
                children: [
                  {
                    code: '130004',
                    name: '吴中区',
                  },
                ],
              },
            ],
          },
          {
            code: '11001',
            name: '山西省',
            children: [
              {
                code: '12001',
                name: '临汾市',
                children: [
                  {
                    code: '13001',
                    name: '翼城县',
                  },
                  {
                    code: '13002',
                    name: '直辖区2',
                  },
                ],
              },
            ],
          },
        ],
        msg: 'string',
      }),
    )
  }),
  rest.get('/adminChain/store/saas/getStoreStoreByCode', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: '',
        data: {
          address: '信投大厦',
          area: '130004',
          areaCode: '2',
          areaName: '吴中区',
          characterType: '1',
          city: '12000',
          cityName: '4',
          code: '51213',
          company: '6',
          contractor: '7',
          createTime: '2022-02-11',
          dazhongNumber: '',
          geoCode: '',
          isDelete: 0,
          list: [
            {
              account: '',
              bank: '',
              code: '',
              name: '',
              storeCode: '',
              storeName: '',
              type: 0,
            },
          ],
          location: '',
          locationLatitude: '31.3224',
          locationLongitude: '120.6769',
          measure: 0,
          meituanNumber: '',
          name: 'name',
          openTime: '2022-02-12',
          openingQuantum: '',
          operateState: 0,
          pics: '',
          province: '1100',
          provinceName: '',
          rankType: '',
          receivingInfo: '',
          roomCount: 0,
          sizeType: 0,
          state: 0,
          storePayPreference: {
            accessToken: '',
            appId: '',
            code: '',
            instNo: '',
            isEnable: 0,
            isParentReserveAccount: 0,
            isParentStoredAccount: 0,
            merchantName: '',
            merchantNo: '',
            notifyUrl: '',
            openingDate: '',
            paymentRate: '',
            serviceUrlPay: '',
            storeCode: '',
            storeName: '',
            terminalId: '',
          },
          telephone: '',
          testOperateTime: '2023-09-12',
          updateTime: '',
          wuYe: 0,
        },
        msg: '',
      }),
    )
  }),

  rest.get('/adminChain/geo/saas/getStoreGeoList', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: '',
        data: [
          {
            code: '',
            mark: 0,
            name: '',
            parentCode: '',
          },
        ],
        msg: '',
      }),
    )
  }),

  //商品管理--商品类型
  rest.get('/adminChain/goods/saas/category/selectByType', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: {
          countId: '1',
          current: 0,
          hitount: true,
          total: 100,
          records: [
            {
              code: '3',
              createTime: '2021-02-11', //创建时间
              name: '啤酒', //商品分类名
            },
            {
              code: '3',
              createTime: '2021-02-11', //创建时间
              name: '水果', //商品分类名
            },
          ],
        },
        msg: 'string',
      }),
    )
  }),
  rest.delete('/adminChain/goods/saas/category/deleteByCode', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [],
        msg: 'string',
      }),
    )
  }),
  rest.post('/adminChain/goods/saas/category/createCategory', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [],
        msg: 'string',
      }),
    )
  }),
  rest.get('/adminChain/goods/saas/statisticsType/selectList', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [
          {
            code: '3',
            name: 'name', //商品分类名
          },
          {
            code: '1',
            name: 'name', //商品分类名
          },
        ],
        msg: 'string',
      }),
    )
  }),
  rest.get('/adminChain/goods/saas/category/selectListByType', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: '',
        data: [
          {
            channel: '',
            code: '',
            createTime: '',
            isVisible: 0,
            name: '洋酒',
            parentCode: '',
            parentName: '',
            pic: '',
            sort: 0,
          },
          {
            channel: '',
            code: '',
            createTime: '',
            isVisible: 0,
            name: '啤酒',
            parentCode: '',
            parentName: '',
            pic: '',
            sort: 0,
          },
        ],
        msg: '',
      }),
    )
  }),

  //商品管理--单位配置
  rest.get('/adminChain/goods/saas/dict/selectDictList', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: {
          countId: '1',
          current: 0,
          hitount: true,
          total: 100,
          records: [
            {
              code: '3',
              remarks: '备注', //创建时间
              name: '名称', //商品分类名
            },
            {
              code: '1',
              remarks: '备注1', //创建时间
              name: '名称1', //商品分类名
            },
          ],
        },
        msg: 'string',
      }),
    )
  }),
  rest.delete('/adminChain/goods/saas/dict/deleteDict', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [],
        msg: 'string',
      }),
    )
  }),
  rest.post('/adminChain/goods/saas/dict/saveDict', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [],
        msg: 'string',
      }),
    )
  }),

  //商品管理--出入库类型
  rest.get('/adminChain/goods/saas/stockChannel/selectChannel', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: {
          countId: '1',
          current: 0,
          hitount: true,
          total: 100,
          records: [
            {
              no: '3',
              name: '名称', //商品分类名
            },
            {
              no: '备注1',
              name: '名称1', //商品分类名
            },
          ],
        },
        msg: 'string',
      }),
    )
  }),

  //商店管理--商品列表
  rest.post('/adminChain/goods/saas/goodsSpu/selectSpuList', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: {
          code: '',
          data: {
            countId: '',
            current: 0,
            hitCount: true,
            maxLimit: 0,
            optimizeCountSql: true,
            orders: [
              {
                asc: true,
                column: '',
              },
            ],
            pages: 0,
            records: [
              {
                code: '',
                id: 0,
                skuDtoList: [
                  {
                    channel: '1',
                    code: '1',
                    createTime: '',
                    id: 0,
                    pic: '1',
                    price: '2',
                    skuName: '3',
                    spec: '',
                    unit: '',
                  },
                ],
                spuName: 'spuname',
              },
            ],
            searchCount: true,
            size: 0,
            total: 0,
          },
          msg: '',
        },
        msg: 'string',
      }),
    )
  }),
  rest.post('/adminChain/goods/saas/goodsSpu/saveSpuInfo', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [],
        msg: 'string',
      }),
    )
  }),
  rest.post('/adminChain/goods/saas/goodsSpu/updateSpuInfo', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [],
        msg: 'string',
      }),
    )
  }),
  rest.delete('/adminChain/goods/saas/goodsSpu/deleteSpuInfoByCode', (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        code: 'string',
        data: [],
        msg: 'string',
      }),
    )
  }),
]
