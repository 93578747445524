<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-08-01 15:34:15
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-08-11 16:39:34
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/purchase-details/order-template/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="after-sale-box">
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap">
      <el-table-column prop="storeName" label="门店" />
      <el-table-column prop="supplierName" label="供应商" />
      <el-table-column prop="preOrderCode" label="售后号" />
      <el-table-column prop="code" label="订单号" />
      <el-table-column prop="price" label="售后金额" />
      <el-table-column prop="type" label="售后类型">
        <template #default="scope">
          {{ typeList[scope.row.type] }}
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="售后原因" />
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <elDrawerVue ref="drawer" :title="'订单号：' + detailData.code" :state="detailData.state">
      <ul class="detail-drawer">
        <li>创建时间： {{ detailData.createTime }}</li>
        <li>确定时间：- -</li>
      </ul>
      <div class="commodity-img-list">
        <h2 class="title">商品图片</h2>
        <ul>
          <li v-for="item in srcList">
            <el-image
              class="el-image"
              :src="item"
              :preview-src-list="imgfilter(srcList)"
              style="width: 100px; height: 100px;margin-right: 20px;"
            />
          </li>
        </ul>
      </div>
      <div class="after-reason-box">
        <h2 class="title">售后原因</h2>
        <p>备注：{{ detailData.remark }}</p>
      </div>
      <TableVue :columns="columns" :data="detailData.itemList" class="table-content" />
    </elDrawerVue>
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
  </div>
</template>

<script>
import elDrawerVue from '../../../../../component/base/el-Drawer/el-drawer.vue'
import Pagination from '../../../component/pagination.vue'
import TableVue from '../component/table.vue'
import api from '../../../service/index'
export default {
  components: {
    elDrawerVue,
    Pagination,
    TableVue,
  },
  data() {
    return {
      tableData: [],
      status: '待收货',
      totalRecords: null,
      totalPage: null,
      model: {
        endTime: '',
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        supplierCode: '',
        storeCodeList: [],
      },
      columns: [
        {
          prop: 'skuName',
          label: '商品名称',
        },
        {
          prop: 'unit',
          label: '售后单位',
        },
        {
          prop: 'skuPrice',
          label: '售后单价',
        },
        {
          prop: 'num',
          label: '数量',
        },
        {
          prop: 'address',
          label: '售后单品总价',
          formatter: val => {
            return val.skuPrice * val.num
          },
        },
      ],
      srcList: [],
      typeList: { 1: '进货订单', 2: '补货订单', 3: '退款订单', 4: '空瓶订单' },
      detailData: {},
    }
  },
  mounted() {
    this.getTableData(this.model)
  },
  methods: {
    showDetail(index, row) {
      this.$refs.drawer.detailModal = true
      api.getAfterSalesDetail({ code: row.code }).then(res => {
        this.detailData = res.data
        this.srcList = this.detailData.pics.split(',')
      })
    },
    getTableData(model) {
      api.getAfterSalesListPages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    imgfilter(imgobj) {
      let imgs = []
      imgobj.forEach(obj => {
        imgs.push(obj)
      })
      return imgs
    },
  },
}
</script>
<style lang="scss" scoped>
.after-sale-box {
  .form-right-style {
    text-align: right;

    .mr-40 {
      margin-left: 50px;
    }

    .mr-30 {
      margin-left: 20px;
    }
  }

  .detail-drawer {
    li {
      margin-bottom: 8px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }

  .logistics-content-box {
    margin-top: 32px;

    h2 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;

      span:last-child {
        font-size: 12px;
      }
    }
  }

  .commodity-img-list {
  }

  .after-reason-box {
    p:last-child {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }

  .title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .table-content {
    margin-top: 81px;
  }
}
</style>
