<template>
  <div style="margin-top: 20px;padding-left: 20px;padding-right: 20px;">
    <el-page-header @back="goBack">
      <template #content>
        <div style="display: flex;">
          <p style="margin-right: 10px;">供应商名称</p>
          <p style="margin-right: 20px;">{{ data.supplierInfo.supplierName }}</p>
          <p style="margin-right: 10px;">供货类型</p>
          <p>{{ data.supplierInfo.supplyType }}</p>
        </div>
      </template>
    </el-page-header>
  </div>
  <div style="display: flex;padding-left: 10px; height: 100%;padding-top: 30px;">
    <div>
      <el-card class="box-card" style="height:80vh;">
        <el-tabs style="width: 200px; height: 100%;" v-model="data.activeName" @tab-click="changeTab">
          <el-tab-pane label="商品" :name="1" style="height: 100%;">
            <el-scrollbar>
              <p
                v-for="(item, index) in data.productTypes"
                :key="index"
                class="productItem"
                :class="{ activeItem: item.activeItemStyle }"
                @click="selectCategoryCode(item)"
              >
                {{ item.name }}
              </p>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="易耗品" :name="4">
            <el-scrollbar>
              <p
                v-for="(item, index) in data.articleTypes"
                :key="index"
                class="productItem"
                :class="{ activeItem: item.activeItemStyle }"
                @click="selectCategoryCode(item)"
              >
                {{ item.name }}
              </p>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
      </el-card>
    </div>

    <div style="width: calc(100% - 140px);">
      <el-form :inline="true" :model="data" style="margin-left: 20px;">
        <el-form-item label="商品名称">
          <el-input
            placeholder="请输入搜索的商品名称"
            v-model="data.productName"
            class="input-search-tel"
            @change="queryShops"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="queryShops">查询</el-button>
          <el-button type="primary" @click="addArticle">新增</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="data.tableData" style="width: 96%; margin-left: 2%;" class="table-content">
        <el-table-column label="序号" align="center" type="index" :index="indexfn" />
        <el-table-column label="商品图片" width="100" align="center">
          <template #default="scope">
            <div style="display: flex; justify-content: center;">
              <img :src="scope.row.skuPic" alt="" style="width:30px; height: 30px;" />
            </div>
          </template>
        </el-table-column>
        <el-table-column label="采购图片" prop="pic" width="100" align="center">
          <template #default="scope">
            <div style="display: flex; justify-content: center;">
              <img :src="scope.row.purchaseGoodsPic || scope.row.skuPic" alt="" style="width:30px; height: 30px;" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="skuName" label="商品名称" width="200px" align="center" />
        <el-table-column prop="purchaseGoodsName" label="采购名称" width="200px" align="center" />
        <el-table-column prop="specifications" label="商品规格" align="center" />
        <el-table-column label="采购单位" align="center" prop="purchaseUnit"> </el-table-column>
        <el-table-column label="最小单位" align="center" prop="miniUnit"> </el-table-column>
        <el-table-column label="采购比(1:N)" width="120px" align="center">
          <template #default="{row}">
            <span>{{ row.attribute == 3 ? '' : row.adoptPinThan }}</span>
          </template>
        </el-table-column>
        <el-table-column label="采购单价(元)" width="120px" align="center" prop="goodsCost" />
        <el-table-column label="空瓶价(元)(若有)" width="150px" align="center" prop="recoveryPrice" />
        <el-table-column prop="createTime" label="创建时间" width="200px" align="center" />
        <el-table-column label="操作" width="200px">
          <template #default="scope">
            <el-button type="text" @click="showDetail(1, scope.row)">采购设置</el-button>
            <el-button type="text" @click="showDetail(2, scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        v-model:current-page="data.currentPage"
        v-model:page-size="data.pageSize"
        :small="small"
        :page-sizes="[5, 10, 20]"
        :disabled="disabled"
        :background="background"
        layout="total,prev, pager, next, jumper"
        :total="data.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
  <AddArticle
    :code="data.supplierInfo.code"
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    @update-dialogVisible="updateDialogVisible"
  ></AddArticle>

  <updateArticle
    v-if="data.updateArticleDialogVisible"
    :dialogVisible="data.updateArticleDialogVisible"
    @update-dialogVisible="updateArticleDialogVisibleFn"
    :specRowMsg="data.specRowMsg"
  ></updateArticle>
</template>

<script setup>
import _ from 'lodash'
import { reactive, onMounted, toRaw, computed, ref } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
import AddArticle from './AddArticle.vue'
import updateArticle from './uploadArticle.vue'

const data = reactive({
  categoryCode: undefined,
  productName: undefined,
  activeName: 1,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 100,
  dialogVisible: false,
  updateArticleDialogVisible: false,
  accountMsg: {},
  productTypes: [],
  articleTypes: [],
  units: [],
  supplierInfo: {},
  specRowMsg: {},
})

const router = useRouter()

onMounted(() => {
  data.supplierInfo = JSON.parse(sessionStorage.getItem('purchase-info'))
  selectBelowProductSkuArray()
  queryAllShopTyps()
  queryAllArticlesTyps()
  selectDictListAll()
})

//获取单位
const selectDictListAll = () => {
  API.selectDictListAll().then(res => {
    if (res.code === '200') {
      data.units = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const selectBelowProductSkuArray = () => {
  let params = {
    skuName: data.productName,
    type: data.activeName,
    current: data.currentPage,
    pageSize: data.pageSize,
    supplierCode: data.supplierInfo.code,
  }
  if (data.categoryCode && data.categoryCode !== -1) {
    params.categoryCode = data.categoryCode
  }
  API.selectProductPage(params).then(res => {
    if (res.code == '200') {
      data.tableData = res.data.records
      data.total = res.data.total
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const goBack = () => {
  router.back()
}
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

//查询所有商品类型
const queryAllShopTyps = () => {
  API.selectListByType({ type: 1 }).then(res => {
    if (res.code === '200') {
      data.productTypes = res.data
      data.productTypes.splice(0, 0, { name: '全部', value: '-1', activeItemStyle: 'true' })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//查询所有易耗品
const queryAllArticlesTyps = () => {
  API.selectListByType({ type: 4 }).then(res => {
    if (res.code === '200') {
      data.articleTypes = res.data
      data.articleTypes.splice(0, 0, { name: '全部', value: '-1' })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const queryShops = () => {
  data.currentPage = 1
  selectBelowProductSkuArray()
}

const changeTab = () => {
  let a = data.articleTypes.find(item => item.activeItemStyle)
  let b = data.productTypes.find(item => item.activeItemStyle)
  if (a) {
    a.activeItemStyle = false
  }
  if (b) {
    b.activeItemStyle = false
  }
  data.categoryCode = ''
  if (data.activeName == 1) {
    data.productTypes[0].activeItemStyle = true
  } else {
    data.articleTypes[0].activeItemStyle = true
  }
  selectBelowProductSkuArray()
}

//添加
const addArticle = () => {
  data.dialogVisible = true
  // data.accountMsg = {}
}
const deleteRow = rowMsg => {
  API.deleteProduct({
    code: rowMsg.code,
  }).then(res => {
    if (res?.code == 200) {
      ElMessage.success('删除成功')
      selectBelowProductSkuArray()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const operationRow = rowMsg => {
  data.updateArticleDialogVisible = true
  data.specRowMsg = {
    ...rowMsg,
    purchaseGoodsPic: rowMsg.purchaseGoodsPic || rowMsg.skuPic,
  }
}

//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      operationRow(rowMsg)
      break
    case 2:
      deleteRow(rowMsg)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    selectBelowProductSkuArray()
  }
}

const updateArticleDialogVisibleFn = ({ update, search }) => {
  data.updateArticleDialogVisible = update
  if (search) {
    selectBelowProductSkuArray()
  }
}

const updateProduct = product => {
  API.updateProduct(product).then(res => {
    if (res?.code == 200) {
      ElMessage.success('修改成功')
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  selectBelowProductSkuArray()
}
const handleCurrentChange = val => {
  data.currentPage = val
  selectBelowProductSkuArray()
}
const selectCategoryCode = item => {
  let a = data.articleTypes.find(item => item.activeItemStyle)
  let b = data.productTypes.find(item => item.activeItemStyle)
  if (a) {
    a.activeItemStyle = false
  }
  if (b) {
    b.activeItemStyle = false
  }
  item.activeItemStyle = true
  data.categoryCode = item.code
  selectBelowProductSkuArray()
}
</script>

<style lang="scss" scoped>
::v-deep.el-input-number {
  width: 100%;
}
.activeItem {
  background-color: rgba(127, 127, 127, 0.3);
}
.productItem {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: rgba(127, 127, 127, 0.3);
  }
}
.el-card {
  ::v-deep .el-card__body {
    height: 90%;
  }
  ::v-deep .el-tabs__content {
    height: 90%;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
