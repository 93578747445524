import _axios, { post, get } from '@/lin/plugin/axios'
import axios from 'axios'

class CsMng {
  //获取门店列表
  async getStoreListByName(data) {
    const res = await post(`/adminChain/store/saas/getStoreList`, data)
    return res
  }
  async smsPageList(data) {
    return await post(`/adminChain/sms/smsPageList`, data)
  }
  //查询区域 == 权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1,
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }
  // 获取短信明细
  async queryReport(data) {
    return await post(`/report/SMS/queryReport`, data)
  }
  // 导出短信明细
  exportReport({ url, data }) {
    return _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
  }
}

export default new CsMng()
