<!--
 * @Author: your name
 * @Date: 2022-04-21 17:06:59
 * @LastEditTime: 2022-08-28 22:08:54
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/shop-session-manager/infoDetail.vue
-->
<template>
  <div class="session-order-detail">
    <GoBack />
    <div class="detail-content-box">
      <ul class="shop-info-item">
        <li><span>门店：</span>{{ infoDetail.storeName }}</li>
        <li><span>包厢类型：</span>{{ infoDetail.roomTypeName }}</li>
        <li><span>包厢名称：</span>{{ infoDetail.roomName }}</li>
        <li>
          <span>场次号：</span> <span class="color-blue">{{ infoDetail.code }}</span>
        </li>
        <li>
          <span>场次状态：</span> <span class="color-blue">{{ sessionStatusList[infoDetail.status] }}</span>
        </li>
      </ul>
      <div class="info-content">
        <div>
          <p>会员：{{ infoDetail.userName }}-{{ infoDetail.telephone }}</p>
          <p>时段：{{ infoDetail.startTime }}-{{ infoDetail.endTime }}</p>
          <p>场次：{{ infoDetail.code }}</p>
        </div>
        <div>
          <p>总消费：{{ infoDetail.totalPrice }}元</p>
          <p>超市：{{ infoDetail.roomGoodsPrice / 100 }}元</p>
          <p>房时：{{ infoDetail.roomTimePrice }}元</p>
        </div>
      </div>
      <p class="table-title">订单明细</p>
      <div class="table-content">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="code" label="订单号" width="180" />
          <el-table-column prop="ticketNo" label="小票号" />
          <el-table-column prop="userName" label="用户名" />
          <el-table-column prop="telephone" label="手机号" />
          <el-table-column prop="orderType" label="类型" :filter-method="filterSource" :filters="statusList">
            <template #default="scope">
              <div>
                {{ scope.row.orderType === 1 ? '房时消费' : '超市消费' }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="金额">
            <template #default="scope">
              <div>
                {{ scope.row.price / 100 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="payPrice" label="实付">
            <template #default="scope">
              <div>
                {{ scope.row.payPrice / 100 }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="payType" label="支付方式" :filter-method="filterSource" :filters="payTypeColumns">
            <template #default="scope">
              <div>
                {{ payTypeList[scope.row.payType] }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="payTime" label="支付时间" sortable />
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="text" @click="showDetail(scope.$index, scope.row)">商品详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
  <el-drawer v-model="detailModal" :with-header="false" width="35%">
    <div id="drawer-content-box" class="drawer-content-box">
      <h1>
        订单号：{{ singleDetails.code
        }}<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="detailModal = false">
          <close />
        </el-icon>
      </h1>
      <p class="content-title">商品详情</p>
      <ul v-if="singleDetails.orderType === 2">
        <li v-for="item in singleDetails.orderGoodsDetailList">
          <span>{{ item.skuName }}</span
          ><span>¥{{ item.price / 100 }}</span>
        </li>
      </ul>
      <ul v-else>
        <li>
          <span>{{ singleDetails.comboCodeName }}</span
          ><span>¥{{ singleDetails.price }}</span>
        </li>
      </ul>
    </div>
  </el-drawer>
</template>
<script>
import { sessionStatusList, payTypeColumns, payTypeList } from '../../../../constants/config'
import GoBack from '../../../../component/base/backHeader/goBack.vue'
import api from '../../service/index'
export default {
  components: { GoBack },
  data() {
    return {
      tableData: [],
      detailModal: false,
      infoDetail: {},
      sessionStatusList: sessionStatusList,
      payTypeColumns: payTypeColumns,
      payTypeList: payTypeList,
      singleDetails: {},
      statusList: [
        { text: '房时消费', value: 1 },
        { text: '超市消费', value: 2 },
      ],
    }
  },
  mounted() {
    this.getInfoDetail()
  },
  methods: {
    filterSource(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    getInfoDetail() {
      api.getOrderOpenDetail({ code: this.$route.query.id }).then(res => {
        this.infoDetail = res.data
        this.tableData = [...res.data.orderGoodsBoList, ...res.data.orderRoomVoList]
      })
    },
    resetComboCodeName(data) {
      if (!data.comboCodeName) {
        const names =
          data && data.orderGoodsBoList
            ? _.map(data.orderGoodsBoList, item => {
                return item.skuName
              })
            : []
        data.comboCodeName = names.join(', ')
      }
    },
    async showDetail(index, row) {
      this.detailModal = true
      if (row.orderType === 1) {
        const resRoom = await api.getOrderRoomDetail({ code: row.code })
        this.singleDetails = resRoom.data
      } else {
        const resGoods = await api.getOrderGoodsDetail({ code: row.code })
        this.singleDetails = resGoods.data
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.session-order-detail {
  // padding-left: 40px;

  h1 {
    width: 1568px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #6d7278;
    position: relative;

    span {
      position: absolute;
      top: 4px;
    }
  }

  .detail-content-box {
    width: 1568px;
    height: 875px;
    background: #ffffff;
    border-radius: 8px;
    margin: auto;

    .shop-info-item {
      height: 66px;
      line-height: 66px;
      width: 1481px;
      margin: auto;
      border: 1px solid #f5f7fa;
      display: flex;

      li {
        flex: 1;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;

        span:first-child {
          margin-right: 24px;
        }

        .color-blue {
          color: #3f77ee;
        }
      }
    }

    .info-content {
      line-height: 66px;
      width: 1481px;
      margin: auto;
      box-sizing: border-box;
      border: 1px solid #f5f7fa;

      div {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        display: inline-block;
        margin-top: 18px;

        p {
          height: 26px;
          line-height: 26px;
        }
      }

      div:first-child {
        padding-right: 74px;
        border-right: 1px solid #eaecef;
        margin-right: 44px;
      }
    }

    .table-title {
      height: 60px;
      line-height: 60px;
      width: 1481px;
      margin: auto;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
    }

    .table-content {
      width: 1481px;
      margin: auto;

      ::v-deep .el-table {
        // tr>th:first-child,
        // tr>td:first-child {
        //     left: 24px;
        // }

        tr > th:last-child,
        tr > td:last-child {
          right: 24px;
        }

        tr > th:last-child .cell {
          padding-left: 26px;
        }

        // .el-table__header {
        //     background-color: #f5f7fa;
        // }
      }
    }
  }
}

.drawer-content-box {
  h1 {
    position: relative;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 0px;
      cursor: pointer;
    }
  }

  .content-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin: 20px 0px;
  }

  ul {
    li {
      height: 26px;
      line-height: 26px;
      display: flex;
      font-size: 16px;
      font-family: D-DIN, D;
      font-weight: normal;
      color: #222222;

      span {
        flex: 1;
      }

      span:last-child {
        text-align: right;
      }
    }
  }
}
</style>
