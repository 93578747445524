<template>
  <div class="drawerform">
    <el-drawer :title="title" v-model="drawerFormVisible" :direction="direction" :size="size">
      <!-- <img class="title_icon2" src="/static/circle2.png" /> -->
      <i class="el-icon-circle-close closebtn" @click="closeDialog"></i>
      <slot name="header"></slot>
      <slot v-if="isDisabled" class="form_content" name="content"></slot>
      <div class="formcontanier" v-else>
        <el-row>
          <el-form
            :inline="true"
            :rules="rules"
            :model="ruleForm"
            v-if="formreload"
            ref="ValidateForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item
              v-for="(vi, i) of formateData"
              :key="i"
              :label="vi.label || ''"
              :prop="vi.prop"
              :label-width="vi.labelWidth"
            >
              <component
                v-if="vi.type !== 'radio-group' && vi.type"
                :is="`el-${vi.type}`"
                :placeholder="vi.placeholder"
                :type="vi.typeProp || 'text'"
                :multiple="vi.multiple"
                :autosize="{ minRows: 3, maxRows: 5 }"
                :clearable="!vi.unclearable"
                :active-text="vi.activeText"
                :props="vi.props || {}"
                :inactive-text="vi.inactiveText"
                :options="vi.options"
                :disabled="vi.disabled"
                :maxlength="vi.maxlength"
                :minlength="vi.minlength"
                :max="vi.max"
                :min="vi.min"
                :is-range="vi.isRange"
                style="width:13vw;"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :format="vi.dataFormate"
                :value-format="vi.dataFormate"
                :show-password="vi.showPassword"
                :show-word-limit="vi.showWordLimit"
                :active-value="vi.activeValue"
                :filterable="vi.filterable"
                :inactive-value="vi.inactiveValue"
                v-model="ruleForm[vi.prop]"
              >
                <template v-if="vi.children">
                  <component
                    :is="`el-${vi.children}`"
                    v-for="(item, index) of vi.options"
                    :key="`${i}_${ind}_${index}`"
                    :value="item.value"
                    :label="item.label"
                  >
                    <!-- <span>{{ vi.type !== 'checkbox-group' ? item[vi.labelKey] : item[vi.idKey] }}</span> -->
                  </component>
                </template>
              </component>
              <el-radio-group v-if="vi.type === 'radio-group'" v-model="ruleForm[vi.prop]">
                <template v-for="(item, index) of vi.options">
                  <component
                    :is="`el-${vi.children}`"
                    v-if="vi.children"
                    :key="`${i}_${ind}_${index}`"
                    :value="item.value"
                    :label="item.label"
                  >
                    <!-- <span>{{vi.labelKey}}</span> -->
                  </component>
                </template>
              </el-radio-group>
            </el-form-item>
          </el-form>
        </el-row>
        <div class="operate">
          <el-button @click="cancelSubmit" style="width: 150px">取消</el-button>
          <el-button style="width: 150px;margin-left:20px" type="primary" @click="onSubmit">保存</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, watch, reactive } from 'vue'

export default defineComponent({
  name: 'drawform',
  props: {
    submitshow: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    direction: {
      type: String,
      default: 'ltr',
    },
    size: {
      type: String,
      default: '45%',
    },
    formList: {
      type: Array,
      default: [],
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    formStyle: {
      type: Object,
      default: () => ({
        width: '33%', // 必须是 % | px 结尾的单位
        labelWidth: '80px', // formitem的label的宽度
        labelPosition: 'right', // 表单每一个form对齐的样式 right | top | left
      }),
    },
  },
  methods: {
    changeForm(itemKey) {
      // this.$emit('change', itemKey, this.form[itemKey])
    },
    closeDialog() {
      this.drawerFormVisible = false
      this.$emit('close')
    },
    cancelSubmit() {
      this.drawerFormVisible = false
    },

    setFormFields(type) {
      this.formreload = false
      if (typeof type == 'boolean') {
        this.formateData = []
        this.rules = {}
        this.ruleForm = {}
        this.formList?.map(item => {
          if (item.type && !item.formhidden) {
            this.formateData.push(item)
            if (item.validate) {
              this.rules[item.prop] = item.validate()
            }
            this.ruleForm[item.prop] = ''
          }
        })
        if (type) {
          this.formreload = true
          Object.keys(this.ruleForm).map(key => {
            this.ruleForm[key] = this.formList.row[key]
          })
        } else {
          this.$nextTick(() => {
            delete this.formList.row
            this.formreload = true
            if (this.$refs['ValidateForm']) {
              this.$refs['ValidateForm'].resetFields()
            }
          })
        }
      }
    },
    onSubmit() {
      this.$refs['ValidateForm'].validate(valid => {
        if (valid) {
          this.ruleForm = Object.assign(this.formList.row ? this.formList.row : {}, this.ruleForm)
          this.$emit('Submit', this.ruleForm)
        } else {
          return false
        }
      })
    },
  },

  mounted() {
    this.setFormFields()
  },

  setup(props, context) {
    if (props.isDisabled) {
    }

    const formreload = ref(false)

    let drawerFormVisible = ref(false)
    const openDrawer = () => {
      drawerFormVisible.value = true
    }

    let ruleForm = ref({})
    let formateData = ref([])
    let rules = ref({})

    return {
      drawerFormVisible,
      ruleForm,
      formateData,
      rules,
      formreload,
      openDrawer,
    }
  },
})
</script>
<style lang="scss" scoped>
.drawerform {
  position: relative;

  .title_icon {
    position: absolute;
    width: 20px;
    top: 15px;
    left: 110px;
    z-index: 10;
  }
  .operate {
    text-align: center;
    // position: absolute;
    // bottom: 40px;
    width: 100%;
  }
  .title_icon2 {
    position: absolute;
    right: -100px;
    top: -80px;
    width: 200px;
  }
  ::v-deep .el-form-item__label {
    color: #7a97f2;
  }
  ::v-deep .el-input__inner,
  .el-input__inner:focus,
  .el-textarea__inner {
    border-color: #a1baff;
  }

  ::v-deep .el-select-dropdown__item {
    color: #fff !important;
  }

  ::v-deep .el-drawer__header span[role='heading'] {
    color: #7a97f2;
    position: absolute;
    font-size: 25px;
    z-index: 11;
    top: 20px;
    left: 10px;
  }
  ::v-deep .el-drawer__close-btn {
    display: none;
  }

  .closebtn {
    position: absolute;
    top: 30px;
    right: 25px;
    font-size: 28px;
    color: gray;
  }
  .formcontanier {
    height: calc(100vh - 200px);
    padding-left: 20px;
    margin-top: 40px;
    overflow-y: auto;
  }
  .operate {
    position: absolute;
    bottom: 30px;
    text-align: center;
  }
  .form_row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; // wrap-reverse;
    justify-content: flex-start;
  }
}
</style>
