/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-07-25 11:07:44
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '账号管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  order: null,
  inNav: true,
  children: [
    {
      title: '门店账号',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/asscount_manager/shop_number',
      filePath: 'plugin/account_manager/view/shop_number/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
