import { checkphone, checknbumber, checkshopnum } from '../../../../validator'
const TableHead = [
  {
    prop: 'storeSort',
    label: '开店顺序',
    formwidth: '50%',
    type: 'input',
    placeholder: '输入(例:宁波第1家）',
    validate: function() {
      return [
        {
          required: true,
          message: '请输入开店顺序',
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'serialNumber',
    type: 'input',
    placeholder: '输入(例:K001）',
    label: '门店编号',
    validate: function() {
      return [
        {
          required: true,
          message: '请输入门店编号（例:K001）',
          trigger: 'change',
        },
        {
          validator: checkshopnum,
          trigger: 'blur',
        },
      ]
    },
  },
  {
    prop: 'fullName',
    label: '店铺名称',
    placeholder: '请输入店铺名称',
    labelWidth: '100px',
    type: 'input',
    validate: function() {
      return [
        {
          required: true,
          message: '请输入店铺名称',
          trigger: 'blur',
        },
      ]
    },
  },

  {
    prop: 'shortName',
    label: '店名',
    width: '200',
    formwidth: '50%',
    type: 'input',
    placeholder: '请输入店名',
    labelWidth: '100px',
    hidden: true,
  },
  {
    prop: 'wuYe',
    label: '物业类型',
    width: '200',
    formwidth: '50%',
    type: 'select',
    labelWidth: '100px',
    placeholder: '请选择物业类型',
    children: 'option',
    hidden: true,
    options: [
      {
        label: '老物业',
        value: 1,
      },
      {
        label: '新物业',
        value: 2,
      },
    ],
    formate: function(val) {
      switch (val) {
        case 1:
          return '老物业'
        case 2:
          return '新物业'
      }
    },
    validate: function() {
      return [
        {
          required: true,
          message: '请选择物业类型',
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'zhpf',
    label: '美团评分',
  },
  {
    prop: 'characterType',
    label: '性质',
    width: '150',
    formwidth: '50%',
    type: 'select',
    placeholder: '请选择门店性质',
    labelWidth: '100px',
    children: 'option',
    options: [
      {
        label: '加盟',
        value: 1,
      },
      {
        label: '直营',
        value: 2,
      },
      {
        label: '直营(合作)',
        value: 3,
      },
    ],
    formate: function(val) {
      switch (val) {
        case 1:
          return '加盟'
        case 2:
          return '直营'
        case 3:
          return '直营(合作)'
      }
    },
    validate: function() {
      return [
        {
          required: true,
          message: '请选择门店性质',
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'sizeType',
    label: '大小分类',
    hidden: true,
    width: '100',
    formwidth: '50%',
    type: 'select',
    placeholder: '请选择门店大小分类',
    labelWidth: '100px',
    children: 'option',
    options: [
      {
        label: 'MINI店',
        value: 1,
      },
      {
        label: 'MAX店',
        value: 2,
      },
    ],
    formate: function(val) {
      switch (val) {
        case 1:
          return 'MINI店'
        case 2:
          return 'MAX店'
      }
    },
    validate: function() {
      return [
        {
          required: true,
          message: '请选择门店大小分类',
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'rankType',
    label: '等级分类',
    width: '100',
    hidden: true,
    formwidth: '50%',
    type: 'select',
    placeholder: '请选择门店等级分类',
    labelWidth: '100px',
    children: 'option',
    options: [
      {
        label: 'A',
        value: 'A',
      },
      {
        label: 'B',
        value: 'B',
      },
      {
        label: 'C',
        value: 'C',
      },
    ],
    validate: function() {
      return [
        {
          required: true,
          message: '请选择门店等级分类',
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'boxNumber',
    label: '包厢数量',
    width: '90',
    formwidth: '50%',
    type: 'input-number',
    min: 1,
    max: 200,
    placeholder: '请输入包厢数量',
    labelWidth: '100px',
    validate: function() {
      return [
        {
          required: true,
          message: '请输入包厢数量',
          trigger: 'blur',
        },
        {
          validator: checknbumber,
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'contactor',
    label: '联系人',
    width: '90',
    hidden: true,
    type: 'input',
    placeholder: '请输入联系人',
    labelWidth: '100px',
    validate: function() {
      return [
        {
          required: true,
          message: '请输入联系人',
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'mobilePhone',
    label: '联系电话',
    width: '130',
    type: 'input',
    placeholder: '请输入联系电话',
    labelWidth: '100px',
    hidden: true,
    validate: function() {
      return [
        {
          required: true,
          message: '请输入联系电话',
          trigger: 'change',
        },
        {
          validator: checkphone,
          trigger: 'blur',
        },
      ]
    },
  },
  {
    prop: 'address',
    label: '地址',
    placeholder: '请输入地址',
    labelWidth: '100px',
    type: 'input',
    hidden: true,
    validate: function() {
      return [
        {
          required: true,
          message: '请输入地址',
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'createTime',
    label: '开业时间',
    hidden: true,
    type: 'date-picker',
    defaultValue: new Date(),
    typeProp: 'date',
    width: '50%',
    dataFormate: 'YYYY-MM-DD',
    labelWidth: '100px',
    placeholder: '请选择开业时间',
    formate: function(val) {
      return val.substr(0, 10)
    },
    validate: function() {
      return [
        {
          required: true,
          message: '请选择开业时间',
          trigger: 'change',
        },
      ]
    },
  },

  {
    prop: 'measure',
    label: '面积',
    hidden: true,
    type: 'input-number',
    placeholder: '请输入面积',
    min: 1,
    max: 1000000,
    labelWidth: '100px',
    validate: function() {
      return [
        {
          validator: checknbumber,
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'lon',
    label: '经度',
    hidden: true,
    type: 'input',
    typeProp: 'Number',
    placeholder: '请输入经度',
    labelWidth: '100px',
    validate: function() {
      return [
        {
          validator: checknbumber,
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'lat',
    label: '纬度',
    hidden: true,
    type: 'input',
    typeProp: 'Number',
    placeholder: '请输入纬度',
    labelWidth: '100px',
    validate: function() {
      return [
        {
          validator: checknbumber,
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'mtbh',
    label: '美团编号',
    hidden: true,
    type: 'input',
    placeholder: '请输入美团编号',
    labelWidth: '100px',
    validate: function() {
      return [
        {
          validator: checknbumber,
          trigger: 'change',
        },
      ]
    },
  },
  {
    prop: 'tool',
    label: '操作',
    width: '240',
    formhidden: true,
  },
]

export default TableHead
