<template>
  <el-dialog v-model="visible" title="组织管理" width="40%" :before-close="backtrack">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="orgMsg" label-width="120px" :rules="rules" ref="elForm">
      <el-form-item label="组织名称" prop="name">
        <el-input v-model="orgMsg.name" placeholder="请填写组织名称" style="width: 75%;" />
      </el-form-item>
      <el-form-item label="上级组织" prop="parentCode">
        <el-cascader
          :placeholder="orgMsg.parentName ? `${orgMsg.parentName}(停用)` : '请填写组织名称'"
          :options="orgs"
          filterable
          :show-all-levels="false"
          v-model="orgMsg.parentCode"
          :props="defaultProp"
        />
      </el-form-item>
      <el-form-item label="组织类型" prop="type">
        <el-radio-group v-model="orgMsg.type" prop="type">
          <el-radio :label="1">公司部门</el-radio>
          <el-radio :label="2">门店区域</el-radio>
          <el-radio :label="3">实体门店</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input v-model="orgMsg.remark" type="textarea" />
      </el-form-item>
      <el-form-item label="排序">
        <el-input v-model="orgMsg.sort" style="width: 75%;" />
      </el-form-item>
      <el-form-item label="状态" prop="state">
        <el-radio-group v-model="orgMsg.state">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="0">停用</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 10px;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;" :disabled="!isEdit">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref } from 'vue'
import API from '../../../service/api'
import { ElMessage } from 'element-plus'
import _ from 'lodash'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    orgMsg: Object,
    orgs: Array,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })

    const defaultProp = reactive({
      label: 'name',
      value: 'code',
      children: 'child',
      checkStrictly: true,
    })

    const elForm = ref(null)

    const rules = reactive({
      name: [{ required: true, message: '请填写角色名称', trigger: 'blur' }],
      type: [{ required: true, message: '请选择组织类型', trigger: 'blur' }],
      state: [{ required: true, message: '请选择状态', trigger: 'blur' }],
      parentCode: [{ required: true, message: '请选择上级组织', trigger: 'blur' }],
    })

    const add = data => {
      API.addOrganizational(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('添加成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const update = data => {
      API.updateOrganizational(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const onSubmit = async () => {
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          const params = _.cloneDeep(props.orgMsg)
          if (Array.isArray(params.parentCode)) {
            params.parentCode = params.parentCode[params.parentCode.length - 1]
          }
          if (Reflect.has(props.orgMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const handleSubmit = _.throttle(
      () => {
        onSubmit()
      },
      2000,
      {
        leading: true,
        trailing: false,
      },
    )
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      handleSubmit,
      onSubmit,
      backtrack,
      props: { multiple: true },
      visible,
      defaultProp,
      rules,
      elForm,
    }
  },
})
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
</style>
