import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {
  //门店列表
  async getStoreListByName(data) {
    const res = await get(`/adminChain/store/getStoreListByName`, data)
    return res
  }
  //商品分类
  async getCategoryList(type) {
    const res = await get(`/adminChain/goods/category/list`, { type: type })
    return res
  }

  async getCategoryListWl(type) {
    const res = await get(`/adminChain/material/category/list`, { type: type })
    return res
  }
  //新增套餐
  async addCombo(data) {
    const res = await post(`/adminChain/goods/combo/create`, data)
    return res
  }
  //新增组合
  async addSkuArray(data) {
    const res = await post(`/adminChain/goods/skuArray/create`, data)
    return res
  }
  //更改组合
  async updateSkuArray(data) {
    const res = await post(`/adminChain/goods/skuArray/update`, data)
    return res
  }
  //分配门店
  async AssignMertoMd(data) {
    const res = await post(`/adminChain/goods/distribution`, data)
    return res
  }
  //查看已分配门店列表
  async AssignMdList(data) {
    const res = await get(`/adminChain/goods/distribution/store/list`, data)
    return res
  }
  //查看组合详情
  async getSkuArray(data) {
    const res = await get(`/adminChain/goods/skuArray`, data)
    return res
  }
  //套餐详情
  async getComboInfo(data) {
    const res = await get(`/adminChain/goods/combo`, data)
    return res
  }
  //修改套餐
  async updateComboInfo(data) {
    const res = await post(`/adminChain/goods/combo/update`, data)
    return res
  }

  //添加品类
  async addCategroy(data) {
    const res = await post(`goods/category/create`, data)
    return res
  }

  //品类排序
  async categoryResort(data) {
    const res = await post(`goods/category/resort`, data)
    return res
  }

  //修改品类
  async categroyUpdate(data) {
    const res = await post(`goods/category/update`, data)
    return res
  }

  //删除品类
  async categroyDelete(categoryCode) {
    const res = await post(`/goods/category/delete/${categoryCode}`)
    return res
  }

  //根据品类查找商品
  async skuList(data) {
    const res = await get(`goods/list`, data)
    return res
  }
  //根据品类查找商品新
  async productList(data) {
    const res = await get(`/adminChain/goods/list`, data)
    return res
  }

  //分配商品到门店
  async skuDistribution(data) {
    const res = await post(`goods/sku/distribution`, data)
    return res
  }

  //推荐商品
  async Recommend(data) {
    const res = await post('goods/recommend', data)
    return res
  }
  //推荐列表
  async RecommendList(data) {
    const res = await get('/adminChain/goods/recommend', data)
    return res
  }
  //推荐列表
  async skuResort(data) {
    const res = await post('/adminChain/goods/sku/resort', data)
    return res
  }
  //商品排序
  async goodsResort(data) {
    const res = await post(`/adminChain/goods/resort/${data.type}`, data.lanmus)
    return res
  }
  //根据品类查询类别
  async categoryListch(data) {
    const res = await get(`/adminChain/goods/category/list/child`, { parentCode: data })
    return res
  }
  //根据品类查询属性
  async categoryAttributes(data) {
    const res = await get(`/adminChain/goods/category/attributes/all`, { categoryCode: data })
    return res
  }
  //创建商品
  async addSku(data) {
    const res = await post(`/adminChain/goods/sku/create`, data)
    return res
  }
  //商品上下架
  async SkuShelve(data) {
    const res = await post(`/adminChain/goods/shelves`, data)
    return res
  }
  //商品单位
  async getSkuDictList(data) {
    const res = await get(`/adminChain/goods/dict/list`, data)
    return res
  }
  //查询商品信息
  async getSkuInfo(data) {
    const res = await get(`goods/sku/detail`, data)
    return res
  }
  //修改商品信息
  async updateSku(data) {
    const res = await post(`/adminChain/goods/sku/update`, data)
    return res
  }
  //获取所有商品
  async getSkuList(data) {
    const res = await get(`/goods/list`, data)
    return res
  }

  //获取所有商品
  async getSkuListLimit(data) {
    const res = await post(`/adminChain/goods/list/page`, data)
    return res
  }

  //增加组合
  async addSkuCombine(data) {
    const res = await post(`goods/skuArray/create`, data)
    return res
  }
  //获取组合版本(包含当前版本)
  async getSkuArrayList(data) {
    const res = await post(`goods/skuArray/list`, data)
    return res
  }
  //获取组合版本
  async getSkuVersionList(data) {
    const res = await post(`goods/skuArray/version/list`, data)
    return res
  }
  //查询已经绑定的供应商接口
  async getAlreadyAddSupplier(data) {
    const res = await get(`/adminChain/goods/getAlreadyAddSupplier`, data)
    return res
  }
  //查询可绑定供应商列表接口
  async getCanAddSupplier(data) {
    const res = await get(`/adminChain/goods/getCanAddSupplier`, data)
    return res
  }
  //绑定供应商
  async addRelationSupplier(data) {
    const res = await post(`/adminChain/goods/addRelationSupplier`, data)
    return res
  }

  //物料品类分类列表
  async getCategoryListML(data) {
    const res = await get(`/adminChain/material/category/list`, data)
    return res
  }

  //物料分类基本信息
  async materialList(data) {
    const res = await get(`/adminChain/material/list`, data)
    return res
  }

  //查询单位列表
  async dictList(data) {
    const res = await get(`/adminChain/goods/dict/list`, { type: 110100 })
    return res
  }

  //创建品类
  async createCategory(data) {
    const res = await post(`/adminChain/material/category/create`, data)
    return res
  }
  //创建物料
  async createMaterial(data) {
    const res = await post(`/adminChain/material/create`, data)
    return res
  }
  //更新物料
  async updateMaterial(data) {
    const res = await post(`/adminChain/material/update`, data)
    return res
  }
  //查询物料基本信息
  async materialInfo(data) {
    const res = await get(`/adminChain/material`, data)
    return res
  }
}

export default new CsMng()
