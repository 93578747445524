<template>
  <div class="filter-big-box">
    <div class="filter-input" @click.stop="onFocus" :class="{ active: isReverse }">
      <span v-if="generateAllFilterTagsArray().length === 0">请选择门店筛选条件</span>
      <div class="filter-tag">
        <el-tag v-if="generateAllFilterTagsArray().length > 0" type="success">{{
          generateAllFilterTagsArray()[0].label
        }}</el-tag>
        <el-tag v-if="generateAllFilterTagsArray().length > 1" class="other-tags-count" type="info"
          >+{{ generateAllFilterTagsArray().length - 1 }}</el-tag
        >
      </div>
      <i class="el-icon-arrow-down" :class="{ 'is-reverse': isReverse }"></i>
    </div>
    <Transition>
      <div v-show="showSelect" class="filter-content">
        <div style="display: flex; flex-direction: column;" @click.stop="">
          <el-form :model="formData">
            <el-form-item label="门店区域"> </el-form-item>
            <el-select placeholder="请选择门店区域" v-model="formData.areas" multiple collapse-tags>
              <template v-for="item in areasData" :key="item.code">
                <el-option :label="item.label" :value="item.code"></el-option>
              </template>
            </el-select>
            <el-form-item label="门店所在城市"> </el-form-item>
            <el-select placeholder="请选择门店所在城市" v-model="formData.citys" multiple collapse-tags>
              <template v-for="item in citysData" :key="item.code">
                <el-option :label="item.label" :value="item.code"></el-option>
              </template>
            </el-select>
            <el-form-item label="性质分类"> </el-form-item>
            <div class="tags" @click="onHandleChange('natures', $event)">
              <tempalte v-for="item in natures" :key="item.value">
                <el-tag :type="item.type">{{ item.label }}</el-tag>
              </tempalte>
            </div>
            <el-form-item label="大小分类"> </el-form-item>
            <div class="tags" @click="onHandleChange('sizes', $event)">
              <tempalte v-for="item in sizes" :key="item.value">
                <el-tag :type="item.type">{{ item.label }}</el-tag>
              </tempalte>
            </div>
            <el-form-item label="等级分类"> </el-form-item>
            <div class="tags" @click="onHandleChange('levels', $event)">
              <tempalte v-for="item in levels" :key="item.value">
                <el-tag :type="item.type">{{ item.label }}</el-tag>
              </tempalte>
            </div>
          </el-form>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { reactive, toRaw, defineExpose, onMounted, ref } from 'vue'
import { onUnmounted } from 'vue'

// 表单数据
const formData = reactive({
  areas: [], // 门店区域
  citys: [], // 门店所在城市
})

//门店区域
const areasData = reactive([
  { code: 1, label: '上海' },
  { code: 2, label: '苏州' },
  { code: 3, label: '南京' },
])

//门店所在城市
const citysData = reactive([
  { code: 1, label: '上海' },
  { code: 2, label: '苏州' },
  { code: 3, label: '南京' },
])
// ags仓库
const tagsWareHouse = reactive({
  naturesTags: [],
  sizesTags: [],
  levelsTags: [],
})

/**
 * 根据tags仓库生成扁平化的tags数组
 * @returns{[]}
 * @example [{"code":1,"label":'加盟'}...]
 */
const generateTagsArray = () => {
  return Object.values(tagsWareHouse).flat()
}

/**
 * 生成所有的过滤tags
 * @return {[]} form + tags 门店区域 + 门店城市 + args仓库
 */
const generateAllFilterTagsArray = () => {
  let fullInfo = getSelectFormFullInfo()
  return Object.values(fullInfo)
    .flat()
    .concat(generateTagsArray())
}

/**
 * 生成所有的过滤tags + from 对象 -->对外暴露获取数据
 * @return {object} form + tags 门店区域 + 门店城市 + tags仓库
 * fullInfo -> 门店区域 + 门店城市
 * tagsWareHouse -》 all tags
 */
const generateAllFilterTagsObj = () => {
  let fullInfo = getSelectFormFullInfo()
  return {
    fullInfo,
    tagsWareHouse,
  }
}
defineExpose({
  generateAllFilterTagsObj,
})

/**
 * 根据选中的城市和区域的code生成全部信息
 * @return{[]}
 * @example [{"code":1,"label":'江苏'}...]
 *
 */
const getSelectFormFullInfo = () => {
  let fullInfo = {
    areasFullInfo: [],
    citysFullInfo: [],
  }
  formData.areas.forEach(code => {
    let area = areasData.find(a => a.code === code)
    fullInfo.areasFullInfo.push(area)
  })

  formData.citys.forEach(code => {
    let city = citysData.find(c => c.code === code)
    fullInfo.citysFullInfo.push(city)
  })

  return fullInfo
}

const showSelect = ref(false) // 展示下方下拉框
const isReverse = ref(false) // 下拉框箭头
/**
 * 输入框获取焦点
 */
const onFocus = () => {
  isReverse.value = true
  showSelect.value = true
}

onMounted(() => {
  hide()
})

onUnmounted(() => {
  document.querySelector('body').removeEventListener('click', bodyEventListener)
})

/**
 * 隐藏下拉框
 */
const hide = () => {
  document.querySelector('body').addEventListener('click', bodyEventListener)
}

/**
 * body事件监听器函数
 */
const bodyEventListener = () => {
  isReverse.value = false
  showSelect.value = false
}

// 性质分类
const natures = reactive([
  {
    label: '加盟',
    value: 1,
    type: 'info',
  },
  {
    label: '直营',
    value: 2,
    type: 'info',
  },
  {
    label: '直营（合作）',
    value: 3,
    type: 'info',
  },
])

// 大小分类
const sizes = reactive([
  {
    label: 'MINI店',
    value: 1,
    type: 'info',
  },
  {
    label: 'MAX店',
    value: 2,
    type: 'info',
  },
])

// 等级分类
const levels = reactive([
  {
    label: 'A',
    value: 1,
    type: 'info',
  },
  {
    label: 'B',
    value: 2,
    type: 'info',
  },
  {
    label: 'C',
    value: 3,
    type: 'info',
  },
])

/**
 * 分类tag点击 变色
 * @param {*} type  natures/sizes/levels 分类类型
 * @param {*} event  事件对象
 */
const onHandleChange = (type, event) => {
  let clickLabel = event.target.outerText
  let clickNode, hasClickNode
  switch (type) {
    case 'natures':
      clickNode = natures.find(n => n.label === clickLabel)
      if (clickNode) {
        // hasClickNode = natures.find(n => n.type === 'success' && n.label !== clickLabel) || {}
        // hasClickNode.type = 'info'
        clickNode.type = clickNode.type === 'success' ? 'info' : 'success'
        setSelectTag('naturesTags', clickNode)
      }
      break
    case 'sizes':
      clickNode = sizes.find(n => n.label === clickLabel)
      if (clickNode) {
        // hasClickNode = sizes.find(n => n.type === 'success' && n.label !== clickLabel) || {}
        // hasClickNode.type = 'info'
        clickNode.type = clickNode.type === 'success' ? 'info' : 'success'
        setSelectTag('sizesTags', clickNode)
      }
      break
    case 'levels':
      clickNode = levels.find(n => n.label === clickLabel)
      if (clickNode) {
        // hasClickNode = levels.find(n => n.type === 'success' && n.label !== clickLabel) || {}
        // hasClickNode.type = 'info'
        clickNode.type = clickNode.type === 'success' ? 'info' : 'success'
        setSelectTag('levelsTags', clickNode)
      }
      break
  }
}

/**
 * 设置选中的tag->将tag添加到tag仓库中便于记录
 * @param {*} type natures/sizes/levels 分类类型
 * @param {*} tag  当前选中的tag
 */
const setSelectTag = (type, tag) => {
  let currentTypeTags = tagsWareHouse[type]
  if (tag.type === 'success') {
    currentTypeTags.push(tag)
  } else {
    let index = currentTypeTags.findIndex(c => c.label === tag.label)
    currentTypeTags.splice(index, 1)
  }
}
</script>

<style lang="scss" scoped>
.filter-input {
  cursor: pointer;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  span {
    opacity: 0.5;
  }
  .filter-tag {
    .other-tags-count {
      margin-left: 10px;
    }
  }
  i {
    transition: all 0.5s ease;
  }
  .is-reverse {
    transform: rotate(-180deg);
  }
}
.active {
  outline: none;
  border-color: #409eff;
}
.filter-content {
  position: absolute;
  z-index: 2;
  width: 280px;
  align-items: center;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 10px 20px;

  .tags {
    .el-tag {
      cursor: pointer;
      margin-right: 10px;
      min-width: 50px;
      text-align: center;
    }
  }
}

// 下拉框动画

.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
.v-enter-from,
.v-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
</style>
