import _axios, { post, get, _delete, requestUrl } from '@/lin/plugin/axios'

class CsMng {
  //用户管理
  async getAuthUserPage(data) {
    return await post(`/adminChain/auth/getAuthUserPage`, data)
  }
  async saveUser(data) {
    return await post(`/adminChain/auth/save`, data)
  }
  async updateUser(data) {
    return await post(`/adminChain/auth/update`, data)
  }
  async deleteUser(data) {
    return await _axios.delete(`/adminChain/auth/delete`, { data })
  }
  async updateState(data) {
    return await post(`/adminChain/auth/updateState`, data)
  }

  //角色管理
  async getAuthRolePage(data) {
    return await post(`/adminChain/role/getAuthRolePage`, data)
  }
  async saveRole(data) {
    return await post(`/adminChain/role/save`, data)
  }
  async infoRole(data) {
    return await get(`/adminChain/role/info/${data}`)
  }
  async updateRole(data) {
    return await post(`/adminChain/role/update`, data)
  }
  async deleteRole(data) {
    return await _axios.delete(`/adminChain/role/delete`, { data })
  }

  //组织
  async getArea(data) {
    return await get('/adminChain/organizational/saas/getArea', data)
  }
  async getOrganizationalList(data) {
    return await post('/adminChain/organizational/saas/getOrganizationalList', data)
  }
  async getOrganizationalByParentCode(data) {
    return await get('/adminChain/organizational/saas/getOrganizationalByParentCode', data)
  }
  async addOrganizational(data) {
    return await post('/adminChain/organizational/saas/addOrganizational', data)
  }
  async updateOrganizational(data) {
    return await post('/adminChain/organizational/saas/updateOrganizational', data)
  }
  async getOrganizationalByCode(data) {
    return await get('/adminChain/organizational/saas/getOrganizationalByCode', data)
  }
  async getOrganizationalListByCode(data) {
    return await get('/adminChain/organizational/saas/getOrganizationalListByCode', data)
  }
  //菜单
  async getAuthMenuPage(data) {
    return await post('/adminChain/menu/getAuthMenuPage', data)
  }
  async deleteMenuPage(data) {
    return await _axios.delete('/adminChain/menu/delete', { data })
  }
  async updateMenuPage(data) {
    return await post('/adminChain/menu/update', data)
  }
  async saveMenuPage(data) {
    return await post('/adminChain/menu/save', data)
  }
  async getAuthMenuList(data) {
    return await post('/adminChain/menu/list', data)
  }

  async getAreaOrganizational(data) {
    return await get('/adminChain/organizational/saas/getAreaOrganizational', data)
  }

  async selectByType(data) {
    return await get(`/adminChain/goods/saas/category/selectByType`, data)
  }
  //赠品组分页查询
  async selectInfoPage(data) {
    return await post(`/adminChain/goods/saas/tbStoreGiveProduct/selectInfoPage`, data)
  }
  //增加赠品组信息
  async saveInfo(data) {
    return await post(`/adminChain/goods/saas/tbStoreGiveProduct/saveInfo`, data)
  }
  //修改赠品组信息
  async updateInfoByCode(data) {
    return await post(`/adminChain/goods/saas/tbStoreGiveProduct/updateInfoByCode`, data)
  }
  //根据code删除赠品组信息
  async deleteByCode(data) {
    return await get(`/adminChain/goods/saas/tbStoreGiveProduct/deleteByCode`, data)
  }

  //获取赠品组管理tab
  async getTableHeader(data) {
    return await get(`/adminChain/goods/saas/tbStoreGiveProduct/selectStoreGradeType`, data)
  }


  //重置密码
  async resetSaasUserSecret(data) {
    return await post(`/adminChain/account/resetSaasUserSecret`, data)
  }

  //门店
  async getStoreList(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']

    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  //获取操作日志
  async getOperateLogList(data) {
    return await post(`/adminChain/saasUser/getList`, data)
  }

  //新菜单
  async getResourceAllList(data, params = {}) {
    return await requestUrl({
      url: '/back/guard/access/resource-all',
      method: 'post',
      data: data,
      params: params
    })
  }

  async getResourceAllTree(data, params = {}) {
    return await requestUrl({
      url: '/back/guard/access/resource-tress',
      method: 'post',
      data: data,
      params: params
    })
  }


  async resourceUpdate(data) {
    return await requestUrl({
      url: '/back/guard/access/resource-save',
      method: 'post',
      data: data,
    })
  }


  async resourceDelete(data) {
    return await requestUrl({
      url: '/back/guard/access/delete',
      method: 'post',
      data: data,
    })
  }


  async resourceRoleUpdate(data) {
    return await requestUrl({
      url: '/back/guard/access/role-resource',
      method: 'post',
      data: data,
    })
  }


}

export default new CsMng()
