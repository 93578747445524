const linCmsUiRouter = {
  route: null,
  name: null,
  title: '员工管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/worker/',
  order: null,
  inNav: true,
  children: [
    {
      title: '总店员工管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/worker-manager/mainworker-manager',
      filePath: 'plugin/worker-manager/view/notice/alert.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '权限角色管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/worker-manager/role-manager',
      filePath: 'plugin/worker-manager/view/notice/loading.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '员工信息',
      type: 'view',
      name: 'Tinymce',
      route: '/worker-manager/workerinfo-manager',
      filePath: 'plugin/worker-manager/view/notice/message.vue',
      inNav: true,
      icon: 'iconfont icon-fuwenbenbianjiqi_gongshi',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
