<!--
 * @Author: your name
 * @Date: 2022-04-28 10:46:15
 * @LastEditTime: 2022-05-19 12:09:17
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/component/base/exportDialog/export-dialog.vue
-->
<template>
  <el-dialog v-model="dialogVisible" title="选择导出字段" width="61%" :before-close="handleClose">
    <div class="export-dialog-box">
      <div class="check-box-list">
        <el-checkbox v-model="checkAll" :indeterminate="isIndeterminate" @change="handleCheckAllChange"
          >选择所有字段
        </el-checkbox>
        <p>基础字段</p>
        <el-checkbox-group v-model="checkedValues" @change="handleCheckedCitiesChange">
          <el-checkbox v-for="item in columnsList" :key="item.value" :label="item.value">{{ item.label }} </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="check-field-list">
        <h2>当前选定的字段</h2>
        <ul>
          <li v-for="item in checkedColumns">
            {{ item.label }}
            <el-icon class="field-icon" :size="16" @click="deleteColumns(item)">
              <close />
            </el-icon>
          </li>
        </ul>
      </div>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="closeExportDialog">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: null,
    },
    columnsList: {
      type: Array,
      default: [],
    },
    exportData: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      checkAll: false,
      isIndeterminate: true,
      checkedColumns: [],
      checkedValues: [],
      columnsList: this.columnsList,
      dialogVisible: false,
    }
  },
  mounted() {},
  methods: {
    findByValue(values) {
      const group = []
      values.forEach(value => {
        const thisValue = this.columnsList.find(item => item.value === value)
        group.push(thisValue)
      })
      return group
    },
    handleCheckAllChange(val) {
      const keys = this.columnsList.map(item => item.value)
      this.checkedColumns = val ? this.columnsList : []
      this.checkedValues = val ? keys : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      this.checkedValues = value
      this.checkedColumns = this.findByValue(value)
      const checkedCount = value.length
      this.checkAll = checkedCount === this.columnsList.length
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.columnsList.length
    },
    deleteColumns(item) {
      this.checkedColumns = this.checkedColumns.filter(elItem => elItem != item)
      this.checkedValues = this.checkedColumns.map(item => item.value)
    },
    openDialog() {
      this.dialogVisible = true
    },
    closeExportDialog() {
      this.exportData(this.checkedValues)
      this.dialogVisible = false
      this.checkedValues = []
      this.checkedColumns = []
    },
  },
}
</script>

<style lang="scss" scoped>
.export-dialog-box {
  display: flex;
  height: 400px;
  border-top: 1px solid EBEEF5;

  .check-box-list {
    flex: 1;

    p {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      margin-top: 27px;
      margin-bottom: 27px;
    }
  }

  .check-field-list {
    width: 329px;
    font-family: PingFangSC-Regular, PingFang SC;
    padding-left: 31px;
    font-size: 18px;
    font-weight: 400;
    border-left: 1px solid #ebeef5;

    div:first-child {
      color: #84898e;
    }

    ul {
      margin-top: 32px;

      li {
        height: 40px;
        line-height: 40px;
        color: #262626;
        position: relative;

        .field-icon {
          position: absolute;
          right: 32px;
          cursor: pointer;
          top: 10px;
        }
      }
    }
  }
}
</style>
