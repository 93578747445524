<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-10-28 11:00:45
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/paymentOrderDayReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportPaymentOrderDay" reportName="日支付账单"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportPaymentOrderDay"
      :emptyText="emptyTableText"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import _Row from 'element-plus/lib/el-row'
import { downLoad } from '../../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  props: {
    auths: Object,
  },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodeList: [],
        orderCode: '',
        payCode: '',
        payTypeList: [],
        status: '',
        isRefund: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'storeName',
          label: '门店名称',
          width: '200px',
        },
        {
          prop: 'openCode',
          label: '场次号',
          width: '200px',
        },
        {
          prop: 'orderCode',
          label: '账单号',
          width: '200px',
        },
        {
          prop: 'payCode',
          label: '网络支付流水号',
          width: '200px',
        },
        {
          prop: 'payTypeTxt',
          label: '支付方式',
          width: '150px',
        },
        {
          prop: 'mtCouponCode',
          label: '美大券号/预定单号',
          width: '230px',
        },
        {
          prop: 'mtUserMobile',
          label: '美大手机号',
          width: '200px',
        },
        {
          prop: 'statusTxt',
          label: '交易状态',
        },
        {
          prop: 'isRefund',
          label: '是否退款',
          width: '160px',
          formatter: val => {
            return val.isRefund === 1 ? '是' : val.isRefund === 0 ? '否' : ''
          },
        },
        {
          prop: 'payPrice',
          label: '交易金额',
        },
        {
          prop: 'serviceCharge',
          label: '手续费',
        },
        {
          prop: 'settledAmount',
          label: '结算金额',
          width: '100px',
        },
        {
          prop: 'points',
          label: '积分',
          width: '100px',
        },
        {
          prop: 'createTime',
          label: '交易时间',
          width: '180px',
        },
        {
          prop: 'reserveCode',
          label: '关联小程序预定单号',
          width: '200px',
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        { label: '场次号', type: 'input', prop: 'openCode', placeholder: '请输入场次号' },
        { label: '账单号', type: 'input', prop: 'orderCode', placeholder: '请输入账单号' },
        {
          label: '网络支付流水号',
          type: 'input',
          labelWidth: '120px',
          prop: 'payCode',
          placeholder: '请输入网络支付流水号',
        },
        {
          label: '支付方式',
          type: 'select',
          placeholder: '请选择支付方式',

          typeProp: 'select',
          children: 'option',
          prop: 'payTypeList',
          options: [],
          multiple: true,
        },
        {
          label: '交易状态',
          placeholder: '请选择交易状态',

          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'status',
          options: [
            { label: '支付成功', value: 1 },
            // { value: 2, label: '支付失败' },
            { value: 3, label: '退款成功' },
            // { value: 4, label: '退款失败' },
          ],
        },
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/export/reportOrderPayList', auth: 'export' },
      ],
    }
  },
  async mounted() {
    this.$route.meta.msg = this.auths
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportPaymentOrderDay(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
      api.paymentList().then(res => {
        this.searchform[5].options = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
    },
    reportPaymentOrderDay(model) {
      this.mergeTotalData(model.pageNum, '消费日支付账单', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!model.payCode && !model.openCode && !model.orderCode && !this.getDays(model.startTime, model.endTime)) {
        return Promise.reject('条件不满足')
      }
    },
    parentSearch(param) {
      const { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode } = param
      this.model = { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      this.mergeTotalData(this.model.pageNum, '消费日支付账单', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode } = param
      this.model = { orderCode, payCode, payTypeList, status, isRefund, storeCodeList, regionCodeList, openCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!Array.isArray(this.model.payTypeList)) {
        this.model.payTypeList = []
      }
      if (
        !this.model.payCode &&
        !this.model.openCode &&
        !this.model.orderCode &&
        !this.getDays(this.model.startTime, this.model.endTime)
      ) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      this.exportData('消费日支付账单', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
