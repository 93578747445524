<template>
  <div style="margin-top: 20px;padding-left: 20px;padding-right: 20px;margin-bottom: 30px;">
    <el-page-header @back="goBack">
      <template #content>
        <div style="display: flex;">
          <p style="margin-right: 10px;">三方供应商名称：{{ data.supplierInfo.supplierName }}</p>
        </div>
      </template>
    </el-page-header>
  </div>
  <el-form :inline="true" :model="data" style="margin-left: 20px;">
    <el-form-item label="空瓶商品名称">
      <el-input placeholder="请输入空瓶商品名称" v-model="data.materielName" clearable></el-input>
    </el-form-item>
    <el-form-item label="三方物料编号">
      <el-input placeholder="请输入三方物料编号" v-model="data.materielCode" clearable></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="queryShops">查询</el-button>
      <el-button @click="resetQuery">重置</el-button>
    </el-form-item>
    <el-form-item>
      <el-button @click="addShop">新增</el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    :highlight-current-row="true"
    v-loading="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" width="100" />
    <el-table-column prop="materielName" label="空瓶商品名称" align="center" />
    <el-table-column prop="materielCode" label="三方物料编号" align="center" />
    <el-table-column prop="spec" label="规格" align="center" />
    <el-table-column prop="unit" label="最小单位" align="center" />
    <el-table-column prop="price" label="空瓶价(元)" align="center" />
    <el-table-column prop="createTime" label="创建时间" align="center" />

    <el-table-column label="操作" width="200" align="center">
      <template #default="scope">
        <el-button link type="text" size="small" @click="setEmptyBottlePrice(scope.row)">设置空瓶价</el-button>
        <el-button link type="text" size="small" @click="deleteRow(scope.row)">移除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total,prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <SelectShops
    v-if="data.addProductVisible"
    :dialogVisible="data.addProductVisible"
    @update-dialogVisible="updateDialogVisible"
    :hasPros="data.tableData"
  ></SelectShops>

  <!--  设置空瓶价-->
  <el-dialog title="设置空瓶价" v-model="data.emptyBottlePayDialogVisible" width="600px" class="dialog-form">
    <el-form label-width="80px" class="dialog-form" :model="data.syncData">
      <el-form-item label="空瓶商品名称:">
        <p>{{ data.emptyBottleObj.materielName }}</p>
      </el-form-item>
      <el-form-item label="三方物料编号:">
        <p>{{ data.emptyBottleObj.materielCode }}</p>
      </el-form-item>
      <el-form-item label="规格:">
        <p>{{ data.emptyBottleObj.spec }}</p>
      </el-form-item>
      <el-form-item label="最小单位:">
        <p>{{ data.emptyBottleObj.unit }}</p>
      </el-form-item>
      <el-form-item label="空瓶价(元):" class="is-required">
        <el-input placeholder="请输入价格" v-model="data.emptyBottleObj.price"></el-input>
      </el-form-item>
    </el-form>
    <div style="display: flex;justify-content: center;">
      <el-button type="primary" @click="handleSureSync">确 定</el-button>
      <el-button @click="data.emptyBottlePayDialogVisible = false">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script setup>
import _ from 'lodash'
import SelectShops from './SelectShops.vue'

import { reactive, onMounted, toRaw, computed, ref, nextTick } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

const data = reactive({
  supplierInfo: {},
  materielName: '',
  materielCode: '',
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  dialogVisible: false,
  accountMsg: {},
  productTypes: [],
  articleTypes: [],
  addProductVisible: false,

  /** 设置空瓶价格  */
  emptyBottlePayDialogVisible: false,
  emptyBottleObj: {},
})

const router = useRouter()

onMounted(() => {
  data.supplierInfo = JSON.parse(sessionStorage.getItem('purchase-info'))
  queryShops()
})

const goBack = () => {
  router.back()
}
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const resetQuery = () => {
  data.currentPage = 1
  data.pageSize = 10
  data.materielCode = ''
  data.materielName = ''
  searchData()
}

// 获取门店列表数据
const searchData = () => {
  let query = {
    supplierCode: data.supplierInfo.code,
    materielCode: data.materielCode,
    materielName: data.materielName,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }

  API.queryThirdPartySupplierEmptyGoodsPage(query).then(res => {
    if (res?.code == 200) {
      data.tableData = res.data?.records || []
      data.total = res.data.total
    }
  })
}
const queryShops = () => {
  data.currentPage = 1
  searchData()
}

//添加
const addShop = () => {
  data.addProductVisible = true
}

const updateDialogVisible = ({ update, search }) => {
  data.addProductVisible = update
  if (search) {
    searchData()
  }
}

//设置空瓶价格
const setEmptyBottlePrice = async row => {
  let res = await API.queryThirdPartySupplierEmptyGoodsDetail({
    materielCode: row.materielCode,
    supplierCode: data.supplierInfo.code,
  })
  if (res.code == 200) {
    data.emptyBottleObj = res.data
    data.emptyBottlePayDialogVisible = true
  } else {
    ElMessage.error(res.msg)
  }
}

const handleSureSync = async () => {
  //设置空评价，范围0.01~9999.99，支持两位小数
  let reg = /^\d+(\.\d{1,2})?$/
  if (!reg.test(data.emptyBottleObj.price) || data.emptyBottleObj.price < 0.01 || data.emptyBottleObj.price > 9999.99) {
    ElMessage.warning('请设置正确的的空瓶价，范围0.01~9999.99，支持两位小数')
    return
  }

  let res = await API.updatePriceByCode(data.emptyBottleObj)
  if (res.code == 200) {
    data.emptyBottleObj = {}
    data.emptyBottlePayDialogVisible = false
    ElMessage.success('操作成功')
    searchData()
  } else {
    ElMessage.error(res.msg)
  }
}

const deleteRow = async row => {
  let res = await API.deleteTbThirdPartySupplierEmptyGoods({
    supplierCode: data.supplierInfo.code,
    materielCode: row.materielCode,
  })
  if (res.code == 200) {
    ElMessage.success('操作成功')
    searchData()
  } else {
    ElMessage.error(res.msg)
  }
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchData()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchData()
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    span {
      width: 100px;
      margin-left: 20px;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
</style>
