<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-31 16:18:22
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-09-02 15:35:55
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/bindingGoods.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <h1 class="content-header">绑定商品选择</h1>
  <div class="commodity-content-checkbox">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane v-for="(item, index) in dataList" :label="item.categoryCodeName" :name="index">
        <p v-if="item.taxState === 1" class="tax-inclusive">（含税<span>|</span>税点：{{ item.taxPoint }}）</p>
        <p v-else class="tax-inclusive">（不含税）</p>
        <el-checkbox-group v-model="checkList" @change="checkDrinks">
          <el-checkbox v-for="(childItem, index) in item.goodsMatterList" :label="childItem.skuName" name="type" />
        </el-checkbox-group>
      </el-tab-pane>
    </el-tabs>
  </div>
  <h1 class="content-header">绑定商品确认</h1>
  <div class="water-list-content" v-for="item in matterList">
    <h1>{{ item.categoryCodeName }}</h1>
    <el-tag
      v-for="tag in item.checkedItems"
      :key="tag.categoryCode1"
      class="mx-1"
      closable
      :type="tag.type"
      @close="deleteTag(tag)"
    >
      {{ tag.skuName }}
    </el-tag>
  </div>
  <el-button type="primary" class="confirm-edit" @click="confirmChange">确认修改</el-button>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../service/index'
export default {
  props: {
    bindType: {
      type: String,
      default: () => {},
    },
    categoryListWithMatterList: {
      type: Array,
      default: [],
    },
    supplierCode: {
      type: String,
      default: '',
    },
    supplierName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      activeName: 0,
      checkList: [],
      tags: [],
      categoryList: [],
      firstStep: true,
      dataList: [],
      matterList: [],
      model: {
        pageNum: 1,
        pageSize: 10,
        supplierCode: this.$route.query.id,
        categoryCode1: '',
        skuCode: '',
      },
      tableData: [],
    }
  },
  watch: {
    categoryListWithMatterList: function(newData, oldData) {
      //newData是更新后的数据
      //oldData是旧数据
      this.dataList = newData
      if (this.bindType !== 'add') {
        this.dataList.map(item => {
          item.goodsMatterList.map(childrenItem => {
            if (childrenItem.selected === 1) {
              this.checkList.push(childrenItem.skuName)
            }
          })
        })
        this.matterList = this.dataList.map(category => {
          const checkedItems = category.goodsMatterList.filter(item => this.checkList.includes(item.skuName))
          return {
            categoryCodeName: category.categoryCodeName,
            checkedItems: checkedItems,
          }
        })
      }
    },
  },
  mounted() {},
  methods: {
    checkDrinks() {
      this.matterList = this.dataList.map(category => {
        const checkedItems = category.goodsMatterList.filter(item => this.checkList.includes(item.skuName))
        return {
          categoryCodeName: category.categoryCodeName,
          checkedItems: checkedItems,
        }
      })
    },
    deleteTag(tag) {
      this.matterList.forEach(item => {
        item.checkedItems.forEach((childrenItem, index) => {
          if (childrenItem.skuName === tag.skuName) {
            item.checkedItems.splice(index, 1)
            return
          }
        })
      })
      this.checkList.forEach((item, index) => {
        if (item === tag.skuName) {
          this.checkList.splice(index, 1)
        }
      })
    },
    checkBoxMatterList() {},
    confirmChange() {
      let list = []
      this.matterList.forEach(item => {
        item.checkedItems.forEach(childrenItem => {
          childrenItem.supplierName = this.supplierName
          childrenItem.supplierCode = this.supplierCode
          list.push(childrenItem)
        })
      })
      api.batchAddMatterList(list).then(res => {
        if (res.code === '200') {
          if (this.bindType === 'add') {
            ElMessage({
              type: 'success',
              message: `新增成功`,
            })
          } else {
            ElMessage({
              type: 'success',
              message: `修改成功`,
            })
          }
          this.$router.go(-1)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content-header {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  height: 50px;
  line-height: 50px;
}

.commodity-content-checkbox {
  min-height: 260px;
  border-bottom: 1px solid #ebeef5;

  .tax-inclusive {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fb5d5d;
    height: 36px;
    line-height: 20px;

    span {
      display: inline-block;
      width: 1px;
      margin-left: 10px;
      margin-right: 10px;
      color: #979595;
    }
  }

  .el-checkbox-group {
    line-height: 26px;
  }
}

.water-list-content {
  height: 150px;

  h1 {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    height: 42px;
    line-height: 42px;
  }

  ::v-deep .el-tag {
    margin-right: 10px !important;
  }
}

.confirm-edit {
  position: fixed;
  right: 100px;
  bottom: 60px;
}
</style>
