<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-10-16 18:31:48
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/americanSecRecords.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="emptyTableText"
      :getTableData="reportRoomIncomeDetail"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'
export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        roomName: '',
        roomTypeCode: '',
        comboCodeName: '',
        billCode: '',
        openCode: '',
        orderCode: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'couponCode',
          label: '券号',
          width: '100px',
        },
        {
          prop: 'couponType',
          label: '券类型',
          width: '100px',
        },
        {
          prop: 'verifyTime',
          label: '验券时间',
          width: '200px',
        },
        {
          prop: 'storeCode',
          label: '验劵门店编号',
          width: '100px',
        },
        {
          prop: 'storeName',
          label: '验劵门店名称',
          width: '150px',
        },
        {
          prop: 'verifyPrice',
          label: '抵扣金额',
          width: '100px',
        },
        {
          prop: 'openCode',
          label: '使用场次',
          width: '180px',
        },
        {
          prop: 'billCode',
          label: '关联结算单',
          width: '180px',
        },
        {
          prop: 'roomName',
          label: '包厢',
        },
        {
          prop: 'couponTitle',
          label: '券名称',
          width: '200px',
        },
        {
          prop: 'dealGroupId',
          label: '券id',
          width: '100px',
        },
        {
          prop: 'couponPrice',
          label: '券面额',
        },
        {
          prop: 'promoAmount',
          label: '商家优惠金额',
          width: '100px',
          formatter: row => {
            return row.promoAmount || 0
          },
        },
        {
          prop: 'verifyStatus',
          label: '状态',
          width: '100px',
        },

        {
          prop: 'revokeVerifyTime',
          label: '撤销时间',
          width: '200px',
        },
      ],
      pagination: {},
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '状态',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'verifyStatus',
          placeholder: '请选择状态',
          options: [
            {
              label: '已核销',
              value: 1,
            },
            {
              label: '撤销核销',
              value: 2,
            },
          ],
        },
        {
          label: '券类型',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'couponType',
          placeholder: '请选择券类型',
          options: [
            {
              label: '团购券',
              value: 1,
            },
            {
              label: '次卡',
              value: 2,
            },
          ],
        },
        { label: '券号', type: 'input', prop: 'couponCode', placeholder: '请输入券号' },
        { label: '券ID', type: 'input', prop: 'dealGroupId', placeholder: '请输入券ID' },
        {
          label: '验券时间',
          type: 'date-picker',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/coupon/mtVerify/export', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      await this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
    },
    verifyModel(model) {
      if (!this.getDays(model.verifyStartTime, model.verifyEndTime)) {
        return Promise.reject('条件不满足')
      }
    },
    reportRoomIncomeDetail(model) {
      this.mergeTotalData(model.pageNum, '美大验券记录', () => {
        return this.verifyModel(model)
      })
    },
    parentSearch(param) {
      const { couponCode, couponType, dealGroupId, storeCodeList, regionCodeList, verifyStatus } = param
      this.model = {
        couponCode,
        couponType,
        dealGroupId,
        storeCodeList,
        regionCodeList,
        verifyStatus,
      }
      this.model.verifyStartTime = param.time ? param.time[0] : ''
      this.model.verifyEndTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '美大验券记录', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }

      const { couponCode, couponType, dealGroupId, storeCodeList, regionCodeList, verifyStatus } = param
      this.model = {
        couponCode,
        couponType,
        dealGroupId,
        storeCodeList,
        regionCodeList,
        verifyStatus,
      }
      this.model.verifyStartTime = param.time ? param.time[0] : ''
      this.model.verifyEndTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!this.getDays(this.model.verifyStartTime, this.model.verifyEndTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      this.exportData('美大验券记录', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
