<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-17 14:40:54
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-08-31 10:40:45
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/supplyCommodity.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="supply-commodity-content">
    <div class="search-table-info">
      <div>
        <el-input v-model="commodityName" placeholder="请输入商品名称搜索" class="input-search-commodity">
          <template #append>
            <el-icon>
              <search />
            </el-icon>
          </template>
        </el-input>
        <el-button type="primary" @click="searchTable">查询</el-button>
        <el-button @click="resetSearch">重置</el-button>
      </div>
      <div>
        <el-button type="primary" @click="editCommodity">修改商品</el-button>
      </div>
    </div>
    <el-table :data="tableData" class="el-table-header-gap">
      <el-table-column prop="skuCode" label="商品ID" />
      <el-table-column prop="skuName" label="商品名称" />
      <el-table-column prop="categoryName" label="所属品类" />
      <el-table-column prop="skuPic" label="商品图片">
        <template #default="scope">
          <el-image
            style="width: 100px; height: 100px"
            :src="scope.row.skuPic"
            fit="contain"
            :preview-src-list="[scope.row.skuPic]"
          />
        </template>
      </el-table-column>
      <el-table-column prop="recoveryPrice" label="空瓶回收价" />
      <el-table-column prop="recoveryUnit" label="空瓶回收单位" />
      <el-table-column prop="stockPrice" label="进货价" />
      <el-table-column prop="stockUnit" label="进货单位" />
      <el-table-column prop="packageCount" label="包装数量" />
      <el-table-column label="操作" width="150">
        <template #default="scope">
          <el-button type="text" @click="editItem(scope.$index, scope.row)">修改</el-button>
          <el-button type="text" @click="deleteItem(scope.$index, scope.row)" class="delete-color">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
    <el-drawer
      v-model="editModal"
      title="苏州信投店-A02/小包"
      size="35%"
      :direction="direction"
      :with-header="false"
      :before-close="handleClose"
    >
      <div class="edit-commdity-info">
        <div class="info-left">
          <h1>
            修改商品<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="editModal = false">
              <close />
            </el-icon>
          </h1>
          <div class="info-line"></div>
          <ul class="commdity-list">
            <li><span>商品ID：</span>{{ rowDetail.skuCode }}</li>
            <li><span>商品名称：</span>{{ rowDetail.skuName }}</li>
            <li><span>所属品类：</span>{{ rowDetail.categoryName }}</li>
          </ul>
          <el-form :model="form" label-width="90px" label-position="left">
            <el-form-item label="进货价">
              <el-col :span="6">
                <el-input v-model="form.stockPrice" />
              </el-col>
              <el-col :span="2" class="form-unit">
                元
              </el-col>
            </el-form-item>
            <el-form-item label="进货单位">
              <el-select v-model="form.stockUnit" placeholder="请选择进货单位">
                <el-option v-for="item in unitList" :label="item.name" :value="item.name" />
              </el-select>
            </el-form-item>
            <el-form-item label="空瓶回收价">
              <el-col :span="6">
                <el-input v-model="form.recoveryPrice" />
              </el-col>
              <el-col :span="2" class="form-unit">
                元
              </el-col>
            </el-form-item>
            <el-form-item label="空瓶回收单位">
              <el-select v-model="form.recoveryUnit" placeholder="请选择回收单位">
                <el-option label="瓶" value="瓶" />
                <el-option label="箱" value="箱" />
              </el-select>
            </el-form-item>
            <el-form-item label="包装数量">
              <el-input-number v-model="form.packageCount" />
            </el-form-item>
            <div class="commdity-list-img">
              <h2>商品图片</h2>
              <ul>
                <li>
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="rowDetail.skuPic"
                    fit="contain"
                    :preview-src-list="[rowDetail.skuPic]"
                  />
                </li>
              </ul>
            </div>
            <el-button type="primary" @click="onSubmit" class="confirm-edit">确认修改</el-button>
          </el-form>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import Pagination from '../../component/pagination.vue'
import api from '../../service/index'
export default {
  components: { Pagination },
  props: {
    code: {
      type: String,
      default: '',
    },
    supplierName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      commodityName: '',
      editModal: false,
      tableData: [],
      totalRecords: null,
      totalPage: null,
      model: {
        pageNum: 1,
        pageSize: 10,
        supplierCode: this.code,
        categoryCode1: '',
        skuName: '',
      },
      form: {
        stockPrice: '',
        stockUnit: '',
        recoveryPrice: '',
        recoveryUnit: '',
        packageCount: '',
        code: '',
      },
      rowDetail: {},
      unitList: [], //进货单位List
    }
  },
  mounted() {
    this.getTableData(this.model)
    this.getUnit()
  },
  methods: {
    getTableData(model) {
      api.getMatterListPages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    async getUnit() {
      const res = await api.unitList()
      this.unitList = res.data
    },
    searchTable() {
      this.model.skuName = this.commodityName
      this.getTableData(this.model)
    },
    resetSearch() {
      this.commodityName = ''
      this.model.skuName = ''
      this.model.pageNum = 1
      this.getTableData(this.model)
    },
    editCommodity() {
      this.$router.push({
        path: '/supplyChain-manager/supplierManagement/modifyGoods',
        query: {
          id: this.code,
          type: 1,
          name: this.$route.query.name,
        },
      })
    },
    editItem(index, row) {
      this.editModal = true
      this.rowDetail = row
      const { stockPrice, stockUnit, recoveryPrice, recoveryUnit, code, packageCount } = row
      this.form = { ...this.form, stockPrice, stockUnit, recoveryPrice, recoveryUnit, code, packageCount }
    },
    onSubmit() {
      api.updateMatter(this.form).then(res => {
        if (res.code !== '200') {
          ElMessage({
            type: 'error',
            message: res.msg,
          })
          return
        }
        ElMessage({
          type: 'success',
          message: `修改成功`,
        })
        this.getTableData(this.model)
        this.editModal = false
      })
    },
    deleteItem(index, row) {
      api.deleteMatter(row.code).then(res => {
        ElMessage({
          type: 'success',
          message: `删除成功`,
        })
        this.getTableData(this.model)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.supply-commodity-content {
  .delete-color {
    color: #ff5959;
  }

  .search-table-info {
    display: flex;
    margin-bottom: 16px;

    div {
      flex: 1;

      .input-search-commodity {
        width: 300px;
        margin-right: 20px;
      }
    }

    div:last-child {
      text-align: right;
    }
  }

  .edit-commdity-info {
    padding: 0 40px;
    position: relative;
    height: 100%;

    .confirm-edit {
      position: absolute;
      bottom: 24px;
      right: 40px;
    }

    .form-unit {
      padding-left: 20px;
    }

    .commdity-list {
      margin-bottom: 40px;

      li {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
      }
    }

    .commdity-list-img {
      border-top: 1px solid #ebeef5;

      h2 {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        height: 66px;
        line-height: 66px;
      }

      li {
        display: inline-block;
        width: 180px;
        height: 180px;
        border-radius: 8px;
      }

      li:not(:first-child) {
        margin-left: 24px;
      }
    }

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .close-icon {
        position: absolute;
        top: 20px;
        right: 0px;
        cursor: pointer;
      }
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }
  }
}
</style>
