<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-05 11:35:45
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-09-21 10:18:45
 * @FilePath: /saas/src/plugin/finance-reconciliation/view/report_module/businessDailyReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="business-content-box">
    <el-table :data="tableData" style="width: 100%" :span-method="arraySpanMethod" :cell-style="rowStyle">
      <!-- 日期 -->
      <el-table-column prop="date" label="日期" width="110" align="center"> </el-table-column>
      <!-- 商户号 -->
      <el-table-column width="120">
        <el-table-column prop="name" label="商户号账号" width="110" align="center"> </el-table-column>
      </el-table-column>
      <!-- 恒优惠 -->
      <el-table-column label="恒优惠商户号余额(前一天17.00获取虚拟账户余额）" align="center">
        <el-table-column prop="province" label="上期期末余额" width="120" align="center"> </el-table-column>
        <el-table-column prop="city" label="进账金额" width="120" align="center"> </el-table-column>
        <el-table-column prop="address" label="转账金额" width="120" align="center"> </el-table-column>
      </el-table-column>
      <!-- 期末余额 -->
      <el-table-column width="120">
        <el-table-column prop="name" label="期末余额" width="110" align="center"> </el-table-column>
      </el-table-column>
      <!-- 省心系统 -->
      <el-table-column prop="name" label="省心系统期末余额(0点获取)" width="120" align="center"> </el-table-column>
      <!-- 省心流水 -->
      <el-table-column prop="name" label="省心当日流水订单" width="120" align="center"> </el-table-column>
      <!-- 手续费 -->
      <el-table-column width="110" label="手续费(根据订单量计算)" align="center">
        <el-table-column prop="name" label="高德订单手续费(0.6%)" width="110" align="center"> </el-table-column>
        <el-table-column prop="name" label="腾讯订单手续费(0.72%)" width="110" align="center"> </el-table-column>
        <el-table-column prop="name" label="PP停车(0.22%)" width="110" align="center"> </el-table-column>
      </el-table-column>
      <!-- 总账 -->
      <el-table-column width="200">
        <el-table-column
          prop="name"
          label="总账金额 富民商户号期末金额+省心期末余额+省心当日流水订单"
          width="200"
          align="center"
        >
        </el-table-column>
      </el-table-column>
      <!-- 进账差异 -->
      <el-table-column width="150">
        <el-table-column prop="name" label="进账差异(进账金额+手续费+转账)" width="150" align="center">
        </el-table-column>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: 'groupOfCustomers',
  data() {
    return {
      tableData: [
        //数据源
        {
          date: '3月8号',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄0',
          zip: 200333,
        },
        {
          date: '3月8号',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄1',
          zip: 200333,
        },
        {
          date: '',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄2',
          zip: 200333,
        },
        {
          date: '3月9号',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄3',
          zip: 200333,
        },
        {
          date: '3月10号',
          name: '王小虎',
          province: '上海',
          city: '普陀区',
          address: '上海市普陀区金沙江路 1518 弄4',
          zip: 200333,
        },
      ],
    }
  },
  methods: {
    // 表格居中
    rowStyle() {
      return 'text-align:center'
    },
    // 表格合并,需要合并的地方是第7列到第12列的第1行,数组下标是从0开始,所以行是0开始算,列是从7列开始算
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      console.log('row, column, rowIndex, columnIndex  :>> ', row, column, rowIndex, columnIndex)

      if (
        columnIndex === 7 ||
        columnIndex === 6 ||
        columnIndex === 8 ||
        columnIndex === 9 ||
        columnIndex === 10 ||
        columnIndex === 11 ||
        columnIndex === 12
      ) {
        if (rowIndex === 0 || rowIndex === 2) {
          return [2, 1]
        } else if (rowIndex === 1) {
          return [0, 0]
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.business-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
}
</style>
