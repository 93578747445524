import _axios, { post, get } from '@/lin/plugin/axios'
import axios from 'axios'

class CsMng {
  //门店
  async getStoreList(data) {
    return await post('/adminChain/store/saas/getStoreList', data)
  }
  //获取门店区域列表
  async getStoreCodeList(data) {
    return await get(`/adminChain/store/saas/getStoreCodeList`, data)
  }
  //获取门店列表-分页
  async getStoreListPages(data) {
    return await post(`/adminChain/store/saas/getStoreListPages`, data)
  }

  //获取门店列表
  async getStoreListByName(data) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']
    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //删除门店信息
  async deleteStoreMsg(data) {
    return await _axios.delete(`/adminChain/store/saas/deleteStoreStore/${data}`)
  }

  //获取地区地址省市区三级嵌套列表
  async getAreaWithChildList(data) {
    return await get('/adminChain/store/saas/getAreaWithChildList', data)
  }

  //获取门店详情
  async getStoreStoreByCode(data) {
    return await get('/adminChain/store/saas/getStoreStoreByCode', data)
  }

  //新增门店
  async addStoreStore(data) {
    return await post(`/adminChain/store/saas/addStoreStore`, data)
  }

  //  编辑门店
  async modifyStoreStore(data) {
    return await post(`/adminChain/store/saas/modifyStoreStore`, data)
  }

  //获取门店区域列表
  async getAreaOrganizational(data) {
    return await get('/adminChain/organizational/saas/getAreaOrganizational', data)
  }

  async getOrganizationalByParentCode(data) {
    return await get('/adminChain/organizational/saas/getOrganizationalByParentCode', data)
  }

  async getArea(data) {
    return await get('/adminChain/organizational/saas/getArea', data)
  }
  async getCheckArea(data) {
    return await get('/adminChain/organizational/saas/getCheckArea', data)
  }
  async getCheckAreaOrganizational(data) {
    return await get('/adminChain/organizational/saas/getCheckAreaOrganizational', data)
  }

  //获取门店支付配置
  async getStorePayPreference(data) {
    return await get('/adminChain/pay/saas/getStorePayPreference', data)
  }
  //添加门店支付配置
  async addStorePayPreference(data) {
    return await post('/adminChain/pay/saas/addStorePayPreference', data)
  }
  //更新门店支付配置
  async updateStorePayPreference(data) {
    return await post('/adminChain/pay/saas/updateStorePayPreference', data)
  }

  // 客户来源管理
  //分页查询
  selectInfoPage(data) {
    return post('/adminChain/user/saas/userSource/selectInfoPage', data)
  }
  //增加
  saveInfo(data) {
    return post('/adminChain/user/saas/userSource/saveInfo', data)
  }
  //修改
  updateInfoByCode(data) {
    return post('/adminChain/user/saas/userSource/updateInfoByCode', data)
  }
  //删除
  deleteByCode(data) {
    return get('/adminChain/user/saas/userSource/deleteByCode', data)
  }

  //查询所有支付方式
  getPayList() {
    return get('/adminChain/user/saas/payment/list')
  }

  //排序
  batchSort(data) {
    return post('/adminChain/user/saas/userSource/batchSort', data)
  }
  //绑定预订来源下拉框
  predetermine() {
    return post('/adminChain/user/saas/userSource/predetermine')
  }

  //删除门店分类类型
  storeTypeDeleteByCode(data) {
    return get('/adminChain/store/saas/storeType/deleteByCode', data)
  }
  //增加门店分类类型
  storeTypeSaveInfo(data) {
    return post('/adminChain/store/saas/storeType/saveInfo', data)
  }
  //查询门店分类类型
  storeTypeSelectInfoPage() {
    return get('/adminChain/store/saas/storeType/selectInfoPage')
  }
  //修改门店分类类型
  storeTypeUpdateInfoByCode(data) {
    return post('/adminChain/store/saas/storeType/updateInfoByCode', data)
  }
  //查看当前类型下是否绑定门店
  selectByTypeName(data) {
    return get('/adminChain/store/saas/storeStoreType/selectByTypeName', data)
  }

  //门店区域
  //修改门店区域关联门店信息
  updateRegionList(data) {
    return post('/adminChain/store/saas/storeRegionConfig/updateRegionList', data)
  }
  //新增门店区域关联门店信息
  saveRegionList(data) {
    return post('/adminChain/store/saas/storeRegionConfig/saveRegionList', data)
  }
  //查询门店区域关联门店详情
  getRegionList(data) {
    return get('/adminChain/store/saas/storeRegionConfig/getRegionList', data)
  }
  //根据code删除门店信息
  deleteRegionByRegionCode(data) {
    return get('/adminChain/store/saas/storeRegionConfig/deleteRegionByRegionCode', data)
  }

  //缴费
  subscription(data) {
    return post('/adminChain/store/saas/subscription', data)
  }
  subscriptionOrderList(data) {
    return post('/adminChain/store/saas/subscription/order/list', data)
  }

  // 配置管理 -- 操作
  addOrUpdateStoreConfig(data) {
    return post('/adminChain/goods/saas/store/config/addOrUpdateStoreConfig', data)
  }
  // 配置管理 -- 查询
  queryStoreConfig(data) {
    return post('/adminChain/goods/saas/store/config/queryStoreConfig', data)
  }
  // 配置管理 -- 获取配置
  getLibrary(data) {
    return get('/adminChain/custom-library/get', data)
  }
  // 配置管理 -- 编辑配置
  updateLibrary(data) {
    return post('/adminChain/custom-library/update', data)
  }
  // 上传企业微信二维码
  updateQrCodeUrl(data) {
    return post('/adminChain/store/saas/updateQrCodeUrl', data)
  }

  changeMerchantNo(data) {
    return post('/adminChain/pay/saas/changeMerchantNo', data)
  }

  //权益金比例
  storeEquityAmountConfigInset(data) {
    return post('/adminChain/storeEquityAmountConfig/insert', data)
  }
  storeEquityAmountConfigUpdate(data) {
    return post('/adminChain/storeEquityAmountConfig/update', data)
  }
  //权益金
  storeEquityAmountConfigList(data) {
    return post('/adminChain/storeEquityAmountConfig/pageList', data)
  }
}

export default new CsMng()
