<template>
  <el-dialog v-model="visible" :title="'配置管理'" width="60%" :before-close="handleClose">
    <el-form class="configurationForm" :model="form" label-position="right" label-width="120px">
      <div class="main-wrap">
        <div class="code flex">
          <div>门店编号：</div>
          <div>{{ specRowMsg.code }}</div>
        </div>
        <div class="name flex">
          <div>门店名称：</div>
          <div>{{ specRowMsg.name }}</div>
        </div>
      </div>
      <div v-for="item in configruationForm" :key="item.code">
        <el-form-item v-if="item.configType === 1" :label="item.name">
          <el-switch
            v-model="item.isSwitch"
            @change="handleChange(item)"
            :disabled="
              item.code == '500121' ||
                item.code == '500101' ||
                item.code == '500123' ||
                item.code == '500125' ||
                item.code == '500114' ||
                item.code == '500115'
            "
          />
        </el-form-item>
        <el-form-item v-if="item.configType === 2" :label="item.name">
          <el-radio-group v-model="item.isSwitch" class="ml-4" @change="handleChange(item)">
            <el-radio v-for="ii in item.choiceList" :key="ii.value" :label="ii.value" size="large">{{
              ii.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item v-if="(item.isSwitch == 1 || item.isSwitch == 2) && item.name == '库文件上传'">
          <el-radio-group v-model="item.isStore" @change="handleChange(item)">
            <el-radio v-for="ii in item.storeList" :key="ii.value" :label="ii.value" size="large">{{
              ii.label
            }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <div class="uploadContainer" v-if="item.name == '库文件上传' && item.isStore == 2">
          <el-form-item label="上传删除库文件">
            <el-upload
              v-model:file-list="delFileList"
              :limit="1"
              action="#"
              :before-upload="beforeUpload"
              :http-request="uploadDelImg"
              :on-remove="handleDelRemove"
              :show-file-list="true"
            >
              <div class="avatar-uploader">
                <el-icon size="26" color="#817777" class="avatar-uploader-icon"><Plus /></el-icon>
              </div>
              <div class="el-upload__tip text-red">
                只能上传.txt文件
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item label="上传全量库文件">
            <el-upload
              v-model:file-list="fulFileList"
              :limit="1"
              action="#"
              :before-upload="beforeUpload"
              :http-request="uploadImg"
              :on-remove="handleFulRemove"
              :show-file-list="true"
            >
              <div class="avatar-uploader">
                <el-icon size="26" color="#817777" class="avatar-uploader-icon"><Plus /></el-icon>
              </div>
              <div class="el-upload__tip text-red">
                只能上传.txt文件
              </div>
            </el-upload>
          </el-form-item>
        </div>
        <div style="text-align: left;margin-left: 220px;">
          <el-button @click="handleUpload" v-if="item.name == '库文件上传' && item.isStore == 2" type="primary"
            >保存文件</el-button
          >
        </div>
      </div>
    </el-form>
  </el-dialog>
</template>
<script setup>
import { ref, defineEmit, defineProps, computed, reactive, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import API from '@/plugin/shop-manager/service/api.js'
import { client, headers } from '@/lin/util/upload.js'
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  specRowMsg: {
    type: Object,
    default: () => {},
  },
})

let configruationForm = ref([])
const form = reactive({
  status: false,
})
const enmuType = {
  1: '开关',
  2: '单选框',
  3: '复选框',
  4: '下拉框',
}

const fullFileUrl = ref('')
const deleteFileUrl = ref('')
const emits = defineEmit('update-dialogVisible')

const visible = computed(() => {
  return props.dialogVisible
})

const handleClose = () => {
  emits('update-dialogVisible', { update: false, search: false })
}

onMounted(async () => {
  getList()
  getUploadData()
})

let delFileList = ref([])
let fulFileList = ref([])
const getUploadData = async () => {
  const params = { storeCode: props.specRowMsg.code }
  const res = await API.getLibrary(params)
  if (res.code == '200') {
    deleteFileUrl.value = res.data?.deleteFileUrl
    fullFileUrl.value = res.data?.fullFileUrl

    delFileList.value = deleteFileUrl.value
      ? [
          {
            name: deleteFileUrl.value,
            url: deleteFileUrl.value,
          },
        ]
      : []
    fulFileList.value = fullFileUrl.value
      ? [
          {
            name: fullFileUrl.value,
            url: fullFileUrl.value,
          },
        ]
      : []
  }
}

// 删除列表
const handleDelRemove = () => {
  deleteFileUrl.value = ''
  delFileList.value = []
}
// 删除全量列表
const handleFulRemove = () => {
  fullFileUrl.value = ''
  fulFileList.value = []
}

const getList = async () => {
  const params = {
    type: 50010,
    storeCode: props.specRowMsg.code,
  }
  const res = await API.queryStoreConfig(params)
  if (res.code === '200') {
    const a = [
      { label: 'V1.0', value: 0 },
      { label: 'V2.0', value: 1 },
    ]
    const b = [
      { label: '扫呗', value: 0 },
      { label: '收钱吧', value: 2 },
    ]
    const store = [
      { label: '总部', value: 1 },
      { label: '门店', value: 2 },
    ]
    configruationForm.value =
      res.data.map(item => {
        return Object.assign(item, {
          isSwitch:
            item.configType === 2
              ? item.isSwitch
              : item.configType === 1 && item.name !== '库文件上传'
              ? item.isSwitch === 1
                ? true
                : false
              : item.isSwitch > 0
              ? true
              : false,
          choiceList:
            item.configType === 2 && item.name === '支付通道'
              ? b
              : item.configType === 2 && item.name === '星聚会内容管理'
              ? a
              : undefined,
          storeList: (item.configType === 1 || item.configType === 2) && item.name === '库文件上传' ? store : undefined,
          isStore: (item.isSwitch == 1 || item.isSwitch == 2) && item.name === '库文件上传' ? item.isSwitch : undefined,
        })
      }) || []
  }
}
const handleChange = async row => {
  let params = {
    dictName: row.name,
    dictCode: row.code,
    storeCode: props.specRowMsg.code,
  }
  if (row.configType !== 2) {
    params = {
      ...params,
      isSwitch: row.isStore && row.isSwitch ? row.isStore : row.isSwitch ? 1 : 0,
    }
  } else {
    params = {
      ...params,
      isSwitch: row.isSwitch,
    }
  }
  const res = await API.addOrUpdateStoreConfig(params)
  if (res.code === '200') {
    ElMessage.success(res.msg)
    getList()
  } else {
    ElMessage.error(res.msg)
  }
}

const uploadDelImg = file => {
  if (checkImg(file)) {
    uploadIMgToClient(file, '1')
  }
}
const uploadImg = file => {
  if (checkImg(file)) {
    uploadIMgToClient(file, '2')
  }
}
const checkImg = file => {
  let type = file.file.type
  if (type !== 'text/plain') {
    ElMessage.error('上传文件格式不是.txt')
    return false
  }
  return true
}
const beforeUpload = rawFile => {
  if (rawFile.type !== 'text/plain') {
    ElMessage.error('上传文件格式不是.txt')
    return false
  }
  return true
}
const getTime = () => {
  const d = new Date()
  const year = d.getFullYear()
  const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
  const day = d.getDate()
  const hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
  const minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
  const second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
  const time = `${year}${month}${day}${hour}${minute}${second}`
  return time
}

const uploadIMgToClient = (file, key) => {
  let fileName =
    key == '1' ? `${props.specRowMsg.code}_delete_${getTime()}.txt` : `${props.specRowMsg.code}_total_${getTime()}.txt`
  client()
    .put('/song/library/' + fileName, file.file, { headers })
    .then(res => {
      const url = res.res?.requestUrls[0]
      const urlResult = 'https://oss.szxjhui.com/song/library' + url.split('/song/library')[1]
      key == '1' ? (deleteFileUrl.value = urlResult) : (fullFileUrl.value = urlResult)
    })
}

const handleUpload = async () => {
  const params = {
    storeCode: props.specRowMsg.code,
    deleteFileUrl: deleteFileUrl.value,
    fullFileUrl: fullFileUrl.value,
  }
  const res = await API.updateLibrary(params)
  if (res.code == '200') {
    ElMessage.success('保存成功')
  }
}
</script>
<style lang="scss" scoped>
.configurationForm {
  ::v-deep .el-form-item__label {
    width: 220px !important;
  }
  .main-wrap {
    display: flex;
    margin-bottom: 50px;
    margin-left: 90px;
    .code {
      display: flex;
      margin-right: 100px;
    }
    .name {
      display: flex;
    }
  }
}
.avatar-uploader {
  width: 160px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
}
.avatar-uploader-icon {
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
.avatar {
  width: 80px;
  height: 80px;
}
.uploadContainer {
  // display: flex;
}
::v-deep .el-upload-list {
  width: 500px;
}
</style>
