<template>
  <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 10px 0  10px;">
    <el-form-item label="组合名称">
      <el-input placeholder="请输入组合名称" v-model="data.name" @keyup.enter="searchLists"></el-input>
    </el-form-item>
    <el-form-item label="渠道">
      <el-select
        clearable
        v-model="data.channel"
        class="search-select"
        placeholder="选择渠道"
        @change="searchLists"
        style="width: 200px;"
      >
        <el-option v-for="item in data.channelList" :key="item.name" :label="item.name" :value="item.name" />
      </el-select>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        clearable
        v-model="data.storeCode"
        :options="data.shopList"
        placeholder="请选择门店"
        class="search-select"
        style="width: 200px;"
        @change="searchLists"
      />
    </el-form-item>
    <el-form-item label="包含商品">
      <el-input placeholder="请输入包含商品" v-model="data.product" @keyup.enter="searchLists"></el-input>
    </el-form-item>
    <el-form-item label="售卖状态">
      <el-select v-model="data.isShelves" placeholder="请选择状态" @change="searchLists">
        <el-option label="上架" :value="1" />
        <el-option label="下架" :value="0" />
        <el-option label="全部" :value="-1" />
      </el-select>
    </el-form-item>
    <el-form-item label="供货状态">
      <el-select v-model="data.isSupply" placeholder="请选择状态" @change="searchLists">
        <el-option label="正常供货" :value="1" />
        <el-option label="停止供货" :value="0" />
        <el-option label="全部" :value="-1" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">查询</el-button>
      <el-button @click="resetParams">
        重置
      </el-button>
      <el-button type="primary" @click="addGroup" v-if="data.authMenus.add"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
    </el-form-item>
  </el-form>

  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" />
    <el-table-column label="商品图片" prop="pic" align="center">
      <template #default="scope">
        <div style="display: flex; justify-content: center;">
          <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
        </div>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="组合名称" prop="name" align="center" />
    <el-table-column show-overflow-tooltip label="套餐规格" prop="spec" align="center" />
    <el-table-column show-overflow-tooltip label="指导价(元)" prop="price" align="center" />
    <el-table-column show-overflow-tooltip label="最小单位" prop="unit" align="center" />
    <el-table-column show-overflow-tooltip label="单品数量" prop="skuCount" align="center" />
    <el-table-column show-overflow-tooltip label="投放渠道" prop="channel" align="center" />
    <el-table-column show-overflow-tooltip label="下发门店数" prop="storeCount" align="center" />
    <el-table-column show-overflow-tooltip label="更新时间" prop="updateTime" align="center" />
    <el-table-column show-overflow-tooltip label="售卖状态" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.isShelves === 1">上架</el-tag>
        <el-tag type="error" v-else>下架</el-tag>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="供货状态" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.isSupply === 1">正常供货</el-tag>
        <el-tag type="error" v-else>停止供货</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="180" align="center">
      <template #default="scope">
        <el-button type="text" @click="typeOperation(1, scope.row)" v-if="data.authMenus.query">详情</el-button>
        <el-button type="text" @click="typeOperation(2, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <el-button type="text" @click="typeOperation(3, scope.row)" v-if="data.authMenus.update">{{
          scope.row.isShelves === 1 ? '下架' : '上架'
        }}</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddGroup
    v-if="data.addProductVisible"
    :dialogVisible="data.addProductVisible"
    :specRowMsg="data.groupRowMsg"
    :units="data.units"
    :isEdit="data.isEdit"
    :channelList="data.channelList"
    @update-dialogVisible="updateAddProductVisible"
  ></AddGroup>
</template>

<script setup>
import { useRoute } from 'vue-router'
import { ref, reactive, onMounted, computed } from 'vue'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/api'
import AddGroup from './AddGroup.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
const data = reactive({
  authMenus: {},
  name: undefined,
  storeCode: undefined,
  channel: undefined,
  product: undefined,
  isShelves: -1,
  isSupply: -1,

  tableData: [],
  storeCode: undefined,
  pageSize: 10,
  currentPage: 1,
  total: 100,
  addProductVisible: false,
  groupRowMsg: {},
  shopList: [],
  units: [],
  channelList: [],
  loading: false,
})
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  getShops()
  getChannelList()
  selectDictListAll()
})

const getShops = () => {
  API.getStoreList(null).then(res => {
    if (res.code === '200') {
      data.shopList = res.data.map(item => ({
        value: item.code,
        label: item.name,
      }))
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//获取单位
const selectDictListAll = () => {
  API.selectDictListAll().then(res => {
    if (res.code === '200') {
      data.units = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const getChannelList = () => {
  API.getChannelList(null).then(res => {
    if (res.code === '200') {
      data.channelList = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
const resetParams = () => {
  data.name = undefined
  data.channel = undefined
  data.storeCode = undefined
  data.product = undefined
  data.isShelves = -1
  data.isSupply = -1
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let queryParams = {
    name: data.name,
    channel: data.channel,
    product: data.product,
    store: data.storeCode,
    current: data.currentPage,
    pageSize: data.pageSize,
  }
  if (data.isShelves !== -1) {
    queryParams.isShelves = data.isShelves
  }
  if (data.isSupply !== -1) {
    queryParams.isSupply = data.isSupply
  }
  API.selectSkuArrayPage(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}

//添加
const addGroup = rowMsg => {
  data.groupRowMsg = {
    pic: rowMsg.pic,
    storeCodeList: [],
    skuArrayContentResp: [],
    spuCode: rowMsg.code,
    categoryCode1: rowMsg.categoryCode,
    price: '',
    price1: 1,
    price2: 2,
    shops: _.cloneDeep(data.shopList),
    isStock: true,
    isRecommend: false,
    isMinConsume: false,
    isSales: 1,
    isShelves: 1,
    isSupply: 1,
    channel: data.channelList.map(item => item.name),
  }
  data.isEdit = true
  data.addProductVisible = true
}
const stopOrStartRow = rowMsg => {
  API.updateSkuArrayIsShelves({ code: rowMsg.code, isShelves: rowMsg.isShelves === 1 ? 0 : 1 }).then(res => {
    if (res.code === '200') {
      ElMessage.success('更新成功')
      queryLists({})
    } else {
      ElMessage.error(res.msg)
    }
  })
}
const editProType = rowMsg => {
  API.selectSkuArrayItem({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseFloat(res.data.price.split('~')[0])
        res.data.price2 = parseFloat(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }

      data.groupRowMsg = {
        shops: _.cloneDeep(data.shopList),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        channel: res.data.channel.split(','),
        caseSpec: parseInt(res.data.caseSpec),
      }
      data.addProductVisible = true
      data.isEdit = true
    } else {
      ElMessage.error(res.error)
    }
  })
}
const showProType = rowMsg => {
  API.selectSkuArrayItem({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseInt(res.data.price.split('~')[0])
        res.data.price2 = parseInt(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }
      data.groupRowMsg = {
        shops: _.cloneDeep(data.shopList),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        channel: res.data.channel.split(','),
        caseSpec: parseInt(res.data.caseSpec),
      }
      data.addProductVisible = true
      data.isEdit = false
    } else {
      ElMessage.error(res.error)
    }
  })
}

//操作商品列表数据
const typeOperation = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showProType(rowMsg)
      break
    case 2:
      editProType(rowMsg)
      break
    case 3:
      stopOrStartRow(rowMsg)
      break
  }
}

const updateAddProductVisible = ({ update, search }) => {
  data.addProductVisible = update
  if (search) {
    queryLists()
  }
}
</script>

<style lang="scss" scope>
.product-list-top {
  width: 96%;
  margin-top: 20px;
  margin-left: 2%;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    .title {
      width: 100px;
      margin-left: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: right;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
