/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-06-08 10:26:09
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-06-23 11:40:18
 * @FilePath: /saas/src/config/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const Config = {
  sidebarLevel: 3, // 侧边栏层级（无限级）限制, 默认为 3 级，可根据需求自行扩充
  openAutoJumpOut: true, // 是否开启无操作跳出
  defaultRoute: '/', // 默认打开的路由
  websocketEnable: false, // 默认关闭 websocket
  showSidebarSearch: false, // 默认打开侧边栏搜索
  notLoginRoute: ['login', 'forgetPwd'], // 无需登录即可访问的路由 name,
  useFrontEndErrorMsg: false, // 默认采用后端返回异常
  stagnateTime: 60 * 60 * 1000, // 无操作停滞时间，默认1小时
  baseURL: process.env.VUE_APP_BASE_URL, // API接口baseURL，在根目录.env文件查找对应环境变量配置
}

export default Config
