/*
 * @Author: your name
 * @Date: 2022-05-05 13:57:11
 * @LastEditTime: 2024-10-21 14:31:43
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/member_manager/service/index.js
 */
import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {
  //查询充值订单列表
  async getCardsStoredRecordPages(data) {
    const res = await get(`/adminChain/order/getCardsStoredRecordPages`, data)
    return res
  }
  //查询充值订单详情
  async getCardsStoredRecordDetails(data) {
    const res = await get(`/adminChain/order/getCardsStoredRecordDetails`, data)
    return res
  }
  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1,
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }
  async exportReport({ url, data }) {
    const res = await _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //获取门店列表
  async getStoreListByName(data) {
    const res = await get(`/adminChain/store/getStoreListByName`, data)
    return res
  }
  //门店
  async getStoreList(data) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']
    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //导出储值订单列表
  async exportOrderCardStored(data) {
    const res = await _axios({
      url: '/adminChain/order/exportOrderCardStored',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //退款
  async refundMoney(data) {
    const res = await post(`/adminChain/order/refundMoney`, data)
    return res
  }

  //根据品类查找商品
  async skuList(data) {
    const res = await get(`goods/sku/list`, data)
    return res
  }
  //根据品类查找商品新
  async productList(data) {
    const res = await get(`goods/product/list`, data)
    return res
  }

  //分配商品到门店
  async skuDistribution(data) {
    const res = await post(`goods/sku/distribution`, data)
    return res
  }

  //推荐商品
  async Recommend(data) {
    const res = await post('goods/recommend', data)
    return res
  }
  //推荐列表
  async RecommendList() {
    const res = await get('goods/recommend')
    return res
  }
  //推荐列表
  async skuResort(data) {
    const res = await post('goods/sku/resort', data)
    return res
  }
  //商品排序
  async goodsResort(data) {
    const res = await post(`goods/resort/${data.type}`, data.lanmus)
    return res
  }
  //根据品类查询类别
  async categoryListch(data) {
    const res = await get(`goods/category/list/child`, { parentCode: data })
    return res
  }
  //根据品类查询属性
  async categoryAttributes(data) {
    const res = await get(`goods/attribute/list/all`, { categoryCode: data })
    return res
  }
  //创建商品
  async addSku(data) {
    const res = await post(`goods/sku/create`, data)
    return res
  }
  //商品单位
  async getSkuDictList(data) {
    const res = await get(`goods/dict/list`, data)
    return res
  }

  //获取存酒记录
  async getWineListPages(data) {
    const res = await post(`/adminChain/wineAccess/getWineListPages`, data)
    return res
  }
  //查询会员列表
  async getMemberList(data) {
    const res = await post(`/adminChain/customer/list/saas/page`, data)
    return res
  }
  //查询会员等级列表
  async getLevelList(data) {
    const res = await get(`/adminChain/customer/level/list/saas`, data)
    return res
  }
  //查询会员详情
  async getMemberDetail(data) {
    const res = await get(`/adminChain/customer/saas`, data)
    return res
  }

  //导出会员列表
  async exportMember(data) {
    const res = await _axios({
      url: '/adminChain/customer/list/saas/export',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }
  //获取存取、酒列表
  async getWineLogGroupListPages(data) {
    const res = await post(`/adminChain/wineAccess/getWineLogGroupListPages`, data)
    return res
  }
  //获取存酒日志记录
  async getWineLogListPages(data) {
    const res = await post(`/adminChain/wineAccess/getWineLogListPages`, data)
    return res
  }

  //批量存酒续存
  async batchRenewWineAccess(data) {
    const res = await post(`/adminChain/wineAccess/batchRenewWineAccess`, data)
    return res
  }

  //获取存取酒详情用户信息
  async getCustomerUserByTelephone(data) {
    const res = await get(`/adminChain/customer/saas/getCustomerUserByTelephone`, data)
    return res
  }

  //获取门店列表
  getStoreListByName(data) {
    return post(`/adminChain/store/saas/getStoreList`, data)
  }
  //分页查询优惠券规则模版列表
  selectCouponPage(data) {
    return post(`/adminChain/coupon/saas/selectCouponPage`, data)
  }
  //删除优惠券
  deleteCoupon(data) {
    return get(`/adminChain/coupon/saas/deleteCoupon`, data)
  }
  //查询优惠券规则详情
  queryCouponRuleDetails(data) {
    return get(`/adminChain/coupon/saas/queryCouponRuleDetails`, data)
  }
  //创建优惠券模版
  createCouponRule(data) {
    return post(`/adminChain/coupon/saas/createCouponRule`, data)
  }
  //编辑优惠券模版
  updateCouponRule(data) {
    return post(`/adminChain/coupon/saas/updateCouponRule`, data)
  }
  //作废优惠券
  cancelCoupon(data) {
    return get(`/adminChain/coupon/saas/cancelCoupon`, data)
  }

  //地区
  async getArea(data) {
    return await get('/adminChain/organizational/saas/getArea', data)
  }

  //充值套餐

  //查询储值套餐列表
  async getPriceLsit(data) {
    return await get('/adminChain/customer/stored/prize/list', data)
  }
  //查询储值套餐详情
  async getPriceDetail(data) {
    return await get('/adminChain/customer/stored/prize/detail', data)
  }
  //保存储值套餐
  async PriceSave(data) {
    return await post('/adminChain/customer/stored/prize/save', data)
  }
  //删除会员储值方案信息
  async priceDeleteStored(data) {
    return await get('/adminChain/customer/stored/prize/deleteStored', data)
  }

  //会员卡等级
  //分页查询会员等级
  async queryLevels(data) {
    return await post('/adminChain/customer/level/page', data)
  }

  //删除会员等级
  async levelDelete(data) {
    return await get('/adminChain/customer/level/delete', data)
  }

  //获取会员等级详情
  async getLevelDetail(data) {
    return await get('/adminChain/customer/level/details', data)
  }

  //保存会员等级
  async levelSave(data) {
    return await post('/adminChain/customer/level/save', data)
  }

  //修改会员信息
  customerUpdate(data) {
    return post('/adminChain/customer/user/customer/update', data)
  }

  //包厢类型
  getParentStoreRoomTypeList() {
    return get(`/adminChain/room/saas/getParentStoreRoomTypeList`)
  }

  //查询门店区域关联门店详情
  getRegionList(data) {
    return get('/adminChain/store/saas/storeRegionConfig/getRegionList', data)
  }

  //n倍数储值免单
  selectStoredGratis(data) {
    return post('/adminChain/coupon/saas/activity/storedGratis/selectStoredGratis', data)
  }
  insertStoredGratis(data) {
    return post('/adminChain/coupon/saas/activity/storedGratis/insertStoredGratis', data)
  }
  deleteStoredGratis(data) {
    return post('/adminChain/coupon/saas/activity/storedGratis/deleteStoredGratis', data)
  }
  updateStoredGratis(data) {
    return post('/adminChain/coupon/saas/activity/storedGratis/updateStoredGratis', data)
  }

  //会员
  queryStoreCardListByCustomerCode(data) {
    return post('/adminChain/customer/queryStoreCardListByCustomerCode', data)
  }

  //券包
  selectCouponPackagePage(data) {
    return post('/adminChain/coupon/saas/package/selectCouponPackagePage', data)
  }
  saveCouponPackage(data) {
    return post('/adminChain/coupon/saas/package/saveCouponPackage', data)
  }
  updateCouponPackage(data) {
    return post('/adminChain/coupon/saas/package/updateCouponPackage', data)
  }
  UpOrDownShelves(data) {
    return post('/adminChain/coupon/saas/package/UpOrDownShelves', data)
  }

  async selectByType(data) {
    return await get(`/adminChain/goods/saas/category/selectByType`, data)
  }
}

export default new CsMng()
