<template>
  <el-dialog v-model="visible" title="选择商品" width="70%" :before-close="handleClose" style="height: 50%;">
    <el-form :model="form" class="demo-form-inline">
      <!-- <el-form-item label="采购方式" required>
        <el-radio-group v-model="form.purchaseType" @change="changeType">
          <el-radio :label="1">商品最低价采购</el-radio>
          <el-radio :label="2">指定供应商采购</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="供应商" v-if="form.purchaseType == 2">
        <el-select v-model="form.region" placeholder="请选择供应商" @change="selectBelowProductSkuArray">
          <el-option
            :label="item.supplierName"
            :value="item.code"
            v-for="(item, index) in form.supplierList"
            :key="index"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <div style="display: flex;padding-left: 10px; height: 100%;padding-top: 30px;">
      <div>
        <el-card class="box-card" style="height:90%;">
          <el-tabs style="width: 140px; height: 100%;" v-model="form.activeName" @tab-click="changeTab">
            <el-tab-pane label="商品" :name="1">
              <el-scrollbar height="400px">
                <p
                  v-for="(item, index) in form.productTypes"
                  :key="index"
                  class="productItem"
                  :class="{ activeItem: item.activeItemStyle }"
                  @click="selectCategoryCode(item)"
                >
                  {{ item.name }}
                </p>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="易耗品" :name="4">
              <el-scrollbar height="400px">
                <p
                  v-for="(item, index) in form.articleTypes"
                  :key="index"
                  class="productItem"
                  :class="{ activeItem: item.activeItemStyle }"
                  @click="selectCategoryCode(item)"
                >
                  {{ item.name }}
                </p>
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
      <div style="width: calc(100% - 140px);">
        <el-form :inline="true" :model="form" style="margin-left: 20px;">
          <el-form-item label="商品名称">
            <el-input placeholder="请输入搜索的商品名称" v-model="form.productName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="selectBelowProductSkuArray">查询</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>
        </el-form>
        <el-table :data="form.skuListsSelect" style="width: 94%; margin-left: 2%;">
          <el-table-column align="center">
            <template #header>
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="form.checkAll"
                @change="handleCheckAllChange"
              ></el-checkbox>
            </template>
            <template #default="{row}">
              <el-checkbox v-model="row.checked" />
            </template>
          </el-table-column>
          <el-table-column label="序号" align="center" type="index" :index="indexfn1" />
          <el-table-column label="商品图片" prop="pic" width="100" align="center">
            <template #default="scope">
              <div style="display: flex; justify-content: center;">
                <img :src="scope.row.skuPic" alt="" style="width:30px; height: 30px;" />
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="skuName" label="商品名称" align="center" />
          <el-table-column prop="specifications" label="商品规格" align="center" />
          <el-table-column prop="purchaseUnit" label="采购单位" align="center" />
          <el-table-column prop="miniUnit" label="最小单位" align="center" />
          <el-table-column label="采购比(1:N)" align="center">
            <template #default="{row}">
              <span>{{ row.attribute == 3 ? '' : row.adoptPinThan }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="goodsCost" label="采购价(元)" align="center" />
        </el-table>
        <el-pagination
          style="margin-right: 6%;"
          v-model:current-page="form.currentPage"
          v-model:page-size="form.pageSize"
          :small="small"
          :disabled="disabled"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="form.total"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, onMounted, watchEffect, watch } from 'vue'
import _ from 'lodash'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    purchaseInfo: Object,
    storeCode: String,
    versionUid: String,
    storeName: String,
    cityCode: Number,
  },
  setup(props, ctx) {
    const form = reactive({
      categoryCode: undefined,
      productName: '',
      checkAll: false,
      activeName: 1,
      tableData: [],
      skuListsSelect: [],
      currentPage: 1,
      pageSize: 5,
      total: 100,
      loading: false,
      productTypes: [],
      articleTypes: [],
      skuLists: [],
      skuListsSelect: [],
      checkAll: false,
      purchaseType: 2,
      supplierList: [],
      region: '',
    })
    onMounted(() => {
      // if (JSON.stringify(props.purchaseInfo) == '{}') {
      //   form.purchaseType = 1
      // } else {
      //   form.purchaseType = 2
      // }
      queryAllShopTyps()
      queryAllArticlesTyps()
      selectBelowProductSkuArray()
      selectSupplierList()
    })
    watchEffect(() => {
      form.checkAll = false
      let start = (form.currentPage - 1) * form.pageSize
      let end = form.currentPage * form.pageSize
      form.total = form.skuLists.filter(item => item.skuName.includes(form.productName)).length
      let newArr = form.skuLists.filter(item => item.skuName.includes(form.productName)).slice(start, end)
      form.skuListsSelect = [...newArr]
    })
    // watch(
    //   () => form.activeName,
    //   () => {
    //     selectBelowProductSkuArray()
    //   },
    // )

    watch(
      () => props.purchaseInfo,
      newVal => {
        console.log(newVal)
        form.region = newVal.supplierCode
      },
      { deep: true, immediate: true },
    )

    const selectBelowProductSkuArray = () => {
      let params = {
        categoryCode: form.categoryCode,
        skuName: form.productName,
        type: form.activeName,
        pageSize: 9999,
        current: 1,
        storeCode: props.storeCode,
        supplierState: 10,
      }

      if (form.purchaseType == 2) {
        params.supplierCode = form.region

        if (!params.supplierCode) {
          return ElMessage.warning('请选择供应商')
        }
      } else {
        params.isMin = 1
      }
      API.selectProductPage(params).then(res => {
        if (res.code == '200') {
          form.skuLists = res.data.records.map(item => ({ ...item, checked: false }))
          form.total = res.data.total
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const selectCategoryCode = item => {
      let a = form.articleTypes.find(item => item.activeItemStyle)
      let p = form.productTypes.find(item => item.activeItemStyle)

      if (a) {
        a.activeItemStyle = false
      }
      if (p) {
        p.activeItemStyle = false
      }

      item.activeItemStyle = true
      form.categoryCode = item.code
      selectBelowProductSkuArray()
    }
    const visible = computed(() => {
      return props.dialogVisible
    })
    const clickSecondMenu = activeItem => {
      let isActiveItem = form.productTypes.find(item => item.isActive)
      if (isActiveItem) {
        isActiveItem.isActive = false
      }
      if (activeItem !== isActiveItem) {
        activeItem.isActive = true
      }
    }
    //查询所有商品类型
    const queryAllShopTyps = () => {
      API.selectListByType({ type: 1 }).then(res => {
        if (res.code === '200') {
          form.productTypes = res.data
          form.productTypes.splice(0, 0, { name: '全部', value: '-1', activeItemStyle: 'true' })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    //获取供应商
    const selectSupplierList = () => {
      API.selectSupplierList({
        storeCodeList: [props.storeCode],
      }).then(res => {
        if (res.code === '200') {
          form.supplierList = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const queryAllArticlesTyps = () => {
      API.selectListByType({ type: 4 }).then(res => {
        if (res.code === '200') {
          form.articleTypes = res.data
          form.articleTypes.splice(0, 0, { name: '全部', value: '-1' })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const indexfn1 = computed(() => {
      return (form.currentPage - 1) * form.pageSize + 1
    })

    const changeTab = () => {
      let a = form.articleTypes.find(item => item.activeItemStyle)
      let b = form.productTypes.find(item => item.activeItemStyle)
      if (a) {
        a.activeItemStyle = false
      }
      if (b) {
        b.activeItemStyle = false
      }
      form.categoryCode = ''
      if (form.activeName == 1) {
        form.productTypes[0].activeItemStyle = true
      } else {
        form.articleTypes[0].activeItemStyle = true
      }
      selectBelowProductSkuArray()
    }

    const changeType = () => {
      selectBelowProductSkuArray()
    }
    const onSubmit = _.throttle(
      () => {
        let params = form.skuLists
          .filter(item => item.checked)
          .map(item => {
            return {
              ...item,
              versionUid: props.versionUid,
              price: item.goodsCost,
              spec: item.specifications,
              num: 1,
            }
          })

        if (params.length == 0) {
          return
        }
        let reqParams = {
          storeCode: props.storeCode,
          storeName: props.storeName,
          purchaseOrderBOList: params,
          cityCode: props.cityCode,
        }
        API.savePurchaseOrder(reqParams).then(res => {
          if (res?.code == 200) {
            ElMessage.success('商品已入采购清单')
            form.checkAll = false
            selectBelowProductSkuArray()
          }
        })
      },
      3000,
      { trailing: false },
    )
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false, search: true })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false, search: true })
    }
    const handleCheckAllChange = () => {
      form.skuListsSelect.forEach(element => {
        element.checked = form.checkAll
      })
    }
    return {
      form,
      indexfn1,
      onSubmit,
      handleClose,
      backtrack,
      clickSecondMenu,
      props: { multiple: true },
      visible,
      handleCheckAllChange,
      selectCategoryCode,
      selectBelowProductSkuArray,
      changeTab,
      changeType,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep.el-input-number {
  width: 100%;
}
.activeItem {
  background-color: rgba(127, 127, 127, 0.3);
}
.productItem {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  &:hover {
    background-color: rgba(127, 127, 127, 0.3);
  }
}
.el-card {
  ::v-deep .el-card__body {
    height: 90%;
  }
  ::v-deep .el-tabs__content {
    height: 90%;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
