/*
 * @Author: your name
 * @Date: 2022-05-05 13:57:11
 * @LastEditTime: 2022-12-22 14:37:29
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/service/index.js
 */
import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {
  //获取门店列表
  async getStoreListByName(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']
    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1,
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  //查询门店包厢类型 不需要传参
  async getStoreRoomTypeList2(data) {
    const res = await get(`/store/storeRoomType/getStoreRoomTypeList2`, data)
    return res
  }

  async selectByType(data) {
    return await get(`/adminChain/goods/saas/category/selectByType`, data)
  }

  async exportReport({ url, data }) {
    const res = await _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //分页查询商品提成列表
  async commissonGoods(data) {
    return await post(`/report/commission/goods/pageList`, data)
  }

  //分页查询商品销售提成列表
  async querySalesCommissionDetail(data) {
    return await post(`/report/commission/querySalesCommissionDetail`, data)
  }

  //分页查询储值卡提成列表
  async commissonStored(data) {
    return await post(`/report/commission/stored/pageList`, data)
  }
}

export default new CsMng()
