<template>
  <el-dialog
    :title="'code' in updateRowMsg ? '编辑券包' : '新增券包'"
    v-model="dialogVisible"
    width="70%"
    :before-close="handleClose"
  >
    <el-form :model="updateRowMsg" :rules="rules" ref="formRef">
      <el-form-item label="券包名称" label-width="120px" prop="name">
        <el-input
          placeholder="请输入券包名称"
          v-model="updateRowMsg.name"
          class="input-search-tel"
          clearable
        ></el-input>
      </el-form-item>

      <el-form-item label="包含优惠券" label-width="120px" prop="couponRuleList">
        <div style="display: flex;">
          <el-button @click="addCoupou">添加优惠券</el-button>
          <span style="margin-left: 10px;color: #ae7731;">添加的优惠券适用门店必须一致</span>
        </div>
        <el-table class="coupon-table" :data="pageData">
          <el-table-column label="优惠券" prop="name" align="center" width="440">
            <template #default="scope">
              <div class="coupou-item">
                <div class="left">
                  <text>{{ scope.row.scheme }}{{ unitsObj[scope.row.type] }}</text>
                  <text>{{ scope.row.minimumCharge === -1 ? '无门槛' : scope.row.minimumCharge }}</text>
                </div>
                <div class="right">
                  <text>{{ scope.row.name }}</text>
                  <div>有效期：{{ scope.row.validityTimeStr }}</div>
                  <text>仅{{ scope.row.usedRange === 1 ? '房费' : '商品' }}可用</text>
                  <text>发行数量：{{ scope.row.issueNum === -1 ? '不限制' : scope.row.realIssueNum }}</text>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center">
            <template v-slot="scope">
              <el-input-number v-model="scope.row.ruleSetNum" size="small" :min="1"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{row}">
              <el-button @click="deleteMustSelectProduct(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-model:current-page="pageParams.pageNum"
          v-model:page-size="pageParams.pageSize"
          :small="small"
          :disabled="disabled"
          :page-sizes="[5, 10, 20, 30]"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageParams.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-form-item>

      <el-form-item label="个人限买数量" label-width="120px" prop="limitNum">
        <el-input-number
          placeholder="请输入个人限买数量"
          v-model="updateRowMsg.limitNum"
          class="input-search-tel"
          style="width: 200px;display: inline-block;margin-right: 10px;"
          clearable
          :controls="false"
          v-if="!isEdit"
        ></el-input-number>
        <span v-else>{{ !updateRowMsg.limitNum ? '不限制' : updateRowMsg.limitNum }}</span>
        <span style="color: #ae7731;" v-if="!isEdit">未设置表示不限制</span>
      </el-form-item>
      <el-form-item label="售卖状态">
        <el-radio-group v-model="updateRowMsg.status">
          <el-radio :label="0">上架</el-radio>
          <el-radio :label="1">下架</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit" :disabled="isEdit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <SelectCoupou
    :isAll="true"
    v-if="coupouDialogVisible"
    :dialogVisible="dialogVisible"
    @update-dialogVisible="rectiveProducts"
    :storeCodeList="storeCodeList"
    :needFilterStore="false"
    :usedChannel="2"
    :hasPro="hasPro"
    :isFixedStore="updateRowMsg.isFixedStore"
  ></SelectCoupou>
</template>

<script setup>
import _ from 'lodash'
import API from '../../service/index'
import { ref, defineEmit, defineProps, reactive, nextTick, watchEffect, onMounted } from 'vue'
import SelectCoupou from '../../component/select-coupou.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

//分页参数
const pageParams = reactive({
  pageNum: 1,
  pageSize: 5,
  total: 0,
})
//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
}
const handleCurrentChange = val => {
  pageParams.pageNum = val
}
const pageData = ref([]) //分页数据
watchEffect(() => {
  console.log(props.updateRowMsg)
  let start = pageParams.pageSize * (pageParams.pageNum - 1)
  let end = start + pageParams.pageSize
  pageData.value = (props.updateRowMsg.couponRuleList ?? []).slice(start, end)
  pageParams.total = props.updateRowMsg.couponRuleList?.length ?? 0
})

/**
 * 父容器传过来的参数
 */
const props = defineProps({
  updateRowMsg: {
    type: Object,
    default: () => ({}),
  },
  isEdit: Boolean,
})
const unitsObj = {
  1: '折',
  2: '元',
  3: '分钟',
  4: '元',
}

const hasPro = ref([])
/**添加优惠卷 */
const addCoupou = () => {
  hasPro.value = _.cloneDeep(props.updateRowMsg.couponRuleList || [])
  isShowCoupouDialog(true)
}
/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['handleCloseDialog'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

/**
 * 删除当前优惠卷
 * @param {object} row
 */
const deleteMustSelectProduct = row => {
  ElMessageBox.confirm('确认删除当前优惠券吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let deleteIndex = props.updateRowMsg.couponRuleList.findIndex(a => a.code === row.code)
    props.updateRowMsg.couponRuleList.splice(deleteIndex, 1)
  })
}

// 选择优惠卷
const coupouDialogVisible = ref(false) //商品选择组件展示

/**
 * 接受新数据
 * @param {object} item 选择的商品
 */
const rectiveProducts = ({ selectSkus }) => {
  isShowCoupouDialog(false)
  // injectNewProduct(props.updateRowMsg.couponRuleList, selectSkus)
  props.updateRowMsg.couponRuleList = selectSkus
  if (props.updateRowMsg.couponRuleList.length > 0) {
    formRef.value.validateField('couponRuleList')
  }
}

/**
 * 注入新数据
 * @param {array} oldProducts  老数据
 * @param {array} newProducts  新数据
 */
const injectNewProduct = (oldProducts, newProducts) => {
  oldProducts = newProducts
  newProducts.forEach(n => {
    let old = oldProducts.find(o => o.code === n.code)
    if (old) {
      //old.ruleSetNum++
    } else {
      oldProducts.push({
        ...n,
        ruleSetNum: 1,
      })
    }
  })
}

/**
 * 是否展示选择优惠卷ialog
 */
const isShowCoupouDialog = val => {
  coupouDialogVisible.value = val
}

/**
 * 校验优惠卷数量
 */
const validateCoupons = (rule, value, callback) => {
  if (!value || value.length == 0) {
    return callback(new Error('优惠券必选'))
  }
  return callback()
}
const validateLimitNum = (rule, value, callback) => {
  if (value == 0) {
    return callback(new Error('个人限买数量不能为0'))
  }
  return callback()
}

const formRef = ref(null)
const rules = {
  couponRuleList: [{ required: true, validator: validateCoupons, trigger: 'blur' }],
  limitNum: [{ required: false, validator: validateLimitNum, trigger: 'blur' }],
  name: [{ required: true, trigger: 'blur', message: '请输入券包名称' }],
}

const checkCardStore = async () => {
  let storeSet = new Set()
  props.updateRowMsg.couponRuleList.forEach(a => {
    storeSet.add(a.attemptStore)
  })
  if (storeSet.size !== 1) {
    ElMessage.warning('添加的优惠券适用门店必须一致')
    return Promise.reject('error')
  }
}

/**
 * 保存
 */
const handleCommit = async () => {
  try {
    await formRef.value.validate()
    await checkCardStore()
    let params = generateParams()
    let res
    if ('code' in props.updateRowMsg) {
      res = await API.updateCouponPackage(params)
    } else {
      res = await API.saveCouponPackage(params)
    }

    if (res.code === '200') {
      ElMessage.success('操作成功')
      emit('handleCloseDialog', { update: false, search: true })
    } else {
      ElMessage.error(res.msg)
    }
  } catch (error) {
    ElMessage.info('请填写完成必要信息')
    console.log(error)
  }
}

/**
 * 生成参数
 */
const generateParams = () => {
  let params = Object.assign({}, props.updateRowMsg)
  params.attemptStore = params.couponRuleList[0].attemptStore
  params.couponRuleList = params.couponRuleList.map(item => ({
    ruleSetNum: item.ruleSetNum,
    ruleCode: item.code,
    ruleName: item.name,
  }))
  params.limitNum = params.limitNum ? params.limitNum : -1
  return params
}
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
.remark {
  color: rosybrown;
}
.coupon-table {
  margin-top: 5px;
}
.el-input-number {
  width: 100%;
}
::v-deep(.el-input__inner) {
  text-align: left;
}
.timer-toast {
  margin-left: 5px;
  color: rosybrown;
}
.coupou-item {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  .left {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-right: 1px solid #ccc;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
