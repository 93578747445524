/*
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-06-26 11:19:20
 * @LastEditTime: 2024-07-16 18:01:46
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/mixins/clearTableData.js
 * @Description:
 */
import { ElMessage } from 'element-plus'
export default {
  data() {
    return {
      emptyTableText: '请先设置查询条件，再点击查询数据',
    }
  },
  methods: {
    /**
     * @description: 清除表格数据
     * @return {*}
     */
    clearTableData() {
      this.tableData = []
      this.totalRecords = this.totalPage = 0
    },
    /**
     * @description: 设置空数据展示
     * @return {*}
     */
    setEmptyDataText() {
      this.emptyTableText = this.tableData.length == 0 ? '暂无数据' : ''
    },

    /**
     * @description: 计算两个时间的相差天数
     * @param {*} sDate1
     * @param {*} sDate2
     * @return {*}
     */
    getDays(sDate1, sDate2) {
      if (!sDate1 || !sDate2) {
        ElMessage.warning('请设置查询时间')
        return false
      }

      const DAY = 32
      //sDate1和sDate2是2002-12-18格式
      var dateSpan,
        tempDate,
        iDays
      sDate1 = Date.parse(sDate1)
      sDate2 = Date.parse(sDate2)
      dateSpan = sDate2 - sDate1
      dateSpan = Math.abs(dateSpan)
      iDays = Math.floor(dateSpan / (24 * 3600 * 1000)) + 1
      if (iDays > DAY) {
        ElMessage.warning('查询或导出时间范围不能超过一个月')
        return false
      }
      return true
    }
  }
}
