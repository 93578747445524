<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="菜单" name="oldMenu" v-if="isShowReport('oldMenu')">
      <OldMenu :auths="authType.oldMenu" />
    </el-tab-pane>
    <el-tab-pane label="星系统" name="newMenu" v-if="isShowReport('newMenu')">
      <newMenu :auths="authType.newMenu" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import OldMenu from './menu-old/index'
import newMenu from './menu-new/index'

import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  oldMenu: {}, // 优惠券使用明细
  newMenu: {}, // 优惠券领取明细
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.oldMenu = authAllMenus.find(a => a.url === 'oldMenu') || { childrens: [] }
  authType.newMenu = authAllMenus.find(a => a.url === 'newMenu') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsIncomeAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
