/*
 * @Author: your name
 * @Date: 2022-05-05 17:42:41
 * @LastEditTime: 2022-09-26 15:01:25
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/constants/config.js
 */
export const payTypeList = {
  1: '现金',
  2: '支付宝',
  3: '微信',
  5: '储值卡',
  6: '组合支付',
  9: '美大支付',
  10: '雷石会员',
  12: '大众点评',
  13: '抖音支付',
  14: '雷石优惠券',
  15: '新系统优惠券',
  16: '内部挂账',
  17: '招待免收',
  18: '利通取酒',
  19: '可即会员',
  20: 'POS',
} //支付方式

export const statusList = { 1: '待支付', 2: '部分付款', 3: '支付成功', 4: '交易关闭', 5: '支付异常', 6: '已退款' } //支付状态

export const payTypeColumns = [
  { text: '现金', value: 1 },
  { text: '支付宝', value: 2 },
  { text: '微信', value: 3 },
  { text: '储值卡', value: 5 },
  { text: '组合支付', value: 6 },
  { text: '美大支付', value: 9 },
  { text: '雷石会员', value: 10 },
  { text: '大众点评', value: 12 },
  { text: '抖音支付', value: 13 },
  { text: '雷石优惠券', value: 14 },
  { text: '新系统优惠券', value: 15 },
  { text: '内部挂账', value: 16 },
  { text: '招待免收', value: 17 },
  { text: '利通取酒', value: 18 },
  { text: '可即会员', value: 19 },
  { text: 'POS', value: 20 },
] //table表头筛选 支付方式

export const statusColumns = [
  { text: '待支付', value: 1 },
  { text: '部分付款', value: 2 },
  { text: '支付成功', value: 3 },
  { text: '交易关闭', value: 4 },
  { text: '支付异常', value: 5 },
  { text: '已退款', value: 6 },
] //table表头筛选 支付状态

export const sourceList = { 1: '小程序', 2: '美团', 3: '到店', 4: '抖音', 5: '预定', 6: '留房' } //预定来源列表

export const sourceColumns = [
  { text: '小程序', value: 1 },
  { text: '美团', value: 2 },
  { text: '到店', value: 3 },
  { text: '抖音', value: 4 },
  { text: '预定', value: 5 },
  { text: '留房', value: 6 },
] //table表头筛选 预定来源

export const sessionStatusList = {
  1: '未消费',
  2: '消费中',
  3: '已消费',
  4: '退款中',
  5: '已退款',
  6: '异常支付',
  7: '挂单',
} //支付状态

export const sessionStatusColumns = [
  { text: '未消费', value: 1 },
  { text: '消费中', value: 2 },
  { text: '已消费', value: 3 },
  { text: '退款中', value: 4 },
  { text: '已退款', value: 5 },
  { text: '异常支付', value: 6 },
  { text: '挂单', value: 7 },
] //table表头筛选 支付状态

export const roomStateList = {
  1: '空闲',
  2: '故障',
  3: '带客',
  4: '清洁',
  5: '预定',
  6: '留房',
  7: '已开(未结)',
  8: '关台(未结)',
  9: '已开(已结)',
  10: '带客',
} //预定来源列表

export const isComplianceList = [
  //是否合规
  { text: '不合规', value: 0 },
  { text: '合规', value: 1 },
]
export const stateListColumns = [
  //禁用启用
  { text: '禁用', value: 0 },
  { text: '启用', value: 10 },
]
export const stateList = { 0: '禁用', 10: '启用' }
