<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-06-24 12:01:41
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/stockReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reprotStock" reportName="实时库存" /> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="'暂无数据'"
      :getTableData="reprotStock"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
// import tableSearchTab from '../../component/tableSearchTab.vue'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        pageNum: 1,
        pageSize: 10,
        storeCode: '',
        goodsCategory: '',
        goodsName: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'storeName',
          label: '门店',
        },
        {
          prop: 'goodsCategory',
          label: '商品类型',
        },
        {
          prop: 'goodsName',
          label: '商品名称',
        },
        {
          prop: 'unit',
          label: '单位',
        },
        {
          prop: 'stock',
          label: '实时库存',
        },
        {
          prop: 'goodsCost',
          label: '商品成本',
        },
        {
          prop: 'totalGoodsCost',
          label: '总成本',
        },
        // {
        //     prop: 'stockAmount',
        //     label: '成本金额',
        // },
        // {
        //   prop: 'lastPurchaseTime',
        //   label: '最新采购时间',
        // },
        // {
        //   prop: 'lastPurchaseSupplier',
        //   label: '最新供应商',
        // },
      ],
      searchform: [
        {
          label: '门店',
          type: 'select',
          labelWidth: '50px',
          typeProp: 'select',
          children: 'option',
          prop: 'storeCode',
          options: [],
          filterable: 'filterable',
        },
        {
          label: '商品类型',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'goodsCategory',
          options: [],
        },
        { label: '商品名称', type: 'input', prop: 'goodsName', placeholder: '商品名称' },
        {
          label: '导出',
          type: 'exportButton',
          url: '/report/export/repotRealTimeStock',
          auth: 'export',
        },
      ],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      // this.reprotStock(this.model)
      this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getStoreList() {
      api.getStoreListByName().then(res => {
        //门店接口
        this.searchform[0].options = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
      api.getCategoryList(2).then(res => {
        //商品分类接口
        this.searchform[1].options = res.data.map(item => {
          return {
            value: item.code,
            label: item.name,
          }
        })
      })
    },
    reprotStock(model) {
      api.reprotStock(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    parentSearch(param) {
      const { goodsCategory, goodsName, storeCode } = param
      this.model = { goodsCategory, goodsName, storeCode }
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.reprotStock(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { goodsCategory, goodsName, storeCode } = param
      this.model = { goodsCategory, goodsName, storeCode }
      this.model.pageNum = 1
      this.model.pageSize = 10
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '实时库存.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;
}
</style>
