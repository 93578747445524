<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="优惠券使用明细" name="couponsUsageAuth" v-if="isShowReport('couponsUsageAuth')">
      <couponsUsageDetails :auths="authType.couponsUsageAuth" />
    </el-tab-pane>
    <el-tab-pane label="优惠券领取明细" name="couponsClaimAuth" v-if="isShowReport('couponsClaimAuth')">
      <couponsClaimDetails :auths="authType.couponsClaimAuth" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import couponsUsageDetails from '../report_module/couponsUsageDetails'
import couponsClaimDetails from '../report_module/couponsClaimDetails'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  couponsUsageAuth: {}, // 优惠券使用明细
  couponsClaimAuth: {}, // 优惠券领取明细
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.couponsUsageAuth = authAllMenus.find(a => a.url === 'couponsUsageAuth') || { childrens: [] }
  authType.couponsClaimAuth = authAllMenus.find(a => a.url === 'couponsClaimAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsIncomeAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
