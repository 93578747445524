const linCmsUiRouter = {
  route: null,
  name: null,
  title: '运营统计',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/inventory/',
  order: null,
  inNav: false,
  children: [
    {
      title: '盘点统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/inventory-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '客遗统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/ky-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '提成统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/commision-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '管理组赠送',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/glzzs-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '财务扣款下单',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/cwkkxd-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '经营报表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/jybb-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '经营预算',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/jyys-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '全国经营报表',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/qgjybbtj-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '套餐/套免分析',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/bbtotal-manager/tctmfx-manager',
      filePath: 'plugin/bbtotal-manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
