<template>
  <div class="wTable">
    <!-- 头部工具栏 -->
    <div v-if="tools && tools.length > 0 && tools[0].label" class="toolBar">
      <template v-for="(tool, key) in tools">
        <!-- 具名插槽 -->
        <slot v-if="tool.slot" :name="tool.slot" />
        <el-button
          v-else
          :key="key"
          :type="tool.type"
          :size="tool.size"
          :plain="tool.plain"
          :round="tool.round"
          :circle="tool.circle"
          :icon="tool.icon"
          :style="tool.style"
          :disabled="tool.disabled"
          @click.native.prevent="tool.method()"
        >
          {{ tool.label }}
        </el-button>
      </template>
    </div>
    <!-- 表格 -->
    <el-table
      :empty-text="emptyText"
      id="wTable"
      ref="wTable"
      :cell-style="{ height: '40px' }"
      v-loading.wTable="woptions.loading"
      :data="data"
      :height="tHeight"
      :stripe="woptions.stripe"
      :border="woptions.border"
      :span-method="spanMethod"
      :row-key="rowKey"
      @selection-change="handleSelectionChange"
    >
      <!-- 多选框 -->
      <!-- <el-table-column v-if="woptions.mutiSelect" :reserve-selection="true" type="selection" :selectable="woptions.checkSelect" style="width: 55px;" align="center" /> -->
      <!-- 序号 -->
      <!-- <el-table-column v-if="woptions.index" label="序号" type="index" style="width: 55px;" align="center" /> -->
      <!-- 数据列 -->
      <template v-for="(column, index) of columns">
        <!-- 如过有嵌套，递归多级表头 -->
        <template v-if="column.children && column.children.length > 0">
          <!-- <WColumn :key="index" :column="column" /> -->
        </template>
        <!-- 没有正常显示 -->
        <template v-else>
          <!-- 具名插槽 -->
          <slot v-if="column.slot" :name="column.slot" />
          <el-table-column
            v-else-if="column.header"
            :prop="column.prop"
            :label="column.label"
            :type="column.type"
            :min-width="column.minWidth"
            :width="column.width"
            align="center"
            :fixed="column.fixed"
            :class-name="column.class"
            :sortable="column.sort"
          >
            <template #header="row">
              {{ column.label }}
              <el-tooltip placement="top-start">
                <template #content> {{ column.des }}</template>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-else-if="!column.isHide"
            v-slot="scope"
            :key="index"
            :prop="column.prop"
            :label="column.label"
            :type="column.type"
            :min-width="column.minWidth"
            :width="column.width"
            align="center"
            :fixed="column.fixed"
            :class-name="column.class"
            :sortable="column.sort"
          >
            <!-- 是否需要特殊处理 -->
            <template v-if="column.formatter">
              <span v-html="column.formatter(scope.row, column)"></span>
            </template>
            <template v-else>
              <el-tooltip class="box-item" effect="dark" :content="scope.row[column.prop]" placement="top">
                <span>{{ scope.row[column.prop] }}</span>
              </el-tooltip>
            </template>
            <template v-if="column.button">
              <el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>
            </template>
            <!-- <template v-else>
              <span>{{ scope.row[column.prop] }}</span>
            </template> -->
          </el-table-column>
        </template>
      </template>
    </el-table>
    <!-- 分页 -->
    <div v-if="isPagination" class="pagination">
      <el-pagination
        :current-page="model.pageNum"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="model.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        class="pagination-style"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

<script>
/**
 * 使用方法：
 * 通用的可参考 views/businessManage/businessList
 * 多级表头可参考 views/dingdang/privacyCallInfo
 * 合并行可参考 views/codeManage/routeTest
 */
// import WColumn from './WColumn'
export default {
  name: 'WTable',
  // components: { WColumn },
  props: {
    emptyText: {
      type: String,
    },
    // 头部按钮组
    tools: {
      type: Array,
      default() {
        return [
          // {method() {}}
        ]
      },
    },
    // 数据
    data: {
      type: Array,
      requre: false,
      default() {
        return []
      },
    },
    // 合并行
    spanMethod: Function,
    // 多选回显key
    rowKey: {
      type: String,
      default: 'id',
    },
    /**
     * 表格默认配置
     * height: 表格高度
     * stripe: 是否为斑马纹 table
     * border: 是否带有纵向边框
     * fit: 列的宽度是否自撑开
     * mutiSelect: 是否开启多选
     * loading: 添加loading
     */
    options: {
      type: Object,
      default() {
        return {
          stripe: false,
          border: false,
          fit: true,
          mutiSelect: false,
          loading: false,
          index: true,
        }
      },
    },
    // 表格高度
    tHeight: {
      type: String,
      default: '100%',
    },
    /**
     * 列集合
     * prop: 列字段
     * label: 列名称
     * align: 文本显示位置
     * width: 列固定宽度，例：200
     * minWidth: 自适应宽度 例： 200 或 20%
     * type: 类型
     * formatter: 特殊处理
     * slot: 特殊列名称
     * isHide: 是否隐藏列，用于动态展示列
     * tooltip: 超出是否...
     * class: 列的class
     * sort: 是否排序
     */
    columns: {
      type: Array,
      default() {
        return []
      },
      require: false,
    },
    // 数据总数
    total: {
      type: Number,
      default: 0,
    },
    // 是否显示分页
    isPagination: {
      type: Boolean,
      default: false,
    },
    /**
     * 分页参数
     * pagesize:每页显示的条数
     * currentPage:当前页码
     * pageArr: 显示多少条集合
     * background: 是否要背景
     */
    pagination: {
      type: Object,
      default() {
        return {
          pageSize: 10,
          background: true,
          currentPage: 1,
          pageArr: [10, 20, 50, 100],
        }
      },
    },
    model: {
      type: Object,
      default: () => {},
    },
    getTableData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 默认的表格配置
      woptions: {
        stripe: false,
        border: false,
        fit: true,
        mutiSelect: false,
        loading: false,
        index: true,
        checkSelect() {
          return true
        },
      },
      // 默认的分页配置
      wpagination: {
        pageSize: 10,
        background: true,
        currentPage: 1,
        pageArr: [10, 20, 50, 100],
      },
    }
  },
  created() {
    // 扩展配置，目的是引用组件不用把配置全写一遍
    this.woptions = Object.assign({}, this.woptions, this.options)
    this.wpagination = Object.assign({}, this.wpagination, this.pagination)
  },
  methods: {
    // 表格多选
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    // 选择每页展示的条数
    handleSizeChange(size) {
      this.model.pageSize = size
      this.getTableData(this.model)
      this.$emit('handleSizeChange', size)
    },
    // 点击第几页
    handleCurrentChange(currentPage) {
      console.log(111)
      //  if (this.totalPage >= 1) {}
      this.model.pageNum = currentPage
      this.getTableData(this.model)
      this.$emit('handleCurrentChange', currentPage)
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
.wTable {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // box-shadow: 0 0 10px #eee;
  .toolBar {
    // padding: 10px;
    // overflow: hidden;
  }

  .el-table {
    flex: 1;
    overflow-y: auto;
  }

  .pagination {
    text-align: right;
    padding: 20px 0;
    // text-align: center;
  }

  #table {
    // width: 100%;
  }

  .pagination-style {
    // position: fixed;
    // right: 24px;
    // bottom: 14px;
  }
}
</style>
