<template>
  <div class="searchbar"></div>
  <div>
    <Table :tableHead="tableHead"></Table>
  </div>
</template>
<script>
import { defineComponent, computed, reactive, ref, onMounted, nextTick } from 'vue'
import { Table, Drawerform } from '@/component/base'
import tablehead from './model.js'

export default {
  components: { Table },
  setup() {
    let tableHead = ref(tablehead)
    return {
      tableHead,
    }
  },
}
</script>
<style lang="scss" scoped>
.searchbar {
  background-color: #ffffff;
  height: 30px;
}
</style>
