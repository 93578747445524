import _axios, { post, get, put } from '@/lin/plugin/axios'
class API {
  async getParentStoreRoomTypeList() {
    return await get(`/adminChain/room/saas/getParentStoreRoomTypeList`)
  }
  async getRoomTypeOne(data) {
    return await get(`/adminChain/room/saas/getRoomTypeOne`, data)
  }
  async storeRoomTypeAdd(data) {
    return await post(`/adminChain/room/saas/storeRoomTypeAdd`, data)
  }
  async storeRoomTypeUpdate(data) {
    return await post(`/adminChain/room/saas/storeRoomTypeUpdate`, data)
  }
}
export default new API()
