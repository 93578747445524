<template>
  <el-dialog v-model="visible" title="库存明细" width="40%" :before-close="handleClose">
    <el-form :model="rowMsg" style="width: 96%; margin-left: 2%;margin-top: 10px;" label-width="100px">
      <el-form-item label="类型名称">
        <el-input
          placeholder="请输入类型名称"
          v-model="data.name"
          class="input-search-tel"
          @keyup.enter="searchLists"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注">
        <el-input placeholder="请输入备注" v-model="data.name" type="textarea" class="input-search-tel"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="handleClose">
          保存
        </el-button>
        <el-button @click="handleClose">
          取消
        </el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { ref, reactive, defineComponent, computed, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'AddShop',
  props: {
    dialogVisible: Boolean,
    rowMsg: Object,
  },
  setup(props, ctx) {
    const data = reactive({
      loading: false,
    })
    const visible = computed(() => {
      return props.dialogVisible
    })
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    //分页
    const handleSizeChange = val => {
      data.pageSize = val
      queryLists()
    }
    const handleCurrentChange = val => {
      data.currentPage = val
      queryLists()
    }
    return {
      handleSizeChange,
      handleCurrentChange,
      handleClose,
      visible,
      data,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
