<template>
  <el-dialog v-model="visible" :title="title" width="40%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="shopType"
      label-width="120px"
      :rules="rules"
      ref="elForm"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="统计分类" prop="parentCode">
        <el-select v-model="shopType.parentCode" placeholder="请选择统计分类">
          <el-option :label="item.name" :value="item.code" v-for="(item, index) in allShopTypes" ::key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="类型名称" prop="name">
        <el-input v-model="shopType.name" placeholder="请输入名称" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="类型图片" prop="pic">
        <el-upload
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage"
        >
          <img v-if="shopType.pic" :src="shopType.pic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="shopType.sort" class="mx-4" :min="1" :max="1000" controls-position="right" />
      </el-form-item>
      <!-- <el-form-item label="状态" prop="isShelves">
        <el-radio-group v-model="shopType.isShelves">
          <el-radio :label="1">上架</el-radio>
          <el-radio :label="0">下架</el-radio>
        </el-radio-group>
      </el-form-item> -->
      <el-form-item label="投放渠道" prop="channel">
        <el-checkbox-group v-model="shopType.channel">
          <el-checkbox :label="item.name" size="large" v-for="item in channelList">{{ item.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { reactive, defineComponent, computed, onMounted, ref } from 'vue'
import { ElMessage } from 'element-plus'
import _ from 'lodash'

import API from '../../service/api'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    shopType: Object,
    channelList: Array,
  },
  setup(props, ctx) {
    const allShopTypes = reactive([])
    const visible = computed(() => {
      return props.dialogVisible
    })

    const form = reactive({
      loadText: '正在火速加载数据...',
      loading: false,
      img: [],
    })

    onMounted(() => {
      queryAllType()
    })
    const changeImage = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img.push(file)
      props.shopType.pic = ''
      let fileName = file.file.name + new Date()
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.shopType.pic = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }
    const title = computed(() => {
      return Reflect.has(props.shopType, 'code') ? '修改商品类型' : '添加商品类型'
    })
    //查询统计分类
    const queryAllType = () => {
      API.getSelectList().then(res => {
        if (res.code === '200') {
          allShopTypes.push(...res.data)
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const rules = reactive({
      name: [{ required: true, message: '请填写类型名称', trigger: 'blur' }],
      pic: [{ required: true, message: '请上传图片', trigger: 'blur' }],
      parentCode: [{ required: true, message: '请选择分类', trigger: 'blur' }],
      channel: [{ required: true, message: '请选择投放渠道', trigger: 'blur' }],
      isShelves: [{ required: true, message: '请选择商品状态', trigger: 'blur' }],
    })

    const elForm = ref(null)
    //提交
    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let params = _.cloneDeep(props.shopType)
          params.type = 1
          params.channel = props.shopType.channel.join(',')
          params.pic = props.shopType.pic
          if (Reflect.has(props.shopType, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const add = params => {
      API.createCategory(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('添加成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const update = params => {
      API.updateCategory(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('编辑成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      handleSubmit,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      allShopTypes,
      rules,
      elForm,
      title,
      form,
      changeImage,
    }
  },
})
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
