<template>
  <div class="customer-source">
    <!-- 搜索条件 -->
    <el-form :inline="true">
      <el-form-item label="区域名称">
        <el-input placeholder="区域名称" v-model="filterTerm.regionName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="selectInfoPage" v-if="authMenus.query">查询</el-button>
        <el-button @click="resetFilter">重置</el-button>
        <el-button @click="addCustomerSource" v-if="authMenus.add">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格数据 -->
    <el-table :data="tableData">
      <el-table-column label="区域名称" prop="regionName" align="center"></el-table-column>
      <el-table-column label="区域门店数量" prop="size" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button type="text" @click="updateCustomerSource(scope.row)" v-if="authMenus.update">编辑</el-button>
          <el-button type="text" @click="deleteCustomerSource(scope.row)" v-if="authMenus.delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="filterTerm.currentPage"
      v-model:page-size="filterTerm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="filterTerm.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdate
    v-if="dialogVisible"
    :needUpdateInfo="needUpdateInfo"
    @handleCloseDialog="handleCloseOrOpenDialog"
  ></addOrUpdate>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import addOrUpdate from './add-or-update.vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
const authMenus = ref([])
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  selectInfoPage()
})
/** 需要更新的会员卡数据---dialog展示框展示*/
const needUpdateInfo = ref({})
const dialogVisible = ref(false)
/**
 * 关闭or打开dialog展示框
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  selectInfoPage()
}

/**过滤条件 */
const filterTerm = reactive({
  regionName: '',
  current: 1,
  pageSize: 10,
  total: 0,
})

/**
 * 重制
 */
const resetFilter = () => {
  filterTerm.regionName = ''
  selectInfoPage()
}

/**
 * 获取表格信息
 */
const selectInfoPage = async () => {
  if (authMenus.value.query) {
    let res = await API.getRegionList(filterTerm)
    if (res.code === '200') {
      tableData.value = res.data.records
      filterTerm.total = res.data.total
    } else {
      ElMessage.error(res.msg)
    }
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

/**
 * 新增门店区域
 */
const addCustomerSource = () => {
  needUpdateInfo.value = {
    storeCodeList: [],
  }
  handleCloseOrOpenDialog(true)
}
/**
 * 删除门店区域
 * @param {object} param  门店区域
 */
const deleteCustomerSource = async ({ regionCode }) => {
  ElMessageBox.confirm('确认删除当前门店区域吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.deleteRegionByRegionCode({ regionCode })
    if (res.code === '200') {
      ElMessage.success('删除成功')
    } else {
      ElMessage.error(res.msg)
    }
    selectInfoPage()
  })
}

/**
 * 修改门店区域
 * @param {*} row
 */
const updateCustomerSource = async row => {
  console.log(row)
  needUpdateInfo.value = {
    ...row,
  }
  handleCloseOrOpenDialog(true)
}

/**
 * 表单数据
 */
const tableData = ref([])

//分页
const handleSizeChange = val => {
  filterTerm.pageSize = val
  selectInfoPage()
}
const handleCurrentChange = val => {
  filterTerm.current = val
  selectInfoPage()
}
</script>

<style scoped lang="scss">
.customer-source {
  padding: 20px 10px;
  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
