<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
      <el-form-item label="营业时间">
        <el-date-picker
          value-format="YYYY-MM-DD HH:mm:ss"
          v-model="data.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          :default-time="data.defaultTime"
        />
      </el-form-item>
      <el-form-item label="门店区域">
        <el-cascader
          v-model="data.regionCodeList"
          :options="areaOptions"
          clearable
          filterable
          placeholder="请选择门店区域"
          @change="changeArea()"
          :show-all-levels="false"
          collapse-tags
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="门店">
        <el-select-v2
          v-model="data.storeCodeList"
          :options="data.storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          style="width: 250px;"
          multiple
          filterable
          collapse-tags
          collapse-tags-tooltip
        />
      </el-form-item>
      <el-form-item label="商品类型">
        <el-select v-model="data.goodsCategory" filterable placeholder="请选择商品类型" clearable>
          <el-option v-for="item in data.productTypes" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称">
        <el-input placeholder="请输入商品名称" v-model="data.goodsName" clearable></el-input>
      </el-form-item>
      <el-form-item label="会员电话">
        <el-input placeholder="请输入会员电话" v-model="data.telephone" clearable></el-input>
      </el-form-item>
      <el-form-item label="会员姓名">
        <el-input placeholder="请输入会员姓名" v-model="data.nickName" clearable></el-input>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input placeholder="请输入订单号" v-model="data.orderCode" clearable></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="searchLists" type="primary">
          查询
        </el-button>
        <el-button @click="resetData">
          重置
        </el-button>
        <el-button @click="exportData">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="data.tableData" border style="width: 96%; margin-left: 2%;" :emptyText="emptyTableText">
      <el-table-column width="180">
        <el-table-column prop="roomName" label="包厢号"> </el-table-column>
        <el-table-column show-overflow-tooltip width="100px" prop="goodsCategory" label="商品类型"> </el-table-column>
        <el-table-column show-overflow-tooltip width="100px" prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column show-overflow-tooltip width="100px" prop="goodsCost" label="商品成本"> </el-table-column>
        <el-table-column show-overflow-tooltip width="100px" prop="unit" label="单位"> </el-table-column>
        <el-table-column show-overflow-tooltip width="100px" prop="warehouse" label="仓库"> </el-table-column>
        <el-table-column show-overflow-tooltip width="100px" prop="userName" label="会员姓名"> </el-table-column>
        <el-table-column show-overflow-tooltip width="100px" prop="telephone" label="会员电话"> </el-table-column>
      </el-table-column>

      <el-table-column label="存酒">
        <el-table-column show-overflow-tooltip prop="storageWineCode" label="存酒单号" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.storageWineCode : scope.row.type === 1 ? scope.row.storageWineCode : '' }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="storageWineNum" label="存酒数量" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.storageWineNum : scope.row.type === 1 ? scope.row.storageWineNum : 0 }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="storageWineAmount" label="存酒金额" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.storageWineAmount : scope.row.type === 1 ? scope.row.storageWineAmount : 0 }}
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column label="取酒">
        <el-table-column show-overflow-tooltip prop="takeWineCode" label="取酒单号" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.takeWineCode : scope.row.type === 2 ? scope.row.takeWineCode : '' }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="takeWineNum" label="取酒数量" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.takeWineNum : scope.row.type === 2 ? scope.row.takeWineNum : 0 }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="takeWineAmount" label="取酒金额" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.takeWineAmount : scope.row.type === 2 ? scope.row.takeWineAmount : 0 }}
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column label="续存">
        <el-table-column show-overflow-tooltip prop="storageWineCode" label="续存单号" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.storageWineCode : scope.row.type === 3 ? scope.row.storageWineCode : '' }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="continuousWineNum" label="续存数量" width="100px">
          <template v-slot="scope">
            {{ !scope.row.type ? scope.row.continuousWineNum : scope.row.type === 3 ? scope.row.continuousWineNum : 0 }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="storageWineAmount" label="续存金额" width="100px">
          <template v-slot="scope">
            {{
              !scope.row.type
                ? scope.row.continuousWineAmount
                : scope.row.type === 3
                ? scope.row.continuousWineAmount
                : ''
            }}
          </template>
        </el-table-column>
      </el-table-column>

      <el-table-column>
        <el-table-column show-overflow-tooltip prop="createName" label="操作人"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="createTime" label="操作时间" width="100px"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="remark" label="备注"> </el-table-column>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import { downLoad } from '../../../../lin/util/util'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { reactive, onMounted, ref, nextTick } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import clearSelect from '@/lin/util/clearSelect2.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
}

const props = defineProps({
  auths: {},
})
const data = reactive({
  storeCodeList: [],
  areaCode: [],
  regionCodeList: [],
  telephone: '',
  goodsName: '',
  goodsCategory: '',
  orderCode: '',
  nickName: '',
  time: [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ],
  defaultTime: new Date(2000, 1, 1, 8, 0, 0), // '12:00:00'
  authMenus: {},
  tableData: [],
  fullscreenLoading: false,
  storeList: [],
  productTypes: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
})

const route = useRoute()

onMounted(() => {
  route.meta.msg = props.auths
  operationAuth(route, data.authMenus)
  getStores()
  getProfuctType()
  // queryLists()
  getAreas()
})

const getStores = async () => {
  let res = await API.getStoreListByName({ list: data.areaCode })
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
}

/**
 * 获取商品类型
 */
const getProfuctType = () => {
  API.selectByType({
    pageSize: 100,
    current: 1,
    type: 1,
  }).then(res => {
    data.productTypes = res.data.records.map(item => {
      return {
        value: item.code,
        label: item.name,
      }
    })
  })
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.goodsCategory = ''
  data.telephone = ''
  data.goodsName = ''
  data.storeCodeList = []
  data.regionCodeList = []
  data.areaCode = []
  data.orderCode = ''
  data.nickName = ''
  data.time = [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ]
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
  data.pageSize = 10
  data.pageNum = 1
  searchLists()
}

const exportData = async () => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  data.fullscreenLoading = true
  let model = {}
  if (!data.time) {
    model.startTime = ''
    model.endTime = ''
  } else {
    model.startTime = data.time[0]
    model.endTime = data.time[1]
  }
  model.storeCodeList = data.storeCodeList
  model.regionCodeList = data.areaCode
  model.telephone = data.telephone
  model.goodsCategory = data.goodsCategory
  model.goodsName = data.goodsName
  model.orderCode = data.orderCode
  model.nickName = data.nickName

  if (!model.telephone && !model.orderCode && !getDays(model.startTime, model.endTime)) {
    data.fullscreenLoading = false
    return
  }
  let url = '/report/export/reportStorageWineDetails'
  const res = await API.exportReport({ url, data: model })
  data.fullscreenLoading = false
  downLoad(res, '存取酒明细.xlsx')
}
const getDays = (sDate1, sDate2) => {
  if (!sDate1 || !sDate2) {
    ElMessage.warning('请设置查询时间')
    return false
  }

  const DAY = 32
  //sDate1和sDate2是2002-12-18格式
  var dateSpan, tempDate, iDays
  sDate1 = Date.parse(sDate1)
  sDate2 = Date.parse(sDate2)
  dateSpan = sDate2 - sDate1
  dateSpan = Math.abs(dateSpan)
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000)) + 1
  if (iDays > DAY) {
    ElMessage.warning('查询或导出时间范围不能超过一个月')
    return false
  }
  return true
}
const emptyTableText = ref('请先设置查询条件，再点击查询数据')

const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  let params = {
    goodsCategory: data.goodsCategory,
    orderCode: data.orderCode,
    telephone: data.telephone,
    goodsName: data.goodsName,
    storeCodeList: data.storeCodeList,
    regionCodeList: data.areaCode,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    nickName: data.nickName,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  if (!params.telephone && !params.orderCode && !getDays(params.startTime, params.endTime)) {
    return
  }
  data.fullscreenLoading = true

  API.getReportStorageWineDetailsPages(params)
    .then(res => {
      if (res.code === '200') {
        data.tableData = res.data?.records || []
        data.total = res.data?.totalRecords || 0
        emptyTableText.value = data.tableData.length == 0 ? '暂无数据' : ''
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.fullscreenLoading = false
    })
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 30px;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}

::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
</style>
