<template>
  <div class="table-search-tab" style="height: 76px;line-height: 76px;">
    <div class="search-left">
      <span class="title">组织名称</span>

      <el-cascader
        v-model="data.code"
        filterable
        :options="data.orgs"
        :props="defaultProps"
        @change="searchLists()"
        clearable
        style="width: 200px;margin-left: 10px;margin-right: 10px;"
      />
      <el-checkbox v-model="data.isContainStop" label="只看启用" @change="searchLists()" />
      <el-button
        type="primary"
        class="search-button"
        @click="searchLists()"
        style="margin-left: 20px;"
        v-if="data.authMenus.query"
        >查询</el-button
      >
      <el-button @click="reseatFilter">重置</el-button>
    </div>
    <div class="search-right">
      <el-button type="primary" @click="addShop" v-if="data.authMenus.add"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
    </div>
  </div>
  <vxe-table
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
    round
    show-overflow
    height="800"
    align="center"
    ref="xTable"
    :row-config="{ keyField: 'id', height: 40 }"
    :tree-config="{ transform: true, reserve: true }"
    :scroll-y="{ gt: 20 }"
    :data="data.tableData"
    style="width: 96%; margin-left: 2%; margin-bottom: 20px;"
  >
    <vxe-column title="名称" tree-node>
      <template #default="scope">
        <span
          style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer;"
          :title="scope.row.name"
        >
          {{ scope.row.name }}</span
        >
      </template>
    </vxe-column>
    <vxe-column title="类型">
      <template #default="scope">
        <el-tag v-if="scope.row.type === 1">公司部门</el-tag>
        <el-tag v-else-if="scope.row.type === 2" type="warning">门店区域</el-tag>
        <el-tag v-else type="success">实体门店</el-tag>
      </template>
    </vxe-column>
    <vxe-column title="状态" width="100">
      <template #default="scope">
        <el-tag v-if="scope.row.state === 1">启用</el-tag>
        <el-tag v-else-if="scope.row.state === 0" type="error">停用</el-tag>
      </template>
    </vxe-column>
    <vxe-column field="sort" title="排序" width="100"></vxe-column>
    <vxe-column title="操作" width="250">
      <template #default="scope">
        <el-button
          type="text"
          @click="showDetail(1, scope.row)"
          v-if="data.authMenus.add && scope.row.state === 1 && (scope.row.type === 1 || scope.row.type === 2)"
          >新增下级组织</el-button
        >
        <el-button type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update">修改</el-button>
        <el-button type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.delete">{{
          scope.row.state === 1 ? '停用' : '启用'
        }}</el-button>
      </template>
    </vxe-column>
  </vxe-table>
  <AddOrganize
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :orgMsg="data.orgMsg"
    :isEdit="data.isEdit"
    :orgs="data.orgs"
    @update-dialogVisible="updateDialogVisible"
  ></AddOrganize>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import _ from 'lodash'
import { reactive, onMounted, ref } from 'vue'
import AddOrganize from './addOrganize/index.vue'
import API from '../../service/api'
import { ElMessage } from 'element-plus'

const data = reactive({
  code: [],
  isContainStop: true,
  tableData: [],
  orgTypes: [
    {
      label: '公司部门',
      value: 1,
    },
    {
      label: '门店区域',
      value: 2,
    },
    {
      label: '实体门店',
      value: 3,
    },
  ],
  dialogVisible: false,
  orgMsg: {},
  isEdit: false,
  orgs: [],
  authMenus: {},
  loading: false,
})
const route = useRoute()
const xTable = ref(null)

const defaultProps = {
  label: 'name',
  value: 'code',
  children: 'child',
  checkStrictly: true,
}

//重置
const reseatFilter = () => {
  data.code = ''
  searchLists()
}

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  getOrganizationalByParentCode()
})

const searchLists = () => {
  if (data.authMenus.query) {
    data.loading = true
    let query = {
      isOnlyEnable: data.isContainStop ? 1 : 0,
    }
    if (Array.isArray(data.code) && data.code.length !== 0) {
      query.code = data.code[data.code.length - 1]
    } else {
      query.code = '0'
    }

    API.getOrganizationalListByCode(query).then(res => {
      if (res.code === '200') {
        data.tableData = res.data.map(item => ({
          ...item,
          id: item.code,
          parentId: item.parentCode,
        }))
        data.loading = false
      } else {
        data.loading = false

        ElMessage.error(res.msg)
      }
    })
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//获取组织
const getOrganizationalByParentCode = () => {
  API.getOrganizationalByCode({ isOnlyEnable: 1, code: 0 }).then(res => {
    if (res.code === '200') {
      data.orgs = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//添加
const addShop = () => {
  data.dialogVisible = true
  data.isEdit = true
  data.orgMsg = { state: 1 }
}
const showRow = rowMsg => {
  data.isEdit = true
  data.orgMsg = {
    parentCode: rowMsg.code,
  }
  data.dialogVisible = true
}
const editRow = rowMsg => {
  data.isEdit = true
  data.orgMsg = rowMsg
  data.dialogVisible = true
}
const startOrStopRow = rowMsg => {
  API.updateOrganizational({
    code: rowMsg.code,
    state: rowMsg.state === 1 ? 0 : 1,
    parentCode: rowMsg.parentCode,
  }).then(res => {
    if (res.code === '200') {
      ElMessage.success('更新成功')
      searchLists()
      getOrganizationalByParentCode()
    } else {
      ElMessage.error(res.msg)
    }
  })
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showRow(rowMsg)
      break
    case 2:
      editRow(rowMsg)
      break
    case 3:
      startOrStopRow(rowMsg)
      break
  }
}
const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    searchLists()
    getOrganizationalByParentCode()
  }
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    .title {
      width: 100px;
      margin-left: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: right;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }
    .search-button {
      width: 100px;
      height: 25px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
    .search-button {
      width: 144px;
      height: 25px;
    }
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
