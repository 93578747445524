export default function clearSelect(className) {
  let allSelect = Array.from(document.querySelectorAll(`.${className} .el-select-v2__selected-item`))
  let filter = Array.from(document.querySelectorAll(`.${className} .el-select-v2__input-wrapper`))
  allSelect.forEach(item => {
    let notNeedDelContentHtml = filter.find(filterItem => filterItem === item)
    if (!notNeedDelContentHtml) {
      item.innerHTML = ''
    }
  })
}
