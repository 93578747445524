<template>
  <el-dialog v-model="visible" :title="title" width="40%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="unitConfig" label-width="120px" :rules="rules" ref="elForm">
      <el-form-item label="包厢类型名称" prop="name">
        <el-input v-model="unitConfig.name" placeholder="请输入包厢类型名称" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="买钟基准价(元)" prop="price">
        <el-input-number
          v-model="unitConfig.price"
          placeholder="请输入买钟基准价"
          class="mx-4"
          :min="1"
          controls-position="right"
        />
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="unitConfig.level" class="mx-4" :min="1" controls-position="right" />
      </el-form-item>
      <el-form-item label="状态" prop="isEnable">
        <el-radio-group v-model="unitConfig.isEnable">
          <el-radio :label="1">启用</el-radio>
          <el-radio :label="2">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="备注">
        <el-input type="textarea" v-model="unitConfig.remarks" />
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;" :disabled="!isEdit">确定</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, toRaw } from 'vue'
import { ElMessage } from 'element-plus'

import API from '../../service/api'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    unitConfig: Object,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })
    const title = computed(() => {
      return '包厢类型'
    })

    const rules = reactive({
      name: [{ required: true, message: '请填写包厢类型名称', trigger: 'blur' }],
      isEnable: [{ required: true, message: '请选择包厢类型状态', trigger: 'blur' }],
      price: [{ required: true, trigger: 'blur', message: '请填写买钟基准价' }],
    })

    const elForm = ref(null)

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          props.unitConfig.storeCode = 'K0000'
          if (Reflect.has(props.unitConfig, 'code')) {
            update()
          } else {
            add()
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const add = () => {
      API.storeRoomTypeAdd(props.unitConfig).then(res => {
        if (res.code === '200') {
          ElMessage.success('保存成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const update = () => {
      API.storeRoomTypeUpdate(props.unitConfig).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return { handleSubmit, title, onSubmit, handleClose, backtrack, props: { multiple: true }, visible, rules, elForm }
  },
})
</script>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 130px !important;
  }
}
</style>
