<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-08-01 15:34:15
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-08-11 16:39:23
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/purchase-details/order-template/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="empty-bottle-box">
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap">
      <el-table-column prop="storeName" label="门店" />
      <el-table-column prop="supplierName" label="供应商" />
      <el-table-column prop="code" label="空瓶单号" />
      <el-table-column prop="freight" label="运费" />
      <el-table-column prop="address" label="送货联系人">
        <template #default="scope">
          {{ scope.row.logisticsContent.userName }} - {{ scope.row.logisticsContent.telephone }}
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
    <elDrawerVue ref="drawer" :title="'订单号：' + detailData.code" :state="detailData.state">
      <ul class="detail-drawer">
        <li>创建时间： {{ detailData.createTime }}</li>
        <li>确定时间：{{ detailData.receivingTime }}</li>
        <li>
          收货信息： {{ addressContent && addressContent.contractor }} {{ addressContent && addressContent.telephone }}
          {{ addressContent && addressContent.address }}
        </li>
      </ul>
      <TableVue :columns="columns" :data="detailData.itemList" class="table-content" />
    </elDrawerVue>
  </div>
</template>

<script>
import elDrawerVue from '../../../../../component/base/el-Drawer/el-drawer.vue'
import Pagination from '../../../component/pagination.vue'
import TableVue from '../component/table.vue'
import api from '../../../service/index'
export default {
  components: {
    elDrawerVue,
    Pagination,
    TableVue,
  },
  data() {
    return {
      tableData: null,
      formInline: {
        user: '',
        region: '',
      },
      status: '待收货',
      totalRecords: null,
      totalPage: null,
      model: {
        endTime: '',
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        supplierCode: '',
        storeCodeList: [],
      },
      columns: [
        {
          prop: 'skuName',
          label: '空瓶名称',
        },
        {
          prop: 'unit',
          label: '回收单位',
        },
        {
          prop: 'skuPrice',
          label: '回收价格',
        },
        {
          prop: 'num',
          label: '数量',
        },
        {
          prop: 'address',
          label: '回收单品总价',
          formatter: val => {
            return val.skuPrice * val.num
          },
        },
      ],
      detailData: {},
      addressContent: {},
    }
  },
  mounted() {
    this.getTableData(this.model)
  },
  methods: {
    getTableData(model) {
      api.getEmptyBottleListPages(model).then(res => {
        this.tableData = res.data.records.map(item => {
          item.logisticsContent = JSON.parse(item.logisticsContent)
          return item
        })
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    showDetail(index, row) {
      this.$refs.drawer.detailModal = true
      api.getEmptyBottleDetail({ code: row.code }).then(res => {
        this.detailData = res.data
        this.addressContent = JSON.parse(this.detailData.addressContent)
      })
    },
    imgfilter(imgobj) {
      let imgs = []
      imgobj.forEach(obj => {
        imgs.push(obj.src)
      })
      return imgs
    },
  },
}
</script>
<style lang="scss" scoped>
.empty-bottle-box {
  .detail-drawer {
    li {
      margin-bottom: 8px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }

  .logistics-content-box {
    margin-top: 32px;

    h2 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;

      span:last-child {
        font-size: 12px;
      }
    }
  }

  .commodity-img-list {
  }

  .after-reason-box {
    p:last-child {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }

  .title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    margin-top: 32px;
    margin-bottom: 12px;
  }

  .table-content {
    margin-top: 81px;
  }
}
</style>
