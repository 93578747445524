<template>
  <div class="run-data">
    <div class="run-data-content">
      <el-radio-group v-model="tabPosition" style="margin-bottom: 30px;">
        <el-radio-button label="left">精准重跑</el-radio-button>
        <el-radio-button label="center">批量重跑</el-radio-button>
        <el-radio-button label="right">批量重跑（全量门店）</el-radio-button>
      </el-radio-group>
      <el-form ref="form" :model="form" v-if="tabPosition == 'left'" :rules="rules">
        <el-form-item label="重跑数据" prop="type">
          <el-radio-group v-model="form.type" style="text-align:left">
            <el-radio label="reportRepeatRunOpenRoomGoods">场次、商品、房时报表</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="日期" prop="businessDay">
          <el-date-picker
            :disabled-date="disabledDate"
            v-model="form.businessDay"
            type="date"
            placeholder="选择日期"
            style="width: 500px;"
            value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="场次号" prop="openCode">
          <el-input v-model="form.openCode" style="width:500px;" maxlength="25" placeholder="请填写场次号"></el-input>
        </el-form-item>
      </el-form>
      <el-form ref="form" :model="form" v-else-if="tabPosition == 'center'" :rules="rules">
        <el-form-item label="重跑数据" prop="type">
          <el-checkbox-group v-model="type">
            <el-checkbox :label="3">商品提成报表</el-checkbox>
            <el-checkbox :label="1">场次、商品、房时报表</el-checkbox>
            <el-checkbox :label="2">营收场次分析报表</el-checkbox>
            <el-checkbox :label="4">营收储值分析报表</el-checkbox>
            <el-checkbox :label="5">结算报表</el-checkbox>
          </el-checkbox-group>
          <!-- <el-radio-group v-model="form.type" style="text-align:left">
            <div style="height:20px">
              <el-radio label="reportRepeatRunBill">商品提成报表</el-radio>
              <el-radio label="reportRepeatRunOpenRoomGoods">场次、商品、房时报表</el-radio>
            </div>
            <div style="height:30px">
              <el-radio label="reportRepeatRunOpenRevenue">营收场次分析报表</el-radio>
              <el-radio label="reportRepeatRunStoredCardsRevenue">营收储值分析报表</el-radio>
            </div> -->
          <!-- </el-radio-group> -->
        </el-form-item>
        <el-form-item label="日期" prop="businessDay">
          <el-date-picker
            :disabled-date="disabledDate"
            v-model="form.businessDay"
            type="daterange"
            placeholder="选择日期"
            style="width: 500px;"
            value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="门店" prop="storeCode">
          <el-select-v2
            v-model="form.storeCode"
            :options="shops"
            placeholder="请选择门店"
            filterable
            clearable
            style="width: 500px;"
          />
        </el-form-item>
      </el-form>
      <el-form ref="form" :model="form" v-else :rules="rules">
        <el-form-item label="重跑数据" prop="type">
          <el-checkbox-group v-model="type">
            <el-checkbox :label="3">商品提成报表</el-checkbox>
            <el-checkbox :label="1">场次、商品、房时报表</el-checkbox>
            <el-checkbox :label="2">营收场次分析报表</el-checkbox>
            <el-checkbox :label="4">营收储值分析报表</el-checkbox>
            <el-checkbox :label="5">结算报表</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="日期" prop="businessDay">
          <el-date-picker
            :disabled-date="disabledDate"
            v-model="form.businessDay"
            type="daterange"
            placeholder="选择日期"
            style="width: 500px;"
            value-format="YYYY-MM-DD"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <el-button type="primary" style="width: 200px;" @click="handleReRun">重跑</el-button>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/index'
export default {
  data() {
    return {
      tabPosition: 'left',
      shops: [],
      disabledDate(time) {
        return time.getTime() > Date.now() //禁止选择今天以后的时间
        //return time.getTime() < Date.now() - 8.64e7;//禁止选择今天以前的时间
      },
      type: [],
      form: {
        storeCode: '',
        openCode: '',
        businessDay: '',
        type: 'reportRepeatRunOpenRoomGoods',
      },
      rules: {
        type: [
          {
            required: true,
            message: '请选择重跑数据',
            trigger: 'change',
          },
        ],
        storeCode: [
          {
            required: true,
            message: '请选择门店',
            trigger: 'change',
          },
        ],
        openCode: [
          {
            required: true,
            message: '请输入场次号',
            trigger: 'change',
          },
        ],
        businessDay: [
          {
            required: true,
            message: '请选择营业日期',
            trigger: 'change',
          },
        ],
      },
    }
  },
  watch: {
    tabPosition(value) {
      if (value == 'left') {
        this.form.type = 'reportRepeatRunOpenRoomGoods'
        this.resetForm()
      } else {
        this.form.type = this.type = [1, 2, 3, 4, 5]
        this.resetForm()
      }
    },
  },
  mounted() {
    this.getShops()
  },
  methods: {
    resetForm() {
      this.form.storeCode = ''
      this.form.openCode = ''
      this.form.businessDay = ''
    },
    getShops() {
      API.getStoreList().then(res => {
        if (res.code === '200') {
          this.shops = res.data.map(item => {
            return { value: item.code, label: item.code + '--' + item.name }
          })
        } else {
          ElMessage.error(res.msg)
        }
      })
    },
    //获取两个时间段中的每天
    getDates(start, end) {
      const formattedDate = data => {
        const date = new Date(data)
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0')
        const day = date
          .getDate()
          .toString()
          .padStart(2, '0')
        return `${year}-${month}-${day}`
      }

      let dateArr = []
      let startDate = new Date(start)
      let endDate = new Date(end)
      while (startDate <= endDate) {
        dateArr.push(formattedDate(new Date(startDate).toLocaleDateString().replace(/\//g, '-')))
        startDate.setDate(startDate.getDate() + 1)
      }

      return dateArr
    },
    //重跑
    handleReRun() {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          let params = {}
          switch (this.form.type) {
            case 'reportRepeatRunOpenRoomGoods':
              if (this.tabPosition == 'left') {
                params = {
                  openCode: this.form.openCode,
                  businessDay: this.form.businessDay,
                  storeCode: '',
                }
              } else {
                params = {
                  storeCode: this.form.storeCode,
                  type: this.type.join(','),
                }
              }
              break
            default:
              params = {
                storeCode: this.form.storeCode,
                type: this.type.join(','),
              }
              break
          }
          let res

          if (this.tabPosition == 'left') {
            res = await API[this.form.type](params)
          } else {
            params.businessDay = this.getDates(this.form.businessDay[0], this.form.businessDay[1]).join(',')

            let newType = []
            if (params.type.includes(2)) {
              newType.push(2)
            }
            if (params.type.includes(4)) {
              newType.push(4)
            }
            if (params.type.includes(3)) {
              newType.push(3)
            }
            if (newType.length > 0) {
              res = await API.reportDataBaseBetweenMonthNew({
                ...params,
                type: newType.join(','),
              })
            }

            let type = []
            if (params.type.includes(1)) {
              type.push(1)
            }

            if (params.type.includes(5)) {
              type.push(5)
            }
            if (type.length > 0) {
              res = await API.reportDataBaseBetweenMonth({
                ...params,
                type: type.join(','),
              })
            }
          }
          if (res.code !== 200) {
            ElMessage.error(res.msg)
          } else {
            ElMessage.success('操作成功')
          }
        } else {
          ElMessage.error(res.msg)
          return false
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.run-data {
  width: 100%;
  display: flex;
  padding: 30px 0px 0px 0px;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  .run-data-content {
    width: 800px;
    .el-radio-group {
      text-align: center;
    }
    .el-form-item {
      ::v-deep .el-form-item__label {
        width: 150px !important;
      }
      ::v-deep .el-select-v2 {
        .el-select-v2__placeholder {
          display: block;
          overflow: hidden;
          width: 160px;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
