<template>
  <Drawerform :isDisabled="true" title="门店分配" ref="mdTreeDrawer" size="25%">
    <template v-slot:content>
      <div style="margin-top:50px;margin-left:1vw">
        <el-input style="width:20vw" v-model="filterText" placeholder="请输入搜索关键字" />
        <el-tree
          default-expand-all
          style="margin-top:20px;"
          class="mdtree"
          node-key="code"
          ref="mdtree"
          show-checkbox
          :data="mddata"
          :props="defaultProps"
          :filter-node-method="filterNode"
          @node-click="handleNodeClick"
        />
      </div>
      <div class="footer">
        <el-button @click="cancel">取消</el-button>
        <el-button @click="SelectMds" type="primary">保存</el-button>
      </div>
    </template>
  </Drawerform>
</template>

<script>
import { Drawerform } from '@/component/base'
import _axios, { post, get, put } from '@/lin/plugin/axios'
const getMdtree = async () => {
  const res = await get(`store/listWithArea`, { showType: 2 })
  return res
}
export default {
  name: 'mdtree',
  components: {
    Drawerform,
  },
  data() {
    return {
      mddata: [],
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label',
        code: 'code',
      },
    }
  },
  props: {
    checkitems: {
      type: Array,
      default: [],
    },
  },

  computed: {},
  watch: {
    filterText(val) {
      this.$refs.mdtree.filter(val)
    },
  },
  mounted() {
    this.getmddata()
  },
  methods: {
    getmddata() {
      getMdtree().then(res => {
        if (res.code == '200') {
          this.mddata.push(res.data)
        }
      })
    },
    SelectMds() {
      let checkeditems = this.$refs.mdtree.getCheckedKeys()
      const chekedmds = checkeditems.filter(item => item.indexOf('K') > -1)
      this.$emit('mdChange', chekedmds)
    },
    filterNode(value, data) {
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
    cancel() {
      this.$refs.mdTreeDrawer.cancelSubmit()
    },
    handleNodeClick(data) {
      console.log(data)
    },
    openmdtree() {
      this.$refs.mdTreeDrawer.openDrawer()
    },
  },
}
</script>

<style scoped lang="scss">
.mdtree {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
.footer {
  text-align: right;
  line-height: 80px;
  padding-right: 20px;
}
</style>
