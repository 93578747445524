<!--
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-08-26 17:08:04
 * @LastEditTime: 2024-09-05 18:56:20
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/plugin/member_manager/view/top-up-package-manager/index.vue
 * @Description:
-->
<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="通用充值套餐" name="generalPayCoupon" v-if="isShowReport('generalPayCoupon')">
      <generalPayCoupon :auths="authType.generalPayCoupon" />
    </el-tab-pane>
    <el-tab-pane label="门店卡充值套餐" name="storePayCoupon" v-if="isShowReport('storePayCoupon')">
      <storePayCoupon :auths="authType.storePayCoupon" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import generalPayCoupon from './generalPayCoupon/index.vue'
import storePayCoupon from './storePayCoupon/index.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  storePayCoupon: {}, // 门店卡充值套餐
  generalPayCoupon: {}, // 通用卡充值套餐
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.storePayCoupon = authAllMenus.find(a => a.url === 'storePayCoupon') || { childrens: [] }
  authType.generalPayCoupon = authAllMenus.find(a => a.url === 'generalPayCoupon') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsIncomeAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
