<template>
  <el-dialog v-model="visible" title="修改信息" width="40%" :before-close="handleClose">
    <el-form :model="userMsg" label-width="120px" ref="elform" :rules="rules">
      <el-form-item label="会员姓名" placeholder="请填写会员姓名" prop="userName">
        <el-input v-model="userMsg.userName" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="手机号" placeholder="请填写手机号" prop="newTelephone">
        <el-input v-model="userMsg.newTelephone" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="性别">
        <!-- <el-radio-group v-model="userMsg.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group> -->
        <span>{{ userMsg.gender == 1 ? '男' : '女' }}</span>
      </el-form-item>

      <el-form-item label="会员余额">
        <span>{{ userMsg.totalAmount }}</span>
      </el-form-item>
      <el-form-item label="注册门店">
        <span>{{ userMsg.storeCode + '_' + userMsg.storeName }}</span>
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button @click="handleClose" style="width:100px">取消</el-button>
      <el-button @click="handleSubmit" style="width:100px" type="primary">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, h } from 'vue'
import API from '../../service/index'
import _ from 'lodash'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    userMsg: Object,
  },
  setup(props, ctx) {
    const elform = ref(null)
    const rules = reactive({
      userName: [{ required: true, message: '请填写姓名', trigger: 'change' }],
      newTelephone: [{ required: true, message: '请填写手机号', trigger: 'change' }],
    })
    const visible = computed(() => {
      return props.dialogVisible
    })

    const update = data => {
      API.checkOrUpdateCustomerPhone(data)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('编辑成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else if (res.code == '500200') {
            let user = res.data
            ElMessageBox({
              title: '已存在该手机号的会员，是否覆盖？',
              message: h('div', null, [
                h('p', null, `会员姓名：${user.name || user.nickName || ''}`),
                h('p', null, `手机号：${user.telephone}`),
                h('p', null, `会员余额：${user.totalAmount || 0}`),
                h('p', null, `注册门店：${user.storeCode}_${user.storeName}`),
              ]),
              showCancelButton: true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
            }).then(async () => {
              let res = await API.forceUpdatesCustomerPhone(data)
              if (res.code == 200) {
                ElMessage.success('编辑成功')
                ctx.emit('update-dialogVisible', { update: false, search: true })
              } else {
                ElMessage.error(res.msg)
              }
            })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => {})
    }

    const onSubmit = () => {
      validFormTop()
    }

    const validFormTop = async formTop => {
      if (!elform) return
      await elform.value.validate((valid, fields) => {
        if (valid) {
          let saveData = _.cloneDeep(props.userMsg)

          if (!/^\d+$/.test(saveData.newTelephone)) {
            ElMessage.warning('请输入正确手机号')
            return
          }
          update({
            ...saveData,
            newTelephone: saveData.newTelephone.trim(),
          })
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      elform,
      handleClose,
      visible,
      rules,
      handleSubmit,
    }
  },
})
</script>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
.no-autofill-pwd {
  ::v-deep .el-input__inner {
    -webkit-text-security: disc !important;
  }
}
</style>
