<!--
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-09-05 11:29:33
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/app.vue
-->
<template>
  <el-config-provider :locale="locale">
    <div id="app">
      <router-view />
      <!-- <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" /> -->

      <!-- <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.name"></component>
        </keep-alive>
        <component :is="Component" v-if="!$route.meta.keepAlive" :key="$route.name">></component>
      </router-view> -->
    </div>
  </el-config-provider>
</template>

<script>
import { ElConfigProvider } from 'element-plus'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
import { setWaterMark } from '@/utils/waterMark.js'

//我们把代码改一下

export default {
  components: {
    [ElConfigProvider.name]: ElConfigProvider,
  },
  data() {
    return {
      locale: zhCn,
    }
  },
  mounted() {
    document.getElementById('loader').style.display = 'none'
    this.setWaterMarkByUser()
    this.handleWindowResize(this.setWaterMarkByUser)
  },
  methods: {
    //设置水印
    setWaterMarkByUser() {
      const user = JSON.parse(localStorage.getItem('vuex'))
      let msg =
        (user?.user?.username || '') +
        ' ' +
        (user?.user?.userInfo?.userSaasUserVO?.cellPhone?.substring(
          user?.user?.userInfo?.userSaasUserVO?.cellPhone?.length - 4,
        ) || '')
      setWaterMark('', msg || '')
    },

    //窗口大小改变事件
    handleWindowResize(callBack) {
      const debouce = (fn, dela) => {
        let timer
        return function() {
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            fn()
          }, 1000)
        }
      }
      const cancalDebounce = debouce(callBack, 500)
      window.addEventListener('resize', cancalDebounce)
    },
  },
}
</script>

<style lang="scss">
@import url('https://at.alicdn.com/t/font_3356965_5awcq2o68ae.css');

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition-delay: 99999s;
    transition: color 99999s ease-out, background-color 99999s ease-out;
  }
}
</style>
