<template>
  <el-dialog v-model="visible" title="供应商管理" width="60%" :before-close="handleClose">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="基本信息" name="1">
        <el-form :model="needEditMsg" label-width="120px" class="form">
          <el-form-item label="三方供应商编码">
            <el-input v-model="needEditMsg.supplierCode" placeholder="请填写三方供应商编码" :disabled="true" />
          </el-form-item>
          <el-form-item label="三方供应商名称">
            <el-input v-model="needEditMsg.supplierName" placeholder="请填写三方供应商名称" :disabled="true" />
          </el-form-item>
          <el-form-item label="供应商简称">
            <el-input v-model="needEditMsg.supplierAbbreviationName" placeholder="请填写供应商简称" :disabled="true" />
          </el-form-item>
          <el-form-item label="供应商类型">
            <el-input v-model="needEditMsg.supplierType" placeholder="请填写供应商类型" :disabled="true" />
          </el-form-item>
          <el-form-item label="国家/地区" prop="countriesAndRegions">
            <el-input v-model="needEditMsg.countriesAndRegions" placeholder="请填写国家/地区" :disabled="true" />
          </el-form-item>
          <el-form-item label="统一社会信用代码" prop="unifyTheSocialCreditCode">
            <el-input
              v-model="needEditMsg.unifyTheSocialCreditCode"
              placeholder="请填写统一社会信用代码"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item label="纳税人类型" prop="taxpayerType">
            <el-input v-model="needEditMsg.taxpayerType" placeholder="请填写纳税人类型" :disabled="true" />
          </el-form-item>
          <el-form-item label="供应商地区" prop="countyCode">
            <div style="display: flex; justify-content: space-between;">
              <el-input v-model="needEditMsg.provinceName" :disabled="true" />
              <el-input v-model="needEditMsg.cityName" :disabled="true" />
              <el-input v-model="needEditMsg.countyName" :disabled="true" />
            </div>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="needEditMsg.addressDetails" :disabled="true" />
          </el-form-item>
          <el-form-item label="联系人">
            <el-input v-model="needEditMsg.contactPerson" :disabled="true" />
          </el-form-item>
          <el-form-item label="联系电话" prop="contactPersonPhone">
            <el-input v-model="needEditMsg.contactPersonPhone" :disabled="true" />
          </el-form-item>

          <el-form-item label="供应商状态" prop="supplierStatus">
            {{ needEditMsg.supplierStatus }}
          </el-form-item>
          <el-form-item label="冻结状态" prop="frozenState">
            {{ needEditMsg.frozenState }}
          </el-form-item>
        </el-form>
      </el-collapse-item>
      <el-collapse-item title="结算信息" name="2">
        <el-table :data="needEditMsg.bankList">
          <el-table-column show-overflow-tooltip prop="countriesAndRegions" label="国家/地区" align="center" />
          <el-table-column show-overflow-tooltip prop="currency" label="收款币种" align="center" />
          <el-table-column show-overflow-tooltip prop="bankAccount" label="账号" align="center" />
          <el-table-column show-overflow-tooltip prop="bankAccountName" label="账户名称" align="center" />
          <el-table-column show-overflow-tooltip prop="bankName" label="银行名称" align="center" />
          <el-table-column show-overflow-tooltip prop="depositBank" label="开户行" align="center" />
          <el-table-column show-overflow-tooltip prop="identityCard" label="联行号" align="center" />
          <el-table-column show-overflow-tooltip prop="extend" label="其他信息" align="center" />
        </el-table>
      </el-collapse-item>
    </el-collapse>
    <p style="margin-top: 20px;">最近同步时间:{{ needEditMsg.updateTime }}</p>
    <div style=" text-align: center;margin-top: 10px;">
      <el-button type="primary" @click="onSubmit" style="width: 300px;">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, onMounted, toRaw } from 'vue'
import _ from 'lodash'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    needEditMsg: Object,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })

    const onSubmit = _.throttle(
      () => {
        ctx.emit('update-dialogVisible', { update: false, search: false })
      },
      2000,
      { trailing: false },
    )

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      onSubmit,
      handleClose,
      backtrack,
      visible,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
::v-deep .el-cascader .el-input__inner,
::v-deep .el-cascader .el-input {
  height: 100% !important;
}
::v-deep .el-cascader__tags {
  height: 50%;
  overflow: hidden;
}
::v-deep .el-cascader--small {
  width: 200px;
}
.el-collapse-item {
  padding-left: 10px;
}
.form {
  .el-form-item {
    width: 70%;
  }
}

.shopsShow {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 150px;
    line-height: 50px;
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid grey;
    border-radius: 5px;
  }
}
.endMsgTitle {
  text-align: center;
  span {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
