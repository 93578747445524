/*
 * @Author: your name
 * @Date: 2022-05-05 13:57:11
 * @LastEditTime: 2022-06-07 14:39:21
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/service/index.js
 */
import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {
  //预定列表分页查询
  async getAdminChainReserveList(data) {
    const res = await get(`/adminChain/order/getAdminChainReserveList`, data)
    return res
  }
  //查询预定订单详情
  async getOne(data) {
    const res = await get(`/adminChain/order/getOne`, data)
    return res
  }

  //获取门店列表
  async getStoreListByName(data) {
    const res = await get(`/adminChain/store/getStoreListByName`, data)
    return res
  }

  //导出储值订单列表
  async exportOrderCardStored(data) {
    const res = await _axios({
      url: '/adminChain/order/exportOrderCardStored',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }
  //退款
  async refundMoney(data) {
    const res = await post(`/adminChain/order/refundMoney`, data)
    return res
  }

  //总部端场次列表
  async getAdminChainOpenPages(data) {
    const res = await get(`/adminChain/order/getAdminChainOpenPages`, data)
    return res
  }

  //获取场次详情
  async getOrderOpenDetail(data) {
    const res = await get(`/adminChain/order/getOrderOpenDetail`, data)
    return res
  }

  //获取单个超市订单详情
  async getOrderGoodsDetail(data) {
    const res = await get(`/adminChain/order/getOrderGoodsDetail`, data)
    return res
  }

  //获取开台房时订单信息
  async getOrderRoomDetail(data) {
    const res = await get(`/adminChain/order/getOrderRoomDetail`, data)
    return res
  }
  //异常场次详情
  async getAbnormalPayDetail(data) {
    const res = await get(`/adminChain/order/getAbnormalPayDetail`, data)
    return res
  }
  //同意处理异常场次
  async agreeOperaTeAbnormalPayOPen(data) {
    const res = await post(`/adminChain/order/agreeOperaTeAbnormalPayOPen`, data)
    return res
  }

  //获取门店包厢首页信息
  async getStoreRoomHomePage(data) {
    const res = await get(`/adminChain/store/getStoreRoomHomePage`, data)
    return res
  }

  //传入包厢机顶盒roomMacKey生成包厢点歌二维码
  async getWechatOfficialAccountQrCode(data) {
    const res = await post(`/adminChain/store/getWechatOfficialAccountQrCode`, data)
    return res
  }

  //查询门店包厢类型
  async getStoreRoomTypeList(data) {
    const res = await get(`/adminChain/store/getStoreRoomTypeList`, data)
    return res
  }

  //修改包厢信息
  async updateStoreRoom(data) {
    const res = await post(`/adminChain/store/updateStoreRoom`, data)
    return res
  }

  //导出包厢二维码
  async exportStoreRoomQR(data) {
    const res = await post(`/adminChain/store/exportStoreRoomQR`, data)
    return res
  }

  //添加门店包厢
  async addStoreRoom(data) {
    const res = await post(`/adminChain/store/addStoreRoom`, data)
    return res
  }

  //获取包厢价格
  async getAdminChainRoomPriceList(data) {
    const res = await get(`/adminChain/store/getAdminChainRoomPriceList`, data)
    return res
  }
  //查询门店包厢类型
  async getStoreRoomTypeList(data) {
    const res = await get(`/adminChain/store/getStoreRoomTypeList`, data)
    return res
  }
  //修改包厢基准价格
  async updateRoomPrice(data) {
    const res = await post(`/adminChain/store/updateRoomPrice`, data)
    return res
  }
}

export default new CsMng()
