<template>
  <div class="tableSample">
    <div class="main-wrap" @click="handleDownload">
      <div style="margin-bottom: 3px;">
        <el-icon size="20">
          <Download />
        </el-icon>
      </div>
      <span>下</span>
      <span>载</span>
      <span>中</span>
      <span>心</span>
    </div>
    <el-dialog
      width="60%"
      v-if="dialogTableVisible"
      v-model="dialogTableVisible"
      :close-on-click-modal="false"
      title="下载中心"
      @close="handleClose"
    >
      <div class="tips">文件生成成功后,只在下载中心保存至次日10:00,请及时下载保存文件</div>
      <Table
        :tableData="tableData"
        :tableHead="tableHead"
        :slotKeys="['status']"
        :isExpand="false"
        :isPagination="true"
        :showIndex="false"
        :tableHeight="400"
      >
        <template #status="{ data }">
          <span :style="{ color: colorEnmu[data.row.status] }">{{ statusEnmu[data.row.status] }}</span>
        </template>
        <template #operate="{data}">
          <el-button
            v-if="data.row.status === 2"
            type="primary"
            size="mini"
            @click="handleDown(data.row.downloadUrl, data.row.typeDesc)"
            >下载</el-button
          >
        </template>
      </Table>
      <Pagination :pagination="pagination" @handleChange="handleChangePage"></Pagination>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, onUnmounted, onBeforeUnmount } from 'vue'
import { Table } from '@/component/base'
import _axios, { post, get, put } from '@/lin/plugin/axios'
import Pagination from '@/component/base/Pagination1/index'
import { createMoveBall } from '@/utils/createMoveBall.js'
class Api {
  async getList(params) {
    const res = await post('/shadow/export/page', params)
    return res
  }
}
let api = new Api()

let pagination = reactive({
  pageNum: 1,
  pageSize: 10,
  total: 0,
  pageCount: 5,
  pageSizes: [10, 20, 30, 40, 50],
})
let statusEnmu = reactive({
  0: '准备中',
  1: '导出中',
  2: '导出成功',
  3: '导出失败',
})
let colorEnmu = reactive({
  0: 'orange',
  1: 'orange',
  2: 'green',
  3: 'gray',
})

onBeforeUnmount(() => {
  // 关闭弹框的时候清除定时器
  clearInterval(timer.value)
})
const handleDown = (fileUrl, fileName) => {
  console.log(fileUrl, fileName)
  let a = document.createElement('a')
  a.href = fileUrl
  a.download = fileName
  a.click()
}
let dialogTableVisible = ref(false)

let tableData = ref([])

const tableHead = reactive([
  {
    label: '报表名称',
    prop: 'typeDesc',
  },
  {
    label: '查询时间',
    prop: 'createdDate',
  },
  {
    label: '导出时间',
    prop: 'lastModifiedDate',
  },
  {
    label: '文件状态',
    prop: 'status',
  },
  {
    label: '操作',
    prop: 'tool',
    formhidden: true,
  },
])

let timer = ref(null)

const getDownloadList = async () => {
  const { pageNum, pageSize } = pagination
  let params = { pageNum, pageSize }
  const res = await api.getList(params)
  console.log(res, '===')
  if (res.code === '200') {
    tableData.value = res.data.items || []
    pagination.total = res.data.total
  }
}

const handleClose = () => {
  dialogTableVisible.value = false
  // 关闭弹框的时候清除定时器
  clearInterval(timer.value)
}

const handleDownload = () => {
  createMoveBall({}, false)
  dialogTableVisible.value = true
  getDownloadList()
  // 打开弹框开启定时器
  if (dialogTableVisible.value) {
    timer.value = setInterval(getDownloadList, 3000)
  }
}

const handleChangePage = pages => {
  Object.assign(pagination, pages)
  getDownloadList()
}
</script>

<style lang="scss" scoped>
.tableSample {
  .main-wrap {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 42px;
    height: 118px;
    font-size: 16px;
    background-color: #1989fa;
    color: #ffffff;
    bottom: 430px;
    padding: 6px 0;
    right: 0;
    z-index: 10;
    span {
      padding: 2px;
    }
  }
  .tips {
    color: #ff8800;
    text-align: left;
    font-size: 13px;
    margin: 15px 0;
    padding-left: 5px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
::v-deep(.el-dialog__header) {
  text-align: left;
  font-size: 20px;
  border-bottom: 1px solid #dfd9d9;
}
::v-deep(.el-dialog__body) {
  padding-top: 0px;
}
</style>
