<template>
  <el-dialog v-model="visible" title="添加商品类型" width="40%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="articlesRowMsg" label-width="120px" :rules="rules" ref="elForm">
      <el-form-item label="分类名称" prop="name">
        <el-input v-model="articlesRowMsg.name" placeholder="请填写易耗品名称" style="width: 70%;" />
      </el-form-item>
      <el-form-item label="显示排序" prop="sort">
        <el-input-number
          :min="0"
          v-model="articlesRowMsg.sort"
          placeholder="请输入整数，数字小优先显示"
          style="width: 70%;"
        />
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 10px;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;" :disabled="!isEdit">{{
        isEdit ? '确定' : '确定'
      }}</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ref, defineComponent, computed, reactive } from 'vue'
import API from '../../service/api'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    articlesRowMsg: Object,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })

    const elForm = ref(null)

    const rules = reactive({
      name: [{ required: true, message: '请填写分类名称', trigger: 'blur' }],
    })
    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          props.articlesRowMsg.type = 4
          if (Reflect.has(props.articlesRowMsg, 'code')) {
            update()
          } else {
            add()
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const add = () => {
      API.createCategory(props.articlesRowMsg).then(res => {
        if (res.code === '200') {
          ElMessage.success('添加成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const update = () => {
      API.updateCategory(props.articlesRowMsg).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      handleSubmit,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      elForm,
      rules,
    }
  },
})
</script>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
</style>
