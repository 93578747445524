<template>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
    <el-form-item label="门店编号">
      <el-input
        placeholder="请输门店编号"
        v-model="data.storeCode"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店名称">
      <el-input
        placeholder="请输门店名称"
        v-model="data.storeName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="客户姓名">
      <el-input
        placeholder="请输客户名称"
        v-model="data.customerName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="客户手机号">
      <el-input
        placeholder="请输客户手机号"
        v-model="data.customerTelephone"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-date-picker
        v-model="data.time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="创建日期"
        end-placeholder="创建日期"
        value-format="YYYY-MM-DD HH:mm:ss"
      />
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column show-overflow-tooltip label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column show-overflow-tooltip label="门店编号" prop="storeCode" align="center" />
    <el-table-column show-overflow-tooltip label="门店名称" prop="storeName" align="center" />
    <el-table-column show-overflow-tooltip label="客户姓名" prop="customerName" align="center" />
    <el-table-column show-overflow-tooltip label="客户手机号" prop="customerTelephone" align="center" />
    <el-table-column show-overflow-tooltip label="预约时间" prop="reserveTime" align="center" />
    <el-table-column show-overflow-tooltip label="包厢类型" prop="roomTypeName" align="center" />
    <el-table-column show-overflow-tooltip label="创建时间" prop="createTime" align="center" />
    <el-table-column show-overflow-tooltip label="状态" prop="spec" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.state == 0">已预定</el-tag>
        <el-tag v-else-if="scope.row.state == 1" type="success">已完成</el-tag>
        <el-tag v-else-if="scope.row.state == 2" type="info">已取消</el-tag>
        <el-tag v-else-if="scope.row.state == 3" type="warning">已失效</el-tag>
        <el-tag v-else type="warning">待确认</el-tag>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import API from '../../service/api'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import { ElMessage, ElMessageBox } from 'element-plus'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import { post } from '@/lin/plugin/axios'

const data = reactive({
  customerName: undefined,
  customerTelephone: undefined,
  storeCode: undefined,
  storeName: undefined,
  time: ['', ''],
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

const resetData = () => {
  data.customerName = undefined
  data.customerTelephone = undefined
  data.storeCode = undefined
  data.storeName = undefined
  data.time = ['', '']
  searchLists()
}
/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}

const queryLists = async () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let params = {
    customerName: data.customerName,
    customerTelephone: data.customerTelephone,
    storeCode: data.storeCode,
    storeName: data.storeName,

    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }
  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  params = generateParam(params)
  let res = await post(`/shadow/report/details?type=STORE_RESERVE_DETAILS`, params).finally(() => {
    data.loading = false
  })
  if (res.code == 200) {
    data.tableData = res.data.items ?? []
    data.total = res.data.total
  } else {
    data.tableData = []
    data.total = 0
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
