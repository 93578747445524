<template>
  <el-table :data="pageData" class="coupon-table">
    <el-table-column label="优惠卷" prop="name" align="center" width="350">
      <template #default="scope">
        <div class="coupou-item">
          <div class="left">
            <text>{{ scope.row.scheme }}{{ unitsObj[scope.row.type] }}</text>
            <text>{{ scope.row.minimumCharge === -1 ? '无门槛' : scope.row.minimumCharge }}</text>
          </div>
          <div class="right">
            <text>{{ scope.row.name }}</text>
            <div>有效期：{{ scope.row.validityTimeStr }}</div>
            <text>仅{{ scope.row.usedRange === 1 ? '房费' : '商品' }}可用</text>
            <text>发行数量：{{ scope.row.issueNum === -1 ? '不限制' : scope.row.realIssueNum }}</text>
          </div>
        </div>
      </template>
    </el-table-column>
    <el-table-column label="数量" align="center">
      <template v-slot="scope">
        <el-input-number v-model="scope.row.ruleSetNum" size="small" :max="5" :min="1"></el-input-number>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center">
      <template #default="{row}">
        <el-button @click="deleteMustSelectProduct(row)">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="pageParams.pageNum"
    v-model:page-size="pageParams.pageSize"
    :small="small"
    :page-sizes="[5, 10, 20, 30]"
    layout="total, sizes, prev, pager, next, jumper"
    :total="pageParams.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import { defineProps, defineEmit, ref, reactive, watchEffect } from 'vue'
const props = defineProps({
  data: {
    type: Object,
    default: () => [],
  },
  propName: {
    type: String,
  },
})
const emit = defineEmit(['udateCoupou'])
const unitsObj = {
  2: '元',
  3: '分钟',
  4: '元',
}

//分页参数
const pageParams = reactive({
  pageNum: 1,
  pageSize: 5,
  total: 0,
})
//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
}
const handleCurrentChange = val => {
  pageParams.pageNum = val
}
const pageData = ref([]) //分页数据
watchEffect(() => {
  let start = pageParams.pageSize * (pageParams.pageNum - 1)
  let end = start + pageParams.pageSize
  pageData.value = props.data.slice(start, end)
  pageParams.total = props.data.length
})

/**
 * 删除当前优惠卷
 * @param {object} row
 */
const deleteMustSelectProduct = row => {
  ElMessageBox.confirm('确认删除当前优惠券吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let deleteIndex = props.data.findIndex(a => a.code === row.code)
    props.data.splice(deleteIndex, 1)
    emit('udateCoupou', {
      data: props.data,
      propName: props.propName,
    })
  })
}
</script>

<style scoped lang="scss">
.coupon-table {
  margin-top: 5px;
}
.el-input-number {
  width: 100%;
}

.coupou-item {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  .left {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-right: 1px solid #ccc;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 5px;
}
</style>
