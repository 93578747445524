<!--
 * @Author: your name
 * @Date: 2022-04-19 17:20:09
 * @LastEditTime: 2022-06-20 17:21:24
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/component/tableSearchTab.vue
-->
<template>
  <div
    class="table-search-tab"
    style="height: 76px;line-height: 76px;"
    v-if="['sessionType', 'order-session'].includes(searchType)"
  >
    <div class="search-left">
      <span>门店选择</span>
      <el-select
        v-model="storeCode"
        filterable
        remote
        reserve-keyword
        placeholder="选择门店"
        clearable
        class="search-select"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option v-for="item in storeList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-input
        v-model="searchValue"
        :placeholder="typeSelect == 0 ? '请输入手机号搜索' : '请输入包厢名称搜索'"
        class="input-with-select"
      >
        <template #prepend>
          <el-select v-model="typeSelect" class="input-search" style="width: 80px;" @change="search">
            <el-option label="手机" value="0" />
            <el-option label="包厢" value="1" />
          </el-select>
        </template>
      </el-input>
      <el-button type="primary" @click="searchTableData" v-if="authMenus.query">查询</el-button>
      <el-button plain @click="reset">重置</el-button>
    </div>
    <div class="search-right">
      <el-date-picker
        v-model="queryDate"
        type="datetimerange"
        start-placeholder="起始日期"
        format="YYYY-MM-DD"
        end-placeholder="结束日期"
      />
    </div>
  </div>
  <div class="table-search-tab" style="height: 76px;line-height: 76px;" v-if="searchType === 'roomPrice'">
    <div class="search-left">
      <span>包厢类型</span>
      <el-select
        v-model="roomTypeCode"
        placeholder="选择包厢类型"
        clearable
        class="search-select"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option v-for="item in storeRoomList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button type="primary" @click="searchTableData" class="search-btn" v-if="authMenus.query">查询</el-button>
      <el-button plain @click="reset">重置</el-button>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import api from '../service/index'
export default {
  props: {
    authMenus: {
      type: Object,
      default: {},
    },
    getTableData: {
      type: Function,
      default: null,
    },
    model: {
      type: Object,
      default: {},
    },
    searchType: {
      type: String,
      default: 'roomPrice',
    },
  },
  data() {
    return {
      queryDate: '',
      typeSelect: '0',
      searchValue: '',
      storeCode: '',
      storeList: [],
      storeRoomList: [],
      roomTypeCode: '',
    }
  },
  mounted() {
    if (this.searchType === 'roomPrice') {
      this.getStoreRoomTypeList()
    } else {
      this.getStoreList()
    }
  },
  methods: {
    getStoreList() {
      api.getStoreListByName({ storeName: this.storeCode }).then(res => {
        this.storeList = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
    },
    getStoreRoomTypeList() {
      api.getStoreRoomTypeList({ storeCode: 'K0000' }).then(res => {
        this.storeRoomList = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
    },
    searchTableData() {
      this.model.pageNum = 1
      if (['sessionType', 'order-session'].includes(this.searchType)) {
        if (this.typeSelect === '0') {
          this.model.telephone = this.searchValue
          this.model.roomName = ''
        } else if (this.typeSelect === '1') {
          this.model.telephone = ''
          this.model.roomName = this.searchValue
        }
        this.model.startDate = moment(this.queryDate[0]).format('YYYY-MM-DD')
        this.model.endDate = moment(this.queryDate[1]).format('YYYY-MM-DD')
        this.model.storeCode = this.storeCode || 'K0000'
      } else if (this.searchType === 'roomPrice') {
        this.model.roomTypeCode = this.roomTypeCode
      }
      this.getTableData(this.model)
    },
    reset() {
      this.storeCode = ''
      this.searchValue = ''
      this.queryDate = ''
      this.model.pageNum = 1
      this.model.storeCode = 'K0000'
      if (['sessionType', 'order-session'].includes(this.searchType)) {
        this.model.roomName = ''
        this.model.endDate = moment(new Date()).format('YYYY-MM-DD')
        this.model.startDate = moment(new Date()).format('YYYY-MM-DD')
        this.model.telephone = ''
      } else {
        this.model.roomTypeCode = ''
      }

      this.getTableData(this.model)
    },
  },
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;

  .search-left {
    width: 60%;

    .search-btn {
      margin-left: 20px;
    }

    .input-with-select {
      width: 340px;
      margin-left: 24px;
      margin-right: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-input {
    width: 300px;
    margin-left: 56px;
    margin-right: 32px;
  }

  .search-select {
    margin-left: 24px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
}
</style>
