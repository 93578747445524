<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="活动名称">
      <el-input placeholder="请输入活动名称" v-model="data.activityName" class="input-search-tel" clearable></el-input>
    </el-form-item>
    <el-form-item label="活动类型">
      <el-select v-model="data.type">
        <el-option label="倍数储值免单" :value="1" />
      </el-select>
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        ref="casRef"
        v-model="data.regionCode"
        :options="areaOptions"
        clearable
        filterable
        collapse-tags
        placeholder="请选择门店区域"
        collapse-tags-tooltip
        @change="changeArea()"
        :show-all-levels="false"
        :props="areaProps"
      ></el-cascader>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCode"
        :options="data.shops"
        filterable
        placeholder="请选择门店"
        class="search-select userSelect"
        multiple
        collapse-tags
        style="width: 300px;"
        collapse-tags-tooltip
      />
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
      <el-button @click="typeOperation(1, {})" v-if="data.authMenus.add"> 新增 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    :default-expand-all="false"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
  >
    <el-table-column type="index" label="序号" align="center" width="100" />
    <el-table-column show-overflow-tooltip prop="activityName" label="活动名称" align="center" />
    <el-table-column show-overflow-tooltip prop="specCount" label="活动类型" align="center">
      <template #default="scope">
        <p>倍数储值免单</p>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="attemptStoreNum" label="下发门店数" align="center" />
    <el-table-column show-overflow-tooltip prop="updateTime" label="创建时间" align="center" />
    <el-table-column label="操作" width="200" align="center">
      <template #default="scope">
        <el-button type="text" @click="typeOperation(2, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <el-button type="text" @click="typeOperation(3, scope.row)" v-if="data.authMenus.delete">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddOrUpdate
    v-if="data.addOrUpdateVisible"
    :dialogVisible="data.addOrUpdateVisible"
    :updateRowMsg="data.proRowMsg"
    :originRow="data.originRow"
    :authMenus="data.authMenus"
    @handleCloseDialog="updateAddParentProductVisible"
  ></AddOrUpdate>
</template>

<script setup>
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/index'
import { reactive, onMounted, ref, nextTick } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import clearSelect from '@/lin/util/clearSelect2.js'
import AddOrUpdate from './add-or-update-member-car-level.vue'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const casRef = ref()
const changeArea = () => {
  data.storeCode = []
  data.areaCode = data.regionCode.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
}

const data = reactive({
  authMenus: {},
  activityName: '',
  type: '',
  storeCode: [],
  regionCode: [],
  areaCode: [],

  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  addOrUpdateVisible: false,
  proRowMsg: {},
  originRow: {},
  shops: [],
  loading: false,
  loadingText: '正在火速加载数据...',
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  getShops()
  getAreas()
})

const getShops = () => {
  API.getStoreList({ list: data.areaCode }).then(res => {
    if (res.code === '200') {
      data.shops = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const reset = () => {
  data.storeCode = []
  data.regionCode = []
  data.areaCode = []
  data.activityName = ''
  data.type = ''
  nextTick(() => {
    clearSelect('userSelect')
    getShops()
  })
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    storeCodeList: data.storeCode,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
    regionCodeList: data.areaCode,
    type: data.type,
    activityName: data.activityName,
  }
  API.selectStoredGratis(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}

const editProType = rowMsg => {
  data.proRowMsg = {
    ...rowMsg,
    isFixedStore: rowMsg.attemptStore == -1 ? 0 : 1,
    channel: JSON.parse(rowMsg.channel),
    attemptStore: rowMsg.attemptStore == -1 ? [] : JSON.parse(rowMsg.attemptStore),
  }
  // data.originRow = {
  //   ...rowMsg,
  //   attemptStore: rowMsg.attemptStore == -1 ? -1 : JSON.stringify(JSON.parse(rowMsg.attemptStore).sort()),
  // }
  data.addOrUpdateVisible = true
}

//操作商品列表数据

const typeOperation = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      addProSpec(rowMsg)
      break
    case 2:
      editProType(rowMsg)
      break
    case 3:
      deleteProType(rowMsg)
      break
  }
}
const deleteProType = rowMsg => {
  ElMessageBox.confirm('确认删除当条储值活动吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.deleteStoredGratis({ code: rowMsg.code })
    if (res.code === '200') {
      ElMessage.success('删除成功')
    } else {
      ElMessage.error(res.msg)
    }
    queryLists()
  })
}
const addProSpec = rowMsg => {
  data.proRowMsg = {
    attemptStore: [],
    type: 1,
    isFixedStore: 1,
    channel: [1],
  }
  data.addOrUpdateVisible = true
}

const updateAddParentProductVisible = ({ update, search }) => {
  data.addOrUpdateVisible = update
  if (search) {
    queryLists()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
