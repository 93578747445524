<template>
  <el-form
    :inline="true"
    :model="data"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="正在火速加载数据..."
  >
    <el-form-item label="指标日期">
      <el-date-picker
        clearable
        value-format="YYYY-MM-DD"
        v-model="searchFilter.targetDay"
        type="date"
        placeholder="请选择指标日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item label="门店区域">
      <el-cascader
        v-model="searchFilter.regionCodeList"
        :options="areaOptions"
        clearable
        filterable
        @change="changeArea()"
        :show-all-levels="false"
        placeholder="请选择门店区域"
        collapse-tags
        :props="areaProps"
      ></el-cascader>
    </el-form-item>

    <el-form-item>
      <el-button @click="queryLists" type="primary">
        查询
      </el-button>
      <el-button @click="reseatFilter">
        重置
      </el-button>
      <el-button @click="exportData" v-if="authMenus.export">
        导出
      </el-button>
    </el-form-item>
  </el-form>

  <div style="margin: 20px 30px; color: red;">每天9:00自动更新业绩数据</div>
  <el-table
    :data="tableData"
    border
    style="width: 96%; margin-left: 2%;"
    :emptyText="'暂无数据'"
    @sort-change="handleSortChange"
  >
    <el-table-column width="120" prop="targetDay" label="指标日期"> </el-table-column>
    <el-table-column width="150" align="center" prop="dayRevenueRanking" sortable="custom" label="营收完成率排名" />
    <el-table-column width="200" align="center" prop="organizationName" label="大区" show-overflow-tooltip>
    </el-table-column>
    <el-table-column width="200" align="center" prop="organizationUser" label="区总" show-overflow-tooltip>
    </el-table-column>
    <el-table-column width="100" align="center" prop="storeCount" label="门店数"> </el-table-column>
    <el-table-column width="150" align="center" prop="dayRevenueIndicator" sortable="custom" label="营收指标(万元)">
    </el-table-column>
    <el-table-column width="150" align="center" prop="dayRevenue" sortable="custom" label="当日营收(万元)">
    </el-table-column>
    <el-table-column width="150" align="center" prop="dayBreach" label="日缺口(万元)"> </el-table-column>
    <el-table-column width="150" align="center" prop="dayCompletionRate" sortable="custom" label="营收完成率">
    </el-table-column>
    <el-table-column width="150" align="center" prop="shopAverage" sortable="custom" label="店均(万元)">
    </el-table-column>
    <el-table-column
      width="150"
      align="center"
      prop="storedValueProportionIndex"
      sortable="custom"
      label="储值占比指标"
    >
    </el-table-column>
    <el-table-column
      width="160"
      align="center"
      prop="storedValueProportionRate"
      sortable="custom"
      label="储值占比完成率"
    >
    </el-table-column>
    <el-table-column width="150" align="center" prop="currentStoredValue" label="当日储值(万元)"> </el-table-column>
    <el-table-column width="150" align="center" prop="storedValueProportion" label="当前储值占比"> </el-table-column>
  </el-table>
  <el-pagination
    :total="searchFilter.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :current-page="searchFilter.pageNum"
    :page-sizes="[5, 10, 15, 20]"
    :page-size="searchFilter.pageSize"
    layout="total, sizes, prev, pager, next, jumper"
  />
</template>

<script setup>
import { createMoveBall } from '@/utils/createMoveBall.js'
import { post } from '@/lin/plugin/axios'
import { onMounted, reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/index.js'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  checkStrictly: true,
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}

const areaCode = ref([])
const changeArea = () => {
  areaCode.value = searchFilter.regionCodeList.map(item => item[item.length - 1])
}

//搜索条件
const searchFilter = reactive({
  pageNum: 1,
  pageSize: 10,
  total: 0,
  targetDay: dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD'),
  regionCodeList: [],
})

//重制搜索条件
const reseatFilter = () => {
  searchFilter.targetDay = dayjs()
    .subtract(1, 'day')
    .format('YYYY-MM-DD')
  searchFilter.regionCodeList = []
  areaCode.value = []
  searchFilter.pageNum = 1
  searchFilter.pageSize = 10
  queryLists()
}
const fullscreenLoading = ref(false)

/**
 * 获取表单数据
 */
const tableData = ref([])
const totalRowData = ref({})
const queryLists = async () => {
  if (authMenus.value.query) {
    let params = generateParam({ ...searchFilter, regions: areaCode.value })
    //占坑
    tableData.value = [{ name: '合计' }]

    getTableData(params, 'REGION_REVENUE_DAILY_REPORT')

    if (params.pageNum > 1) {
      return tableData.value.splice(0, 1, totalRowData.value)
    }
    getTotalRowRequest(params, 'REGION_REVENUE_DAILY_REPORT')
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
// 获取报表数据
const getTableData = async (params, type) => {
  fullscreenLoading.value = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    fullscreenLoading.value = false
  })
  if (res.code == 200) {
    tableData.value.push(...(res.data.items ?? []))
    searchFilter.total = res.data.total
  } else {
    tableData.value = []
    searchFilter.total = 0
  }
}
/**
 * @description: 获取合计请求数据
 * @return {*}
 */
const getTotalRowRequest = async (params, type) => {
  let res = await post(`/shadow/report/summary?type=${type}`, params)
  if (res.code == 200) {
    totalRowData.value = res.data ?? {}
    tableData.value.splice(0, 1, totalRowData.value)
  } else {
    tableData.value.splice(0, 1)
  }
}

const authMenus = ref({})
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  getAreas()
})

const handleSortChange = ({ column, prop, order }) => {
  searchFilter.sortDirection = order === 'ascending' ? 'asc' : 'desc'
  searchFilter.sortField = prop
  queryLists()
}

/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}
/**
 * 导出
 */
const exportData = async event => {
  if (!authMenus.value.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  fullscreenLoading.value = true
  let model = {}
  model.targetDay = searchFilter.targetDay
  model.regions = areaCode.value

  createMoveBall(event)
  const param = generateParam(model)
  let res = await post(`/shadow/export/download?type=REGION_REVENUE_DAILY_REPORT`, param).finally(() => {
    fullscreenLoading.value = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}

//分页
const handleSizeChange = val => {
  searchFilter.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  searchFilter.pageNum = val
  queryLists()
}
</script>

<style scoped lang="scss">
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.el-pagination {
  text-align: right;
  margin-right: 2%;
}
</style>
