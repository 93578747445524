/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-11-22 16:23:44
 * @FilePath: /saas/src/plugin/member_manager/simulation/movie.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import api from '../service/index'
export const categoryList = async () => {
  let data = []
  await api.getCategoryList(2).then(res => {
    data = res.data.map(item => {
      return {
        value: item.code,
        label: item.name,
      }
    })
  })
  return data
}
