<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-11-25 17:09:51
 * @FilePath: /saas/src/component/base/icon/lin-icon.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <svg class="l-icon" :style="{ width, height }" @click="$emit('click', $event)">
    <use :xlink:href="`#icon-${name}`" :fill="color"></use>
  </svg>
</template>
<script>
export default {
  name: 'LIcon',
  props: {
    name: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    width: {
      type: String,
      default: '1.2em',
    },
    height: {
      type: String,
      default: '1.2em',
    },
  },
}
</script>
<style lang="scss" scoped>
.l-icon {
  &:hover,
  &:focus {
    color: #fff;
  }
}
</style>
