<!--
 * @Author: your name
 * @Date: 2022-04-20 10:24:59
 * @LastEditTime: 2022-12-21 17:23:28
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/memer-information-manager/index.vue
-->
<template>
  <div class="member-storingWine-manager">
    <SearchBar
      :authMenus="authMenus"
      searchTypes="isStoringWine"
      :openExtendModal="openExtendModal"
      :getTableData="getTableData"
      :model="model"
      :columnsList="columnsList"
      ref="searchBar"
    />
    <el-table
      :data="tableData"
      style="width: 100%"
      class="el-table-header-gap table-content"
      @filter-change="filterTagTable"
    >
      <el-table-column prop="userName" label="用户名" width="180" />
      <el-table-column prop="telephone" label="手机号" width="180" />
      <el-table-column prop="storeName" label="存酒门店" />
      <el-table-column prop="levelCode" label="等级">
        <template #default="scope">
          <div>
            {{ getLevelName(scope.row.levelCode) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="num" label="存酒数量">
        <template #default="scope">
          <div>{{ scope.row.num }} {{ scope.row.unit }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="renewTimes" label="续存次数" />
      <el-table-column prop="createTime" label="存酒时间" sortable />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)" v-if="authMenus.query">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :authMenus="authMenus"
      :totalRecords="totalRecords"
      :totalPage="totalPage"
      :model="model"
      :getTableData="getTableData"
    />
    <el-drawer
      v-model="infoModal"
      title="烟雨-1637263627"
      :with-header="false"
      size="35%"
      :direction="direction"
      :before-close="handleClose"
      id="else-title"
    >
      <div class="order-detail-item">
        <h1>
          <img src="../../assets/image/vip-level.png" alt="" class="vip-level-img" />
          {{ rowItem.userName }}-{{ rowItem.telephone }}
          <el-icon :size="20" color="#BFBFBF" class="close-icon" @click="infoModal = false">
            <close />
          </el-icon>
        </h1>
        <div class="info-line"></div>
        <h2 class="info-title">存酒门店</h2>
        <p class="shop-name">{{ rowItem.storeName }}</p>
        <h2 class="info-title">存酒时间</h2>
        <div class="storingWine-time">
          <p>{{ rowItem.createTime }}</p>
          <p>
            <span>{{ pastDue }}天过期</span>
          </p>
        </div>
        <h2 class="info-title">续存次数</h2>
        <p class="info-num">{{ rowItem.renewTimes }}</p>
        <h2 class="info-title">存酒详情</h2>
        <ul>
          <li>
            <p>{{ rowItem.skuName }}</p>
            <p>
              {{ rowItem.num }}/<span>{{ rowItem.unit }}</span>
            </p>
          </li>
        </ul>
      </div>
    </el-drawer>
    <el-dialog v-model="extendModal" width="25%" :before-close="closeExtendModal" center>
      <span class="isConfirm-extend">是否确认续存</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="extendModal = false">取消</el-button>
          <el-button type="primary" @click="openModals">确认</el-button>
        </span>
      </template>
    </el-dialog>
    <ExtendDrawer :extendDrawer="extendDrawer" ref="extendDrawer" :getTableData="getTableData" />
    <ExportDialog :columnsList="columnsList" ref="dialogRef" :exportData="exportData" />
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import dayjs from 'dayjs'
import api from '../../service/index'
import SearchBar from '../../component/tableSearchTab.vue'
import Pagination from '../../component/pagination.vue'
import ExtendDrawer from '../../component/extendDrawer.vue'
import ExportDialog from '../.../../../../../component/base/exportDialog/export-dialog.vue'
import { ElMessage } from 'element-plus'

export default {
  components: { SearchBar, Pagination, ExtendDrawer, ExportDialog },
  data() {
    return {
      authMenus: {},
      searchDate: '',
      options: [],
      tableData: [],
      input2: '',
      background: false,
      infoModal: false, //详情Modal，
      extendModal: false,
      extendDrawer: false,
      model: {
        pageNum: 1,
        pageSize: 10,
        storeCode: '',
        telephone: '',
        levelCode: '',
      },
      columnsList: [
        { value: 'userName', label: '用户名' },
        { value: 'telephone', label: '手机号' },
        { value: 'storeName', label: '存酒门店' },
        { value: 'levelName', label: '等级' },
        { value: 'num', label: '存酒数量' },
        { value: 'renewTimes', label: '续存次数' },
        { value: 'createTime', label: '存酒时间' },
      ],
      rowItem: {},
      pastDue: null,
      levelList: [],
      authMenus: {},
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getTableData(this.model)
      this.getLevelList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getTableData(model) {
      if (this.authMenus.query) {
        api.getWineListPages(model).then(res => {
          this.tableData = res.data.records
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
        })
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    filterTagTable(filters) {
      this.model.levelCode = filters.levelCode[0]
      this.getTableData(this.model)
    },
    getLevelList() {
      api.getLevelList().then(res => {
        this.levelList = res.data.map(item => {
          return { text: item.name, value: item.code }
        })
      })
    },
    getLevelName(level) {
      let obj = this.levelList.find(item => item.value === level)
      return obj && obj.text
    },
    filterLevel(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    search() {},
    showDetail(index, row) {
      this.infoModal = true
      this.rowItem = row
      this.pastDue = dayjs(row.expireTime).diff(new Date(), 'day')
    },
    handleClose() {
      this.infoModal = false
    },
    closeExtendModal() {
      this.extendModal = false
    },
    openExtendModal() {
      this.extendModal = true
    },
    openModals() {
      this.extendModal = false
      this.extendDrawer = true
      this.$refs.extendDrawer.extendDrawer = true
    },
  },
}
</script>
<style lang="scss" scoped>
.member-storingWine-manager {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .table-content {
    height: 100%;
    overflow: auto;
  }
  .isConfirm-extend {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .shop-order-search {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 41px;

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }
  }

  .order-detail-item {
    width: 588px;
    margin: auto;

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .vip-level-img {
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 32px;
      }

      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .info-title {
      box-sizing: border-box;
      margin-bottom: 24px;
      width: 588px;
      height: 40px;
      line-height: 40px;
      background: #f0f2f5;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      padding-left: 24px;
      color: #2e2f31;
      -webkit-text-stroke: 1px #979797;
    }

    .info-num {
      margin-left: 20px;
      margin-bottom: 30px;
    }

    .shop-name {
      margin-bottom: 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      padding-left: 20px;
    }

    .info-hobby {
      margin-left: 20px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }

    .consumption-statistics-info {
      display: flex;
      margin: auto;
      padding-left: 20px;

      div {
        flex: 1;
        height: 180px;
        text-align: center;

        p {
          font-size: 40px;
          font-family: D-DIN-Bold, D-DIN;
          font-weight: bold;
          color: #c03918;
          margin-top: 30px;
          margin-bottom: 19px;
        }

        span {
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #666666;
        }
      }
    }

    .storingWine-time {
      display: flex;
      padding-left: 20px;
      margin-bottom: 30px;

      p {
        flex: 1;

        span {
          text-align: center;
          float: right;
          // width: 59px;
          height: 22px;
          line-height: 22px;
          background: #fff1f0;
          border-radius: 4px;
          border: 1px solid #ffa39e;
          font-size: 12px;
          font-family: Helvetica;
          color: #f5222d;
        }
      }
    }

    ul {
      padding-left: 20px;

      li {
        height: 30px;
        line-height: 30px;
        display: flex;

        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
          flex: 1;
        }

        p:last-child {
          span {
            color: #909399;
          }

          text-align: right;
        }
      }
    }
  }
}
</style>
