<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="日志类型">
      <el-select v-model="data.type" placeholder="请选择日志类型" clearable>
        <el-option label="新增用户" :value="1" />
        <el-option label="修改用户" :value="2" />
      </el-select>
    </el-form-item>
    <el-form-item label="日志对象">
      <el-input placeholder="请输入日志对象" clearable v-model="data.operateUser"></el-input>
    </el-form-item>
    <el-form-item label="操作人">
      <el-input placeholder="请输入操作人" clearable v-model="data.createName"></el-input>
    </el-form-item>
    <el-form-item label="操作时间">
      <el-date-picker
        v-model="data.time"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        value-format="YYYY-MM-DD HH:mm:ss"
      />
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    :default-expand-all="false"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
    row-key="storeCode"
    :expand-row-keys="expands"
  >
    <el-table-column type="index" label="序号" align="center" width="100" />
    <el-table-column show-overflow-tooltip prop="storeCode" label="日志类型" align="center">
      <template #default="scope">
        <span v-if="scope.row.type == 1">新增用户</span>
        <span v-if="scope.row.type == 2">修改用户</span>
      </template>
    </el-table-column>

    <el-table-column show-overflow-tooltip label="日志对象" align="center">
      <template #default="scope">
        <span>{{ scope.row.userName }}({{ scope.row.telephone }})</span>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="createName" label="操作人" align="center" />
    <el-table-column show-overflow-tooltip prop="createTime" label="操作时间" align="center" />

    <el-table-column label="操作" width="200" align="center" fixed="right">
      <template #default="scope">
        <el-button type="text" @click="specOperation(scope.row)" v-if="data.authMenus.query">详情</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />

  <el-dialog title="日志详情" v-model="data.dialogVisible" width="70%" :before-close="handleClose">
    <span slot="footer" class="dialog-footer">
      <div v-for="(item, key) in data.operationMsg" :key="item" style="margin-bottom: 40px;">
        <text style="font-size: 24px;">{{ key }}:</text>
        <hr />
        <text>{{ item }}</text>
      </div>

      <div style="text-align: right;">
        <el-button type="primary" @click="data.dialogVisible = false">关闭</el-button>
      </div>
    </span>
  </el-dialog>
</template>

<script setup>
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/api'
import { reactive, onMounted, ref, nextTick } from 'vue'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'

const data = reactive({
  authMenus: {},
  createName: '',
  operateUser: '',
  type: '',
  time: '',
  pageSize: 10,
  currentPage: 1,
  total: 0,
  tableData: [],
  loading: false,
  loadingText: '正在火速加载数据...',
  dialogVisible: false,
  operationMsg: [],
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
})

const reset = () => {
  data.createName = undefined
  data.operateUser = undefined
  data.type = undefined
  data.time = ''
  searchLists()
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    createName: data.createName,
    operateUser: data.operateUser,
    type: data.type,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }

  if (!data.time) {
    queryParams.startTime = ''
    queryParams.endTime = ''
  } else {
    queryParams.startTime = data.time[0]
    queryParams.endTime = data.time[1]
  }
  API.getOperateLogList(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data?.records ?? []
        data.total = res.data?.totalRecords ?? 0
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const specOperation = a => {
  try {
    data.dialogVisible = true
    data.operationMsg = JSON.parse(a.content)
  } catch (error) {
    data.operationMsg = {}
  }
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
