<!--
 * @Author: your name
 * @Date: 2022-04-20 10:24:59
 * @LastEditTime: 2024-08-19 17:04:07
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/member_manager/view/member-information-manager/index.vue
-->
<template>
  <div class="member-information-manager">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
    />
    <el-table
      :emptyText="model.storeCode ? '暂无数据' : '暂无数据'"
      :data="tableData"
      style="width: 100%"
      v-loading.fullscreen.lock="loading"
      element-loading-text="正在火速加载数据..."
    >
      <el-table-column align="center" width="120px" prop="telephone" label="手机号" show-overflow-tooltip />
      <el-table-column align="center" width="120px" prop="levelName" label="会员卡等级" show-overflow-tooltip />
      <el-table-column align="center" width="120px" prop="storeCode" label="开卡门店" show-overflow-tooltip />
      <el-table-column align="center" prop="status" label="状态" show-overflow-tooltip> </el-table-column>
      <el-table-column align="center" prop="userName" label="姓名" show-overflow-tooltip />
      <el-table-column align="center" prop="gender" label="性别" show-overflow-tooltip> </el-table-column>
      <el-table-column align="center" prop="birthday" label="生日" show-overflow-tooltip />
      <el-table-column align="center" prop="customerTagsName" label="会员标签" show-overflow-tooltip width="120px" />
      <el-table-column align="center" prop="totalAmount" label="余额" show-overflow-tooltip />
      <el-table-column align="center" width="120px" prop="amount" label="本金余额" show-overflow-tooltip />
      <el-table-column align="center" width="120px" prop="giftAmount" label="赠金余额" show-overflow-tooltip />
      <el-table-column
        align="center"
        prop="returnedAmountChange"
        label="不可用金额"
        show-overflow-tooltip
        width="200px"
      />
      <el-table-column align="center" prop="points" label="积分" show-overflow-tooltip />
      <el-table-column align="center" prop="points" label="门店卡数量" show-overflow-tooltip>
        <template #default="scope">
          <span @click="handleShowPoints(scope.row)" style="cursor: pointer;color: #66b1ff;">
            {{ scope.row.storeCardsCount }}</span
          >
        </template>
      </el-table-column>

      <el-table-column align="center" prop="overTime" label="会员等级有效期" show-overflow-tooltip width="200px" />
      <el-table-column align="center" prop="lastPayTime" label="末次消费时间" show-overflow-tooltip width="200px" />
      <el-table-column align="center" prop="source" width="120px" label="会员来源" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" width="120px" prop="createTime" label="开卡时间" show-overflow-tooltip />
      <el-table-column align="center" width="120px" prop="updateTime" label="更新时间" show-overflow-tooltip />
      <el-table-column align="center" label="操作">
        <template #default="scope">
          <el-button type="text" @click="updateMemberInfoFn(scope.row)" v-if="authMenus.update">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecords"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <updateMember
    v-if="memberVisible"
    :updateMemberInfo="updateMemberInfo"
    @handleChangeVisible="handleChangeVisible"
  ></updateMember>

  <showPointsMsg
    v-if="pointsVisible"
    :updateMemberInfo="updateMemberInfo"
    @handleShowPointsVisible="handleShowPointsVisible"
  ></showPointsMsg>
</template>
<script>
import showPointsMsg from './show-points-msg.vue'
import updateMember from './update-member.vue'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import API from '../../service/index'
import dayjs from 'dayjs'
import { downLoad } from '../../../../lin/util/util'
export default {
  components: { tableSearchTab, updateMember, showPointsMsg },
  data() {
    return {
      loading: '',
      authMenus: {},
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      totalRecords: 0,
      totalPage: 0,
      memberVisible: false,
      pointsVisible: false,
      updateMemberInfo: {},
      memberOrigin: {
        1: '小程序',
        2: 'POS',
        3: '小程序未授权',
        4: '小程序-游戏',
        5: '美大',
        6: '抖音',
        7: '利通会员导入',
        8: '发券助手',
      },
      model: {
        pageNum: 1,
        pageSize: 10,
        storeCode: '',
        telephone: '',
        nickName: '',
        levelCode: '',
        status: '',
        openCardTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        openCardTimeEnd: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        time: [
          dayjs()
            .subtract(1, 'day')
            .set('hour', 8)
            .set('minute', 0)
            .set('second', 0)
            .format('YYYY-MM-DD HH:mm:ss'),
          dayjs()
            .set('hour', 8)
            .set('minute', 0)
            .set('second', 0)
            .format('YYYY-MM-DD HH:mm:ss'),
        ],
      },
      searchform: [
        {
          label: '开卡时间',
          type: 'date-picker',
          labelWidth: '100px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店',
          type: 'select-v2',
          labelWidth: '50px',
          prop: 'storeCode',
          filterable: 'filterable',
          options: [],
        },
        { label: '会员姓名', type: 'input', prop: 'nickName', placeholder: '会员姓名' },
        { label: '会员手机号', type: 'input', prop: 'telephone', placeholder: '会员手机号', labelWidth: '100px' },
        { label: '会员等级', type: 'select', typeProp: 'select', children: 'option', prop: 'levelCode', options: [] },
        { label: '会员状态', type: 'select', typeProp: 'select', children: 'option', prop: 'status', options: [] },
        {
          label: '会员标签',
          type: 'select',
          style: { width: '250px' },
          labelWidth: '50px',
          prop: 'customerTagsCode',
          children: 'option',
          filterable: 'filterable',
          multiple: true,
          options: [],
        },
        {
          label: '会员来源',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'source',
          options: [
            {
              value: 1,
              label: '小程序',
            },
            {
              value: 2,
              label: 'POS',
            },
            {
              value: 3,
              label: '小程序未授权',
            },
            {
              value: 4,
              label: '小程序-游戏',
            },
            {
              value: 5,
              label: '美大',
            },
            {
              value: 6,
              label: '抖音',
            },
            {
              value: 7,
              label: '利通会员导入',
            },
            {
              value: 8,
              label: '发券助手',
            },
          ],
        },
        { label: '导出', type: 'exportButton', url: '/adminChain/customer/list/saas/export', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    this.getSearchData()
    this.getTableData(this.model)
  },
  methods: {
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }

      const { storeCode, status, telephone, nickName, levelCode, time, customerTagsCode, source } = param
      this.model = {
        storeCode,
        status,
        telephone,
        nickName,
        levelCode,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        time,
        customerTagsCode,
        source,
      }
      if (Array.isArray(time)) {
        this.model.openCardTime = time[0]
        this.model.openCardTimeEnd = time[1]
      } else {
        this.model.openCardTime = ''
        this.model.openCardTimeEnd = ''
      }
      this.model.customerTagsCode = (this.model?.customerTagsCode || []).join(',')

      this.$refs.search.fullscreenLoading = false
      this.exportData('会员列表', event)
    },
    /**
     * 查询level数据
     */
    async querylevels() {
      let res = await API.queryLevels({
        levelName: '',
        pageSize: 100,
        pageNum: 1,
      })
      if (res.code === '200') {
        this.searchform[4].options = res.data.records.map(item => ({
          label: item.name,
          value: item.code,
        }))
      } else {
        ElMessage.error(res.message)
      }
    },
    /**
     * 改变dislog弹出框显隐藏
     * @param {boolean} val  true/false
     */
    handleChangeVisible(val) {
      this.memberVisible = val
      this.parentSearch()
    },

    handleShowPointsVisible(val) {
      this.pointsVisible = val
    },
    handleShowPoints(data) {
      this.pointsVisible = true
      this.updateMemberInfo = data
    },
    //更新会员信息
    async updateMemberInfoFn(updateMemberInfo) {
      let res = await API.getMemberDetail({ customerCode: updateMemberInfo.code })
      if (res.code != 200) {
        ElMessage.error(res.msg)
        return
      }
      this.updateMemberInfo = {
        ...res.data,
        accAmount: Math.ceil(res.data.accAmount),
        status: res.data.status === 0 ? 0 : 1,
        isSaas: 1,
        customerTagsCode: res.data.customerTagsCode ? res.data.customerTagsCode.split(',') : [],
      }
      this.handleChangeVisible(true)
    },
    /**
     * 查询表单数据
     * @param {*} param
     */
    parentSearch(param) {
      if (param) {
        const { storeCode, status, telephone, nickName, levelCode, time, customerTagsCode, source } = param
        this.model = {
          storeCode,
          status,
          telephone,
          nickName,
          levelCode,
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          time,
          customerTagsCode,
          source,
        }
        if (Array.isArray(time)) {
          this.model.openCardTime = time[0]
          this.model.openCardTimeEnd = time[1]
        } else {
          this.model.openCardTime = ''
          this.model.openCardTimeEnd = ''
        }
      } else {
        this.model.pageNum = this.currentPage
        this.model.pageSize = this.pageSize
        if (Array.isArray(this.model.time)) {
          this.model.openCardTime = this.model.time[0]
          this.model.openCardTimeEnd = this.model.time[1]
        } else {
          this.model.openCardTime = ''
          this.model.openCardTimeEnd = ''
        }
      }
      this.model.customerTagsCode = (this.model?.customerTagsCode || []).join(',')
      this.mergeTotalData(this.model.pageNum, '会员列表', null, true)
    },

    /**
     * 获取搜索条件数据
     */
    async getSearchData() {
      //会员等级
      this.querylevels()

      //会员状态
      this.searchform[5].options = [
        {
          value: '1',
          label: '正常',
        },
        {
          value: '0',
          label: '冻结',
        },
      ]

      //会员标签
      this.searchform[6].options = [
        {
          value: '101',
          label: '外营卡-实名',
        },
        {
          value: '102',
          label: '内营卡-实名',
        },
      ]
      let res = await API.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },

    // getTableData(model) {
    //   if (this.authMenus.query) {
    //     this.loading = true

    //     API.getMemberList({
    //       ...model,
    //       customerTagsCode: (model.customerTagsCode || [])?.join(','),
    //     })
    //       .then(res => {
    //         this.tableData = res.data?.records || []
    //         this.totalRecords = res.data?.totalRecords || 0
    //         this.totalPage = res.data?.totalPage || 0
    //       })
    //       .finally(() => {
    //         this.loading = false
    //       })
    //   } else {
    //     ElMessage.warning('抱歉，你没有查看权限')
    //   }
    // },

    handleSizeChange(val) {
      this.pageSize = val
      this.parentSearch()
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.parentSearch()
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-select__input {
  max-width: 10px !important;
}
.member-information-manager {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 30px;
}
</style>
