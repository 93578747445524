<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-08-01 17:03:20
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-08-31 16:20:55
 * @FilePath: /saas/src/component/base/el-Drawer/el-drawer.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-drawer v-model="detailModal" :before-close="handleClose" :with-header="false" size="35%">
    <div class="drawer-content-box">
      <div slot="title">
        <h2 class="title">
          {{ title }}
          <el-icon :size="20" color="#BFBFBF" class="close-icon" @click="detailModal = false">
            <close />
          </el-icon>
          <span :class="'state' + state" class="status-box">{{ stateList[state] }}</span>
        </h2>
        <slot name="header"></slot>
      </div>
      <slot></slot>
      <p class="buttons" v-if="isShowBtn">
        <!-- <el-button class="btn yesBtn" @click="saveInfo">{{
                    commitBtnText 
            }}</el-button> -->
        <el-button class="btn closeBtn reset" v-if="isRejectBtn" @click="reject">审批不通过</el-button>
        <el-button class="btn closeBtn reset" v-if="!isRejectBtn" @click="cancle">{{ cancelName }}</el-button>
      </p>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: 'drawer',
  props: {
    detailModal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '50%',
    },
    title: {
      type: String,
      default: '',
    },
    hasChildren: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    subSize: {
      type: String,
      default: '50%',
    },
    subTitle: {
      type: String,
      default: '',
    },
    isShowBtn: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    isRejectBtn: {
      //是否是审批驳回按钮
      type: Boolean,
      default: () => {
        return false
      },
    },
    commitBtnText: {
      type: String,
      default: '确定',
    },
    cancelName: {
      type: String,
      default: '取消',
    },
    className: {
      type: String,
      default: 'drawerClass',
    },
    state: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      detailModal: false,
      innerDrawer: false,
      outSize: '50%',
      stateList: { 1: '待确定', 2: '待发货', 3: '待收货', 4: '已收货' },
      stateName: '',
      stateStyle: '',
    }
  },
  watch: {
    state(newval, oldval) {
      // 该写法监听的是people对象引用地址，当改变子属性name的值时，对象的引用地址并没有改变，因此不会监听到变化
      console.log(newval, oldval)
    },
  },
  methods: {
    //保存
    saveInfo() {
      this.$emit('saveInfo')
    },
    //取消
    cancle() {
      this.drawer = false
      this.$emit('cancleInfo')
    },
    //驳回
    reject() {
      this.$emit('rejectOrder')
    },
    //关闭弹框
    closeDrawer() {
      this.$emit('closeDrawer')
    },
  },
}
</script>

<style lang="scss" scoped>
.drawer-content-box {
  box-sizing: border-box;
  padding: 0px 40px;

  .title {
    height: 73px;
    line-height: 73px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 20px;

    .close-icon {
      float: right;
      top: 20px;
      cursor: pointer;
    }

    .status-box {
      float: right;
      display: inline-block;
      width: 64px;
      text-align: center;
      margin-top: 20px;
      margin-right: 32px;
      height: 32px;
      line-height: 32px;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .state1 {
      background-color: rgba(243, 137, 0, 0.15);
      color: rgba(243, 137, 0, 1);
    }

    .state2 {
      background-color: rgba(255, 160, 133, 0.15);
      color: rgba(255, 160, 133, 1);
    }

    .state3 {
      background-color: rgba(58, 132, 255, 0.15);
      color: #3a84ff;
    }

    .state4 {
      background-color: rgba(90, 196, 0, 0.15);
      color: #5ac400;
    }
  }
}
</style>
