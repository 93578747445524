<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-13 16:40:36
 * @FilePath: /saas/src/plugin/finance-reconciliation/view/report_module/cjmxReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="getReportStorageTracePages" reportName="出库记录"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="'暂无数据'"
      :getTableData="getReportStorageTracePages"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
// import tableSearchTab from '../../component/tableSearchTab.vue'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        endTime: '',
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        storeCode: '',
        materialCategory: '',
        materialName: '',
        channelName: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'storeName',
          label: '门店',
          width: '180px',
        },
        {
          prop: 'orderCode',
          label: '单号',
          width: '180px',
        },
        {
          prop: 'channelName',
          label: '出库类型',
        },
        {
          prop: 'materialCategory',
          label: '商品类型',
        },
        {
          prop: 'materialName',
          label: '商品名称',
        },
        {
          prop: 'stockPrice',
          label: '商品成本',
        },
        {
          prop: 'stock',
          label: '出库数量',
        },
        {
          prop: 'unit',
          label: '单位',
        },
        {
          prop: 'stockAmount',
          label: '出库金额',
        },
        {
          prop: 'supplierName',
          label: '供应商',
        },
        {
          prop: 'orderCreateTime',
          label: '出库时间',
          width: '180px',
        },
      ],
      searchform: [
        {
          label: '门店',
          type: 'select',
          labelWidth: '50px',
          typeProp: 'select',
          children: 'option',
          prop: 'storeCode',
          options: [],
          filterable: 'filterable',
        },
        {
          label: '商品分类',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'materialCategory',
          options: [],
        },
        { label: '商品名称', type: 'input', prop: 'materialName', placeholder: '商品名称' },
        { label: '供应商', type: 'input', prop: 'supplierName', placeholder: '供应商' },
        { label: '出库类型', type: 'select', typeProp: 'select', children: 'option', prop: 'channelName', options: [] },
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm',
        },
        {
          label: '导出',
          type: 'exportButton',
          url: '/report/export/reportStorageOutBoundTrace',
          auth: 'export',
        },
      ],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      // this.getReportStorageTracePages(this.model)
      this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getStoreList() {
      api.getStoreListByName().then(res => {
        //门店接口
        this.searchform[0].options = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
      api.getCategoryList(2).then(res => {
        //商品分类接口
        this.searchform[1].options = res.data.map(item => {
          return {
            value: item.code,
            label: item.name,
          }
        })
      })
      api.stockList(2).then(res => {
        this.searchform[4].options = res.data.map(item => {
          return {
            value: item.name,
            label: item.name,
          }
        })
      })
    },
    getReportStorageTracePages(model) {
      api.getReportStorageTracePages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    parentSearch(param) {
      const { materialCategory, materialName, channelName, storeCode, supplierName } = param
      this.model = { materialCategory, materialName, channelName, storeCode, supplierName }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.getReportStorageTracePages(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { materialCategory, materialName, channelName, storeCode, supplierName } = param
      this.model = { materialCategory, materialName, channelName, storeCode, supplierName }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '出库记录.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;
}
</style>
