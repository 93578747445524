<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="办卡储值明细" name="storedCardAuth" v-if="isShowReport('storedCardAuth')">
      <stored-card-report :auths="authType.storedCardAuth" />
    </el-tab-pane>
    <el-tab-pane label="办卡储值统计" name="storedCardStatisticsAuth" v-if="isShowReport('storedCardStatisticsAuth')">
      <stored-card-statistics-report :auths="authType.storedCardStatisticsAuth" />
    </el-tab-pane>
    <el-tab-pane label="会员卡消费明细" name="customerCardAuth" v-if="isShowReport('customerCardAuth')">
      <customer-card-report
        :reportType="reportType"
        :reportTypeName="reportTypeName"
        :auths="authType.customerCardAuth"
      />
    </el-tab-pane>
    <el-tab-pane
      label="会员卡消费统计"
      name="customerCardStatisticsAuth"
      v-if="isShowReport('customerCardStatisticsAuth')"
    >
      <customer-card-statistics-report :auths="authType.customerCardStatisticsAuth" />
    </el-tab-pane>

    <el-tab-pane label="总分店会员分账统计" name="totalBranchMemberAuth" v-if="isShowReport('totalBranchMemberAuth')">
      <total-branch-member-report :auths="authType.totalBranchMemberAuth" />
    </el-tab-pane>
    <el-tab-pane label="利通会员消费明细" name="ltCustomerAuth" v-if="isShowReport('ltCustomerAuth')">
      <ltCustomerReport :auths="authType.ltCustomerAuth" />
    </el-tab-pane>
    <el-tab-pane
      label="利通会员消费统计"
      name="ltCustomerStatisticsAuth"
      v-if="isShowReport('ltCustomerStatisticsAuth')"
    >
      <ltCustomerStatisticsReport :auths="authType.ltCustomerStatisticsAuth" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import storedCardReport from '../report_module/storedCardReport.vue'
import storedCardStatisticsReport from '../report_module/storedCardStatisticsReport.vue'
import customerCardReport from '../report_module/customerCardReport.vue'
import customerCardStatisticsReport from '../report_module/customerCardStatisticsReport.vue'
import totalBranchMemberReport from '../report_module/totalBranchMemberReport.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import Api from '@/api/index.js'
import ltCustomerReport from '../report_module/ltCustomerReport.vue'
import ltCustomerStatisticsReport from '../report_module/ltCustomerStatisticsReport.vue'
const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  storedCardAuth: {}, // 办卡储值明细
  storedCardStatisticsAuth: {}, // 办卡储值统计
  customerCardAuth: {}, //会员卡消费明细
  customerCardStatisticsAuth: {}, // 会员卡消费统计
  totalBranchMemberAuth: {}, //总分店会员分账统计
  ltCustomerAuth: {}, //利通会员消费明细
  ltCustomerStatisticsAuth: {}, //利通会员消费统计
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.storedCardAuth = authAllMenus.find(a => a.url === 'storedCardAuth') || { childrens: [] }
  authType.storedCardStatisticsAuth = authAllMenus.find(a => a.url === 'storedCardStatisticsAuth') || { childrens: [] }
  authType.customerCardAuth = authAllMenus.find(a => a.url === 'customerCardAuth') || { childrens: [] }
  authType.customerCardStatisticsAuth = authAllMenus.find(a => a.url === 'customerCardStatisticsAuth') || {
    childrens: [],
  }
  authType.totalBranchMemberAuth = authAllMenus.find(a => a.url === 'totalBranchMemberAuth') || { childrens: [] }
  authType.ltCustomerAuth = authAllMenus.find(a => a.url === 'ltCustomerAuth') || { childrens: [] }
  authType.ltCustomerStatisticsAuth = authAllMenus.find(a => a.url === 'ltCustomerStatisticsAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsSellAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
let reportType = ref('')
let reportTypeName = ref('')
const EnmuList = reactive({
  storedCardAuth: '办卡储值明细',
  storedCardStatisticsAuth: '办卡储值统计',
  customerCardAuth: '会员卡消费明细',
  customerCardStatisticsAuth: '会员卡消费统计',
  totalBranchMemberAuth: '总分店会员分账统计',
  ltCustomerAuth: '利通会员消费明细',
  ltCustomerStatisticsAuth: '利通会员消费统计',
})

const handleClick = () => {
  if (activeName.value === 'customerCardAuth') {
  }
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
