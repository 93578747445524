/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-25 17:06:29
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2023-01-12 11:45:17
 * @FilePath: /saas/src/plugin/merchant-manager/stage-config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '设备管理',
  type: 'folder',
  icon: 'iconfont icon-jingyingbanci',
  filePath: 'view/merchant/',
  route: '/facility-manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '包厢自检管理',
      type: 'view',
      name: 'boxCheckManager',
      route: '/facility-manager/boxCheckManager',
      filePath: 'plugin/facility-manager/view/boxCheckManager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },

  ],
}

export default linCmsUiRouter
