<template>
  <el-dialog v-model="visible" title="库存明细" width="70%" :before-close="handleClose">
    <div>
      <el-form
        :inline="true"
        :model="rowMsg"
        label-width="100px"
        style="width: 96%; margin-left: 2%;margin-top: 10px; display: flex; justify-content: space-between;height: 50px;"
      >
        <el-form-item label="盘点日期：" style="flex:1">
          <p>{{ rowMsg.createTime.split(' ')[0] }}</p>
        </el-form-item>
        <el-form-item label="门店编号：" style="flex:1">
          <p>{{ rowMsg.storeCode }}</p>
        </el-form-item>
        <el-form-item label="门店名称：" style="flex:1">
          <p>{{ rowMsg.storeName }}</p>
        </el-form-item>
      </el-form>
      <el-form
        :inline="true"
        :model="data"
        label-width="100px"
        style="width: 96%; margin-left: 2%; display: flex; justify-content: space-between;height: 50px;"
      >
        <el-form-item label="仓库：" style="flex:1">
          <p>{{ rowMsg.houseName }}</p>
        </el-form-item>
        <el-form-item label="盘点人：" style="flex:1">
          <p>{{ rowMsg.userName }}</p>
        </el-form-item>
        <el-form-item label="盘点时间：" style="flex:1">
          <p>{{ rowMsg.createTime }}</p>
        </el-form-item>
      </el-form>
    </div>
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;height: 50px;">
      <el-form-item label="商品名称">
        <el-input
          placeholder="请输商品名称"
          v-model="data.productName"
          clearable
          class="input-search-tel"
          @keyup.enter="searchLists"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="searchLists">
          查询
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="data.tableData"
      style="width: 96%; margin-left: 2%;margin-top: 10px;"
      class="table-content"
      v-loading.fullscreen.lock="loading"
      element-loading-text="正在火速加载数据..."
    >
      <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
      <el-table-column label="商品图片" prop="pic" width="100" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;">
            <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="productName" align="center" />
      <el-table-column label="商品规格" prop="spec" align="center" />
      <el-table-column label="成本(元)" prop="profitFree" align="center">
        <template #default="scope">
          <span v-if="scope.row.profitCount == 0">
            0
          </span>
          <span v-else>
            {{ parseInt(scope.row.profitFree) / scope.row.profitCount }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="账面数量" prop="spec" align="center">
        <template #default="scope">
          {{ scope.row.inventory - scope.row.profitCount }}
        </template>
      </el-table-column>
      <el-table-column label="实盘数量" prop="inventory" align="center" />
      <el-table-column label="盈亏数量" prop="profitCount" align="center" />
      <el-table-column label="盈亏金额(元)" prop="profitFree" align="center" />
      <el-table-column label="盈亏状态" prop="spec" align="center">
        <template #default="scope">
          <el-tag v-if="scope.row.profitCount > 0" type="success">盘盈</el-tag>
          <el-tag v-else-if="scope.row.profitCount < 0" type="info">盘亏</el-tag>
          <el-tag v-else>盘平</el-tag>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ref, reactive, defineComponent, computed, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'AddShop',
  props: {
    dialogVisible: Boolean,
    rowMsg: Object,
    params: Object,
  },
  setup(props, ctx) {
    const data = reactive({
      productName: undefined,
      tableData: [],
      pageSize: 10,
      currentPage: 1,
      total: 10,
      loading: false,
    })
    const visible = computed(() => {
      return props.dialogVisible
    })
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    onMounted(() => {
      queryLists()
    })
    const queryLists = () => {
      data.loading = true
      let params = _.cloneDeep(props.params)
      console.log('🚀 ~ queryLists ~ params:', params)
      params.code = props.rowMsg.checkCode
      params.current = data.currentPage
      params.pageSize = data.pageSize
      params.productName = data.productName
      API.selectCheckItemPagePC(params)
        .then(res => {
          if (res.code === '200') {
            data.tableData = res.data.records
            data.total = res.data.total
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => {
          data.loading = false
        })
    }

    const searchLists = () => {
      data.currentPage = 1
      queryLists()
    }
    //分页
    const handleSizeChange = val => {
      data.pageSize = val
      queryLists()
    }
    const handleCurrentChange = val => {
      data.currentPage = val
      queryLists()
    }
    return {
      handleSizeChange,
      handleCurrentChange,
      handleClose,
      searchLists,
      visible,
      data,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
