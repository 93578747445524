<template>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
    <el-form-item label="订单号">
      <el-input
        placeholder="请输入订单号"
        v-model="data.code"
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="会员姓名">
      <el-input
        placeholder="请输入会员姓名"
        v-model="data.userName"
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="会员手机号">
      <el-input
        placeholder="请输入会员手机号"
        v-model="data.telephone"
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="会员卡号">
      <el-input
        placeholder="请输入会员卡号"
        v-model="data.userCode"
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="储值方案">
      <el-input
        placeholder="请输入储值方案"
        v-model="data.onSaleName"
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>

    <el-table-column show-overflow-tooltip label="订单号" prop="code" align="center" />
    <el-table-column show-overflow-tooltip label="会员姓名" prop="userName" align="center" />
    <el-table-column show-overflow-tooltip label="会员昵称" prop="nickName" align="center" />
    <el-table-column show-overflow-tooltip label="会员手机号" prop="telephone" align="center" />
    <el-table-column show-overflow-tooltip label="会员卡号" prop="userCode" align="center" />
    <el-table-column show-overflow-tooltip label="储值方案" prop="onSaleName" align="center" />
    <el-table-column show-overflow-tooltip label="储值本金(元)" prop="storedMoney" align="center" />
    <el-table-column show-overflow-tooltip label="储值赠金(元)" prop="presentMoney" align="center" />
    <el-table-column show-overflow-tooltip label="订单金额(元)" prop="orderMoney" align="center" />
    <el-table-column show-overflow-tooltip label="优惠金额(元)" prop="discountMoney" align="center" />
    <el-table-column show-overflow-tooltip label="应收金额(元)" prop="receivableAmount" align="center" />
    <el-table-column show-overflow-tooltip label="实收金额(元)" prop="actualAmount" align="center" />
    <el-table-column show-overflow-tooltip label="储值门店" prop="storeName" align="center" />
    <el-table-column show-overflow-tooltip label="支付方式" prop="spec" align="center">
      <template #default="scope">
        <text>{{ data.payTypes[scope.row.payType] }}</text>
      </template>
    </el-table-column>
    <el-table-column label="支付时间" prop="payTime" align="center" width="150" />
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/api'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
const data = reactive({
  name: undefined,
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
  payTypes: {
    1: '现金',
    2: '支付宝',
    3: '微信',
    4: 'POS刷卡',
  },
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}
const resetData = () => {
  data.code = undefined
  data.onSaleName = undefined
  data.telephone = undefined
  data.userName = undefined
  data.userCode = undefined
  searchLists()
}
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let params = {
    code: data.code,
    onSaleName: data.onSaleName,
    telephone: data.telephone,
    userName: data.userName,
    userCode: data.userCode,

    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }
  API.getCardsStoredRecordPages(params)
    .then(res => {
      if (res.code == '200') {
        data.tableData = res.data.records
        data.total = res.data.totalRecords
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
