<!--
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-08-20 12:02:42
 * @LastEditTime: 2024-09-19 17:47:19
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report/goodsSellReport.vue
 * @Description:
-->
<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="商品销售统计" name="goodsSellAuth" v-if="isShowReport('goodsSellAuth')">
      <goods-sell-report :reportType="reportType" :reportTypeName="reportTypeName" :auths="authType.goodsSellAuth" />
    </el-tab-pane>
    <el-tab-pane label="套餐销售统计" name="comboSellAuth" v-if="isShowReport('comboSellAuth')">
      <combo-sell-report :reportType="reportType" :reportTypeName="reportTypeName" :auths="authType.comboSellAuth" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import goodsSellReport from '../report_module/goodsSellReport.vue'
import comboSellReport from '../report_module/comboSellReport.vue'
import { onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import Api from '@/api/index.js'

const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  goodsSellAuth: {}, // 商品销售统计
  comboSellAuth: {}, // 套餐销售统计
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})
let reportType = ref('')
let reportTypeName = ref('')
onMounted(() => {
  // getEnmuList()
})

const EnmuList = reactive({
  goodsSellAuth: '商品销售统计',
  comboSellAuth: '套餐销售统计',
})
const getEnmuList = async () => {
  const res = await Api.getEnmuList()
  if (res.code === '200') {
    localStorage.setItem('excelEnmu', JSON.stringify(res?.data) || {})
    reportType.value = res.data[EnmuList[activeName.value]]
    reportTypeName.value = EnmuList[activeName.value]
  }
}
const handleClick = tab => {
  // getEnmuList()
}
/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.goodsSellAuth = authAllMenus.find(a => a.url === 'goodsSellAuth') || { childrens: [] }
  authType.comboSellAuth = authAllMenus.find(a => a.url === 'comboSellAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsSellAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
