<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-08-01 14:38:39
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-14 17:18:04
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/purchaseDetails/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="purchase-detail-box">
    <div class="content-box">
      <el-tabs v-model="tabsName" class="demo-tabs" @tab-click="changeTab">
        <searchTabVue
          :authMenus="authMenus"
          :tabs="tabsName"
          :model="model"
          :getTableData="getTableData"
          ref="searchRef"
          :exportName="exportName"
        />
        <el-tab-pane label="订单" name="first">
          <OrderTemplate ref="orderTemplate"></OrderTemplate>
        </el-tab-pane>
        <el-tab-pane label="售后单" name="second">
          <AfterSale :is="tabsName === 'second'" ref="afterSale" tab="售后单" />
        </el-tab-pane>
        <el-tab-pane label="空瓶单" name="third">
          <EmptyBottle :is="tabsName === 'third'" ref="emptyBottle" tab="空瓶单" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import OrderTemplate from './order-template/index.vue'
import AfterSale from './after-sale/index.vue'
import EmptyBottle from './empty-bottle/index.vue'
import searchTabVue from '../../component/searchTab.vue'
export default {
  components: { OrderTemplate, AfterSale, EmptyBottle, searchTabVue },
  data() {
    return {
      authMenus: {},
      tabsName: 'first',
      tabsList: ['订单', '售后单', '空瓶单'],
      model: {
        endTime: '',
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        supplierCode: '',
        storeCodeList: [],
      },
      getTableData: null,
      exportName: '订单',
    }
  },
  mounted() {
    if (this.tabsName === 'first') {
      operationAuth(this.$route, this.authMenus)
      this.model = this.$refs.orderTemplate.model
      this.getTableData = this.$refs.orderTemplate.getTableData
    }
  },
  methods: {
    changeTab(prop) {
      this.exportName = prop.props.label
      this.tabsName = prop.props.name
      this.$refs.searchRef.formInline = {
        startTime: '', //订单开始时间
        endTime: '', //订单结束时间
        supplierCode: '',
        receivedStartTime: '', //收货开始时间
        receivedEndTime: '', //收货结束时间
        storeCodeList: [],
        region: '',
        recive: '',
      }
      switch (this.tabsName) {
        case 'first':
          let orderModal = {
            pageNum: 1,
            pageSize: 10,
            startTime: '', //订单开始时间
            endTime: '', //订单结束时间
            receivedStartTime: '', //收货开始时间
            receivedEndTime: '', //收货结束时间
            supplierCode: '', //供应商
          }
          this.getTableData = this.$refs.orderTemplate.getTableData
          this.$refs.orderTemplate.getTableData(orderModal)
          break
        case 'second':
          let model = {
            endTime: '',
            pageNum: 1,
            pageSize: 10,
            startTime: '',
            supplierCode: '',
            storeCodeList: [],
          }
          this.getTableData = this.$refs.afterSale.getTableData
          this.$refs.afterSale.getTableData(model)
          break
        case 'third':
          let _model = {
            endTime: '',
            pageNum: 1,
            pageSize: 10,
            startTime: '',
            supplierCode: '',
            storeCodeList: [],
          }
          // this.model = this.$refs.emptyBottle.model
          this.getTableData = this.$refs.emptyBottle.getTableData
          this.$refs.emptyBottle.getTableData(_model)

          break
        default:
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.purchase-detail-box {
  box-sizing: border-box;
  padding: 40px;
  height: 100%;
  overflow: hidden;

  .content-box {
    background-color: #fff;
    box-sizing: border-box;
    padding: 27px 40px;
    height: 100%;
    overflow: hidden;
  }
}
</style>
