import _axios, { post, get, put } from '@/lin/plugin/axios'
import axios from 'axios'

class CsMng {

  //门店
  async getStoreList(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']

    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  //获取全部商品
  selectProductAll(data) {
    return post('/adminChain/goods/saas/goodsSku/selectProductAll', data)
  }

  //查询门店区域关联门店详情
  getRegionList(data) {
    return get('/adminChain/store/saas/storeRegionConfig/getRegionList', data)
  }
  //商品列表
  async selectByType(data) {
    return await get(`/adminChain/goods/saas/category/selectByType`, data)
  }
  async updateCategory(data) {
    return await post(`/adminChain/goods/saas/category/updateCategory`, data)
  }
  async deleteByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/category/deleteByCode`, { params: data })
  }
  async createCategory(data) {
    return await post(`/adminChain/goods/saas/category/createCategory`, data)
  }
  async getSelectList(data) {
    return await get(`/adminChain/goods/saas/statisticsType/selectList`, data)
  }
  async selectListByType(data) {
    return await get(`/adminChain/goods/saas/category/selectListByType`, data)
  }
  async updateIsShelves(data) {
    return await get(`/adminChain/goods/saas/category/updateIsShelves`, data)
  }

  async updateIsShelvesNoType(data) {
    return await get(`adminChain/goods/saas/goodsSku/updateIsShelves`, data)
  }
  //单位配置
  async selectDictList(data) {
    return await get(`/adminChain/goods/saas/dict/selectDictList`, data)
  }
  async deleteDict(data) {
    return await _axios.delete(`/adminChain/goods/saas/dict/deleteDict`, { params: data })
  }
  async saveDict(data) {
    return await post(`/adminChain/goods/saas/dict/saveDict`, data)
  }
  async selectDictListAll(data) {
    return await get(`/adminChain/goods/saas/dict/selectDictListAll`, data)
  }
  async updateDict(data) {
    return await post(`/adminChain/goods/saas/dict/updateDict`, data)
  }


  //出入库类型
  async selectChannel(data) {
    return await get(`/adminChain/goods/saas/stockChannel/selectChannel`, data)
  }

  //易耗品商店列表
  async selectConsumables(data) {
    return await post(`/adminChain/goods/saas/goodsSku/selectConsumables`, data)
  }

  //商品列表 -- 一级操作
  async selectSpuList(data) {
    return await post(`/adminChain/goods/saas/goodsSpu/selectSpuList`, data)
  }
  async saveSpuInfo(data) {
    return await post(`/adminChain/goods/saas/goodsSpu/saveSpuInfo`, data)
  }
  async updateSpuInfo(data) {
    return await post(`/adminChain/goods/saas/goodsSpu/updateSpuInfo`, data)
  }
  async deleteSpuInfoByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/goodsSpu/deleteSpuInfoByCode`, { params: data })
  }
  async uploadImags(data) {
    return await post('/adminChain/goods/saas/material/upload', data, { 'Content-type': 'multipart/form-data' })
  }

  //商品门店二级
  async saveSkuOne(data) {
    return await post('/adminChain/goods/saas/goodsSku/saveSkuOne', data)
  }
  async updateSkuByCode(data) {
    return await post('/adminChain/goods/saas/goodsSku/updateSkuByCode', data)
  }
  async selectDetails(data) {
    return await get('/adminChain/goods/saas/goodsSku/selectDetails', data)
  }
  async deleteSkuByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/goodsSku/deleteSkuByCode`, { params: data })
  }

  //供应商信息
  async selectSupplierPage(data) {
    return await post('/adminChain/supplier/saas/supplier/selectSupplierPage', data)
  }
  async saveSupplierInfo(data) {
    return await post('/adminChain/supplier/saas/supplier/saveSupplierInfo', data)
  }
  async updateSupplierInfo(data) {
    return await post('/adminChain/supplier/saas/supplier/updateSupplierInfo', data)
  }
  //供应商停用/启用
  async supplierEnable(data) {
    return await get('/adminChain/supplier/saas/supplier/supplierEnable', data)
  }
  //供应商-门店信息分页查询
  async selectStorePage(data) {
    return await post('/adminChain/supplier/saas/suppliesStore/selectStorePage', data)
  }

  //供应商-新供应商-关联门店回显
  async querySupplierStoreInfo(data) {
    return await get('/adminChain/supplier/saas/suppliesStore/querySupplierStoreInfo', data)
  }

  //供应商-新供应商-绑定门店
  async newSaveBingSupplierStore(data) {
    return await post('/adminChain/supplier/saas/suppliesStore/newSaveBingSupplierStore', data)
  }


  //供应商-停止供贷
  async isStopSupply(data) {
    return await post('/adminChain/supplier/saas/suppliesStore/isStopSupply', data)
  }

  //供应商详情
  async querySupplierDetails(data) {
    return await get('/adminChain/supplier/saas/supplier/querySupplierDetails', data)
  }
  //供应商-绑定门店
  async saveBingSupplierStore(data) {
    return await post('/adminChain/supplier/saas/suppliesStore/saveBingSupplierStore', data)
  }
  //供应商-绑定商品
  async saveBindProduct(data) {
    return await post('/adminChain/supplier/saas/supplierGoods/saveBindProduct', data)
  }

  //供应商-修改商品信息
  async updateProduct(data) {
    return await post('/adminChain/supplier/saas/supplierGoods/updateProduct', data)
  }

  //供应商-商品信息分页查询
  async selectProductPage(data) {
    return await get('/adminChain/supplier/saas/supplierGoods/selectProductPage', data)
  }

  //获取地区地址省市区三级嵌套列表
  async getAreaWithChildList(data) {
    return await get('/adminChain/store/saas/getAreaWithChildList', data)
  }

  //渠道
  async getChannelList(data) {
    return await get('/adminChain/goods/saas/getChannelList', data)
  }

  //组合
  async selectSkuArrayPage(data) {
    return await post('/adminChain/goods/saas/goodsArray/selectSkuArrayPage', data)
  }
  async saveSkuArray(data) {
    return await post('/adminChain/goods/saas/goodsArray/saveSkuArray', data)
  }
  async updateSkuArrayByCode(data) {
    return await post('/adminChain/goods/saas/goodsArray/updateSkuArrayByCode', data)
  }
  async deleteComboByCode(data) {
    return await _axios.delete(`/adminChain/goods/saas/goodsCombo/deleteComboByCode`, { params: data })
  }
  async selectBelow(data) {
    return await get('/adminChain/goods/saas/goodsCombo/selectBelow', data)
  }
  async selectBelowProductSkuArray(data) {
    return await get('/adminChain/goods/saas/goodsArray/selectBelowProductSkuArray', data)
  }

  async supplierQuerySku(data) {
    return await get('/adminChain/goods/saas/supplierQuerySku', data)
  }

  async updateSkuArrayIsShelves(data) {
    return await get('/adminChain/goods/saas/goodsSku/updateSkuArrayIsShelves', data)
  }
  async selectSkuArrayItem(data) {
    return await get('/adminChain/goods/saas/goodsArray/selectSkuArrayItem', data)
  }

  //套餐
  async selectComboPage(data) {
    return await post('/adminChain/goods/saas/goodsCombo/selectComboPage', data)
  }
  async createComboInfo(data) {
    return await post('/adminChain/goods/saas/goodsCombo/createComboInfo', data)
  }
  async updateComboInfo(data) {
    return await post('/adminChain/goods/saas/goodsCombo/updateComboInfo', data)
  }
  async updateComboIsShelves(data) {
    return await get('/adminChain/goods/saas/goodsCombo/updateComboIsShelves', data)
  }
  async selectBelowProductCombo(data) {
    return await get('/adminChain/goods/saas/goodsCombo/selectBelowProductCombo', data)
  }
  async selectComboItem(data) {
    return await get('/adminChain/goods/saas/goodsCombo/selectComboItem', data)
  }
  //地区
  async getArea(data) {
    return await get('/adminChain/organizational/saas/getArea', data)
  }

  //组织
  async getCheckAreaOrganizational(data) {
    return await get('/adminChain/organizational/saas/getCheckAreaOrganizational', data)
  }

  //获取门店列表-分页
  async getStoreListPages(data) {
    return await post(`/adminChain/store/saas/getStoreListPages`, data)
  }

  //采购单列表
  async selectPurchasePage(data) {
    return await post(`/adminChain/purchase/saas/purchase/selectPurchasePage`, data)
  }
  //生成versionUid
  async generateVersionUid(data) {
    return await get(`/adminChain/purchase/saas/purchase/generateVersionUid`, data)
  }
  //添加采购单商品
  async savePurchaseOrder(data) {
    return await post(`/adminChain/purchase/saas/purchase/savePurchaseOrder`, data)
  }
  //获取采购单详情
  async selectPurchaseDetails(data) {
    return await get(`/adminChain/purchase/saas/purchase/selectPurchaseDetails`, data)
  }
  //取消采购
  async cancelPurchase(data) {
    return await post(`/adminChain/purchase/saas/purchase/cancelPurchase`, data)
  }

  //取消采购
  async cancelPurchaseInDetails(data) {
    return await post(`/adminChain/purchase/saas/purchase/cancelPurchaseInDetails`, data)
  }

  //修改采购单
  async updatePurchaseOrder(data) {
    return await post(`/adminChain/purchase/saas/purchase/updatePurchaseOrder`, data)
  }
  //提交采购单
  async commitPurchaseOrder(data) {
    return await post(`/adminChain/purchase/saas/purchase/commitPurchaseOrder`, data)
  }
  //供应商-下拉框
  async selectSupplierList(data) {
    return await post(`/adminChain/supplier/saas/supplier/selectSupplierList`, data)
  }
  //删除商品
  async deleteProduct(data) {
    return await get(`/adminChain/supplier/saas/supplierGoods/deleteProduct`, data)
  }

  //删除商品
  async deleteDetailsGoods(data) {
    return await get(`/adminChain/purchase/saas/purchase/deleteDetailsGoods`, data)
  }

  //详情
  async queryDetails(data) {
    return await get(`/adminChain/purchase/saas/purchase/queryDetails`, data)
  }


  //供应商--新

  async queryThirdPartySupplierEmptyGoodsPage(data) {
    return await post(`/adminChain/thirdPartySupplierEmptyGoods/queryThirdPartySupplierEmptyGoodsPage`, data)
  }
  async queryThirdPartySupplierEmptyGoodsDetail(data) {
    return await post(`/adminChain/thirdPartySupplierEmptyGoods/queryThirdPartySupplierEmptyGoodsDetail`, data)
  }
  async updatePriceByCode(data) {
    return await post(`/adminChain/thirdPartySupplierEmptyGoods/updatePriceByCode`, data)
  }
  async deleteTbThirdPartySupplierEmptyGoods(data) {
    return await post(`/adminChain/thirdPartySupplierEmptyGoods/deleteTbThirdPartySupplierEmptyGoods`, data)
  }
  async batchThirdPartySupplierEmptyGoods(data) {
    return await post(`/adminChain/thirdPartySupplierEmptyGoods/batchThirdPartySupplierEmptyGoods`, data)
  }
  async queryGoodsEmptyBottleInfo(data) {
    return await post(`/adminChain/goodsEmptyBottle/queryGoodsEmptyBottleInfo`, data)
  }

  // 通联账号
  async queryMerchantNo(data) {
    return await post(`/adminChain/thirdPartySupplierMerchant/queryMerchantNo`, data)
  }
  async updateMerchant(data) {
    return await post(`/adminChain/thirdPartySupplierMerchant/updateMerchant`, data)
  }

  //供应商详情
  async queryTbThirdPartySupplierByCodeDetail(data) {
    return await post(`/adminChain/thirdPartySupplier/queryTbThirdPartySupplierByCodeDetail`, data)
  }

  async queryTbThirdPartySupplierPage(data) {
    return await post(`/adminChain/thirdPartySupplier/queryTbThirdPartySupplierPage`, data)
  }

  //绑定门店
  async batchSaveThirdPartySupplierStore(data) {
    return await post(`/adminChain/thirdPartySupplierStore/batchSaveThirdPartySupplierStore`, data)
  }

  async queryThirdPartySupplierStoreAll(data) {
    return await post(`/adminChain/thirdPartySupplierStore/queryThirdPartySupplierStoreAll`, data)
  }
  async queryStoreCode(data) {
    return await post(`/adminChain/thirdPartySupplierStore/queryStoreCode`, data)
  }

  async updateIsSupper(data) {
    return await post(`/adminChain/thirdPartySupplierStore/updateIsSupper`, data)
  }


}

export default new CsMng()
