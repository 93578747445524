<template>
  <el-dialog
    :title="'code' in updateRowMsg ? '编辑可兑换商品' : '新增可兑换商品'"
    v-model="dialogVisible"
    width="60%"
    :before-close="handleClose"
  >
    <el-form :model="updateRowMsg" :rules="rules" ref="formRef">
      <el-form-item label="可兑换商品名称" label-width="120px" prop="name">
        <div>
          <el-input
            placeholder="请输入可兑换商品名称，1~20个字符"
            maxlength="20"
            v-model="updateRowMsg.name"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="商品类型" label-width="120px" class="is-required">
        <el-select v-model="updateRowMsg.type">
          <el-option label="优惠券" :value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="关联对象" label-width="120px" prop="coupons">
        <el-button @click="addCoupou">选择优惠卷</el-button>
        <div v-if="JSON.stringify(updateRowMsg.coupons) != '{}'">
          <div class="coupou-item">
            <div class="left">
              <text style="text-align: center;"
                >{{ updateRowMsg.coupons.scheme }}{{ unitsObj[updateRowMsg.coupons.type] }}</text
              >
              <text style="text-align: center;">{{
                updateRowMsg.coupons.minimumCharge === -1 ? '无门槛' : updateRowMsg.coupons.minimumCharge
              }}</text>
            </div>
            <div class="right">
              <text>{{ updateRowMsg.coupons.name }}</text>
              <div>有效期：{{ updateRowMsg.coupons.validityTimeStr }}</div>
              <text>仅{{ updateRowMsg.coupons.usedRange === 1 ? '房费' : '商品' }}可用</text>
              <text
                >发行数量：{{
                  updateRowMsg.coupons.issueNum === -1 ? '不限制' : updateRowMsg.coupons.realIssueNum
                }}</text
              >
            </div>

            <div style="position: absolute;top: 10px;right: 20px;cursor: pointer;" @click="deleteMustSelectProduct">
              <i class="el-icon-circle-close"></i>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="上架">
        <el-switch v-model="updateRowMsg.status" :active-value="1" inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item label="兑换积分" prop="amount">
        <el-input-number
          style="width: 74%"
          v-model="updateRowMsg.amount"
          placeholder="请输入兑换所需积分，1~9999"
          precision="0"
          :controls="false"
          :min="1"
          :max="9999"
        />
      </el-form-item>
      <el-form-item label="会员等级" prop="userCustomerLevels">
        <el-checkbox-group v-model="updateRowMsg.userCustomerLevels" @change="changeUserCustomerLevels">
          <el-checkbox :label="level.code" v-for="level in levels" :key="level.code">{{ level.name }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="可兑换总数" class="is-required" prop="grantCount">
        <div style="display: flex;align-items: center;height: 40px">
          <el-radio-group v-model="updateRowMsg.grantCountStatus" style="width: 150px;" @input="inputGrantCountStatus">
            <el-radio :label="1">不限</el-radio>
            <el-radio :label="0">限制</el-radio>
          </el-radio-group>
          <el-input-number
            v-if="updateRowMsg.grantCountStatus == 0"
            style="width: 200px;"
            v-model="updateRowMsg.grantCount"
            precision="0"
            :controls="false"
            :min="1"
            :max="999999"
          />
        </div>
      </el-form-item>
      <el-form-item label="个人可兑换数量" prop="grantLimit" class="is-required">
        <div style="display: flex;align-items: center;height: 40px">
          <el-radio-group v-model="updateRowMsg.grantLimitStatus" style="width: 150px;" @input="inputGrantLimitStatus">
            <el-radio :label="1">不限</el-radio>
            <el-radio :label="0">限制</el-radio>
          </el-radio-group>
          <el-input-number
            v-if="updateRowMsg.grantLimitStatus == 0"
            style="width: 200px;"
            v-model="updateRowMsg.grantLimit"
            precision="0"
            :controls="false"
            :min="1"
            :max="99"
          />
        </div>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <SelectCoupou
    :couponByUserAuth="true"
    :isAll="true"
    :onlySelectOne="true"
    :isFixedStore="0"
    :needFilterStore="true"
    :storeCodeList="stores"
    v-if="coupouDialogVisible"
    :dialogVisible="dialogVisible"
    @update-dialogVisible="rectiveProducts"
    :hasPro="hasPro"
  ></SelectCoupou>
</template>

<script setup>
import API from '../service/api'
import { ref, defineEmit, defineProps, reactive, nextTick, watchEffect, onMounted } from 'vue'
import SelectCoupou from '../component/select-coupou.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

//分页参数
const pageParams = reactive({
  pageNum: 1,
  pageSize: 5,
  total: 0,
})
//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
}
const handleCurrentChange = val => {
  pageParams.pageNum = val
}
const pageData = ref([]) //分页数据

const unitsObj = {
  1: '折',
  2: '元',
  3: '分钟',
  4: '元',
}

//日期选择器设置
const disabledDate = time => {
  return time.getTime() < Date.now()
}
/**
 * 父容器传过来的参数
 */
const props = defineProps({
  updateRowMsg: {
    type: Object,
    default: () => ({
      fixedStores: [],
      storedAmount: '',
    }),
  },
  levels: {
    type: Array,
    default: () => [],
  },
})
const stores = ref([])
//  获取门店
const getShops = () => {
  let params = {
    list: [],
  }
  API.getStoreList(params).then(res => {
    if (res.code === '200') {
      stores.value = res.data.map(item => item.code)
    }
  })
}
getShops()

const hasPro = ref([])
/**添加优惠卷 */
const addCoupou = () => {
  if (JSON.stringify(props.updateRowMsg.coupons) != '{}') {
    hasPro.value = [props.updateRowMsg.coupons]
  }
  isShowCoupouDialog(true)
}
/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['handleCloseDialog'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

/**
 * 删除当前优惠卷
 * @param {object} row
 */
const deleteMustSelectProduct = row => {
  ElMessageBox.confirm('确认删除当前优惠劵吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    props.updateRowMsg.coupons = {}
    hasPro.value = []
    if (JSON.stringify(props.updateRowMsg.coupons) != '{}') {
      formRef.value.validateField('coupons')
    }
  })
}

// 选择优惠卷
const coupouDialogVisible = ref(false) //商品选择组件展示

/**
 * 接受新数据
 * @param {object} item 选择的商品
 */
const rectiveProducts = ({ selectSkus, isUpdate }) => {
  console.log(selectSkus)

  isShowCoupouDialog(false)
  if (isUpdate) {
    props.updateRowMsg.coupons = selectSkus[0] || {}
    if (JSON.stringify(props.updateRowMsg.coupons) != '{}') {
      formRef.value.validateField('coupons')
    }
  }
}

/**
 * 是否展示选择优惠卷ialog
 */
const isShowCoupouDialog = val => {
  coupouDialogVisible.value = val
}

const validateGrantCount = (rule, value, callback) => {
  if (props.updateRowMsg.grantCountStatus == 0 && !props.updateRowMsg.grantCount) {
    return callback(new Error('可兑换总数必填'))
  } else {
    callback()
  }
}
const validateGrantLimit = (rule, value, callback) => {
  if (props.updateRowMsg.grantLimitStatus == 0 && !props.updateRowMsg.grantLimit) {
    return callback(new Error('个人可兑换数量必填'))
  } else if (
    props.updateRowMsg.grantLimitStatus == 0 &&
    props.updateRowMsg.grantCountStatus == 0 &&
    props.updateRowMsg.grantCount < props.updateRowMsg.grantLimit
  ) {
    return callback(new Error('个人可兑换数量必须小于可兑换数量'))
  }
  {
    callback()
  }
}
const validateCoupons = (rule, value, callback) => {
  if (JSON.stringify(props.updateRowMsg.coupons) == '{}') {
    return callback(new Error('请选择关联对象'))
  } else {
    callback()
  }
}

const changeUserCustomerLevels = () => {
  formRef.value.validateField('userCustomerLevels')
}
const inputGrantCountStatus = () => {
  if (props.updateRowMsg.grantCountStatus == 0) {
    formRef.value.clearValidate('grantCount')
    if (props.updateRowMsg.grantLimitStatus == 0 && props.updateRowMsg.grantLimit) {
      formRef.value.clearValidate('grantLimit')
    } else {
      formRef.value.validateField('grantLimit')
    }
  } else {
    formRef.value.validateField('grantCount')
  }
}

const inputGrantLimitStatus = () => {
  if (props.updateRowMsg.grantLimitStatus == 0) {
    formRef.value.clearValidate('grantLimit')
  } else {
    formRef.value.validateField('grantLimit')
  }
}

const formRef = ref(null)
const rules = {
  coupons: [{ required: true, trigger: 'change', validator: validateCoupons }],
  name: [{ required: true, message: '请填写可兑换商品名称', trigger: 'blur' }],
  amount: [{ required: true, message: '请填写兑换积分', trigger: 'blur' }],
  type: [{ required: true, message: '请选择商品类型', trigger: 'blur' }],
  userCustomerLevels: [{ required: true, message: '请选择会员等级', trigger: 'blur' }],

  grantCount: [{ required: true, validator: validateGrantCount, trigger: 'blur' }],
  grantLimit: [{ required: true, validator: validateGrantLimit, trigger: 'blur' }],
}

/**
 * 保存
 */
const handleCommit = async () => {
  try {
    await formRef.value.validate()
    let params = generateParams()
    let res
    if ('code' in params) {
      res = await API.pointsGoodsUpdate(params)
    } else {
      res = await API.pointsGoodsAdd(params)
    }

    if (res.code === '200') {
      ElMessage.success('操作成功')
      emit('handleCloseDialog', {
        search: true,
      })
    } else {
      ElMessage.error(res.msg)
    }
  } catch (error) {
    console.log(error)
    ElMessage.warning('请完成必要信息填写')
  }
}

/**
 * 生成参数
 */
const generateParams = () => {
  let params = Object.assign({}, props.updateRowMsg)
  params.relationJson = JSON.stringify(params.coupons)
  params.grantCount = params.grantCountStatus == 1 ? -1 : params.grantCount
  params.grantLimit = params.grantLimitStatus == 1 ? -1 : params.grantLimit
  params.userCustomerLevels = params.userCustomerLevels.map(a => {
    let b = props.levels.find(b => b.code == a)
    return {
      code: b.code,
      name: b.name,
    }
  })
  delete params.grantCountStatus
  delete params.grantLimitStatus
  delete params.coupons
  return params
}
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 130px !important;
  }
}
.remark {
  color: rosybrown;
}
.coupon-table {
  margin-top: 5px;
}
.el-input-number {
  width: 100%;
}
::v-deep(.el-input__inner) {
  text-align: left;
}
.timer-toast {
  margin-left: 5px;
  color: rosybrown;
}
.coupou-item {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  position: relative;
  .left {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-right: 1px solid #ccc;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
