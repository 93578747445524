<template>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
    <el-form-item label="供应商名称">
      <el-input
        placeholder="请输入供应商名称"
        v-model="data.supplierName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="订单编号">
      <el-input
        placeholder="请输订单编号"
        v-model="data.purchaseCode"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店名称">
      <el-input
        placeholder="请输门店名称"
        v-model="data.storeName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>

    <el-form-item>
      <el-date-picker
        v-model="data.time"
        type="daterange"
        range-separator="至"
        start-placeholder="订单日期"
        end-placeholder="订单日期"
        value-format="YYYY-MM-DD"
        @change="searchLists"
      />
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" type="primary">
        查询
      </el-button>
      <el-button @click="resetData" type="primary">
        <el-icon><Delete /></el-icon>
        重置
      </el-button>
      <el-button @click="addPurchase" v-if="data.authMenus.add" type="primary">
        <el-icon><Plus /></el-icon>
        新增
      </el-button>
    </el-form-item>
  </el-form>
  <el-tabs v-model="activeName" class="demo-tabs">
    <el-tab-pane :label="item.name" :name="item.value" v-for="(item, index) in data.purchaseOrderType" :key="index" />
  </el-tabs>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column show-overflow-tooltip label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column show-overflow-tooltip label="订单号" prop="code" align="center" />
    <el-table-column show-overflow-tooltip label="供应商名称" prop="supplierName" align="center" />
    <el-table-column show-overflow-tooltip label="门店编号" prop="storeCode" align="center" />
    <el-table-column show-overflow-tooltip label="门店名称" prop="storeName" align="center" />
    <el-table-column show-overflow-tooltip label="SKU数量" prop="skuCount" align="center" />
    <el-table-column show-overflow-tooltip label="商品总数量" prop="totalCount" align="center" />
    <el-table-column show-overflow-tooltip label="总金额(元)" prop="totalPrice" align="center">
      <template #default="scope">
        {{ scope.row.totalPrice + scope.row.freight }}
      </template>
    </el-table-column>
    <el-table-column label="结账类型" prop="checkoutType" align="center">
      <template #default="scope">
        {{ scope.row.checkoutType == 1 ? '月结' : '现结' }}
      </template>
    </el-table-column>
    <el-table-column label="订单状态" align="center">
      <template #default="scope">
        {{ data.orderstatus[scope.row.status] }}
      </template>
    </el-table-column>
    <el-table-column label="下单时间" prop="createTime" align="center" />
    <el-table-column label="操作" align="center" width="300">
      <template #default="scope">
        <!-- <el-button link type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.query">详情</el-button>
        <el-button link type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update">修改</el-button>
        <el-button link type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.delete">删除</el-button> -->
        <el-button
          v-if="data.authMenus.update"
          link
          type="text"
          @click="showDetail(item1, scope.row)"
          :key="index"
          v-for="(item1, index) in data.btnsByType[scope.row.status]"
          >{{ data.btnName[item1] }}</el-button
        >
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />

  <AddOrder
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    @update-dialogVisible="updateDialogVisible"
  ></AddOrder>
  <OrderInfo
    :rowMsg="data.rowMsg"
    v-if="data.orderInfodialogVisible"
    :dialogVisible="data.orderInfodialogVisible"
    @update-dialogVisible="updateOrderInfoDialogVisible"
  ></OrderInfo>
</template>

<script setup>
import OrderInfo from './order-info.vue'
import AddOrder from './add-order.vue'
import API from '../../service/api'
import { ref, reactive, computed, onMounted, watch } from 'vue'
import _ from 'lodash'
import { ElMessage, ElMessageBox } from 'element-plus'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
const data = reactive({
  storeName: '',
  supplierName: '',
  purchaseCode: '',
  time: ['', ''],
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
  dialogVisible: false,
  rowMsg: {},
  orderInfodialogVisible: false,
  btnName: {
    1: '订单详情',
    2: '取消退单',
    3: '再次购买',
    4: '取消订单',
    5: '收货入库',
    6: '查看物流',
    7: '缺货退款',
    8: '退换/售后',
    9: '删除订单',
  },
  btnsByType: {
    2: [1, 2, 3],
    1: [1, 4, 5, 3],
    3: [1, 4, 5, 3],
    5: [1, 6, 5, 7, 8, 3],
    6: [1, 9, 3],
    7: [1, 9, 3],
    8: [1, 8, 3],
  },
  orderstatus: {
    1: '待接单',
    2: '申请退单',
    3: '待发货',
    5: '待收货',
    6: '已拒单',
    7: '已取消',
    8: '已完成',
  },
  purchaseOrderType: [
    {
      name: '全部订单',
      value: -1,
    },
    {
      name: '申请退单',
      value: 2,
    },
    {
      name: '待接单',
      value: 1,
    },
    {
      name: '待发货',
      value: 3,
    },
    {
      name: '待收货',
      value: 5,
    },
    {
      name: '已拒单',
      value: 6,
    },
    {
      name: '已取消',
      value: 7,
    },
    {
      name: '已完成',
      value: 8,
    },
  ],
})
const activeName = ref(-1)
const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
watch(activeName, () => {
  searchLists()
})

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}
const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryLists()
  }
}

const updateOrderInfoDialogVisible = ({ update, search }) => {
  data.orderInfodialogVisible = update
  if (search) {
    queryLists()
  }
}
const resetData = () => {
  data.storeName = undefined
  data.supplierName = undefined
  data.purchaseCode = undefined
  data.time = ['', '']
  searchLists()
}
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let params = {
    purchaseCode: data.purchaseCode,
    supplierName: data.supplierName,
    storeName: data.storeName,
    status: activeName.value,
    current: data.currentPage,
    pageSize: data.pageSize,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }

  API.selectPurchasePage(params)
    .then(res => {
      if (res.code === '200') {
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}

//操作
const showDetail = (type, row) => {
  console.log(type)
  switch (type) {
    case 1:
      data.rowMsg = _.cloneDeep(row)
      data.orderInfodialogVisible = true
      break
    case 2:
      break
    case 3:
      break
    case 4:
      cancelOrder(row)
      break
    case 5:
      break
    case 6:
      break
  }
}

//取消采购
const cancelOrder = rowMsg => {
  ElMessageBox.prompt('请输入取消原因', '取消', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPattern: /\S/,
    inputErrorMessage: '取消原因不能为空！',
  })
    .then(({ value }) => {
      let params = {
        supplierCode: rowMsg.supplierCode,
        versionUid: rowMsg.versionUid,
        remarks: value,
      }
      API.cancelPurchase(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('取消成功')
            queryLists()
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => {
          data.loading = false
        })
    })
    .catch(re => {})
}

const addPurchase = () => {
  data.dialogVisible = true
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
