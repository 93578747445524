<!-- 班次营业情况-->
<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="收入类型分析" name="incomeTypeAnalysisAtuh" v-if="isShowReport('incomeTypeAnalysisAtuh')">
      <IncomeTypeAnalysis :auths="authType.incomeTypeAnalysisAtuh" />
    </el-tab-pane>
    <!-- <el-tab-pane label="包厢类型分析" name="boxTypeAnalysisAuth" v-if="isShowReport('boxTypeAnalysisAuth')">
      <BoxTypeAnalysis :auths="authType.boxTypeAnalysisAuth" />
    </el-tab-pane> -->
  </el-tabs>
</template>

<script setup>
import IncomeTypeAnalysis from '../report_module/shiftOperation/IncomeTypeAnalysis.vue'
import BoxTypeAnalysis from '../report_module/shiftOperation/boxTypeAnalysis.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  incomeTypeAnalysisAtuh: {}, // 收入类型分析
  boxTypeAnalysisAuth: {}, // 包厢类型分析
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.incomeTypeAnalysisAtuh = authAllMenus.find(a => a.url === 'incomeTypeAnalysisAtuh') || { childrens: [] }
  authType.boxTypeAnalysisAuth = authAllMenus.find(a => a.url === 'boxTypeAnalysisAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'incomeTypeAnalysisAtuh'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
.el-tabs {
  padding: 0px 20px;
}
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}

::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
</style>
