<!--
 * @Author: your name
 * @Date: 2022-04-20 13:09:23
 * @LastEditTime: 2022-12-14 10:22:08
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/shop-member-manager/index.vue
-->
<template>
  <div class="shop-order-manager">
    <SearchBar :authMenus="authMenus" searchType="roomPrice" :getTableData="getTableData" :model="model" />
    <h1 class="table-title">买钟基准价</h1>
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap">
      <el-table-column prop="roomTypeName" label="包厢类型" />
      <el-table-column prop="price" label="房费">
        <template #default="scope">
          <div>{{ scope.row.price }}/小时</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template #default="scope">
          <el-button type="text" @click="editRoomPrice(scope.$index, scope.row)" v-if="authMenus.update"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
    <el-drawer v-model="infoModal" size="35%" :direction="direction" :with-header="false" :before-close="handleClose">
      <div class="order-detail-item">
        <h1>
          编辑基准买钟价格<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="infoModal = false">
            <close />
          </el-icon>
        </h1>
        <div class="info-line"></div>
        <div class="room-type">包厢类型：{{ rowDetail.roomTypeName }}</div>
        <div class="room-time">
          <span>房费</span>
          <el-input v-model="price" placeholder="Please input" class="roomTime-input">
            <template #append>元/小时</template>
          </el-input>
        </div>
        <el-button type="primary" class="info-confirm" @click="updatePrice">保存</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import SearchBar from '../../component/tableSearchTab.vue'
import api from '../../service/index'
export default {
  components: { SearchBar },
  data() {
    return {
      authMenus: {},
      searchDate: '',
      options: [],
      tableData: [],
      background: false,
      infoModal: false, //详情Modal
      recordsDetail: {},
      model: {
        pageNum: 1,
        pageSize: 10,
        storeCode: 'K0000',
        roomTypeCode: '',
      },
      rowDetail: {},
      price: '',
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getTableData(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getTableData(model) {
      if (this.authMenus.query) {
        api.getAdminChainRoomPriceList(model).then(res => {
          this.tableData = res.data
        })
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    editRoomPrice(index, row) {
      this.infoModal = true
      this.price = row.price
      this.rowDetail = row
    },
    handleClose() {
      this.infoModal = false
    },
    updatePrice() {
      this.rowDetail.price = this.price
      api.updateRoomPrice(this.rowDetail).then(res => {
        if (res.code === '200') {
          ElMessage({
            type: 'success',
            message: `编辑成功`,
          })
          this.infoModal = false
          this.getTableData(this.model)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.shop-order-manager {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;

  .table-title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    margin-bottom: 20px;
    color: #222222;
  }

  .shop-order-search {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 41px;

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }
  }

  ::v-deep .el-drawer__body {
    margin-top: -75px;
  }

  .order-detail-item {
    width: 588px;
    margin: auto;
    margin-top: 73px;

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .close-icon {
        position: absolute;
        top: 20px;
        right: 0px;
        cursor: pointer;
      }
    }

    .room-type {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      margin-bottom: 24px;
      color: #000000;
    }

    .room-time {
      span {
        display: inline-block;
        width: 80px;
      }

      .roomTime-input {
        width: 200px;

        ::v-deep .el-input-group__append,
        .el-input-group__prepend {
          background-color: #f3f3f3;
          border: none;
          color: #000000;
        }
      }
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .info-confirm {
      position: fixed;
      bottom: 24px;
      right: 40px;
      width: 121px;
      height: 40px;
      background-color: #1989fa !important;
      border: none !important;
    }
  }
}
</style>
