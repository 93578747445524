<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-08-01 15:34:15
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-14 17:19:38
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/purchase-details/order-template/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="order-detail-box">
    <el-table :data="tableData" style="height:100%" class="el-table-header-gap table-content">
      <el-table-column prop="storeName" label="门店" />
      <el-table-column prop="supplierName" label="供应商" />
      <el-table-column prop="code" label="订单号" />
      <el-table-column prop="price" label="订单金额" />
      <el-table-column prop="isStatement" label="付款状态">
        <template #default="scope">
          {{ isStatementList[scope.row.isStatement] }}
        </template>
      </el-table-column>
      <el-table-column prop="state" label="订单状态">
        <template #default="scope">
          <span :class="'state' + scope.row.state">
            {{ stateList[scope.row.state] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
    <elDrawerVue ref="drawer" :title="'订单号：' + detailData.code" :state="detailData.state">
      <ul class="detail-drawer">
        <li>下单时间：{{ detailData.createTime }}</li>
        <li>发货时间： {{ detailData.deliveryTime }}</li>
        <li>收货信息： {{ addressContent.contractor }} {{ addressContent.telephone }} {{ addressContent.address }}</li>
        <li>收货时间：{{ detailData.receivedTime }}</li>
      </ul>
      <div class="logistics-content-box" v-if="detailData.state !== 1">
        <h2>
          物流状态：<span></span>
          <span
            ><img v-if="expressageImg" :src="expressageImg" class="logo-img" alt="" />{{ expName }}
            {{ detailData.logisticsInfo && detailData.logisticsInfo.result.number }}</span
          >
        </h2>
        <div style="display:block;height:200px;overflow: auto; margin-bottom: 32px;padding-top: 10px;">
          <el-timeline style="height:100px">
            <el-timeline-item
              v-for="(activity, index) in logisticsInfo"
              :key="index"
              :hollow="activity.hollow"
              :timestamp="activity.time"
              :color="activity.color"
            >
              {{ activity.status }}
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <TableVue :columns="columns" :data="detailData.itemList" v-if="detailData.state !== 1" class="table-content" />
    </elDrawerVue>
  </div>
</template>

<script>
import elDrawerVue from '../../../../../component/base/el-Drawer/el-drawer.vue'
import Pagination from '../../../component/pagination.vue'
import TableVue from '../component/table.vue'
import api from '../../../service/index'
export default {
  components: {
    elDrawerVue,
    Pagination,
    TableVue,
  },
  data() {
    return {
      tableData: [],
      status: '待收货',
      totalRecords: null,
      totalPage: null,
      model: {
        pageNum: 1,
        pageSize: 10,
        startTime: '', //订单开始时间
        endTime: '', //订单结束时间
        receivedStartTime: '', //收货开始时间
        receivedEndTime: '', //收货结束时间
        supplierCode: '', //供应商
      },
      isStatementList: { 0: '未结单', 1: '已结单未付款', 2: '已结单已付款' },
      stateList: { 1: '待确定', 2: '待发货', 3: '待收货', 4: '已收货' },
      detailData: {},
      supplierList: [],
      addressContent: {},
      columns: [
        {
          prop: 'skuName',
          label: '商品名称',
        },
        {
          prop: 'unit',
          label: '进货单位',
        },
        {
          prop: 'skuPrice',
          label: '进货价',
        },
        {
          prop: 'num',
          label: '数量',
        },
        {
          prop: 'address',
          label: '进货单品总价',
          formatter: val => {
            return val.skuPrice * val.num
          },
        },
      ],
      logisticsInfo: [],
      expName: null,
      expressageImg: '',
    }
  },
  mounted() {
    this.getTableData(this.model)
  },
  methods: {
    getTableData(model) {
      api.getOrderListPages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    showDetail(index, row) {
      this.$refs.drawer.detailModal = true
      this.logisticsInfo = []
      api.getOrderDetail({ code: row.code }).then(res => {
        this.detailData = res.data
        this.addressContent = JSON.parse(this.detailData.addressContent)
        this.logisticsInfo = this.detailData.logisticsInfo && this.detailData.logisticsInfo.result.list
        this.expName = this.detailData.logisticsInfo && this.detailData.logisticsInfo.result.expName
        this.expressageImg = this.detailData.logisticsInfo && this.detailData.logisticsInfo.result.logo
        this.logisticsInfo &&
          this.logisticsInfo.map((item, index) => {
            if (index === 0) {
              item.hollow = true
              item.type = 'primary'
              item.color = '#1890FF'
            }
          })
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.order-detail-box {
  .table-content {
  }

  .state1 {
    color: #f38900;
  }

  .state2 {
    color: #ffa085;
  }

  .state3 {
    color: #2389ff;
  }

  .state4 {
    color: #2ac600;
  }

  .detail-drawer {
    li {
      margin-bottom: 8px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
    }
  }

  .logistics-content-box {
    margin-top: 32px;

    // height: 200px;
    .logo-img {
      display: inline-block;
      width: 24px;
      height: 24px;
      vertical-align: middle;
      margin-right: 10px;
    }

    h2 {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;

      span:last-child {
        font-size: 12px;
      }
    }
  }
}
</style>
