<template>
  <el-dialog v-model="visible" title="资料填写" width="70%" :before-close="handleClose">
    <div style=" text-align: center;">
      <el-button type="primary" @click="onSubmit">保存</el-button>
      <el-button @click="backtrack">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref } from 'vue'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
  },
  setup(props, ctx) {
    const form = reactive({
      productTypes: [
        {
          name: '洋酒',
        },
        {
          name: '小吃',
        },
      ],
      tableData: [],
      currentPage: 1,
      pageSize: 10,
      total: 100,
    })

    const visible = computed(() => {
      return props.dialogVisible
    })
    const clickSecondMenu = activeItem => {
      let isActiveItem = form.productTypes.find(item => item.isActive)
      if (isActiveItem) {
        isActiveItem.isActive = false
      }
      if (activeItem !== isActiveItem) {
        activeItem.isActive = true
      }
    }

    const onSubmit = () => {
      ctx.emit('update-dialogVisible', false)
    }
    const backtrack = () => {
      ctx.emit('update-dialogVisible', false)
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', false)
    }
    return {
      form,
      onSubmit,
      handleClose,
      backtrack,
      clickSecondMenu,
      props: { multiple: true },
      visible,
    }
  },
})
</script>
<style lang="scss" scoped>
.product-list-top {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 10px;
  div {
    width: 100px;
    margin-left: 20px;
    text-align: center;
    line-height: 30px;
    border: 1px solid grey;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
