<!--
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-09-14 17:18:11
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/component/layout/sidebar/menu-tree.vue
-->
<template>
  <el-submenu v-if="item.children?.length > 0" :index="item.path" popper-append-to-body>
    <template #title>
      <i v-if="!filterIcon(item.icon)" :class="item.icon"></i>
      <img v-else :src="item.icon" class="img-icon" />
      <span>{{ item.title }}</span>
    </template>
    <menu-tree v-for="child in item.children" :key="child.path" :item="child" />
  </el-submenu>

  <el-menu-item class="test1" v-else :index="item.path" @click="navigateTo(item.path)">
    <!-- <i v-if="!filterIcon(item.icon)" :class="item.icon"></i> -->
    <!-- <img v-else :src="item.icon" class="img-icon" /> -->
    <template #title
      ><span class="title">{{ item.title }}</span></template
    >
  </el-menu-item>
</template>

<script>
export default {
  name: 'MenuTree',
  props: {
    item: {
      type: Object,
      required: true,
    },
  },

  methods: {
    navigateTo(path) {
      // alert(path)
      this.$router.push({ path })
    },
    filterIcon(icon) {
      return icon.indexOf('/') !== -1
    },
  },
}
</script>

<style lang="scss" scoped>
.img-icon {
  width: 16px;
  height: 16px;
  margin-right: 10px;
  margin-left: 5px;
  display: inline-block;
  transform: translateY(21px);
}

.iconfont {
  margin-right: 10px;
  margin-left: 5px;
  color: $sub-menu-title;
  height: $menu-item-height;
}
.title {
  display: inline-block;
  // width: 110px;
  padding-left: 20px;
  width: 150px;
  @include no-wrap();
}

.el-menu--popup {
  .is-active {
    background-color: #ffffff !important;
  }
}
</style>
