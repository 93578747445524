<template>
  <div class="customer-source">
    <!-- 搜索条件 -->
    <el-form :inline="true">
      <el-form-item label="客户来源">
        <el-input placeholder="客户来源" v-model="filterTerm.name" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="selectInfoPage" v-if="authMenus.query">查询</el-button>
        <el-button @click="resetFilter">重置</el-button>
        <el-button @click="addCustomerSource" v-if="authMenus.add">新增</el-button>
        <el-button @click="saveSort" v-if="authMenus.update">保存排序</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格数据 -->
    <el-table :data="tableData">
      <el-table-column label="客户来源" prop="sourceName" align="center"></el-table-column>
      <el-table-column label="客户来源简称" prop="sourceSimple" align="center"></el-table-column>
      <el-table-column show-overflow-tooltip label="绑定支付方式" align="center" prop="payMethodName">
      </el-table-column>
      <el-table-column label="排序" align="center">
        <template #default="scope">
          <el-button @click="moveRow(scope.row, 'up')" v-if="isEnbaleToUp(scope)">上移</el-button>
          <el-button @click="moveRow(scope.row, 'down')" v-if="isEnbaleToDown(scope)">下移</el-button>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <el-button type="text" @click="updateCustomerSource(scope.row)" v-if="authMenus.update">编辑</el-button>
          <el-button type="text" @click="deleteCustomerSource(scope.row)" v-if="authMenus.delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="filterTerm.currentPage"
      v-model:page-size="filterTerm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="filterTerm.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdate
    v-if="dialogVisible"
    :needUpdateInfo="needUpdateInfo"
    @handleCloseDialog="handleCloseOrOpenDialog"
  ></addOrUpdate>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import addOrUpdate from './add-or-update.vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
const authMenus = ref([])
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  selectInfoPage()
})
/** 需要更新的会员卡数据---dialog展示框展示*/
const needUpdateInfo = ref({})
const dialogVisible = ref(false)
/**
 * 关闭or打开dialog展示框
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  selectInfoPage()
}

/**过滤条件 */
const filterTerm = reactive({
  name: '',
  currentPage: 1,
  pageSize: 10,
  total: 0,
})

/**
 * 重制
 */
const resetFilter = () => {
  filterTerm.name = ''
  selectInfoPage()
}

/**
 * 获取表格信息
 */
const selectInfoPage = async () => {
  if (authMenus.value.query) {
    console.log(filterTerm)
    let res = await API.selectInfoPage(filterTerm)
    if (res.code === '200') {
      tableData.value = res.data.records
      filterTerm.total = res.data.total
    } else {
      ElMessage.error(res.msg)
    }
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
const payList = ref({})

//获取支付数据
const getPays = row => {}

/**
 * 新增客户来源
 */
const addCustomerSource = () => {
  needUpdateInfo.value = {
    payMethod: [],
    sort: 0,
  }
  handleCloseOrOpenDialog(true)
}
/**
 * 删除客户来源
 * @param {object} param  客户来源
 */
const deleteCustomerSource = async ({ code }) => {
  ElMessageBox.confirm('确认删除当前客户来源吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.deleteByCode({ code })
    if (res.code === '200') {
      ElMessage.success('删除成功')
    } else {
      ElMessage.error(res.msg)
    }
    selectInfoPage()
  })
}

/**
 * 修改客户来源
 * @param {*} row
 */
const updateCustomerSource = async row => {
  needUpdateInfo.value = {
    ...row,
    payMethod: !row.payMethod ? [] : row.payMethod.split(','),
  }
  handleCloseOrOpenDialog(true)
}

/**
 * 保存排序
 */
const saveSort = async () => {
  tableData.value.forEach((item, index) => {
    item.sort = index
  })
  const res = await API.batchSort(tableData.value)
  if (res.code === '200') {
    ElMessage.success('保存成功')
  } else {
    ElMessage.error(res.msg)
  }
}

/**
 * 表单数据
 */
const tableData = ref([])

/**
 * 是否可以上移
 * @param {*} scope
 */
const isEnbaleToUp = scope => {
  return scope.$index !== 0
}
/**
 * 是否可以下移
 * @param {*} scope
 */
const isEnbaleToDown = scope => {
  return scope.$index !== tableData.value.length - 1
}

/**
 * 上下移动行
 * @param {*} row  当前行数据
 * @param {*} type  类型 up or down
 */
const moveRow = (row, type) => {
  let currentIndex = tableData.value.findIndex(t => t.code === row.code)
  tableData.value.splice(currentIndex, 1)
  if (type === 'up') {
    tableData.value.splice(currentIndex - 1, 0, row)
  } else {
    tableData.value.splice(currentIndex + 1, 0, row)
  }
}

//分页
const handleSizeChange = val => {
  filterTerm.pageSize = val
  selectInfoPage()
}
const handleCurrentChange = val => {
  filterTerm.current = val
  selectInfoPage()
}
</script>

<style scoped lang="scss">
.customer-source {
  padding: 20px 10px;
  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
