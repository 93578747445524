const linCmsUiRouter = {
  route: null,
  name: null,
  title: '运采管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/ycmanager/',
  order: null,
  inNav: true,
  children: [
    {
      title: '商品销售统计',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/ycgl_manager/inventory-manager',
      filePath: 'plugin/ycgl_manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '销售结构分析',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/ycgl_manager/xsjgfx-manager',
      filePath: 'plugin/ycgl_manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '销售结构占比',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/ycgl_manager/xsjgzb-manager',
      filePath: 'plugin/ycgl_manager/view/table/table.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
