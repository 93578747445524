import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {
  //查询门店账号
  async getAccountNumber(data) {
    const res = await get(`/adminChain/order/getCardsStoredRecordPages`, data)
    return res
  }

  //获取门店账号列表
  async getStoreListByName(data) {
    return await get(`/adminChain/store/getStoreListByName`, data)
  }

  //新增账号
  async exportOrderCardStored(data) {
    return await _axios({
      url: '/adminChain/order/exportOrderCardStored',
      method: 'POST',
      data,
      responseType: 'blob',
    })
  }
}

export default new CsMng()
