<template>
  <div class="tableRCIM">
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper"> -->
    <el-table
      :data="tableData"
      stripe
      type="index"
      :height="tableHeight || null"
      :loading="listLoading"
      :cell-class-name="cellClassName"
      :show-summary="showSummary"
      row-key="id"
      @selection-change="handleSelectionChange"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column v-if="checkbox" type="selection" width="55"> </el-table-column>
      <el-table-column v-if="isExpand" type="expand">
        <template #default="{ row }">
          <el-form label-width="80px" label-position="left" inline class="demo-table-expand">
            <template v-for="item in tableHead">
              <el-form-item v-if="!item.formhidden" :key="item[propKey.prop]" :label="item.label">
                <span class="table-cell">{{
                  item.formate ? item.formate(row[item[propKey.prop]]) : row[item[propKey.prop]]
                }}</span>
              </el-form-item>
            </template>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column v-if="showIndex" type="index" width="50"> </el-table-column>
      <template v-for="item in tableHead">
        <el-table-column
          ref="tableColum"
          v-if="!item.hidden"
          :key="item[propKey.prop]"
          :prop="item[propKey.prop]"
          :label="item[propKey.label]"
          :width="item.width"
          :sortable="item.sortable"
          :show-overflow-tooltip="true"
          :align="item.align"
          :fixed="item.fixed"
        >
          <template #default="{ row }">
            <slot v-if="isUseSlot" :data="{ col: item, row }">11</slot>
            <div class="table-cell" v-else>
              <div v-if="item[propKey.prop] === 'tool'">
                <slot name="operate" :data="{ row }"> </slot>
              </div>
              <div v-else-if="slotKeys.includes(item[propKey.prop])">
                <slot :name="item[propKey.prop]" :data="{ row }"> </slot>
              </div>
              <span v-else>{{ row[item[propKey.prop]] || defaultVal }}</span>
            </div>
          </template>
        </el-table-column>
      </template>
      <template v-slot:empty>
        <el-empty description="暂无数据"></el-empty>
      </template>
    </el-table>
    <!-- </el-scrollbar> -->
  </div>
</template>

<script>
export default {
  name: 'Table',
  data() {
    return {
      // row[item[propKey.prop]] | defaultVal,
      indexLabel: '序号',
      Height: '',
    }
  },
  props: {
    checkbox: {
      type: Boolean,
      default: () => false,
    },
    // 表格数据
    tableData: {
      type: Array,
      default: () => [],
    },
    // 表格头数据
    tableHead: {
      type: Array,
      default: [],
    },
    // 表格高度
    tableHeight: {
      type: [String, Number],
      default: () => 'calc(100vh - 220px)',
    },
    // 表头键名替换
    propKey: {
      type: Object,
      default: () => {
        return { prop: 'prop', label: 'label' }
      },
    },
    isExpand: {
      type: Boolean,
      default: true,
    },
    // 是否使用插槽，false则显示默认表格数据
    isUseSlot: {
      type: Boolean,
      default: false,
    },
    // 是否显示序号
    showIndex: {
      type: Boolean,
      default: true,
    },
    listLoading: {
      type: Boolean,
      default: false,
    },
    // 页数
    page: {
      type: Number,
      default: 1,
    },
    // 每页条数
    limit: {
      type: Number,
      default: 10,
    },
    // 需要自定义slot的字段
    slotKeys: {
      type: Array,
      default: () => [],
    },
    // 是否显示表尾合计
    showSummary: {
      type: Boolean,
      default: () => false,
    },
    // 是否显示表尾合计
    lazy: {
      type: Boolean,
      default: () => false,
    },
    // 是否显示表尾合计
    load: {
      type: Function,
      default: () => () => [],
    },
  },
  watch: {
    tableHead(headData) {
      console.log('watch = headData :>> ', headData)
      this.insteadIndex(headData)
    },
    tableData(data) {
      console.log('data :>> ', data)
      // console.log(this.$refs.tableColum[0].offsetHeight)
      // this.Height
    },
    tableHeight() {
      this.Height = this.tableHeight
    },
  },
  created() {
    this.insteadIndex(this.tableHead)
    this.Height = this.tableHeight
    console.log('this.tableHead :>> ', this.tableHead)
  },
  filters: {
    // 过滤空值，若为空值则显示'-'
    defaultVal(value) {
      if (!value && value !== 0) return '-'
      value = value.toString()
      return value
    },
  },
  methods: {
    handleSelectionChange(selection) {
      this.$emit('selectionchange', selection)
    },
    handleEdit(row) {
      this.$emit('handleEdit', row)
    },
    insteadIndex(headData) {
      if (headData.length === 0) return
      if (headData[0][this.propKey.prop] === 'index') {
        this.indexLabel = headData[0][this.propKey.label]
        headData.shift()
        this.tableHead = headData
      }
    },
    cellClassName({ row, column }) {
      return row.cellClass && (column.property === 'sourceNumber' || column.property === 'sourceRealNumber')
        ? row.cellClass
        : ''
    },
  },
}
</script>

<style lang="scss">
.tableRCIM {
  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    height: 30px;
    margin-bottom: 0;
    width: 33%;
  }

  .el-table__expanded-cell[class*='cell'] {
    padding: 15px 20px !important;
  }

  .el-table__expanded-cell:hover {
    background-color: #ffffff !important;
  }

  ::deep {
    .el-form-item {
      margin-bottom: 0px;
    }
  }

  .el-scrollbar {
    height: 100%;
  }

  .el-scrollbar__wrap {
    overflow: scroll;
    overflow-x: auto;
  }

  .el-table th {
    font-weight: 400;
    color: #333;
  }

  .table-cell {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    display: inline-block;
    text-align: left;
  }

  .el-table td,
  .el-table th.is-leaf {
    border-bottom: 1px solid rgb(221, 220, 220);
  }

  .el-table--border th,
  .el-table--border th.gutter:last-of-type {
    border-bottom: 1px solid rgb(221, 220, 220);
  }

  .el-table--border td,
  .el-table--border th {
    border-right: 1px solid rgb(221, 220, 220);
  }
}
</style>
