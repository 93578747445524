// 点击导出会出现小红点指引到下载中心，点击下载中心小红点消失
export const createMoveBall = (event, isShow = true) => {
  //删除之前的div
  const oldDiv = document.querySelector('.down-move-ball')
  if (oldDiv && !isShow) {
    oldDiv.remove()
    return
  } else if (oldDiv) {
    oldDiv.remove()
  }

  let { clientX, clientY } = event
  //创建一个div小球
  const div = document.createElement('div')
  div.style.position = 'fixed'
  div.style.left = `${clientX}px`
  div.style.top = `${clientY}px`
  div.style.width = '10px'
  div.style.height = '10px'
  div.style.borderRadius = '50%'
  div.style.zIndex = '999'
  div.style.backgroundColor = 'red'
  div.className = 'down-move-ball'

  //将div挂载到body上
  document.body.appendChild(div)

  //获取下载中心位置
  const downloadCenter = document.querySelector('.tableSample .main-wrap')
  const downloadCenterRect = downloadCenter.getBoundingClientRect()

  //计算小球移动的距离
  const distanceX = downloadCenterRect.left - event.clientX
  const distanceY = downloadCenterRect.top - event.clientY
  //设置小球移动动画
  div.style.transition = 'all 0.5s ease'
  div.style.transform = `translate(${distanceX}px, ${distanceY}px)`

  // div.addEventListener('transitionend', () => {
  //   //设置小球消失动画
  //   // div.style.opacity = 0
  //   // div.style.transition = 'all 1s ease'
  //   //动画结束后移除小球
  //   // div.remove()
  // })
}
