import _axios, { post, get } from '@/lin/plugin/axios'
import axios from 'axios'

class CsMng {
  //获取门店区域列表
  async getStoreCodeList(data) {
    return await get(`/adminChain/store/saas/getStoreCodeList`, data)
  }
  //获取门店列表-分页
  async getStoreListPages(data) {
    return await post(`/adminChain/store/saas/getStoreListPages`, data)
  }

  //获取门店列表
  async getStoreListByName(data) {
    return await post(`/adminChain/store/saas/getStoreListByName`, data)
  }

  //删除门店信息
  async deleteStoreMsg(data) {
    return await _axios.delete(`/adminChain/store/saas/deleteStoreStore/${data}`)
  }

  //出入库类型
  async selectChannel(data) {
    return await get(`/adminChain/goods/saas/stockChannel/selectChannel`, data)
  }

  //PC商品库存查询
  async selectStockGoodsPc(data) {
    return await post(`/adminChain/goods/saas/stockGoods/selectStockGoodsPc`, data)
  }

  //PC出入库分页查询
  async selectBatchPagePC(data) {
    return await post(`/adminChain/goods/saas/stockGoodsBatch/selectBatchPagePC`, data)
  }

  //PC盘点分页查询
  async selectCheckPagePC(data) {
    return await post(`/adminChain/goods/saas/stockGoodsCheck/selectCheckPagePC`, data)
  }

  //门店
  async getStoreList(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']

    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }

  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  //盘点记录查询
  async queryStockGoodsCheck(data) {
    return await post(`/report/stock/queryStockGoodsCheck`, data)
  }

  //PC盘点明细查询
  async selectCheckItemPagePC(data) {
    return await post(`/adminChain/goods/saas/stockGoodsCheck/selectCheckItemPagePC`, data)
  }

  //PC商品库存明细查询
  async selectStockGoodsPcItem(data) {
    return await get(`/adminChain/goods/saas/stockGoods/selectStockGoodsPcItem`, data)
  }

  //存取酒明细记录
  async getStorageTakeWineRecord(data) {
    return await post(`/adminChain/wine/getStorageTakeWineRecord`, data)
  }
  //存酒实时库存
  async getStorageWineActualTime(data) {
    return await post(`/adminChain/wine/getStorageWineActualTime`, data)
  }

  async exportReport({ url, data }) {
    const res = await _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }
}

export default new CsMng()
