<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-09-27 16:30:48
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/roomIncomeReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportRoomIncomeDetail" reportName="包厢收入明细"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="emptyTableText"
      :getTableData="reportRoomIncomeDetail"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'
export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        roomName: '',
        roomTypeCode: '',
        comboCodeName: '',
        billCode: '',
        openCode: '',
        orderCode: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'roomTypeName',
          label: '包厢类型',
          width: '140px',
        },
        {
          prop: 'roomName',
          label: '包厢名称',
        },
        {
          prop: 'comboCodeName',
          label: '消费项目',
          width: '150px',
        },
        {
          prop: 'billCode',
          label: '账单号',
          width: '200px',
        },
        {
          prop: 'openCode',
          label: '场次号',
          width: '200px',
        },
        {
          prop: 'orderCode',
          label: '订单号',
          width: '200px',
        },
        {
          prop: 'openTime',
          label: '开台时长(分钟)',
          width: '150px',
        },
        {
          prop: 'price',
          label: '应收',
        },
        {
          prop: 'discountAmount',
          label: '优惠',
        },
        {
          prop: 'wipeZeroAmount',
          label: '抹零',
        },
        {
          prop: 'payPrice',
          label: '实收',
        },
        {
          prop: 'netIncomeFee',
          label: '净收',
          width: '140px',
        },
        {
          prop: 'openStartTime',
          label: '开台开始时间',
          width: '180px',
        },
        {
          prop: 'openEndTime',
          label: '开台结束时间',
          width: '180px',
        },
        {
          prop: 'paymentTime',
          label: '结账时间',
          width: '180px',
        },
      ],
      pagination: {},
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '包厢类型',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'roomTypeCode',
          placeholder: '请选择包厢类型',
          options: [],
        },
        { label: '包厢', labelWidth: '50px', type: 'input', prop: 'roomName', placeholder: '请输入包厢' },
        { label: '消费项目', type: 'input', prop: 'comboCodeName', placeholder: '请输入消费项目' },
        { label: '账单号', type: 'input', prop: 'billCode', placeholder: '请输入账单号' },
        { label: '场次号', type: 'input', prop: 'openCode', placeholder: '请输入场次号' },
        { label: '订单号', type: 'input', prop: 'orderCode', placeholder: '请输入订单号' },
        {
          label: '日期',
          labelWidth: '50px',
          type: 'date-picker',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/export/roomIncomeDetail', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportRoomIncomeDetail(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })

      api.getStoreRoomTypeList2().then(res => {
        //包厢类型接口
        this.searchform[2].options = res.data.map(item => {
          return {
            value: item.code,
            label: item.name,
          }
        })
      })
    },
    reportRoomIncomeDetail(model) {
      this.mergeTotalData(model.pageNum, '包厢收入明细', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!model.orderCode && !model.billCode && !model.openCode) {
        if (!this.getDays(model.startTime, model.endTime)) {
          return Promise.reject('条件不满足')
        }
      }
    },
    parentSearch(param) {
      const {
        roomName,
        roomTypeCode,
        storeCodeList,
        billCode,
        openCode,
        orderCode,
        comboCodeName,
        regionCodeList,
      } = param
      this.model = {
        roomName,
        roomTypeCode,
        storeCodeList,
        billCode,
        openCode,
        orderCode,
        comboCodeName,
        regionCodeList,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '包厢收入明细', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const {
        roomName,
        roomTypeCode,
        storeCodeList,
        billCode,
        openCode,
        orderCode,
        comboCodeName,
        regionCodeList,
      } = param
      this.model = {
        roomName,
        roomTypeCode,
        storeCodeList,
        billCode,
        openCode,
        orderCode,
        comboCodeName,
        regionCodeList,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10

      if (!this.model.orderCode && !this.model.billCode && !this.model.openCode) {
        if (!this.getDays(this.model.startTime, this.model.endTime)) {
          this.$refs.search.fullscreenLoading = false
          return
        }
      }
      this.exportData('包厢收入明细', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
