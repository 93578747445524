<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-18 16:31:32
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-06-22 11:04:09
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/bindingStore.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="binding-store-content">
    <GoBack :active-name="activeName" />
    <div class="binding-store-box">
      <div class="bindingStore-left">
        <span class="select-shop">门店选择</span>
        <el-select
          v-model="storeCode"
          filterable
          remote
          reserve-keyword
          placeholder="选择门店"
          clearable
          class="search-select"
          :remote-method="remoteMethod"
          :loading="loading"
          multiple
          collapse-tags
          @change="selectStoreCode"
        >
          <el-option v-for="item in storeList" :key="item.code" :label="item.name" :value="item.code" />
        </el-select>
        <div class="tags-item">
          <el-tag
            v-for="(tag, index) in storeName"
            :key="tag.code"
            class="mx-1"
            closable
            @close="deteleTag(tag, index)"
          >
            {{ tag.name }}
          </el-tag>
        </div>
        <div class="supply-commodity-content">
          <h2>供应商品选择</h2>
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in dataList" :label="item.categoryCodeName" :name="index">
              <p v-if="item.taxState === 1">（含税<span>|</span>税点：{{ item.taxPoint }}）</p>
              <p v-else>（不含税）</p>
              <el-checkbox-group v-model="checkList" @change="checkDrinks">
                <el-checkbox
                  v-for="(childItem, index) in item.goodsMatterList"
                  :label="childItem.skuName"
                  name="type"
                />
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
      <div class="bindingStore-right">
        <h1 class="info-right-title">供应商品确认</h1>
        <div class="supply-goods-list">
          <div class="water-list-content" v-for="item in matterList">
            <h1>{{ item.categoryCodeName }}</h1>
            <el-tag
              v-for="tag in item.checkedItems"
              :key="tag.categoryCode1"
              class="mx-1"
              closable
              :type="tag.type"
              @close="deleteFood(tag)"
            >
              {{ tag.skuName }}
            </el-tag>
          </div>
        </div>
        <el-form :model="form" label-width="90px" label-position="left" class="form-content">
          <el-form-item label="运费">
            <el-col :span="6">
              <el-input v-model="form.freight" />
            </el-col>
            <el-col :span="2" class="form-unit">
              元
            </el-col>
          </el-form-item>
          <el-form-item label="最小起订金额">
            <el-col :span="6">
              <el-input v-model="form.miniPrice" />
            </el-col>
            <el-col :span="2" class="form-unit">
              元
            </el-col>
          </el-form-item>
          <el-form-item label="结算类型">
            <el-select v-model="form.billType" placeholder="请选择结算类型">
              <el-option label="现结" :value="1" />
              <el-option label="月结" :value="2" />
            </el-select>
          </el-form-item>
          <el-button type="primary" @click="onSubmit" class="confirm-edit">确认</el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { ElMessage } from 'element-plus'
import GoBack from '../../../../component/base/backHeader/goBack.vue'
import api from '../../service/index'
export default {
  components: { GoBack },
  data() {
    return {
      tags: [],
      activeName: 0,
      checkList: [],
      storeCode: '',
      form: {
        freight: '',
        miniPrice: '',
        billType: 1,
      },
      storeList: [],
      storeName: [],
      dataList: [],
      matterList: [],
    }
  },
  mounted() {
    this.getUnbindStore()
    this.getMatterListGroupByCategory()
  },
  methods: {
    getUnbindStore() {
      api.getStoreList({ bindType: 2, supplierCode: this.$route.query.id }).then(res => {
        this.storeList = res.data
      })
    },
    selectStoreCode(val) {
      const list = []
      this.storeCode.forEach(item => {
        this.storeList.forEach(elItem => {
          if (elItem.code === item) {
            list.push(elItem)
          }
        })
      })
      this.storeName = list
    },
    deteleTag(tag, index) {
      this.storeName.splice(index, 1)
      this.storeCode.forEach((item, index) => {
        if (tag.code === item) {
          this.storeCode.splice(index, 1)
        }
      })
    },
    getMatterListGroupByCategory() {
      api.getMatterListGroupByCategory({ supplierCode: this.$route.query.id }).then(res => {
        this.dataList = res.data
      })
    },
    checkDrinks() {
      this.matterList = this.dataList.map(category => {
        const checkedItems = category.goodsMatterList.filter(item => this.checkList.includes(item.skuName))
        return {
          categoryCodeName: category.categoryCodeName,
          checkedItems: checkedItems,
        }
      })
    },
    deleteFood(tag) {
      this.matterList.forEach(item => {
        item.checkedItems.forEach((childrenItem, index) => {
          if (childrenItem.skuName === tag.skuName) {
            item.checkedItems.splice(index, 1)
          }
        })
      })
      this.checkList.forEach((item, index) => {
        if (item === tag.skuName) {
          this.checkList.splice(index, 1)
        }
      })
    },
    onSubmit() {
      let relationGoodsAddBOS = []
      this.matterList.forEach(item => {
        item.checkedItems.forEach(childrenItem => {
          relationGoodsAddBOS.push(childrenItem)
        })
      })
      let relationStoreAddBOS = []
      this.storeName.forEach(item => {
        relationStoreAddBOS.push({ storeCode: item.code, storeName: item.name })
      })
      let param = {
        billType: this.form.billType,
        freight: this.form.freight,
        miniPrice: this.form.miniPrice,
        relationGoodsAddBOS: relationGoodsAddBOS,
        relationStoreAddBOS: relationStoreAddBOS,
        supplierCode: this.$route.query.id,
        supplierName: this.$route.query.name,
      }
      api.batchBindStoreList(param).then(res => {
        if (res.code === '200') {
          ElMessage({
            type: 'success',
            message: `绑定成功`,
          })
          this.$router.go(-1)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.binding-store-content {
  .binding-store-box {
    width: 1568px;
    height: 850px;
    max-height: 850px;
    overflow: auto;
    background: #ffffff;
    border-radius: 8px;
    margin: auto;
    display: flex;
    clear: both;

    .bindingStore-left {
      width: 50%;
      padding-left: 40px;
      padding-top: 30px;
      position: relative;

      .select-shop {
        margin-right: 20px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }

      .tags-item {
        margin-top: 20px;
        width: 380px;
        height: 280px;
        max-height: 280px;
        overflow: auto;

        .mx-1 {
          margin-left: 12px;
          margin-bottom: 12px;
        }
      }

      .supply-commodity-content {
        width: 700px;
        position: absolute;
        bottom: 100px;

        h2 {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          margin-bottom: 32px;
          color: #222222;
        }

        .demo-tabs {
          min-height: 300px;

          p:first-child {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fb5d5d;
            height: 36px;
            line-height: 20px;

            span {
              display: inline-block;
              width: 1px;
              margin-left: 10px;
              margin-right: 10px;
              color: #979595;
            }
          }

          .el-checkbox-group {
            line-height: 26px;
          }
        }
      }
    }

    .bindingStore-right {
      flex: 1;
      padding-right: 40px;
      display: flex;
      flex-direction: column;

      .supply-goods-list {
        height: 500px;
        max-height: 500px;
        overflow: auto;
      }

      .form-content {
        padding-top: 24px;
        border-top: 1px solid #ebeef5;

        ::v-deep .el-form-item {
          margin-bottom: 0px !important;
        }

        .form-unit {
          padding-left: 20px;
        }

        .confirm-edit {
          width: 121px;
          float: right;
        }
      }

      .info-right-title {
        margin-top: 40px;
        margin-bottom: 24px;
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        position: relative;

        .close-icon {
          position: absolute;
          right: 0px;
          top: -20px;
          cursor: pointer;
        }
      }

      .water-list-content {
        height: 150px;

        h1:first-child {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
          height: 42px;
          line-height: 42px;
        }

        ::v-deep .el-tag {
          margin-right: 10px !important;
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
