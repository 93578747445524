<!--
 * @Author: your name
 * @Date: 2022-04-18 15:47:45
 * @LastEditTime: 2022-11-25 12:23:19
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/room-box-manger/index.vue
-->
<template>
  <div class="shop-manager-box">
    <div class="shop-manager-search">
      <span
        >门店选择
        <el-select
          v-model="storeCode"
          filterable
          remote
          reserve-keyword
          placeholder="选择门店"
          clearable
          @change="selectStoreCode"
          style="margin-left: 20px;"
          :remote-method="remoteMethod"
          :loading="loading"
        >
          <el-option v-for="item in storeList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </span>
      <span>
        <ul>
          <li>共{{ roomCount }}个包厢</li>
          <li>开台{{ openedCount }}个</li>
          <li>未结{{ unPayCount }}个</li>
          <li>空闲{{ freeCount }}个</li>
        </ul>
        <el-button type="primary" @click="openModals" v-if="authMenus.add">新增包厢</el-button>
      </span>
    </div>
    <div class="bxroomcotanier">
      <div class="shop-box-content" v-for="item in storeRoomTypeCategoryVOList">
        <div class="box-size">{{ item.roomTypeName }}（{{ item.count }}）</div>
        <div class="box-list">
          <el-row :gutter="20">
            <div class="row-flex-box" v-for="childItem in item.storeRoomVOList">
              <div class="grid-content bg-purple">
                <div :style="filterColor(childItem.roomState)" class="box-title">
                  <span>{{ childItem.name }}</span>
                  <span>
                    <el-popover
                      placement="bottom"
                      :width="142"
                      trigger="hover"
                      v-if="authMenus.query"
                      @show="showCode(childItem)"
                    >
                      <template #reference>
                        <img src="../../assets/image/QR-code.png" alt="" />
                      </template>
                      <div class="codeUrl-content" style="text-align: center;">
                        <el-image style="width: 100px; height: 100px;margin: auto;" :src="codeUrl" :fit="voer" />
                      </div>
                    </el-popover>
                  </span>
                </div>
                <div class="box-content">
                  <p v-if="childItem.roomState === 4">
                    {{ childItem.roomTypeName }}({{ roomStateList[childItem.roomState] }})
                  </p>
                  <p v-else>{{ roomStateList[childItem.roomState] }}</p>
                  <p v-if="[7, 8, 9, 10].includes(childItem.roomState)">
                    {{ childItem.content.startTime && childItem.content.startTime.slice(0, 5) }}-{{
                      childItem.content.endTime && childItem.content.endTime.slice(0, 5)
                    }}（{{ childItem.content.remindTime }}”）
                  </p>
                  <p v-if="[7, 8, 9, 10].includes(childItem.roomState)">消费：{{ childItem.content.totalPrice }}</p>
                  <img
                    src="../../assets/image/edit.png"
                    style="cursor: pointer;"
                    alt=""
                    v-if="authMenus.update"
                    @click="editRoom(childItem)"
                  />
                </div>
              </div>
            </div>
          </el-row>
        </div>
      </div>
    </div>

    <el-drawer
      v-model="editModal"
      :title="modalTitle"
      :direction="direction"
      :before-close="handleClose"
      size="35%"
      :with-header="false"
    >
      <h1 style="width: 90%;margin: auto;">
        {{ modalTitle
        }}<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="editModal = false">
          <close />
        </el-icon>
      </h1>
      <div class="info-line"></div>
      <div class="add-form-box">
        <el-form label-width="80px" :rules="rules" ref="ruleFormRef" :model="boxForm">
          <el-form-item label="包厢类型" prop="roomTypeCode">
            <el-select v-model="boxForm.roomTypeCode" placeholder="请选择">
              <el-option v-for="item in roomTypeList" :label="item.name" :value="item.code" />
            </el-select>
          </el-form-item>
          <el-form-item label="包厢名称" prop="roomName">
            <el-input v-model="boxForm.roomName" />
          </el-form-item>
          <el-form-item label="MAC地址" prop="roomMacKey">
            <el-input v-model="boxForm.roomMacKey" />
          </el-form-item>
          <el-button type="submit" @click="addRoom" class="confirm-btn">确认</el-button>
        </el-form>
      </div>
    </el-drawer>
    <ExportDialog :dialogVisible="exportDialog" />
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage, ElMessageBox } from 'element-plus'
import ExportDialog from '../../../../component/base/exportDialog/export-dialog.vue'
import { roomStateList } from '../../../../constants/config'
import api from '../../service/index'
const ROOM_STATUS = {
  FREE: 1, //空闲
  ERROR: 2, //故障;
  // WCLIENT:3,//带客
  CLEAN: 4, //清洁
  ENGAGED: 5, //预定
  LEAFROOM: 6, //留房
  OPENEDWJ: 7, //已开(未结)
  CLOSEROOM: 8, //关台(未结)
  OPENROOMYJ: 9, //已开(已结)
  DAIKE: 10, //带客
}
export default {
  components: { ExportDialog },
  data() {
    return {
      authMenus: {},
      options: [],
      editModal: false,
      boxForm: {
        roomName: '',
        roomMacKey: '',
        roomTypeCode: '',
      },
      modalTitle: '创建新包厢',
      exportDialog: false,
      storeList: '',
      storeCode: 'K0000',
      unPayCount: '',
      freeCount: '',
      openedCount: '',
      storeRoomTypeCategoryVOList: [],
      roomStateList: roomStateList,
      roomCount: '',
      codeUrl: '',
      roomTypeList: [],
      rules: {
        roomTypeCode: [{ required: true, message: '请选择包厢类型', trigger: 'blur' }],
        roomMacKey: [{ required: true, message: '请输入MAC地址', trigger: 'blur' }],
        roomName: [{ required: true, message: '请输入包厢名称', trigger: 'blur' }],
      },
      storeRoomOperateBO: {},
      roomTypeName: '',
      isEdit: false,
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      this.getStoreRoomHomePage()
      this.getRoomType()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    filterColor(status) {
      switch (status) {
        case ROOM_STATUS.FREE:
          return 'background:#32C5A9'
        case ROOM_STATUS.ERROR:
          return 'background:#DF902A'
        case ROOM_STATUS.WCLIENT:
          return 'background:#4E77E4'
        case ROOM_STATUS.CLEAN:
          return 'background:#33CDEA'
        case ROOM_STATUS.ENGAGED:
          return 'background:#32C5A9'
        case ROOM_STATUS.LEAFROOM:
          return 'background:#32C5A9'
        case ROOM_STATUS.OPENEDWJ:
          return 'background:#C9492A'
        case ROOM_STATUS.CLOSEROOM:
          return 'background:#C9492A'
        case ROOM_STATUS.OPENROOMYJ:
          return 'background:#4E77E4'
        case ROOM_STATUS.DAIKE:
          return 'background:#4E77E4'
      }
    },
    getStoreList() {
      api.getStoreListByName().then(res => {
        this.storeList = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
    },
    selectStoreCode() {
      if (this.authMenus.query) {
        this.getStoreRoomHomePage()
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    getStoreRoomHomePage() {
      api.getStoreRoomHomePage({ storeCode: this.storeCode }).then(res => {
        this.unPayCount = res.data.unPayCount
        this.freeCount = res.data.freeCount
        this.openedCount = res.data.openedCount
        this.roomCount = res.data.roomCount
        this.storeRoomTypeCategoryVOList = res.data.storeRoomTypeCategoryVOList
      })
    },
    addBox() {
      this.editModal = true
    },
    handleClose() {
      this.editModal = false
    },
    addRoom() {
      this.$refs['ruleFormRef'].validate(valid => {
        if (valid) {
          let obj = _.find(this.roomTypeList, { code: this.boxForm.roomTypeCode })
          this.roomTypeName = obj.name
          let level = obj.level
          let param = {
            level: level,
            roomCode: this.storeRoomOperateBO.code,
            roomMacKey: this.boxForm.roomMacKey,
            roomName: this.boxForm.roomName,
            roomTypeCode: this.boxForm.roomTypeCode,
            roomTypeName: this.roomTypeName,
            storeCode: this.storeCode,
          }
          if (this.isEdit) {
            api.updateStoreRoom(param).then(res => {
              this.handleClose()
              this.getStoreRoomHomePage()
              this.$refs['ruleFormRef'].resetFields()
            })
          } else {
            api.addStoreRoom(param).then(res => {
              this.handleClose()
              this.getStoreRoomHomePage()
              this.$refs['ruleFormRef'].resetFields()
            })
          }
        } else {
          console.log('error submit!')
          return false
        }
      })
    },
    showCode(item) {
      api.getWechatOfficialAccountQrCode({ roomMacKey: item.roomMacKey }).then(res => {
        this.codeUrl = res.data
      })
    },
    downloadByBlob(url, name) {
      let image = new Image()
      image.setAttribute('crossOrigin', 'Anonymous')
      // image.crossOrigin = "Anonymous";
      image.src = url
      image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob(blob => {
          let url = URL.createObjectURL(blob)
          download(url, name)
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    saveCode(qrId) {
      this.downloadByBlob(this.codeUrl, '二维码')
    },
    getRoomType() {
      api.getStoreRoomTypeList({ storeCode: this.storeCode }).then(res => {
        this.roomTypeList = res.data
      })
    },
    editRoom(item) {
      console.log('item :>> ', item)
      this.isEdit = true
      this.editModal = true
      this.modalTitle = '编辑包厢'
      this.storeRoomOperateBO = item
      this.boxForm.roomTypeCode = item.roomTypeCode
      this.boxForm.roomName = item.name
      this.boxForm.roomMacKey = item.roomMacKey
    },
    openModals() {
      this.editModal = true
      this.isEdit = false
    },
  },
}
</script>
<style lang="scss" scoped>
.shop-manager-box {
  background-color: #f8faff;
  box-sizing: border-box;
  height: calc(100vh - 150px);
  padding-right: 40px;
  padding-left: 40px;
  overflow: hidden;
  ::v-deep .el-main {
    overflow: hidden !important;
  }
  .bxroomcotanier {
    height: calc(100vh - 200px);
    overflow: auto;
  }

  h1 {
    width: 100%;
    height: 73px;
    line-height: 73px;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
    position: relative;

    .close-icon {
      position: absolute;
      top: 20px;
      right: 0px;
      cursor: pointer;
    }
  }

  .info-line {
    width: 90%;
    height: 1px;
    background-color: #ebeef5;
    margin: auto;
    margin-bottom: 25px;
  }

  .add-form-box {
    width: 60%;
    padding-left: 20px;

    .confirm-btn {
      position: absolute;
      bottom: 24px;
      right: 40px;
      width: 121px;
      height: 40px;
      background: #1989fa;
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .shop-manager-search {
    padding: 26px 0px 16px 0px;
    border-bottom: 1px solid #ebeef5;
    display: flex;

    span {
      flex: 1;
    }

    span:first-child {
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;

      .select-shop {
        margin-left: 12px;
      }
    }

    span:last-child {
      text-align: right;

      ul {
        display: inline-block;
        height: 30px;
        margin-top: 14px;
        margin-right: 40px;

        li {
          display: inline-block;
          font-size: 16px;
          border-right: 1px solid #c0c4cc;
          padding-right: 10px;
          padding-left: 10px;
        }

        li:first-child {
          color: #c0c4cc;
        }

        li:nth-child(2) {
          color: #1989fa;
        }

        li:nth-child(3) {
          color: #f66c6a;
        }

        li:last-child {
          color: #32c5a9;
          border-right: none;
          padding-right: 0px;
        }
      }
    }
  }

  .shop-box-content {
    .box-size {
      height: 57px;
      line-height: 57px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #303133;
    }

    .box-list {
      .row-flex-box {
        width: 200px;
        min-width: 200px;
        padding: 12px;
        height: 140px;

        .grid-content {
          border-radius: 16px;
          height: 140px;

          .box-title {
            height: 40px;
            line-height: 40px;
            border-radius: 16px 16px 0px 0px;
            box-sizing: border-box;
            padding: 0px 16px 0px 16px;
            display: flex;

            .codeUrl-content {
              text-align: center !important;
            }

            span:first-child {
              font-size: 20px;
              font-family: D-DIN-Bold, D-DIN;
              font-weight: bold;
              color: #ffffff;
            }

            span {
              flex: 1;
            }

            span:last-child {
              position: relative;

              .code-text {
                display: block;
                font-size: 16px;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #007aff;
              }

              img {
                width: 24px;
                height: 24px;
                position: absolute;
                right: 0;
                top: 8px;
              }
            }
          }

          .free-box {
            background: #32c5a9;
          }

          .inuse-box {
            background-color: #c9492a;
          }

          .finish-box {
            background-color: #4e77e4;
          }

          .clean-box {
            background-color: #32c5ff;
          }

          .error-box {
            background-color: #df902a;
          }

          .box-content {
            box-sizing: border-box;
            height: 100%;
            padding-left: 16px;
            padding-top: 16px;

            p {
              font-size: 16px;
              height: 26px;
              width: 100%;
              line-height: 26px;
            }

            p:first-child {
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #262626;
            }

            p:nth-child(2) {
              font-size: 16px;
              font-family: D-DIN-Bold, D-DIN;
              font-weight: bold;
              color: #222222;
            }
          }
        }
      }

      .el-row {
        margin-bottom: 20px;
      }

      .el-row:last-child {
        margin-bottom: 0;
      }

      .el-col {
        border-radius: 16px;
        height: 140px;
      }

      .bg-purple-dark {
        background: #99a9bf;
      }

      .bg-purple {
        background: #ffffff;
        position: relative;

        img {
          position: absolute;
          bottom: 16px;
          right: 16px;
          width: 24px;
          height: 24px;
        }
      }

      .add-box {
        width: 100%;
        height: 100%;

        div {
          position: relative;

          .add-icon {
            margin: 40px 75px 10px 75px;
          }

          p {
            font-size: 14px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #c0c4cc;
            text-align: center;
          }
        }
      }

      .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
      }
    }
  }
}
</style>
