<template>
  <div class="supplier-management-box">
    <SearchBar :authMenus="authMenus" :getTableData="getTableData" searchTypes="supplier-index" />
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap">
      <el-table-column prop="code" label="供应商ID" width="180" />
      <el-table-column prop="supplierName" label="供应商名称" width="180" />
      <el-table-column prop="contacts" label="联系人" />
      <el-table-column prop="cellphone" label="手机号" />
      <el-table-column
        prop="isCompliance"
        label="是否合规"
        :filters="isComplianceList"
        :filter-method="filterIscompliance"
      >
        <template #default="scope">
          <div>
            {{ scope.row.isCompliance === 0 ? '不合规' : '合规' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="状态" :filters="stateList" :filter-method="filterState">
        <template #default="scope">
          <div :class="scope.row.state === 10 ? 'start-using' : ''">
            {{ scope.row.state === 0 ? '禁用' : '启用' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="relationCategoryListStr" label="绑定品类" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)" v-if="authMenus.query">详情</el-button>
          <el-button
            type="text"
            class="delete-style"
            @click="deleteItem(scope.$index, scope.row)"
            v-if="authMenus.delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import SearchBar from '../../component/tableSearchTab.vue'
import Pagination from '../../component/pagination.vue'
import { ElMessage } from 'element-plus'
import api from '../../service/index'
export default {
  components: { SearchBar, Pagination },
  data() {
    return {
      authMenus: {},
      searchDate: '',
      options: [],
      tableData: [],
      input2: '',
      background: false,
      infoModal: false, //详情Modal，
      extendModal: false,
      extendDrawer: false,
      totalRecords: null,
      totalPage: null,
      model: {
        pageNum: 1,
        pageSize: 10,
        supplierName: '',
        categoryCode1: '',
      },
      isComplianceList: [
        { text: '不合规', value: 0 },
        { text: '合规', value: 1 },
      ],
      stateList: [
        { text: '禁用', value: 0 },
        { text: '启用', value: 10 },
      ],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    this.getTableData(this.model)
  },
  methods: {
    getTableData(model) {
      api.getSupplierListWithCategoryPages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    search() {
      console.log(1)
    },
    showDetail(index, row) {
      localStorage.setItem('activeName', '基本资料')
      this.$router.push({
        path: '/supplyChain-manager/supplierManagement/infoDetail',
        query: {
          name: row.supplierName,
          id: row.code,
        },
      })
    },
    deleteItem(index, row) {
      api.deleteSupplier(row.code).then(res => {
        ElMessage.success('删除供应商成功')
        this.getTableData(this.model)
      })
    },
    filterIscompliance(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    filterState(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    handleClose() {
      this.infoModal = false
    },
    closeExtendModal() {
      this.extendModal = false
    },
    openExtendModal() {
      this.extendModal = true
    },
  },
}
</script>
<style lang="scss" scoped>
.supplier-management-box {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .start-using {
    color: #61c322;
  }

  .delete-style {
    color: #f56c6a;
  }

  .isConfirm-extend {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #606266;
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .shop-order-search {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 41px;

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }
  }

  .order-detail-item {
    width: 588px;
    margin: auto;

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .vip-level-img {
        width: 40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 32px;
      }

      .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
      }
    }
  }
}
</style>
