<template>
  <el-dialog v-model="visible" title="结算信息配置" width="50%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <!-- <el-row :gutter="20" class="endMsgTitle">
      <el-col :span="4"><span>账户类型</span></el-col>
      <el-col :span="5"><span>账户名称</span></el-col>
      <el-col :span="5"><span>开户行</span></el-col>
      <el-col :span="5"><span>账号</span></el-col>
      <el-col :span="4"></el-col>
    </el-row>
    <el-row v-for="(item, index) in accountMsg.list" :key="index" :gutter="20">
      <el-col :span="4">
        <el-select v-model="item.type">
          <el-option label="公户" :value="2" />
          <el-option label="私户" :value="1" /> </el-select
      ></el-col>
      <el-col :span="5">
        <el-input v-model="item.name" />
      </el-col>
      <el-col :span="5">
        <el-input v-model="item.bank" />
      </el-col>
      <el-col :span="5">
        <el-input v-model="item.account" />
      </el-col>
      <el-col :span="5">
        <el-button type="primary" @click="addRow" :disabled="!isEdit"
          ><el-icon><Plus /></el-icon
        ></el-button>
        <el-button type="primary" @click="deleteRow(index)" v-if="index !== 0"
          ><el-icon><Minus /></el-icon
        ></el-button>
      </el-col>
    </el-row> -->

    <span>
      <font style="color: black; font-size: 13px; font-weight: 500;">收银信息</font>
      <font style="color: red;">(务必填写并核实正确，错误导致无法收款)</font>
    </span>
    <el-form :model="accountMsg" label-width="120px" :rules="rules" ref="formBottom">
      <el-form-item label="收款通道">
        <span v-if="accountMsg.channel == 2">收钱吧</span>
        <span v-else>扫呗</span>
      </el-form-item>
      <el-form-item label="商户名称">
        <el-input
          v-model="accountMsg.storeName"
          style="width: 74%"
          :maxlength="20"
          placeholder="请填写商户名称"
          :disabled="accountMsg.channel == 2"
        />
      </el-form-item>
      <el-form-item label="权益金比例" prop="equityAmountProportion">
        <el-input style="width: 74%" v-model="accountMsg.equityAmountProportion" placeholder="权益金比例" disabled>
          <template #suffix>%</template>
        </el-input>
      </el-form-item>
      <el-form-item label="商户号" prop="merchantNo">
        <el-input
          style="width: 74%"
          v-model="accountMsg.merchantNo"
          placeholder="请填写商户号"
          :disabled="accountMsg.channel == 2"
        />
        <div v-for="(item,index) in accountMsg.spareMerchantNos" :key="item" style="display: flex;margin-top: 10px">
          <p style="width: 50%;">{{ item }}</p>
          <el-button @click="changeMerchantNo(item,index)">切换</el-button>
        </div>
      </el-form-item>
      <el-form-item label="pos商户号" prop="posMerchantNo" v-if="accountMsg.channel == 2">
        <el-input
          style="width: 74%"
          v-model="accountMsg.posMerchantNo"
          placeholder="请填写商户号"
          :disabled="accountMsg.channel == 2"
        />
      </el-form-item>
      <el-form-item label="pos唯一识别码" prop="posDeviceId" v-if="accountMsg.channel == 2">
        <el-input
          style="width: 74%"
          v-model="accountMsg.posDeviceId"
          placeholder="请填写商户号"
          :maxlength="31"
          :disabled="accountMsg.channel == 2"
        />
      </el-form-item>
      <el-form-item label="商户密钥" prop="accessToken" v-if="accountMsg.channel == 1">
        <el-input v-model="accountMsg.accessToken" style="width: 74%" placeholder="请填写商户密钥" :maxlength="63" />
      </el-form-item>
      <el-form-item label="终端号" prop="terminalId" v-if="accountMsg.channel == 1">
        <el-input style="width: 74%" v-model="accountMsg.terminalId" placeholder="请填写终端号" :maxlength="15" />
      </el-form-item>
      <el-form-item label="小程序APPID" v-if="accountMsg.channel == 1">
        <el-input style="width: 74%" v-model="accountMsg.appId" placeholder="小程序APPID" disabled />
      </el-form-item>
    </el-form>
    <div style="text-align: center;">
      <el-button type="primary" @click="onSubmit" style="width: 100px;" v-if="accountMsg.channel == 1">
        确定
      </el-button>
      <el-button @click="handleClose" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ref, reactive, defineComponent, computed, toRaw } from 'vue'
import API from '../../../service/api'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    accountMsg: Object,
  },
  setup(props, ctx) {
    const formBottom = ref(null)

    const visible = computed(() => {
      return props.dialogVisible
    })

    const checkEquityAmountProportion = (rule, value, callback) => {
      console.log(value)

      if (!/^\d+(\.\d+)?$/.test(value) || value < 0 || value > 100) {
        return callback(new Error('权益金比例支持输入0～100的数字，支持小数点'))
      } else {
        callback()
      }
    }

    const rules = reactive({
      merchantNo: [{ required: true, message: '请填写商户号', trigger: 'change' }],
      accessToken: [{ required: true, message: '请填写商户密钥', trigger: 'change' }],
      terminalId: [{ required: true, message: '请填写终端号', trigger: 'change' }],

      equityAmountProportion: [
        {
          required: false,
          trigger: 'change',
          validator: checkEquityAmountProportion,
        },
      ],
    })

    const onSubmit = _.throttle(() => {
      validFormBottom(formBottom)
    }, 2000)

    //校验下半部分
    const validFormBottom = async formBottom => {
      if (!formBottom) return
      await formBottom.value.validate((valid, fields) => {
        if (valid) {
          sendData()
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const sendData = () => {
      let params = {
        ...toRaw(props.accountMsg),
      }
      if (!params.isAdd) {
        if (params.channel == 1) {
          update(params)
        }
      } else {
        add(params)
      }
    }

    const add = data => {
      API.addStorePayPreference(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('添加成功')
          ctx.emit('update-dialogVisible', { update: false, search: false })
          resetForm()
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const update = data => {
      API.updateStorePayPreference(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: false })
          resetForm()
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const resetForm = () => {
      formBottom.value.resetFields()
    }

    const handleClose = () => {
      resetForm()
      ctx.emit('update-dialogVisible', { update: false })
    }
    const addRow = () => {
      props.accountMsg.list.push({ accountType: '1' })
    }
    const deleteRow = index => {
      if (props.accountMsg.list.length === 1) {
        return
      } else {
        props.accountMsg.list.splice(index, 1)
      }
    }

    const changeMerchantNo = async (data,index) => {
      let res = await API.changeMerchantNo({
        storeCode: props.accountMsg.storeCode,
        sourceMerchantNo: props.accountMsg.merchantNo,
        targetMerchantNo: data,
      })
      if (res.code == 200) {
        props.accountMsg.spareMerchantNos[index] = props.accountMsg.merchantNo
        props.accountMsg.merchantNo = data
        ElMessage.success('切换成功')
      } else {
        ElMessage.error(res.msg)
      }
    }
    return {
      onSubmit,
      handleClose,
      formBottom,
      visible,
      addRow,
      deleteRow,
      rules,
      changeMerchantNo,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
.el-row {
  margin-bottom: 20px;
  width: 96%;
  margin-left: 7% !important;
}
.endMsgTitle {
  text-align: center;
  span {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }
}
</style>
