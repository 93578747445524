<template>
  <el-dialog v-model="visible" title="新增供应商" width="70%" :before-close="handleClose">
    <el-form :inline="true" :model="form" style="margin-left: 20px;">
      <el-form-item label="门店编号">
        <el-input placeholder="请输入门店编号" v-model="form.storeCode" @change="queryShops"></el-input>
      </el-form-item>
      <el-form-item label="门店名称">
        <el-input
          placeholder="请输入门店名称"
          v-model="form.storeName"
          class="input-search-tel"
          @change="queryShops"
        ></el-input>
      </el-form-item>
      <el-form-item label="门店区域">
        <el-cascader
          v-model="form.geoCode"
          :options="form.shopLists"
          :props="form.optionsProps"
          :show-all-levels="false"
          filterable
          clearable
          style="width: 300px"
          placeholder="选择门店区域"
          @change="queryShops"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
      <el-table
        :data="form.skuListsSelect"
        style="width: 96%; margin-left: 2%;"
        class="table-content"
        :highlight-current-row="true"
        v-loading="form.loading"
        element-loading-text="正在火速加载数据..."
      >
        <el-table-column align="center" width="50">
          <template #header>
            <el-checkbox v-model="form.checkAll" @change="handleCheckAllChange"></el-checkbox>
          </template>
          <template #default="{row}">
            <el-checkbox v-model="row.checked" />
          </template>
        </el-table-column>
        <el-table-column label="序号" align="center" type="index" :index="indexfn" />

        <el-table-column prop="code" label="门店编号" align="center" />
        <el-table-column prop="name" label="门店名称" align="center" />
        <el-table-column prop="storeShortName" label="门店简介" align="center" width="100" />
        <el-table-column prop="characterType" label="门店类型" width="100" align="center">
          <template #default="scope">
            <span v-if="scope.row.characterType === 1">加盟</span>
            <span v-else-if="scope.row.characterType === 2">直营</span>
            <span v-else>直营（合作）</span>
          </template>
        </el-table-column>
        <el-table-column label="门店区域" align="center" width="300">
          <template #default="scope">
            <span
              style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer;"
              :title="scope.row.storeAreaName"
            >
              {{ scope.row.storeAreaName }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="门店地址" width="200" align="center">
          <template #default="scope">
            <span
              style=" overflow: hidden; text-overflow: ellipsis; white-space: nowrap; cursor: pointer;"
              :title="scope.row.address"
            >
              {{ scope.row.address }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="操作" width="180" align="center">
          <template #default="scope">
            <el-button link type="text" size="small" @click="showDetail(1, scope.row)">移除供货</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-pagination
      v-model:current-page="form.currentPage"
      v-model:page-size="form.pageSize"
      :small="small"
      :page-sizes="[5, 10, 20]"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="form.total"
    />
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/api.js'
import { reactive, defineComponent, computed, ref, onMounted, toRaw, watchEffect, inject } from 'vue'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    code: String,
  },
  setup(props, ctx) {
    const form = reactive({
      storeCode: '',
      storeName: '',
      geoCode: [],
      shopLists: [],
      currentPage: 1,
      pageSize: 5,
      total: 100,
      loading: false,
      optionsProps: {
        label: 'name',
        children: 'child',
        value: 'name',
        checkStrictly: true,
      },
      skuLists: [],
      skuListsSelect: [],
      checkAll: false,
    })
    onMounted(() => {
      //获取门店区域
      API.getCheckArea({ isOnlyEnable: 1 }).then(res => {
        if (res.code === '200') {
          form.shopLists = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
      queryShops()
    })

    watchEffect(() => {
      form.checkAll = false
      let start = (form.currentPage - 1) * form.pageSize
      let end = form.currentPage * form.pageSize

      let d = form.skuLists.filter(
        item =>
          item.code.includes(form.storeCode) &&
          item.name.includes(form.storeName) &&
          item.storeAreaName.includes(form.geoCode[form.geoCode.length - 1] || ''),
      )

      form.total = d.length
      let newArr = d.slice(start, end)
      form.skuListsSelect = [...newArr]
    })
    //重置
    const resetQuery = () => {
      form.code = undefined
      form.name = undefined
      form.geoCode = undefined
      queryShops()
    }
    const indexfn = computed(() => {
      return (form.currentPage - 1) * form.pageSize + 1
    })
    const visible = computed(() => {
      return props.dialogVisible
    })
    const handleCheckAllChange = () => {
      form.skuListsSelect.forEach(element => {
        element.checked = form.checkAll
      })
    }
    const clickSecondMenu = activeItem => {
      let isActiveItem = form.productTypes.find(item => item.isActive)
      if (isActiveItem) {
        isActiveItem.isActive = false
      }
      if (activeItem !== isActiveItem) {
        activeItem.isActive = true
      }
    }
    // 获取门店列表数据
    const searchLists = () => {
      form.loading = true
      let query = {
        code: form.code,
        // name: form.name,
      }
      if (Array.isArray(toRaw(form.geoCode))) {
        query.geoCode = form.geoCode[form.geoCode.length - 1]
      }

      API.getStoreList(query).then(res => {
        if (res.code === '200') {
          form.skuLists = res.data.map(item => ({ ...item, checked: false }))
          form.total = res.data.length
          form.loading = false
        } else {
          ElMessage.error(res.msg)
          form.loading = false
        }
      })
    }
    const queryShops = () => {
      form.currentPage = 1
      searchLists()
    }

    const onSubmit = _.throttle(
      () => {
        let params = form.skuLists
          .filter(item => item.checked)
          .map(item => {
            return {
              supplierCode: props.code,
              storeCode: item.code,
              storeName: item.name,
            }
          })

        if (params.length == 0) {
          return
        }

        API.saveBingSupplierStore(params).then(res => {
          if (res?.code == 200) {
            ctx.emit('update-dialogVisible', { update: false, search: true })
          }
        })
      },
      2000,
      { trailing: false },
    )
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false, search: true })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false, search: true })
    }
    return {
      form,
      onSubmit,
      handleClose,
      backtrack,
      clickSecondMenu,
      props: { multiple: true },
      visible,
      indexfn,
      onSubmit,
      handleCheckAllChange,
    }
  },
})
</script>
<style lang="scss" scoped>
.product-list-top {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 10px;
  div {
    width: 100px;
    margin-left: 20px;
    text-align: center;
    line-height: 30px;
    border: 1px solid grey;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
.active {
  background-color: grey;
}
.article-title {
  width: 200px;
  span {
    font-size: 15px;
    font-weight: 500;
  }
}
</style>
