<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-08-02 11:06:24
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-14 16:18:02
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/purchase-details/component/searchTab.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-form
    :inline="true"
    :model="formInline"
    class="demo-form-inline"
    label-width="110px"
    label-position="left"
    ref="formEl"
    v-if="tabs === 'first'"
  >
    <el-row>
      <el-col :span="8">
        <el-form-item label="订单生成时间">
          <el-date-picker
            v-model="formInline.region"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :size="size"
          />
        </el-form-item>
        <el-form-item label="收货时间">
          <el-date-picker
            v-model="formInline.recive"
            type="daterange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            :size="size"
          />
        </el-form-item>
      </el-col>
      <el-col :span="16" class="form-right-style">
        <el-form-item label="城市选择">
          <el-cascader
            v-model="formInline.storeCodeList"
            style="width:224px"
            class="select-input"
            :options="areaList"
            :props="props"
            collapse-tags
            :show-all-levels="false"
            placeholder="城市选择"
          />
        </el-form-item>
        <el-form-item label="供应商选择" class="mr-40">
          <el-select
            v-model="formInline.supplierCode"
            style="width:224px !important"
            class="select-input"
            placeholder="供应商选择"
            filterable
          >
            <el-option v-for="item in supplierList" :label="item.supplierName" :value="item.code" />
          </el-select>
        </el-form-item>
        <div>
          <el-form-item class="mr-30">
            <el-button type="primary" @click="onSubmit" :disabled="!authMenus.query">查询</el-button>
          </el-form-item>
          <el-form-item class="mr-30">
            <el-button @click="onReset">重置</el-button>
          </el-form-item>
          <el-form-item class="mr-30">
            <el-button type="primary" @click="exportExcel" v-if="authMenus.export" :disabled="!authMenus.export"
              >导出</el-button
            >
          </el-form-item>
        </div>
      </el-col>
    </el-row>
  </el-form>
  <el-form
    :inline="true"
    :model="formInline"
    class="demo-form-inline"
    label-width="90px"
    label-position="left"
    ref="formEl"
    v-else
  >
    <el-form-item label="创建时间">
      <el-date-picker
        v-model="formInline.region"
        type="daterange"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        :size="size"
      />
    </el-form-item>
    <el-form-item label="城市选择">
      <el-cascader
        v-model="formInline.storeCodeList"
        :options="areaList"
        :props="props"
        collapse-tags
        @change="handleChange"
        :show-all-levels="false"
        placeholder="城市选择"
      />
    </el-form-item>
    <el-form-item label="供应商选择" class="mr-40">
      <el-select v-model="formInline.supplierCode" placeholder="供应商选择" filterable>
        <el-option v-for="item in supplierList" :label="item.supplierName" :value="item.code" />
      </el-select>
    </el-form-item>
    <el-form-item class="mr-20">
      <el-button type="primary" @click="onSubmit" :disabled="!authMenus.query">查询</el-button>
    </el-form-item>
    <el-form-item class="mr-20">
      <el-button @click="onReset">重置</el-button>
    </el-form-item>
    <el-form-item class="mr-20">
      <el-button type="primary" @click="exportExcel" v-if="authMenus.export" :disabled="!authMenus.export"
        >导出</el-button
      >
    </el-form-item>
  </el-form>
</template>
<script>
import { downLoad } from '../../../lin/util/util'
import moment from 'moment'
import api from '../service/index'
export default {
  props: {
    authMenus: {
      type: Object,
      default: {},
    },
    tabs: {
      type: String,
      default: '',
    },
    getTableData: {
      type: Function,
      default: null,
    },
    model: {
      type: Object,
      default: {},
    },
    exportName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formInline: {
        startTime: '', //订单开始时间
        endTime: '', //订单结束时间
        supplierCode: '',
        receivedStartTime: '', //收货开始时间
        receivedEndTime: '', //收货结束时间
        storeCodeList: [],
        region: '',
        recive: '',
      },
      supplierList: [],
      areaList: [],
      props: {
        multiple: true,
        value: 'code',
        label: 'label',
        children: 'children',
      },
    }
  },
  mounted() {
    this.getSupplier()
    this.getListArea()
  },
  methods: {
    getSupplier() {
      api.getSupplierList().then(res => {
        this.supplierList = res.data
      })
    },
    getListArea() {
      api.listWithArea().then(res => {
        this.areaList = res.data.children
        Object.values(this.areaList)
      })
    },
    onSubmit() {
      let list = []
      if (this.formInline.storeCodeList) {
        this.formInline.storeCodeList.map(item => {
          list.push(item[2])
        })
      }
      this.model.startTime = this.formInline.region && moment(this.formInline.region[0]).format('YYYY-MM-DD HH:mm:ss')
      this.model.endTime = this.formInline.region && moment(this.formInline.region[1]).format('YYYY-MM-DD HH:mm:ss')
      this.model.storeCodeList = list
      this.model.supplierCode = this.formInline.supplierCode
      this.model.pageNum = 1
      if (this.tabs !== 'first') {
        delete this.model.receivedStartTime
        delete this.model.receivedEndTime
        this.getTableData(this.model)
      } else {
        this.model.receivedStartTime =
          this.formInline.recive && moment(this.formInline.recive[0]).format('YYYY-MM-DD HH:mm:ss')
        this.model.receivedEndTime =
          this.formInline.recive && moment(this.formInline.recive[1]).format('YYYY-MM-DD HH:mm:ss')
        this.getTableData(this.model)
      }
    },
    onReset() {
      this.model.startTime = ''
      this.model.endTime = ''
      this.model.storeCodeList = []
      this.model.supplierCode = ''
      this.model.pageNum = 1
      this.formInline = {
        startTime: '', //订单开始时间
        endTime: '', //订单结束时间
        supplierCode: '',
        receivedStartTime: '', //收货开始时间
        receivedEndTime: '', //收货结束时间
        storeCodeList: '',
        region: '',
      }
      this.getTableData(this.model)
    },
    async exportExcel() {
      console.log(this.tabs, 'tabs')
      let requestApi = null
      switch (this.exportName) {
        case '账单':
          requestApi = api.exportBillExcel // 当表达式的结果等于 value1 时，则执行该代码
          break
        case '售后单':
          requestApi = api.exportAfterSalesList // 当表达式的结果等于 value2 时，则执行该代码
          break
        case '空瓶单':
          requestApi = api.exportEmptyBottleList // 当表达式的结果等于 valueN 时，则执行该代码
          break
        case '订单':
          requestApi = api.exportOrderExcel // 当表达式的结果等于 valueN 时，则执行该代码
          break
        default:
          statements // 如果没有与表达式相同的值，则执行该代码
      }
      const excelData = await requestApi(this.model)
      downLoad(excelData)
    },
  },
}
</script>
<style lang="scss" scoped>
.form-right-style {
  text-align: right;

  .select-input {
    width: 224px !important;
  }

  .mr-40 {
    margin-left: 50px;
  }

  .mr-30 {
    margin-left: 30px;
  }
}

.mr-20 {
  margin-left: 20px;
}
</style>
