<template>
  <el-dialog
    v-model="visible"
    :title="'code' in needUpdateInfo ? '修改客户来源' : '新增客户来源'"
    width="40%"
    :before-close="handleClose"
  >
    <el-form :model="needUpdateInfo" ref="formRef" :rules="rules">
      <el-form-item label="客户来源名称" label-width="120px" prop="sourceName">
        <el-input placeholder="请输入" v-model="needUpdateInfo.sourceName" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="客户来源简称" label-width="120px">
        <div class="customer-source-jc">
          <el-input placeholder="请输入" v-model="needUpdateInfo.sourceSimple" :maxlength="20"></el-input>
          <p>配置后会在收银机的实时包厢上显示</p>
        </div>
      </el-form-item>
      <el-form-item label="绑定支付方式" label-width="120px">
        <div>
          <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0;"></div>
          <el-checkbox-group v-model="needUpdateInfo.payMethod" @change="handleCheckedPayChange">
            <el-checkbox v-for="pay in payTypes" :label="pay.methodCode" :key="pay.methodCode">{{
              pay.methodName
            }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <p class="remark">
          一个支付方式只能绑定一个客户来源，若某支付方式已被其他客户来源绑定，则保存后该支付方式会从原客户来源移除
        </p>
      </el-form-item>
      <!-- <el-form-item label="绑定预订来源" label-width="120px">
        <el-select v-model="needUpdateInfo.scheduledSource">
          <el-option v-for="item in reserveSource" :key="item.code" :value="item.code" :label="item.name"></el-option>
        </el-select>
        <p class="remark">
          一个预订来源只能绑定一个客户来源，若某预订来源已被其他客户来源绑定，则保存后该预订来源会从原客户来源移除
        </p>
      </el-form-item> -->
      <el-form-item label="设为默认来源">
        <el-switch v-model="needUpdateInfo.isSource"></el-switch>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveCustomerSourceConfig">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import API from '../../service/api'
import { ref, defineProps, defineEmit, reactive } from 'vue'
import axios from 'axios'
import { ElMessage } from 'element-plus'

const props = defineProps({
  /*需要操作的会员卡信息 */
  needUpdateInfo: {
    type: Object,
    default: {},
  },
})

const emit = defineEmit(['handleCloseDialog'])

const visible = ref(true)

/**
 * 关闭弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

//预订来源
const reserveSource = ref([])
// 绑定支付方式
const payTypes = ref([]) //所有支付方式
const isIndeterminate = ref(false)
const checkAll = ref(false) //是否全选

//获取列表数据源
const listDataSource = async () => {
  let res = await axios.all([API.getPayList(), API.predetermine()])
  payTypes.value = res[0].data
  reserveSource.value = res[1].data
  isIndeterminate.value =
    res[0].data.length === props.needUpdateInfo.payMethod.length || props.needUpdateInfo.payMethod.length === 0
      ? false
      : true
  checkAll.value = res[0].data.length === props.needUpdateInfo.payMethod.length ? true : false
}
listDataSource()
/**
 * 全选
 * @param {*} val
 */
const handleCheckAllChange = val => {
  props.needUpdateInfo.payMethod = val ? payTypes.value.map(item => item.methodCode) : []
  isIndeterminate.value = false
}
/**
 * 选一个
 * @param {*} value
 */
const handleCheckedPayChange = value => {
  let checkedCount = value.length
  checkAll.value = checkedCount === payTypes.value.length
  isIndeterminate.value = checkedCount > 0 && checkedCount < payTypes.value.length
}

//校验规则
const formRef = ref(null)
const rules = {
  sourceName: [{ required: true, message: '请填写客户来源名称', trigger: 'change' }],
}

/**
 * 保存客户来源配置
 */
const saveCustomerSourceConfig = async () => {
  await formRef.value.validate()
  let info = generatorSaveData()
  let res
  if ('code' in props.needUpdateInfo) {
    res = await API.updateInfoByCode(info)
  } else {
    res = await API.saveInfo(info)
  }
  if (res.code !== '200') {
    ElMessage.error(res.msg)
  }
  handleClose()
}

/**
 * 生成数据
 */
const generatorSaveData = () => {
  return {
    ...props.needUpdateInfo,
    payMethod: props.needUpdateInfo.payMethod.join(','),
    isSource: props.needUpdateInfo.isSource === true ? 1 : 0,
  }
}
</script>

<style scoped lang="scss">
.customer-source-jc {
  p {
    margin-top: 5px;
    color: #bdc3c7;
    font-size: 12px;
  }
}
.remark {
  color: #e67e22;
  font-size: 12px;
}
</style>
