/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-10-25 16:08:27
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/points-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '营销管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/points',
  order: null,
  inNav: true,
  children: [
    {
      title: '积分商城管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/points/manager',
      filePath: 'plugin/points-manager/view/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '积分变动明细',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/points/pointChangeDetail',
      filePath: 'plugin/points-manager/view/pointChangeDetail.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
  ],
}

export default linCmsUiRouter
