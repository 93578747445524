<template>
  <div class="types">
    <el-row>
      <el-col :span="12">分类名称</el-col>
      <el-col :span="12">分类类型</el-col>
    </el-row>
    <el-row v-for="(item, index) in shopTypes" :key="index">
      <el-col :span="12">{{ item.categoryName }}</el-col>
      <el-col :span="12">
        <el-select v-model="item.typeName" @change="changeSelect(item)">
          <el-option
            v-for="(optionItem, i) in item.types"
            :key="i"
            :label="optionItem.name"
            :value="optionItem.name"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import { onMounted, ref, defineEmit, watchEffect } from 'vue'
import API from '../service/api'
const props = defineProps({
  parentShopTypes: {
    type: Array,
    default: () => [],
  },
})

const emits = defineEmit(['changeselectShopType'])

const shopTypes = ref([])
const respData = ref([])
/**
 * 获取所有类型
 */
const storeTypeSelectInfoPage = async () => {
  let res = await API.storeTypeSelectInfoPage()
  if (res.code === '200') {
    respData.value = res.data
    generateData()
  } else {
    ElMessage.error(res.msg)
  }
}

/**
 * 生成数据
 */
const generateData = () => {
  shopTypes.value = respData.value.map(item => ({
    ...item,
    types: item.typeName.split('、').map(i => ({ name: i })),
    typeName: props.parentShopTypes.find(i => i.categoryCode === item.categoryCode)?.typeName || '',
  }))
}
// watchEffect(() => {
//   generateData()
// })
onMounted(() => {
  storeTypeSelectInfoPage()
})

/**
 * 下拉框改变事件
 * @param {*} item
 */
const changeSelect = item => {
  emits('changeselectShopType', item)
}
</script>

<style lang="scss" scoped>
.types {
  .el-row {
    border: 1px solid #ccc;
    padding: 5px 0px;
    .el-col {
      text-align: center;
    }
    .el-col:nth-child(1) {
      border-right: 1px solid #ccc;
    }
  }
}
</style>
