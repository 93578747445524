<template>
  <el-dialog
    :title="'code' in updateRowMsg ? '编辑充值套餐' : '新增充值套餐'"
    v-model="dialogVisible"
    width="70%"
    :before-close="handleClose"
  >
    <el-form :model="updateRowMsg" :rules="rules" ref="formRef">
      <el-form-item label="充值金额" label-width="120px" prop="storedAmount">
        <div>
          <el-input-number
            placeholder="请输入整数，例如：填写1000"
            precision="0"
            :controls="false"
            :min="1"
            :max="999999"
            v-model="updateRowMsg.storedAmount"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item label="赠送金额" label-width="120px">
        <div>
          <el-input-number
            placeholder="请输入整数，例如：填写200"
            precision="0"
            :min="0"
            :max="999999"
            :controls="false"
            v-model="updateRowMsg.giftAmount"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item label="有效期" label-width="120px">
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD HH:mm:ss"
          v-model="updateRowMsg.time"
          :disabled-date="disabledDate"
        />
        <span class="timer-toast">未设置有效期则表示长期有效</span>
      </el-form-item>
      <el-form-item label="投放渠道" label-width="120px">
        <el-checkbox-group v-model="updateRowMsg.channel">
          <el-checkbox label="POS" value="POS"></el-checkbox>
          <el-checkbox label="小程序" value="小程序"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="指定门店可用">
        <el-button
          :disabled="!authMenus.designatedStore"
          :type="updateRowMsg.isFixedStore == 1 ? 'primary' : ''"
          @click="updateRowMsg.isFixedStore = 1"
          >指定门店</el-button
        >
        <el-button
          :disabled="!authMenus.allStores"
          :type="updateRowMsg.isFixedStore != 1 ? 'primary' : ''"
          @click="updateRowMsg.isFixedStore = 0"
          style="margin-left: 0px;"
          >全部门店</el-button
        >
      </el-form-item>
      <el-form-item
        label="适用门店"
        label-width="120px"
        prop="fixedStores"
        v-if="authMenus.designatedStore && updateRowMsg.isFixedStore === 1"
      >
        <FilterShop :storeData="updateRowMsg.fixedStores" @changeStore="changeStore" />
      </el-form-item>
      <el-form-item label="赠金当日不可用" label-width="120px">
        <!-- <el-switch v-model="updateRowMsg.a5"></el-switch> -->
        <span>是</span>
      </el-form-item>
      <el-form-item label="赠送优惠券" label-width="120px" prop="coupons">
        <el-button @click="addCoupou">添加优惠卷</el-button>
        <el-table class="coupon-table" :data="pageData">
          <el-table-column label="优惠卷" prop="name" align="center" width="440">
            <template #default="scope">
              <div class="coupou-item">
                <div class="left">
                  <text>{{ scope.row.scheme }}{{ unitsObj[scope.row.type] }}</text>
                  <text>{{ scope.row.minimumCharge === -1 ? '无门槛' : scope.row.minimumCharge }}</text>
                </div>
                <div class="right">
                  <text>{{ scope.row.name }}</text>
                  <div>有效期：{{ scope.row.validityTimeStr }}</div>
                  <text>仅{{ scope.row.usedRange === 1 ? '房费' : '商品' }}可用</text>
                  <text>发行数量：{{ scope.row.issueNum === -1 ? '不限制' : scope.row.realIssueNum }}</text>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center">
            <template v-slot="scope">
              <el-input-number v-model="scope.row.ruleSetNum" size="small" :max="999" :min="1"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{row}">
              <el-button @click="deleteMustSelectProduct(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-model:current-page="pageParams.pageNum"
          v-model:page-size="pageParams.pageSize"
          :small="small"
          :disabled="disabled"
          :page-sizes="[5, 10, 20, 30]"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageParams.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <SelectCoupou
    :isAll="true"
    v-if="coupouDialogVisible"
    :dialogVisible="dialogVisible"
    @update-dialogVisible="rectiveProducts"
    :storeCodeList="storeCodeList"
    :needFilterStore="true"
    :isFixedStore="updateRowMsg.isFixedStore"
    :hasPro="hasPro"
  ></SelectCoupou>
</template>

<script setup>
import API from '../../../service/index'
import { ref, defineEmit, defineProps, reactive, nextTick, watchEffect, onMounted } from 'vue'
import SelectCoupou from '../../../component/select-coupou.vue'
import FilterShop from '../../../component/filter-shop.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

//分页参数
const pageParams = reactive({
  pageNum: 1,
  pageSize: 5,
  total: 0,
})
//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
}
const handleCurrentChange = val => {
  pageParams.pageNum = val
}
const pageData = ref([]) //分页数据
watchEffect(() => {
  let start = pageParams.pageSize * (pageParams.pageNum - 1)
  let end = start + pageParams.pageSize
  pageData.value = props.updateRowMsg.coupons.slice(start, end)
  pageParams.total = props.updateRowMsg.coupons.length
})
const unitsObj = {
  1: '折',
  2: '元',
  3: '分钟',
  4: '元',
}

//日期选择器设置
const disabledDate = time => {
  return time.getTime() < Date.now()
}
/**
 * 父容器传过来的参数
 */
const props = defineProps({
  updateRowMsg: {
    type: Object,
    default: () => ({
      fixedStores: [],
      storedAmount: '',
    }),
  },
  authMenus: {
    type: Object,
    default: () => ({}),
  },
})

console.log(props.authMenus)

/**
 * 改变门店
 * @param {*} data
 */
const changeStore = ({ isAll, data = [], isOneClick }) => {
  if (isOneClick) {
    props.updateRowMsg.fixedStores = data
    if (data.length > 0) {
      nextTick(() => {
        formRef.value.clearValidate('fixedStores')
      })
    }

    return
  }
  if (isAll) {
    data.forEach(item => {
      let index = props.updateRowMsg.fixedStores.findIndex(i => i === item)
      if (index === -1) {
        props.updateRowMsg.fixedStores.push(item)
      }
    })
  } else {
    data.forEach(item => {
      let index = props.updateRowMsg.fixedStores.findIndex(i => i === item)
      props.updateRowMsg.fixedStores.splice(index, 1)
    })
  }
  nextTick(() => {
    formRef.value.clearValidate('fixedStores')
  })
}

const storeCodeList = ref([])
const hasPro = ref([])
/**添加优惠卷 */
const addCoupou = () => {
  storeCodeList.value = props.updateRowMsg.fixedStores
  hasPro.value = props.updateRowMsg.coupons
  isShowCoupouDialog(true)
}
/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['handleCloseDialog'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

/**
 * 删除当前优惠卷
 * @param {object} row
 */
const deleteMustSelectProduct = row => {
  ElMessageBox.confirm('确认删除当前优惠券吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let deleteIndex = props.updateRowMsg.coupons.findIndex(a => a.code === row.code)
    props.updateRowMsg.coupons.splice(deleteIndex, 1)
    if (props.updateRowMsg.coupons.length <= 10) {
      formRef.value.validateField('coupons')
    }
  })
}

// 选择优惠卷
const coupouDialogVisible = ref(false) //商品选择组件展示

/**
 * 接受新数据
 * @param {object} item 选择的商品
 */
const rectiveProducts = ({ selectSkus }) => {
  isShowCoupouDialog(false)
  props.updateRowMsg.coupons = selectSkus.map(a => ({
    ...a,
    ruleSetNum: a.ruleSetNum ? a.ruleSetNum : 1,
  }))
  // injectNewProduct(props.updateRowMsg.coupons, selectSkus)
}

/**
 * 注入新数据
 * @param {array} oldProducts  老数据
 * @param {array} newProducts  新数据
 */
const injectNewProduct = (oldProducts, newProducts) => {
  newProducts.forEach(n => {
    let old = oldProducts.find(o => o.code === n.code)
    if (old) {
      // old.ruleSetNum++
    } else {
      oldProducts.push({
        ...n,
        ruleSetNum: 1,
      })
    }
  })
}

/**
 * 是否展示选择优惠卷ialog
 */
const isShowCoupouDialog = val => {
  coupouDialogVisible.value = val
}

const validateFixedStores = (rule, value, callback) => {
  if (props.updateRowMsg.isFixedStore === 1 && props.updateRowMsg.fixedStores.length === 0) {
    return callback(new Error('请至少选择一家门店'))
  }
  callback()
}
const validateStoredAmount = (rule, value, callback) => {
  console.log(props.updateRowMsg.storedAmount)
  if (!/\d/.test(props.updateRowMsg.storedAmount) && !props.updateRowMsg.storedAmount) {
    return callback(new Error('请填写充值金额'))
  }
  if (props.updateRowMsg.storedAmount > 0 && props.updateRowMsg.storedAmount <= 999999) {
    callback()
  }
}

/**
 * 校验优惠卷数量
 */
const validateCoupons = (rule, value, callback) => {
  if (!value || value.length <= 10) {
    return callback()
  } else {
    return callback(new Error('优惠券种类不能超过10'))
  }
}

const formRef = ref(null)
const rules = {
  fixedStores: [{ validator: validateFixedStores, trigger: 'change' }],
  storedAmount: [{ required: true, validator: validateStoredAmount, trigger: 'blur' }],
  coupons: [{ required: false, validator: validateCoupons, trigger: 'change' }],
}

/**
 * 保存
 */
const handleCommit = async () => {
  try {
    if (!props.authMenus.designatedStore && !props.authMenus.allStores) {
      ElMessage.warning('暂无指定门店操作权限')
      return
    }
    if (!props.authMenus.designatedStore && props.updateRowMsg.isFixedStore === 1) {
      return ElMessage.warning('指定门店请选择全部门店')
    }
    await formRef.value.validate()
    let params = generateParams()
    let res = await API.PriceSave(params)

    if (res.code === '200') {
      ElMessage.success('操作成功')
      handleClose()
    } else {
      ElMessage.error(res.msg)
    }
  } catch (error) {
    console.log(error)
    ElMessage.warning('请完成必要信息填写')
  }
}

/**
 * 生成参数
 */
const generateParams = () => {
  let params = Object.assign({}, props.updateRowMsg)
  if (Array.isArray(params.time)) {
    params.beginTime = params.time[0]
    params.endTime = params.time[1]
  } else {
    params.beginTime = ''
    params.endTime = ''
  }
  params.channel = params.channel.join(',')
  params.coupons = params.coupons.map(item => ({
    ruleSetNum: item.ruleSetNum,
    ruleCode: item.code,
  }))
  delete params.time
  params.type = 'COMMON'

  return params
}
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
.remark {
  color: rosybrown;
}
.coupon-table {
  margin-top: 5px;
}
.el-input-number {
  width: 100%;
}
::v-deep(.el-input__inner) {
  text-align: left;
}
.timer-toast {
  margin-left: 5px;
  color: rosybrown;
}
.coupou-item {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  .left {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-right: 1px solid #ccc;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
