<template>
  <el-row class="merchantcontanier" :gutter="10">
    <el-col class="type_class" :span="3">
      <div class="class_edit">
        <el-dropdown>
          <span class="el-dropdown-link">
            <el-icon color="#78acff" class="el-icon--center" size="30"><arrow-down /></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu v-if="authMenus.update">
              <el-dropdown-item @click="stopmanu" class="shop_manu"
                ><el-icon class="lefticon"><add-location /></el-icon>完成</el-dropdown-item
              >
              <el-dropdown-item @click="startedit" class="shop_manu"
                ><el-icon class="lefticon"><edit /></el-icon>编辑</el-dropdown-item
              >
              <el-dropdown-item @click="startorder" class="shop_manu"
                ><el-icon class="lefticon"><sort /></el-icon>排序</el-dropdown-item
              >
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="categroy_list">
        <lanmu-list
          @categorySelect="selectmechant"
          :enabled="false"
          group="tj"
          :star="true"
          :disableedit="false"
          ref="tj"
          :lanmus="tuijian"
        ></lanmu-list>
        <lanmu-list
          @categorySelect="selectmechant"
          :enabled="enabled"
          :edittable="editable"
          ref="tc"
          group="tc"
          :lanmus="categories"
        ></lanmu-list>
        <lanmu-list
          @categorySelect="selectmechant"
          :enabled="enabled"
          :edittable="editable"
          group="sp"
          ref="sp"
          :lanmus="mc_categories"
        ></lanmu-list>
      </div>
    </el-col>
    <el-col :span="21">
      <el-row style="line-height: 65px" :gutter="5">
        <el-col :span="16" style="display: flex; padding-top: 20px; box-sizing: border-box">
          <el-form :inline="true">
            <el-form-item label="商品状态" v-if="category_select != 'tjsp'">
              <el-select v-model="marchantparam.status" placeholder="请选择商品状态">
                <el-option key="1" label="上架" value="1"> </el-option>
                <el-option key="0" label="下架" value="0"> </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门店">
              <el-select
                v-model="marchantparam.storeCode"
                filterable
                remote
                reserve-keyword
                placeholder="请输入门店名"
                :remote-method="remoteMethod"
                :loading="loading"
              >
                <el-option key="" label="星聚会总部" value="K0000"></el-option>
                <el-option v-for="item in mds" :key="item.code" :label="item.name" :value="item.code" />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="8" style="text-align: right; padding-right: 20px">
          <el-dropdown v-if="authMenus.add">
            <el-button size="small" type="primary">
              新增<el-icon style="position: relative; top: 3px" class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item class="shop_manu" @click="showclass_dialog"
                  ><i class="iconfont icon-a-ziyuan2"></i> &nbsp;新增类别</el-dropdown-item
                >
                <el-dropdown-item class="shop_manu" @click="openmerchantDrawer('merchant')"
                  ><i class="iconfont icon-a-ziyuan21"></i>&nbsp;新增商品</el-dropdown-item
                >
                <el-dropdown-item class="shop_manu" @click="openmerchantDrawer('combine')"
                  ><i class="iconfont icon-a-ziyuan19"></i>&nbsp;新增组合</el-dropdown-item
                >
                <el-dropdown-item class="shop_manu" @click="openmerchantDrawer('package')"
                  ><i class="iconfont icon-a-ziyuan22"></i>&nbsp;新增套餐</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <lanmu-list
          v-loading="prdloading"
          @assignmd="assigproducttomd"
          @editmerchant="editmerchantinfo"
          :categegorytype="category_select"
          :enabled="enabled"
          :storeCode="marchantparam.storeCode"
          @refresh="closeDrawer"
          @VerClick="VersionManager"
          type="card"
          :lanmus="merchants"
        ></lanmu-list>
      </el-row>
    </el-col>
  </el-row>
  <!--商品管理-->
  <Drawerform @close="resetFields" :isDisabled="true" :title="merchantDrawer.merchanttitle" size="40%" ref="formDelg">
    <template v-slot:content>
      <merchant-info
        ref="merchant"
        @close="closeDrawer"
        :skucode="skucode"
        v-if="mechanttype == 'merchant'"
      ></merchant-info>
      <combine-info
        ref="combineinfo"
        :skucode="skucode"
        @close="closeDrawer"
        v-if="mechanttype == 'combine'"
      ></combine-info>
      <package-info
        ref="packageinfo"
        :skucode="skucode"
        @close="closeDrawer"
        v-if="mechanttype == 'package'"
      ></package-info>
    </template>
  </Drawerform>
  <md-tree @mdChange="SelectMds" ref="mdtree"></md-tree>
  <el-dialog title="新增类别" center v-model="classvisible" width="30%">
    <el-form>
      <el-form-item label="类别名称" placeholder="请输入类别名称" :label-width="formLabelWidth">
        <el-input v-model="categroyform.name" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="类型" :label-width="formLabelWidth">
        <el-select v-model="categroyform.type" style="width: 100%" placeholder="请选择类型">
          <el-option label="套餐" value="1"></el-option>
          <el-option label="商品" value="2"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div slot="footer" style="text-align: center">
      <el-button @click="classvisible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
  <combine-version ref="combV"></combine-version>
  <!-- </div> -->
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { Table, Drawerform, SearchBar } from '@/component/base'
import LanmuList from '../../component/lanmulist.vue'
import UploadList from '../../component/uploadlist.vue'
import MerchantInfo from '../../component/merchantinfo.vue'
import CombineInfo from '../../component/combineinfo.vue'
import PackageInfo from '../../component/packageinfo.vue'
import { MdTree } from '@/component/base/index.js'
import { ElNotification, ElMessage } from 'element-plus'
import CombineVersion from '../../component/combineversion.vue'
import MerchantAPI from '../../service/merchant.js'
import { ArrowDown, AddLocation, Edit, Sort, CirclePlusFilled, Plus } from '@element-plus/icons-vue'

export default {
  //注册draggable组件
  components: {
    SearchBar,
    Table,
    Drawerform,
    LanmuList,
    MdTree,
    CombineVersion,
    UploadList,
    MerchantInfo,
    CombineInfo,
    PackageInfo,
    ArrowDown,
    AddLocation,
    Edit,
    Sort,
    CirclePlusFilled,
    Plus,
  },
  data() {
    return {
      authMenus: {},
      enabled: false,
      prdloading: false,
      loading: false,
      editable: false,
      mechanttype: '',
      classvisible: false,
      formLabelWidth: '80px',
      merchantDrawer: {
        merchanttitle: '新增商品',
        isDisabled: true,
      },
      mds: [],
      value: '',
      merchantdragable: true,
      merchants: [],
      //分类表单
      categroyform: {
        name: '',
        type: '1',
      },
      tuijian: [{ code: 'tjsp', sort: 1, name: '推荐' }],
      //品类列表
      categories: [],
      //商品分类
      mc_categories: [],
      marchantparam: {
        categoryCode: '',
        status: '1',
        storeCode: 'K0000',
        keyword: '',
      },
      skucode: '',
      currentSkuCode: '',
      category_select: 'tjsp',
    }
  },
  watch: {
    'marchantparam.storeCode'() {
      if (this.category_select == 'tjsp') {
        this.getRecommendList()
      } else {
        this.getSkuList()
      }
    },
    'marchantparam.keyword'() {
      if (this.category_select == 'tjsp') {
        this.getRecommendList()
      } else {
        this.getSkuList()
      }
    },
    'marchantparam.status'() {
      if (this.category_select == 'tjsp') {
        this.getRecommendList()
      } else {
        this.getSkuList()
      }
    },
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getCategroyList_TC()
      this.getCategroyList_MC()
      this.getRecommendList()
      document.querySelectorAll('.menuitem')[0].style.backgroundColor = '#5384FF'
      document.querySelectorAll('.menuitem')[0].style.color = '#FFFFFF'
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    remoteMethod(query) {
      MerchantAPI.getStoreListByName({
        storeName: query,
      }).then(res => {
        if (res.code == '200') {
          this.mds = res.data
        }
      })
    },
    SelectMds(mds) {
      MerchantAPI.skuDistribution({
        skuCode: this.currentSkuCode,
        storeCodes: mds,
      }).then(res => {
        if (res.code == '200') {
          ElMessage({
            message: '分配门店成功！',
            type: 'success',
          })
        }
        this.$refs.mdtree.cancel()
      })
    },
    closeDrawer() {
      this.$refs.formDelg.cancelSubmit()
      if (this.category_select == 'tjsp') {
        this.getRecommendList()
      } else {
        this.getSkuList()
      }
    },
    VersionManager() {
      this.$refs.combV.openCombineVersion()
    },
    getRecommendList() {
      MerchantAPI.RecommendList({
        storeCode: this.marchantparam.storeCode,
      }).then(res => {
        if (res.code == '200') {
          this.merchants = res.data
        }
      })
    },
    getCategroyList_TC() {
      MerchantAPI.getCategoryList(1).then(res => {
        this.categories = res.data
      })
    },
    getCategroyList_MC() {
      MerchantAPI.getCategoryList(2).then(res => {
        this.mc_categories = res.data
      })
    },
    resetFields() {
      switch (this.mechanttype) {
        case 'merchant':
          this.$refs.merchant.resetFields()
          break
        case 'combine':
          this.merchantDrawer.merchanttitle = '新增组合'
          break
        case 'package':
          this.merchantDrawer.merchanttitle = '新增套餐'
          break
      }
    },
    async editmerchantinfo(code, producttype) {
      this.merchantDrawer.merchanttitle = '商品编辑'
      switch (producttype) {
        case 1:
          this.mechanttype = 'merchant'
          break
        case 2:
          this.mechanttype = 'combine'
          break
        case 3:
          this.mechanttype = 'package'
      }
      this.skucode = code
      this.$refs.formDelg.openDrawer()
      this.$nextTick(() => {
        switch (producttype) {
          case 1:
            this.$refs.merchant.getSkuInfo()
            break
          case 2:
            this.$refs.combineinfo.getTcinfo()
            break
          case 3:
            this.$refs.packageinfo.getTcinfo()
            break
        }
      })
    },
    editclass() {
      this.disabled = !this.disabled
      ElNotification({
        title: '提示',
        type: 'success',
        message: '开启编辑成功',
      })
    },
    submit() {
      MerchantAPI.addCategroy(this.categroyform).then(res => {
        this.getCategroyList_TC()
        this.getCategroyList_MC()
        this.categroyform.name = ''
        this.classvisible = false
        ElMessage({
          message: '添加品类成功！',
          type: 'success',
        })
      })
    },
    assigproducttomd(code) {
      this.currentSkuCode = code
      this.$refs.mdtree.openmdtree()
    },
    showclass_dialog() {
      this.classvisible = true
    },
    stopmanu() {
      this.enabled = false
      this.editable = false
    },
    startedit() {
      this.enabled = false
      this.editable = true
    },
    startorder() {
      this.enabled = true
      this.editable = false
    },
    getSkuList() {
      this.prdloading = true
      MerchantAPI.productList(this.marchantparam).then(res => {
        if (res.code == '200') {
          this.prdloading = false
          this.merchants = res.data
        }
      })
    },
    selectmechant(code) {
      this.category_select = code
      if (code == 'tjsp') {
        this.getRecommendList()
      } else {
        this.marchantparam.categoryCode = code
        this.getSkuList()
      }
    },
    openmerchantDrawer(type) {
      this.mechanttype = type
      this.skucode = ''
      this.$refs.formDelg.openDrawer()
      this.$nextTick(() => {
        switch (type) {
          case 'merchant':
            this.$refs.merchant.resetFields()
            this.merchantDrawer.merchanttitle = '新增商品'
            break
          case 'combine':
            this.$refs.combineinfo.resetFields()
            this.merchantDrawer.merchanttitle = '新增组合'
            break
          case 'package':
            this.$refs.packageinfo.resetFields()
            this.merchantDrawer.merchanttitle = '新增套餐'
            break
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.merchantcontanier {
  overflow-x: hidden;
  ::v-deep .el-dropdown__popper {
    width: 250px;
  }
  ::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
    color: #78acff;
  }
  .type_class {
    height: calc(100vh - 150px);
    .categroy_list {
      height: calc(100vh - 190px);
      overflow-y: auto;
    }

    background: #ffffff;
  }
  .iconfont {
    position: relative;
    top: 3px;
  }
  .class_edit {
    width: 100%;
    height: 40px;
    cursor: pointer;
    line-height: 40px;
    text-align: center;
    background: #ffffff;
    color: #5384ff;
    text-decoration: underline;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.09);
    border-radius: 0px 0px 10px 10px;
  }
}
.shop_manu {
  .lefticon {
    position: relative;
    top: 2px;
  }
  width: 6vw;
  text-align: center;
  // letter-spacing: 5px;
  &:hover {
    color: #5384ff;
  }
}
.leftclass {
  position: relative;
  height: calc(100vh - 156px);
  border-right: 1px solid #dedede;
}
.dialog-footer {
  text-align: right;
}

.header {
  background: white;
  .menu {
    line-height: 60px;
    height: 60px;
  }
}
</style>
