<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-18 14:34:16
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-07-08 12:12:08
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/supplyShopModal.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="supplyShop-modal-content">
    <el-drawer
      v-model="editModal"
      title="苏州信投店-A02/小包"
      size="70%"
      :direction="direction"
      :with-header="false"
      :before-close="handleClose"
    >
      <div class="supplyShop-info">
        <div class="info-left">
          <h1>
            {{ storeName }}
          </h1>
          <div class="info-line"></div>
          <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane v-for="(item, index) in dataList" :label="item.categoryCodeName" :name="index">
              <p v-if="item.taxState === 1">（含税<span>|</span>税点：{{ item.taxPoint }}）</p>
              <p v-else>（不含税）</p>
              <el-checkbox-group v-model="checkList" @change="checkDrinks">
                <el-checkbox
                  v-for="(childItem, index) in item.goodsMatterList"
                  :label="childItem.skuName"
                  name="type"
                />
              </el-checkbox-group>
            </el-tab-pane>
          </el-tabs>
        </div>
        <div class="info-right">
          <h1 class="info-right-title">
            供应商品确认<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="editModal = false">
              <close />
            </el-icon>
          </h1>
          <div class="supply-goods-list">
            <div class="water-list-content" v-for="item in matterList">
              <h1>{{ item.categoryCodeName }}</h1>
              <div class="elTag-list">
                <el-tag
                  v-for="tag in item.goodsMatterList"
                  :key="tag.categoryCode1"
                  class="mx-1"
                  closable
                  :type="tag.type"
                  @close="deleteFood(tag)"
                >
                  {{ tag.skuName }}
                </el-tag>
              </div>
            </div>
          </div>
          <el-form :model="form" label-width="90px" label-position="left" class="form-content">
            <el-form-item label="运费">
              <el-col :span="6">
                <el-input v-model="form.freight" />
              </el-col>
              <el-col :span="2" class="form-unit">
                元
              </el-col>
            </el-form-item>

            <el-form-item label="最小起订金额">
              <el-col :span="6">
                <el-input v-model="form.miniPrice" />
              </el-col>
              <el-col :span="2" class="form-unit">
                元
              </el-col>
            </el-form-item>
            <el-form-item label="结算类型">
              <el-select v-model="form.billType" placeholder="请选择结算类型">
                <el-option label="现结" :value="1" />
                <el-option label="月结" :value="2" />
              </el-select>
            </el-form-item>
            <el-form-item label="供应状态">
              <el-select v-model="form.state" placeholder="请选择供应状态">
                <el-option label="取关" :value="0" />
                <el-option label="在供" :value="10" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <el-button type="primary" @click="onSubmit" class="confirm-edit">确认修改</el-button>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../service/index'
export default {
  props: {
    getTableData: {
      type: Function,
      default: () => {},
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editModal: false,
      tags: [],
      activeName: 0,
      checkList: [],
      form: {
        freight: '',
        miniPrice: '',
        billType: 1,
        state: null,
      },
      dataList: [],
      matterList: [],
      code: '',
      storeName: '',
    }
  },
  methods: {
    getEditInfo(code, storeName) {
      this.code = code
      this.storeName = storeName
      api.getStoreAlreadyBindGoodsList({ code: code }).then(res => {
        this.dataList = res.data.categoryWithMatterList
        this.matterList = this.dataList.map(category => {
          const goodsMatterList = category.goodsMatterList.filter(item => item.selected === 1)
          return {
            categoryCodeName: category.categoryCodeName,
            goodsMatterList: goodsMatterList,
          }
        })
        this.checkList = []
        this.dataList.forEach(item => {
          item.goodsMatterList.forEach(elItem => {
            if (elItem.selected === 1) {
              this.checkList.push(elItem.skuName)
            }
          })
        })
        const { freight, miniPrice, billType, state } = res.data
        this.form = { ...this.form, freight, miniPrice, billType, state }
      })
    },
    checkDrinks() {
      this.matterList = this.dataList.map(category => {
        const goodsMatterList = category.goodsMatterList.filter(item => this.checkList.includes(item.skuName))
        return {
          categoryCodeName: category.categoryCodeName,
          goodsMatterList: goodsMatterList,
        }
      })
    },
    deleteFood(tag) {
      this.matterList.forEach(item => {
        item.goodsMatterList.forEach((childrenItem, index) => {
          if (childrenItem.skuName === tag.skuName) {
            item.goodsMatterList.splice(index, 1)
          }
        })
      })
      this.checkList.forEach((item, index) => {
        if (item === tag.skuName) {
          this.checkList.splice(index, 1)
        }
      })
    },
    onSubmit() {
      const list = []
      this.matterList.forEach(item => {
        item.goodsMatterList.forEach(elItem => {
          list.push(elItem)
        })
      })
      let param = {
        billType: this.form.billType,
        categoryWithMatterList: [],
        code: this.code,
        freight: this.form.freight,
        goodsAddBOList: list,
        miniPrice: this.form.miniPrice,
        state: this.form.state,
      }
      api.updateBindStoreDataByCode(param).then(res => {
        if (res.code === '200') {
          ElMessage({
            type: 'success',
            message: `编辑成功`,
          })
          this.editModal = false
          this.$parent.getTableData(this.model)
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.supplyShop-info {
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding-left: 40px;

  .confirm-edit {
    position: absolute;
    right: 40px;
    bottom: 24px;
  }

  .info-left,
  .info-right {
    height: 100vh;
    flex: 1;
  }

  .info-left {
    max-width: 50%;

    h1 {
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      height: 73px;
      line-height: 73px;
    }

    .demo-tabs {
      p:first-child {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #fb5d5d;
        height: 36px;
        line-height: 20px;

        span {
          display: inline-block;
          width: 1px;
          margin-left: 10px;
          margin-right: 10px;
          color: #979595;
        }
      }

      .el-checkbox-group {
        line-height: 26px;
      }
    }
  }

  .info-line {
    width: 588px;
    height: 1px;
    background-color: #ebeef5;
    margin-bottom: 25px;
  }

  .info-right {
    background-color: #f8faff;
    box-sizing: border-box;
    padding: 0 40px;
    position: relative;

    .supply-goods-list {
      height: calc(100vh - 420px);
      overflow: auto;
    }

    .form-content {
      padding-top: 24px;
      width: 600px;
      position: absolute;
      bottom: 65px;
      border-top: 1px solid #ebeef5;

      ::v-deep .el-form-item {
        margin-bottom: 0px !important;
      }

      .form-unit {
        padding-left: 20px;
      }
    }

    .info-right-title {
      margin-top: 40px;
      margin-bottom: 24px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      position: relative;

      .close-icon {
        position: absolute;
        right: 0px;
        top: -20px;
        cursor: pointer;
      }
    }

    .water-list-content {
      height: 250px;
      .elTag-list {
        max-height: calc(100% - 60px);
        overflow: auto;
        margin-bottom: 10px !important;
      }

      h1:first-child {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        height: 42px;
        line-height: 42px;
        margin-bottom: 10px;
        margin-top: 10px;
      }

      ::v-deep .el-tag {
        margin-right: 10px !important;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
