<!--
 * @Author: your name
 * @Date: 2022-04-20 13:48:02
 * @LastEditTime: 2022-07-21 10:02:19
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/component/tableSearchTab的副本.vue
-->
<template>
  <div class="table-search-tab" style="height: 76px;line-height: 76px;">
    <div class="search-left">
      <span v-if="searchTypes === 'supplier-index'">品类</span>
      <!-- <el-select v-model="value" class="search-select" placeholder="选择门店">
        <el-option v-for="item in storeList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select> -->
      <el-select
        v-if="searchTypes === 'supplier-index'"
        v-model="categoryCode"
        filterable
        remote
        reserve-keyword
        placeholder="选择品类"
        clearable
        class="search-select"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option v-for="item in categoryList" :key="item.code" :label="item.name" :value="item.code" />
      </el-select>
      <el-input v-model="supplierName" placeholder="请输入供应商搜索" class="input-search-tel">
        <template #append>
          <el-icon>
            <search />
          </el-icon>
        </template>
      </el-input>
      <el-button type="primary" @click="searchTableData" :disabled="!authMenus.query">查询</el-button>
      <el-button plain @click="reset">重置</el-button>
    </div>
    <div class="search-right">
      <el-button
        type="primary"
        v-if="searchTypes === 'isStoringWine' && authMenus.batchRenew"
        class="batch-btn"
        @click="openExtendModal"
        :disabled="!authMenus.batchRenew"
        >批量续存
      </el-button>
      <el-date-picker
        v-model="queryDate"
        type="datetimerange"
        start-placeholder="起始日期"
        format="YYYY-MM-DD"
        end-placeholder="结束日期"
        v-if="searchTypes === 'sccessRecord'"
        style="margin-right: 20px;"
      />
      <el-button type="primary" @click="addSupplier" v-if="authMenus.add" :disabled="!authMenus.add"
        >新增供应商</el-button
      >
    </div>
  </div>
  <ExportDialog :columnsList="columnsList" ref="dialogRef" :exportData="exportData" />
</template>
<script>
import { async } from 'q'
import api from '../service/index'
import { downLoad } from '../../../lin/util/util'
import ExportDialog from '../../../component/base/exportDialog/export-dialog.vue'
import { value } from 'dom7'
export default {
  components: { ExportDialog },
  props: {
    authMenus: {
      type: Object,
      default: {},
    },
    searchTypes: {
      type: String,
      default: '',
    },
    openExtendModal: {
      type: Function,
      default: null,
    },
    getTableData: {
      type: Function,
      default: null,
    },
    model: {
      type: Object,
      default: {},
    },
    columnsList: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      queryDate: '',
      typeSelect: '',
      supplierName: '',
      storeList: [],
      storeCode: '',
      columnsList: this.columnsList,
      dialogVisible: false,
      categoryCode: '',
      categoryList: [],
    }
  },
  mounted() {
    this.getCategory()
  },
  methods: {
    searchTableData() {
      if (this.model.pageNum !== 1) {
        this.model.pageNum = 1
      }
      this.model.pageSize = 10
      this.model.supplierName = this.supplierName
      this.model.storeCode = this.storeCode
      this.model.categoryCode1 = this.categoryCode
      this.getTableData(this.model)
    },
    getCategory() {
      api.getCategoryList().then(res => {
        this.categoryList = res.data
      })
    },
    reset() {
      this.supplierName = ''
      this.model.supplierName = ''
      this.model.storeCode = ''
      this.categoryCode = ''
      this.model.pageNum = 1
      this.model.categoryCode1 = ''
      this.getTableData(this.model)
    },
    async exportData(exportDatas) {
      const excelData = await api.exportOrderCardStored({
        telephone: this.telephone,
        storeCode: this.storeCode,
        exportDatas: exportDatas.join(','),
      })
      downLoad(excelData)
    },
    openExportDialog() {
      this.$refs.dialogRef.openDialog()
    },
    addSupplier() {
      this.$router.push('/supplyChain-manager/supplierManagement/addSupplier')
    },
  },
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;

  .search-left {
    width: 60%;

    .input-with-select {
      width: 340px;
      margin-left: 24px;
      margin-right: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
</style>
