<template>
  <el-dialog v-model="visible" title="库存明细" width="70%" :before-close="handleClose">
    <el-table
      :data="data.tableData"
      style="width: 96%; margin-left: 2%;margin-top: 10px;"
      class="table-content"
      v-loading.fullscreen.lock="loading"
      element-loading-text="正在火速加载数据..."
    >
      <el-table-column label="序号" align="center" type="index"></el-table-column>
      <el-table-column label="商品图片" prop="pic" width="100" align="center">
        <template #default="scope">
          <div style="display: flex; justify-content: center;">
            <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
          </div>
        </template>
      </el-table-column>
      <el-table-column label="商品名称" prop="productName" align="center" />
      <el-table-column label="商品规格" prop="spec" align="center" />
      <el-table-column label="库存数量" prop="stock" align="center" />
      <el-table-column label="最小单位" prop="unit" align="center" />
      <el-table-column label="仓库" prop="houseName" align="center" />
    </el-table>
  </el-dialog>
</template>

<script>
import { ref, reactive, defineComponent, computed, onMounted } from 'vue'
import API from '../../../service/api'
import { ElMessage } from 'element-plus'
export default defineComponent({
  name: 'AddShop',
  props: {
    dialogVisible: Boolean,
    params: Object,
  },
  setup(props, ctx) {
    const data = reactive({
      tableData: [],
      loading: false,
    })

    onMounted(() => {
      queryLists()
    })

    const queryLists = () => {
      data.loading = true
      API.selectStockGoodsPcItem(props.params)
        .then(res => {
          if (res.code === '200') {
            console.log(res.data)
            data.tableData = res.data
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(() => {
          data.loading = false
        })
    }

    const visible = computed(() => {
      return props.dialogVisible
    })
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      handleClose,
      visible,
      data,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-row {
  margin-bottom: 20px;
  width: 96%;
  margin-left: 7% !important;
}
.endMsgTitle {
  text-align: center;
  span {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }
}
</style>
