<template>
  <div style="width:100%">
    <extend-drawer :checkdata="selectmds" @confirmassign="AssignMdPrd" ref="extendDrawer"></extend-drawer>
    <div v-if="type == 'card'" class="merchant_list">
      <draggable
        chosenClass="chosen"
        forceFallback="true"
        group="merchant"
        :disabled="!enabled"
        animation="1000"
        @end="onEnd"
      >
        <transition-group>
          <div v-if="lanmus.length == 0" style="text-align:center;margin-top:200px;">
            <el-empty :image-size="200" />
          </div>
          <div v-else v-for="sku in lanmus" :key="sku" class="mechant_card">
            <el-card style="width:160px;height:200px;" :body-style="{ padding: '5px' }">
              <el-image fit="fill" style="width: 150px; height: 130px" :src="sku.pic" lazy>
                <template #error>
                  <div class="image-slot">
                    <el-empty :image-size="120" description="暂无图片"></el-empty>
                  </div>
                </template>
              </el-image>
              <div class="merchant_info" v-if="!isMateriel">
                <p class="merchant_price">
                  <span
                    ><b>¥{{ storeCode == 'K0000' ? sku.price : sku.retailPrice }}</b
                    ><span>{{ sku.productType == 1 ? '/' + sku.unit : '' }}</span>
                  </span>
                  <span>
                    <!-- <el-dropdown v-if="storeCode == 'K0000'"> -->
                    <el-dropdown>
                      <span class="el-dropdown-link">
                        <el-icon style="position: relative; top: 3px" color="#78acff" class="el-icon--center" size="20">
                          <Menu />
                        </el-icon>
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item
                            v-if="sku.isShelves == 1"
                            @click="editinfo(sku.code, sku.productType)"
                            class="shop_manu"
                            >编辑</el-dropdown-item
                          >
                          <div>
                            <el-dropdown-item
                              @click="changeshlve(sku.code, 0)"
                              v-if="sku.isShelves == 1"
                              class="shop_manu"
                              >下架</el-dropdown-item
                            >
                            <el-dropdown-item v-else @click="changeshlve(sku.code, 1)" class="shop_manu"
                              >上架
                            </el-dropdown-item>
                          </div>

                          <!-- <el-dropdown-item @click="Versionmng(sku.code)" v-if="sku.productType == 2" class="shop_manu"
                            >版本管理</el-dropdown-item
                          > -->
                          <el-dropdown-item
                            v-if="sku.isShelves == 1"
                            @click="assignmd(sku.code, sku.productType)"
                            class="shop_manu"
                            >分配门店</el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                    <!-- <span v-if="storeCode == 'K0000' && sku.isShelves == 1" style="position: relative; top: 5px"> -->
                    <span style="position: relative; top: 5px">
                      <el-popconfirm
                        @confirm="tuijian(sku.code, sku.isRecommend)"
                        :title="sku.isRecommend ? '是否取消推荐？' : '是否加入推荐？'"
                      >
                        <template #reference>
                          <el-icon style="cursor: pointer" size="24" :color="sku.isRecommend ? '#FF922E' : '#d4d4d4'">
                            <star-filled />
                          </el-icon>
                        </template>
                      </el-popconfirm>
                    </span>
                  </span>
                </p>
                <p class="merchant_name">{{ sku.name }}</p>
              </div>
              <div class="category_info" v-else>
                <div>
                  <p class="category_name">{{ sku.name }}</p>
                </div>
                <div>
                  <el-dropdown>
                    <span class="el-dropdown-link">
                      <el-icon style="position: relative; top: 3px" color="#78acff" class="el-icon--center" size="20">
                        <Menu />
                      </el-icon>
                    </span>
                    <template #dropdown>
                      <el-dropdown-menu>
                        <el-dropdown-item @click="editinfo(sku.code, sku.productType)" class="shop_manu"
                          >编辑
                        </el-dropdown-item>
                        <!-- <el-dropdown-item class="shop_manu" v-if="isMateriel" @click="openSupplyModal(sku)">
                          分配供应商
                        </el-dropdown-item> -->
                      </el-dropdown-menu>
                    </template>
                  </el-dropdown>
                </div>
              </div>
            </el-card>
          </div>
        </transition-group>
      </draggable>
    </div>

    <draggable
      v-else
      chosenClass="chosen"
      forceFallback="true"
      :group="'mechantList' + group"
      :disabled="!enabled"
      animation="1000"
      :class="'mechantList' + group"
      style="padding: 5px 8px"
      @end="onEnd"
    >
      <transition-group>
        <div
          class="menuitem"
          @click="selectclass($event, element.code)"
          :aria-sort="element.sort"
          :code="element.code"
          :name="element.name"
          v-for="element in lanmus"
          :key="element.id"
        >
          <el-icon v-if="star" style="poistion: relative; left: -5px; top: 2px" color="#FF922E" size="14">
            <star-filled /> </el-icon
          ><span>
            <el-icon v-if="edittable" size="14" @click.stop="editclass(element.code, element.name)" class="editicon">
              <info-filled /> </el-icon
            >{{ element.name }}
          </span>
          <span v-if="edittable" class="controller">
            <el-icon @click.stop="removeclass(element.code)" color="#676767" :size="15">
              <circle-close-filled />
            </el-icon>
          </span>
        </div>
      </transition-group>
    </draggable>
  </div>
  <el-dialog v-model="supplyModal" title="分配供应商" width="40%" :before-close="closeSupplyModal">
    <div style="width: 300px;">
      <el-form ref="formRef" :model="form" label-width="100px">
        <el-form-item label="供应商">
          <el-select
            v-model="form.supplierCode"
            filterable
            remote
            reserve-keyword
            placeholder="请选择供应商"
            clearable
            class="search-select"
            :remote-method="remoteMethod"
            :loading="loading"
            @change="selectSupplier"
          >
            <el-option
              v-for="item in canBindSupplierList"
              :key="item.supplierCode"
              :label="item.supplierName"
              :value="item.supplierCode"
            />
          </el-select>
        </el-form-item>
        <el-form-item
          label="进货价"
          class="unit-style"
          prop="stockPrice"
          :rules="[{ type: 'number', message: '进货价必须是数字' }]"
        >
          <el-input v-model.number="form.stockPrice" placeholder="请输入进货价" />
          <span>元</span>
        </el-form-item>
        <el-form-item label="进货单位">
          <el-select v-model="form.stockUnit" placeholder="请选择进货单位">
            <el-option v-for="item in unitList" :label="item.name" :value="item.name" />
          </el-select>
        </el-form-item>
        <el-form-item
          label="空瓶回收价"
          class="unit-style"
          prop="recoveryPrice"
          :rules="[{ type: 'number', message: '空瓶回收价必须是数字' }]"
        >
          <el-input v-model.number="form.recoveryPrice" placeholder="请输入空瓶回收价" />
          <span>元</span>
        </el-form-item>
        <el-form-item label="空瓶回收单位">
          <el-select v-model="form.recoveryUnit" placeholder="请选择回收单位">
            <el-option label="瓶" value="瓶" />
            <el-option label="箱" value="箱" />
          </el-select>
        </el-form-item>
        <el-form-item label="包装数量">
          <el-input-number v-model="form.packageCount" :min="1" />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="supplyModal = false">取消</el-button>
        <el-button type="primary" @click="addSupplier">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import ExtendDrawer from './extendDrawer.vue'
import {
  CircleClose,
  Picture,
  Edit,
  InfoFilled,
  Check,
  StarFilled,
  CircleCloseFilled,
  Message,
  Star,
  Delete,
  ArrowDown,
  Menu,
} from '@element-plus/icons-vue'
import { ElMessageBox, ElMessage, ElStep } from 'element-plus'
import MerchantAPI from '../service/merchant.js'
import api from '../../supplyChain-manager/service/index'
// import emptyimg from '../assets/image/empty.png'

export default {
  name: 'Lanmu',
  components: {
    draggable: VueDraggableNext,
    CircleClose,
    ExtendDrawer,
    StarFilled,
    Edit,
    Check,
    Picture,
    Message,
    CircleCloseFilled,
    Star,
    InfoFilled,
    Delete,
    ArrowDown,
    Menu,
  },
  data() {
    return {
      currentCode: '',
      productType: '',
      selectmds: [],
      supplyModal: false,
      form: {
        categoryCode1: '',
        packageCount: 1,
        recoveryPrice: null,
        recoveryUnit: '',
        skuCode: '',
        skuUnit: '',
        stockPrice: null,
        stockUnit: '',
        storeCode: '',
        storeName: '',
        supplierCode: '',
        supplierName: '',
      },
      canBindSupplierList: [],
      unitList: [], //进货单位
    }
  },
  props: {
    edittable: {
      type: Boolean,
      default: false,
    },
    star: {
      type: Boolean,
      default: false,
    },
    categegorytype: {
      type: String,
      default: '',
    },
    disableedit: {
      type: Boolean,
      default: true,
    },
    enabled: {
      type: Boolean,
      default: false,
    },
    group: {
      type: String,
      default: '',
    },
    lanmus: {
      type: Array,
      default: [],
    },
    type: {
      type: String,
      default: '',
    },
    storeCode: {
      type: String,
      default: 'K0000',
    },
    isMateriel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {},
  watch: {},
  mounted() {},
  methods: {
    //分配门店
    AssignMdPrd(mdlist) {
      MerchantAPI.AssignMertoMd({
        code: this.currentCode,
        productType: this.productType,
        storeCodes: mdlist,
      }).then(res => {
        if (res.code == '200') {
          ElMessage({
            type: 'success',
            message: '分配成功!',
          })
          this.$refs.extendDrawer.extendDrawer = false
        }
      })
    },
    changeshlve(skucode, shlve) {
      this.$confirm(`是否确认${shlve == 1 ? '上架' : '下架'}商品？`, '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'customConfirm',
        cancelButtonClass: 'btn-custom-cancel',
        type: 'warning',
      }).then(() => {
        MerchantAPI.SkuShelve({
          code: skucode,
          isShelves: shlve,
          storeCode: this.storeCode,
        }).then(res => {
          if (res.code == '200') {
            if (shlve == 1) {
              ElMessage({
                type: 'success',
                message: '上架成功',
              })
            } else {
              ElMessage({
                type: 'success',
                message: '下架成功',
              })
            }
            this.$emit('refresh')
          }
        })
      })
    },
    onEnd(e) {
      if (e.newIndex != e.oldIndex) {
        let tempsort = this.lanmus[e.newIndex].sort
        const list = JSON.parse(JSON.stringify(this.lanmus))
          .map((item, index) => {
            if (index == e.oldIndex) {
              item.sort = tempsort
            }
            if (index > e.newIndex - 1 && index < e.oldIndex) {
              item.sort = this.lanmus[index + 1].sort
            }
            if (index > e.oldIndex && index < e.newIndex + 1) {
              item.sort = this.lanmus[index - 1].sort
            }
            return item
          })
          .sort((a, b) => {
            return a.sort - b.sort
          })

        if (this.type == 'card') {
          MerchantAPI.goodsResort({ type: this.categegorytype == 'tjsp' ? 2 : 1, lanmus: list }).then(res => {
            if (res.code == '200') {
              ElMessage({
                type: 'success',
                message: '排序成功',
              })
            }
          })
        } else {
          MerchantAPI.categoryResort(list).then(res => {
            ElMessage({
              type: 'success',
              message: '排序成功',
            })
          })
        }
      }
    },
    mechantclick() {
      this.$emit('itemclick', false)
    },
    Versionmng(code) {
      this.$emit('VerClick', code)
    },
    removeclass(code) {
      ElMessageBox.confirm('是否确认删除该品类?', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        MerchantAPI.categroyDelete(code).then(res => {
          if (res.code == '200') {
            ElMessage({
              type: 'success',
              message: '删除品类成功',
            })
            this.lanmus.filter((item, index) => {
              if (item.code == code) {
                this.lanmus.splice(index, 1)
                return
              }
            })
          } else {
            ElMessage({
              type: 'error',
              message: res.msg,
            })
          }
        })
      })
    },
    editinfo(code, productType) {
      this.$emit('editmerchant', code, productType)
    },
    openSupplyModal(item) {
      this.form = {
        categoryCode1: '',
        packageCount: 1,
        recoveryPrice: null,
        recoveryUnit: '',
        skuCode: '',
        skuUnit: '',
        stockPrice: null,
        stockUnit: '',
        storeCode: '',
        storeName: '',
        supplierCode: '',
        supplierName: '',
      }
      this.form.storeCode = item.storeCode
      this.supplyModal = true
      MerchantAPI.getAlreadyAddSupplier({ skuCode: item.code, storeCode: item.storeCode }).then(res => {
        if (res.data !== null) {
          const {
            supplierCode,
            supplierName,
            packageCount,
            recoveryPrice,
            stockUnit,
            stockPrice,
            recoveryUnit,
            skuUnit,
            skuCode,
            categoryCode1,
          } = res.data
          this.form = {
            ...this.form,
            supplierCode,
            supplierName,
            packageCount,
            recoveryPrice,
            stockUnit,
            stockPrice,
            recoveryUnit,
            skuUnit,
            skuCode,
            categoryCode1,
          }
        }
      })
      MerchantAPI.getCanAddSupplier({ skuCode: item.code }).then(res => {
        this.canBindSupplierList = res.data
      })
      api.unitList().then(res => {
        this.unitList = res.data
      })
    },
    addSupplier() {
      MerchantAPI.addRelationSupplier(this.form).then(res => {
        if (res.code === '200') {
          ElMessage({
            type: 'success',
            message: '绑定供应商成功!',
          })
          this.closeSupplyModal()
        } else {
          ElMessage({
            type: 'error',
            message: res.msg,
          })
        }
      })
    },
    closeSupplyModal() {
      this.supplyModal = false
      this.$refs.formRef.resetFields()
    },
    selectSupplier() {
      let supplierData = this.canBindSupplierList.find(item => item.supplierCode === this.form.supplierCode)
      const {
        supplierCode,
        supplierName,
        packageCount,
        recoveryPrice,
        stockUnit,
        stockPrice,
        recoveryUnit,
        skuUnit,
        skuCode,
        categoryCode1,
      } = supplierData
      this.form = {
        ...this.form,
        supplierCode,
        supplierName,
        packageCount,
        recoveryPrice,
        stockUnit,
        stockPrice,
        recoveryUnit,
        skuUnit,
        skuCode,
        categoryCode1,
      }
    },
    selectclass(e, code) {
      this.clearstyle()
      e.currentTarget.style.backgroundColor = '#5384FF'
      e.currentTarget.style.color = '#FFFFFF'
      this.$emit('categorySelect', code)
    },
    clearstyle() {
      document.querySelectorAll('.menuitem').forEach(item => {
        item.style.backgroundColor = '#ffffff'
        item.style.color = '#000000'
      })
    },
    tuijian(code, isRecommend) {
      MerchantAPI.Recommend({
        isRecommend: isRecommend ? 0 : 1,
        productCode: code,
        storeCode: this.storeCode,
      }).then(res => {
        if (res.code == '200') {
          if (this.categegorytype == 'tjsp') {
            if (isRecommend == 1) {
              this.lanmus.filter((item, index) => {
                if (item.code == code) {
                  this.lanmus.splice(index, 1)
                  return
                }
              })
            }
          } else {
            this.lanmus.map(item => {
              if (item.code == code) {
                item.isRecommend = isRecommend ? 0 : 1
                return item
              }
            })
          }

          ElMessage({
            type: 'success',
            message: isRecommend ? '取消推荐成功' : '推荐成功',
          })
        }
      })
    },
    assignmd(code, prdtype) {
      this.currentCode = code
      this.productType = prdtype
      MerchantAPI.AssignMdList({
        code: code,
      }).then(res => {
        if (res.code == '200') {
          this.selectmds = res.data
        }
      })
      this.$refs.extendDrawer.extendDrawer = true
    },
    editclass(code, name) {
      ElMessageBox.prompt('请修改品类名称', '品类修改', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: name,
        // inputPattern: /^[\u4e00-\u9fa5]+$/,
        inputErrorMessage: '输入正确的品类名称',
      }).then(({ value }) => {
        MerchantAPI.categroyUpdate({
          code: code,
          name: value,
        }).then(res => {
          this.lanmus.map(item => {
            if (item.code == code) {
              item.name = value
              return item
            }
          })
          ElMessage({
            type: 'success',
            message: `修改成功`,
          })
        })
      })
    },
  },
}
</script>

<style scoped lang="scss">
.image {
  border-radius: 5px;
}

.merchant_list {
  max-height: calc(100vh - 250px);
  width: 100%;
  overflow-y: auto;
}

.el-empty {
  padding: 0px;
}

.controller {
  position: absolute;
  right: -5px;
  top: -10px;
  z-index: 99;
}

.editicon {
  position: absolute;
  top: 12px;
  left: 15px;
}

.menuitem {
  text-align: center;
  margin-bottom: 5px;
  line-height: 40px;
  position: relative;
  cursor: pointer;

  &:hover {
    background: #f2f2f2;
  }

  // &:active {
  //   background: #5384ff;
  // }
}

.shop_manu {
  .lefticon {
    position: relative;
    top: 2px;
  }

  width: 6vw;
  text-align: center;
  letter-spacing: 2px;

  &:hover {
    color: #5384ff;
  }
}

.mechant_card {
  // width: 240px;
  margin-left: 16px;
  margin-bottom: 12px;
  display: inline-block;
}

.chosen {
  border: solid 2px #3089dc !important;
}

.category_info {
  display: flex;

  div:first-child {
    width: 80%;
  }

  div {
    flex: 1;
    // margin-top: 10px;

    .image-slot {
      width: 238px;
      height: 200px;
      padding: 20px 0px;
    }

    .merchant_name {
      line-height: 50px;
      height: 50px;
      width: 200px;
      font-size: 14px;
      margin: 0 auto;
      text-align: left;
      color: #616161;
    }

    .category_name {
      height: 50px;
      width: 160px;
      font-size: 14px;
      margin: 10px auto;
      text-align: left;
      color: #616161;
    }

    .merchant_manu {
      display: flex;
      margin-top: 10px;
      flex-wrap: wrap;
      cursor: pointer;

      span {
        display: inline-block;
        width: 100px;
        height: 38px;
        line-height: 38px;
        background: #ffffff;
        margin-left: 10px;
        margin-bottom: 10px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        color: #7ea3ff;
        font-size: 15px;
      }
    }
  }
}

.merchant_info {
  text-align: center;
  position: relative;

  .image-slot {
    width: 238px;
    height: 200px;
    padding: 20px 0px;
  }

  .merchant_name {
    line-height: 50px;
    height: 50px;
    width: 200px;
    font-size: 14px;
    margin: 0 auto;
    text-align: left;
    color: #616161;
  }

  .category_name {
    background-color: red;
    height: 50px;
    width: 200px;
    font-size: 14px;
    margin: 0 auto;
    text-align: left;
    color: #616161;
  }

  .merchant_price {
    line-height: 40px;
    height: 28px;
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    color: #616161;
  }

  .merchant_manu {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;
    cursor: pointer;

    span {
      display: inline-block;
      width: 100px;
      height: 38px;
      line-height: 38px;
      background: #ffffff;
      margin-left: 10px;
      margin-bottom: 10px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      color: #7ea3ff;
      font-size: 15px;
    }
  }
}

.unit-style {
  position: relative;

  span {
    position: absolute;
    right: -30px;
  }
}
</style>
