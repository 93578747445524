<!--
 * @Author: your name
 * @Date: 2022-04-14 12:14:26
 * @LastEditTime: 2022-06-23 11:19:15
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/view/login/forgetPwd/index.vue
-->
<template>
  <div class="forget-pwd-box">
    <div class="forget-pwd-bg"></div>
    <div class="forget-pwd-title" @click="$router.go(-1)">
      <el-icon :size="20" color="rgba(0, 0, 0, 0.25)">
        <arrow-left-bold />
      </el-icon>
      <span>忘记密码</span>
    </div>
    <div class="forget-pwd-form">
      <el-steps :active="stepNum" finish-status="success" simple class="step-num">
        <el-step title="发起验证" icon="iconfont icon-a-1-xuanzhong" />
        <el-step title="修改密码" icon="iconfont icon-a-2-weixuanzhong" />
        <el-step title="更改完成" icon="iconfont icon-a-3-weixuanzhong" />
      </el-steps>
      <div class="pwd-form" v-if="stepNum !== 2">
        <h2>发起验证</h2>
        <el-form
          :model="form"
          label-width="80px"
          label-position="left"
          ref="formRef"
          :rules="rules"
          :hide-required-asterisk="true"
        >
          <div v-if="stepNum === 0">
            <el-form-item label="手机号" prop="telephone">
              <el-input v-model="form.telephone" />
            </el-form-item>
            <el-form-item label="验证码">
              <el-row>
                <el-col :span="13">
                  <el-input v-model="form.code" />
                </el-col>
                <el-col :span="2"> </el-col>
                <el-col :span="6">
                  <el-button @click="send_captcha" plain :disabled="!codeData.canClick"
                    >{{ codeData.content }}
                  </el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </div>
          <div v-else-if="stepNum === 1">
            <el-form-item label="新密码" prop="password" label-width="100px">
              <el-input type="password" v-model="form.password" />
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword" label-width="100px">
              <el-input type="password" v-model="form.confirmPassword" />
            </el-form-item>
            <div style="margin: 20px 0px; text-align: left; color: red;">
              密码规则：6-16位，必须为字母和数字的组合
            </div>
          </div>
          <el-button type="primary" @click="onSubmit" class="next-btn"
            >{{ stepNum === 0 ? '下一步 >' : '确认 >' }}
          </el-button>
        </el-form>
        <div></div>
      </div>
      <div class="reset-success" v-if="stepNum === 2">
        <el-icon color="rgba(92, 184, 122, 1)" :size="22"> <circle-check /> </el-icon
        ><span>重置密码成功，请重新登录</span>
        <el-button type="primary" class="login-again" @click="$router.push('/login')">重新登录</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from 'element-plus'
import api from '../api/index'
import { async } from 'q'
const reg = /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/
const isTel = (rule, value, callback) => {
  if (!value) {
    callback(new Error('请输入手机号'))
  } else if (!reg.test(value)) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
}
export default {
  data() {
    return {
      form: {
        telephone: '',
        code: '',
        password: '',
        confirmPassword: '',
        delivery: false,
        type: [],
        resource: '',
        desc: '',
      },
      stepNum: 0,
      codeData: {
        content: '发送验证码',
        totalTime: 60,
        canClick: true, //添加canClick
      },
      rules: {
        telephone: [
          { required: this.stepNum === 0, message: '请输入手机号', trigger: 'blur' },
          { validator: isTel, trigger: 'blur' },
        ],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.form.password) {
                callback(new Error('两次密码输入不一致！'))
              } else {
                callback()
              }
            },
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {},
  methods: {
    send_captcha() {
      if (!this.codeData.canClick) return
      this.$refs.formRef.validate(async valid => {
        if (valid) {
          await api.sendSmsCode({ telephone: this.form.telephone, type: 3 }).then(res => {
            if (res.code === '0') {
              ElMessage.error(res.msg)
            }
          })
          this.codeData.canClick = false
          this.codeData.content = this.codeData.totalTime + 's后重新发送'
          let clock = window.setInterval(() => {
            this.codeData.totalTime--
            this.codeData.content = this.codeData.totalTime + 's后重新发送'
            if (this.codeData.totalTime < 0) {
              window.clearInterval(clock)
              this.codeData.content = '重新发送验证码'
              this.codeData.totalTime = 60
              this.codeData.canClick = true //这里重新开启
            }
          }, 1000)
        } else {
          console.log('error submit!')
          return false
        }
      })
    },
    onSubmit() {
      if (this.stepNum === 0) {
        api.verifySmsCodeNotDelCache({ smsCode: this.form.code, telephone: this.form.telephone }).then(res => {
          if (res.code === '200') {
            this.stepNum = 1
          } else {
            ElMessage.error(res.msg)
          }
        })
      } else if (this.stepNum === 1) {
        this.$refs.formRef.validate(valid => {
          if (valid) {
            api
              .forgetPassword({ password: this.form.password, smsCode: this.form.code, telephone: this.form.telephone })
              .then(res => {
                if (res.code == 200) {
                  this.stepNum = 2
                } else {
                  ElMessage.error(res.msg)
                }
              })
          } else {
            console.log('error submit!')
            return false
          }
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 87px !important;
  }
}
.forget-pwd-box {
  height: 100vh;
  text-align: left;
  overflow: hidden;

  .forget-pwd-bg {
    height: 200px;
    background: url('../../../assets/image/login/forget-pwd.png') no-repeat;
    background-size: 100% 100%;
  }

  .forget-pwd-title {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #222222;
    padding-left: 46px;
    height: 61px;
    line-height: 61px;
    position: relative;

    span {
      margin-left: 10px;
      vertical-align: super;
    }
  }

  .forget-pwd-form {
    width: 780px;
    margin: auto;

    .pwd-form {
      width: 390px;
      margin: auto;
      margin-top: 80px;

      h2 {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        margin-bottom: 23px;
      }

      span {
        display: inline-block;
        width: 90px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 4px;
        border: 1px solid #1989fa;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #1989fa;
      }

      .next-btn {
        width: 418px;
        height: 64px;
        background: #ffffff;
        border-radius: 8px;
        opacity: 0.65;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 60px;
      }
    }

    .reset-success {
      margin: auto;
      width: 420px;
      margin-top: 80px;
      margin-bottom: 240px;

      span {
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        vertical-align: super;
        margin-left: 12px;
      }

      .login-again {
        width: 418px;
        height: 64px;
        background: #1989fa !important;
        border-radius: 8px;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin: auto;
        margin-top: 240px;
      }
    }

    .step-num {
      ::v-deep .el-step__line-inner {
        border: 0;
      }
    }
  }
}
</style>
