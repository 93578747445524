<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-11-13 10:36:38
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/IndicatorCullingLog.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="queryWeightIndexLog" reportName="月支付账单" /> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :authMenus="authMenus"
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="emptyTableText"
      :getTableData="queryWeightIndexLog"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import moment from 'moment'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../service/index'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        endTime: moment(new Date())
          .add(1, 'M')
          .format('YYYY-MM-DD'),
        pageNum: 1,
        pageSize: 10,
        startTime: moment(new Date()).format('YYYY-MM-DD'),
        storeCodeList: [],
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'index',
          label: '序号',
        },
        {
          prop: 'time',
          label: '指标日期',
        },
        {
          prop: 'storeCode',
          label: '门店编号',
        },
        {
          prop: 'storeName',
          label: '门店名称',
        },
        {
          prop: 'createUser',
          label: '操作人',
        },
        {
          prop: 'createTime',
          label: '操作时间',
        },
        {
          button: true,
          label: '操作',
          name: '指标还原',
          authMenu: 'update',
          authPropName: 'code',
          vm: this,
          callback: function(data) {
            ElMessageBox.confirm('<p>确定要还原指标吗？<p>注意：指标还原后需要手动执行重新计算</p></p>', '指标还原', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              dangerouslyUseHTMLString: true,
            }).then(async () => {
              this.vm.restoreStoreWeightIndexLog(data)
            })
          },
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '操作时间',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'daterange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD',
          modelValue: [
            moment(new Date()).format('YYYY-MM-DD'),
            moment(new Date())
              .add(1, 'days')
              .format('YYYY-MM-DD'),
          ],
        },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async restoreStoreWeightIndexLog(id) {
      let res = await api.restoreStoreWeightIndexLog({ id })
      if (res.code == 200) {
        ElMessage.success('还原成功')
        this.queryWeightIndexLog(this.model)
      } else {
        ElMessage.error(res.msg)
      }
    },
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
    },
    queryWeightIndexLog(model) {
      this.$refs.search.fullscreenLoading = true
      api
        .queryWeightIndexLog(model)
        .then(res => {
          this.tableData = res.data.records?.map((a, index) => ({
            ...a,
            index: (this.model.pageNum - 1) * this.model.pageSize + index + 1,
            time: a.targetDay ? `${a.targetDay}(${a.targetWeeks})` : a.targetMonth,
          }))
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
          this.setEmptyDataText()
        })
        .finally(() => {
          this.$refs.search.fullscreenLoading = false
        })
    },
    parentSearch(param) {
      const { storeCodeList, regionCodeList } = param
      this.model = { storeCodeList, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.queryWeightIndexLog(this.model)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
