/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-07-25 11:07:44
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '门店管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/shop_manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '门店管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/shop_manager/shop_manager',
      filePath: 'plugin/shop-manager/view/shop_manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '权益金比例',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/shop_manager/equityRatio',
      filePath: 'plugin/shop-manager/view/equityRatio/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '门店分类',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/shop_manager/shop_type',
      filePath: 'plugin/shop-manager/view/shop_type/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '门店区域',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/shop_manager/shop_area',
      filePath: 'plugin/shop-manager/view/shop-area/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '客户来源管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/shop_manager/customer-source',
      filePath: 'plugin/shop-manager/view/customer-source-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '缴费记录',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/shop_manager/paymsg',
      filePath: 'plugin/shop-manager/view/payMsg/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
