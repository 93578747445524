<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-09-18 11:03:22
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/getBillMonthReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="getBillMonth" reportName="月支付账单" /> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="emptyTableText"
      :getTableData="getBillMonth"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import moment from 'moment'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        endTime: moment(new Date())
          .add(1, 'M')
          .format('YYYY-MM'),
        pageNum: 1,
        pageSize: 10,
        startTime: moment(new Date()).format('YYYY-MM'),
        storeCodeList: [],
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'storeName',
          label: '门店名称',
        },
        {
          prop: 'monthTime',
          label: '账单日期',
        },
        {
          prop: 'tradeCount',
          label: '交易笔数',
        },
        {
          prop: 'tradeAmount',
          label: '交易金额',
        },
        {
          prop: 'successTradeCount',
          label: '成功支付笔数',
        },
        {
          prop: 'successTradeAmount',
          label: '成功支付金额',
        },
        {
          prop: 'refundTradeCount',
          label: '退款笔数',
        },
        {
          prop: 'refundTradeAmount',
          label: '退款金额',
        },
        {
          prop: 'settledAmount',
          label: '结算金额',
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'daterange',
          isRange: true,
          dataFormate: 'YYYY-MM',
          modelValue: [
            moment(new Date()).format('YYYY-MM'),
            moment(new Date())
              .add(1, 'M')
              .format('YYYY-MM'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/export/billMonth', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.getBillMonth(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
    },
    getBillMonth(model) {
      this.mergeTotalData(model.pageNum, '月支付账单', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return Promise.reject('条件不满足')
      }
    },
    parentSearch(param) {
      const { storeCodeList, regionCodeList } = param
      this.model = { storeCodeList, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '月支付账单', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { storeCodeList, regionCodeList } = param
      this.model = { storeCodeList, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      this.exportData('月支付账单', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
