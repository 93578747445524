<template>
  <div v-loading.fullscreen.lock="data.fullscreenLoading" element-loading-text="正在火速加载数据...">
    <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
      <el-form-item label="营业时间">
        <el-date-picker
          value-format="YYYY-MM-DD HH:mm:ss"
          v-model="data.time"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :clearable="true"
          :default-time="data.defaultTime"
        />
      </el-form-item>
      <el-form-item label="门店区域">
        <el-cascader
          v-model="data.regionCodeList"
          :options="areaOptions"
          clearable
          filterable
          placeholder="请选择门店区域"
          @change="changeArea()"
          :show-all-levels="false"
          collapse-tags
          :props="areaProps"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="门店">
        <el-select-v2
          v-model="data.storeCodeList"
          :options="data.storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          style="width: 300px;"
          multiple
          filterable
          clearable
          collapse-tags
          collapse-tags-tooltip
        />
      </el-form-item>
      <el-form-item label="商品分类">
        <el-select v-model="data.goodsCategory" filterable placeholder="请选择商品分类" clearable>
          <el-option v-for="item in data.productTypes" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="仓库">
        <el-select v-model="data.houseName" filterable placeholder="请选择" clearable>
          <el-option v-for="item in data.houseNames" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="商品统计分类">
        <el-select v-model="data.statisticCode" filterable placeholder="请选择" clearable>
          <el-option v-for="item in data.statistics" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="场次号">
        <el-input placeholder="场次号" v-model="data.openCode" clearable></el-input>
      </el-form-item>
      <el-form-item label="账单号">
        <el-input placeholder="账单号" v-model="data.billOrderCode" clearable></el-input>
      </el-form-item>
      <el-form-item label="订单号">
        <el-input placeholder="订单号" v-model="data.orderCode" clearable></el-input>
      </el-form-item>
      <el-form-item label="包厢号">
        <el-input placeholder="包厢号" v-model="data.roomName" clearable></el-input>
      </el-form-item>
      <!-- <el-form-item label="单据号">
        <el-input placeholder="单据号" v-model="data.batchCode"></el-input>
      </el-form-item> -->
      <el-form-item label="商品名称">
        <el-input placeholder="商品名称" v-model="data.goodsName" clearable></el-input>
      </el-form-item>
      <el-form-item label="出入库类型">
        <el-select-v2
          v-model="data.operationTypeList"
          :options="data.operationTypes"
          placeholder="请选择出入库类型"
          class="search-select userSelect"
          style="width: 300px;"
          multiple
          clearable
          filterable
          collapse-tags
          collapse-tags-tooltip
        />
        <!-- <el-select v-model="data.operationType" filterable placeholder="请选择" clearable>
          <el-option v-for="item in data.operationTypes" :key="item.value" :label="item.label" :value="item.label" />
        </el-select> -->
      </el-form-item>

      <el-form-item>
        <el-button @click="searchLists" type="primary">
          查询
        </el-button>
        <el-button @click="resetData">
          重置
        </el-button>
        <el-button @click="exportData">
          导出
        </el-button>
      </el-form-item>
    </el-form>
    <el-table :data="data.tableData" border style="width: 96%; margin-left: 2%;" :emptyText="emptyTableText">
      <el-table-column width="180">
        <el-table-column width="180px" show-overflow-tooltip prop="storeName" label="门店名称"> </el-table-column>
        <!-- <el-table-column width="200px" show-overflow-tooltip prop="batchCode" label="单据号"> </el-table-column> -->
        <el-table-column width="140px" show-overflow-tooltip prop="statisticName" label="商品统计分类">
        </el-table-column>
        <el-table-column width="120px" show-overflow-tooltip prop="goodsCategory" label="商品分类"> </el-table-column>
        <el-table-column width="150px" show-overflow-tooltip prop="goodsName" label="商品名称"> </el-table-column>
        <el-table-column width="150px" show-overflow-tooltip prop="spec" label="规格"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="houseName" label="仓库"> </el-table-column>
        <el-table-column width="140px" show-overflow-tooltipl label="出入库类型" prop="operationType">
        </el-table-column>
        <el-table-column width="200px" show-overflow-tooltip prop="openCode" label="场次号"> </el-table-column>
        <el-table-column width="200px" show-overflow-tooltip prop="billOrderCode" label="账单号"> </el-table-column>
        <el-table-column width="200px" show-overflow-tooltip prop="orderCode" label="订单号"> </el-table-column>
        <el-table-column width="100px" show-overflow-tooltip prop="roomName" label="包厢号"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="count" label="数量"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="unit" label="单位"> </el-table-column>
      </el-table-column>

      <el-table-column label="库存变动前">
        <el-table-column show-overflow-tooltip prop="beforeAverageFree" label="滚动成本" width="120px">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="beforeStock" label="库存数量" width="120px"> </el-table-column>
      </el-table-column>

      <el-table-column label="库存变动后">
        <el-table-column show-overflow-tooltip prop="afterAverageFree" label="滚动成本" width="120px">
        </el-table-column>
        <el-table-column show-overflow-tooltip prop="afterStock" label="库存数量" width="120px"> </el-table-column>
      </el-table-column>

      <el-table-column>
        <el-table-column show-overflow-tooltip prop="inStoreName" label="入库门店"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="outStoreName" label="出库门店"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="userName" label="操作人"> </el-table-column>
        <el-table-column show-overflow-tooltip prop="agentName" label="经手人"> </el-table-column>
        <el-table-column width="180px" show-overflow-tooltip prop="createTime" label="出入库时间"> </el-table-column>
        <el-table-column width="140px" show-overflow-tooltip prop="description" label="备注"> </el-table-column>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="data.currentPage"
      v-model:page-size="data.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="data.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script setup>
import { downLoad } from '../../../../lin/util/util'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
import { reactive, onMounted, ref, nextTick } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
import clearSelect from '@/lin/util/clearSelect2.js'
import { post } from '@/lin/plugin/axios'
import { createMoveBall } from '@/utils/createMoveBall.js'

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const changeArea = () => {
  data.storeCodeList = []
  data.areaCode = data.regionCodeList.map(item => item[item.length - 1])
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
}

const props = defineProps({
  auths: {},
})
const data = reactive({
  storeCodeList: [],
  regionCodeList: [],
  areaCode: [],
  goodsName: '',
  goodsCategory: '',
  houseName: '',
  operationTypeList: [],
  statisticCode: '',
  openCode: '',
  billOrderCode: '',
  orderCode: '',
  roomName: '',
  time: [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ],
  defaultTime: new Date(2000, 1, 1, 8, 0, 0), // '12:00:00'
  authMenus: {},
  tableData: [],
  fullscreenLoading: false,
  storeList: [],
  productTypes: [],
  operationTypes: [],
  houseNames: [
    {
      label: '总仓库',
      value: '总仓库',
    },
    {
      label: '超市仓库',
      value: '超市仓',
    },
    {
      label: '存酒仓库',
      value: '存酒仓库',
    },
  ],
  statistics: [
    {
      label: '超市',
      value: 1,
    },
    {
      label: '水吧',
      value: 3,
    },
    {
      label: '厨房',
      value: 2,
    },
  ],
  pageSize: 10,
  currentPage: 1,
  total: 0,
})

const route = useRoute()

onMounted(async () => {
  route.meta.msg = props.auths
  operationAuth(route, data.authMenus)
  getStores()
  getProfuctType()
  getOpeartiontyps()
  // queryLists()
  getAreas()
})

const getStores = async () => {
  let res = await API.getStoreListByName({ list: data.areaCode })
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
}

/**
 * 获取商品类型
 */
const getProfuctType = () => {
  API.selectByType({
    pageSize: 100,
    current: 1,
    type: 1,
  }).then(res => {
    data.productTypes = res.data.records.map(item => {
      return {
        value: item.code,
        label: item.name,
      }
    })
  })
}

/**
 * 获取操作类型--出入库类型
 */
const getOpeartiontyps = () => {
  API.channelList().then(res => {
    data.operationTypes = res.data.map(item => {
      return {
        value: item.no,
        label: item.name,
      }
    })
  })
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.storeCodeList = []
  data.goodsName = ''
  data.goodsCategory = ''
  data.houseName = ''
  data.operationTypeList = []
  data.statisticCode = ''
  data.openCode = ''
  data.billOrderCode = ''
  data.orderCode = ''
  data.roomName = ''
  data.time = [
    dayjs()
      .subtract(1, 'day')
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
    dayjs()
      .set('hour', 8)
      .set('minute', 0)
      .set('second', 0)
      .format('YYYY-MM-DD HH:mm:ss'),
  ]
  data.regionCodeList = []
  data.areaCode = []
  data.tableData = []
  data.pageSize = 10
  data.pageNum = 1
  data.total = 0
  nextTick(() => {
    clearSelect('userSelect')
    getStores()
  })
  searchLists()
}

const exportData = async event => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  data.fullscreenLoading = true
  let model = {}
  model.stores = data.storeCodeList
  model.regions = data.areaCode
  model.goodsName = data.goodsName
  model.goodsCategory = data.goodsCategory
  model.houseName = data.houseName
  model.operationTypeList = data.operationTypeList
  model.statisticCode = data.statisticCode
  model.openCode = data.openCode
  model.billOrderCode = data.billOrderCode
  model.orderCode = data.orderCode
  model.roomName = data.roomName
  if (!data.time) {
    model.startTime = ''
    model.endTime = ''
  } else {
    model.startTime = data.time[0]
    model.endTime = data.time[1]
  }
  if (!model.openCode && !model.billOrderCode && !model.orderCode && !getDays(model.startTime, model.endTime)) {
    data.fullscreenLoading = false
    return
  }
  data.fullscreenLoading = true
  createMoveBall(event)
  const param = generateParam(model)
  let res = await post(`/shadow/export/download?type=PURCHASE_SALE_STOCK_DETAILS`, param).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    ElMessage.success('导出成功')
  } else {
    ElMessage.error('导出失败')
  }
}
const getDays = (sDate1, sDate2) => {
  if (!sDate1 || !sDate2) {
    ElMessage.warning('请设置查询时间')
    return false
  }

  const DAY = 32
  //sDate1和sDate2是2002-12-18格式
  var dateSpan, tempDate, iDays
  sDate1 = Date.parse(sDate1)
  sDate2 = Date.parse(sDate2)
  dateSpan = sDate2 - sDate1
  dateSpan = Math.abs(dateSpan)
  iDays = Math.floor(dateSpan / (24 * 3600 * 1000)) + 1
  if (iDays > DAY) {
    ElMessage.warning('查询或导出时间范围不能超过一个月')
    return false
  }
  return true
}
const emptyTableText = ref('请先设置查询条件，再点击查询数据')
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  let params = {
    stores: data.storeCodeList,
    regions: data.areaCode,
    goodsName: data.goodsName,
    goodsCategory: data.goodsCategory,
    houseName: data.houseName,
    operationTypeList: data.operationTypeList,
    statisticCode: data.statisticCode,
    openCode: data.openCode,
    billOrderCode: data.billOrderCode,
    orderCode: data.orderCode,
    roomName: data.roomName,
    pageNum: data.currentPage,
    pageSize: data.pageSize,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  if (!params.openCode && !params.billOrderCode && !params.orderCode && !getDays(params.startTime, params.endTime)) {
    return
  }

  params = generateParam(params)
  //占坑
  data.tableData = [{ name: '合计' }]

  getTableData(params, 'PURCHASE_SALE_STOCK_DETAILS')

  if (params.currentPage > 1) {
    return data.tableData.splice(0, 1, totalRowData.value)
  }
  getTotalRowRequest(params, 'PURCHASE_SALE_STOCK_DETAILS')
}

/**
 * @description: 生成请求参数
 * @param {*} type
 * @return {*}
 */
const generateParam = data => {
  let params = {
    pageNum: data.pageNum,
    pageSize: data.pageSize,
  }
  let model = _.cloneDeep(data)
  delete model.pageSize
  delete model.pageNum
  params.criteria = JSON.stringify(model)
  return params
}
const totalRowData = ref({})

// 获取报表数据
const getTableData = async (params, type) => {
  data.fullscreenLoading = true
  let res = await post(`/shadow/report/details?type=${type}`, params).finally(() => {
    data.fullscreenLoading = false
  })
  if (res.code == 200) {
    data.tableData.push(...(res.data.items ?? []))
    data.total = res.data.total
    emptyTableText.value = data.tableData.length == 0 ? '暂无数据' : ''
  } else {
    data.tableData = []
    data.total = 0
  }
}
/**
 * @description: 获取合计请求数据
 * @return {*}
 */
const getTotalRowRequest = async (params, type) => {
  let res = await post(`/shadow/report/summary?type=${type}`, params)
  if (res.code == 200) {
    totalRowData.value = res.data ?? {}
    data.tableData.splice(0, 1, totalRowData.value)
  } else {
    data.tableData.splice(0, 1)
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  searchLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  searchLists()
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 30px;
}
</style>
