<template>
  <div class="login">
    <div class="team-name hidden-sm-and-down"></div>
    <div class="title">
      <h1 title="Lin">星聚会综合服务管理平台</h1>
      <h2>Integrated Service Management Platform</h2>
    </div>
    <div class="form-box" v-loading="loading" element-loading-background="rgba(0, 0, 0, 0)">
      <h1>登录</h1>
      <form class="login-form" autocomplete="off">
        <div>
          <div class="form-item nickname">
            <span class="icon account-icon"></span>
            <input
              type="text"
              v-model="account.telephone"
              autocomplete="off"
              :placeholder="isLoginPwd ? '请输入登录手机/邮箱' : '请输入手机号'"
            />
          </div>
          <div class="form-item password" v-if="isLoginPwd">
            <span class="icon secret-icon"></span>
            <input type="password" v-model="account.password" autocomplete="off" placeholder="请输入登录密码" />
          </div>
          <div class="form-item code" v-else>
            <input type="text" v-model="account.smsCode" autocomplete="off" placeholder="请输入验证码" />
            <button style="cursor: pointer;" @click.prevent="send_captcha" plain :disabled="!codeData.canClick">
              {{ codeData.content }}
            </button>
          </div>
          <div class="form-item hint-content">
            <a @click="changeLoginType">{{ isLoginPwd ? '验证码登录' : '账号密码登录' }}</a>
            <a @click="forgetPwd">忘记密码？</a>
          </div>
        </div>
        <button class="submit-btn" @click="throttleLogin" type="button">登录</button>
      </form>
    </div>
  </div>
</template>

<script>
import { reactive, ref, onMounted, getCurrentInstance } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
// import UserModel from '@/lin/model/user'
import Utils from '@/lin/util/util'
import Config from '@/config'
import api from './api/index'
import homeRouter from '@/router/home-router.js'
import { setWaterMark } from '@/utils/waterMark.js'

export default {
  setup() {
    const wait = 1000 // 2000ms之内不能重复发起请求
    const loading = ref(false)
    const store = useStore()
    const router = useRouter()
    const throttleLogin = ref(null)
    const codeData = reactive({
      content: '发送验证码',
      totalTime: 60,
      canClick: true, //添加canClick
    })
    const { ctx } = getCurrentInstance()

    const account = reactive({
      telephone: '',
      password: '',
      smsCode: '',
    })

    const isLoginPwd = ref(true)

    /**
     * 根据账号密码登录，拿到 token 并储存
     */
    const successLogin = res => {
      loading.value = false
      if (res.code === '200') {
        store.dispatch('setUserAndState', res.data)
        localStorage.setItem('access_token', res.data.access_token)
        localStorage.setItem(
          'authMenuNodes',
          JSON.stringify(res.data.userInfo.authMenuNodes.find(item => item.menuName === '星平台')),
        )
        store.commit('SET_USER_PERMISSIONS', res.data)
        ElMessage({
          type: 'success',
          message: '登录成功',
        })
        let content =
          (res?.data?.username || '') +
          ' ' +
          (res?.data?.userInfo?.userSaasUserVO?.cellPhone?.substring(
            res?.data?.userInfo?.userSaasUserVO?.cellPhone?.length - 4,
          ) || '')

        setWaterMark('', content)

        const url = returnUrl(res.data.userInfo.authMenuNodes.find(item => item.menuName === '星平台').childrens)
        router.push(url)
      } else {
        ElMessage({
          type: 'error',
          message: res.msg,
        })
      }
    }
    // //设置水印
    // const setWaterMarkByUser = () => {
    //   setWaterMark('', msg || '')
    // }

    //窗口大小改变事件
    const handleWindowResize = callBack => {
      const debouce = (fn, dela) => {
        let timer
        return function() {
          if (timer) {
            clearTimeout(timer)
          }
          timer = setTimeout(() => {
            fn()
          }, 1000)
        }
      }
      const cancalDebounce = debouce(callBack, 500)
      window.addEventListener('resize', cancalDebounce)
    }

    const returnUrl = data => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].childrens.length !== 0) {
          return data[i].childrens[0].url
        }
      }
      return data.url
    }

    const login = async () => {
      loading.value = true
      try {
        if (isLoginPwd.value) {
          delete account.smsCode
          if (!account.telephone || !account.password) {
            ElMessage({
              type: 'error',
              message: !account.telephone ? '请输入手机号码' : '请输入密码',
            })
            loading.value = false
            return
          }
          const res = await api.passwordLogin(account)
          successLogin(res)
        } else {
          delete account.password
          if (!account.telephone || !account.smsCode) {
            ElMessage({
              type: 'error',
              message: !account.telephone ? '请输入手机号码' : '请输入验证码',
            })
            loading.value = false
            return
          }
          const res = await api.smsCodeLogin(account)
          successLogin(res)
        }
      } catch (e) {
        loading.value = false
      }
    }

    const changeLoginType = () => {
      isLoginPwd.value = !isLoginPwd.value
    }

    const forgetPwd = () => {
      console.log(router)
      router.push('/forgetPwd')
    }

    const send_captcha = async () => {
      let reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/
      if (!account.telephone) {
        ElMessage.error('请输入手机号')
        return
      }
      if (!reg.test(account.telephone)) {
        ElMessage.error('请输入正确的手机号')
        return
      }
      if (!codeData.canClick) return //改动的是这两行代码
      await api.sendSmsCode({ telephone: account.telephone, type: 1 }).then(res => {
        if (res.code === '0') {
          ElMessage.error(res.msg)
        } else {
          codeData.canClick = false
          codeData.content = codeData.totalTime + 's后重新发送'
          let clock = window.setInterval(() => {
            codeData.totalTime--
            codeData.content = codeData.totalTime + 's后重新发送'
            if (codeData.totalTime < 0) {
              window.clearInterval(clock)
              codeData.content = '重新发送验证码'
              codeData.totalTime = 60
              codeData.canClick = true //这里重新开启
            }
          }, 1000)
        }
      })
    }

    /**
     * 节流登录
     */
    onMounted(() => {
      throttleLogin.value = Utils.throttle(login, wait)
    })

    return {
      account,
      loading,
      codeData,
      throttleLogin,
      isLoginPwd,
      changeLoginType,
      forgetPwd,
      send_captcha,
    }
  },
}
</script>

<style lang="scss">
.login {
  width: 100%;
  height: 100%;
  background: url('../../assets/image/login/login-bg.png') no-repeat;
  background-size: cover;
  position: relative;

  .team-name {
    position: fixed;
    left: 40px;
    top: 50%;
    width: 50px;
    transform: translateY(-50%);
  }

  .title {
    position: absolute;
    top: 155px;
    left: 162px;
    text-align: left;

    h1 {
      font-size: 64px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      margin-bottom: 12px;
    }

    h2 {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.64);
    }
  }

  .form-box {
    position: fixed;
    top: 200px;
    right: 237px;
    width: 450px;
    height: 500px;
    background: #ffffff;
    border-radius: 24px;

    h1 {
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      margin-top: 40px;
      margin-bottom: 72px;
    }

    .login-form {
      width: 100%;

      .form-item {
        width: 100%;
        box-sizing: border-box;

        input {
          width: 100%;
          height: 100%;
          background: transparent;
          color: #999999;
          font-size: 14px;
          box-sizing: border-box;
          text-align: left;
          line-height: 64px;
          padding-left: 24px;
        }
      }

      .form-item.nickname,
      .form-item.password {
        width: 418px;
        height: 64px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #b1bfc8;
        margin: auto;
        margin-bottom: 32px;
      }

      .form-item.code {
        margin: auto;
        width: 418px;
        margin-bottom: 24px;

        input {
          display: inline-block;
          width: 266px;
          height: 64px;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #b1bfc8;
        }

        button {
          width: 128px;
          height: 64px;
          background: #ffffff;
          border-radius: 8px;
          border: 1px solid #1989fa;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #1989fa;
          float: right;
          margin-left: 24px;
        }
      }

      .form-item.hint-content {
        width: 418px;
        border-radius: 8px;
        margin: auto;
        display: flex;

        a {
          flex: 1;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }

        a:first-child {
          color: #2466f7;
          text-align: left;
        }

        a:last-child {
          color: #f66c6a;
          text-align: right;
        }
      }

      .submit-btn {
        width: 418px;
        z-index: 999;
        height: 64px;
        background: #1989fa;
        text-align: center;
        border-radius: 8px;
        font-size: 16px;
        border: none;
        font-size: 26px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 31px;
        cursor: pointer;
      }
    }
  }
}
</style>
