<template>
  <el-dialog v-model="visible" title="批量修改" width="70%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="form.specRowMsg"
      label-width="150px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <div style="margin-bottom:10px">
        <p style="margin-bottom:5px">
          <font color="red">*</font>温馨提示：
          请尽量避免在门店营业时间操作该功能，尽量避免多人同时操作同一商品，以免造成数据异常
        </p>
        <p>
          <font color="red">*</font>修改范围：即将修改1000条数据，使用该功能前，请使用筛选条件确认要修改的数据范围；涉及
          苏州中心店、苏州信投店...等100家门店
        </p>
      </div>
      <el-form-item label="必选商品" prop="requiredContents">
        <el-button @click="addShops(1)">添加商品</el-button>
        <el-card
          style=" margin-top: 10px; width: 85%;overflow-x: auto;"
          v-if="form.specRowMsg.requiredContents && form.specRowMsg.requiredContents.length !== 0"
        >
          <el-table :data="form.specRowMsg.requiredContents" style="width: 100%;max-height:400px">
            <el-table-column prop="name" label="商品名称" align="center" width="150" />
            <el-table-column label="商品图片">
              <template #default="scope" align="center">
                <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
              </template>
            </el-table-column>
            <el-table-column prop="spec" label="规格" />
            <el-table-column prop="price" label="指导价(元)" />
            <el-table-column prop="unit" label="最小单位" />
            <el-table-column label="数量" align="center" width="200">
              <template #default="scope">
                <el-input-number v-model="scope.row.count" :min="1" :max="1000" />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button type="text" @click="deleteShop(scope.row.code, 'requiredContents')">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-form-item>
      <el-form-item label="替换必选商品" prop="optionContentItem">
        <el-button @click="addShops(2)">添加商品</el-button>
        <el-card
          style=" margin-top: 10px; width: 85%;overflow-x: auto;"
          v-if="form.specRowMsg.optionContentItem && form.specRowMsg.optionContentItem.length !== 0"
        >
          <el-table :data="form.specRowMsg.optionContentItem" style="width: 100%;max-height:400px">
            <el-table-column prop="name" label="商品名称" align="center" width="150" />
            <el-table-column label="商品图片">
              <template #default="scope" align="center">
                <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
              </template>
            </el-table-column>
            <el-table-column prop="spec" label="规格" />
            <el-table-column prop="price" label="指导价(元)" />
            <el-table-column prop="unit" label="最小单位" />
            <el-table-column label="数量" align="center" width="200">
              <template #default="scope">
                <el-input-number v-model="scope.row.count" :min="1" :max="1000" />
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template #default="scope">
                <el-button type="text" @click="deleteShop(scope.row.code, 'optionContentItem')">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 10px;">
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">保存</el-button>
    </div>
    <SelectShops
      v-if="form.addProductVisible"
      :type="form.type"
      :dialogVisible="form.addProductVisible"
      @update-dialogVisible="updateAddProductVisible"
    ></SelectShops>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, toRaw, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import SelectShops from './SelectShops.vue'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
  },
  components: {
    SelectShops,
  },
  setup(props, ctx) {
    const form = reactive({
      specRowMsg: {
        requiredContents: [],
        optionContentItem: [],
      },
      loadText: '正在火速加载数据...',
      img1: [],
      searchShop: '',
      addProductVisible: false,
      type: 1, //1:表示必选 2:表示可选
      shopLists: [],
      loading: false,
    })

    const visible = computed(() => {
      return props.dialogVisible
    })

    const addShops = type => {
      form.type = type
      form.addProductVisible = true
    }

    const deleteOptionContentItem = (deleteOptionContents, item) => {
      let index = deleteOptionContents.findIndex(item1 => item1.code === item.code)
      deleteOptionContents.splice(index, 1)
    }

    const deleteShop = (item, type) => {
      let index = form.specRowMsg[type].findIndex(item1 => item1.code === item)
      form.specRowMsg[type].splice(index, 1)
    }

    const updateAddProductVisible = ({ update, selectSkus = [], isUpdate }) => {
      form.addProductVisible = update
      if (isUpdate) {
        if (form.type === 1) {
          selectSkus.forEach(item => {
            let ishasItem = form.specRowMsg.requiredContents.find(formItem => formItem.code === item.code)
            if (!ishasItem) {
              form.specRowMsg.requiredContents.push(item)
            }
          })
        } else {
          selectSkus.forEach(item => {
            let ishasItem = form.specRowMsg.optionContentItem.find(formItem => formItem.code === item.code)
            if (!ishasItem) {
              form.specRowMsg.optionContentItem.push(item)
            }
          })
        }
      }
    }

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const add = params => {
      API.createComboInfo(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const update = params => {
      API.updateComboInfo(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const checkProject = (rule, value, callback) => {
      if (props.specRowMsg.requiredContents.length === 0 && props.specRowMsg.optionContents.length === 0) {
        return callback(new Error('必选商品和可选方案组不能同时为空'))
      } else {
        callback()
      }
    }

    const rules = reactive({
      // price2: [{ required: true, trigger: 'blur', validator: checkprice }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loadText = '正在火速处理数据...'
          form.loading = true
          if (Reflect.has(props.specRowMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    return {
      form,
      handleClose,
      backtrack,
      visible,
      rules,
      elForm,
      updateAddProductVisible,
      handleSubmit,
      addShops,
      deleteShop,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
::v-deep .el-input-number {
  width: 80%;
}
</style>
