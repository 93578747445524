<!--赠送明细-->

<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
    />
    <Table
      :emptyText="'暂无数据'"
      :data="tableData"
      :columns="columns"
      :total="totalRecords"
      :model="model"
      :getTableData="reportGoodsSell"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'
import { ElMessage } from 'element-plus'

export default {
  props: {
    auths: Object,
  },
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 500,
        storeCode: '',
        type: '',
        skuName: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'type',
          label: '场次号',
        },
        {
          prop: 'skuName',
          label: '账单号',
        },
        {
          prop: 'unit',
          label: '包厢',
        },
        {
          prop: 'price',
          label: '结账时间',
        },
        {
          prop: 'count',
          label: '赠送类型',
        },
        {
          prop: 'aloneSellNum',
          label: '赠送方式',
        },
        {
          prop: 'comboSellNum',
          label: '商品',
        },
        {
          prop: 'aloneGiftNum',
          label: '商品类型',
        },
        {
          prop: 'comboGiftNum',
          label: '商品数量',
        },
        {
          prop: 'receivableFee',
          label: '赠送金额',
        },
        {
          prop: 'actualFee',
          label: '赠送时长(分钟)',
          width: '120px',
        },
        {
          prop: 'netIncomeFee',
          label: '赠送人',
        },
        {
          prop: 'stockPrice',
          label: '销售员',
        },
      ],
      searchform: [
        {
          label: '营业日期',
          type: 'date-picker',
          labelWidth: '80px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店',
          type: 'select-v2',
          labelWidth: '50px',
          prop: 'storeCode',
          options: [],
          filterable: 'filterable',
        },
        { label: '账单号', type: 'input', prop: 'skuName', placeholder: '账单号' },
        { label: '场次号', type: 'input', prop: 'skuName', placeholder: '场次号' },
        { label: '赠送类型', type: 'select', typeProp: 'select', children: 'option', prop: 'type', options: [] },
        { label: '赠送人', type: 'select', typeProp: 'select', children: 'option', prop: 'type', options: [] },
        { label: '商品', type: 'input', prop: 'skuName', placeholder: '商品' },
        { label: '销售员', type: 'select', typeProp: 'select', children: 'option', prop: 'type', options: [] },
        { label: '导出', type: 'exportButton', url: '/report/export/reportGoodsSell', auth: 'export' },
      ],
    }
  },
  async mounted() {
    this.$route.meta.msg = this.auths
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      await this.getStoreList()
      // this.reportGoodsSell(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    reportGoodsSell(model) {
      api.reportGoodsSell(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })

      this.searchform[1].modelValue = res.data[0]?.code
      this.model.storeCode = res.data[0]?.code

      //赠送类型
      this.searchform[4].options = [
        {
          value: '1',
          label: '商品赠送',
        },
        {
          value: '2',
          label: '时长赠送',
        },
      ]

      //赠送人
      this.searchform[5].options = [
        {
          value: '1',
          label: '章三',
        },
        {
          value: '2',
          label: '李四',
        },
      ]

      //销售员
      this.searchform[7].options = [
        {
          value: '1',
          label: '章三',
        },
        {
          value: '2',
          label: '李四',
        },
      ]
    },
    parentSearch(param) {
      const { type, skuName, storeCode } = param
      this.model = { type, skuName, storeCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.reportGoodsSell(this.model)
    },

    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { type, skuName, storeCode } = param
      this.model = { type, skuName, storeCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '赠送明细.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;
}
</style>
