<!--
 * @Author: your name
 * @Date: 2022-04-20 13:47:49
 * @LastEditTime: 2022-12-15 10:10:29
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/component/pagination的副本.vue
-->
<template>
  <div>
    <el-pagination
      v-model:currentPage="model.pageNum"
      :page-sizes="[10, 20, 30, 40, 50]"
      :page-size="model.pageSize"
      :small="small"
      :page-count="totalPage"
      :disabled="disabled"
      class="pagination-style"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalRecords"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script>
export default {
  props: {
    totalRecords: {
      type: Number,
      default: null,
    },
    totalPage: {
      type: Number,
      default: null,
    },
    model: {
      type: Object,
      default: {},
    },
    getTableData: {
      type: Function,
      default: null,
    },
  },
  data() {},
  methods: {
    handleSizeChange(size) {
      this.model.pageSize = size
      this.getTableData(this.model)
    },
    handleCurrentChange(val) {
      this.model.pageNum = val
      if (this.totalPage >= 1) {
        this.getTableData(this.model)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.pagination-style {
  // text-align: right;
  position: fixed;
  bottom: 60px;
  right: 60px;
  // padding: 20px 0px;
}
</style>
