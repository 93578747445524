<template>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;margin-top: 10px;">
    <el-form-item label="营业时间">
      <el-date-picker
        value-format="YYYY-MM-DD"
        v-model="data.time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :clearable="true"
      />
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        v-model="data.storeCode"
        :options="data.storeList"
        placeholder="请选择门店"
        class="search-select userSelect"
        style="width: 250px;"
        filterable
      />
    </el-form-item>
    <el-form-item label="班次">
      <el-select v-model="data.banci" placeholder="请选择" clearable>
        <el-option lable="全部" value="">全部</el-option>
        <el-option lable="早" value="早" />
        <el-option lable="中" value="中" />
        <el-option lable="晚" value="晚" />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-button @click="searchLists" type="primary">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
      <el-button @click="exportData">
        导出
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    :span-method="objectSpanMethod"
    border
    style="width: 96%; margin-left: 2%;"
    :emptyText="data.storeCode ? '暂无数据' : '请选择门店后查询数据'"
  >
    <el-table-column width="180">
      <el-table-column show-overflow-tooltip prop="banci" label="班次开台统计" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="date" label="日期" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="week" label="星期" width="120"> </el-table-column>
    </el-table-column>
    <el-table-column label="小包厢(6件)">
      <el-table-column show-overflow-tooltip prop="goodsReceiveMoney" label="预订数" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsActualMoney" label="预订开台率" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="开台数" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="开台率" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="开台时长(分钟)" width="120">
      </el-table-column>
    </el-table-column>
    <el-table-column label="中包厢(6件)">
      <el-table-column show-overflow-tooltip prop="goodsReceiveMoney" label="预订数" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsActualMoney" label="预订开台率" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="开台数" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="开台率" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="开台时长(分钟)" width="120">
      </el-table-column>
    </el-table-column>
    <el-table-column label="合计(15间)">
      <el-table-column show-overflow-tooltip prop="custReceiveMoney" label="应收" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="custActualMoney" label="实收" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="custJingMoney" label="净收" width="120"> </el-table-column>
      <el-table-column show-overflow-tooltip prop="goodsJingMoney" label="优惠" width="120"> </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script setup>
import dayjs from 'dayjs'
import { downLoad } from '../../../../../lin/util/util'
import API from '../../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
const data = reactive({
  storeCode: '',
  banci: '',
  time: ['', ''],
  authMenus: {},
  tableData: [],
  loading: false,
  storeList: [],
})

const route = useRoute()

onMounted(async () => {
  operationAuth(route, data.authMenus)
  resetDate()
  await getStores()
  queryLists()
})

//重置时间
const resetDate = () => {
  data.time = [
    dayjs(new Date().getTime() - 86400000).format('YYYY-MM-DD'),
    dayjs(new Date().getTime() - 86400000).format('YYYY-MM-DD'),
  ]
}

const getStores = async () => {
  let res = await API.getStoreListByName({ storeName: data.storeCode })
  //门店接口
  data.storeList = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })
  data.storeCode = data.storeList[0].value
}

const searchLists = () => {
  queryLists()
}
const resetData = () => {
  data.banci = ''
  data.storeCode = ''
  resetDate()
  searchLists()
}

const exportData = async () => {
  if (!data.authMenus.export) {
    ElMessage.warning('抱歉，你没有导出权限')
    return
  }
  let model = {}
  model.startTime = data.time[0]
  model.endTime = data.time[1]
  model.storeCode = data.storeCode
  model.banci = data.banci
  let url = '/report/banci/exportBanciReport'
  const res = await API.exportReport({ url, data: model })
  downLoad(res, '班次营业情况.xlsx')
}

const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  if (!data.storeCode) {
    ElMessage.warning('请选择门店后查询数据')
    return
  }
  data.loading = true
  let params = {
    banci: data.banci,
    storeCode: data.storeCode,
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  API.banciReport(params)
    .then(res => {
      if (res.code === '200') {
        data.tableData = res.data
        data.total = res.data.length
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}

const objectSpanMethod = ({ row, column, rowIndex, columnIndex }) => {
  let splitRowIndex = data.total / (data.banci ? 1 : 4)
  if (columnIndex === 0) {
    if (rowIndex % splitRowIndex === 0) {
      return {
        rowspan: splitRowIndex,
        colspan: 1,
      }
    } else {
      return {
        rowspan: 0,
        colspan: 0,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-table__empty-block {
  width: 98vw !important;
}
</style>
