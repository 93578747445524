<template>
  <el-tabs v-model="data.activeName" class="demo-tabs" @tab-click="changeTab">
    <el-tab-pane label="商品入库" :name="1"> </el-tab-pane>
    <el-tab-pane label="商品出库" :name="2"> </el-tab-pane>
    <el-tab-pane label="易耗品入库" :name="3"> </el-tab-pane>
    <el-tab-pane label="易耗品出库" :name="4"> </el-tab-pane>
  </el-tabs>
  <el-form :inline="true" :model="data" style="width: 96%; margin-left: 2%;">
    <el-form-item label="商品名称">
      <el-input
        placeholder="请输商品名称"
        v-model="data.productName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店编号">
      <el-input
        placeholder="请输门店编号"
        v-model="data.storeCode"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="门店名称">
      <el-input
        placeholder="请输门店名称"
        v-model="data.storeName"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item label="备注信息">
      <el-input
        placeholder="请输入备注信息"
        v-model="data.description"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-select v-model="data.channel" class="m-2" placeholder="请选择出入库类型" @change="searchLists" clearable>
        <el-option v-for="item in data.channels" :key="item.no" :label="item.name" :value="item.no" />
      </el-select>
    </el-form-item>

    <el-form-item>
      <el-date-picker
        @change="searchLists"
        value-format="YYYY-MM-DD"
        v-model="data.time"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结算日期"
      />
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">
        查询
      </el-button>
      <el-button @click="resetData">
        重置
      </el-button>
      <el-button @click="exportData" v-if="data.authMenus.export">
        导出
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column label="商品图片" prop="pic" align="center">
      <template #default="scope">
        <div style="display: flex; justify-content: center;">
          <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
        </div>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip label="商品名称" prop="productName" align="center" />
    <el-table-column show-overflow-tooltip label="商品规格" prop="spec" align="center" />
    <el-table-column show-overflow-tooltip label="成本(元)" prop="inFree" align="center" />
    <el-table-column
      :label="data.activeName == 1 || data.activeName == 3 ? '入库数量' : '出库数量'"
      prop="inCount"
      align="center"
    />
    <el-table-column show-overflow-tooltip label="最小单位" prop="unit" align="center" />
    <el-table-column
      :label="data.activeName == 1 || data.activeName == 3 ? '入库类型' : '出库类型'"
      prop="accessName"
      align="center"
    />
    <el-table-column show-overflow-tooltip label="仓库" prop="houseName" align="center" />
    <el-table-column show-overflow-tooltip label="门店编号" prop="storeCode" align="center" />
    <el-table-column show-overflow-tooltip label="门店名称" prop="storeName" align="center" />
    <el-table-column show-overflow-tooltip label="备注信息" prop="description" align="center" />
    <el-table-column show-overflow-tooltip label="操作人" prop="userName" align="center" />
    <el-table-column show-overflow-tooltip label="操作时间" prop="createTime" align="center" />
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
</template>

<script setup>
import { downLoad } from '../../../../lin/util/util'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
const data = reactive({
  productName: undefined,
  storeName: undefined,
  storeCode: undefined,
  description: undefined,

  time: [],
  activeName: 1,
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
  channels: [],
  channel: '',
  type: 1,
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
  selectChannel(1)
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
const resetData = () => {
  data.storeCode = undefined
  data.storeName = undefined
  data.productName = undefined
  data.description = undefined
  data.channel = ''
  searchLists()
}

const changeTab = () => {
  data.channel = ''
  if (data.activeName == 1 || data.activeName == 3) {
    data.type = 1
    selectChannel(1)
  } else {
    data.type = 2
    selectChannel(2)
  }
  searchLists()
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  let params = {
    storeCode: data.storeCode,
    productName: data.productName,
    storeName: data.storeName,
    current: data.currentPage,
    pageSize: data.pageSize,
    accessCode: data.channel,
    description: data.description,
  }
  if (data.activeName == 1) {
    params.type = 1
    params.batchType = 1
  } else if (data.activeName == 2) {
    params.type = 1
    params.batchType = 2
  } else if (data.activeName == 3) {
    params.type = 4
    params.batchType = 1
  } else {
    params.type = 4
    params.batchType = 2
  }

  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  API.selectBatchPagePC(params)
    .then(res => {
      if (res.code === '200') {
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => {
      data.loading = false
    })
}

const selectChannel = type => {
  let params = {
    current: 0,
    pageSize: 100,
    type,
  }
  API.selectChannel(params).then(res => {
    data.channels = res.data.records
  })
}

const exportData = async () => {
  let params = {
    storeCode: data.storeCode,
    productName: data.productName,
    storeName: data.storeName,
    current: data.currentPage,
    pageSize: data.pageSize,
    accessCode: data.channel,
    description: data.description,
  }
  if (data.activeName == 1) {
    params.type = 1
    params.batchType = 1
  } else if (data.activeName == 2) {
    params.type = 1
    params.batchType = 2
  } else if (data.activeName == 3) {
    params.type = 4
    params.batchType = 1
  } else {
    params.type = 4
    params.batchType = 2
  }
  if (!data.time) {
    params.startTime = ''
    params.endTime = ''
  } else {
    params.startTime = data.time[0]
    params.endTime = data.time[1]
  }
  let url = '/adminChain/goods/saas/export/selectBatchPagePC'
  const res = await API.exportReport({ url, data: params })
  downLoad(res, '商品出入库记录.xlsx')
}
//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
