// 全局获取导出枚举字段接口
import _axios, { post, get, put } from '@/lin/plugin/axios'

class EnmuApi {
  async getEnmuList() {
    const res = await post('/reportDownloadCenter/reportDict/queryReportDict')
    return res
  }
}

export default new EnmuApi()
