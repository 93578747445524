<!-- 优惠券领取明细 -->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="emptyTableText"
      :getTableData="reportRoomIncomeDetail"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '@/component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { ElMessage } from 'element-plus'

export default {
  components: { Table, tableSearchTab },
  props: {
    auths: Object,
  },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        storeCodeList: [],
        ruleStartType: '',
        couponName: '',
        userName: '',
        telephone: '',
        state: '',
        couponRuleCode: '',
        couponCode: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      couponRuleTypes: {
        1: '折扣',
        2: '抵金额',
        3: '抵时长',
        4: '优惠价',
      },
      couponRuleSchemes: {
        1: '%',
        2: '元',
        3: '分钟',
        4: '元',
      },
      enmuType: {
        1: '开卡赠送',
        2: '升级赠券',
        3: '生日赠券',
        4: '每月赠券',
        5: '充值套餐赠券',
        6: '其他',
        7: '小游戏',
        8: '发券助手',
        10: '企微拉新',
        11: '企微入群',
        12: '企微群活跃',
        20: '积分兑换',
        21: '领券中心',
      },
      columns: [
        {
          prop: 'storeCode',
          label: '门店编号',
          width: '100px',
        },
        {
          prop: 'storeName',
          label: '开卡门店名称',
          width: '180px',
        },
        {
          prop: 'couponCode',
          label: '优惠券号',
          width: '180px',
        },
        {
          prop: 'couponRuleCode',
          label: '优惠券id',
          width: '180px',
        },
        {
          prop: 'couponName',
          label: '优惠券名称',
          width: '180px',
        },
        {
          prop: 'userName',
          label: '客户姓名',
          width: '130px',
        },
        {
          prop: 'telephone',
          label: '客户手机号',
          width: '180px',
        },
        {
          prop: 'sendTime',
          label: '券获得时间',
          width: '180px',
        },
        {
          prop: 'ruleStartType',
          label: '券来源',
          width: '180px',
        },
        {
          label: '优惠方案',
          width: '180px',
          formatter: row => {
            return this.couponRuleTypes[row.couponRuleType]
          },
        },
        {
          prop: 'couponRuleScheme',
          label: '优惠值',
          width: '120px',
        },
        {
          prop: 'usedTimeRange',
          label: '有效期',
          width: '230px',
        },
        {
          prop: 'state',
          label: '使用状态',
          width: '180px',
          formatter: row => {
            const enmu = {
              1: '未使用',
              2: '已使用',
              3: '已过期',
            }
            return enmu[row.state]
          },
        },
        {
          prop: 'usedTime',
          label: '使用时间',
          width: '180px',
        },
      ],
      pagination: {},
      searchform: [
        {
          label: '营业日期',
          type: 'date-picker',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '券来源',
          prop: 'ruleStartType',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          placeholder: '请选择券来源',
          options: [
            {
              label: '开卡赠送',
              value: 1,
            },
            {
              label: '升级赠券',
              value: 2,
            },
            {
              label: '生日赠券',
              value: 3,
            },
            {
              label: '每月赠券',
              value: 4,
            },
            {
              label: '充值套餐赠券',
              value: 5,
            },
            {
              label: '其他',
              value: 6,
            },
            {
              label: '小游戏',
              value: 7,
            },
            {
              label: '发券助手',
              value: 8,
            },
            {
              label: '企微拉新',
              value: 10,
            },
            {
              label: '企微入群',
              value: 11,
            },
            {
              label: '企微群活跃',
              value: 12,
            },
            {
              label: '积分兑换',
              value: 20,
            },
            {
              label: '领券中心',
              value: 21,
            },
            {
              label: '营销活动',
              value: 22,
            },
          ],
        },
        { label: '优惠券名称', type: 'input', prop: 'couponName', placeholder: '请输入优惠券名称' },
        { label: '客户姓名', type: 'input', prop: 'userName', placeholder: '请输入客户姓名' },
        { label: '客户手机号', type: 'input', prop: 'telephone', placeholder: '请输入客户手机号' },
        {
          label: '使用状态',
          prop: 'state',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          placeholder: '请输入使用状态',
          options: [
            { label: '未使用', value: 1 },
            { label: '已使用', value: 2 },
            { label: '已过期', value: 3 },
          ],
        },
        { label: '优惠券号', type: 'input', prop: 'couponCode', placeholder: '请输入优惠券号' },
        { label: '优惠券id', type: 'input', prop: 'couponRuleCode', placeholder: '请输入优惠券id' },
        { label: '导出', type: 'exportButton', url: '/report/coupon/reprotNewCouponGetDetails', auth: 'export' },
      ],
    }
  },
  async mounted() {
    this.$route.meta.msg = this.auths
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      await this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    reportRoomIncomeDetail(model) {
      this.mergeTotalData(
        model.pageNum,
        '优惠券领取报表',
        () => {
          return this.verifyModel(model)
        },
        true,
      )
    },
    verifyModel(model) {
      if (!model.telephone && !this.getDays(model.startTime, model.endTime)) {
        return Promise.reject('条件不满足')
      }
    },
    parentSearch(param) {
      const { storeCodeList, ruleStartType, couponName, userName, telephone, state, couponRuleCode, couponCode } = param
      this.model = {
        storeCodeList,
        ruleStartType,
        couponName,
        userName,
        telephone,
        state,
        couponRuleCode,
        couponCode,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(
        this.model.pageNum,
        '优惠券领取报表',
        () => {
          return this.verifyModel(this.model)
        },
        true,
      )
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { storeCodeList, ruleStartType, couponName, userName, telephone, state, couponRuleCode, couponCode } = param
      this.model = {
        storeCodeList,
        ruleStartType,
        couponName,
        userName,
        telephone,
        state,
        couponRuleCode,
        couponCode,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!this.model.telephone && !this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      this.exportData('优惠券领取报表', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
