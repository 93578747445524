<template>
  <el-tabs v-model="activeName" type="card" class="demo-tabs">
    <el-tab-pane label="入库" name="入库">
      <InType />
    </el-tab-pane>
    <el-tab-pane label="出库" name="出库">
      <OutType />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import { ref } from 'vue'
import OutType from './OutType.vue'
import InType from './InType.vue'

const activeName = ref('入库')
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;
  justify-content: flex-end;

  .search-right {
    margin-right: 20px;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
