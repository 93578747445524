<template>
  <el-dialog
    v-model="visible"
    width="40%"
    :before-close="handleClose"
    :title="'id' in proRowMsg ? '编辑歌曲' : '新增歌曲'"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="proRowMsg"
      label-width="120px"
      :rules="rules"
      ref="elForm"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="歌曲分类" prop="menusCode">
        <el-select v-model="proRowMsg.menusCode" filterable>
          <el-option :label="item.menuName" :value="item.menuCode" v-for="item in menus" />
        </el-select>
      </el-form-item>
      <el-form-item label="歌曲名称" prop="name">
        <el-input v-model="proRowMsg.name" style="width: 70%;" placeholder="请填写歌曲名称" />
      </el-form-item>
      <el-form-item label="雷石歌曲ID" prop="tsCode">
        <el-input v-model="proRowMsg.tsCode" style="width: 70%;" placeholder="请填写雷石歌曲ID" />
      </el-form-item>
      <el-form-item label="歌曲封面" prop="pic">
        <el-upload
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage"
        >
          <img v-if="proRowMsg.pic" :src="proRowMsg.pic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="是否推荐" prop="isRecommend">
        <el-checkbox v-model="proRowMsg.isRecommend" :true-label="1" :false-label="0">推荐歌曲</el-checkbox>
      </el-form-item>
      <el-form-item label="排序" prop="sort">
        <el-input-number v-model="proRowMsg.sort" style="width: 70%;" :controls="false" />
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { defineComponent, computed, ref, reactive } from 'vue'
import API from '../../service/api'
import { ElMessage } from 'element-plus'

const DIR = 'song/'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    proRowMsg: Object,
    menus: Array,
    version: Number,
  },
  setup(props, ctx) {
    const visible = computed(() => {
      return props.dialogVisible
    })
    const form = reactive({
      loadText: '正在火速加载数据...',
      loading: false,
      img: [],
    })

    const changeImage = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      // form.loadText = '正在火速上传图片...'
      // form.loading = true
      let filesSplit = file.file.name.split('.')
      let fileName =
        file.file.name.substring(0, file.file.name.length - filesSplit[filesSplit.length - 1].length - 1) +
        '_' +
        Date.now() +
        '.' +
        filesSplit[filesSplit.length - 1]
      client()
        .put(DIR + fileName, file.file, { headers })
        .then(res => {
          props.proRowMsg.pic = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }

    const rules = reactive({
      name: [{ required: true, message: '请填写歌曲名称', trigger: 'blur' }],
      pic: [{ required: true, message: '请上传商品图片', trigger: 'blur' }],
      menusCode: [{ required: true, message: '请选择歌曲分类', trigger: 'blur' }],
      tsCode: [{ required: true, message: '请填写雷石歌曲ID', trigger: 'blur' }],
      sort: [{ required: true, message: '请填写排序', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let saveData = {
            ...props.proRowMsg,
            version: props.version,
          }
          updateSpuInfo(saveData)
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    //修改数据
    const updateSpuInfo = data => {
      API.updateOrSaveByVodSong(data)
        .then(res => {
          if (Number(res.code) === 200) {
            ElMessage.success('操作成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      elForm,
      rules,
      changeImage,
      form,
      handleSubmit,
    }
  },
})
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
