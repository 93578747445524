<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-31 17:10:55
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-09-02 15:29:46
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/modifyGoods.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="modify-goods-content">
    <GoBack />
    <div class="binding-goods">
      <BindingGoods
        :categoryListWithMatterList="categoryListWithMatterList"
        :supplierCode="$route.query.id"
        :supplierName="$route.query.name"
        bindType="edit"
      />
    </div>
  </div>
</template>

<script>
import GoBack from '../../../../component/base/backHeader/goBack.vue'
import BindingGoods from './bindingGoods.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../service/index'
import _ from 'lodash'
export default {
  components: { GoBack, BindingGoods },
  data() {
    return {
      categoryListWithMatterList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      if (!this.$route.query.type) return
      api.getCategoryListWithMatterList({ supplierCode: this.$route.query.id }).then(res => {
        this.categoryListWithMatterList = res.data
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.modify-goods-content {
  max-height: 875px;

  .binding-goods {
    width: 1568px;
    max-height: 875px;
    overflow: auto;
    background: #ffffff;
    border-radius: 8px;
    margin: auto;
    box-sizing: border-box;
    padding: 24px 40px 24px 40px;
    position: relative;
  }
}
</style>
