<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="进销存明细" name="getMaterialStockTraceAuth" v-if="isShowReport('getMaterialStockTraceAuth')">
      <get-material-stock-trace-report :auths="authType.getMaterialStockTraceAuth" />
    </el-tab-pane>
    <el-tab-pane label="进销存统计" name="salesStatisticsAuth" v-if="isShowReport('salesStatisticsAuth')">
      <sales-statistics-report :auths="authType.salesStatisticsAuth" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import getMaterialStockTraceReport from '../report_module/getMaterialStockTraceReport.vue'
import salesStatisticsReport from '../report_module/salesStatisticsReport.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  getMaterialStockTraceAuth: {}, // 进销存明细
  salesStatisticsAuth: {}, // 进销存统计
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.getMaterialStockTraceAuth = authAllMenus.find(a => a.url === 'getMaterialStockTraceAuth') || {
    childrens: [],
  }
  authType.salesStatisticsAuth = authAllMenus.find(a => a.url === 'salesStatisticsAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'getMaterialStockTraceAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
::v-deep .el-form-item__content {
  display: flex;
}
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}

::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
</style>
