<template>
  <div class="member-card-level">
    <el-form :inline="true" :model="searchForm">
      <el-form-item label="等级名称">
        <el-input placeholder="等级名称" v-model="searchForm.levelName"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryData" v-if="authMenus.query">查询</el-button>
        <el-button @click="resetSearchForm">重置</el-button>
        <el-button @click="addMemberCardLevel" v-if="authMenus.add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column align="center" label="会员等级编号" prop="level" />
      <el-table-column align="center" label="会员卡等级名称" prop="name" />
      <el-table-column align="center" label="包厢折扣" prop="roomDiscount" />
      <el-table-column align="center" label="商品折扣" prop="goodsDiscount" />
      <el-table-column align="center" label="操作">
        <template v-slot="{ row }">
          <el-button type="text" @click="updateMemberCardLevel(row)" v-if="authMenus.update">编辑</el-button>
          <el-button type="text" @click="deleteMemberCardLevel(row)" v-if="row.isDefault !== 1 && authMenus.delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="searchForm.pageNum"
      v-model:page-size="searchForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchForm.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdateMemberCarLevel
    v-if="dialogVisible"
    :needUpdateMemberCardInfo="needUpdateMemberCardInfo"
    @handleCloseDialog="handleCloseOrOpenDialog"
  ></addOrUpdateMemberCarLevel>
</template>

<script setup>
import addOrUpdateMemberCarLevel from './add-or-update-member-car-level.vue'
import { reactive, ref, onMounted } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/index'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'

/***搜索条件 */
const searchForm = reactive({
  levelName: '',
  pageSize: 10,
  pageNum: 1,
  total: 0,
})
/**
 * 重置搜索表单
 */
const resetSearchForm = () => {
  searchForm.levelName = ''
  searchForm.pageSize = 10
  searchForm.pageNum = 1
  queryData()
}

const authMenus = ref({})
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  queryData()
})

/**table数据 */
const tableData = ref([])

/**
 * 查询table数据
 */
const queryData = async () => {
  if (authMenus.value.query) {
    let res = await API.queryLevels(searchForm)
    if (res.code === '200') {
      tableData.value = res.data.records
      searchForm.total = res.data.totalRecords
    } else {
      ElMessage.error(res.message)
    }
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

/** 需要更新的会员卡数据---dialog展示框展示*/
const needUpdateMemberCardInfo = ref({})
const dialogVisible = ref(false)
const cardTypes = {
  1: {
    name: '开卡赠送',
    propName: 'upCoupons',
  },
  2: {
    name: '升级赠券',
    propName: 'upCoupons',
  },
  3: {
    name: '生日送券',
    propName: 'birthdayCoupons',
  },
  4: {
    name: '每月赠券',
    propName: 'monthCoupons',
  },
  5: {
    name: '充值套餐赠券',
  },
  6: {
    name: '用户营销',
  },
}
/**
 * 编辑当前行数据
 * @param {*} memberCard  当前会员卡等级
 */
const updateMemberCardLevel = async memberCard => {
  let { data } = await API.getLevelDetail({ code: memberCard.code })
  needUpdateMemberCardInfo.value = {
    ...data,
    termOfValidity: data.effectiveYears === -1 ? 1 : 2,
    effectiveYears: data.effectiveYears === -1 ? '' : data.effectiveYears,
  }
  //加入不同类型的卡卷
  data.couponSendRuleSetDetailsVOList?.forEach(item => {
    let propName = cardTypes[item.ruleStartType].propName
    needUpdateMemberCardInfo.value[propName] =
      item.queryCouponRuleVOList.map(a => ({
        ...a,
        ruleCode: a.code,
      })) || []
  })
  handleCloseOrOpenDialog(true)
}

/**
 * 新增会员卡信息
 */
const addMemberCardLevel = () => {
  needUpdateMemberCardInfo.value = {
    termOfValidity: 1, //默认永久有效
    goodsDiscount: 10, //商品折扣
    accAmountPoints: 1,
    accAmountPointsX: 1,
    storedAmountPoints: 1,
    storedAmountPointsX: 1,
  }
  handleCloseOrOpenDialog(true)
}

const deleteMemberCardLevel = async row => {
  ElMessageBox.confirm('确认删除当前会员卡等级吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    if (row.isDefault === 1) {
      ElMessage.warning('当前会员卡等级是默认的，不可删除！')
    } else {
      let { code, msg } = await API.levelDelete({ code: row.code })
      if (code === '200') {
        ElMessage.success('删除成功')
        queryData()
      } else {
        ElMessage.error(msg)
      }
    }
  })
}

/**
 * 关闭or打开dialog展示框
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  if (!val) {
    queryData()
  }
}

//分页
const handleSizeChange = val => {
  searchForm.pageSize = val
  queryData()
}
const handleCurrentChange = val => {
  searchForm.pageNum = val
  queryData()
}
</script>

<style scoped lang="scss">
.member-card-level {
  padding: 20px 10px;
  .el-pagination {
    text-align: right;
  }
}
</style>
