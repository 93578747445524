<template>
  <div class="contanier">
    <el-form
      ref="ruleform"
      :rules="rules"
      :model="merchantform"
      label-width="5.5vw"
      label-position="center"
      :inline="true"
    >
      <h2>图片信息</h2>
      <div>
        <div class="uploadimg_contanier">
          <el-upload
            list-type="picture"
            :headers="headerObj"
            class="avatar-uploader"
            :action="baseUrl + '/adminChain/goods/material/upload'"
            :show-file-list="false"
            :on-success="handleSkuPic"
          >
            <img v-if="merchantform.pic" :src="merchantform.pic" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
          <div style="line-height:30px">商品图片</div>
        </div>
        <div class="uploadimg_contanier">
          <el-upload
            class="avatar-uploader"
            :headers="headerObj"
            :action="baseUrl + '/adminChain/goods/material/upload'"
            :show-file-list="false"
            :on-success="handleDetailPic"
          >
            <img v-if="merchantform.picDetail" :src="merchantform.picDetail" class="avatar" />
            <el-icon v-else class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
          <div style="line-height:30px">原始图片</div>
        </div>
      </div>
      <h2 style="margin-top:20px">基本信息</h2>
      <div>
        <el-form-item prop="name" label="物料名称">
          <el-select
            v-model="merchantform.materialCode"
            class="m-2"
            placeholder="请输入物料名称"
            filterable
            @change="getMateriel"
            :remote-method="remoteMethod"
            :loading="loading"
            remote
            reserve-keyword
          >
            <el-option v-for="item in materielList" :key="item.code" :label="item.name" :value="item.code" />
          </el-select>
        </el-form-item>
      </div>
      <div>
        <el-form-item label="对应关系">
          <el-input v-model="merchantform.materialCount" placeholder="物料数量" style="width: 8vw">
            <template #append>{{ materielUnit }}</template>
          </el-input>
        </el-form-item>
        <span class="tip mr-20">=</span>
        <el-form-item prop="highProfitRate">
          <el-input v-model="merchantform.skuCount" placeholder="商品数量"> </el-input>
        </el-form-item>
        <el-form-item prop="unit">
          <el-select v-model="merchantform.unit" style="width: 4vw;left:-10px;" placeholder="单位">
            <el-option v-for="item in dicts" :key="item.code" :label="item.name" :value="item.code"> </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item prop="name" label="商品名称">
        <el-input :style="{ width: formitemwidth }" v-model="merchantform.name" placeholder="请输入商品名称"></el-input>
      </el-form-item>
      <el-form-item prop="price" label="指导价">
        <el-input-number
          :min="0"
          :style="{ width: formitemwidth }"
          v-model="merchantform.price"
          placeholder="请输入商品指导价"
        >
        </el-input-number>
      </el-form-item>
      <el-form-item prop="lowProfitRate" label="毛利率">
        <el-input v-model="merchantform.lowProfitRate" style="width: 4.5vw"></el-input>
      </el-form-item>
      <span class="tip">%～</span>
      <el-form-item prop="highProfitRate">
        <el-input v-model="merchantform.highProfitRate" style="width: 4.5vw"></el-input>
      </el-form-item>
      <span class="tip">%</span>
      <el-form-item prop="unit" label="销售单位">
        <el-select v-model="merchantform.unit" :style="{ width: formitemwidth }" placeholder="请选择销售单位">
          <el-option v-for="item in dicts" :key="item.code" :label="item.name" :value="item.code"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="categoryCode1" label="品类">
        <el-select
          @change="selectcategory"
          v-model="merchantform.categoryCode1"
          :style="{ width: formitemwidth }"
          placeholder="请选择品类"
        >
          <el-option v-for="item in categries" :key="item.code" :label="item.name" :value="item.code"> </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类别">
        <el-select v-model="merchantform.categoryCode2" :style="{ width: formitemwidth }" placeholder="请选择类别">
          <el-option v-for="item in category_children" :key="item.code" :label="item.name" :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item prop="packageCount" label="进货单位">
        <el-input v-model="merchantform.packageCount" style="width: 7vw"></el-input>
      </el-form-item> -->
      <!-- <el-form-item prop="packageUnit">
        <el-select v-model="merchantform.packageUnit" style="width: 5vw;left:-10px;" placeholder="单位">
          <el-option v-for="item in dicts" :key="item.code" :label="item.name" :value="item.code"> </el-option>
        </el-select>
      </el-form-item> -->
      <el-form-item label="条形码">
        <el-input
          v-model="merchantform.qrCode"
          :style="{ width: formitemwidth, left: '-10px' }"
          placeholder="请输入商品条形码"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="属性">
        <el-cascader
          style="width: 30vw"
          v-model="merchantform.attributes"
          :options="category_attributes"
          :props="props"
          clearable
        >
        </el-cascader>
      </el-form-item>
      <h2 style="margin-top:-20px">商品配置</h2>
      <el-form-item v-if="merchantform.isRecovery" label="空瓶价格">
        <el-input v-model="merchantform.recoverPrice" min="0" style="width: 7vw"></el-input>
      </el-form-item>
      <el-form-item prop="recoveryUnit" v-if="merchantform.isRecovery">
        <el-select v-model="merchantform.recoveryUnit" style="width: 5vw;left:-10px;" placeholder="单位">
          <el-option v-for="item in dicts" :key="item.code" :label="item.name" :value="item.code"> </el-option>
        </el-select>
      </el-form-item>
      <div style="width:100%">
        <el-form-item prop="isRecovery">
          <el-checkbox style="margin-left:2.5vw" v-model="merchantform.isRecovery">是否支持空瓶</el-checkbox>
        </el-form-item>
        <el-form-item prop="isPresent">
          <el-checkbox v-model="merchantform.isPresent">是否支持商家赠送</el-checkbox>
        </el-form-item>
        <el-form-item prop="isMinConsume">
          <el-checkbox v-model="merchantform.isMinConsume">是否计入低销</el-checkbox>
        </el-form-item>
        <el-form-item prop="isStock">
          <el-checkbox v-model="merchantform.isStock">是否计入库存</el-checkbox>
        </el-form-item>
      </div>
      <!-- <h2 style="margin-top:20px;" v-if="!skucode">素材上传</h2>
      <el-form-item v-if="!skucode">
        <el-upload
          class="avatar-uploader"
          :action="baseUrl + 'goods/material/upload'"
          list-type="picture-card"
          :limit="9"
          :file-list="fileList"
          :on-error="handleAvatarError"
          :on-remove="handleAvatarRemove"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <i class="avatar-uploader-icon el-icon-plus"> </i>
        </el-upload>
      </el-form-item> -->
      <h2 style="margin-top:-20px">商品描述</h2>
      <el-form-item>
        <el-input
          resize="none"
          v-model="merchantform.description"
          style="width: 35vw"
          type="textarea"
          :rows="3"
          placeholder="请输入商品描述"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <div class="operate">
      <el-button @click="cancelSubmit" style="width: 150px">取消</el-button>
      <el-button style="width: 150px;margin-left:20px" type="primary" @click="onSubmit">保存</el-button>
    </div>
  </div>
</template>

<script>
import MerchantAPI from '../service/merchant.js'
import Config from '@/config'
import { ElMessage } from 'element-plus'
export default {
  name: 'merchant',
  components: {},
  data() {
    return {
      headerObj: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      },
      dialogVisible: false,
      props: { multiple: true },
      baseUrl: Config.baseURL || '',
      category_children: [],
      category_attributes: [],
      merchantInfo: {},
      merchantform: {
        name: '', //商品名称
        price: 0, //指导价
        categoryCode1: '', //一级分类
        categoryCode2: '', //二级分类
        pic: '', //商品图片
        picDetail: '', //商品广告图
        lowProfitRate: 0, //毛利率
        highProfitRate: 0, //毛利率
        pic_detail: '', //商品详情图
        description: '', //商品描述
        recoveryUnit: '', //回收单位
        unit: '', //商品售卖单位
        isStock: true, //是否计算库存
        isPresent: false, //是否支持赠送
        isMinConsume: false, //是否支持低消
        isRecovery: false, //是否支持空瓶回收
        recoverPrice: 0, //回收价格
        packageUnit: '', //包装单位
        packageCount: '', //包装内单品数量
        qrCode: '', //二维码
        pics: [], //商品素材
        attributes: [], //属性
        materialCode: '',
        materialCount: '',
        skuCount: '',
      },
      rules: {
        name: [
          {
            required: true,
            message: '请输入商品名称',
            trigger: 'blur',
          },
        ],
        price: [
          {
            required: true,
            message: '请输入商品指导价',
            trigger: 'blur',
          },
        ],
        lowProfitRate: [
          {
            required: true,
            message: '请输入商品毛利率',
            trigger: 'blur',
          },
        ],
        unit: [
          {
            required: true,
            message: '请输入商品销售单位',
            trigger: 'blur',
          },
        ],
        categoryCode1: [
          {
            required: true,
            message: '请选择商品所属品类',
            trigger: 'blur',
          },
        ],
        package_count: [
          {
            required: true,
            message: '请输入进货单位',
            trigger: 'blur',
          },
        ],
      },
      formitemwidth: '12vw',
      inputVisible: false,
      inputValue: '',
      categries: [],
      fileList: [],
      uploadlist: [],
      dicts: [],
      materielList: [],
      materielUnit: '袋',
    }
  },
  props: {
    imgsuploads: {
      type: Array,
      default: [],
    },
    skucode: {
      type: String,
      default: '',
    },
  },

  computed: {},
  mounted() {
    this.getCategroyList()
    this.getdictlist()
  },
  methods: {
    resetFields() {
      this.getdictlist()
      this.category_children = []
      this.merchantform = {
        name: '', //商品名称
        price: 0, //指导价
        categoryCode1: '', //一级分类
        categoryCode2: '', //二级分类
        pic: '', //商品图片
        picDetail: '', //商品广告图
        lowProfitRate: 0, //毛利率
        highProfitRate: 0, //毛利率
        pic_detail: '', //商品详情图
        description: '', //商品描述
        recoveryUnit: '', //回收单位
        unit: '', //商品售卖单位
        isStock: true, //是否计算库存
        isPresent: false, //是否支持赠送
        isMinConsume: false, //是否支持低消
        isRecovery: false, //是否支持空瓶回收
        recoverPrice: 0, //回收价格
        packageUnit: '', //包装单位
        packageCount: '', //包装内单品数量
        qrCode: '', //二维码
        pics: [], //商品素材
        attributes: [], //属性
      }
    },
    handleSkuPic(file) {
      if (file.code == '200') {
        this.merchantform.pic = file.data.url
      }
    },
    handleDetailPic(file) {
      if (file.code == '200') {
        this.merchantform.picDetail = file.data.url
      }
    },
    async getSkuInfo() {
      const res = await MerchantAPI.getSkuInfo({
        skuCode: this.skucode,
        storeCode: 'k0000',
      })
      if (res.code == '200') {
        this.merchantform = { ...res.data }
        this.merchantform.isPresent = this.merchantform.isPresent ? true : false
        this.merchantform.isRecovery = this.merchantform.isRecovery ? true : false
        this.merchantform.isStock = this.merchantform.isStock ? true : false
        this.merchantform.isMinConsume = this.merchantform.isMinConsume ? true : false
        this.selectcategory(this.merchantform.categoryCode1)
      }
    },
    getdictlist() {
      MerchantAPI.getSkuDictList({ type: 110100 }).then(res => {
        if (res.code == '200') {
          this.dicts = res.data
        }
      })
      MerchantAPI.materialList({ keyword: this.merchantform.materialCode }).then(res => {
        this.materielList = res.data
      })
    },
    handleAvatarRemove(file, fileList) {
      this.uploadlist = fileList
    },
    handleAvatarSuccess(res, file) {
      if (res.code == '200') {
        this.uploadlist.push(res.data.url)
      }
    },
    beforeAvatarUpload(file) {
      const isIMG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isIMG) {
        this.$message.error('图片必须是jpg/png格式!')
      }
      if (!isLt2M) {
        this.$message.error('图片大小2MB以内')
      }
      return isIMG && isLt2M
    },
    handleAvatarError(res) {
      ElMessage.error('上传失败！')
    },
    cancelSubmit() {
      this.$emit('close')
    },
    // resetFields() {
    //   this.$refs['ruleform'].resetFields()
    // },
    onSubmit() {
      if (this.uploadlist.length > 0) {
        this.merchantform.pics = this.uploadlist
      }
      if (this.merchantform.attributes.length > 0) {
        let temp = []
        this.merchantform.attributes.forEach(atr => {
          temp.push({
            nameCode: atr[0],
            valueCode: atr[1],
          })
        })
        this.merchantform.attributes = temp
      }
      this.$refs['ruleform'].validate(valid => {
        if (valid) {
          this.merchantform.isPresent = this.merchantform.isPresent ? 1 : 0
          this.merchantform.isRecovery = this.merchantform.isRecovery ? 1 : 0
          this.merchantform.isStock = this.merchantform.isStock ? 1 : 0
          this.merchantform.isMinConsume = this.merchantform.isMinConsume ? 1 : 0
          this.merchantform.unit = parseInt(this.merchantform.unit)
          this.merchantform.packageUnit = parseInt(this.merchantform.packageUnit)
          if (this.skucode) {
            this.merchantform.code = this.skucode
            MerchantAPI.updateSku(this.merchantform).then(res => {
              if (res.code == '200') {
                this.$message.success('修改商品成功！')
                this.$emit('close')
                this.$refs['ruleform'].resetFields()
              } else {
                this.$message.error(res.mg)
              }
            })
          } else {
            MerchantAPI.addSku(this.merchantform).then(res => {
              if (res.code == '200') {
                this.$message.success('添加商品成功！')
                this.$emit('close')
                this.$refs['ruleform'].resetFields()
              } else {
                this.$message.error(res.mg)
              }
            })
          }
        } else {
          return false
        }
      })
    },
    selectcategory(val) {
      MerchantAPI.categoryListch(val).then(res => {
        if (res.code == '200') {
          this.category_children = res.data
          if (this.skucode) {
            let attributes = []
            this.merchantform.attributes.forEach(item => {
              item.values.forEach(val => {
                attributes.push([item.code, val.code])
              })
            })
            this.merchantform.attributes = attributes
          }
        }
      })
      MerchantAPI.categoryAttributes(val).then(res => {
        if (res.code == '200') {
          let multidata = []
          res.data.forEach(categoty => {
            let data = {
              label: categoty.name,
              value: categoty.code,
            }
            if (categoty.values.length > 0) {
              data.children = []
              categoty.values.forEach(item => {
                let children = {
                  label: item.value,
                  value: item.code,
                }
                data.children.push(children)
              })
            }
            multidata.push(data)
          })
          this.category_attributes = multidata
        }
      })
    },
    getMateriel(val) {
      let data = this.materielList.find(item => item.code === val)
      this.materielUnit = data.unit
    },
    getCategroyList() {
      MerchantAPI.getCategoryList(2).then(res => {
        if (res.code == '200') {
          this.categries = res.data
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
.contanier {
  margin: 25px 10px;
  height: calc(100vh - 150px);
  overflow-y: auto;

  // .avatar-uploader .el-upload {
  //   border: 1px dashed var(--el-border-color);
  //   border-radius: 6px;
  //   cursor: pointer;
  //   position: relative;
  //   overflow: hidden;
  //   transition: var(--el-transition-duration-fast);
  //   .avatar-uploader-icon{
  //     width: 140px;
  //   }
  // }
  .operate {
    text-align: center;
    position: absolute;
    bottom: 30px;
    width: 100%;
  }

  .tip {
    color: #7a97f2;
    font-size: 14px;
    left: -5px;
  }

  .mr-20 {
    margin-right: 10px;
  }

  .subformitem {
    position: relative;
    left: -10px;
  }

  .uploadimg_contanier {
    text-align: center;
    display: inline-block;
    width: 200px;
  }

  .avatar-uploader {
    ::v-deep .el-upload {
      border: 1px dashed #d9d9d9;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      border-color: #a1baff;

      .avatar-uploader-icon {
        font-size: 40px;
        color: #8c939d;
        height: 138px;
        width: 138px;
        line-height: 138px;
        text-align: center;
      }
    }
  }

  ::v-deep .el-textarea__inner {
    border-color: #a1baff;
  }

  .avatar {
    width: 158px;
    height: 158px;
    display: block;
  }

  .merchantcheck {
    margin-left: 1.5vw;
    display: flex;
    flex-wrap: nowrap;
  }

  .inputjh {
    display: inline-block;
    width: 400px;
    margin-left: 30px;
  }

  .tag {
    cursor: pointer;
  }

  ::v-deep .el-tag {
    margin-right: 10px;
  }

  h2 {
    border-left: 5px solid #5384ff;
    margin-top: 5px;
    padding: 5px 10px;
    margin-bottom: 15px;
  }
}
</style>
