<template>
  <el-dialog v-model="visible" title="采购设置" width="50%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="specRowMsg"
      label-width="120px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="单品图片">
        <el-upload list-type="picture" class="avatar-uploader" action="#" :show-file-list="false">
          <img :src="specRowMsg.skuPic" class="avatar" />
        </el-upload>
      </el-form-item>
      <el-form-item label="采购图片" prop="purchaseGoodsPic">
        <el-upload
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage1"
        >
          <img v-if="specRowMsg.purchaseGoodsPic" :src="specRowMsg.purchaseGoodsPic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="单品名称" prop="name">
        <el-input v-model="specRowMsg.skuName" disabled style="width:70%" placeholder="请填写商品名称" />
      </el-form-item>
      <el-form-item label="采购名称" prop="purchaseGoodsName">
        <el-input v-model="specRowMsg.purchaseGoodsName" style="width:70%" placeholder="请填写进货名称" />
      </el-form-item>
      <el-form-item label="单品规格">
        <el-input v-model="specRowMsg.specifications" disabled style="width:70%" placeholder="请填写规格" />
      </el-form-item>
      <el-form-item label="最小单位" v-if="specRowMsg.attribute != 3">
        <el-select v-model="specRowMsg.miniUnit" placeholder="请选择最小单位" disabled>
          <el-option :label="item.name" :value="item.name" v-for="(item, index) in form.units" :key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="采购单位" prop="purchaseUnit">
        <el-select v-model="specRowMsg.purchaseUnit" placeholder="请选择进货单位">
          <el-option :label="item.name" :value="item.name" v-for="(item, index) in form.units" :key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="采销比(1:N)" prop="adoptPinThan" v-if="specRowMsg.attribute != 3">
        <el-input-number
          :precision="0"
          :controls="false"
          v-model="specRowMsg.adoptPinThan"
          style="width:30%"
          placeholder="请输入N值,整数格式"
        />
      </el-form-item>

      <el-form-item label="采购单价(元)" prop="goodsCost">
        <el-input-number
          :precision="2"
          :controls="false"
          v-model="specRowMsg.goodsCost"
          style="width:30%"
          placeholder="支持2位小数"
        />
      </el-form-item>

      <el-form-item label="空瓶价(元)">
        <div style="display: flex;">
          <el-input-number
            :precision="2"
            :controls="false"
            v-model="specRowMsg.recoveryPrice"
            style="width:30%"
            placeholder="支持2位小数"
          />
          <span>
            （若为酒水商品且有回收空瓶，则维护；除此以外，请忽略）
          </span>
        </div>
      </el-form-item>
    </el-form>

    <div style="text-align: center; margin-top: 10px;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">
        确定
      </el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { reactive, defineComponent, computed, ref, toRaw, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
  },
  setup(props, ctx) {
    const form = reactive({
      loadText: '正在火速加载数据...',
      img1: [],
      img2: [],
      optionsProps: {
        label: 'name',
        children: 'child',
        value: 'code',
        checkStrictly: true,
      },
      loading: false,
    })

    const visible = computed(() => {
      return props.dialogVisible
    })
    onMounted(() => {
      selectDictListAll()
    })

    //获取单位
    const selectDictListAll = () => {
      API.selectDictListAll().then(res => {
        if (res.code === '200') {
          form.units = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const changeImage1 = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img1.push(file)
      props.specRowMsg.purchaseGoodsPic = ''
      let fileName = file.file.name + new Date()
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.specRowMsg.purchaseGoodsPic = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }
    const changeImage2 = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img2.push(file)
      props.specRowMsg.pic = ''
      let fileName = file.file.name + new Date()
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.specRowMsg.pic = res.res.requestUrls[0]
        })
        .finally(err => {
          form.loading = false
        })
      // let formData = new FormData()
      // formData.append('file', file.file)
      // API.uploadImags(formData).then(res => {
      //   if (res.code === '200') {
      //     form.img2.push(file)
      //     props.specRowMsg.pic = res.data.url
      //   } else {
      //     ElMessage.error(res.msg)
      //   }
      // })
    }
    const update = params => {
      API.updateProduct(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const rules = reactive({
      purchaseGoodsPic: [{ required: true, message: '请上传采购图片', trigger: 'blur' }],
      purchaseGoodsName: [{ required: true, message: '请填写采购名称', trigger: 'blur' }],
      purchaseUnit: [{ required: true, message: '请选择采购单位', trigger: 'blur' }],
      adoptPinThan: [{ required: true, message: '请填写采销比', trigger: 'blur' }],
      goodsCost: [{ required: true, message: '请填写采购单价', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let params = {
            ...props.specRowMsg,
          }
          if (Reflect.has(props.specRowMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      handleSubmit,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      changeImage1,
      changeImage2,
      rules,
      elForm,
    }
  },
})
</script>
<style lang="scss" scoped>
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
