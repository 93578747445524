<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2023-01-10 17:34:02
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2023-01-10 17:45:42
 * @FilePath: /saas/src/plugin/merchant-manager/component/storeComponent.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-form :model="ruleForm">
    <el-form-item label="商品分配门店" class="form-item"> </el-form-item>
    <el-row>
      <el-col :span="2">
        <el-checkbox
          v-model="ruleForm.resource"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
          style="padding-top:10px"
          >全选</el-checkbox
        >
      </el-col>
      <el-col :span="1"> </el-col>
      <el-col class="text-center" :span="4">
        <el-select v-model="value" filterable placeholder="Select">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-col>
      <el-col :span="1"> </el-col>
      <el-col class="text-center" :span="4">
        <el-select v-model="value" filterable placeholder="Select">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </el-col>
      <el-col :span="1"> </el-col>
      <el-col :span="6">
        <el-input v-model="ruleForm.name" />
      </el-col>
      <el-col :span="1"> </el-col>
      <el-col :span="2">
        <el-button type="primary">搜索</el-button>
      </el-col>
    </el-row>
    <div class="selected-city">
      <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
        <el-checkbox v-for="city in cities" :key="city" :label="city" border>{{ city }}</el-checkbox>
      </el-checkbox-group>
    </div>
  </el-form>
</template>
<script>
import { reactive, ref } from 'vue'
export default {
  setup(prop, content) {
    const ruleForm = reactive({
      name: 'Hello',
      region: '',
      count: '',
      date1: '',
      date2: '',
      delivery: false,
      type: [],
      resource: '',
      desc: '',
    })
    const checkAll = ref(false)
    const isIndeterminate = ref(true)
    const checkedCities = ref(['Shanghai', 'Beijing'])
    const cities = [
      'Shanghai',
      'Beijing',
      'Guangzhou',
      'Shenzhen',
      'Shenzhen',
      'Shenzhen',
      'Shenzhen',
      'Shenzhen',
      'Shenzhen',
      'Shenzhen',
    ]

    const handleCheckAllChange = val => {
      checkedCities.value = val ? cities : []
      isIndeterminate.value = false
    }
    const handleCheckedCitiesChange = value => {
      const checkedCount = value.length
      checkAll.value = checkedCount === cities.length
      isIndeterminate.value = checkedCount > 0 && checkedCount < cities.length
    }

    return {
      ruleForm,
      checkAll,
      isIndeterminate,
      checkedCities,
      cities,
      handleCheckAllChange,
      handleCheckedCitiesChange,
    }
  },
}
</script>
<style lang="scss" scoped>
.selected-city {
  margin-top: 20px;

  ::v-deep .el-checkbox.is-bordered + .el-checkbox.is-bordered {
    margin-left: 0 !important;
    margin-top: 10px;
  }
}
</style>
