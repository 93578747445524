<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="单位名称">
      <el-input
        placeholder="请输单位名称"
        v-model="data.name"
        clearable
        class="input-search-tel"
        @keyup.enter="searchLists"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists">
        查询
      </el-button>
      <el-button type="primary" @click="addUnitConfig" v-if="data.authMenus.add"> 新增 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column prop="name" label="名称" align="center" />
    <el-table-column prop="remarks" label="备注" align="center" />
    <el-table-column prop="updateTime" label="更新时间" align="center" />
    <el-table-column label="操作" width="250" align="center">
      <template #default="scope" fixed="right">
        <el-button type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <el-button type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.delete">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddUnitConfig
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :unitConfig="data.unitConfig"
    @update-dialogVisible="updateDialogVisible"
  ></AddUnitConfig>
</template>

<script setup>
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import API from '../../service/api'
import { reactive, onMounted, computed } from 'vue'
import AddUnitConfig from './AddUnitConfig.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'

const data = reactive({
  name: undefined,
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  dialogVisible: false,
  unitConfig: {},
  loading: false,
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

const queryLists = () => {
  data.loading = true
  if (data.authMenus.query) {
    let queryParams = {
      pageSize: data.pageSize,
      current: data.currentPage,
      name: data.name,
    }
    API.selectDictList(queryParams)
      .then(res => {
        if (res.code === '200') {
          data.loading = false
          data.tableData = res.data.records
          data.total = res.data.total
        } else {
          ElMessage.error(res.msg)
        }
      })
      .finally(() => (data.loading = false))
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//添加
const addUnitConfig = () => {
  data.unitConfig = {}
  data.dialogVisible = true
}
const deleteRow = rowMsg => {
  ElMessageBox.confirm('确认删除当前所选单位配置吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    API.deleteDict({ code: rowMsg.code }).then(res => {
      if (res.code === '200') {
        ElMessage.success('删除成功')
        queryLists()
      } else {
        ElMessage.error(res.msg)
      }
    })
  })
}
const editRow = rowMsg => {
  data.unitConfig = rowMsg
  data.dialogVisible = true
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      editRow(rowMsg)
      break
    case 2:
      deleteRow(rowMsg)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryLists()
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  justify-content: flex-end;

  .search-right {
    margin-right: 20px;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
