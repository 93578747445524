<!--
 * @Author: your name
 * @Date: 2022-04-20 13:48:02
 * @LastEditTime: 2022-09-07 16:33:44
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/component/tableSearchTab的副本.vue
-->
<template>
  <div class="table-search-tab" style="height: 76px;line-height: 76px;">
    <div class="search-left">
      <span>门店选择</span>
      <!-- <el-select v-model="value" class="search-select" placeholder="选择门店">
        <el-option v-for="item in storeList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select> -->
      <el-select
        v-model="storeCode"
        filterable
        remote
        reserve-keyword
        placeholder="选择门店"
        clearable
        class="search-select"
        :remote-method="remoteMethod"
        :loading="loading"
      >
        <el-option
          v-if="searchTypes === 'isMemberInfo' || searchTypes === 'isStoredValue'"
          key=""
          label="星聚会总部"
          value="K0000"
        ></el-option>
        <el-option v-for="item in storeList" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <!-- <el-input v-model="telephone" placeholder="请输入手机号搜索" class="input-with-select">
        <template #prepend>
          <el-select v-model="typeSelect" class="input-search" style="width: 80px;" @change="search">
            <el-option label="手机" value="0" />
            <el-option label="包厢" value="1" />
          </el-select>
        </template>
      </el-input> -->
      <el-input v-model="telephone" placeholder="请输入手机号搜索" class="input-search-tel">
        <template #append>
          <el-icon>
            <search />
          </el-icon>
        </template>
      </el-input>
      <el-button type="primary" @click="searchTableData" v-if="authMenus.query">查询</el-button>
      <el-button plain @click="reset">重置</el-button>
    </div>
    <div class="search-right">
      <el-button
        type="primary"
        v-if="searchTypes === 'isStoringWine' && authMenus.batchRenew"
        class="batch-btn"
        @click="openExtendModal"
        >批量续存
      </el-button>
      <el-date-picker
        v-model="queryDate"
        type="datetimerange"
        start-placeholder="起始日期"
        format="YYYY-MM-DD"
        end-placeholder="结束日期"
        v-if="!['isStoredValue', 'sccessRecord', 'isMemberInfo', 'isStoringWine'].includes(searchTypes)"
        style="margin-right: 20px;"
      />
      <el-button
        type="primary"
        @click="openExportDialog"
        v-if="searchTypes !== 'sccessRecord' && searchTypes !== 'isStoringWine' && authMenus.export"
        >导出</el-button
      >
    </div>
  </div>
  <ExportDialog :columnsList="columnsList" ref="dialogRef" :exportData="exportData" />
</template>
<script>
import { async } from 'q'
import api from '../service/index'
import { downLoad } from '../../../lin/util/util'
import ExportDialog from '../../../component/base/exportDialog/export-dialog.vue'
import { value } from 'dom7'
import { throwStatement } from '@babel/types'
export default {
  components: { ExportDialog },
  props: {
    authMenus: {
      type: Object,
      default: {},
    },
    searchTypes: {
      type: String,
      default: '',
    },
    openExtendModal: {
      type: Function,
      default: null,
    },
    getTableData: {
      type: Function,
      default: null,
    },
    model: {
      type: Object,
      default: {},
    },
    columnsList: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {
      queryDate: '',
      typeSelect: '',
      telephone: '',
      storeList: [],
      storeCode: '',
      columnsList: this.columnsList,
      dialogVisible: false,
    }
  },
  mounted() {
    if (this.searchTypes === 'isMemberInfo' || this.searchTypes === 'isStoredValue') {
      this.storeCode = 'K0000'
    }
    this.getStoreList()
  },
  methods: {
    getStoreList() {
      api.getStoreListByName().then(res => {
        this.storeList = res.data.map(item => {
          return { value: item.code, label: item.name }
        })
      })
    },
    searchTableData() {
      this.model.pageNum = 1
      this.model.telephone = this.telephone
      this.model.storeCode = this.storeCode
      this.getTableData(this.model)
    },
    reset() {
      this.telephone = ''
      this.storeCode = ''
      this.model.pageNum = 1
      this.model.telephone = this.telephone
      this.model.storeCode = this.storeCode
      this.getTableData(this.model)
    },
    async exportData(exportDatas) {
      let request
      if (this.searchTypes === 'isMemberInfo') {
        request = api.exportMember
      } else if (this.searchTypes === 'isStoredValue') {
        request = api.exportOrderCardStored
      }
      const excelData = await request({
        telephone: this.telephone,
        storeCode: this.storeCode,
        exportDatas: exportDatas.join(','),
      })
      downLoad(excelData)
    },
    openExportDialog() {
      this.$refs.dialogRef.openDialog()
    },
  },
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 41px;

  .search-left {
    width: 60%;

    .input-with-select {
      width: 340px;
      margin-left: 24px;
      margin-right: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
</style>
