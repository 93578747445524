<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-18 11:09:42
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-09-02 15:53:32
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/editCategory.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="edit-category-content">
    <el-drawer
      v-model="editModal"
      title="苏州信投店-A02/小包"
      size="35%"
      :direction="direction"
      :with-header="false"
      :before-close="handleClose"
    >
      <div class="edit-commdity-info">
        <div class="info-left">
          <h1>
            编辑品类<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="editModal = false">
              <close />
            </el-icon>
          </h1>
          <div class="info-line"></div>
          <el-form :model="form" label-width="80px" class="form-content" label-position="left" v-if="!isEdit">
            <span class="form-title">绑定品类</span>
            <el-checkbox-group v-model="form.typeList" class="beverages-list" @change="handleCategory">
              <el-checkbox v-for="item in categoryList" :label="item.name" name="type" />
            </el-checkbox-group>
            <span class="form-title">品类确认</span>
            <div class="supply-goods-list">
              <div v-for="(item, index) in checkBoxCategory">
                <el-tag class="mx-1 tags" closable type="" @close="deleteTag(item, index)">
                  {{ item.name }}
                </el-tag>
                <el-row>
                  <el-col :span="8">
                    <el-radio-group v-model="item.taxState" class="tax-point-list" @change="changeItemTaxState(item)">
                      <el-radio :label="1">含税</el-radio>
                      <el-radio :label="0">不含税</el-radio>
                    </el-radio-group>
                  </el-col>
                  <el-col :span="6">
                    <el-form-item
                      label="税点"
                      label-width="50px"
                      v-if="item.taxState !== 0"
                      :rules="[{ required: item.taxState === 1, message: '请输入两位正整数' }]"
                    >
                      <el-input-number v-model="item.taxPoint" :precision="0" :max="20" :min="0" />
                    </el-form-item>
                  </el-col>
                </el-row>
              </div>
            </div>
            <el-button type="primary" @click="onSubmitAll" class="confirm-edit">确认修改</el-button>
          </el-form>
          <div class="edit-category-item" v-else>
            <h2>品类名称：{{ model.categoryCodeName }}</h2>
            <el-form :model="model" style="width: 200px;" :rules="rules" ref="editForm">
              <el-radio-group v-model="model.taxState" class="tax-point-list" @change="changeTaxState">
                <el-radio :label="1">含税</el-radio>
                <el-radio :label="0">不含税</el-radio>
              </el-radio-group>
              <el-form-item
                label="税点"
                label-width="50px"
                prop="taxPoint"
                v-if="model.taxState !== 0"
                :rules="[{ required: model.taxState === 1, message: '请输入两位正整数' }]"
              >
                <el-input-number v-model="model.taxPoint" :min="0" :max="20" :precision="0" />
              </el-form-item>
            </el-form>
          </div>
          <el-button type="primary" @click="onSubmit" class="confirm-edit" v-if="isEdit">确认修改</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../service/index'
export default {
  props: {
    getTableData: {
      type: Function,
      default: () => {},
    },
    tableModel: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editModal: false,
      isEdit: false, //false 全部品类编辑，true单个编辑
      form: {
        typeList: [],
      },
      model: {
        taxState: 1,
        taxPoint: '',
        categoryCode1: '',
        code: '',
        categoryCodeName: '',
      },
      tableData: [],
      categoryList: [],
      checkBoxCategory: [],
      supplierCode: '',
      supplierName: '',
    }
  },
  mounted() {
    this.getCategoryList()
  },
  watch: {
    editModal(newval) {
      if (newval) {
        this.checkBoxCategory = []
        this.handleCategory()
      }
    },
  },
  methods: {
    getCategoryList() {
      api.getCategoryList().then(res => {
        res.data.map(item => {
          this.categoryList.push(
            Object.assign({}, item, {
              categoryCode1: item.code,
            }),
          )
        })
      })
    },
    changeTaxState(val) {
      if (val === 0) {
        this.model.taxPoint = 0
      }
    },
    changeItemTaxState(item) {
      if (item.taxState === 0) {
        item.taxPoint = 0
      }
    },
    handleCategory() {
      //复选框修改
      let categries = []
      this.form.typeList.forEach(category => {
        categries.push(this.categoryList.filter(item => item.name === category)[0])
      })
      categries.map(categroy => {
        categroy.taxPoint = categroy.taxPoint ? categroy.taxPoint : ''
        categroy.taxState = categroy.taxState ? categroy.taxState : 0
        categroy.supplierCode = this.supplierCode
        categroy.supplierName = this.supplierName
        let selectdata = this.tableData.filter(item => item.categoryCodeName == categroy.name)
        if (selectdata.length == 1) {
          categroy.taxPoint = selectdata[0].taxPoint
          categroy.taxState = selectdata[0].taxState
          categroy.categoryCode1 = selectdata[0].categoryCode1
          categroy.supplierCode = this.supplierCode
          categroy.supplierName = this.supplierName
        }
        return categroy
      })
      this.checkBoxCategory = categries
    },
    editInfo(row) {
      const { taxPoint, taxState, categoryCode1, code, categoryCodeName } = row
      this.model = { ...this.model, taxState, taxPoint, categoryCode1, code, categoryCodeName }
    },
    deleteTag(item, index) {
      const spliceIndex = this.checkBoxCategory.findIndex(categroy => categroy.name === item.name)
      this.checkBoxCategory.splice(spliceIndex, 1)
      this.form.typeList.splice(spliceIndex, 1)
    },
    onSubmit() {
      this.$refs.editForm.validate((valid, fields) => {
        if (valid) {
          api.updateBindCategory1DataByCode(this.model).then(res => {
            ElMessage({
              type: 'success',
              message: `修改品类成功`,
            })
            this.editModal = false
            this.getTableData(this.tableModel)
          })
        } else {
          console.log('error submit!', fields)
        }
      })
    },
    onSubmitAll() {
      this.checkBoxCategory.forEach(item => {
        item.categoryName = item.name
      })
      api.batchBindCategory1Code(this.checkBoxCategory).then(res => {
        ElMessage({
          type: 'success',
          message: `编辑品类成功`,
        })
        this.editModal = false
        this.getTableData(this.tableModel)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.edit-category-content {
  position: relative;

  .confirm-edit {
    position: absolute;
    right: 40px;
    bottom: 24px;
  }

  .edit-commdity-info {
    padding: 0 40px;
    .supply-goods-list {
      height: 600px;
      max-height: 600px;
      overflow: auto;
    }
    .edit-category-item {
      h2 {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        height: 40px;
      }

      .tax-point-list {
        margin-bottom: 20px;
      }
    }

    ::v-deep .el-tag {
      margin-bottom: 6px !important;
    }

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .close-icon {
        position: absolute;
        top: 20px;
        right: 0px;
        cursor: pointer;
      }
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .form-content {
      .tax-point-list {
        line-height: 52px !important;
      }

      .beverages-list {
        margin-bottom: 20px;
      }

      ::v-deep .el-checkbox {
        margin-bottom: 6px;
      }
    }

    .form-title {
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      margin-bottom: 20px;
    }
  }
}
</style>
