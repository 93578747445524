<template>
  <el-dialog v-model="visible" title="编辑群二维码" width="46%" :before-close="handleClose">
    <el-form
      ref="ruleFormRef"
      style="max-width: 600px"
      :model="formData"
      :rules="rules"
      label-width="auto"
      class="demo-ruleForm"
      :size="formSize"
      status-icon
    >
      <el-form-item label="私域群二维码" prop="pic">
        <el-upload class="avatar-uploader" :show-file-list="false" action="#" :http-request="uploadImg">
          <img v-if="formData.pic" :src="formData.pic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item style="text-align: left;">
        <el-button style="width: 120px;" @click="handleClose">取消</el-button>
        <el-button style="width: 120px;" type="primary" @click="handleSubmit(ruleFormRef)">保存</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script setup>
import API from '@/plugin/shop-manager/service/api'
import { ref, defineProps, defineEmit, reactive, computed, onMounted } from 'vue'
import { Plus } from '@element-plus/icons-vue'
import { headers, client } from '@/lin/util/uploadImg.js'
import { ElMessage } from 'element-plus'
const props = defineProps({
  dialogVisible: {
    type: Boolean,
    default: false,
  },
  specRowMsg: {
    type: Object,
    default: () => ({}),
  },
})
const ruleFormRef = ref(null)
const formData = reactive({
  pic: '',
})
let emit = defineEmit(['update-dialogVisible'])
onMounted(() => {
  formData.pic = props.specRowMsg.qrCodeUrl
})
const rules = reactive({
  pic: [{ required: true, message: '请上传私域群二维码', trigger: 'change' }],
})

const visible = computed(() => {
  return props.dialogVisible
})

// 保存节流操作
const handleSubmit = _.throttle(() => {
  onSubmit()
}, 2000)

// 保存
const onSubmit = async () => {
  if (!ruleFormRef) return
  await ruleFormRef.value.validate((valid, fields) => {
    if (valid) {
      const params = {
        qrCodeUrl: formData.pic,
        storeCode: props.specRowMsg.code,
      }
      update(params)
    }
  })
}

const update = params => {
  API.updateQrCodeUrl(params)
    .then(res => {
      if (res.code === '200') {
        ElMessage.success('更新成功')
        emit('update-dialogVisible', { update: false, search: true })
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(err => {})
}
// 页面关闭
const handleClose = () => {
  emit('update-dialogVisible', { update: false })
}

// 图片上传
const uploadImg = file => {
  if (checkImg(file)) {
    uploadIMgToClient(file)
  }
}
const checkImg = file => {
  let size = file.file.size
  if (size > 100 * 1024 * 1024) {
    ElMessage.info('上传图片小于100M')
    return false
  }
  return true
}
const uploadIMgToClient = file => {
  console.log(new Date().getTime() + file.file.name, '=========file')

  let fileName = new Date().getTime() + file.file.name
  client()
    .put(fileName, file.file, { headers })
    .then(res => {
      formData.pic = res.res.requestUrls[0]
      emit('uploadImage', { imageUrl: formData.pic, propName: props.propName })
      if (ruleFormRef.value) {
        ruleFormRef.value.clearValidate('pic')
      }
    })
}
</script>

<style lang="scss" scoped>
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
::v-deep .el-form-item__label:before {
  content: '*';
  color: var(--el-color-danger);
  margin-right: 0.020833rem;
}
</style>
