<template>
  <div class="Xzztree">
    <el-input :clearable="true" placeholder="输入关键字进行过滤" class="filterfiled" v-model="filterText"> </el-input>
    <el-tree
      class="zztree"
      :data="zzdata"
      :props="defaultProps"
      ref="tree"
      :filter-node-method="filterNode"
      node-key="id"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
      :default-expanded-keys="defaultNodes"
      draggable
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
      :expand-on-click-node="false"
    >
      <template #default="{ node, data }">
        <div @click.stop="ViewMd(data)" class="custom-tree-node">
          <el-tooltip effect="light" v-if="node.label.length > 40" :content="node.label" placement="top">
            <span class="labelname"> <img v-if="data.mark == 2" src="/static/shop.png" />{{ node.label }}</span>
          </el-tooltip>
          <span v-else class="labelname"> <img v-if="data.mark == 2" src="/static/shop.png" />{{ node.label }}</span>
          <span class="controller">
            <i
              v-show="data.mark != 2"
              style="color: #87E293"
              @click.stop="AddNode(data)"
              class="iconfont el-icon-circle-plus-outline"
            ></i>
            <i
              v-show="data.mark != 2 && data.pid != null"
              @click.stop="editNode(data)"
              style="color:#577DF4"
              class="iconfont el-icon-edit"
            ></i>
            <i
              @click.stop="deleteNode(node, data)"
              v-show="!data.children?.length && data.mark != 2"
              style="color: #ED4D67"
              class="iconfont el-icon-delete"
            ></i>
          </span>
        </div>
      </template>
    </el-tree>
    <el-dialog :title="title" v-model="treeDialogVisible" width="30%">
      <el-input v-model="nodename" placeholder="请输入节点名称"></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="treeDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveNode">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import xjhzzmdModel from '../../model/xjgzzmd'

export default {
  name: 'XjhZztree',
  props: {
    elMenuCollapse: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    let filterText = ref('')
    let title = ref('新增节点')
    const zzdata = ref([])
    const currentNode = ref({})
    // let idArr = ref([1])
    const defaultNodes = ref([1])
    const treeDialogVisible = ref(false)
    const nodename = ref('')
    const gettrees = async () => {
      const treedata = await xjhzzmdModel.getAllGeo()
      if (treedata.code == 200) {
        zzdata.value = treedata.result
        // treedata.result[0].children.map(item=>{
        //     defaultNodes.value.push(item.name)
        // })
        // console.log(defaultNodes.value)
      }
    }

    onMounted(() => {
      gettrees()
      // zzdata.value[0].children.map(item=>{
      //   this.idArr.push(item.id)
      // })
    })

    const defaultProps = ref({
      children: 'children',
      label: 'name',
    })
    return {
      filterText,
      defaultProps,
      currentNode,
      defaultNodes,
      gettrees,
      treeDialogVisible,
      nodename,
      zzdata,
      // idArr,
      title,
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    ViewMd(node) {
      this.$emit('ViewData', node)
    },
    refreshtree() {
      this.gettrees()
    },

    async saveNode() {
      if (!this.nodename) {
        ElMessage.error('请输入节点名称')
        return
      } else {
        if (this.title == '新增节点') {
          const result = await xjhzzmdModel.addGeo({
            name: this.nodename,
            pid: this.currentNode.id,
            mark: 1,
          })
          if (result.code == 200) {
            const newChild = result.result
            this.treeDialogVisible = false
            if (!this.currentNode.children) {
              this.currentNode.children = []
            }
            this.currentNode.children.push(newChild)
            // this.zzdata = [...this.zzdata]
          } else {
            ElMessage.error(result.message)
          }
        } else {
          const result = await xjhzzmdModel.updateGeo({
            name: this.nodename,
            id: this.currentNode.id,
          })

          if (result.code == 200) {
            this.treeDialogVisible = false
            this.currentNode.name = this.nodename
            // this.zzdata = [...this.zzdata]
            this.$emit('treenodechage', this.nodename)
          } else {
            ElMessage.error(result.message)
          }
        }
      }
    },
    async deleteNode(node, data) {
      this.$confirm('此操作将永久删除该节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result = await xjhzzmdModel.deleteGeo({
            id: data.id,
          })
          if (result.code == 200) {
            this.remove(node, data)
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          }
        })
        .catch(() => {})
    },
    editNode(data) {
      this.title = '编辑节点'
      this.treeDialogVisible = true
      this.currentNode = data
      this.nodename = data.name
    },
    AddNode(data) {
      this.nodename = ''
      this.title = '新增节点'
      this.treeDialogVisible = true
      this.currentNode = data
    },

    remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
      this.zzdata = this.zzdata
    },

    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },

    async handleDrop(draggingNode, dropNode, dropType, ev) {
      const result = await xjhzzmdModel.updateGeo({
        id: draggingNode.data.id,
        pid: dropNode.data.id,
      })
      if (result.code != 200) {
        ElMessage.error(result.message)
      } else {
        this.$message({
          type: 'success',
          message: '组织迁移成功!',
        })
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.mark == 2) {
        return false
      } else {
        return type == 'inner'
      }

      // return false;
    },
    allowDrag(draggingNode) {
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.Xzztree {
  width: 100%;
  position: relative;
  height: calc(100vh - 120px) !important;
  padding: 10px;
  background: #fff;
  .zztree {
    max-height: calc(100vh - 180px);
    width: 98%;
    margin-top: 60px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .el-tag {
    margin-right: 20px;
    cursor: pointer;
    border-radius: 2px;
  }
  .filterfiled {
    margin: 10px 0px;
    position: fixed;
    top: 90px;
    z-index: 99;
    width: 18vw;
  }
  .level_button {
    margin: 10px 0px;
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    .el-tag {
      flex: 1;
    }
  }
  ::v-deep .el-table th,
  .el-table td {
    padding: 8px 0px;
  }
  ::v-deep .el-tree-node__content {
    position: relative;
  }
  .custom-tree-node {
    font-size: 14px;
    width: 100%;
    .controller {
      width: 70px;
      text-align: right;
      position: absolute;
      right: 0px;
      top: 8px;
      display: flex;
      flex-wrap: nowrap;
    }
    .iconfont {
      display: inline-block;
      margin-right: 8px;
    }
    .labelname {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 30px;
      position: relative;
      left: -5px;
      img {
        width: 25px;
        display: inline-block;
        height: 25px;
        position: relative;
        top: 7px;
      }
      display: inline-block;
      width: 250px;
    }
  }
}
</style>
