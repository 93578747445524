<template>
  <div class="member-card-coupou">
    <el-form :inline="true">
      <el-form-item label="商品分类">
        <el-select v-model="searchFilter.categoryCode" clearable filterable>
          <el-option v-for="item in productTypes" :key="item.value" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="单品名称">
        <el-input label="单品名称" v-model="searchFilter.productName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="getTableData" v-if="authMenus.query">查询</el-button>
        <el-button @click="reseatFilter">重置</el-button>
        <el-button @click="addGiftGroup" v-if="authMenus.add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData">
      <el-table-column show-overflow-tooltip prop="categoryName" label="商品分类" align="center"></el-table-column>
      <el-table-column label="单品图片">
        <template #default="{row}">
          <img :src="row.pic" style="height: 30px; width: 30px;" />
        </template>
      </el-table-column>
      <el-table-column align="center" show-overflow-tooltip prop="productName" label="单品名称"></el-table-column>
      <el-table-column align="center" show-overflow-tooltip prop="spec" label="单品规格"></el-table-column>
      <el-table-column align="center" show-overflow-tooltip prop="price" label="指导价(元)"></el-table-column>
      <template v-for="(item, index) of headerTitles" :key="index">
        <el-table-column :label="item + '级门店赠送'" align="center" show-overflow-tooltip>
          <template #default="scope">
            {{ scope?.row?.levelCommon[headerTitles[item]] }}
          </template>
        </el-table-column>
      </template>
      <el-table-column align="center" show-overflow-tooltip prop="createTime" label="创建时间"></el-table-column>
      <el-table-column align="center" label="操作" width="150">
        <template v-slot="{ row }">
          <el-button type="text" @click="updateGiftGroup(row)" v-if="authMenus.update">编辑</el-button>
          <el-button type="text" @click="deleteGiftGroup(row)" v-if="authMenus.delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="searchFilter.currentPage"
      v-model:page-size="searchFilter.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="searchFilter.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdate
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseOrOpenDialog"
    :updateRowMsg="updateRowMsg"
  ></addOrUpdate>
</template>

<script setup>
import { onMounted, reactive, ref } from 'vue'
import addOrUpdate from './add-or-update.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/api'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'

//搜索条件
const searchFilter = reactive({
  categoryCode: '',
  productName: '',
  current: 1,
  pageSize: 10,
  total: 0,
})

//重制搜索条件
const reseatFilter = () => {
  searchFilter.categoryCode = ''
  searchFilter.productName = ''
  searchFilter.current = 1
  searchFilter.pageSize = 10
  getTableData()
}

const dialogVisible = ref(false) //弹出框
const updateRowMsg = ref({}) //需要操作的某一行数据
/**
 * 打开或者关闭弹出框
 * @param {boolean} val
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  if (!val) {
    getTableData()
  }
}

const productTypes = ref([])
const getProductTypes = () => {
  API.selectByType({
    pageSize: 100,
    current: 1,
    type: 1,
  }).then(res => {
    productTypes.value = res.data.records.map(item => {
      return {
        value: item.code,
        label: item.name,
      }
    })
  })
}
/**
 * 获取表单数据
 */
const tableData = ref([])
const getTableData = async () => {
  if (authMenus.value.query) {
    let { data } = await API.selectInfoPage(searchFilter)
    tableData.value = data.records
    searchFilter.total = data.total
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

const headerTitles = ref([])
const getTableHeader = async () => {
  let { data } = await API.getTableHeader()
  headerTitles.value = data
}

const authMenus = ref({})
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  getProductTypes()
  await getTableHeader()
  getTableData()
})

/**
 *新增赠品组
 */
const addGiftGroup = () => {
  updateRowMsg.value = {
    levelCommon: [],
  }
  handleCloseOrOpenDialog(true)
}

/**
 * 修改某一行数据
 * @param {object} row
 */
const updateGiftGroup = row => {
  let levelCommon = {}
  Object.keys(row.levelCommon).forEach(key => {
    levelCommon[key] = parseInt(row.levelCommon[key])
  })
  updateRowMsg.value = {
    code: row.code,
    levelCommon: [
      {
        code: row.code,
        ...levelCommon,
        productName: row.productName,
        categoryName: row.categoryName,
        categoryCode: row.categoryCode,
        productCode: row.productCode,
      },
    ],
  }
  handleCloseOrOpenDialog(true)
}

/**
 * 删除某一行数据
 * @param {object} row
 */
const deleteGiftGroup = row => {
  ElMessageBox.confirm('确认删除当前赠品吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let { code, msg } = await API.deleteByCode({ code: row.code })
    if (code === '200') {
      ElMessage.success('删除成功')
      getTableData()
    } else {
      ElMessage.error(msg)
    }
  })
}

//分页
const handleSizeChange = val => {
  searchFilter.pageSize = val
  getTableData()
}
const handleCurrentChange = val => {
  searchFilter.current = val
  getTableData()
}
</script>

<style scoped lang="scss">
.member-card-coupou {
  padding: 20px 10px;

  .el-pagination {
    text-align: right;
  }
}
</style>
