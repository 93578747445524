<!--
 * @Author: your name
 * @Date: 2022-04-19 17:20:24
 * @LastEditTime: 2022-04-19 18:02:09
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/component/table.vue
-->
<template>
  <div>
    <el-table :data="tableData" style="width: 100%" height="calc(100vh - 220px)">
      <el-table-column prop="date" label="门店" width="180" />
      <el-table-column prop="name" label="来源" width="180" />
      <el-table-column prop="address" label="场次号" />
      <el-table-column prop="address" label="手机号" />
      <el-table-column prop="address" label="包厢名称" />
      <el-table-column prop="address" label="支付时间" />
      <el-table-column prop="address" label="场次状态" />
      <el-table-column label="操作">
        <template #default>
          <el-button type="text" @click="handleClick">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div></template
>
<script></script>
