const TableHead = [
  {
    prop: 'fullName',
    label: '分类名称',
    placeholder: '请输入店铺名称',
    labelWidth: '150px',
    width: '300',
    type: 'input',
    validate: function() {
      return [
        {
          required: true,
          message: '请输入店铺名称',
          trigger: 'blur',
        },
      ]
    },
  },
  {
    prop: 'shortName',
    label: '分类类型',
    formwidth: '50%',
    type: 'input',
    placeholder: '请输入店名',
    labelWidth: '100px',
  },
  {
    prop: 'tool',
    label: '操作',
    width: '240',
    formhidden: true,
  },
]

export default TableHead
