/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-08-03 15:28:57
 * @FilePath: /saas/src/store/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createStore, createLogger } from 'vuex'
import VuexPersistence from 'vuex-persist'

import mutations from './mutation'
import state from './state'
import * as getters from './getter'
import actions from './action'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: stateData => ({
    user: stateData.user,
    loggedIn: stateData.loggedIn,
    permissions: stateData.permissions,
  }),
})

const debug = process.env.NODE_ENV !== 'production'

export default createStore({
  state,
  getters,
  actions,
  mutations,
  strict: debug,
  plugins: debug ? [vuexLocal.plugin, createLogger()] : [vuexLocal.plugin],
})
