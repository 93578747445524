<template>
  <el-dialog v-model="visible" title="新增供货" width="70%" :before-close="handleClose">
    <div style="display: flex;padding-left: 10px; height: 600px;padding-top: 30px;">
      <div style="height: 100%;">
        <el-card class="box-card" style="height:100%;">
          <el-tabs style="width: 160px; height: 100%;" v-model="form.activeName" @tab-click="changeTab">
            <el-tab-pane label="商品" :name="1">
              <el-scrollbar>
                <p
                  v-for="(item, index) in form.productTypes"
                  :key="index"
                  class="productItem"
                  :class="{ activeItem: item.activeItemStyle }"
                  @click="selectCategoryCode(item)"
                >
                  {{ item.name }}
                </p>
              </el-scrollbar>
            </el-tab-pane>
            <el-tab-pane label="易耗品" :name="4">
              <el-scrollbar>
                <p
                  v-for="(item, index) in form.articleTypes"
                  :key="index"
                  class="productItem"
                  :class="{ activeItem: item.activeItemStyle }"
                  @click="selectCategoryCode(item)"
                >
                  {{ item.name }}
                </p>
              </el-scrollbar>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>

      <div style="width: calc(100% - 140px);display:flex; flex-direction: column;">
        <el-form :inline="true" :model="form" style="margin-left: 20px;">
          <el-form-item label="商品名称">
            <el-input placeholder="请输入搜索的商品名称" v-model="form.productName"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="">查询</el-button>
            <el-button type="primary" @click="onSubmit">确定</el-button>
          </el-form-item>
        </el-form>
        <div style="flex:1;overflow:auto">
          <el-table :data="form.skuListsSelect" style="width: 94%; margin-left: 2%;" height="400">
            <el-table-column align="center">
              <template #header>
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="form.checkAll"
                  @change="handleCheckAllChange"
                ></el-checkbox>
              </template>
              <template #default="{row}">
                <el-checkbox v-model="row.checked" />
              </template>
            </el-table-column>
            <el-table-column label="序号" align="center" type="index" :index="indexfn1" />
            <el-table-column label="商品图片" prop="pic" width="100" align="center">
              <template #default="scope">
                <div style="display: flex; justify-content: center;">
                  <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品名称" align="center" />
            <el-table-column prop="spec" label="商品规格" align="center" />
            <!-- <el-table-column prop="unit" label="采购单位" align="center" /> -->
            <el-table-column prop="unit" label="最小单位" align="center" />
          </el-table>
          <el-pagination
            style="margin-right: 6%;"
            v-model:current-page="form.currentPage"
            v-model:page-size="form.pageSize"
            :small="small"
            :page-sizes="[5, 10, 20]"
            :disabled="disabled"
            :background="background"
            layout="total, sizes, prev, pager, next, jumper"
            :total="form.total"
          />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, onMounted, watchEffect, watch } from 'vue'
import _ from 'lodash'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    code: String,
  },
  setup(props, ctx) {
    const form = reactive({
      categoryCode: undefined,
      productName: '',
      checkAll: false,
      activeName: 1,
      tableData: [],
      skuListsSelect: [],
      currentPage: 1,
      pageSize: 5,
      total: 100,
      loading: false,
      productTypes: [],
      articleTypes: [],
      skuLists: [],
      skuListsSelect: [],
      checkAll: false,
    })
    onMounted(() => {
      queryAllShopTyps()
      queryAllArticlesTyps()
      selectBelowProductSkuArray()
    })
    watchEffect(() => {
      form.checkAll = false
      let start = (form.currentPage - 1) * form.pageSize
      let end = form.currentPage * form.pageSize
      form.total = form.skuLists.filter(item => item.name.includes(form.productName)).length
      let newArr = form.skuLists.filter(item => item.name.includes(form.productName)).slice(start, end)
      form.skuListsSelect = [...newArr]
    })
    watch(
      () => form.activeName,
      () => {
        selectBelowProductSkuArray()
      },
    )

    const selectBelowProductSkuArray = () => {
      let params = {
        categoryCode: form.categoryCode,
        // productName: form.productName,
        type: form.activeName,
      }
      API.supplierQuerySku(params).then(res => {
        if (res.code === '200') {
          form.skuLists = res.data.map(item => ({ ...item, checked: false }))
          form.total = res.data.length
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const selectCategoryCode = item => {
      let a = form.articleTypes.find(item => item.activeItemStyle)
      let p = form.productTypes.find(item => item.activeItemStyle)

      if (a) {
        a.activeItemStyle = false
      }
      if (p) {
        p.activeItemStyle = false
      }

      item.activeItemStyle = true
      form.categoryCode = item.code
      selectBelowProductSkuArray()
    }
    const visible = computed(() => {
      return props.dialogVisible
    })
    const clickSecondMenu = activeItem => {
      let isActiveItem = form.productTypes.find(item => item.isActive)
      if (isActiveItem) {
        isActiveItem.isActive = false
      }
      if (activeItem !== isActiveItem) {
        activeItem.isActive = true
      }
    }
    //查询所有商品类型
    const queryAllShopTyps = () => {
      API.selectListByType({ type: 1 }).then(res => {
        if (res.code === '200') {
          form.productTypes = res.data
          form.productTypes.splice(0, 0, { name: '全部', value: '-1', activeItemStyle: 'true' })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const queryAllArticlesTyps = () => {
      API.selectListByType({ type: 4 }).then(res => {
        if (res.code === '200') {
          form.articleTypes = res.data
          form.articleTypes.splice(0, 0, { name: '全部', value: '-1' })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const indexfn1 = computed(() => {
      return (form.currentPage - 1) * form.pageSize + 1
    })

    const onSubmit = _.throttle(
      () => {
        let params = form.skuLists
          .filter(item => item.checked)
          .map(item => {
            return {
              supplierCode: props.code,
              categoryCode1: item.categoryCode1,
              adoptPinThan: 1,
              goodsCost: 0,
              miniCount: 0,
              recoveryPrice: 0,
              miniUnit: item.unit,
              purchaseUnit: item.unit,
              skuCode: item.code,
              spec: item.spec,
              spuCode: '',
              num: 1,
              type: form.activeName,
              specifications: item.spec,
            }
          })

        if (params.length == 0) {
          return
        }

        API.saveBindProduct(params).then(res => {
          if (res?.code == 200) {
            ElMessage.success('商品已入供货清单')
            form.checkAll = false
            selectBelowProductSkuArray()
          }
        })
      },
      3000,
      { trailing: false },
    )
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false, search: true })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false, search: true })
    }
    const handleCheckAllChange = () => {
      form.skuListsSelect.forEach(element => {
        element.checked = form.checkAll
      })
    }

    const changeTab = () => {
      let a = form.articleTypes.find(item => item.activeItemStyle)
      let b = form.productTypes.find(item => item.activeItemStyle)
      if (a) {
        a.activeItemStyle = false
      }
      if (b) {
        b.activeItemStyle = false
      }
      form.categoryCode = ''
      if (form.activeName == 1) {
        form.productTypes[0].activeItemStyle = true
      } else {
        form.articleTypes[0].activeItemStyle = true
      }
      selectBelowProductSkuArray()
    }

    return {
      form,
      indexfn1,
      onSubmit,
      handleClose,
      backtrack,
      clickSecondMenu,
      props: { multiple: true },
      visible,
      handleCheckAllChange,
      selectCategoryCode,
      changeTab,
    }
  },
})
</script>
<style lang="scss" scoped>
:deep(.el-tab-pane) {
  height: 100%;
}
.activeItem {
  background-color: rgba(127, 127, 127, 0.3);
}
.productItem {
  height: 40px;
  line-height: 40px;
  padding-left: 10px;
  margin-bottom: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  &:hover {
    background-color: rgba(127, 127, 127, 0.3);
  }
}
.el-card {
  ::v-deep .el-card__body {
    height: 90%;
  }
  ::v-deep .el-tabs__content {
    height: 90%;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
