<template>
  <div class="member-card-coupou">
    <el-form :inline="true">
      <el-form-item label="门店">
        <el-select-v2
          v-model="filterSearchs.storeCode"
          :options="storeList"
          filterable
          placeholder="请选择门店"
          clearable
        />
      </el-form-item>
      <el-form-item label="优惠方案 ">
        <el-select v-model="filterSearchs.type" clearable>
          <el-option v-for="item in types" :label="item.label" :key="item.code" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="优惠券名称">
        <el-input v-model="filterSearchs.couponRuleName" placeholder="请输入优惠券名称" clearable></el-input>
      </el-form-item>
      <el-form-item label="优惠券id">
        <el-input v-model="filterSearchs.code" placeholder="请输入优惠券id" clearable></el-input>
      </el-form-item>
      <el-form-item label="使用场景 ">
        <el-select v-model="filterSearchs.usedScenario" clearable>
          <el-option v-for="item in usedScenario" :label="item.label" :key="item.code" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="券状态 ">
        <el-select v-model="filterSearchs.isCancel" clearable>
          <el-option v-for="item in status" :label="item.label" :key="item.code" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="queryTableData">查询</el-button>
        <el-button @click="resetSearch">重置</el-button>
        <el-button @click="addMemberCardCoupou" v-if="authMenus.add">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" v-loading.fullscreen.lock="dataPage.loading" element-loading-text="正在火速加载数据...">
      <el-table-column show-overflow-tooltip label="名称" prop="name" align="center"></el-table-column>
      <el-table-column show-overflow-tooltip label="优惠券id" prop="code" align="center"></el-table-column>
      <el-table-column show-overflow-tooltip label="使用场景" prop="usedScenario " align="center">
        <template #default="{row}">
          {{ usedScenarioType[row.usedScenario] }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="优惠方案" align="center">
        <template #default="{row}">
          {{ typesObj[row.type] }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip align="center" label="优惠值">
        <template #default="{row}"> {{ row.scheme }}{{ unitsObj[row.type] }} </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip align="center" label="有效期" prop="validityTimeStr"></el-table-column>
      <el-table-column show-overflow-tooltip align="center" label="发放数量" prop="realIssueNum">
        <template #default="scope">
          {{ scope.row.issueNum === -1 ? '不限制' : scope.row.realIssueNum }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip align="center" label="节假日不可用" prop="">
        <template #default="{row}">
          {{ row.isHolidaysExcepted === 0 ? '可用' : '不可用' }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="适用门店" align="center">
        <template #default="scope">
          <span v-if="scope.row.attemptStore == -1">全部门店</span>
          <span v-else style="color:rgb(0, 217, 255);cursor: pointer;" @click="showStore(scope.row)">指定门店</span>
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip align="center" label="状态" prop="">
        <template #default="{row}">
          <el-tag v-if="row.isCancel === 0">正常</el-tag>
          <el-tag v-else-if="row.isCancel === 1" type="error">已作废</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template v-slot="{ row }">
          <el-button type="text" @click="updateMemberCardCoupou(row)" v-if="authMenus.update && row.isCancel === 0"
            >编辑</el-button
          >
          <el-button type="text" @click="deleteMemberCardCoupou(row)" v-if="authMenus.delete && row.isCancel === 0"
            >作废</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="filterSearchs.pageNum"
      v-model:page-size="filterSearchs.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="filterSearchs.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdate
    :authMenus="authMenus"
    v-if="dialogVisible"
    @handleCloseDialog="handleCloseOrOpenDialog"
    :updateRowMsg="updateRowMsg"
  ></addOrUpdate>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import addOrUpdate from './add-or-update.vue'
import API from '../../service/index'
import { ElMessage, ElMessageBox, ElNotification } from 'element-plus'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'

//权限
const authMenus = ref({})
const route = useRoute()
operationAuth(route, authMenus.value)

const status = [
  {
    label: '正常',
    code: 0,
  },
  {
    label: '作废',
    code: 1,
  },
]
const usedScenario = [
  {
    label: '收银结账',
    code: 1,
  },
  {
    label: '小程序预定',
    code: 2,
  },
]

//优惠类型
const types = [
  {
    code: 1,
    label: '折扣',
  },
  {
    code: 2,
    label: '优惠金额',
  },
  {
    code: 3,
    label: '抵时长',
  },
  {
    code: 4,
    label: '优惠价',
  },
]
const typesObj = {
  1: '折扣',
  2: '优惠金额',
  3: '抵时长',
  4: '优惠价',
}

const unitsObj = {
  1: '折',
  2: '元',
  3: '分钟',
  4: '元',
}

const usedScenarioType = {
  1: '收银结账',
  2: '小程序预定',
}

const storeList = ref([])
/**
 * 获取门店
 */
const getStores = async () => {
  let res = await API.getStoreListByName({ storeName: '' })
  storeList.value = res.data.map(item => {
    return { value: item.code, label: item.code + '--' + item.name }
  })

  // filterSearchs.storeCode = storeList.value[0].value
}
onMounted(async () => {
  await getStores()
  queryTableData()
})

//筛选条件
const filterSearchs = reactive({
  type: '',
  storeCode: '',
  couponRuleName: '',
  pageSize: 10,
  pageNum: 1,
  total: 0,
  code: '',
  isCancel: undefined,
})
//表哥数据
const tableData = ref([])
let dataPage = reactive({
  loading: false,
})
/**
 * 获取table数据
 */
const queryTableData = async () => {
  if (authMenus.value.query) {
    dataPage.loading = true
    let params = Object.assign({}, filterSearchs)
    delete params.total
    params = {
      ...params,
      isCancel: params.isCancel === undefined || params.isCancel === '' ? -1 : params.isCancel,
    }
    let { data } = await API.selectCouponPage(params).finally(() => {
      dataPage.loading = false
    })
    tableData.value = data.records || []
    filterSearchs.total = data.totalRecords
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}
queryTableData()

/**
 * 重置筛选条件
 */
const resetSearch = () => {
  filterSearchs.type = ''
  filterSearchs.couponRuleName = ''
  filterSearchs.code = ''
  filterSearchs.storeCode = ''
  filterSearchs.pageSize = 10
  filterSearchs.pageNum = 1
  filterSearchs.total = 0
  filterSearchs.isCancel = undefined

  queryTableData()
}

const dialogVisible = ref(false) //弹出框
const updateRowMsg = ref({}) //需要操作的某一行数据
/**
 * 打开或者关闭弹出框
 * @param {boolean} val
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  if (!val) {
    queryTableData()
  }
}

/**
 *新增优惠卷
 */
const addMemberCardCoupou = () => {
  updateRowMsg.value = {
    isFixedStore: 1,
    type: 3,
    usedRange: 1,
    termOfValidity: 1,
    issueNum: -1,
    minimumCharge: undefined,
    usedTimeRange: -1,
    dayOfWeek: [1],
    dayOfWeekDetailsList: [{ dayOfWeek: 1, isAllDay: 1, dayTime: '', position: 8 }],
    time: ['', ''],
    skuInfoList: [],
    limitType: 1,
    limitValue: '',
    limitValue1: '',
    usedScenario: 1,
  }
  handleCloseOrOpenDialog(true)
}

/**
 * 展示指定门店信息
 */
const showStore = data => {
  ElNotification({
    title: '指定的门店',
    message: `当前包含的门店有：${data.storeNames ? data.storeNames : '苏州店，厦门店，上海店'}`,
    duration: 0,
  })
}

/**
 * 修改某一行数据
 * @param {object} row
 */
const updateMemberCardCoupou = async ({ code }) => {
  let { data } = await API.queryCouponRuleDetails({ code })

  const days = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 1, 2, 3, 4, 5, 6, 7, 8]
  data.dayOfWeekDetailsList.forEach(a => {
    let s = a.startTime.split(':')[0]
    let e = a.endTime.split(':')[0]
    if (a.isAllDay == 1) {
      a.startTime = ''
      a.endTime = ''
      return
    }
    a.startTimeIndex = days.findIndex(b => b == s)
    if (a.isNextDay == 1 && e == 8) {
      a.endTimeIndex = days.length - 1
    } else {
      a.endTimeIndex = days.findIndex(b => b == e)
    }
    a.startTime = s
    a.endTime = e
  })
  data.dayOfWeekDetailsList = data.dayOfWeekDetailsList.map(a => ({
    ...a,
    position: a.dayOfWeek == 1 ? 8 : a.dayOfWeek,
  }))

  updateRowMsg.value = {
    ...data,
    isHolidaysExcepted: data.isHolidaysExcepted === 1 ? true : false,
    time: [data.validityStart, data.validityEnd],
    dayOfWeek: JSON.parse(data.dayOfWeek),
    skuInfoList: data.skuInfoList || [],
    isFixedStore: data.attemptStore == -1 ? 0 : 1,
    attemptStore: data.attemptStore == -1 ? [] : JSON.parse(data.attemptStore),
    limitValue1: data.limitType == 3 ? data.limitValue : undefined,
    minimumCharge: data.minimumCharge == -1 ? undefined : data.minimumCharge,
  }
  handleCloseOrOpenDialog(true)
}

/**
 * 删除某一行数据
 * @param {object} row
 */
const deleteMemberCardCoupou = async ({ code }) => {
  ElMessageBox.confirm('确认作废该优惠券？', '操作确认', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.cancelCoupon({ code })
    if (res.code === '200') {
      ElMessage.success('作废成功')
      queryTableData()
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const handleSizeChange = val => {
  // 当每页显示的记录数发生变化时，更新 pageSize 变量
  filterSearchs.pageSize = val
  queryTableData()
}
const handleCurrentChange = val => {
  // 当当前页码发生变化时，更新 currentPage 变量
  filterSearchs.pageNum = val
  queryTableData()
}
</script>

<style scoped lang="scss">
.member-card-coupou {
  padding: 20px 10px;

  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
