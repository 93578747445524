/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-07-25 11:07:44
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '门店库存',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/store-inventory',
  order: null,
  inNav: true,
  children: [
    {
      title: '商品实时库存',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/store-inventory/goods-real-time-inventory',
      filePath: 'plugin/store-inventory/view/goods-real-time-inventory/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '商品实时库存-全国',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/store-inventory/goods-real-time-inventory-new',
      filePath: 'plugin/store-inventory/view/goods-real-time-inventory-new/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    // {
    //   title: '商品出入库记录',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/store-inventory/goods-in-out',
    //   filePath: 'plugin/store-inventory/view/goods-in-out/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true,
    //   },
    // },
    {
      title: '商品盘点记录',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/store-inventory/goods-inventory-record',
      filePath: 'plugin/store-inventory/view/goods-inventory-record/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '会员存酒',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/store-inventory/wine-storage-inventory',
      filePath: 'plugin/store-inventory/view/wine-storage-inventory/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    // {
    //   title: '存取酒记录',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/store-inventory/liquor-access-record',
    //   filePath: 'plugin/store-inventory/view/liquor-access-record/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true,
    //   },
    // },
    // {
    //   title: '出入库类型管理',
    //   type: 'view',
    //   name: 'ImgsUploadDemo',
    //   route: '/store-inventory/in-out-inventory',
    //   filePath: 'plugin/store-inventory/view/in-out-inventory/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    // },
  ],
}

export default linCmsUiRouter
