import OSS from 'ali-oss'
export function client() {
  var client = new OSS({
    endpoint: 'oss-cn-hangzhou.aliyuncs.com', //填写Bucket所在地域
    accessKeyId: 'LTAI5tNxzd8mPuFvUqRpzxRd',
    accessKeySecret: '9E4D3WkfQsyLuCjY0kacoAoqYavOZs',
    bucket: 'oss-hangzhou-user', // 填写Bucket名称。
  }) //后端提供数据
  return client
}
const headers = {
  'Access-Control-Allow-Origin': '*',
  // 指定该Object被下载时的网页缓存行为。
  'Cache-Control': 'no-cache',
  // 指定该Object被下载时的名称。
  //   "Content-Disposition": "example.txt",
  // 指定该Object被下载时的内容编码格式。
  'Content-Encoding': 'utf-8',
  // 指定过期时间，单位为毫秒。
  //   Expires: "1000",
  // 指定Object的存储类型。
  //   "x-oss-storage-class": "Standard",
  // 指定Object的访问权限。
  'x-oss-object-acl': 'public-read',
  // 指定Object标签，可同时设置多个标签。
  'x-oss-tagging': 'Tag1=1&Tag2=2',
  // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
  'x-oss-forbid-overwrite': 'true',
}

export const httpPre = 'https://xjh-saas-cloud.oss-cn-shanghai.aliyuncs.com'

export function posClient() {
  console.log(process.env.VUE_APP_POS_accessKeyNewId)
  var client = new OSS({
    secure: true,
    endpoint: process.env.VUE_APP_POS_region, //填写Bucket所在地域
    accessKeyId: process.env.VUE_APP_POS_accessKeyNewId,
    accessKeySecret: process.env.VUE_APP_POS_accessKeyNewSecret,
    bucket: process.env.VUE_APP_POS_NEW_bucket, // 填写Bucket名称。
  }) //后端提供数据
  return client
}
export { headers }
