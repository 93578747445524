<template>
  <el-dialog v-model="visible" title="新增" width="70%" :before-close="handleClose">
    <el-form :inline="true" :model="form" style="margin-left: 20px;">
      <el-form-item label="采购门店" required>
        <el-select-v2
          v-model="form.storeCode"
          :options="form.shops"
          filterable
          :disabled="form.selectDisabled"
          placeholder="请选择门店"
          @change="handleChangeStore"
        />
      </el-form-item>
    </el-form>

    <div style="display: flex; justify-content: space-between; ">
      <h2>商品清单</h2>
      <el-button type="primary" @click="selectProduct">选择商品</el-button>
    </div>

    <div v-for="(item, index) in form.purchaseInfoList" :key="index" style="margin-top: 20px;">
      <div style="display: flex; justify-content: space-between;margin-bottom: 10px;">
        <div>
          <span>{{ item.supplierName }}</span>
          <span
            v-if="isShowTishi(item)"
            style="margin-left: 20px;background-color: red;border-radius: 3px;display: inline-block;
                  height: 30px;line-height: 30px;padding: 0 14px;color: white;"
            >未达到起订金额：¥{{ item.miniPrice }}</span
          >
        </div>
        <div>
          <el-button type="primary" @click="addPurchase(item)">继续加购</el-button>
          <el-button type="primary" @click="cancelPurchase(item, index)">取消采购</el-button>
        </div>
      </div>
      <el-table :data="item.detailList" style="width: 94%; margin-left: 2%;">
        <el-table-column label="序号" align="center" type="index" />
        <el-table-column label="商品图片" prop="pic" width="100" align="center">
          <template #default="scope">
            <div style="display: flex; justify-content: center;">
              <img :src="scope.row.skuPic" alt="" style="width:30px; height: 30px;" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="skuName" label="商品名称" align="center" />
        <el-table-column prop="spec" label="商品规格" align="center" />
        <el-table-column prop="purchaseUnit" label="采购单位" align="center" />
        <el-table-column prop="miniUnit" label="最小单位" align="center" />
        <el-table-column prop="adoptPinThan" label="采购比(1:N)" align="center" />
        <el-table-column prop="price" label="采购单价(元)" align="center" />
        <el-table-column label="采购数量" align="center">
          <template #default="{row}">
            <el-input-number
              v-model="row.num"
              placeholder="请输入整数"
              :precision="0"
              :controls="false"
              @blur="updatePurchaseOrder(row)"
            ></el-input-number>
          </template>
        </el-table-column>
        <el-table-column prop="miniUnitCount" label="最小单位数量" align="center">
          <template #default="{row}">
            {{ row.num * row.adoptPinThan }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #default="{row}">
            <el-button @click="deletePro(item.detailList, row.code)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="text-align: center;margin-top: 20px;">
        <el-button type="primary" @click="handleSavePurchase">确定</el-button>
        <el-button type="primary" @click="handleClose">返回</el-button>
      </div>
    </div>

    <select-product
      :versionUid="form.code"
      :storeCode="form.storeCode"
      :cityCode="form.cityCode"
      :storeName="form.storeName"
      :purchaseInfo="form.purchaseInfo"
      v-if="form.selectProductDialogVisible"
      :dialogVisible="form.selectProductDialogVisible"
      @update-dialogVisible="updateDialogVisible"
    ></select-product>
  </el-dialog>
</template>

<script>
import _ from 'lodash'
import SelectProduct from './select-product.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import API from '../../service/api.js'
import { reactive, defineComponent, computed, ref, onMounted, toRaw, watchEffect } from 'vue'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
  },
  components: {
    SelectProduct,
  },
  setup(props, ctx) {
    const form = reactive({
      code: '',
      cityCode: '',
      purchaseInfo: {},
      shops: [],
      storeCode: undefined,
      storeName: undefined,
      selectProductDialogVisible: false,
      purchaseInfoList: [],
    })
    onMounted(() => {
      generateVersionUid()
      getShops()
    })

    form.selectDisabled = computed(() => {
      if (form.purchaseInfoList.length == 0) {
        return false
      }
      for (let i = 0; i < form.purchaseInfoList.length; i++) {
        if (form.purchaseInfoList[i]?.detailList.length !== 0) {
          return true
        }
      }
      return false
    })

    //展示是否达到起订金额
    const isShowTishi = data => {
      let totalCount = data.detailList.reduce((a, b) => {
        return parseFloat(a) + parseInt(b.adoptPinThan) * parseFloat(b.price) * parseInt(b.num)
      }, 0)
      if (totalCount < data.miniPrice) {
        data.isEnable = true
        return true
      } else {
        data.isEnable = false
        return false
      }
    }

    const visible = computed(() => {
      return props.dialogVisible
    })

    const handleChangeStore = () => {
      form.storeName = form.shops.find(item => item.value == form.storeCode).label
      form.cityCode = form.shops.find(item => item.value == form.storeCode).cityCode
    }

    //获取id
    const generateVersionUid = () => {
      API.generateVersionUid().then(res => {
        form.code = res.msg
      })
    }

    //修改商品
    const updatePurchaseOrder = row => {
      row.miniUnitCount = row.num * row.adoptPinThan
      API.updatePurchaseOrder(row).then(res => {
        if (res?.code == 200) {
          ElMessage.success('修改成功')
        }
      })
    }

    const selectPurchaseDetails = () => {
      API.selectPurchaseDetails({
        versionUid: form.code,
      }).then(res => {
        form.purchaseInfoList = res.data.purchaseInfoList || []
      })
    }

    //选择商品
    const selectProduct = () => {
      if (!form.storeCode) {
        ElMessage.info('请先选择采购门店')
        return
      }
      form.selectProductDialogVisible = true
      form.purchaseInfo = {}
    }

    //继续加购
    const addPurchase = item => {
      form.selectProductDialogVisible = true
      form.purchaseInfo = item
    }

    //取消采购
    const cancelPurchase = (item, index) => {
      let params = {
        supplierCode: item.supplierCode,
        versionUid: form.code,
      }
      API.cancelPurchaseInDetails(params).then(res => {
        if (res?.code == 200) {
          ElMessage.success('操作成功')
          form.purchaseInfoList.splice(index, 1)
        }
      })
    }

    //提交采购单
    const handleSavePurchase = _.throttle(
      () => {
        for (let i = 0; i < form.purchaseInfoList.length; i++) {
          if (form.purchaseInfoList[i].isEnable) {
            return ElMessage.warning('未达到起订金额无法提交')
          }
        }

        let params = {
          versionUid: form.code,
        }
        API.commitPurchaseOrder(params).then(res => {
          if (res?.code == 200) {
            ElMessage.success('操作成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          }
        })
      },
      2000,
      { trailing: false },
    )

    //删除某一个商品
    const deletePro = (data, code) => {
      API.deleteDetailsGoods({ code: code }).then(res => {
        if (res?.code == 200) {
          ElMessage.success('操作成功')
          let index = data.findIndex(item => item.code == code)
          data.splice(index, 1)
        }
      })
    }

    const getShops = () => {
      API.getStoreList(null).then(res => {
        if (res.code === '200') {
          form.shops = res.data.map(item => ({
            value: item.code,
            label: item.name,
            cityCode: item.city,
          }))
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const updateDialogVisible = ({ update, search }) => {
      form.selectProductDialogVisible = update
      if (search) {
        selectPurchaseDetails()
        // selectBelowProductSkuArray()
      }
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false, search: true })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false, search: false })
    }
    return {
      form,
      handleClose,
      backtrack,
      visible,
      selectProduct,
      updateDialogVisible,
      addPurchase,
      cancelPurchase,
      deletePro,
      updatePurchaseOrder,
      handleSavePurchase,
      isShowTishi,
      handleChangeStore,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep.el-input-number {
  width: 100%;
}
.product-list-top {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 10px;
  div {
    width: 100px;
    margin-left: 20px;
    text-align: center;
    line-height: 30px;
    border: 1px solid grey;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
.active {
  background-color: grey;
}
.article-title {
  width: 200px;
  span {
    font-size: 15px;
    font-weight: 500;
  }
}
</style>
