<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportPaymentOrderDay"
      :emptyText="'暂无数据'"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../component/base/searchbar/index.vue'
import Table from '../component/table.vue'
import api from '../service/api'
import { downLoad } from '../../../lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        salesName: '',
        orderCode: '',
        openCode: '',
        roomCode: '',
        productName: '',
        storeCodeList: [],
        regionCodeList: [],
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'storeCode',
          label: '门店编号',
          width: '100',
        },
        {
          prop: 'storeName',
          label: '门店名称',
          width: '200',
        },
        {
          prop: 'productName',
          label: '商品/套餐名称',
          width: '200',
        },
        {
          prop: 'isConsumeFree',
          label: '套餐场景',
          width: '100px',
          formatter: val => {
            return val.storeCode == '合计' ? '' : !val.isConsumeFree ? '-' : val.isConsumeFree
          },
        },
        {
          prop: 'count',
          label: '销量',
          width: '100',
        },
        {
          prop: 'roomCode',
          label: '包厢',
          width: '100',
        },
        {
          prop: 'orderCode',
          label: '订单号',
          width: '100',
        },
        {
          prop: 'openCode',
          label: '场次号',
          width: '200',
        },
        {
          prop: 'originalCreateTime',
          label: '订单生成时间',
          width: '200',
        },
        {
          prop: 'userName',
          label: '销售员',
          width: '200',
        },
        {
          prop: 'staffId',
          label: '工号',
          width: '100',
        },
        {
          prop: 'commissionRule',
          label: '提成规则',
          width: '100',
        },
        {
          prop: 'commissionPrice',
          label: '提成单价',
          width: '200',
        },
        {
          prop: 'amount',
          label: '提成总额',
          width: '200',
        },
        {
          prop: 'staffAmount',
          label: '员工提成金额（60%）',
          width: '200',
          // formatter: val => {
          //   return val.storeCode != '合计' ? val.goodsRate + '%' : ''
          // },
        },
        {
          prop: 'storeAmount',
          label: '门店提成金额（40%）',
          width: '200',
        },
      ],
      searchform: [
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '套餐场景',
          placeholder: '请选择套餐场景',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'isConsumeFree',
          options: [
            {
              label: '套餐',
              value: 0,
            },
            {
              label: '套免',
              value: 1,
            },
          ],
        },
        {
          label: '商品/套餐名称',
          type: 'input',
          labelWidth: '120px',
          prop: 'productName',
          placeholder: '请输入商品/套餐名称',
        },
        {
          label: '包厢',
          type: 'input',
          labelWidth: '120px',
          prop: 'roomCode',
          placeholder: '请输入包厢',
        },
        {
          label: '场次号',
          type: 'input',
          labelWidth: '120px',
          prop: 'openCode',
          placeholder: '请输入场次号',
        },

        {
          label: '订单号',
          type: 'input',
          labelWidth: '120px',
          prop: 'orderCode',
          placeholder: '请输入订单号',
        },
        {
          label: '销售员',
          type: 'input',
          labelWidth: '120px',
          prop: 'salesName',
          placeholder: '请输入销售员',
        },
        { label: '导出', type: 'exportButton', url: '/report/commission/exportSalesCommissionDetail', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    resetForm() {},
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[1].options = res.data
      })
    },
    reportPaymentOrderDay(model) {
      this.mergeTotalData(model.pageNum, '商品销售提成', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return Promise.reject('条件不满足')
      }
    },
    parentSearch(param) {
      const {
        roomCode,
        productName,
        openCode,
        orderCode,
        salesName,
        storeCodeList,
        regionCodeList,
        isConsumeFree,
      } = param
      this.model = {
        roomCode,
        productName,
        openCode,
        orderCode,
        salesName,
        storeCodeList,
        regionCodeList,
        isConsumeFree,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '商品销售提成', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }

      const {
        roomCode,
        productName,
        openCode,
        orderCode,
        salesName,
        storeCodeList,
        regionCodeList,
        isConsumeFree,
      } = param
      this.model = {
        roomCode,
        productName,
        openCode,
        orderCode,
        salesName,
        storeCodeList,
        regionCodeList,
        isConsumeFree,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      this.exportData('商品销售提成', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
