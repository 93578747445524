<template>
  <el-dialog v-model="visible" title="门店账户" width="40%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="form" label-width="120px">
      <el-form-item label="用户名" placeholder="请填写用户名">
        <el-input v-model="form.name" :disabled="!isEdit" />
      </el-form-item>
      <el-form-item label="姓名" placeholder="请填写姓名">
        <el-input v-model="form.name" :disabled="!isEdit" />
      </el-form-item>
      <el-form-item label="性别">
        <el-radio-group v-model="form.resource" :disabled="!isEdit">
          <el-radio label="男" />
          <el-radio label="女" />
        </el-radio-group>
      </el-form-item>
      <el-form-item label="手机号" placeholder="请填写手机号">
        <el-input v-model="form.name" :disabled="!isEdit" />
      </el-form-item>
      <el-form-item label="邮箱" placeholder="请填写邮箱">
        <el-input v-model="form.name" :disabled="!isEdit" />
      </el-form-item>
      <el-form-item label="所属角色">
        <el-select v-model="form.region" placeholder="请选择你的角色">
          <el-option label="店长角色" value="1" />
        </el-select>
      </el-form-item>
      <el-form-item label="所属门店">
        <el-cascader
          :disabled="!isEdit"
          v-model="value"
          :options="form.options"
          @change="handleChange"
          :show-all-levels="false"
          :props="props"
          filterable
        >
          <template #default="{ node, data }">
            <span>{{ data.label }}</span>
            <span v-if="!node.isLeaf"> ({{ data.children.length }}) </span>
          </template>
        </el-cascader>
      </el-form-item>
      <el-form-item label="状态">
        <el-radio-group v-model="form.resource" :disabled="!isEdit">
          <el-radio label="启用" />
          <el-radio label="停用" />
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">保存</el-button>
        <el-button @click="backtrack">返回</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed } from 'vue'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    isEdit: Boolean,
    accountMsg: Object,
  },
  setup(props, ctx) {
    const form = reactive({
      name: '',
      region: '',
      date1: '',
      date2: '',
      delivery: false,
      type: [],
      resource: '',
      desc: '',
      options: [
        {
          value: '苏州',
          label: '苏州',
          children: [
            {
              value: '苏州信投店',
              label: '苏州信投店',
            },
            {
              value: '苏州奥体店',
              label: '苏州奥体店',
            },
          ],
        },
      ],
    })

    const visible = computed(() => {
      return props.dialogVisible
    })

    const onSubmit = () => {
      ctx.emit('update-dialogVisible', false)
    }
    const backtrack = () => {
      ctx.emit('update-dialogVisible', false)
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', false)
    }
    return {
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
    }
  },
})
</script>
