<template>
  <el-upload
    :show-file-list="false"
    action="#"
    :http-request="uploadImg"
    class="avatar-uploader"
    :disabled="diableUpload"
  >
    <div class="img" @mouseenter="handleMouseEnterImg" @mouseleave="handleMouseLevelImg">
      <img v-if="imgUrl" :src="imgUrl" class="avatar" />
      <el-icon v-else class="avatar-uploader-icon" size="30"><Plus /></el-icon>
      <div v-if="imgUrl && showDeleteIcon" class="img-operation">
        <span class="el-upload-list__item-preview" @click.stop="handleDeleteImg">
          <el-icon size="30"><Delete /></el-icon>
        </span>
        <span class="el-upload-list__item-preview" @click.stop="handlePictureCardPreview">
          <el-icon size="30"><ZoomIn /></el-icon>
        </span>
      </div>
    </div>
  </el-upload>
  <el-dialog v-model="dialogVisible">
    <img w-full :src="imgUrl" alt="Preview Image" />
  </el-dialog>
</template>

<script setup>
import { headers, client } from '../../../lin/util/uploadImg.js'
import { Delete, Plus, ZoomIn } from '@element-plus/icons-vue'
import { ref, defineEmit } from 'vue'

const props = defineProps({
  //图片地址
  imageSrc: {
    type: String,
  },
  //属性名称
  propName: {
    type: String,
  },
})
const emit = defineEmit(['uploadImage'])

const showDeleteIcon = ref(false) //是否展示删除按钮
const imgUrl = ref(props.imageSrc) // 上传图片的url

/**
 * 上传图片凑走
 * @param {*} file
 */
const uploadImg = file => {
  if (checkImg(file)) {
    uploadIMgToClient(file)
  }
}

/**
 * 校验图片规则
 * @param {*} file
 */
const checkImg = file => {
  let size = file.file.size
  if (size > 100 * 1024 * 1024) {
    ElMessage.info('上传图片小于100M')
    return false
  }
  return true
}

/**
 * 上传图片到云端
 * @param {*} file
 */
const uploadIMgToClient = file => {
  let fileName = file.file.name + new Date()
  client()
    .put(fileName, file.file, { headers })
    .then(res => {
      imgUrl.value = res.res.requestUrls[0]
      isEnableUpload(false)
      emit('uploadImage', { imageUrl: imgUrl.value, propName: props.propName })
    })
}

/**
 * 鼠标移入事件
 */
const handleMouseEnterImg = () => {
  if (imgUrl.value) {
    isShowDeleteIcon(true)
  }
}
/**
 * 鼠标移出事件
 */
const handleMouseLevelImg = () => {
  isShowDeleteIcon(false)
}

/**
 * 删除图片
 */
const handleDeleteImg = () => {
  imgUrl.value = ''
  isEnableUpload(true)
  emit('uploadImage', { propName: props.propName })
}

const diableUpload = ref(false)

/**
 * 是否可以上传
 * @param {boolean} val
 */
const isEnableUpload = val => {
  diableUpload.value = !val
}

/**
 * 是否展示删除按钮
 * @param {boolean} val
 */
const isShowDeleteIcon = val => {
  showDeleteIcon.value = val
}

const dialogVisible = ref(false) // 展示缩略图
const handlePictureCardPreview = () => {
  dialogVisible.value = true
}
</script>

<style scoped lang="scss">
.remark {
  color: rosybrown;
}

.avatar-uploader {
  ::v-deep(.el-upload) {
    border: 1px dashed #ccc;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
  }
  .img {
    width: 178px;
    height: 178px;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    &:hover {
      opacity: 0.5;
    }

    .img-operation {
      position: absolute;
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      .el-upload-list__item-preview {
        color: white;
      }
    }
  }
}
::v-deep(.el-icon) {
  cursor: pointer;
}
</style>
