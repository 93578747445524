<!--
 * @Author: your name
 * @Date: 2022-04-21 19:45:16
 * @LastEditTime: 2022-12-21 17:23:34
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/view/member-accessRecord-manager/index.vue
-->
<template>
  <div class="member-accessRecord-box">
    <SearchBar :authMenus="authMenus" searchTypes="sccessRecord" :getTableData="getTableData" :model="model" />
    <el-table
      :data="tableData"
      style="width: 100%"
      class="el-table-header-gap table-content"
      @filter-change="filterTagTable"
    >
      <el-table-column prop="userName" label="用户名" width="180" />
      <el-table-column prop="telephone" label="手机号" width="180" />
      <el-table-column prop="storeName" label="存酒门店" />
      <el-table-column prop="levelCode" label="等级">
        <template #default="scope">
          <div>
            {{ getLevelName(scope.row.levelCode) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="最近存取时间" sortable />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)" v-if="authMenus.query">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :authMenus="authMenus"
      :totalRecords="totalRecords"
      :totalPage="totalPage"
      :model="model"
      :getTableData="getTableData"
    />
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import SearchBar from '../../component/tableSearchTab.vue'
import Pagination from '../../component/pagination.vue'
import api from '../../service/index'
export default {
  components: { SearchBar, Pagination },
  data() {
    return {
      authMenus: {},
      tableData: [],
      model: {
        pageNum: 1,
        pageSize: 10,
        storeCode: '',
        telephone: '',
        levelCode: '',
      },
      totalRecords: null,
      totalRecords: null,
      totalPage: null,
      levelList: [],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)

    if (this.authMenus.query) {
      this.getTableData(this.model)
      this.getLevelList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getTableData(model) {
      if (this.authMenus.query) {
        api.getWineLogGroupListPages(model).then(res => {
          this.tableData = res.data.records
          this.totalRecords = res.data.totalRecords
          this.totalPage = res.data.totalPage
        })
      } else {
        ElMessage.warning('抱歉，你没有查看权限')
      }
    },
    filterTagTable(filters) {
      this.model.levelCode = filters.levelCode[0]
      this.getTableData(this.model)
    },
    getLevelList() {
      api.getLevelList().then(res => {
        this.levelList = res.data.map(item => {
          return { text: item.name, value: item.code }
        })
      })
    },
    getLevelName(level) {
      let obj = this.levelList.find(item => item.value === level)
      return obj && obj.text
    },
    filterLevel(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    showDetail(index, item) {
      this.$router.push({
        path: '/member_manager/member-accessRecord-manager/infoDetail',
        query: { id: item.code, telephone: item.telephone },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.member-accessRecord-box {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .table-content {
    height: 100%;
    overflow: auto;
  }
}
</style>
