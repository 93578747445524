/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-09-01 18:02:48
 * @FilePath: /saas/src/plugin/supplyChain-manager/service/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import _axios, { post, get, put, _delete } from '@/lin/plugin/axios'

class SupplyMng {
  //分页查询供应商列表
  async getSupplierListWithCategoryPages(data) {
    const res = await post(`/adminChain/supplier/getSupplierListWithCategoryPages`, data)
    return res
  }
  //查询单个供应商
  async supplierGetOne(data) {
    const res = await get(`/adminChain/supplier/getOne`, data)
    return res
  }

  //删除单个供应商
  async deleteSupplier(data) {
    const res = await _delete(`/adminChain/supplier/delete/${data}`)
    return res
  }

  //获取绑定门店列表
  async getBindStoreCodeList(data) {
    const res = await get(`/adminChain/supplier/getBindStoreCodeList`, data)
    return res
  }

  //获取绑定品类列表
  async getBingCategory1List(data) {
    const res = await get(`/adminChain/supplier/getBingCategory1List`, data)
    return res
  }
  //获取绑定商品列表
  async getBingGoodsList(data) {
    const res = await get(`/adminChain/supplier/getBingGoodsList`, data)
    return res
  }
  //上传证件图片
  async uploadFile(data) {
    const res = await post(`/adminChain/supplier/uploadFile`, data)
    return res
  }
  //  //查询需要上传的供应商证件列表
  async getCertificateDictList(data) {
    const res = await get(`/adminChain/supplier/getCertificateDictList`, data)
    return res
  }
  //新增供应商
  async addSupplier(data) {
    const res = await post(`/adminChain/supplier/add`, data)
    return res
  }
  //修改供应商信息
  async updateSupplier(data) {
    const res = await post(`/adminChain/supplier/update`, data)
    return res
  }

  //解绑品类
  async deletCategory1ByCode(data) {
    const res = await _delete(`/adminChain/supplier/unBindCategory1ByCode/${data}`)
    return res
  }
  //修改绑定品类信息
  async updateBindCategory1DataByCode(data) {
    const res = await post(`/adminChain/supplier/updateBindCategory1DataByCode`, data)
    return res
  }

  //批量绑定品类
  async batchBindCategory1Code(data) {
    const res = await post(`/adminChain/supplier/batchBindCategory1Code`, data)
    return res
  }

  //获取分配到门店的商品列表
  async getBingGoodsList(data) {
    const res = await get(`/adminChain/supplier/getBingGoodsList`, data)
    return res
  }

  //解绑门店
  async unBindStoreByCode(data) {
    const res = await _delete(`/adminChain/supplier/unBindStoreByCode/${data}`)
    return res
  }
  //查看商品分类和绑定物料列表
  async getCategoryListWithMatterList(data) {
    const res = await get(`/adminChain/supplier/getCategoryListWithMatterList`, data)
    return res
  }
  //查看物料分组
  async getMatterListGroupByCategory(data) {
    const res = await get(`/adminChain/supplier/getMatterListGroupByCategory`, data)
    return res
  }
  //批量绑定物料列表
  async batchAddMatterList(data) {
    const res = await post(`/adminChain/supplier/batchAddMatterList`, data)
    return res
  }

  //获取物料列表
  async getMatterListPages(data) {
    const res = await get(`/adminChain/supplier/getMatterListPages`, data)
    return res
  }

  //进货单位
  async unitList(data) {
    const res = await get(`/adminChain/goods/dict/unitList`, data)
    return res
  }

  //删除单个物料
  async deleteMatter(data) {
    const res = await _delete(`/adminChain/supplier/deleteMatter/${data}`)
    return res
  }
  //修改物料信息
  async updateMatter(data) {
    const res = await post(`/adminChain/supplier/updateMatter`, data)
    return res
  }

  //获取未绑定门店列表
  async getStoreList(data) {
    const res = await get(`/adminChain/supplier/getStoreList`, data)
    return res
  }

  //批量绑定门店
  async batchBindStoreList(data) {
    const res = await post(`/adminChain/supplier/batchBindStoreList`, data)
    return res
  }
  //获取门店已绑定商品列表（根据品类分类）
  async getStoreAlreadyBindGoodsList(data) {
    const res = await get(`/adminChain/supplier/getStoreAlreadyBindGoodsList`, data)
    return res
  }

  //修改绑定门店的信息
  async updateBindStoreDataByCode(data) {
    const res = await post(`/adminChain/supplier/updateBindStoreDataByCode`, data)
    return res
  }
  //获取所有单位
  async getSkuDictList() {
    const res = await get(`goods/dict/list`, {
      type: '110100',
    })
    return res
  }

  //获取品类类别
  async getCategoryList() {
    const res = await get(`/adminChain/material/category/list`, {
      type: 2,
    })
    return res
  }
  //获取订单列表
  async getOrderListPages(data) {
    const res = await post(`/adminChain/supplier/reconciliation/getOrderListPages`, data)
    return res
  }
  //获取订单详情
  async getOrderDetail(data) {
    const res = await get(`/adminChain/supplier/reconciliation/getOrderDetail`, data)
    return res
  }
  //获取供应商列表
  async getSupplierList(data) {
    const res = await get(`/adminChain/supplier/reconciliation/getSupplierList`, data)
    return res
  }

  //获取空瓶列表
  async getEmptyBottleListPages(data) {
    const res = await post(`/adminChain/supplier/reconciliation/getEmptyBottleListPages`, data)
    return res
  }
  //获取空瓶详情
  async getEmptyBottleDetail(data) {
    const res = await get(`/adminChain/supplier/reconciliation/getEmptyBottleDetail`, data)
    return res
  }

  //获取售后列表
  async getAfterSalesListPages(data) {
    const res = await post(`/adminChain/supplier/reconciliation/getAfterSalesListPages`, data)
    return res
  }
  //获取售后详情
  async getAfterSalesDetail(data) {
    const res = await get(`/adminChain/supplier/reconciliation/getAfterSalesDetail`, data)
    return res
  }
  //获取城市
  async listWithArea() {
    const res = await get(`store/listWithArea`, { showType: 2 })
    return res
  }
  //获取账单列表
  async getBillListPages(data) {
    const res = await post(`/adminChain/supplier/reconciliation/getBillListPages`, data)
    return res
  }
  //获取账单详情
  async getBillDetail(data) {
    const res = await get(`/adminChain/supplier/reconciliation/getBillDetail`, data)
    return res
  }
  //导出账单列表
  async exportBillExcel(data) {
    const res = await _axios({
      url: '/adminChain/supplier/reconciliation/exportBillExcel',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //导出空瓶列表
  async exportEmptyBottleList(data) {
    const res = await _axios({
      url: '/adminChain/supplier/reconciliation/exportEmptyBottleList',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //导出售后列表
  async exportAfterSalesList(data) {
    const res = await _axios({
      url: '/adminChain/supplier/reconciliation/exportAfterSalesList',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //导出订单列表
  async exportOrderExcel(data) {
    const res = await _axios({
      url: '/adminChain/supplier/reconciliation/exportOrderExcel',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //删除银行账号
  async deleteBankAccount(data) {
    const res = await _delete(`/adminChain/supplier/deleteBankAccount/${data}`)

    return res
  }
  //添加银行账号
  async addBankAccount(data) {
    const res = await post(`/adminChain/supplier/addBankAccount`, data)
    return res
  }

  //修改银行账号信息
  async updateBankAccount(data) {
    const res = await post(`/adminChain/supplier/updateBankAccount`, data)
    return res
  }
}

export default new SupplyMng()
