<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 12:34:09
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-14 10:22:38
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/supplyShop.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="supply-category-content">
    <el-button type="primary" @click="editAllCategory" class="binding-btn">编辑品类</el-button>
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap" @filter-change="filterTagTable">
      <el-table-column prop="categoryCode1" label="品类ID" width="180" />
      <el-table-column prop="categoryCodeName" label="品类名称" width="180" />
      <el-table-column
        prop="taxState"
        label="是否含税"
        :filters="taxStateColumns"
        :column-key="'taxState'"
        :filter-multiple="false"
      >
        <template #default="scope">
          <div>
            {{ scope.row.taxState === 0 ? '否' : '是' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="taxPoint" label="税点" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="editCategory(scope.$index, scope.row)">修改</el-button>
          <el-button type="text" class="delete-style" @click="deleteItem(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <EditCategory ref="editCategory" :getTableData="getTableData" :tableModel="model" />
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import EditCategory from './editCategory.vue'
import api from '../../service/index'
export default {
  components: { EditCategory },
  props: {
    code: {
      type: String,
      default: '',
    },
    supplierName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      totalRecords: null,
      totalPage: null,
      model: {
        pageNum: 1,
        pageSize: 100,
        supplierCode: this.code,
        taxState: '',
      },
      taxStateColumns: [
        //禁用启用
        { text: '否', value: 0 },
        { text: '是', value: 1 },
      ],
      editModal: false,
      rowItem: {},
    }
  },
  mounted() {
    this.getTableData(this.model)
  },
  methods: {
    filterTagTable(filters) {
      this.model.taxState = [0, 1].includes(filters.taxState[0]) ? filters.taxState[0] : ''
      this.getTableData(this.model)
    },
    getTableData(model) {
      api.getBingCategory1List(model).then(res => {
        this.tableData = res.data
      })
    },
    editCategory(index, row) {
      this.$refs.editCategory.isEdit = true
      this.$refs.editCategory.editModal = true
      this.$refs.editCategory.editInfo(row)
    },
    deleteItem(index, row) {
      api.deletCategory1ByCode(row.code).then(res => {
        ElMessage({
          type: 'success',
          message: `删除品类成功`,
        })
        this.getTableData(this.model)
      })
    },
    editAllCategory() {
      this.$refs.editCategory.isEdit = false
      this.$refs.editCategory.editModal = true
      this.$refs.editCategory.tableData = this.tableData
      this.$refs.editCategory.form.typeList = []
      this.$refs.editCategory.supplierCode = this.code
      this.$refs.editCategory.supplierName = this.supplierName
      this.tableData.map(item => {
        this.$refs.editCategory.form.typeList.push(item.categoryCodeName)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.supply-category-content {
  overflow: hidden;

  .binding-btn {
    float: right;
    margin-bottom: 15px;
  }

  .delete-style {
    color: #ff5959;
  }
}
</style>
