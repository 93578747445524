<template>
  <el-form :inline="true" :model="data" style="margin-top:10px;padding: 10px 10px 0  20px;">
    <el-form-item label="空瓶商品名称">
      <el-input placeholder="空瓶商品名称" v-model="data.emptyBottleName" class="input-search-tel" clearable></el-input>
    </el-form-item>
    <el-form-item label="三方物料编码">
      <el-input placeholder="物料编码" v-model="data.materielCode" class="input-search-tel" clearable></el-input>
    </el-form-item>
    <el-form-item>
      <el-button @click="searchLists" v-if="data.authMenus.query" type="primary">
        查询
      </el-button>
      <el-button @click="reset" v-if="data.authMenus.query">
        重置
      </el-button>
      <el-button @click="typeOperation(1)" v-if="data.authMenus.add"> 新增 </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    :element-loading-text="loadingText"
  >
    <el-table-column type="index" label="序号" align="center" width="100" />
    <el-table-column label="空瓶图片" align="center">
      <template #default="scope">
        <div style="display: flex; justify-content: center;">
          <img :src="scope.row.emptyBottleUrl" alt="" style="width:30px; height: 30px;" />
        </div>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="emptyBottleName" label="空瓶商品名称" align="center" />
    <el-table-column show-overflow-tooltip prop="emptyBottleSpec" label="规格" align="center" />
    <el-table-column show-overflow-tooltip prop="emptyBottleUnit" label="最小单位" align="center" />
    <el-table-column show-overflow-tooltip prop="materielCode" label="三方物料编码" align="center" />
    <el-table-column show-overflow-tooltip prop="createTime" label="创建时间" align="center" />
    <el-table-column label="操作" width="200" align="center">
      <template #default="scope">
        <el-button type="text" @click="typeOperation(2, scope.row)" v-if="data.authMenus.update">编辑</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <UpdateOrAdd
    v-if="data.addProductSpecVisible"
    :dialogVisible="data.addProductSpecVisible"
    :specRowMsg="data.specRowMsg"
    :units="data.units"
    @update-dialogVisible="updateAddProductSpecVisible"
  ></UpdateOrAdd>
</template>

<script setup>
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import API from '../../service/api'
import { reactive, onMounted, ref, nextTick } from 'vue'
import UpdateOrAdd from './update-or-add.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

const data = reactive({
  authMenus: {},
  spuName: undefined,
  skuName: undefined,
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 0,
  addProductSpecVisible: false,
  specRowMsg: {},
  units: [],
  loading: false,
  loadingText: '正在火速加载数据...',
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  searchLists()
  selectDictListAll()
})

const reset = () => {
  data.materielCode = undefined
  data.emptyBottleName = undefined
  searchLists()
}

//获取单位
const selectDictListAll = () => {
  API.selectDictListAll().then(res => {
    if (res.code === '200') {
      data.units = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//  查询列表
const queryLists = () => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  data.loadingText = '正在火速加载数据...'
  let queryParams = {
    emptyBottleName: data.emptyBottleName, //商品名称
    materielCode: data.materielCode,
    pageSize: data.pageSize,
    pageNum: data.currentPage,
  }
  API.queryGoodsEmptyBottleInfo(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}

//操作商品列表数据

const typeOperation = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      addProSpec()
      break
    case 2:
      editProSpec(rowMsg)
      break
  }
}

const addProSpec = () => {
  data.specRowMsg = {}
  data.addProductSpecVisible = true
}

const editProSpec = rowMsg => {
  API.queryTbGoodsEmptyBottleDetail({ id: rowMsg.id }).then(res => {
    if (res.code === '200') {
      data.specRowMsg = {
        ...res.data,
      }
      data.addProductSpecVisible = true
    } else {
      ElMessage.error(res.error)
    }
  })
}
const showProSpec = (rowMsg, parentRow) => {
  API.selectDetails({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseFloat(res.data.price.split('~')[0])
        res.data.price2 = parseFloat(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }

      data.specRowMsg = {
        shops: _.cloneDeep(data.shops),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        channel: res.data.channel.split(','),
        caseSpec: parseInt(res.data.caseSpec),
      }
      data.addProductSpecVisible = true
      data.isEdit = false
    } else {
      ElMessage.error(res.error)
    }
  })
}

const shopOrStartRow = rowMsg => {
  if (rowMsg.isShelves === 1) {
    ElMessageBox.confirm('下架当前商品将会下架与该商品绑定的组合套餐,确认下架吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }).then(() => {
      API.updateIsShelvesNoType({ code: rowMsg.code, isShelves: rowMsg.isShelves === 1 ? 0 : 1 }).then(res => {
        if (res.code === '200') {
          ElMessage.success('更新成功')
          queryLists()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
  } else {
    API.updateIsShelvesNoType({ code: rowMsg.code, isShelves: rowMsg.isShelves === 1 ? 0 : 1 }).then(res => {
      if (res.code === '200') {
        ElMessage.success('更新成功')
        queryLists()
      } else {
        ElMessage.error(res.msg)
      }
    })
  }
}

const specOperation = (index, rowMsg, parentRow) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showProSpec(rowMsg, parentRow)
      break
    case 2:
      editProSpec(rowMsg, parentRow)
      break
    case 3:
      shopOrStartRow(rowMsg)
      break
  }
}

const updateAddParentProductVisible = ({ update, search }) => {
  data.addParentProductVisible = update
  if (search) {
    queryLists()
  }
}
const updateAddProductSpecVisible = ({ update, search }) => {
  data.addProductSpecVisible = update
  if (search) {
    queryLists()
  }
}
const batchDeliveryVisible = ({ update, search }) => {
  data.batchDeliveryVisible = update
  if (search) {
    queryLists()
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-select-v2 {
  .el-tag--light {
    display: flex;
    align-items: center;
    max-width: 120px;
    span {
      display: block;
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
    }
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
::v-deep .el-form-item__content {
  display: flex;
}
.product-list-top {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 20px;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}

.search-select {
  width: 230px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
