<template>
  <el-dialog
    v-model="visible"
    :title="'code' in needUpdateInfo ? '编辑权益金比例' : '新增权益金比例'"
    width="40%"
    :before-close="handleClose"
  >
    <el-form :model="needUpdateInfo" ref="formRef" :rules="rules">
      <el-form-item label="门店" prop="storeCode">
        <el-select-v2
          v-model="needUpdateInfo.storeCode"
          :options="storeList"
          placeholder="请选择门店"
          class="search-select userSelect"
          style="width: 300px;"
          filterable
          clearable
        />
      </el-form-item>
      <el-form-item label="收取口径备注" label-width="120px">
        <el-input placeholder="请输入" v-model="needUpdateInfo.collectCaliberRemark" style="width: 300px;"></el-input>
      </el-form-item>
      <el-form-item label="收取口径" prop="collectCaliberProportion">
        <el-input v-model="needUpdateInfo.collectCaliberProportion" placeholder="请输入" style="width: 300px;">
          <template #suffix>
            %
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="门店市场推广服务费" prop="extensionProportion">
        <el-input v-model="needUpdateInfo.extensionProportion" placeholder="请输入" style="width: 300px;">
          <template #suffix>
            %
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="是否启用">
        <el-switch v-model="needUpdateInfo.isOpenSeparateAccount" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item label="启用时间" v-if="needUpdateInfo.isOpenSeparateAccount == 1" prop="time">
        <el-date-picker
          v-model="needUpdateInfo.time"
          type="daterange"
          start-placeholder="起始日期"
          format="YYYY-MM-DD"
          end-placeholder="结束日期"
          style="margin-right: 20px;"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveCustomerSourceConfig">
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import _ from 'lodash'
import API from '../../service/api'
import { ref, defineProps, defineEmit, reactive } from 'vue'
import { ElMessage } from 'element-plus'

const props = defineProps({
  needUpdateInfo: {
    type: Object,
    default: {},
  },

  storeList: {
    type: Array,
    default: () => [],
  },
})

const emit = defineEmit(['handleCloseDialog'])

const visible = ref(true)

/**
 * 关闭弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', {
    update: false,
  })
}

const checkCollectCaliberProportion = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请填写收取口径'))
  } else if (!/^[0-9]{1,2}(\.[0-9]{0,2})?$/.test(value)) {
    return callback(new Error('收取口径是数字，且最多保留两位'))
  } else {
    callback()
  }
}
const checkExtensionProportion = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('请填写门店市场推广服务费'))
  } else if (!/^[0-9]{1,2}(\.[0-9]{0,2})?$/.test(value)) {
    return callback(new Error('门店市场推广服务费是数字，且最多保留两位'))
  } else {
    callback()
  }
}

//校验规则
const formRef = ref(null)
const rules = {
  storeCode: [{ required: true, message: '请选择门店', trigger: 'change' }],
  collectCaliberProportion: [{ required: true, trigger: 'change', validator: checkCollectCaliberProportion }],
  extensionProportion: [{ required: true, trigger: 'change', validator: checkExtensionProportion }],
  sourceName: [{ required: true, message: '请填写客户来源名称', trigger: 'change' }],
  time: [{ required: true, message: '请选择时间', trigger: 'change' }],
}

const isFirst = ref(true)

/**
 */
const saveCustomerSourceConfig = async () => {
  try {
    await formRef.value.validate()
    let info = generatorSaveData()
    delete info.time
    let res
    if (!isFirst.value) {
      return
    }
    isFirst.value = false
    if ('code' in props.needUpdateInfo) {
      res = await API.storeEquityAmountConfigUpdate(info)
    } else {
      res = await API.storeEquityAmountConfigInset(info)
    }
    if (res.code !== '200') {
      ElMessage.error(res.msg)
      isFirst.value = true
    } else {
      ElMessage.success('操作成功')
      emit('handleCloseDialog', {
        update: true,
      })
    }
  } catch (e) {
    console.log(e)
    isFirst.value = true
  }
}

/**
 * 生成数据
 */
const generatorSaveData = () => {
  const user = JSON.parse(localStorage.getItem('vuex'))
  let customerCode = user.user.userInfo.userSaasUserVO.code
  return {
    ...props.needUpdateInfo,
    startDate: props.needUpdateInfo.time[0],
    endDate: props.needUpdateInfo.time[1],
    customerCode,
  }
}
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 170px !important;
  }
}
.el-form-item__content {
  height: 40px;
  align-items: center;
}
</style>
