<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-13 16:42:16
 * @FilePath: /saas/src/plugin/finance-reconciliation/view/report_module/cjmxReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
    />
    <div class="toast">
      <p>
        *短信字数＝短信模板内容字数 +
        签名字数。若短信字数小于70个字数，按照70个字数一条短信计算。若短信字数大于70个字数，即为长短信，按照67个字数记为一条短信计算
      </p>
      <p>
        *示例：短信字数为68，按1条短信计算；短信字数为134，按2条短信计算；短信字数为135，按3条短信计算。
      </p>
    </div>
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="model.storeCode ? '暂无数据' : '请选择门店后查询数据'"
      :getTableData="getReportStorageWineExpiredPages"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '@/component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/api'
import { downLoad } from '@/lin/util/util'
import { ElMessage } from 'element-plus'

const enmuNoticeType = {
  1: '包厢预订',
  2: '会员注册',
  3: '会员充值',
  4: '存酒',
  5: '取酒',
  6: '会员营销',
  7: '节日营销',
  8: '活动营销',
}
const enmuTemplateType = {
  0: '验证码',
  1: '短信通知',
  2: '推广短信',
  3: '国际/港澳台消息',
}
const enmuState = {
  0: '失败',
  1: '成功',
  2: '发送中',
}
export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        endTime: '',
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        storeCode: '',
        goodsCategory: '',
        goodsName: '',
        telephone: '',
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      columns: [
        {
          prop: 'createTime',
          label: '投放时间',
        },
        {
          prop: 'templateType',
          label: '通知类型',
        },
        {
          prop: 'state',
          label: '发送状态',
        },
        {
          prop: 'noticeType',
          label: '发送场景',
        },
        {
          prop: 'phoneNumber',
          label: '接收者',
        },
        {
          prop: 'templateContent',
          label: '发送内容',
        },
        {
          prop: 'telephone',
          label: '计费条数',
        },
        {
          prop: 'reason',
          label: '失败原因',
        },
      ],
      searchform: [
        {
          label: '操作日期',
          type: 'date-picker',
          labelWidth: '100px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm',
        },
        {
          label: '门店',
          type: 'select-v2',
          labelWidth: '50px',
          prop: 'storeCode',
          options: [],
          filterable: 'filterable',
        },
        {
          label: '发送状态',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'state',
          options: [
            {
              value: 0,
              label: '失败',
            },
            {
              value: 1,
              label: '成功',
            },
            {
              value: 2,
              label: '发送中',
            },
          ],
        },
        { label: '接收者', type: 'input', prop: 'phoneNumber', placeholder: '接收者手机号' },
        {
          label: '导出',
          type: 'exportButton',
          url: '/report/export/reportStorageWineExpired',
          auth: 'export',
        },
      ],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    this.getStoreList()
  },
  methods: {
    getStoreList() {
      api.getStoreListByName().then(res => {
        //门店接口
        this.searchform[1].options = res.data.map(item => {
          return { value: item.code, label: item.code + '--' + item.name }
        })
      })
    },
    getReportStorageWineExpiredPages(model) {
      api.smsPageList(model).then(res => {
        this.tableData = Object.freeze(
          res.data?.records.map(item => {
            ;({
              ...item,
              noticeType: enmuNoticeType[item.noticeType],
              templateType: enmuTemplateType[item.templateType],
              state: enmuState[item.state],
            })
          }) || [],
        )
        this.totalRecords = res.data?.totalRecords || 0
        this.totalPage = res.data?.totalPage || 0
      })
    },
    parentSearch(param) {
      const { phoneNumber, state, storeCode } = param
      this.model = {
        pageNum: this.model.pageNum,
        pageSize: this.model.pageSize,
        phoneNumber: phoneNumber,
        state: state,
        storeCode: storeCode,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.getReportStorageWineExpiredPages(this.model)
    },
    async exportReport(param) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { telephone, phoneNumber, state, storeCode } = param
      this.model = {
        telephone,
        pageNum: this.model.pageNum,
        pageSize: this.model.pageSize,
        phoneNumber: phoneNumber,
        state: state,
        storeCode: storeCode,
      }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''

      let url = this.searchform.find(item => item.label === '导出').url
      const res = await api.exportReport({ url, data: this.model })
      this.$refs.search.fullscreenLoading = false
      downLoad(res, '短信报表.xlsx')
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;

  .toast {
    height: 40px;
    p {
      line-height: 20px;
    }
  }
}
</style>
