/*
 * @Author: your name
 * @Date: 2022-04-07 11:14:02
 * @LastEditTime: 2022-04-27 14:52:11
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/view/login/api/index.js
 */
import _axios, { post, get, put } from '@/lin/plugin/axios'
class CsMng {
  //商品分类
  async getCategoryList(type) {
    const res = await get(`goods/category/list`, { type: type })
    return res
  }
  //手机号密码登录
  async passwordLogin(data) {
    const res = await post(`/adminChain/account/passwordLogin`, data)
    return res
  }
  // 发送验证码
  async sendSmsCode(data) {
    const res = await get(`/adminChain/account/sendSmsCode`, data)
    return res
  }
  //手机号验证码登录
  async smsCodeLogin(data) {
    const res = await post(`/adminChain/account/smsCodeLogin`, data)
    return res
  }
  //校验验证码
  async verifySmsCodeNotDelCache(data) {
    const res = await get(`/adminChain/account/verifySmsCodeNotDelCache`, data)
    return res
  }
  //忘记密码
  async forgetPassword(data) {
    const res = await post(`/adminChain/account/forgetPassword`, data)
    return res
  }
  //修改密码
  async changeSaasUserSecret(data) {
    const res = await post(`/adminChain/account/changeSaasUserSecret`, data)
    return res
  }
}

export default new CsMng()
