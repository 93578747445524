<!--
 * @Author: your name
 * @Date: 2022-04-19 14:39:00
 * @LastEditTime: 2022-12-14 15:54:51
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/view/shop-session-manager/index.vue
-->
<template>
  <div class="shop-session-manager">
    <SearchBar :authMenus="authMenus" :getTableData="getTableData" :model="model" searchType="sessionType" />

    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap ">
      <el-table-column prop="storeName" label="门店" width="180" />
      <el-table-column prop="source" label="来源" width="180" :filters="sourceColumns" :filter-method="filterSource">
        <template #default="scope">
          <div>
            {{ sourceList[scope.row.source] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="code" label="场次号" />
      <el-table-column prop="telephone" label="手机号" />
      <el-table-column prop="roomName" label="包厢名称" />
      <el-table-column prop="payTime" label="支付时间" sortable />
      <el-table-column prop="status" label="场次状态" :filters="sessionStatusColumns" :filter-method="filterSource">
        <template #default="scope">
          <div :class="scope.row.status === 6 ? 'abnormal-color' : 'expense-ing-color'">
            {{ sessionStatusList[scope.row.status] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" v-if="authMenus.query" @click="showDetail(scope.$index, scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagination
      :authMenus="authMenus"
      :totalRecords="totalRecords"
      :totalPage="totalPage"
      :model="model"
      :getTableData="getTableData"
    />

    <el-drawer
      v-model="infoModal"
      title="苏州信投店-A02/小包"
      size="70%"
      :direction="direction"
      :with-header="false"
      :before-close="handleClose"
    >
      <div class="session-detail-info">
        <div class="info-left">
          <h1>
            {{ abnormalPayDetail.storeName }}-{{ abnormalPayDetail.roomName }}/{{ abnormalPayDetail.roomTypeName }}
            <span>{{ sessionStatusList[abnormalPayDetail.status] }}</span>
          </h1>
          <div class="info-line"></div>
          <ul class="info-list">
            <li>场次：{{ abnormalPayDetail.code }}</li>
            <li>时段：{{ abnormalPayDetail.startTime }}-{{ abnormalPayDetail.endTime }}</li>
            <li>会员：{{ abnormalPayDetail.userName }}-{{ abnormalPayDetail.telephone }}</li>
          </ul>
          <div class="info-imgs">
            <h2 class="info-title">图片</h2>
            <ul>
              <li v-for="item in abnormalPayDetail.abnormalPics">
                <el-image :src="item" class="img-style" fit="cover" :preview-src-list="item" />
              </li>
            </ul>
          </div>
          <div class="info-remark">
            <h2 class="info-title">备注</h2>
            <p>{{ abnormalPayDetail.abnormalRemarks }}</p>
          </div>
        </div>
        <div class="info-right">
          <h1>
            消费详情
            <el-icon :size="20" color="#BFBFBF" class="close-icon">
              <close />
            </el-icon>
          </h1>
          <ul>
            <li>商品总额：</li>
            <li>¥{{ abnormalPayDetail.totalPrice }}</li>
            <li>商品详情：</li>
            <li>
              <p v-for="item in goodsDetailList">
                <span>{{ item.skuName }}</span>
                <span>¥{{ item.price / 100 }}</span>
              </p>
            </li>
            <li>应收：</li>
            <li>¥{{ abnormalPayDetail.totalPrice }}</li>
            <li>实收：</li>
            <li>¥{{ abnormalPayDetail.payMoney }}</li>
            <li>支付方式：</li>
            <li>
              {{ payTypeList[abnormalPayDetail.abnormalPayType] }}
            </li>
            <li>支付时间：</li>
            <li>{{ abnormalPayDetail.payTime }}</li>
            <li>未收：</li>
            <li id="official-receipts">¥{{ abnormalPayDetail.abnormalMoney }}</li>
          </ul>
          <el-button type="primary" class="info-confirm" @click="agreeAbnormal">确定</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import SearchBar from '../../component/tableSearchTab.vue'
import Pagination from '../../component/pagination.vue'
import {
  sourceList,
  sourceColumns,
  sessionStatusList,
  sessionStatusColumns,
  payTypeList,
} from '../../../../constants/config'
import moment from 'moment'
import _ from 'lodash'
import api from '../../service/index'
export default {
  components: { SearchBar, Pagination },
  data() {
    return {
      authMenus: {},
      options: [],
      tableData: [],
      input2: '',
      background: false,
      infoModal: false, //详情Modal
      model: {
        pageNum: 1,
        pageSize: 10,
        roomName: '',
        endDate: moment(new Date()).format('YYYY-MM-DD'),
        startDate: moment(new Date()).format('YYYY-MM-DD'),
        storeCode: '',
        telephone: '',
      },
      recordsDetail: {},
      totalRecords: null, //总共数据条数
      totalPage: null, //总共页数
      sourceColumns: sourceColumns,
      sourceList: sourceList,
      sessionStatusList: sessionStatusList,
      sessionStatusColumns: sessionStatusColumns,
      abnormalPayDetail: {}, //异常场次详情
      payTypeList: payTypeList,
      goodsDetailList: [],
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getTableData(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    getTableData(model) {
      api.getAdminChainOpenPages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    showDetail(index, row) {
      if (row.status === 6) {
        this.infoModal = true
        api.getAbnormalPayDetail({ code: row.code }).then(res => {
          this.abnormalPayDetail = res.data
          this.abnormalPayDetail.abnormalPics = this.abnormalPayDetail.abnormalPics.split(',')
          const list = []
          _.forEach(this.abnormalPayDetail.orderGoodsBoList, child => {
            _.forEach(child.orderGoodsDetailList, item => {
              list.push({ price: item.price, skuName: item.skuName })
            })
          })
          this.goodsDetailList = list
        })
      } else {
        this.$router.push({
          path: '/room-manager/shop-session-manager/infoDetail',
          query: {
            name: row.roomName,
            id: row.code,
          },
        })
      }
    },
    handleClose() {
      this.infoModal = false
    },
    filterSource(value, row, column) {
      const property = column['property']
      return row[property] === value
    },
    agreeAbnormal() {
      api.agreeOperaTeAbnormalPayOPen({ orderOpenCode: this.abnormalPayDetail.code }).then(res => {
        this.infoModal = false
        this.getTableData(this.model)
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.shop-session-manager {
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .abnormal-color {
    color: #f59400;
  }

  .expense-ing-color {
    color: #007aff;
  }

  .shop-session-search {
    display: flex;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 41px;

    div {
      flex: 1;
    }

    div:last-child {
      text-align: right;
    }
  }

  .session-detail-info {
    display: flex;
    position: relative;

    .info-left,
    .info-right {
      flex: 1;
      box-sizing: border-box;
      // padding-top: 73px;
    }

    .info-right {
      background-color: #f8faff;
      box-sizing: border-box;
      padding-right: 40px;
      padding-left: 40px;

      h1 {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        height: 97px;
        line-height: 97px;
        position: relative;

        .close-icon {
          position: absolute;
          top: 20px;
          right: 0px;
        }
      }

      ul {
        font-size: 16px;

        li {
          p {
            display: flex;
            line-height: 26px;

            span {
              flex: 1;
            }

            span:last-child {
              text-align: right;
            }
          }
        }

        #official-receipts {
          color: #f66c6a;
        }

        li:nth-child(2n-1) {
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          margin-bottom: 10px;
        }

        li:nth-child(2n) {
          font-size: 16px;
          font-family: D-DIN-Bold, D-DIN;
          font-weight: normal;
          color: #222222;
          margin-bottom: 24px;
        }
      }

      .info-confirm {
        position: fixed;
        bottom: 24px;
        right: 40px;
        width: 121px;
        height: 40px;
      }
    }

    .info-left {
      width: 588px;
      box-sizing: border-box;
      padding-left: 40px;
      height: 100vh;

      h1 {
        height: 73px;
        line-height: 73px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;

        span {
          display: inline-block;
          width: 80px;
          height: 22px;
          background: #fff2e8;
          border-radius: 4px;
          border: 1px solid #ffbb96;
          height: 20px;
          font-size: 12px;
          font-family: Helvetica;
          color: #fa541c;
          margin-left: 40px;
          line-height: 22px;
          text-align: center;
        }
      }

      .info-line {
        width: 588px;
        height: 1px;
        background-color: #ebeef5;
        margin-bottom: 25px;
      }

      .info-list {
        margin-bottom: 40px;

        li {
          height: 30px;
          line-height: 30px;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
        }
      }

      .info-imgs {
        margin-bottom: 40px;

        ul {
          width: 588px;

          li {
            display: inline-block;

            .img-style {
              width: 180px;
              height: 180px;
              border-radius: 8px;
            }
          }

          li:nth-child(2) {
            margin-right: 24px;
            margin-left: 24px;
          }
        }
      }

      .info-remark {
        width: 588px;

        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 26px;
          padding-left: 20px;
        }
      }

      .info-title {
        box-sizing: border-box;
        margin-bottom: 24px;
        width: 588px;
        height: 40px;
        line-height: 40px;
        background: #f0f2f5;
        border-radius: 4px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        padding-left: 24px;
        color: #2e2f31;
        -webkit-text-stroke: 1px #979797;
      }
    }
  }
}
</style>
