<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-14 11:22:11
 * @FilePath: /saas/src/component/layout/app-main.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <section class="container">
    <div class="wrapper" id="wrapper">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" :key="$route.title" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" :key="$route.title" v-if="!$route.meta.keepAlive" />
      </router-view>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AppMain',
}
</script>

<style lang="scss" scoped>
.container {
  overflow-x: hidden;

  .wrapper {
    width: 100%;
    min-height: 100%;
    text-align: left;
    padding-bottom: 30px;
  }
}
</style>
