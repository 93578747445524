<template>
  <el-dialog v-model="dialogVisible" width="70%" :before-close="handleClose">
    <p style="margin: 20px;color: red;">注：门店主动营销，请务必选择对应的发券门店</p>
    <el-form :model="updateRowMsg" :rules="rules" ref="formRef">
      <el-form-item label="短信发送方" label-width="120px" prop="aa">
        <div class="origin-type">
          <div
            v-if="!isEdit || authMenus.headCoupon"
            class="type-item "
            :class="{ active: updateRowMsg.type === 1 }"
            @click="handleChangeOriginType(1)"
          >
            总部发券
          </div>
          <div
            v-if="!isEdit || authMenus.storeCoupon"
            class="type-item"
            :class="{ active: updateRowMsg.type === 2 }"
            @click="handleChangeOriginType(2)"
          >
            门店发券
          </div>
        </div>
      </el-form-item>
      <el-form-item label="选择门店" label-width="120px" prop="storeCode" v-if="updateRowMsg.type === 2">
        <el-select-v2
          v-model="updateRowMsg.storeCode"
          :options="shops"
          placeholder="请选择门店"
          filterable
          clearable
          style="width: 300px;"
        />
      </el-form-item>
      <el-form-item label="用户手机号" label-width="120px" prop="telephones">
        <el-input
          type="textarea"
          :rows="8"
          placeholder="请输入手机号，多个手机号用英文逗号隔开，一次最多可录入3000个手机号"
          v-model="updateRowMsg.telephones"
        >
        </el-input>
      </el-form-item>
      <el-form-item label="发送优惠券" label-width="120px" prop="sendCoupnMapList">
        <el-button @click="addCoupou">添加优惠券</el-button>
        <el-table class="coupon-table" :data="pageData">
          <el-table-column label="优惠券" prop="name" align="center" width="440">
            <template #default="scope">
              <div class="coupou-item">
                <div class="left">
                  <text>{{ scope.row.scheme }}{{ unitsObj[scope.row.type] }}</text>
                  <text>{{ scope.row.minimumCharge === -1 ? '无门槛' : scope.row.minimumCharge }}</text>
                </div>
                <div class="right">
                  <text>{{ scope.row.name }}</text>
                  <div>有效期：{{ scope.row.validityTimeStr }}</div>
                  <text>仅{{ scope.row.usedRange === 1 ? '房费' : '商品' }}可用</text>
                  <text>发行数量：{{ scope.row.issueNum === -1 ? '不限制' : scope.row.realIssueNum }}</text>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="数量" align="center">
            <template v-slot="scope">
              <el-input-number v-model="scope.row.ruleSetNum" size="small" :max="5" :min="1"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template #default="{row}">
              <el-button @click="deleteMustSelectProduct(row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          v-model:current-page="pageParams.pageNum"
          v-model:page-size="pageParams.pageSize"
          :small="small"
          :disabled="disabled"
          :page-sizes="[5, 10, 20, 30]"
          :background="background"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageParams.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit" :disabled="!isEdit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
  <SelectCoupou
    :isAll="true"
    v-if="coupouDialogVisible"
    :dialogVisible="dialogVisible"
    @update-dialogVisible="rectiveProducts"
    :needFilterStore="true"
    :storeCodeList="storeCodeList"
  ></SelectCoupou>
</template>

<script setup>
import API from '../../service/index'
import { ref, defineEmit, defineProps, reactive, watchEffect, onMounted } from 'vue'
import SelectCoupou from '../../component/select-coupou.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

/**
 * 父容器传过来的参数
 */
const props = defineProps({
  updateRowMsg: {
    type: Object,
  },
  isEdit: {
    type: Boolean,
    default: false,
  },
  authMenus: {
    type: Array,
    default: () => [],
  },
})

//分页参数
const pageParams = reactive({
  pageNum: 1,
  pageSize: 5,
  total: 0,
})
//分页
const handleSizeChange = val => {
  pageParams.pageSize = val
}
const handleCurrentChange = val => {
  pageParams.pageNum = val
}
const pageData = ref([]) //分页数据

onMounted(() => {
  getShops()
})
watchEffect(() => {
  let start = pageParams.pageSize * (pageParams.pageNum - 1)
  let end = start + pageParams.pageSize
  pageData.value = props.updateRowMsg.sendCoupnMapList.slice(start, end)
  pageParams.total = props.updateRowMsg.sendCoupnMapList.length
})
const unitsObj = {
  2: '元',
  3: '分钟',
  4: '元',
}

const storeCodeList = ref([])
/**添加优惠卷 */
const addCoupou = () => {
  storeCodeList.value = [props.updateRowMsg.storeCode]
  isShowCoupouDialog(true)
}
/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['handleCloseDialog'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', false)
}

const shops = ref([])
const getShops = () => {
  API.getStoreList().then(res => {
    if (res.code === '200') {
      shops.value = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

/**
 * @description: 设置当前短信发送方
 * @param {*} data
 * @return {*}
 */
const handleChangeOriginType = data => {
  props.updateRowMsg.type = data
}

/**
 * 删除当前优惠卷
 * @param {object} row
 */
const deleteMustSelectProduct = row => {
  ElMessageBox.confirm('确认删除当前优惠券吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let deleteIndex = props.updateRowMsg.sendCoupnMapList.findIndex(a => a.code === row.code)
    props.updateRowMsg.sendCoupnMapList.splice(deleteIndex, 1)
    if (props.updateRowMsg.sendCoupnMapList.length <= 10) {
      formRef.value.validateField('sendCoupnMapList')
    }
  })
}

// 选择优惠卷
const coupouDialogVisible = ref(false) //商品选择组件展示

/**
 * 接受新数据
 * @param {object} item 选择的商品
 */
const rectiveProducts = ({ selectSkus }) => {
  isShowCoupouDialog(false)
  injectNewProduct(props.updateRowMsg.sendCoupnMapList, selectSkus)
}

/**
 * 注入新数据
 * @param {array} oldProducts  老数据
 * @param {array} newProducts  新数据
 */
const injectNewProduct = (oldProducts, newProducts) => {
  oldProducts = oldProducts || []
  newProducts.forEach(n => {
    let old = oldProducts.find(o => o.code === n.code)
    if (old) {
      old.ruleSetNum++
    } else {
      oldProducts.push({
        ...n,
        ruleSetNum: 1,
      })
    }
  })
}

/**
 * 是否展示选择优惠卷ialog
 */
const isShowCoupouDialog = val => {
  coupouDialogVisible.value = val
}

/**
 * 校验优惠卷数量
 */
const validateCoupons = (rule, value, callback) => {
  if (!value || value.length <= 10) {
    return callback()
  } else {
    return callback(new Error('优惠券种类不能超过10'))
  }
}

const formRef = ref(null)
const rules = {
  telephones: [{ required: true, trigger: 'change', message: '请输入手机号' }],
  storeCode: [{ required: true, trigger: 'change', message: '请选择门店' }],
  sendCoupnMapList: [{ required: false, validator: validateCoupons, trigger: 'change' }],
}

/**
 * 保存
 */
const handleCommit = async () => {
  try {
    await formRef.value.validate()
    let params = generateParams()
    let res = await API.addSendCoupon(params)

    if (res.code === '200') {
      ElMessage.success('操作成功')
      handleClose()
    } else {
      ElMessage.error(res.msg)
    }
  } catch (error) {
    ElMessage.warning('请完成必要信息填写')
  }
}

/**
 * 生成参数
 */
const generateParams = () => {
  let params = Object.assign({}, props.updateRowMsg)
  params.sendCoupnMapList = params.sendCoupnMapList.map(item => ({
    num: item.ruleSetNum,
    ruleCode: item.code,
    ruleName: item.name,
  }))
  params.telephones = params.telephones.replaceAll('，', ',').replaceAll('\n', '')
  return params
}
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
.remark {
  color: rosybrown;
}
.coupon-table {
  margin-top: 5px;
}
.el-input-number {
  width: 100%;
}
::v-deep(.el-input__inner) {
  text-align: left;
}
.timer-toast {
  margin-left: 5px;
  color: rosybrown;
}
.coupou-item {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  .left {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-right: 1px solid #ccc;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }
}

.origin-type {
  display: flex;
  .type-item {
    width: 140px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .active {
    background-color: #4187f2;
    color: white;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
