/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-07-25 11:07:44
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/room-manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '采购管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/purchase-manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '供应商管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/purchase-manager/provider-manager',
      filePath: 'plugin/purchase-manager/view/provider-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '供应商管理(新)',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/purchase-manager/provider-manager-new',
      filePath: 'plugin/purchase-manager/view/provider-manager-new/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '供应商列表(新)-供货空瓶商品',
      name: 'merchantmanager-produce',
      route: '/purchase-manager-new/ArticleManager',
      filePath: 'plugin/purchase-manager/view/provider-manager-new/ArticleManager.vue',
      inNav: false,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '供应商列表(新)-供货门店',
      name: 'merchantmanager-shop',
      route: '/purchase-manager-new/ShopManager',
      filePath: 'plugin/purchase-manager/view/provider-manager-new/ShopManager.vue',
      inNav: false,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '供应商列表-商品管理',
      name: 'merchantmanager-produce',
      route: '/purchase-manager/ArticleManager',
      filePath: 'plugin/purchase-manager/view/provider-manager/ArticleManager.vue',
      inNav: false,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '供应商列表-门店管理',
      name: 'merchantmanager-shop',
      route: '/purchase-manager/ShopManager',
      filePath: 'plugin/purchase-manager/view/provider-manager/ShopManager.vue',
      inNav: false,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '采购订单',
      type: 'view',
      name: 'merchantmanager-order',
      route: '/purchase-manager/purchase-order',
      filePath: 'plugin/purchase-manager/view/purchase-order/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
