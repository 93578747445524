<template>
  <el-dialog v-model="visible" width="65%" :before-close="handleClose">
    <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 10px 0  10px;">
      <el-form-item label="券包编号">
        <el-input v-model="data.couponPackageCode" clearable></el-input>
      </el-form-item>
      <el-form-item label="券包名称">
        <el-input v-model="data.couponPackageName" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="selectList">查询</el-button>
        <el-button @click="backtrack">取消</el-button>
        <el-button @click="onsubmit" type="primary">确定</el-button>
      </el-form-item>
    </el-form>

    <el-empty description="暂无数据" :image-size="100" v-if="data.skuLists.length === 0" />
    <div v-if="data.skuLists.length !== 0" style="display: flex; flex-wrap: wrap; max-height: 600px; overflow-y: auto;">
      <el-table :data="data.skuLists" style="width: 100%" height="480" ref="elTable">
        <el-table-column align="center" width="50">
          <template #header>
            <el-checkbox v-model="data.checkAll" @change="handleCheckAllChange"></el-checkbox>
          </template>
          <template #default="{row}">
            <el-checkbox v-model="row.checked" @change="handleCheckAllOne(row)" />
          </template>
        </el-table-column>
        <el-table-column label="序号" align="center" type="index" :index="indexfn" width="100" />
        <el-table-column show-overflow-tooltip label="券包编号" align="center" prop="code" />
        <el-table-column show-overflow-tooltip label="券包名称" align="center" prop="name" />
        <el-table-column show-overflow-tooltip label="包含优惠券种类" align="center" prop="containCount" />
        <el-table-column show-overflow-tooltip label="个人限买数量" align="center" prop="limitNum">
          <template #default="scope">
            {{ scope.row.limitNum == -1 ? '不限制' : scope.row.limitNum }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
      <el-pagination
        v-if="data.skuLists.length !== 0"
        v-model:current-page="data.currentPage"
        v-model:page-size="data.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="data.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, onMounted } from 'vue'
import API from '../service/api'
import { ElMessage } from 'element-plus'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
    hasPros: Array,
  },
  setup(props, ctx) {
    const data = reactive({
      checkAll: false,
      couponPackageCode: undefined,
      couponPackageName: undefined,
      currentPage: 1,
      pageSize: 10,
      total: 0,
      selectLists: [],
      skuLists: [],
      hasSelectSkuList: [],
      type: '',
      couponRuleName: '',
      checked: true,
    })
    const visible = computed(() => {
      return props.dialogVisible
    })

    const elTable = ref(null)

    const indexfn = computed(() => {
      return (data.currentPage - 1) * data.pageSize + 1
    })

    onMounted(() => {
      selectList()
    })

    const selectList = () => {
      data.currentPage = 1
      selectBelowProductSkuArray()
    }

    const selectBelowProductSkuArray = () => {
      data.hasSelectSkuList = data.hasSelectSkuList.length == 0 ? props.hasPros : data.hasSelectSkuList
      let params = {
        pageSize: data.pageSize,
        pageNum: data.currentPage,
        couponPackageCode: data.couponPackageCode,
        couponPackageName: data.couponPackageName,
      }
      API.selectCouponPackagePageByCombo(params).then(res => {
        if (res.code === '200') {
          res.data.records = res.data.records.map(item => ({
            ...item,
            checked: false,
          }))
          res.data.records.forEach(a => {
            let b = data.hasSelectSkuList.find(b => b.code == a.code)

            if (b) {
              a.checked = true
            } else {
              a.checked = false
            }
          })
          data.skuLists = res.data.records ?? []
          data.total = res.data.totalRecords
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const handleCheckAllChange = () => {
      data.skuLists.forEach(element => {
        element.checked = data.checkAll
        handleCheckAllOne(element)
      })
    }

    const handleCheckAllOne = row => {
      let b = data.hasSelectSkuList.findIndex(a => a.code == row.code)
      if (b !== -1) {
        data.hasSelectSkuList.splice(b, 1)
      } else {
        data.hasSelectSkuList.push(row)
      }
    }

    const onsubmit = () => {
      data.checkAll = false
      let selectSkus = data.skuLists.filter(item => item.checked).map(item => ({ ...item, count: 1 }))

      selectSkus.forEach(item => {
        if (!data.hasSelectSkuList.find(a => a.code == item.code)) {
          data.hasSelectSkuList.push(item)
        }
      })

      if (data.hasSelectSkuList.length === 0) {
        ElMessage.warning('暂无券包选择')
        return
      }
      console.log(ElMessage)

      ElMessage.success('添加成功')
    }

    const backtrack = () => {
      handleClose()
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false, selectSkus: data.hasSelectSkuList, isUpdate: true })
    }
    const handleSizeChange = val => {
      data.checkAll = false
      data.pageSize = val
      selectBelowProductSkuArray()
    }
    const handleCurrentChange = val => {
      data.checkAll = false
      data.currentPage = val
      selectBelowProductSkuArray()
    }
    return {
      handleSizeChange,
      handleCurrentChange,
      selectBelowProductSkuArray,
      data,
      handleClose,
      backtrack,
      visible,
      onsubmit,
      indexfn,
      elTable,
      handleCheckAllChange,
      handleCheckAllOne,
      selectList,
    }
  },
})
</script>
<style lang="scss" scoped>
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
</style>
