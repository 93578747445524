// const linCmsUiRouter = {
//   route: null,
//   name: null,
//   title: '财务对账管理',
//   type: 'folder',
//   icon: 'iconfont icon-jiemiansheji',
//   filePath: 'view/fiancy/',
//   order: null,
//   inNav: true,
//   children: [
//     {
//       title: '交班报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/jbbb-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },
//     {
//       title: '包厢收入',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/roomincome-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },
//     {
//       title: '商品收入',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/merchant-income-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '账单明细',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/bill-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '优惠赠送',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/coupon-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '预定报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/reserve-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '存酒列表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/storewine-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '进销存报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/inout-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '支付',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/pay-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '支付报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/pay-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '异常单报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/abnormal-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '优惠券报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/conpon-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '优惠券报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/conpon-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '券包报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/conponbag-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '积分兑换表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/integral-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '退单报表',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/chargeback-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     },{
//       title: '开票明细',
//       type: 'view',
//       name: 'ImgsUploadDemo',
//       route: '/financial-manager/invoce-manager',
//       filePath: 'plugin/financial-manager/view/table/table.vue',
//       inNav: true,
//       icon: 'iconfont icon-upload',
//       permission: null,
//     }
//   ],
// }

// export default linCmsUiRouter
