<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 12:34:09
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-06-27 14:19:44
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/supplyShop.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="supply-shop-content">
    <el-button type="primary" @click="bindStore" class="binding-btn">绑定门店</el-button>
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap" @filter-change="filterTagTable">
      <el-table-column prop="storeName" label="门店" />
      <el-table-column prop="freight" label="运费" sortable />
      <el-table-column prop="miniPrice" label="最小起订金额" sortable />
      <el-table-column
        prop="address"
        label="结账类型"
        :filters="billTypeColumns"
        :column-key="'billType'"
        :filter-multiple="false"
      >
        <template #default="scope">
          <div>
            {{ scope.row.billType === 1 ? '现结' : '月结' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="state"
        label="状态"
        :filters="stateListColumns"
        :column-key="'state'"
        :filter-multiple="false"
      >
        <template #default="scope">
          <div :class="scope.row.state !== 0 ? 'state-color' : ''">
            {{ scope.row.state === 0 ? '取关' : '在供' }}
          </div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="220">
        <template #default="scope">
          <el-button type="text" @click="editShop(scope.$index, scope.row)">编辑</el-button>
          <el-button type="text" @click="showDetail(scope.$index, scope.row)">商品详情</el-button>
          <el-button type="text" @click="deteleItem(scope.$index, scope.row)" class="delete-color">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <CommodityModalDetail
      ref="commodityModal"
      :bingGoodsList="bingGoodsList"
      :getTableData="getBindGoodsList"
      :model="goodsModel"
      :rowDetail="rowDetail"
    />
    <SupplyShopModal ref="supplyShopModal" :getTableData="getTableData" :model="model" />
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
  </div>
</template>
<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import Pagination from '../../component/pagination.vue'
import SupplyShopModal from '@/plugin/supplyChain-manager/view/supplier-management/supplyShopModal'
import CommodityModalDetail from './commodityModalDetail.vue'
import api from '../../service/index'
export default {
  components: { Pagination, SupplyShopModal, CommodityModalDetail },
  props: {
    code: {
      type: String,
      default: '',
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      tableData: [],
      totalRecords: null,
      totalPage: null,
      model: {
        pageNum: 1,
        pageSize: 10,
        supplierCode: this.code,
        billType: '',
        state: '',
      },
      billTypeColumns: [
        { text: '现结', value: 1 },
        { text: '月结', value: 2 },
      ],
      stateListColumns: [
        { text: '取关', value: 0 },
        { text: '在供', value: 10 },
      ],
      goodsModel: {
        pageNum: 1,
        pageSize: 10,
        storeCode: '',
        supplierCode: '',
      },
      bingGoodsList: null,
      rowDetail: {},
      newActiveName: '',
    }
  },
  mounted() {
    this.getTableData(this.model)
  },
  watch: {
    activeName(newVal, oldVal) {
      this.newActiveName = newVal
    },
  },
  methods: {
    getTableData(model) {
      api.getBindStoreCodeList(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    filterTagTable(filters) {
      if (filters.billType) {
        this.model.billType = filters.billType[0] ? filters.billType[0] : ''
      }
      if (filters.state) {
        this.model.state = [0, 1].includes(filters.state[0]) ? filters.state[0] : ''
      }
      this.getTableData(this.model)
    },
    showDetail(index, row) {
      this.$refs.commodityModal.editModal = true
      this.rowDetail = row
      this.goodsModel.storeCode = row.storeCode
      this.goodsModel.supplierCode = row.supplierCode
      this.getBindGoodsList(this.goodsModel)
    },
    getBindGoodsList(goodsModel) {
      api.getBingGoodsList(goodsModel).then(res => {
        this.$refs.commodityModal.detailInfo(res.data, this.rowDetail.storeName)
      })
    },
    deteleItem(index, row) {
      api.unBindStoreByCode(row.code).then(res => {
        ElMessage({
          type: 'success',
          message: `删除门店成功`,
        })
        this.getTableData(this.model)
      })
    },
    editShop(index, row) {
      this.$refs.supplyShopModal.editModal = true
      this.$refs.supplyShopModal.getEditInfo(row.code, row.storeName)
    },
    bindStore() {
      this.$router.push({
        path: '/supplyChain-manager/supplierManagement/bindingStore',
        query: {
          id: this.$route.query.id,
          name: this.$route.query.name,
          activeName: this.newActiveName,
        },
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.supply-shop-content {
  overflow: hidden;

  .binding-btn {
    float: right;
    margin-bottom: 15px;
  }

  .state-color {
    color: #6dd400;
  }

  .delete-color {
    color: #ff5959;
  }
}
</style>
