/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-04-21 19:41:11
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-05-11 10:27:56
 * @FilePath: /saas/src/router/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { createRouter, createWebHashHistory } from 'vue-router'
import { ElMessage } from 'element-plus'

import appConfig from '@/config/index'
import Util from '@/lin/util/util'
import autoJump from '@/lin/util/auto-jump'
import store from '../store'
import routes from './route'
import homeRouter from '@/router/home-router.js'

// 判断是否需要登录访问, 配置位于 config 文件夹
let isLoginRequired = routeName => {
  // 首次执行时缓存配置
  let { notLoginRoute } = appConfig
  const notLoginMark = {}

  // 构建标记对象
  if (Array.isArray(notLoginRoute)) {
    for (let i = 0; i < notLoginRoute.length; i += 1) {
      notLoginMark[notLoginRoute[i].toString()] = true
    }
  }

  notLoginRoute = null // 释放内存

  // 重写初始化函数
  isLoginRequired = name => {
    if (!name) {
      return true
    }
    // 处理 Symbol 类型
    const target = typeof name === 'symbol' ? name.description : name
    return !notLoginMark[target]
  }

  return isLoginRequired(routeName)
}

const router = createRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  base: process.env.BASE_URL,
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
    return
  }
  // 登录验证
  if (isLoginRequired(to.name) && !store.state.loggedIn) {
    next({ path: '/login' })
    return
  }

  // TODO: tab 模式重复点击验证

  // 权限验证
  // if (store?.state && store?.getters) {
  //   const { permissions, user } = store.getters
  //   if (to.path !== '/about' && !Util.hasPermission(permissions, to.meta, user)) {
  //     ElMessage.error('您无此页面的权限哟')
  //     next({ path: '/about' })
  //     return
  //   }
  // }
  //

  if (to.name === 'forgetPwd') {
    next()
    return
  }

  //路由发生变化重新计时https://adminchaintest.xjhktv.com/#/shop_manager/shop_manager
  // autoJump(router)
  let currenRoutes = router.options.routes[0]
  let promise = JSON.parse(localStorage.getItem('authMenuNodes'))?.childrens || []
  if (promise.length === 0) {
    next({ path: '/login' })
    console.log('切换url')
    return
  }
  console.log('no切换url')
  console.log(homeRouter);

  if (currenRoutes.children.length === 0 && to.path !== '/login') {
    const routertest = []
    if (promise) {
      promise.forEach(item => {
        routertest.push(item)
        item.childrens.forEach(itemChild => {
          const menu = homeRouter.find(homeroute => homeroute.path === itemChild.url)
          if (menu) {
            menu.meta.msg = itemChild
            currenRoutes.children.push(menu)
            router.addRoute('Home', menu)
            routertest.push(menu)
          }
        })
      })
    }
    sessionStorage.setItem('test', JSON.stringify(routertest))
    next({ ...to, replace: true })
  } else {
    next()
  }

  // // 路由发生变化修改页面title
  // if (to.meta.title) {
  //   // document.title = to.meta.title
  // }
})

export default router
