<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-09-21 10:45:29
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/goodsSellReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      :isShowToast="false"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />

    <Table
      :emptyText="emptyTableText"
      :data="tableData"
      :isPagination="true"
      :columns="columns"
      :total="totalRecords"
      :model="model"
      :getTableData="reportGoodsSell"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'
import { ElMessage } from 'element-plus'
import { createMoveBall } from '@/utils/createMoveBall.js'
export default {
  props: {
    auths: Object,
    reportType: {
      type: String,
      default: '',
    },
    reportTypeName: {
      type: String,
      default: '',
    },
  },
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        // skuName: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'statisticName',
          label: '统计分类',
          width: '100px',
        },
        {
          prop: 'type',
          label: '商品分类',
          width: '100px',
        },
        {
          prop: 'skuName',
          label: '商品名称',
          width: '160px',
        },
        {
          prop: 'spec',
          label: '规格',
          width: '100px',
        },
        {
          prop: 'unit',
          label: '单位',
        },
        {
          prop: 'price',
          label: '售卖价格',
          width: '100px',
        },

        {
          prop: 'count',
          label: '总销售量',
          width: '100px',
        },
        {
          prop: 'aloneSellNum',
          label: '单点销售数量',
          width: '120px',
        },
        {
          prop: 'comboSellNum',
          label: '套餐销售数量',
          width: '120px',
        },
        {
          prop: 'aloneGiftNum',
          label: '单点赠送数量',
          width: '120px',
        },
        {
          prop: 'comboGiftNum',
          label: '套餐赠送数量',
          width: '120px',
        },
        {
          prop: 'receivableFee',
          label: '应收',
          width: '140px',
        },
        {
          prop: 'discountAmount',
          label: '优惠',
          width: '140px',
        },
        // {
        //   prop: 'wipeZeroAmount',
        //   label: '抹零',
        // },
        {
          prop: 'actualFee',
          label: '实收',
          width: '140px',
        },
        {
          prop: 'netIncomeFee',
          label: '净收',
          width: '140px',
        },
        {
          prop: 'stockPrice',
          label: '成本',
          width: '140px',
        },
        {
          prop: 'profit',
          label: '毛利(净收-成本)',
          width: '160px',
        },
        {
          prop: 'percent',
          label: '毛利率(净收-成本)/净收',
          width: '200px',
        },
        {
          prop: 'wechat',
          label: '微信',
          width: '140px',
        },
        {
          prop: 'alipay',
          label: '支付宝',
          width: '140px',
        },
        {
          prop: 'vipCard',
          label: '储值卡',
          width: '140px',
        },
        {
          prop: 'cash',
          label: '现金',
          width: '140px',
        },
        {
          prop: 'meituan',
          label: '美团 ',
          width: '140px',
        },
        {
          prop: 'leishi',
          label: '雷石',
          width: '140px',
        },
        {
          prop: 'stoneCoupon',
          label: '雷石优惠券',
          width: '140px',
        },
        {
          prop: 'dianping',
          label: '大众点评',
          width: '100px',
        },
        {
          prop: 'freeHospitality',
          label: '招待免收',
          width: '100px',
        },
        {
          prop: 'kj',
          label: '可即会员',
          width: '100px',
        },
        {
          prop: 'newCoupon',
          label: '新系统优惠券',
          width: '140px',
        },
        {
          prop: 'tiktok',
          label: '抖音',
          width: '140px',
        },
        {
          prop: 'teamHospitality',
          label: '利通取酒',
          width: '140px',
        },
        {
          prop: 'posCard',
          label: 'pos刷卡',
          width: '100px',
        },
        {
          prop: 'pos',
          label: 'POS',
          width: '140px',
        },
        {
          prop: 'hangBill',
          label: '内部挂账',
          width: '100px',
        },
        {
          prop: 'leiShiPrincipalFee',
          label: '利通会员本金',
          width: '140px',
        },
        {
          prop: 'leiShiGiftFee',
          label: '利通会员赠金',
          width: '140px',
        },
        {
          prop: 'giftCard',
          label: '礼品卡',
          width: '100px',
        },
        {
          prop: 'yudingWechat',
          label: '预定单微信支付',
          width: '140px',
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },

        {
          label: '商品分类',
          placeholder: '请选择商品分类',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'type',
          options: [],
          filterable: true,
        },
        {
          label: '统计分类',
          type: 'select',
          typeProp: 'select',
          children: 'option',
          prop: 'statisticCode',
          placeholder: '请选择统计分类',
          options: [],
        },

        { label: '商品名称', type: 'input', prop: 'skuName', placeholder: '请输入商品名称' },
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '导出',
          type: 'exportButton',
          url: '/reportDownloadCenter/reportDownloadInfo/exportReport',
          auth: 'export',
        },
      ],
    }
  },
  async mounted() {
    this.$route.meta.msg = this.auths
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      await this.getStoreList()
      // this.reportGoodsSell(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    verifyModel(model) {
      if (!this.getDays(model.startTime, model.endTime)) {
        return Promise.reject('条件不满足')
      }
    },
    reportGoodsSell(model) {
      this.mergeTotalData(model.pageNum, '商品销售统计', () => {
        return this.verifyModel(model)
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })

      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
      api
        .selectByType({
          pageSize: 100,
          current: 1,
          type: 1,
        })
        .then(res => {
          this.searchform[2].options = res.data.records.map(item => {
            return {
              value: item.code,
              label: item.name,
            }
          })
        })

      api.getSelectList().then(res => {
        if (res.code === '200') {
          this.searchform[3].options = res.data.map(item => {
            return {
              value: item.code,
              label: item.name,
            }
          })
        } else {
          ElMessage.error(res.msg)
        }
      })
    },
    parentSearch(param) {
      const { type, skuName, storeCodeList, regionCodeList, statisticCode } = param
      this.model = { type, skuName, storeCodeList, regionCodeList, statisticCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '商品销售统计', () => {
        return this.verifyModel(this.model)
      })
    },

    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }

      const { type, skuName, storeCodeList, regionCodeList, statisticCode } = param
      this.model = { type, skuName, storeCodeList, regionCodeList, statisticCode }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10

      if (!this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      this.exportData('商品销售统计', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;
}
</style>
