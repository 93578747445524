<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-09-04 16:22:58
 * @LastEditors: 张维杰 15536847978@163.com
 * @LastEditTime: 2024-09-18 14:41:19
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report_module/customerCardStatisticsReport.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="report-content-box">
    <!-- <tableSearchTab :model="model" :getTableData="reportCustomerCardStatistics" reportName="会员卡消费统计"/> -->
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :emptyText="emptyTableText"
      :getTableData="reportCustomerCardStatistics"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '../../../../component/base/searchbar/index.vue'
import Table from '../../component/table.vue'
import api from '../../service/index'
import { downLoad } from '../../../../lin/util/util'
import { ElMessage } from 'element-plus'
export default {
  props: {
    auths: {},
  },
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        startTime: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        endTime: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        telephone: '',
        level: '',
      },
      tableData: [],
      totalRecords: 0,
      totalPage: 0,
      columns: [
        {
          prop: 'storeName',
          label: '门店名称',
        },
        {
          prop: 'storeNameRegister',
          label: '注册门店',
        },
        {
          prop: 'nickName',
          label: '会员姓名',
        },
        {
          prop: 'telephone',
          label: '会员电话',
        },
        {
          prop: 'commonPrincipalFeePayAmount',
          label: '通用储值本金支付',
          width: 150,
        },
        {
          prop: 'commonGiftFeePayAmount',
          label: '通用储值赠金支付',
          width: 150,
        },
        {
          prop: 'storePrincipalFeePayAmount',
          label: '门店卡储值本金支付',
          width: 150,
        },
        {
          prop: 'storeGiftFeePayAmount',
          label: '门店卡储值赠金支付',
          width: 150,
        },
      ],
      searchform: [
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          type: 'select-v2',
          placeholder: '请选择门店',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodeList',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        { label: '手机号', type: 'input', prop: 'telephone', placeholder: '请输入手机号' },
        // {
        //   label: '会员等级',
        //   type: 'select',
        //   typeProp: 'type',
        //   placeholder: '请选择会员等级',
        //   children: 'option',
        //   prop: 'level',
        //   options: [],
        // },
        {
          label: '日期',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'time',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        { label: '导出', type: 'exportButton', url: '/report/export/customerCardStatistics', auth: 'export' },
      ],
    }
  },
  async mounted() {
    this.$route.meta.msg = this.auths
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
      // this.reportCustomerCardStatistics(this.model)
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    async changeArea(areas) {
      let res = await api.getStoreListByName({ list: areas })
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getStoreListByName()
      //门店接口
      this.searchform[1].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[0].options = res.data
      })
    },
    reportCustomerCardStatistics(model) {
      this.mergeTotalData(model.pageNum, '会员卡消费统计', () => {
        return this.verifyModel(model)
      })
    },
    verifyModel(model) {
      if (!model.telephone && !this.getDays(model.startTime, model.endTime)) {
        return Promise.reject('条件不满足')
      }
    },
    parentSearch(param) {
      const { level, telephone, storeCodeList, regionCodeList } = param
      this.model = { level, telephone, storeCodeList, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '会员卡消费统计', () => {
        return this.verifyModel(this.model)
      })
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { level, telephone, storeCodeList, regionCodeList } = param
      this.model = { level, telephone, storeCodeList, regionCodeList }
      this.model.startTime = param.time ? param.time[0] : ''
      this.model.endTime = param.time ? param.time[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      if (!this.model.telephone && !this.getDays(this.model.startTime, this.model.endTime)) {
        this.$refs.search.fullscreenLoading = false
        return
      }
      this.exportData('会员卡消费统计', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  max-height: calc(100vh - 100px) !important;
}
</style>
