<template>
  <el-dialog v-model="visible" width="60%" :before-close="handleClose" title="新增空瓶商品">
    <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 10px 0  10px;">
      <el-form-item label="空瓶商品名称">
        <el-input
          clearable
          placeholder="请输入空瓶商品名称"
          v-model="data.emptyBottleName"
          class="input-search-tel"
          @change="selectBelowProductSkuArray({ currentPage: 1 })"
        ></el-input>
      </el-form-item>
      <el-form-item label="三方物料编号">
        <el-input
          clearable
          placeholder="请输入三方物料编号"
          v-model="data.materielCode"
          class="input-search-tel"
          @change="selectBelowProductSkuArray({ currentPage: 1 })"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="selectBelowProductSkuArray({ currentPage: 1 })">查询</el-button>
        <el-button @click="handleReset">重置</el-button>
        <el-button @click="onsubmit" type="primary">确定</el-button>
      </el-form-item>
    </el-form>

    <el-empty
      description="暂无数据"
      :image-size="100"
      v-if="data.skuLists.length === 0 && !data.productName && !data.categoryCode"
    />
    <div
      v-if="data.skuLists.length !== 0 || data.productName || data.categoryCode"
      style="display: flex; flex-wrap: wrap; max-height: 500px; overflow-y: auto;"
    >
      <el-table :data="data.skuLists" style="width: 100%" ref="elTable">
        <el-table-column align="center" width="50">
          <template #header>
            <el-checkbox v-model="data.checkAll" @change="handleCheckAllChange"></el-checkbox>
          </template>
          <template #default="{row}">
            <el-checkbox v-model="row.hasChecked" @change="handleCheckAllOne(row)" />
          </template>
        </el-table-column>
        <el-table-column label="序号" align="center" type="index" :index="indexfn" />
        <el-table-column prop="emptyBottleName" label="空瓶商品名称" align="center" />
        <el-table-column prop="materielCode" label="三方物料编号" align="center" />
        <el-table-column prop="emptyBottleSpec" label="规格" />
        <el-table-column prop="emptyBottleUnit" label="最小单位" />
      </el-table>
    </div>
    <div style="display: flex; justify-content: flex-end; align-items: flex-end;">
      <el-pagination
        v-if="data.skuLists.length !== 0"
        v-model:current-page="data.currentPage"
        v-model:page-size="data.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="data.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </el-dialog>
</template>

<script>
import { reactive, defineComponent, computed, ref, toRaw, onMounted, watchEffect } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    type: Number,
    hasPros: Array,
  },
  setup(props, ctx) {
    const data = reactive({
      checkAll: false,
      emptyBottleName: undefined,
      materielCode: undefined,
      currentPage: 1,
      pageSize: 5,
      total: 0,
      skuLists: [],
    })

    const hasSelectSkuList = computed(() => {
      return props.hasPros.map(item => ({
        ...item,
        emptyBottleName: item.materielName,
        emptyBottleSpec: item.spec,
        emptyBottleUnit: item.unit,
      }))
    })
    const visible = computed(() => {
      return props.dialogVisible
    })
    const elTable = ref(null)

    const indexfn = computed(() => {
      return (data.currentPage - 1) * data.pageSize + 1
    })

    onMounted(() => {
      selectBelowProductSkuArray({})
    })

    const hasCheckAll = () => {
      let hasCheckedLength = data.skuLists.filter(item => item.hasChecked).length
      if (hasCheckedLength == data.skuLists.length) {
        data.checkAll = true
      } else {
        data.checkAll = false
      }
    }
    const handleReset = () => {
      data.emptyBottleName = ''
      data.materielCode = ''
      selectBelowProductSkuArray({})
    }

    //缓存历史选过的商品
    const cacheOldPro = () => {
      let hasSelect = data.skuLists.filter(item => item.hasChecked)
      hasSelect.forEach(item => {
        let a = hasSelectSkuList.value.find(b => b.materielCode == item.materielCode)
        if (!a) {
          hasSelectSkuList.value.push(item)
        }
      })
      if (hasSelectSkuList.value.length > 0) {
        return true
      } else {
        return false
      }
    }

    const selectBelowProductSkuArray = ({ currentPage }) => {
      if (currentPage) {
        data.currentPage = currentPage
      }
      let params = {
        materielCode: data.materielCode,
        emptyBottleName: data.emptyBottleName,
        pageSize: data.pageSize,
        pageNum: data.currentPage,
      }
      cacheOldPro()

      API.queryGoodsEmptyBottleInfo(params).then(res => {
        if (res.code === '200') {
          res.data.records.forEach(a => {
            let b = hasSelectSkuList.value.find(b => b.materielCode == a.materielCode)
            if (b) {
              a.hasChecked = true
            } else {
              a.hasChecked = false
            }
          })
          data.skuLists = res.data.records
          data.total = res.data.total
          hasCheckAll()
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const onsubmit = async () => {
      if (cacheOldPro()) {
        let supplierInfo = JSON.parse(sessionStorage.getItem('purchase-info'))
        console.log(hasSelectSkuList.value)
        debugger
        let param = hasSelectSkuList.value.map(item => ({
          supplierCode: supplierInfo.code,
          supplierName: supplierInfo.supplierName,
          materielCode: item.materielCode,
          materielName: item.emptyBottleName,
          spec: item.emptyBottleSpec,
          unit: item.emptyBottleUnit,
        }))
        let res = await API.batchThirdPartySupplierEmptyGoods(param)
        if (res.code === '200') {
          ElMessage.success('新增成功')
          handleClose()
        } else {
          ElMessage.error(res.msg)
        }
      } else {
        ElMessage.warning('请选择商品')
      }
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', {
        update: false,
        search: true,
      })
    }
    const handleSizeChange = val => {
      data.pageSize = val
      selectBelowProductSkuArray({})
    }
    const handleCurrentChange = val => {
      data.currentPage = val
      selectBelowProductSkuArray({})
    }

    const handleCheckAllChange = () => {
      data.skuLists.forEach(item => (item.hasChecked = data.checkAll))
    }
    const handleCheckAllOne = a => {
      let index = hasSelectSkuList.value.findIndex(b => b.materielCode == a.materielCode)
      if (index !== -1) {
        hasSelectSkuList.value.splice(index, 1)
      }
      hasCheckAll()
    }

    return {
      handleSizeChange,
      handleCurrentChange,
      selectBelowProductSkuArray,
      data,
      handleClose,
      backtrack,
      visible,
      onsubmit,
      indexfn,
      elTable,
      handleCheckAllChange,
      handleCheckAllOne,
      handleReset,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-input-number {
  width: 100% !important;
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}
</style>
