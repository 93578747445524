<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-18 11:09:42
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-06-27 14:19:57
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-management/editCategory.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="edit-category-content">
    <el-drawer
      v-model="editModal"
      title="苏州信投店-A02/小包"
      size="35%"
      :direction="direction"
      :with-header="false"
      :before-close="handleClose"
    >
      <div class="edit-commdity-info">
        <div class="info-left">
          <h1>
            {{ storeName }}-商品详情<el-icon :size="20" color="#BFBFBF" class="close-icon" @click="editModal = false">
              <close />
            </el-icon>
          </h1>
          <div class="info-line"></div>
          <el-table :data="tableData" :row-style="{ height: '35px' }">
            <!-- <el-table-column prop="skuCode" label="商品ID" /> -->
            <el-table-column prop="skuName" label="商品名称" />
            <el-table-column prop="categoryName" label="所属品类" />
          </el-table>
          <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import Pagination from '../../component/pagination.vue'
export default {
  components: { Pagination },
  props: {
    getTableData: {
      type: Function,
      default: () => {},
    },
    model: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      editModal: false,
      tableData: [],
      totalRecords: null,
      totalPage: null,
      goodsModel: null,
      storeName: '',
    }
  },
  mounted() {},
  methods: {
    detailInfo(data, storeName) {
      this.storeName = storeName
      this.tableData = data.records
      this.totalPage = data.totalPage
      this.totalRecords = data.totalRecords
    },
  },
}
</script>
<style lang="scss" scoped>
.edit-category-content {
  position: relative;
  .table-height {
    background-color: red;
    height: 100px;
  }
  .confirm-edit {
    position: absolute;
    right: 40px;
    bottom: 24px;
  }

  .edit-commdity-info {
    padding: 0 40px;

    .edit-category-item {
      h2 {
        font-size: 16px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        height: 40px;
      }

      .tax-point-list {
        margin-bottom: 20px;
      }
    }

    h1 {
      height: 73px;
      line-height: 73px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      position: relative;

      .close-icon {
        position: absolute;
        top: 20px;
        right: 0px;
        cursor: pointer;
      }
    }

    .info-line {
      width: 588px;
      height: 1px;
      background-color: #ebeef5;
      margin-bottom: 25px;
    }

    .form-content {
      .tax-point-list {
        line-height: 52px !important;
      }

      .beverages-list {
        margin-bottom: 20px;
      }
    }

    .form-title {
      display: block;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      margin-bottom: 20px;
    }
  }
}
</style>
