<template>
  <div>
    <el-pagination
      class="pagination-style"
      v-model:current-page="pagination.pageNum"
      v-model:page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizes"
      :total="pagination.total"
      :small="small"
      :disabled="disabled"
      :background="false"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script setup>
import { ElMessage } from 'element-plus'
import { defineProps, defineEmit, ref, watch, reactive } from 'vue'
const props = defineProps({
  // 是否让分页变小
  small: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  // 暂时未接入权限相关内容
  authMenus: {
    type: Object,
    default: () => {},
  },
  pagination: {
    type: Object,
    default: () => ({
      pageNum: 1,
      pageSize: 10,
      total: 0,
      pageCount: 0,
    }),
  },
  getTableData: {
    type: Function,
    default: () => {},
  },
})

let pageNum = ref(1)
let pageSize = ref(10)
let pageSizes = ref([10, 20, 30, 40, 50])
let total = ref(0)

const emit = defineEmit(['handleChange'])

const handleCurrentChange = num => {
  pageNum.value = num
  const pages = reactive({
    pageNum: pageNum.value,
    pageSize: pageSize.value,
    pageSizes: pageSizes.value,
  })
  emit('handleChange', pages)
}

const handleSizeChange = size => {
  pageSize.value = size
  const pages = reactive({
    pageNum: 1,
    pageSize: pageSize.value,
    pageSizes: pageSizes.value,
  })
  emit('handleChange', pages)
}

watch(
  () => props.pagination,
  (nv, ov) => {
    pageNum.value = nv.pageNum
    pageSize.value = nv.pageSize
    pageSizes.value = nv.pageSizes
    total.value = nv.total
  },
  { deep: true, immediate: true },
)
</script>
<style lang="scss" scoped>
.pagination-style {
  text-align: right;
  padding: 20px 0;
}
</style>
