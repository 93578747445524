<!--
 * @Author: 张维杰 15536847978@163.com
 * @Date: 2024-08-23 12:31:32
 * @LastEditTime: 2024-08-23 14:26:59
 * @LastEditors: 张维杰 15536847978@163.com
 * @FilePath: /saas-pc/src/plugin/finance-reconciliation/view/report/dailyBill.vue
 * @Description:
-->
<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="消费日支付账单" name="paymentOrderDayReport" v-if="isShowReport('paymentOrderDayReport')">
      <paymentOrderDayReport :auths="authType.paymentOrderDayReport" />
    </el-tab-pane>
    <el-tab-pane
      label="储值日支付账单"
      name="storedValuePaymentJournal"
      v-if="isShowReport('storedValuePaymentJournal')"
    >
      <storedValuePaymentJournal :auths="authType.storedValuePaymentJournal" />
    </el-tab-pane>
    <el-tab-pane label="预定单日支付账单" name="reverseOrderDayReport" v-if="isShowReport('reverseOrderDayReport')">
      <reverseOrderDayReport :auths="authType.reverseOrderDayReport" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import paymentOrderDayReport from '../report_module/paymentOrderDayReport'
import storedValuePaymentJournal from '../report_module/storedValuePaymentJournal'
import reverseOrderDayReport from '../report_module/reverseOrderDayReport'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  paymentOrderDayReport: {}, // 消费日支付账单
  storedValuePaymentJournal: {}, // 储值支付账单
  reverseOrderDayReport: {}, // 预定单日支付账单
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.paymentOrderDayReport = authAllMenus.find(a => a.url === 'paymentOrderDayReport') || { childrens: [] }
  authType.storedValuePaymentJournal = authAllMenus.find(a => a.url === 'storedValuePaymentJournal') || {
    childrens: [],
  }
  authType.reverseOrderDayReport = authAllMenus.find(a => a.url === 'reverseOrderDayReport') || {
    childrens: [],
  }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsIncomeAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
.el-tabs {
  padding: 0px 20px;
}
</style>
