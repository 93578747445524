/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-16 11:42:10
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-05-20 11:40:05
 * @FilePath: /saas/src/store/state.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import appConfig from '@/config/index' // 引入项目配置
import stageConfig from '@/config/stage' // 引入舞台配置

export default {
  user: {}, // 当前用户
  loggedIn: false, // 是否登录
  permissions: [], // 每个用户的所有权限

  // 推送消息
  unreadMessages: [],
  alreadyReadMessages: [],

  // 舞台配置
  stageConfig,

  // 当前页信息
  currentRoute: {
    config: null,
    treePath: [],
  },

  sidebarLevel: appConfig.sidebarLevel || 3,
  defaultRoute: appConfig.defaultRoute || '/about',

  //会员等级数组
  levelList: [],
}
