<template>
  <div class="customer-source">
    <!-- 搜索条件 -->
    <el-form :inline="true">
      <el-form-item label="版本号">
        <el-input placeholder="版本号" v-model="filterTerm.versionCode" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="selectInfoPage" v-if="authMenus.query">查询</el-button>
        <el-button @click="resetFilter" v-if="authMenus.query">重置</el-button>
        <el-button @click="addCustomerSource" v-if="authMenus.add">新增</el-button>
      </el-form-item>
    </el-form>
    <!-- 表格数据 -->
    <el-table :data="tableData">
      <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>
      <el-table-column label="版本号" prop="versionCode" align="center"></el-table-column>
      <el-table-column label="更新内容" prop="remarks" align="center" width="500"></el-table-column>
      <el-table-column label="更新时间" prop="updateTime" align="center"></el-table-column>
      <el-table-column label="发布策略" prop="size" align="center">
        <template #default="scope">
          {{ scope.row.type == 0 ? '全量发布' : '部分发布' }}
        </template>
      </el-table-column>
      <el-table-column label="状态" prop="size" align="center">
        <template #default="scope">
          {{ scope.row.isRelease == 0 ? '待发布' : '发布' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" fixed="right" width="200">
        <template #default="scope">
          <el-button type="text" @click="handleQueryInfo(scope.row)" v-if="authMenus.query">详情</el-button>
          <el-button type="text" @click="handleUpdate(scope.row)" v-if="authMenus.update && scope.row.isRelease == 0"
            >编辑</el-button
          >
          <el-button
            type="text"
            @click="hanldeReleaseVersion(scope.row)"
            v-if="authMenus.release && scope.row.isRelease == 0"
            >发布</el-button
          >
          <el-button type="text" @click="handleDelete(scope.row)" v-if="authMenus.delete">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:current-page="filterTerm.pageNum"
      v-model:page-size="filterTerm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="filterTerm.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
  <addOrUpdate
    v-if="dialogVisible"
    :needUpdateInfo="needUpdateInfo"
    @handleCloseDialog="handleCloseOrOpenDialog"
    :needEdit="needEdit"
  ></addOrUpdate>
</template>

<script setup>
import operationAuth from '@/lin/util/operationAuth.js'
import { onMounted, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'
import addOrUpdate from './add-or-update.vue'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'
const authMenus = ref([])
const route = useRoute()
onMounted(async () => {
  operationAuth(route, authMenus.value)
  selectInfoPage()
})
/** 需要更新的会员卡数据---dialog展示框展示*/
const needUpdateInfo = ref({})
const dialogVisible = ref(false)
const needEdit = ref(false)
/**
 * 关闭or打开dialog展示框
 */
const handleCloseOrOpenDialog = val => {
  dialogVisible.value = val
  selectInfoPage()
}

/**过滤条件 */
const filterTerm = reactive({
  versionCode: '',
  pageNum: 1,
  pageSize: 10,
  total: 0,
})

/**
 * 重制
 */
const resetFilter = () => {
  filterTerm.versionCode = ''
  selectInfoPage()
}

/**
 * 获取表格信息
 */
const selectInfoPage = async () => {
  if (authMenus.value.query) {
    let res = await API.queryVersionApkPage(filterTerm)
    if (res.code === '200') {
      tableData.value = res.data.records
      filterTerm.total = res.data.total
    } else {
      ElMessage.error(res.msg)
    }
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

/**
 * 新增
 */
const addCustomerSource = () => {
  needUpdateInfo.value = {
    storeCodeList: [],
    type: 0,
  }
  needEdit.value = true
  handleCloseOrOpenDialog(true)
}
/**
 * 发布
 */
const hanldeReleaseVersion = async ({ code }) => {
  ElMessageBox.confirm('确认发布当前版本吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.releaseVersion({ code })
    if (res.code === '200') {
      ElMessage.success('发布成功')
    } else {
      ElMessage.error(res.msg)
    }
    selectInfoPage()
  })
}

//删除
const handleDelete = async ({ code }) => {
  ElMessageBox.confirm('确认删除当前版本吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(async () => {
    let res = await API.deleteVersion({ code })
    if (res.code === '200') {
      ElMessage.success('删除功能')
    } else {
      ElMessage.error(res.msg)
    }
    selectInfoPage()
  })
}

/**
 * 修改
 * @param {*} row
 */
const handleUpdate = async row => {
  needUpdateInfo.value = {
    ...row,
    storeCodeList: JSON.parse(row.storeCodeList),
  }
  needEdit.value = true
  handleCloseOrOpenDialog(true)
}

//详情
const handleQueryInfo = row => {
  needUpdateInfo.value = {
    ...row,
    storeCodeList: JSON.parse(row.storeCodeList),
  }
  needEdit.value = false
  handleCloseOrOpenDialog(true)
}
/**
 * 表单数据
 */
const tableData = ref([])

//分页
const handleSizeChange = val => {
  filterTerm.pageSize = val
  selectInfoPage()
}
const handleCurrentChange = val => {
  filterTerm.pageNum = val
  selectInfoPage()
}
</script>

<style scoped lang="scss">
.customer-source {
  padding: 20px 10px;
  .el-pagination {
    text-align: right;
    margin-top: 10px;
  }
}
</style>
