<template>
  <el-dialog
    :title="'code' in updateRowMsg ? '修改储值活动' : '新增储值活动'"
    v-model="dialogVisible"
    width="70%"
    :before-close="handleClose"
  >
    <el-form :model="updateRowMsg" ref="formRef" :rules="rules">
      <el-form-item label="活动名称" prop="activityName">
        <el-input placeholder="请输入活动名称" v-model="updateRowMsg.activityName" :maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="活动类型" prop="type">
        <el-radio-group v-model="updateRowMsg.type">
          <el-radio :label="1">倍数储值免单</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="储值倍数" prop="storedMultiple">
        <el-input placeholder="请输入储值金额是消费金额的倍数" v-model="updateRowMsg.storedMultiple"></el-input>
      </el-form-item>
      <el-form-item label="消费门槛" prop="minimumCharge">
        <el-input placeholder="请输入触发n倍储值的最低消费金额" v-model="updateRowMsg.minimumCharge"></el-input>
      </el-form-item>
      <el-form-item label="投放渠道" prop="channel">
        <el-checkbox-group v-model="updateRowMsg.channel" disabled>
          <el-checkbox :label="1">收银机</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="指定门店可用">
        <el-button
          :disabled="!authMenus.designatedStore"
          :type="updateRowMsg.isFixedStore == 1 ? 'primary' : ''"
          @click="updateRowMsg.isFixedStore = 1"
          >指定门店</el-button
        >
        <el-button
          :disabled="!authMenus.allStores"
          :type="updateRowMsg.isFixedStore != 1 ? 'primary' : ''"
          @click="updateRowMsg.isFixedStore = 0"
          style="margin-left: 0px;"
          >全部门店</el-button
        >
      </el-form-item>
      <el-form-item
        label="适用门店"
        label-width="120px"
        prop="attemptStore"
        v-if="authMenus.designatedStore && updateRowMsg.isFixedStore === 1"
      >
        <FilterShop :storeData="updateRowMsg.attemptStore" @changeStore="changeStore" />
      </el-form-item>
      <el-form-item label="赠金当日不可用">
        <p>否</p>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import _ from 'lodash'
import FilterShop from '../../component/filter-shop.vue'
import tabBar from '../../component/tab-bar.vue'
import { ref, defineEmit, defineProps, reactive, watchEffect, onMounted, nextTick } from 'vue'
import API from '../../service/index'
import { ElMessage, ElMessageBox } from 'element-plus'

/**
 * 改变门店
 * @param {*} data
 */
const changeStore = ({ isAll, data = [], isOneClick }) => {
  if (isOneClick) {
    props.updateRowMsg.attemptStore = data
    if (data.length > 0) {
      nextTick(() => {
        formRef.value.clearValidate('attemptStore')
      })
    }

    return
  }
  if (isAll) {
    data.forEach(item => {
      let index = props.updateRowMsg.attemptStore.findIndex(i => i === item)
      if (index === -1) {
        props.updateRowMsg.attemptStore.push(item)
      }
    })
  } else {
    data.forEach(item => {
      let index = props.updateRowMsg.attemptStore.findIndex(i => i === item)
      props.updateRowMsg.attemptStore.splice(index, 1)
    })
  }
  nextTick(() => {
    formRef.value.clearValidate('fixedStores')
  })
}

/**
 * 父容器传过来的参数
 */
const props = defineProps({
  updateRowMsg: {
    type: Object,
    default: {},
  },
  originRow: {
    type: Object,
    default: {},
  },
  authMenus: {
    type: Object,
    default: {},
  },
})

/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['handleCloseDialog'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('handleCloseDialog', { update: false, search: true })
}

/**
 * 校验scheme
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 */
var validateStoreMultiple = (rule, value, callback) => {
  if (!/^([1-9](\.\d{1,2})?|10|10\.0|10\.00)$/.test(value)) {
    return callback(new Error('储值倍数必须输入大于等于1，小于等于10，支持2位数小数'))
  } else {
    callback()
  }
}

var validateMinimumCharge = (rule, value, callback) => {
  if (!/^\d+$/.test(value) || value < 100 || value > 100000) {
    return callback(new Error('消费门槛必须输入正整数(100~100000)'))
  } else {
    callback()
  }
}
const validateFixedStores = (rule, value, callback) => {
  if (props.updateRowMsg.isFixedStore === 1 && props.updateRowMsg.attemptStore.length === 0) {
    return callback(new Error('请至少选择一家门店'))
  }
  callback()
}

//规则
const formRef = ref(null)
const rules = {
  activityName: [{ required: true, message: '请输入活动名称', trigger: 'change' }],
  type: [{ required: true, message: '请选择活动类型', trigger: 'change' }],
  storedMultiple: [{ required: true, validator: validateStoreMultiple, trigger: 'change' }],
  minimumCharge: [{ required: true, validator: validateMinimumCharge, trigger: 'change' }],
  attemptStore: [{ validator: validateFixedStores, trigger: 'change' }],
}

//生成参数
const createParam = (newParam, oldParam) => {
  let params = {
    code: oldParam.code,
  }

  if (newParam.activityName !== oldParam.activityName) {
    params.activityName = newParam.activityName
  }
  if (newParam.type !== oldParam.type) {
    params.type = newParam.type
  }
  if (newParam.storedMultiple != oldParam.storedMultiple) {
    params.storedMultiple = newParam.storedMultiple
  }
  if (newParam.minimumCharge != oldParam.minimumCharge) {
    params.minimumCharge = newParam.minimumCharge
  }
  if (newParam.attemptStore != oldParam.attemptStore) {
    params.attemptStore = newParam.attemptStore
  }

  return params
}

/**
 * 提交
 */
const handleCommit = _.throttle(
  async function() {
    if (!props.authMenus.designatedStore && !props.authMenus.allStores) {
      ElMessage.warning('暂无指定门店操作权限')
      return
    }
    if (!props.authMenus.designatedStore && props.updateRowMsg.isFixedStore === 1) {
      return ElMessage.warning('指定门店请选择全部门店')
    }
    await formRef.value.validate()
    let params = Object.assign({}, props.updateRowMsg)
    delete params.channel
    delete params.isFixedStore
    params = {
      ...params,
      attemptStore: props.updateRowMsg.isFixedStore === 1 ? JSON.stringify(params.attemptStore.sort()) : -1,
    }
    let res
    if ('code' in params) {
      // params = createParam(params, props.originRow)
      res = await API.updateStoredGratis(params)
    } else {
      res = await API.insertStoredGratis(params)
    }

    if (res.code === '200') {
      ElMessage.success('操作成功')
      handleClose()
    } else {
      ElMessage.error(res.msg)
    }
  },
  2000,
  {
    leading: true,
    trailing: false,
  },
)
</script>

<style scoped lang="scss">
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 120px !important;
  }
}
// tab栏下方的控件
.tab-des {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  ::v-deep(.el-date-editor) {
    width: 100%;
  }
  ::deep(.el-input__suffix) {
    width: 40px !important;
  }
  ::v-deep(.el-input__inner) {
    text-align: right;
    padding-right: 40px !important;
  }
  ::v-deep(.el-input-number) {
    width: 100%;
  }
  label {
    width: 70px;
    margin-right: 5px;
  }
  .toast {
    color: #ccc;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
  ::v-deep(.el-input__inner) {
    text-align: auto;
    padding-right: auto !important;
  }
}

// 底部按钮区
.dialog-footer {
  .el-button {
    width: 100px;
  }
}
</style>
