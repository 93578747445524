<template>
  <div class="report-content-box">
    <tableSearchTab
      :authMenus="authMenus"
      ref="search"
      :searchform="searchform"
      @childSearch="parentSearch"
      @exportReport="exportReport"
      @changeArea="changeArea"
    />
    <Table
      :data="tableData"
      :columns="columns"
      :isPagination="true"
      :total="totalRecords"
      :model="model"
      :getTableData="reportPaymentOrderDay"
      :emptyText="'暂无数据'"
    />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import tableSearchTab from '@/component/base/searchbar/index'
import Table from './component/Table.vue'
import api from '../../service/api'
import { downLoad } from '@/lin/util/util'

export default {
  components: { Table, tableSearchTab },
  data() {
    return {
      authMenus: {},
      model: {
        orderTimeStart: dayjs()
          .subtract(1, 'day')
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        orderTimeEnd: dayjs()
          .set('hour', 8)
          .set('minute', 0)
          .set('second', 0)
          .format('YYYY-MM-DD HH:mm:ss'),
        pageNum: 1,
        pageSize: 10,
        reserveOrderCode: '',
        state: '',
        code: '',
        regionCodeList: [],
        storeCodes: [],
      },
      tableData: [],
      totalRecords: null,
      totalPage: null,
      orderEnmu: {
        0: '待消费',
        1: '已消费',
        4: '待接单',
        2: '已取消',
        5: '拒绝接单',
        6: '待确认退款',
        7: '已退款',
      },
      columns: [
        {
          label: '预定单号',
          prop: 'code',
          width: 200,
        },
        {
          label: '预定来源',
          prop: 'reserveTer',
          width: 130,
        },
        {
          label: '门店编号',
          prop: 'storeCode',
          width: 80,
        },
        {
          label: '门店名称',
          prop: 'storeName',
          width: 180,
        },
        {
          label: '美大单号',
          prop: 'reserveOrderCode',
          width: 180,
        },
        {
          label: '预定单状态',
          prop: 'state',
          width: 100,
        },
        {
          label: '预定时间',
          prop: 'reserveTime',
          width: 230,
        },
        {
          label: '预定包型',
          prop: 'roomTypeName',
          width: 100,
        },
        {
          label: '预定套餐',
          prop: 'packageName',
          width: 180,
        },
        {
          label: '预定套餐详情',
          prop: 'packageDetail',
          width: 200,
        },
        {
          label: '预定单金额',
          prop: 'payAmount',
          width: 130,
        },
        {
          prop: 'promoAmount',
          label: '商家优惠金额',
          width: '100px',
        },
        {
          label: '预定人姓名',
          prop: 'customerName',
          width: 130,
        },
        {
          label: '预定人性别',
          prop: 'gender',
          width: 130,
        },
        {
          label: '预定人电话',
          prop: 'customerTelephone',
          width: 150,
        },
        {
          label: '下单时间',
          prop: 'orderTime',
          width: 200,
        },
        {
          label: '商家备注',
          prop: 'remarks',
          width: 130,
        },
        {
          label: '消费时间',
          prop: 'openTime',
          width: 200,
        },
        {
          prop: 'verifyAmount',
          label: '抵扣金额',
          width: '100px',
        },
        {
          label: '关联场次',
          prop: 'openCode',
          width: 160,
        },
        {
          label: '退款原因',
          prop: 'refundReason',
          width: 130,
        },
        {
          label: '退款时间',
          prop: 'refundTime',
          width: 200,
        },
        {
          label: '审核人',
          prop: 'refundAuditUser',
          width: 130,
        },
      ],
      searchform: [
        {
          label: '预定下单时间',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'orderTime',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
          modelValue: [
            dayjs()
              .subtract(1, 'day')
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
            dayjs()
              .set('hour', 8)
              .set('minute', 0)
              .set('second', 0)
              .format('YYYY-MM-DD HH:mm:ss'),
          ],
        },
        {
          label: '门店区域',
          type: 'cascader',
          prop: 'regionCodeList',
          filterable: true,
          placeholder: '请选择门店区域',
          props: {
            label: 'name',
            value: 'code',
            children: 'child',
            multiple: true,
          },
        },
        {
          label: '门店',
          placeholder: '请选择门店',
          type: 'select-v2',
          labelWidth: '50px',
          children: 'option',
          prop: 'storeCodes',
          options: [],
          filterable: true,
          multiple: true,
          modelValue: [],
        },
        {
          label: '预定单状态',
          type: 'select',
          labelWidth: '120px',
          prop: 'state',
          typeProp: 'select',
          children: 'option',
          placeholder: '请选择',
          options: [
            { label: '已取消', value: 2 },
            { label: '拒绝接单', value: 5 },
            { label: '待接单', value: 4 },
            { label: '待消费', value: 0 },
            { label: '已消费', value: 1 },
            { label: '待确认退款', value: 6 },
            { label: '已退款', value: 7 },
          ],
        },
        {
          type: 'input',
          label: '预订单号',
          prop: 'code',
          placeholder: '请输入预定单号',
        },
        {
          type: 'input',
          label: '美大单号',
          prop: 'reserveOrderCode',
          placeholder: '请输入预定单号',
        },
        {
          label: '消费时间',
          type: 'date-picker',
          labelWidth: '50px',
          prop: 'openTime',
          typeProp: 'datetimerange',
          isRange: true,
          dataFormate: 'YYYY-MM-DD HH:mm:ss',
        },
        { label: '导出', type: 'exportButton', url: '/adminChain/order/exportMtReserveOrders', auth: 'export' },
      ],
    }
  },
  async mounted() {
    operationAuth(this.$route, this.authMenus)
    if (this.authMenus.query) {
      this.getStoreList()
    } else {
      ElMessage.warning('抱歉，你没有查看权限')
    }
  },
  methods: {
    resetForm() {},
    async changeArea(areas) {
      let res = await api.getDianpuList({ list: areas })
      this.searchform[2].options = res.data?.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
    },
    async getStoreList() {
      let res = await api.getDianpuList()
      //门店接口
      this.searchform[2].options = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name }
      })
      api.getCheckArea().then(res => {
        this.searchform[1].options = res.data
      })
    },
    reportPaymentOrderDay(model) {
      this.mergeTotalData(model.pageNum, '美大预定明细', null, true)
    },
    parentSearch(param) {
      const { storeCodes, regionCodeList, reserveOrderCode, state, code } = param
      this.model = { storeCodes, regionCodeList, reserveOrderCode, state, code }
      this.model.orderTimeStart = param.orderTime ? param.orderTime[0] : ''
      this.model.orderTimeEnd = param.orderTime ? param.orderTime[1] : ''
      this.model.openTimeStart = param.openTime ? param.openTime[0] : ''
      this.model.openTimeEnd = param.openTime ? param.openTime[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10
      this.mergeTotalData(this.model.pageNum, '美大预定明细', null, true)
    },
    async exportReport(param, event) {
      if (!this.authMenus.export) {
        ElMessage.warning('抱歉，你没有导出权限')
        return
      }
      const { storeCodes, regionCodeList, reserveOrderCode, state, code } = param
      this.model = { storeCodes, regionCodeList, reserveOrderCode, state, code }
      this.model.orderTimeStart = param.orderTime ? param.orderTime[0] : ''
      this.model.orderTimeEnd = param.orderTime ? param.orderTime[1] : ''
      this.model.openTimeStart = param.openTime ? param.openTime[0] : ''
      this.model.openTimeEnd = param.openTime ? param.openTime[1] : ''
      this.model.pageNum = 1
      this.model.pageSize = 10

      this.exportData('美大预定明细', event)
    },
  },
}
</script>

<style lang="scss" scoped>
.report-content-box {
  box-sizing: border-box;
  padding-left: 40px;
  padding-right: 40px;
  height: calc(100vh - 100px) !important;
}
</style>
