<template>
  <el-form
    :inline="true"
    :model="data"
    style="width: 96%; margin-left: 2%; justify-content: space-between;display: flex; margin-top: 10px; height: 63px;"
  >
    <el-form-item style="flex: 1;">
      <el-tabs v-model="data.type" class="demo-tabs" @tab-click="searchLists">
        <el-tab-pane label="入库类型" :name="1"> </el-tab-pane>
        <el-tab-pane label="出库类型" :name="2"> </el-tab-pane>
      </el-tabs>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn"></el-table-column>
    <el-table-column prop="name" label="入库类型名称" align="center" />
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <Add
    :rowMsg="rowMsg"
    v-if="data.dialogVisible"
    @update-dialogVisible="updateDialogVisible"
    :dialogVisible="data.dialogVisible"
  >
  </Add>
</template>

<script setup>
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { ref, reactive, computed, onMounted } from 'vue'
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import { useRoute } from 'vue-router'
const data = reactive({
  type: 1,
  authMenus: {},
  tableData: [],
  pageSize: 10,
  currentPage: 1,
  total: 10,
  loading: false,
  rowMsg: {},
})
const activeName = ref('入库类型')

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const editRow = () => {
  data.dialogVisible = true
}
const addRow = () => {
  data.rowMsg = {}
  data.dialogVisible = true
}

const showDetail = (enmu, row) => {
  switch (enmu) {
    case 0:
      addRow()
      break
    case 1:
      editRow(row)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryLists()
  }
}

const searchLists = () => {
  data.currentPage = 1
  queryLists()
}
const queryLists = () => {
  data.loading = true
  if (data.authMenus.query) {
    let queryParams = {
      pageSize: data.pageSize,
      current: data.currentPage,
      type: data.type,
    }
    API.selectChannel(queryParams)
      .then(res => {
        if (res.code === '200') {
          data.loading = false
          data.tableData = res.data.records
          data.total = res.data.total
        } else {
          ElMessage.error(res.error)
        }
      })
      .finally(() => (data.loading = false))
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//分页
const handleSizeChange = val => {
  data.pageSize = val
  queryLists()
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists()
}
</script>

<style lang="scss" scoped>
.demo-tabs {
  width: 96%;
  margin-left: 2%;
  margin-top: 10px;
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
