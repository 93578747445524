<template>
  <div class="product-list-top">
    <el-radio-group v-model="data.categoryCode" @change="queryLists({ currentPage: 1 })">
      <el-radio
        :key="index"
        border
        v-for="(item, index) in data.productTypes"
        :label="item.code"
        :title="item.name"
        style="margin-bottom: 5px;margin-right: 5px; margin-left: 0px;height: 25px; width: 86px;  line-height: 16px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"
      >
        {{ item.name }}</el-radio
      >
    </el-radio-group>
  </div>
  <hr />
  <el-form :inline="true" :model="data" class="demo-form-inline" style="margin-top:10px;padding: 0 10px 0  20px;">
    <el-form-item label="易耗品名称">
      <el-input
        placeholder="请输入易耗品名称"
        v-model="data.name"
        class="input-search-tel"
        @keyup.enter="queryLists({ currentPage: 1 })"
      >
      </el-input>
    </el-form-item>
    <el-form-item label="门店">
      <el-select-v2
        clearable
        filterable
        v-model="data.storeCode"
        :options="data.shopList"
        placeholder="请选择门店"
        class="search-select"
        style="width: 200px;"
        @change="queryLists({ currentPage: 1 })"
      />
    </el-form-item>
    <el-form-item label="供货状态">
      <el-select v-model="data.isSupply" placeholder="请选择状态" @change="queryLists({ currentPage: 1 })">
        <el-option label="正常供货" :value="1" />
        <el-option label="停止供货" :value="0" />
        <el-option label="全部" :value="-1" />
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button @click="queryLists({ currentPage: 1 })">搜索</el-button>
      <el-button @click="resetParams">
        重置
      </el-button>
      <el-button type="primary" @click="addGroup" v-if="data.authMenus.add"
        ><el-icon><Plus /></el-icon>
        新增
      </el-button>
    </el-form-item>
  </el-form>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;"
    :highlight-current-row="true"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index" :index="indexfn" />
    <el-table-column label="易耗品图片" prop="pic" align="center">
      <template #default="scope">
        <div style="display: flex; justify-content: center;">
          <img :src="scope.row.pic" alt="" style="width:30px; height: 30px;" />
        </div>
      </template>
    </el-table-column>
    <el-table-column show-overflow-tooltip prop="name" label="易耗品名称" align="center" />
    <el-table-column show-overflow-tooltip prop="spec" label="规格" align="center" />
    <el-table-column show-overflow-tooltip prop="unit" label="最小单位" align="center" />
    <el-table-column show-overflow-tooltip label="下发门店数" prop="storeCount" align="center" />
    <el-table-column show-overflow-tooltip label="更新时间" prop="updateTime" align="center" />
    <el-table-column show-overflow-tooltip label="供货状态" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.isSupply === 1">正常供货</el-tag>
        <el-tag type="error" v-else>停止供货</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="150" align="center">
      <template #default="scope">
        <el-button type="text" @click="typeOperation(1, scope.row)" v-if="data.authMenus.update">编辑</el-button>
        <el-button type="text" @click="typeOperation(2, scope.row)" v-if="data.authMenus.delete">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
  <el-pagination
    v-model:current-page="data.currentPage"
    v-model:page-size="data.pageSize"
    :small="small"
    :disabled="disabled"
    :background="background"
    layout="total, sizes, prev, pager, next, jumper"
    :total="data.total"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
  />
  <AddPerishableArticles
    v-if="data.addProductVisible"
    :dialogVisible="data.addProductVisible"
    :specRowMsg="data.articleRowMsg"
    :units="data.units"
    :projectTypes="data.projectTypes"
    @update-dialogVisible="updateAddProductVisible"
  ></AddPerishableArticles>
</template>

<script setup>
import { useRoute } from 'vue-router'
import operationAuth from '@/lin/util/operationAuth.js'
import _ from 'lodash'
import { ref, reactive, onMounted, computed } from 'vue'
import API from '../../service/api'
import AddPerishableArticles from './AddPerishableArticles.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
const data = reactive({
  authMenus: {},
  productTypes: [],
  tableData: [],
  name: undefined,
  storeCode: undefined,
  isSupply: -1,
  storeCode: undefined,
  pageSize: 10,
  currentPage: 1,
  total: 100,
  addProductVisible: false,
  articleRowMsg: {},
  shopList: [],
  units: [],
  projectTypes: [],
  loading: false,
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists({})
  getShops()
  selectDictListAll()
  queryProjectTypes()
})

const getShops = () => {
  API.getStoreList().then(res => {
    if (res.code === '200') {
      data.shopList = res.data.map(item => {
        return { value: item.code, label: item.code + '--' + item.name, name: item.name }
      })
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//获取单位
const selectDictListAll = () => {
  API.selectDictListAll().then(res => {
    if (res.code === '200') {
      data.units = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

//查询易耗品类型
const queryProjectTypes = () => {
  API.selectListByType({ type: 4 }).then(res => {
    if (res.code === '200') {
      data.projectTypes = res.data
    } else {
      ElMessage.error(res.msg)
    }
  })
}

const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})
const resetParams = () => {
  data.name = undefined
  data.storeCode = undefined
  data.isSupply = -1
  queryLists({ currentPage: 1 })
}

//  查询列表
const queryLists = ({ currentPage }) => {
  if (!data.authMenus.query) {
    ElMessage.warning('抱歉，你没有查看权限')
    return
  }
  data.loading = true
  if (currentPage) {
    data.currentPage = currentPage
  }
  let queryParams = {
    name: data.name, //商品名称
    storeCode: data.storeCode,
    current: currentPage || data.currentPage,
    pageSize: data.pageSize,
  }
  if (data.isSupply !== -1) {
    queryParams.isSupply = data.isSupply
  }
  API.selectConsumables(queryParams)
    .then(res => {
      if (res.code === '200') {
        data.loading = false
        data.tableData = res.data.records
        data.total = res.data.total
      } else {
        ElMessage.error(res.msg)
      }
    })
    .finally(() => (data.loading = false))
}

const handleSizeChange = val => {
  data.pageSize = val
  queryLists({})
}
const handleCurrentChange = val => {
  data.currentPage = val
  queryLists({})
}

//添加
const addGroup = () => {
  data.articleRowMsg = {
    storeCodeList: [],
    shops: _.cloneDeep(data.shopList),
    isStock: true,
    isSales: 1,
    isSupply: 1,
  }
  data.addProductVisible = true
}
const deleteProRow = rowMsg => {
  ElMessageBox.confirm('确认删除当前商品吗', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  })
    .then(() => {
      API.deleteSkuByCode({ code: rowMsg.code }).then(res => {
        if (res.code === '200') {
          ElMessage.success('删除成功')
          queryLists({})
        } else {
          ElMessage.error(res.msg)
        }
      })
    })
    .catch(() => {
      ElMessage({
        type: 'info',
        message: '删除失败',
      })
    })
}
const editProType = rowMsg => {
  API.selectDetails({ code: rowMsg.code }).then(res => {
    if (res.code === '200') {
      if (res.data.price) {
        res.data.price1 = parseFloat(res.data.price.split('~')[0])
        res.data.price2 = parseFloat(res.data.price.split('~')[1])
      } else {
        res.data.price1 = 0
        res.data.price2 = 1
      }

      data.articleRowMsg = {
        shops: _.cloneDeep(data.shopList),
        ...res.data,
        isStock: res.data.isStock === 1 ? true : false,
        isRecommend: res.data.isRecommend === 1 ? true : false,
        isMinConsume: res.data.isMinConsume === 1 ? true : false,
        caseSpec: parseInt(res.data.caseSpec),
      }
      data.addProductVisible = true
      data.isEdit = true
    } else {
      ElMessage.error(res.error)
    }
  })
}

//操作商品列表数据
const typeOperation = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      editProType(rowMsg)
      break
    case 2:
      deleteProRow(rowMsg)
      break
  }
}

const updateAddProductVisible = ({ update, search }) => {
  data.addProductVisible = update
  if (search) {
    queryLists({})
  }
}
</script>

<style lang="scss" scope>
.product-list-top {
  width: 96%;
  margin-top: 20px;
  margin-left: 2%;
  .el-buttoon {
    line-height: 19px;
    height: 25px;
  }
}
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;

  .search-left {
    width: 60%;
    display: flex;
    align-items: center;
    .title {
      width: 100px;
      margin-left: 20px;
      font-size: 16px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #000000;
      text-align: right;
    }
    .input-with-select {
      width: 340px;
      margin-left: 24px;
    }

    .input-search-tel {
      width: 300px;
      margin-left: 24px;
      margin-right: 24px;
    }
  }

  .search-right {
    margin-right: 20px;
    width: 40%;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
