<template>
  <el-dialog v-model="visible" title="供应商管理" width="60%" :before-close="handleClose">
    <el-collapse v-model="activeNames" @change="handleChange">
      <el-collapse-item title="基本信息" name="1">
        <el-form :model="needEditMsg" label-width="120px" :rules="rules" ref="elForm" class="form">
          <el-form-item label="供应商名称" prop="supplierName">
            <el-input v-model="needEditMsg.supplierName" placeholder="请填写供应商名称" />
          </el-form-item>
          <el-form-item label="供应商地区" prop="countyCode">
            <div style="display: flex; justify-content: space-between;">
              <el-select
                v-model="needEditMsg.provinceCode"
                placeholder="请选择省份"
                filterable
                style="width: 30%;"
                @change="changePro"
              >
                <el-option v-for="item in form.provinces" :key="item.code" :label="item.name" :value="item.code" />
              </el-select>
              <el-select
                v-model="needEditMsg.cityCode"
                placeholder="请选择城市"
                filterable
                style="width: 30%;"
                @change="changeCity"
              >
                <el-option v-for="(item, index) in form.citys" :key="index" :label="item.name" :value="item.code" />
              </el-select>
              <el-select
                v-model="needEditMsg.countyCode"
                placeholder="请选择区县"
                filterable
                style="width: 30%;"
                @change="changeArea"
              >
                <el-option v-for="(item, index) in form.areas" :key="index" :label="item.name" :value="item.code" />
              </el-select>
            </div>
          </el-form-item>

          <el-form-item label="详细地址" prop="addressDetails">
            <el-input v-model="needEditMsg.addressDetails" placeholder="请填写详细地址"></el-input>
          </el-form-item>
          <el-form-item label="联系人" prop="contacts">
            <el-input v-model="needEditMsg.contacts" placeholder="请填写联系人" />
          </el-form-item>
          <el-form-item label="联系电话" prop="phone">
            <el-input v-model="needEditMsg.phone" placeholder="请填写联系电话" />
          </el-form-item>

          <el-form-item label="供货类型" prop="supplyType">
            <el-checkbox-group v-model="needEditMsg.supplyType">
              <el-checkbox :label="1">商品</el-checkbox>
              <el-checkbox :label="4">易耗品</el-checkbox>
            </el-checkbox-group>
          </el-form-item>
          <el-form-item label="合作类型" prop="cooperateType">
            <el-radio-group v-model="needEditMsg.cooperateType">
              <el-radio :label="2">未税</el-radio>
              <el-radio :label="1">含税</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="结账类型" prop="checkoutType">
            <el-radio-group v-model="needEditMsg.checkoutType">
              <el-radio :label="1">月结</el-radio>
              <el-radio :label="2">现结</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="起订金额(元)" prop="miniPrice">
            <el-input-number
              :precision="2"
              :controls="false"
              v-model="needEditMsg.miniPrice"
              placeholder="支持两位小数"
            />
          </el-form-item>
          <el-form-item label="运费" prop="isGeo">
            <div>
              <el-radio-group v-model="needEditMsg.isGeo">
                <el-radio :label="1">免运费</el-radio>
                <el-radio :label="2">有运费</el-radio>
              </el-radio-group>
              <div v-for="(item, index) in needEditMsg.supplierGeoList" :key="index" v-if="needEditMsg.isGeo == 2">
                <div style="display: flex; margin-bottom: 10px;">
                  <el-cascader
                    v-model="item.regionList"
                    :options="form.provincesCitys"
                    :props="{ multiple: true, label: 'name', value: 'code' }"
                    clearable
                    collapse-tags
                    size="small"
                    filterable
                    collapse-tags-tooltip
                  />
                  <el-input-number
                    :precision="2"
                    :controls="false"
                    v-model="item.freight"
                    placeholder="支持两位小数"
                    style="width: 100px;margin-left: 10px;"
                  />
                  <div style="margin-left: 10px;">
                    <el-button type="primary" @click="addRowSupplierGeo"
                      ><el-icon><Plus /></el-icon
                    ></el-button>
                    <el-button type="primary" @click="deleteRowSupplierGeo(index)" v-if="index !== 0"
                      ><el-icon><Minus /></el-icon
                    ></el-button>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="税点(%)" prop="point" v-if="needEditMsg.cooperateType == 1">
            <el-input-number :precision="2" :controls="false" v-model="needEditMsg.point" placeholder="支持两位小数" />
          </el-form-item>
          <el-form-item label="合作状态" prop="state">
            <el-radio-group v-model="needEditMsg.state">
              <el-radio :label="10">启用</el-radio>
              <el-radio :label="0">禁用</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </el-collapse-item>
      <el-collapse-item title="结算信息" name="2">
        <el-row :gutter="20" class="endMsgTitle">
          <el-col :span="1"><span></span></el-col>
          <el-col :span="5"><span>账户类型</span></el-col>
          <el-col :span="4"><span>账户名称</span></el-col>
          <el-col :span="4"><span>开户行</span></el-col>
          <el-col :span="4"><span>账号</span></el-col>
          <el-col :span="4"></el-col>
        </el-row>
        <el-row
          v-for="(item, index) in needEditMsg.supplierAccountList"
          :key="index"
          :gutter="20"
          style="margin-top: 10px;"
        >
          <el-col :span="1"><span></span></el-col>
          <el-col :span="5">
            <el-select v-model="item.paymentAccountType">
              <el-option label="公户" :value="1" />
              <el-option label="私户" :value="2" /> </el-select
          ></el-col>
          <el-col :span="4">
            <el-input v-model="item.accountName" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="item.bankName" />
          </el-col>
          <el-col :span="4">
            <el-input v-model="item.account" />
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="addRow"
              ><el-icon><Plus /></el-icon
            ></el-button>
            <el-button type="primary" @click="deleteRow(index)" v-if="index !== 0"
              ><el-icon><Minus /></el-icon
            ></el-button>
          </el-col>
        </el-row>
      </el-collapse-item>
      <el-collapse-item title="证照信息" name="3">
        <el-form :inline="true" :model="form" label-width="120px">
          <el-form-item label="营业执照" prop="pic">
            <el-upload
              list-type="picture"
              :headers="form.headerObj"
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="changeBusinessPicsImage"
            >
              <img v-if="needEditMsg.businessPics" :src="needEditMsg.businessPics" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="开户许可证" prop="pic">
            <el-upload
              list-type="picture"
              :headers="form.headerObj"
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="changeOpensAccountLicencePicsImage"
            >
              <img
                v-if="needEditMsg.opensAccountLicencePics"
                :src="needEditMsg.opensAccountLicencePics"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-form :inline="true" :model="form" label-width="120px">
          <el-form-item label="食品经营许可证" prop="pic">
            <el-upload
              list-type="picture"
              :headers="form.headerObj"
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="changeFoodBusinessLicensePicsImage"
            >
              <img
                v-if="needEditMsg.foodBusinessLicensePics"
                :src="needEditMsg.foodBusinessLicensePics"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="酒水经营许可证" prop="pic">
            <el-upload
              list-type="picture"
              :headers="form.headerObj"
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="changeLiquorBusinessLicensePicsImage"
            >
              <img
                v-if="needEditMsg.liquorBusinessLicensePics"
                :src="needEditMsg.liquorBusinessLicensePics"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </el-form>
        <el-form :inline="true" :model="form" label-width="120px">
          <el-form-item label="特殊经营许可证" prop="pic">
            <el-upload
              list-type="picture"
              :headers="form.headerObj"
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="changeSpecialBusinessLicensePicsImage"
            >
              <img
                v-if="needEditMsg.specialBusinessLicensePics"
                :src="needEditMsg.specialBusinessLicensePics"
                class="avatar"
              />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
          <el-form-item label="其他" prop="pic">
            <el-upload
              list-type="picture"
              :headers="form.headerObj"
              class="avatar-uploader"
              action="#"
              :show-file-list="false"
              :http-request="changeOtherPicsImage"
            >
              <img v-if="needEditMsg.otherPics" :src="needEditMsg.otherPics" class="avatar" />
              <el-icon v-else class="avatar-uploader-icon">
                <Plus />
              </el-icon>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-collapse-item>
    </el-collapse>

    <div style=" text-align: center;margin-top: 10px;">
      <el-button type="primary" @click="onSubmit" style="width: 100px;" :disabled="!isEdit">保存</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../lin/util/uploadImg.js'
import Config from '@/config'
import { reactive, defineComponent, computed, ref, onMounted, toRaw } from 'vue'
import { regionData } from 'element-china-area-data'
import _ from 'lodash'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRouter } from 'vue-router'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    needEditMsg: Object,
    isEdit: Boolean,
  },
  data() {
    return {
      options: regionData,
    }
  },
  setup(props, ctx) {
    const form = reactive({
      headerObj: {
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
      },
      baseUrl: Config.baseURL || '',
      pic: undefined,
      provinces: [],
      citys: [],
      areas: [],
      test: [],
      provincesCitys: [],
    })

    onMounted(() => {
      API.getAreaWithChildList(null).then(res => {
        if (res?.code == '200') {
          form.provinces.push(...res.data)
          let data = _.cloneDeep(res.data)
          data.forEach(item => {
            item?.children.forEach(childItem => {
              delete childItem.children
            })
          })
          form.provincesCitys = data
          initCityAreaData()
        } else {
          ElMessage.error(res.msg)
        }
      })
    })

    const visible = computed(() => {
      return props.dialogVisible
    })

    const changePro = val => {
      form.citys.length = 0
      form.areas.length = 0
      form.citys.push(...toRaw(form.provinces.find(item => item.code === val)?.children || []))

      props.needEditMsg.addressDetails = undefined
      props.needEditMsg.cityCode = undefined
      props.needEditMsg.countyCode = undefined
    }
    const changeCity = val => {
      form.areas.length = 0
      form.areas.push(...toRaw(form.citys.find(item => item.code === val)?.children || []))
      props.needEditMsg.addressDetails = undefined
      props.needEditMsg.countyCode = undefined
    }
    const changeArea = val => {
      props.needEditMsg.addressDetails = undefined
    }

    const rules = reactive({
      supplierName: [{ required: true, message: '请填写供应商名字', trigger: 'blur' }],
      cooperateType: [{ required: true, message: '请选择合作类型', trigger: 'change' }],
      checkoutType: [{ required: true, message: '请选择结账类型', trigger: 'change' }],
      addressDetails: [{ required: true, message: '请输入详细地址', trigger: 'blur' }],
      checkominiPriceutType: [{ required: true, message: '请填写起订金额', trigger: 'blur' }],
      isGeo: [{ required: true, message: '请选择运费方式', trigger: 'change' }],
      countyCode: [{ required: true, message: '请选择供应商地区', trigger: 'change' }],
      miniPrice: [{ required: true, message: '请填写起订金额', trigger: 'blur' }],
      point: [{ required: true, message: '请填写税点', trigger: 'blur' }],
      state: [{ required: true, message: '请选择合作状态', trigger: 'change' }],
      contacts: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
      phone: [{ required: true, message: '请输入联系电话', trigger: 'blur' }],
      supplyType: [{ required: true, message: '请选择供货类型', trigger: 'change' }],
    })
    const add = params => {
      API.saveSupplierInfo(params).then(res => {
        if (res.code === '200') {
          ElMessage.success('保存成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }
    const update = params => {
      API.updateSupplierInfo(params).then(res => {
        if (res.code === '200') {
          ElMessage.success('更新成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    //初始化省市区数据
    const initCityAreaData = () => {
      form.citys.length = 0
      form.areas.length = 0
      form.citys.push(
        ...toRaw(form.provinces.find(item => item.code === props.needEditMsg.provinceCode)?.children || []),
      )
      form.areas.push(...toRaw(form.citys.find(item => item.code === props.needEditMsg.cityCode)?.children || []))
    }

    const elForm = ref(null)

    const onSubmit = _.throttle(
      () => {
        validForm()
      },
      2000,
      { trailing: false },
    )

    const validForm = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          let params = _.cloneDeep(props.needEditMsg)
          params.supplyType = JSON.stringify(params.supplyType)
          params.supplierGeoList = params.supplierGeoList.filter(item => item.regionList.length !== 0)

          if (Reflect.has(props.needEditMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const addRow = () => {
      props.needEditMsg.supplierAccountList.push({})
    }
    const deleteRow = index => {
      if (props.needEditMsg.supplierAccountList.length === 1) {
        return
      } else {
        props.needEditMsg.supplierAccountList.splice(index, 1)
      }
    }

    const addRowSupplierGeo = () => {
      props.needEditMsg.supplierGeoList.push({ regionList: [] })
    }
    const deleteRowSupplierGeo = index => {
      if (props.needEditMsg.supplierGeoList.length === 1) {
        return
      } else {
        props.needEditMsg.supplierGeoList.splice(index, 1)
      }
    }
    const changeImage = (file, prop) => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      props.needEditMsg[prop] = ''
      let fileName = file.file.name + new Date()
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.needEditMsg[prop] = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }
    const changeBusinessPicsImage = file => {
      changeImage(file, 'businessPics')
    }
    const changeOpensAccountLicencePicsImage = file => {
      changeImage(file, 'opensAccountLicencePics')
    }
    const changeFoodBusinessLicensePicsImage = file => {
      changeImage(file, 'foodBusinessLicensePics')
    }
    const changeLiquorBusinessLicensePicsImage = file => {
      changeImage(file, 'liquorBusinessLicensePics')
    }
    const changeSpecialBusinessLicensePicsImage = file => {
      changeImage(file, 'specialBusinessLicensePics')
    }
    const changeOtherPicsImage = file => {
      changeImage(file, 'otherPics')
    }
    return {
      deleteRow,
      addRow,
      addRowSupplierGeo,
      deleteRowSupplierGeo,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      rules,
      elForm,
      changeCity,
      changePro,
      changeArea,
      changeImage,
      changeBusinessPicsImage,
      changeOpensAccountLicencePicsImage,
      changeFoodBusinessLicensePicsImage,
      changeLiquorBusinessLicensePicsImage,
      changeSpecialBusinessLicensePicsImage,
      changeOtherPicsImage,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 130px !important;
  }
}
::v-deep .el-cascader .el-input__inner,
::v-deep .el-cascader .el-input {
  height: 100% !important;
}
::v-deep .el-cascader__tags {
  height: 50%;
  overflow: hidden;
}
::v-deep .el-cascader--small {
  width: 200px;
}
.el-collapse-item {
  padding-left: 10px;
}
.form {
  .el-form-item {
    width: 70%;
  }
}

.shopsShow {
  display: flex;
  flex-wrap: wrap;
  div {
    width: 150px;
    line-height: 50px;
    text-align: center;
    margin-right: 10px;
    margin-top: 10px;
    border: 1px solid grey;
    border-radius: 5px;
  }
}
.endMsgTitle {
  text-align: center;
  span {
    color: black;
    font-weight: 500;
    font-size: 12px;
  }
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
