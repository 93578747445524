/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2022-04-14 15:12:30
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/router/route.js
 */

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/login',
    component: () => import('@/view/home/home'),
    children: [],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/view/login/login'),
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import('@/view/login/forgetPwd/index'),
  },
  {
    name: 'resetPassword',
    path: '/center/resetPassword',
    component: () => import('@/view/center/resetPassword.vue'),
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/view/error-page/404.vue'),
  },
  {
    name: '*',
    path: '/404',
    component: () => import('@/view/error-page/404.vue'),
  },
]

export default routes
