<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="存取酒明细" name="storageWineAuth" v-if="isShowReport('storageWineAuth')">
      <storage-wine-report :auths="authType.storageWineAuth" />
    </el-tab-pane>
    <el-tab-pane label="过期酒明细" name="storageWinePastAuth" v-if="isShowReport('storageWinePastAuth')">
      <storage-wine-past-report :auths="authType.storageWinePastAuth" />
    </el-tab-pane>
    <!-- <el-tab-pane label="结存酒明细" name="customerCardAuth" v-if="isShowReport('customerCardAuth')">
      <customer-card-report :auths="authType.customerCardAuth" />
    </el-tab-pane> -->
    <el-tab-pane label="存取酒统计" name="storageTakeWineAuth" v-if="isShowReport('storageTakeWineAuth')">
      <storage-take-wine-for-report :auths="authType.storageTakeWineAuth" />
    </el-tab-pane>
    <el-tab-pane label="预过期酒统计" name="preExpiredAuth" v-if="isShowReport('preExpiredAuth')">
      <pre-expired-report :auths="authType.preExpiredAuth" />
    </el-tab-pane>
    <el-tab-pane label="结存酒统计" name="balanceWineAuth" v-if="isShowReport('balanceWineAuth')">
      <balance-wine-report :auths="authType.balanceWineAuth" />
    </el-tab-pane>
  </el-tabs>
</template>

<script setup>
import storageWineReport from '../report_module/storageWineReport.vue'
import storageWinePastReport from '../report_module/storageWinePastReport.vue'
// import customerCardReport from '../report_module/customerCardReport.vue'
import storageTakeWineForReport from '../report_module/storage5TakeWineForReport.vue'
import preExpiredReport from '../report_module/preExpiredReport.vue'
import balanceWineReport from '../report_module/balanceWineReport.vue'
import { onBeforeMount, reactive, ref } from 'vue'
import { useRoute } from 'vue-router'

const activeName = ref()

const route = useRoute()
const authAllMenus = route.meta.msg.childrens
const authType = reactive({
  storageWineAuth: {}, // 存取酒明细
  storageWinePastAuth: {}, // 过期酒明细
  customerCardAuth: {}, // 会员卡消费明细
  storageTakeWineAuth: {}, // 存取酒统计
  preExpiredAuth: {}, // 预过期酒统计
  balanceWineAuth: {}, //结存酒统计
})

onBeforeMount(() => {
  //获取权限
  generateAuth()
  activeName.value = authAllMenus[0].url
})

/**
 * 分配每张报表的权限
 */
const generateAuth = () => {
  authType.storageWineAuth = authAllMenus.find(a => a.url === 'storageWineAuth') || { childrens: [] }
  authType.storageWinePastAuth = authAllMenus.find(a => a.url === 'storageWinePastAuth') || { childrens: [] }
  authType.customerCardAuth = authAllMenus.find(a => a.url === 'customerCardAuth') || { childrens: [] }
  authType.storageTakeWineAuth = authAllMenus.find(a => a.url === 'storageTakeWineAuth') || {
    childrens: [],
  }
  authType.preExpiredAuth = authAllMenus.find(a => a.url === 'preExpiredAuth') || { childrens: [] }
  authType.balanceWineAuth = authAllMenus.find(a => a.url === 'balanceWineAuth') || { childrens: [] }
}

/**
 * 报表是否展示
 * @param {string} type  报表名 'goodsSellAuth'
 * @return {boolean}
 */
const isShowReport = type => {
  return authType[type].childrens.length !== 0
}
</script>

<style lang="scss" scoped>
::v-deep(.el-table__body-wrapper) {
  height: auto !important;
}
::v-deep .el-select-v2__placeholder {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.el-tabs {
  padding: 0px 20px;
}
::v-deep .el-form-item__content {
  display: flex;
}
</style>
