<template>
  <div class="table-search-tab" style="height: 60px;line-height: 60px;" v-if="data.authMenus.add">
    <div class="search-right">
      <el-button type="primary" @click="addUnitConfig">
        新增
      </el-button>
    </div>
  </div>
  <el-table
    :data="data.tableData"
    style="width: 96%; margin-left: 2%;margin-top: 10px;"
    class="table-content"
    v-loading.fullscreen.lock="data.loading"
    element-loading-text="正在火速加载数据..."
  >
    <el-table-column label="序号" align="center" type="index"></el-table-column>
    <el-table-column prop="name" label="包厢类型" align="center" />
    <el-table-column prop="price" label="买钟基准价(元)" align="center" />
    <el-table-column prop="level" label="显示排序(数字小优先显示)" align="center" />
    <el-table-column prop="remarks" label="备注" align="center" />
    <el-table-column label="操作" align="center">
      <template #default="scope">
        <el-tag v-if="scope.row.isEnable === 1">启用</el-tag>
        <el-tag type="error" v-else>停用</el-tag>
      </template>
    </el-table-column>
    <el-table-column label="操作" width="200" align="center">
      <template #default="scope" fixed="right">
        <el-button type="text" @click="showDetail(1, scope.row)" v-if="data.authMenus.query">详情</el-button>
        <el-button type="text" @click="showDetail(2, scope.row)" v-if="data.authMenus.update">修改</el-button>
        <el-button type="text" @click="showDetail(3, scope.row)" v-if="data.authMenus.update">{{
          scope.row.isEnable === 1 ? '停用' : '启用'
        }}</el-button>
      </template>
    </el-table-column>
  </el-table>
  <AddRoom
    v-if="data.dialogVisible"
    :dialogVisible="data.dialogVisible"
    :unitConfig="data.unitConfig"
    :isEdit="data.isEdit"
    @update-dialogVisible="updateDialogVisible"
  ></AddRoom>
</template>

<script setup>
import _ from 'lodash'
import operationAuth from '@/lin/util/operationAuth.js'
import API from '../../service/api'
import { reactive, onMounted, computed } from 'vue'
import AddRoom from './room-add.vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import { useRoute } from 'vue-router'

const data = reactive({
  authMenus: {},
  tableData: [],
  dialogVisible: false,
  unitConfig: {},
  loading: false,
  isEdit: false,
})

const route = useRoute()

onMounted(() => {
  operationAuth(route, data.authMenus)
  queryLists()
})
const indexfn = computed(() => {
  return (data.currentPage - 1) * data.pageSize + 1
})

const queryLists = () => {
  data.loading = true
  if (data.authMenus.query) {
    API.getParentStoreRoomTypeList()
      .then(res => {
        if (res.code === '200') {
          data.loading = false
          data.tableData = res.data
        } else {
          ElMessage.error(res.msg)
        }
      })
      .finally(() => (data.loading = false))
  } else {
    ElMessage.warning('抱歉，你没有查看权限')
  }
}

//添加
const addUnitConfig = () => {
  data.unitConfig = {
    level: 0,
    isEnable: 1,
    price: 1,
  }
  data.isEdit = true
  data.dialogVisible = true
}

const editRow = rowMsg => {
  data.unitConfig = rowMsg
  data.dialogVisible = true
  data.isEdit = true
}
const showRow = rowMsg => {
  data.unitConfig = rowMsg
  data.dialogVisible = true
  data.isEdit = false
}

const startOrStop = rowMsg => {
  let params = {
    isEnable: rowMsg.isEnable === 1 ? 2 : 1,
    storeCode: rowMsg.storeCode,
    code: rowMsg.code,
  }
  API.storeRoomTypeUpdate(params).then(res => {
    if (res.code === '200') {
      ElMessage.success('更新成功')
      queryLists()
    } else {
      ElMessage.error(res.msg)
    }
  })
}
//操作数据
const showDetail = (index, rowMsg) => {
  rowMsg = _.cloneDeep(rowMsg)
  switch (index) {
    case 1:
      showRow(rowMsg)
      break
    case 2:
      editRow(rowMsg)
      break
    case 3:
      startOrStop(rowMsg)
      break
  }
}

const updateDialogVisible = ({ update, search }) => {
  data.dialogVisible = update
  if (search) {
    queryLists()
  }
}
</script>

<style lang="scss" scoped>
.table-search-tab {
  display: flex;
  border-bottom: 1px solid #ebeef5;
  margin-bottom: 20px;
  justify-content: flex-end;

  .search-right {
    margin-right: 20px;
    text-align: right;
    float: right;
  }

  .search-select {
    margin-left: 24px;
    margin-right: 20px;
  }

  span {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #000000;
  }

  .batch-btn {
    margin-right: 20px;
  }
}
.el-pagination {
  text-align: right;
  margin-top: 20px;
  margin-right: 20px;
}
</style>
