<template>
  <el-dialog title="批量下发" v-model="dialogVisible" width="70%" :before-close="handleClose">
    <div style="margin-bottom:10px">
      <p style="margin-bottom:5px">
        <font color="red">*</font>温馨提示：
        请尽量避免在门店营业时间操作该功能，尽量避免多人同时操作同一商品，以免造成数据异常
      </p>
      <p>
        <font color="red">*</font>修改范围：即将修改{{
          props.propData.total
        }}条数据，使用该功能前，请使用筛选条件确认要修改的数据范围；
      </p>
    </div>
    <el-form :model="updateRowMsg" :rules="rules" ref="formRef">
      <el-form-item label="下发现存门店" label-width="120px" prop="storeList">
        <FilterShop @changeStore="changeStore" :selectStoreData="updateRowMsg.storeList" />
      </el-form-item>
      <el-form-item label="下发将来门店">
        <el-radio-group v-model="updateRowMsg.isSales">
          <el-radio :label="1"
            >自动开启售卖
            <el-cascader
              ref="casRef"
              v-model="updateRowMsg.isSalesRegion"
              :options="areaOptions"
              clearable
              filterable
              @change="changeArea()"
              :show-all-levels="false"
              collapse-tags
              :props="areaProps"
            ></el-cascader>
          </el-radio>
          <el-radio :label="0">禁止开启售卖</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleCommit">
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import API from '../service/api'
import { ref, defineEmit, reactive, nextTick, onMounted, defineProps } from 'vue'
import FilterShop from './filter-shop.vue'
import { ElMessage, ElMessageBox } from 'element-plus'

const props = defineProps({
  propData: [],
  productType: '',
})

onMounted(() => {
  getAreas()
})

const updateRowMsg = reactive({
  storeList: [],
  regionCode: [],
  isSales: 1,
})

const areaOptions = ref([])
const areaProps = reactive({
  label: 'name',
  value: 'code',
  children: 'child',
  checkStrictly: true,
  multiple: true,
})
//获取区域数据
const getAreas = () => {
  API.getCheckArea().then(res => {
    areaOptions.value = res.data
  })
}
const casRef = ref()
const changeArea = () => {
  updateRowMsg.regionCode = casRef.value.getCheckedNodes().map(item => item.data.code)
}

/**
 * 改变门店
 * @param {*} data
 */
const changeStore = data => {
  updateRowMsg.storeList = data.data
  nextTick(() => {
    formRef.value.clearValidate('storeList')
  })
}
/**
 * 父容器传来的自定义事件
 */
const emit = defineEmit(['update-dialogVisible'])

const dialogVisible = ref(true)

/**
 * 关闭dialog弹出框
 */
const handleClose = () => {
  emit('update-dialogVisible', false)
}

const validateFixedStores = (rule, value, callback) => {
  if (updateRowMsg.storeList.length === 0) {
    return callback(new Error('请至少选择一家门店'))
  }
  callback()
}

const formRef = ref(null)
const rules = {
  storeList: [{ validator: validateFixedStores, trigger: 'change' }],
}

/**
 * 保存
 */
const handleCommit = async () => {
  try {
    await formRef.value.validate()
    let params = {
      ...updateRowMsg,
      isSalesRegion: updateRowMsg.regionCode,
      productList: props.propData.productList,
      productType: props.productType,
    }
    let res = await API.skuBatchInStore(params)

    if (res.code === '200') {
      ElMessage.success('操作成功')
    } else {
      ElMessage.error(res.msg)
    }
    handleClose()
  } catch (error) {
    console.log(error)
    ElMessage.warning('请完成必要信息填写')
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.remark {
  color: rosybrown;
}
.coupon-table {
  margin-top: 5px;
}
.el-input-number {
  width: 100%;
}
::v-deep(.el-input__inner) {
  text-align: left;
}
.timer-toast {
  margin-left: 5px;
  color: rosybrown;
}
.coupou-item {
  display: flex;
  border: 1px solid #ccc;
  margin: 10px;
  .left {
    display: flex;
    flex-direction: column;
    width: 100px;
    border-right: 1px solid #ccc;
    justify-content: center;
  }
  .right {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 10px;
  }
}

.el-pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
