<template>
  <el-form class="configurationForm" :model="formData" label-position="right" label-width="200px">
    <div class="main-wrap">
      <el-form-item label="上传删除库文件">
        <el-upload
          v-model:file-list="delFileList"
          :limit="1"
          action="#"
          :before-upload="beforeUpload"
          :http-request="uploadDelImg"
          :on-remove="handleDelRemove"
          :show-file-list="true"
        >
          <div class="avatar-uploader">
            <el-icon size="26" color="#817777" class="avatar-uploader-icon"><Plus /></el-icon>
          </div>
          <div class="el-upload__tip text-red">
            只能上传.txt文件
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="上传全量库文件">
        <el-upload
          v-model:file-list="fulFileList"
          :limit="1"
          action="#"
          :before-upload="beforeUpload"
          :http-request="uploadImg"
          :on-remove="handleFulRemove"
          :show-file-list="true"
        >
          <div class="avatar-uploader">
            <el-icon size="26" color="#817777" class="avatar-uploader-icon"><Plus /></el-icon>
          </div>
          <div class="el-upload__tip text-red">
            只能上传.txt文件
          </div>
        </el-upload>
      </el-form-item>
    </div>
    <div><el-button style="margin-left: 200px;" type="primary" @click="handleSave">保存文件</el-button></div>
  </el-form>
</template>

<script setup>
import { ElMessage } from 'element-plus'
import { ref, reactive, onMounted } from 'vue'
import { client, headers } from '@/lin/util/upload.js'
import API from '@/plugin/operation_manager/service/index.js'

const formData = reactive({
  orderCode: '',
  deleteFileUrl: '',
  fullFileUrl: '',
})
onMounted(() => {
  getLibrary()
})

let delFileList = ref([])
let fulFileList = ref([])
const getLibrary = async () => {
  const params = { orderCode: '' }
  const res = await API.getLibrary(params)
  if (res.code == '200') {
    formData.deleteFileUrl = res.data?.deleteFileUrl
    formData.fullFileUrl = res.data?.fullFileUrl

    delFileList.value = formData.deleteFileUrl
      ? [
          {
            name: formData.deleteFileUrl,
            url: formData.deleteFileUrl,
          },
        ]
      : []
    fulFileList.value = formData.fullFileUrl
      ? [
          {
            name: formData.fullFileUrl,
            url: formData.fullFileUrl,
          },
        ]
      : []
  }
}

const beforeUpload = rawFile => {
  if (rawFile.type !== 'text/plain') {
    ElMessage.error('上传文件格式不是.txt')
    return false
  }
  return true
}
// 删除列表
const handleDelRemove = () => {
  formData.deleteFileUrl = ''
  delFileList.value = []
}
// 删除全量列表
const handleFulRemove = () => {
  formData.fullFileUrl = ''
  fulFileList.value = []
}
const handleSave = async () => {
  const params = {
    ...formData,
    orderCode: '',
  }
  const res = await API.updateLibrary(params)
  if (res.code == '200') {
    // getLibrary()
    ElMessage.success('上传成功')
  }
}
// 覆盖文件上传
// const handleExceed = file => {
//   console.log(file[0],'file');
//   uploadIMgToClient(file[0], '1')
// }

const uploadDelImg = file => {
  if (checkImg(file)) {
    uploadIMgToClient(file, '1')
  }
}
const uploadImg = file => {
  if (checkImg(file)) {
    uploadIMgToClient(file, '2')
  }
}
const checkImg = file => {
  let type = file.file.type
  if (type !== 'text/plain') {
    ElMessage.info('上传文件格式不是.txt')
    return false
  }
  return true
}
const getTime = () => {
  const d = new Date()
  const year = d.getFullYear()
  const month = d.getMonth() + 1 < 10 ? '0' + (d.getMonth() + 1) : d.getMonth() + 1
  const day = d.getDate()
  const hour = d.getHours() < 10 ? '0' + d.getHours() : d.getHours()
  const minute = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()
  const second = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds()
  const time = `${year}${month}${day}${hour}${minute}${second}`
  return time
}

const uploadIMgToClient = (file, key) => {
  let fileName = key == '1' ? `HQ_delete_${getTime()}.txt` : `HQ_total_${getTime()}.txt`
  client()
    .put('/song/library/' + fileName, file.file, { headers })
    .then(res => {
      const url = res.res?.requestUrls[0]
      const urlResult = 'https://oss.szxjhui.com/song/library' + url.split('/song/library')[1]
      key == '1' ? (formData.deleteFileUrl = urlResult) : (formData.fullFileUrl = urlResult)
    })
}
</script>

<style lang="scss" scoped>
.main-wrap {
  // display: flex;
  // align-items: center;
  margin-top: 100px;
  margin-left: 60px;
}
.avatar-uploader {
  width: 160px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed #ccc;
}
.avatar-uploader-icon {
  width: 30px;
  height: 30px;
  margin-top: 10px;
}
.avatar {
  width: 120px;
  height: 120px;
}
::v-deep .el-upload-list {
  width: 500px;
}
</style>
