/*
 * @Author: your name
 * @Date: 2022-05-05 13:57:11
 * @LastEditTime: 2022-12-22 14:37:29
 * @LastEditors: luqunyan 710422911@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas/src/plugin/member_manager/service/index.js
 */
import _axios, { post, get, put } from '@/lin/plugin/axios'

class CsMng {
  //获取门店列表
  async getStoreListByName(data = {}) {
    if ('list' in data && data.list.length != 0) {
    } else {
      data.list = ['0']
    }
    data.isContainStop = 1
    const res = await post(`/adminChain/organizational/saas/getCheckStoreListByOrganizationalCodeList`, data)
    return res
  }
  async getParentStoreRoomTypeList() {
    return await get(`/adminChain/room/saas/getParentStoreRoomTypeList`)
  }
  async getPeopleSource(data) {
    return await post(`adminChain/user/saas/userSource/selectInfoPage`, data)
  }

  async getPayments() {
    return await get(`adminChain/user/saas/payment/list`)
  }

  async revenueAnalysisReport(data) {
    return await post(`/report/revenue/analysis/revenueAnalysisReport`, data)
  }
  //分页查询存酒明细报表
  async getReportStorageWineDetailsPages(data) {
    return await post(`/report/storageWine/getReportStorageWineDetailsPages`, data)
  }
  //分页查询过期存酒明细报表
  async getReportStorageWineExpiredPages(data) {
    const res = await post(`/report/storageWine/getReportStorageWineExpiredPages`, data)
    return res
  }
  //分页查询预过期存酒明细报表
  async getReportStorageWineTemporaryPages(data) {
    const res = await post(`/report/storageWine/getReportStorageWineTemporaryPages`, data)
    return res
  }
  //分页查询存酒统计报表
  async getStorage5TakeWineForReportForm(data) {
    const res = await post(`/report/storageWine/getStorage5TakeWineForReportForm`, data)
    return res
  }
  //分页查询结存酒统计报表
  async getReportStorageWineBalanceStatisticsPages(data) {
    const res = await post(`/report/storageWine/getReportStorageWineBalanceStatisticsPages`, data)
    return res
  }
  async getSelectList(data) {
    return await get(`/adminChain/goods/saas/statisticsType/selectList`, data)
  }
  //套餐收入明细
  async reportComboIncomeDetail(data) {
    const res = await post(`/report/reportComboIncomeDetail`, data)
    return res
  }
  //商品收入明细
  async reportGoodsIncomeDetail(data) {
    const res = await post(`/report/reportGoodsIncomeDetail`, data)
    return res
  }

  //套餐销售统计
  async reportComboSell(data) {
    const res = await post(`/report/reportComboSell`, data)
    return res
  }
  //商品销售统计
  async reportGoodsSell(data) {
    const res = await post(`/report/reportGoodsSell`, data)
    return res
  }

  //包厢收入明细
  async reportRoomIncomeDetail(data) {
    const res = await post(`/report/reportRoomIncomeDetail`, data)
    return res
  }

  //分页查询入库报表
  async getReportStorageBatchPages(data) {
    const res = await post(`/report/reportStorage/getReportStorageBatchPages`, data)
    return res
  }

  //分页查询充公报表
  async getReportStorageConfiscatePages(data) {
    const res = await post(`/report/reportStorage/getReportStorageConfiscatePages`, data)
    return res
  }

  //分页查询盘点报表
  async getReportStockCheckPages(data) {
    const res = await post(`/report/reportStorage/getReportStockCheckPages`, data)
    return res
  }

  //分页查询出库报表
  async getReportStorageTracePages(data) {
    const res = await post(`/report/reportStorage/getReportStorageTracePages`, data)
    return res
  }

  //账单明细
  async reportBillDetail(data) {
    const res = await post(`/report/reportBillDetail`, data)
    return res
  }
  //账单明细V2
  async reportBillDetailV2(data) {
    const res = await post(`/report/V2/reportBillDetail`, data)
    return res
  }
  //班次营业情况
  async banciReport(data) {
    const res = await post(`/report/banci/banciReport`, data)
    return res
  }
  //办卡储值明细
  async reportStoredCardDetail(data) {
    const res = await post(`/report/reportStoredCardDetail`, data)
    return res
  }
  //办卡储值统计
  async reportStoredCardStatistics(data) {
    const res = await post(`/report/reportStoredCardStatistics`, data)
    return res
  }

  //会员卡消费明细
  async reportCustomerCardDetail(data) {
    const res = await post(`/report/reportCustomerCardDetail`, data)
    return res
  }

  //会员卡消费统计
  async reportCustomerCardStatistics(data) {
    const res = await post(`/report/reportCustomerCardStatistics`, data)
    return res
  }

  //日支付账单
  async reportPaymentOrderDay(data) {
    const res = await post(`/report/stock/getOrderPayListPages`, data)
    return res
  }

  //月支付账单
  async getBillMonth(data) {
    const res = await post(`/report/stock/getBillMonth`, data)
    return res
  }

  //进销存统计
  async getMaterialStockTraceStatistics(data) {
    const res = await post(`/report/stock/getMaterialStockTraceStatistics`, data)
    return res
  }

  //进销存明细
  async getMaterialStockTracePages(data) {
    const res = await post(`/report/stock/getMaterialStockTracePages`, data)
    return res
  }

  //盘点明细 - new
  async queryStockGoodsCheckDetail(data) {
    const res = await post(`/report/stock/queryStockGoodsCheckDetail`, data)
    return res
  }

  //进销存明细 - new
  async queryPurchaseSaleStockDetail(data) {
    const res = await post(`/report/stock/queryPurchaseSaleStockDetail`, data)
    return res
  }

  //场次收入明细
  async reportOpenDetail(data) {
    const res = await post(`/report/reportOpenDetail/getList`, data)
    return res
  }

  //包厢类型
  async getStoreRoomTypeList(data) {
    const res = await get(`/store/storeRoomType/getStoreRoomTypeList`, data)
    return res
  }

  //总分店会员分账统计
  async reportTotalBranchMemberLedgerStatistic(data) {
    const res = await post(`/report/reportTotalBranchMemberLedgerStatistic`, data)
    return res
  }

  //实时库存
  async reprotStock(data) {
    const res = await post(`/report/stock/getRealTimeStockPages`, data)
    return res
  }

  //导出储值订单列表
  async exportOrderCardStored(data) {
    const res = await _axios({
      url: '/adminChain/order/exportOrderCardStored',
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }
  //退款
  async refundMoney(data) {
    const res = await post(`/adminChain/order/refundMoney`, data)
    return res
  }

  //储值支付流水
  async getStoredCardsPayTraceListPages(data) {
    const res = await post(`/report/stored/getStoredCardsPayTraceListPages`, data)
    return res
  }

  //总部端场次列表
  async getAdminChainOpenPages(data) {
    const res = await get(`/adminChain/order/getAdminChainOpenPages`, data)
    return res
  }

  //获取场次详情
  async getOrderOpenDetail(data) {
    const res = await get(`/adminChain/order/getOrderOpenDetail`, data)
    return res
  }

  //获取单个超市订单详情
  async getOrderGoodsDetail(data) {
    const res = await get(`/adminChain/order/getOrderGoodsDetail`, data)
    return res
  }

  //获取开台房时订单信息
  async getOrderRoomDetail(data) {
    const res = await get(`/adminChain/order/getOrderRoomDetail`, data)
    return res
  }
  //异常场次详情
  async getAbnormalPayDetail(data) {
    const res = await get(`/adminChain/order/getAbnormalPayDetail`, data)
    return res
  }
  //同意处理异常场次
  async agreeOperaTeAbnormalPayOPen(data) {
    const res = await post(`/adminChain/order/agreeOperaTeAbnormalPayOPen`, data)
    return res
  }

  //获取门店包厢首页信息
  async getStoreRoomHomePage(data) {
    const res = await get(`/adminChain/store/getStoreRoomHomePage`, data)
    return res
  }

  //传入包厢机顶盒roomMacKey生成包厢点歌二维码
  async getWechatOfficialAccountQrCode(data) {
    const res = await post(`/adminChain/store/getWechatOfficialAccountQrCode`, data)
    return res
  }

  //查询门店包厢类型
  async getStoreRoomTypeList(data) {
    const res = await get(`/adminChain/store/getStoreRoomTypeList`, data)
    return res
  }
  //查询门店包厢类型 不需要传参
  async getStoreRoomTypeList2(data) {
    const res = await get(`/store/storeRoomType/getStoreRoomTypeList2`, data)
    return res
  }

  //修改包厢信息
  async updateStoreRoom(data) {
    const res = await post(`/adminChain/store/updateStoreRoom`, data)
    return res
  }

  //导出包厢二维码
  async exportStoreRoomQR(data) {
    const res = await post(`/adminChain/store/exportStoreRoomQR`, data)
    return res
  }

  //添加门店包厢
  async addStoreRoom(data) {
    const res = await post(`/adminChain/store/addStoreRoom`, data)
    return res
  }

  //商品分类
  async getCategoryList(type) {
    const res = await get(`/adminChain/goods/category/list`, { type: type })
    return res
  }

  //查询会员等级列表
  async getLevelList(data) {
    const res = await get(`/adminChain/customer/level/list/saas`, data)
    return res
  }
  //操作类型的接口
  async channelList(data) {
    const res = await post(`/adminChain/goods/stock/channel/list`, data)
    return res
  }
  //查询所有支付方式
  async paymentList(data) {
    const res = await get(`/payment/list`, data)
    return res
  }
  //查询出库入库类型
  async stockList(type) {
    const res = await get(`/report/stock/channel/list`, { type: type })
    return res
  }
  //查询门店区域关联门店详情
  getRegionList(data) {
    return get('/adminChain/store/saas/storeRegionConfig/getRegionList', data)
  }

  //查询区域-》权限
  async getCheckArea() {
    let data = {
      isOnlyEnable: 1,
    }
    const res = await get(`/adminChain/organizational/saas/getCheckArea`, data)
    return res
  }

  async selectByType(data) {
    return await get(`/adminChain/goods/saas/category/selectByType`, data)
  }
  async exportReport({ url, data }) {
    const res = await _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //供应商采购明细
  async queryGoodsProcureInfoDetail(data) {
    const res = await post(`/report/stock/queryGoodsProcureInfoDetail`, data)
    return res
  }

  //供应商采购统计
  async queryGoodsProcureInfo(data) {
    const res = await post(`/report/stock/queryGoodsProcureInfo`, data)
    return res
  }

  //门店营业月报-查询
  async queryStoreOpeningMonthly(data) {
    const res = await post(`/report/storeMonthlyIndicator/queryStoreOpeningMonthly`, data)
    return res
  }

  //门店营业月报-编辑中-查询月指标
  async queryStoreMonthlyIndicator(data) {
    const res = await post(`/report/storeMonthlyIndicator/queryStoreMonthlyIndicator`, data)
    return res
  }

  //门店营业月报-编辑
  async updateStoreMonthlyIndicator(data) {
    const res = await post(`/report/storeMonthlyIndicator/updateStoreMonthlyIndicator`, data)
    return res
  }

  //门店营业月报-删除月度指标
  async deleteStoreMonthlyIndicator(data) {
    const res = await post(`/report/storeMonthlyIndicator/deleteStoreMonthlyIndicator`, data)
    return res
  }

  //门店营业月报-重新计算
  async recalculate(data) {
    const res = await post(`/report/storeMonthlyIndicator/recalculate`, data)
    return res
  }

  //门店营业月报-导出
  async exportStoreOpeningMonthly(data) {
    const res = await post(`/report/storeMonthlyIndicator/exportStoreOpeningMonthly`, data)
    return res
  }

  async exportReport({ url, data }) {
    const res = await _axios({
      url,
      method: 'POST',
      data,
      responseType: 'blob',
    })
    return res
  }

  //门店营业月报-导入绩效表格
  inputBatchStoreMonthlyIndicator(data) {
    return post('/report/storeMonthlyIndicator/inputBatchStoreMonthlyIndicator', data)
  }



  //门店营业日报-分页查询
  queryReportStoreOpeningDailyPaper(data) {
    return post('/report/storeDailyPaper/queryReportStoreOpeningDailyPaper', data)
  }
  //门店营业日报-编辑日权重
  updateStoreDailyWeight(data) {
    return post('/report/storeDailyPaper/updateStoreDailyWeight', data)
  }
  //门店营业日报-删除月度指标
  deleteStoreMonthlyIndicator(data) {
    return post('/report/storeMonthlyIndicator/deleteStoreMonthlyIndicator', data)
  }
  //门店营业日报-点击重新计算
  dayRecalculate(data) {
    return post('/report/storeDailyPaper/dayRecalculate', data)
  }


  // 消流水费明细
  pageList(data) {
    return post('/report/consumeSett/pageList', data)
  }

  //结算统计
  settStatisticsPageList(data) {
    return post('/report/settStatistics/pageList', data)
  }

  //储值流水结算明细
  storedDetail(data) {
    return post('/report/stored/detail/query', data)
  }
}

export default new CsMng()
