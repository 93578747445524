/*
 * @Author: your name
 * @Date: 2022-03-30 11:52:28
 * @LastEditTime: 2024-08-07 14:35:54
 * @LastEditors: 张维杰 15536847978@163.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /saas-pc/src/plugin/system_manager/stage-config.js
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '系统管理',
  type: 'folder',
  icon: 'iconfont icon-jiemiansheji',
  filePath: 'view/operation/',
  route: '/system_manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '赠品组管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/system_manager/gift-group-manager',
      filePath: 'plugin/system_manager/view/gift-group-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '用户管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/system_manager/user_manager',
      filePath: 'plugin/system_manager/view/user_manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '组织管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/system_manager/organize_manager',
      filePath: 'plugin/system_manager/view/organize_manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '角色管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/system_manager/role_manager',
      filePath: 'plugin/system_manager/view/role_manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '菜单管理',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/system_manager/menu_manager',
      filePath: 'plugin/system_manager/view/menu_manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
    {
      title: '操作日志',
      type: 'view',
      name: 'ImgsUploadDemo',
      route: '/system_manager/operation_log',
      filePath: 'plugin/system_manager/view/operation-log/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
    },
  ],
}

export default linCmsUiRouter
