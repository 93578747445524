<template>
  <el-dialog v-model="visible" title="菜单添加" width="40%" :before-close="handleClose">
    <hr style="margin-bottom: 20px;" />
    <el-form :model="menuMsg" label-width="120px" :rules="rules" ref="form">
      <el-form-item label="类型">
        <el-radio-group v-model="menuMsg.menuType">
          <el-radio label="P">平台</el-radio>
          <el-radio label="M">目录</el-radio>
          <el-radio label="C">菜单</el-radio>
          <el-radio label="F">按钮</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="名称" placeholder="请填写名称" prop="menuName">
        <el-input v-show="menuMsg.menuType !== 'F'" v-model="menuMsg.menuName" style="width:75%" />

        <el-select
          v-show="menuMsg.menuType === 'F'"
          v-model="menuMsg.menuName"
          placeholder="请选择按钮类型"
          style="width:75%"
          filterable
        >
          <el-option label="所有按钮" value="所有按钮" />
          <el-option label="详情" value="详情" />
          <el-option label="编辑" value="编辑" />
          <el-option label="删除" value="删除" />
          <el-option label="新增" value="新增" />
          <el-option label="导入" value="导入" />
          <el-option label="导出" value="导出" />
          <el-option label="重置密码" value="重置密码" />
          <el-option label="下载模版" value="下载模版" />
          <el-option label="批量下发" value="批量下发" />
          <el-option label="批量续存" value="批量续存" />
          <el-option label="退单" value="退单" />
          <el-option label="上架" value="上架" />
          <el-option label="下架" value="下架" />
          <el-option label="解绑" value="解绑" />
          <el-option label="设置主收银" value="设置主收银" />
          <el-option label="注册" value="注册" />
        </el-select>
      </el-form-item>
      <el-form-item label="父菜单" placeholder="请选择父菜单" prop="parentId">
        <el-cascader
          filterable
          v-model="menuMsg.parentId"
          :options="menus"
          :props="props"
          clearable
          ref="menusTree"
          style="width:75%"
        />
      </el-form-item>
      <el-form-item label="路由">
        <el-input v-model="menuMsg.url" placeholder="请填写路由,没有请不填或者填写#" style="width:75%" />
      </el-form-item>
      <el-form-item label="备注" placeholder="请填写备注">
        <el-input v-model="menuMsg.comment" style="width:75%" />
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="menuMsg.orderNum" />
      </el-form-item>
    </el-form>
    <div style="text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="handleClose" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import { reactive, defineComponent, computed, ref } from 'vue'
import API from '../../../service/api'
import _ from 'lodash'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    menuMsg: Object,
    menus: Object,
  },
  setup(props, ctx) {
    const data = reactive({
      props: {
        label: 'menuName',
        value: 'id',
        children: 'childrens',
        checkStrictly: true,
      },
      isRight: true,
    })
    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)
    const visible = computed(() => {
      return props.dialogVisible
    })
    const add = data => {
      API.saveMenuPage(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('添加成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const update = data => {
      API.updateMenuPage(data).then(res => {
        if (res.code === '200') {
          ElMessage.success('编辑成功')
          ctx.emit('update-dialogVisible', { update: false, search: true })
        } else {
          ElMessage.error(res.msg)
        }
      })
    }

    const form = ref(null)

    const onSubmit = () => {
      validForm(form)
    }

    const validForm = async form => {
      if (!form) return
      await form.value.validate((valid, fields) => {
        if (valid) {
          const params = _.cloneDeep(props.menuMsg)
          if (Array.isArray(params.parentId)) {
            params.parentId = params.parentId[params.parentId.length - 1]
          }
          if (Reflect.has(props.menuMsg, 'id')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }

    const menusTree = ref(null)

    const isParentMenu = (rule, value, callback) => {
      if (value) {
        let type = menusTree.value.getCheckedNodes()[0].data.menuType
        if (props.menuMsg.menuType === 'P' && type) {
          return callback(new Error('根目录默认不选'))
        }
        if (props.menuMsg.menuType === 'M' && type !== 'P') {
          return callback(new Error('请选择平台'))
        }
        // if (props.menuMsg.menuType === 'C' && type !== 'M') {
        //   return callback(new Error('请选择目录'))
        // }
        if (props.menuMsg.menuType === 'F' && type !== 'C') {
          return callback(new Error('请选择菜单'))
        }
        callback()
      } else {
        if (props.menuMsg.menuType === 'M') {
          return callback(new Error('请选择平台'))
        }
        if (props.menuMsg.menuType === 'C') {
          return callback(new Error('请选择目录'))
        }
        if (props.menuMsg.menuType === 'F') {
          return callback(new Error('请选择菜单'))
        }
        callback()
      }
    }

    const rules = reactive({
      menuName: [{ required: true, message: '请填写名称', trigger: 'change' }],
      parentId: [{ trigger: 'change', validator: isParentMenu }],
    })

    return {
      handleSubmit,
      menusTree,
      onSubmit,
      handleClose,
      visible,
      ...data,
      rules,
      form,
    }
  },
})
</script>
<style lang="scss" scoped>
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 100px !important;
  }
}
</style>
