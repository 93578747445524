<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-21 14:50:27
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-05-22 21:18:26
 * @FilePath: /saas/src/component/base/storeTree/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <el-tree
      :default-expand-all="false"
      style="margin-top:20px;"
      node-key="code"
      ref="mdtree"
      show-checkbox
      @check="handleCheck"
      :data="mddata"
      :props="defaultProps"
      :filter-node-method="filterNode"
      @node-click="handleNodeClick"
      @check-change="handleCheckChange"
    />
  </div>
</template>

<script>
import _axios, { post, get, put } from '@/lin/plugin/axios'
const mapTree = parent => {
  const levelParent = []
  parent.children.forEach(item => {
    if (item.level === '1' && item.children) {
      levelParent.push(item)
      item.children.forEach(child => {
        if (child.children) {
          child.children.forEach(thirdChild => {
            if (thirdChild && thirdChild.level === '3') {
              thirdChild.firstParent = item
            }
          })
        }
      })
    }
  })
}
const getMdtree = async () => {
  const res = await get(`store/listWithArea`, { showType: 2 })
  mapTree(res.data)
  return res
}
export default {
  name: 'mdtree',
  components: {},
  props: {
    mdChange: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      mddata: [],
      filterText: '',
      defaultProps: {
        children: 'children',
        label: 'label',
        code: 'code',
      },
      shopCodeList: [],
      allShopList: {},
      storeCodeListStr: [],
    }
  },
  mounted() {
    this.getmddata()
    console.log(123)
  },
  methods: {
    getmddata() {
      getMdtree().then(res => {
        if (res.code == '200') {
          this.mddata.push(res.data)
        }
      })
    },

    handleNodeClick(item) {},
    handleCheckChange(val) {},
    handleCheck(check) {
      this.shopCodeList = this.$refs.mdtree.getCheckedNodes(false, false)
      const selected = this.$refs.mdtree.getCheckedKeys()
      const listMap = {}
      const list = []
      this.shopCodeList.forEach(item => {
        if (item.level === '3' && item.firstParent) {
          if (!listMap[item.firstParent.label]) {
            listMap[item.firstParent.label] = []
          }
          listMap[item.firstParent.label].push(item)
          list.push(item.code)
        }
      })
      this.allShopList = listMap
      this.storeCodeListStr = list
      // this.mdChange(listMap)
      this.$emit('mdChange', listMap, list)
      console.log(list, listMap, selected, '000')
    },
    setCheckedKeys(keys) {
      this.$refs.mdtree.setCheckedKeys(keys)
    },
    filterNode(value, data) {
      console.log('value, data :>> ', value, data)
      if (!value) return true
      return data.label.indexOf(value) !== -1
    },
  },
}
</script>
