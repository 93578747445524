<template>
  <el-dialog
    v-model="visible"
    :title="specRowMsg.code ? (isEdit ? '修改易耗品' : '详情') : '新增易耗品'"
    width="70%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="specRowMsg"
      label-width="120px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="易耗品图片" prop="pic">
        <el-upload
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage"
        >
          <img v-if="specRowMsg.pic" :src="specRowMsg.pic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>

      <el-form-item label="易耗品名称" prop="name">
        <el-input v-model="specRowMsg.name" style="width:70%" placeholder="请填写易耗品名称" />
      </el-form-item>
      <el-form-item label="进货名称" prop="inName">
        <el-input v-model="specRowMsg.inName" style="width:70%" placeholder="请填写进货名称" />
      </el-form-item>
      <el-form-item label="规格">
        <el-input v-model="specRowMsg.spec" style="width:70%" placeholder="请填写规格" />
      </el-form-item>
      <el-form-item label="易耗品分类" prop="unit">
        <el-select v-model="specRowMsg.categoryCode1" placeholder="请选择易耗品分类" filterable>
          <el-option :label="item.name" :value="item.code" v-for="(item, index) in projectTypes" :key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="最小单位" prop="unit">
        <el-select v-model="specRowMsg.unit" placeholder="请选择最小单位" filterable>
          <el-option :label="item.name" :value="item.name" v-for="(item, index) in units" :key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="采购单位" prop="unitLay">
        <el-select v-model="specRowMsg.unitLay" placeholder="请选择采购单位" filterable>
          <el-option :label="item.name" :value="item.name" v-for="(item, index) in units" :key="index" />
        </el-select>
      </el-form-item>
      <el-form-item label="进销比(1:N)" prop="caseSpec">
        <el-input-number :min="1" v-model="specRowMsg.caseSpec" style="width:35%" placeholder="请输入N值,整数格式" />
      </el-form-item>
      <el-form-item label="下发现存门店" prop="storeCodeList">
        <FilterShop :selectStoreData="specRowMsg.storeCodeList" @changeStore="changeStore" />
      </el-form-item>
      <el-form-item label="下发将来门店" prop="isSales">
        <el-radio-group v-model="specRowMsg.isSales">
          <el-radio :label="1">开启同步售卖</el-radio>
          <el-radio :label="0">禁止同步售卖</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="供货状态" prop="isSupply">
        <el-radio-group v-model="specRowMsg.isSupply">
          <el-radio :label="1">正常供货</el-radio>
          <el-radio :label="0">停止供货(现有库存，售完为止)</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="text-align: center; margin-top: 10px;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import FilterShop from '../../component/filter-shop.vue'
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { reactive, defineComponent, computed, ref, onMounted, nextTick } from 'vue'
import API from '../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
    units: Array,
    projectTypes: Array,
  },
  components: {
    FilterShop,
  },
  setup(props, ctx) {
    const form = reactive({
      loadText: '正在火速加载数据...',
      img: [],
      searchShop: '',
      shopLists: [],
      geoCode: [],
      optionsProps: {
        label: 'name',
        children: 'child',
        value: 'code',
        checkStrictly: true,
        multiple: true,
      },
      loading: false,
      loading1: false,
    })
    const checked1 = ref(false)

    const visible = computed(() => {
      return props.dialogVisible
    })

    /**
     * 改变门店
     * @param {*} data
     */
    const changeStore = data => {
      props.specRowMsg.storeCodeList = data
      nextTick(() => {
        elForm.value.clearValidate('storeCodeList')
      })
    }
    const casRef = ref()

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const changeImage = file => {
      let size = file.file.size
      if (size > 100 * 1024 * 1024) {
        ElMessage.info('上传图片小于100M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img.push(file)

      props.specRowMsg.pic = ''
      let fileName = file.file.name + new Date()
      client()
        .put(fileName, file.file, { headers })
        .then(res => {
          props.specRowMsg.pic = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }

    const add = params => {
      API.saveSkuOne(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    const update = params => {
      API.updateSkuByCode(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const rules = reactive({
      pic: [{ required: true, message: '请上传易耗品图片', trigger: 'blur' }],
      name: [{ required: true, message: '请填写易耗品名称', trigger: 'blur' }],
      inName: [{ required: true, message: '请填写进货名称', trigger: 'blur' }],
      spec: [{ required: true, message: '请填易耗品品规格', trigger: 'blur' }],
      unit: [{ required: true, message: '请选择最小单位', trigger: 'blur' }],
      unitLay: [{ required: true, message: '请选择采购单位', trigger: 'blur' }],
      caseSpec: [{ required: true, message: '请填写进货比率', trigger: 'blur' }],
      storeCodeList: [{ required: true, message: '请选择下发现存门店', trigger: 'blur' }],
      categoryCode1: [{ required: true, message: '请选择易耗品分类', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let params = {
            ...props.specRowMsg,
            type: 4,
            isStock: props.specRowMsg.isStock ? 1 : 0,
            isMinConsume: props.specRowMsg.isMinConsume ? 1 : 0,
            isRecommend: props.specRowMsg.isRecommend ? 1 : 0,
            storeCodeList: [...document.querySelectorAll('.is-checked.my-checkbox')].map(
              item => item.children[0].children[1].value,
            ),
          }
          if (Reflect.has(props.specRowMsg, 'code')) {
            update(params)
          } else {
            add(params)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      checked1,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      changeImage,
      rules,
      elForm,
      casRef,
      handleSubmit,
      changeStore,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 130px !important;
  }
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
