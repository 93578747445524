/*
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-05-25 17:06:29
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2023-01-12 11:45:17
 * @FilePath: /saas/src/plugin/merchant-manager/stage-config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
const linCmsUiRouter = {
  route: null,
  name: null,
  title: '商品管理',
  type: 'folder',
  icon: 'iconfont icon-jingyingbanci',
  filePath: 'view/merchant/',
  route: '/merchant-manager',
  order: null,
  inNav: true,
  children: [
    {
      title: '空瓶商品管理',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/empty-bottle-manager',
      filePath: 'plugin/merchant-manager/view/empty-bottle-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },
    {
      title: '物料管理',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/materiel-manager',
      filePath: 'plugin/merchant-manager/view/materiel-manager/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },

    {
      title: '商品类型',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/product-types',
      filePath: 'plugin/merchant-manager/view/product-types/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '易耗品类型',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/perishableArticles-types',
      filePath: 'plugin/merchant-manager/view/perishableArticles-types/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '单品列表',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/product-list',
      filePath: 'plugin/merchant-manager/view/product-list/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: false,
      },
    },

    // {
    //   title: '组合列表',
    //   type: 'view',
    //   name: 'merchantmanager',
    //   route: '/merchant-manager/group-list',
    //   filePath: 'plugin/merchant-manager/view/group-list/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: true,
    //   },
    // },
    {
      title: '套餐列表',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/combo-list',
      filePath: 'plugin/merchant-manager/view/combo-list/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '易耗品列表',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/perishableArticles-list',
      filePath: 'plugin/merchant-manager/view/perishableArticles-list/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    {
      title: '单位配置',
      type: 'view',
      name: 'merchantmanager',
      route: '/merchant-manager/uint-config',
      filePath: 'plugin/merchant-manager/view/uint-config/index.vue',
      inNav: true,
      icon: 'iconfont icon-upload',
      permission: null,
      meta: {
        keepAlive: true,
      },
    },
    // {
    //   title: '出入库类型',
    //   type: 'view',
    //   name: 'merchantmanager',
    //   route: '/merchant-manager/inputOroutput-type',
    //   filePath: 'plugin/merchant-manager/view/inputOroutput-type/index.vue',
    //   inNav: true,
    //   icon: 'iconfont icon-upload',
    //   permission: null,
    //   meta: {
    //     keepAlive: false,
    //   },
    // },
  ],
}

export default linCmsUiRouter
