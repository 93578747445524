<template>
  <el-dialog
    v-model="visible"
    :title="specRowMsg.isExpired == 0 ? '门店系统缴费登记-首次缴费' : '门店系统缴费登记-续费'"
    width="50%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="specRowMsg"
      label-width="120px"
      ref="elForm"
      :rules="rules"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="缴费金额" prop="feeAmount" class="is-required">
        <el-input v-model="specRowMsg.feeAmount" style="width:70%" placeholder="请填写缴费金额" clearable />
      </el-form-item>
      <el-form-item label="上线时间" v-if="specRowMsg.isExpired == 0" prop="onlineTime">
        <el-date-picker
          v-model="specRowMsg.onlineTime"
          type="date"
          value-format="YYYY-MM-DD"
          clearable
          placeholder="选择日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="当前到期时间" v-else>
        {{ specRowMsg.expiredTime }}
      </el-form-item>
      <el-form-item label="续费后到期时间" prop="payExpiredTime">
        <el-date-picker
          v-model="specRowMsg.payExpiredTime"
          value-format="YYYY-MM-DD"
          clearable
          type="date"
          :disabled-date="disabledDate"
          placeholder="选择日期"
          :default-value="specRowMsg.defaultValue"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="附件" prop="voucherPic">
        <el-upload
          accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP,.pdf"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage1"
        >
          <img v-if="specRowMsg.voucherPic" :src="specRowMsg.voucherPic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
        <text>2M以内，支持jpg、png、pdf格式</text>
      </el-form-item>
    </el-form>

    <div style="text-align: center; margin-top: 10px;">
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../../lin/util/uploadImg'
import { reactive, defineComponent, computed, ref } from 'vue'
import API from '../../../service/api'
import { ElMessage, ElMessageBox } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: Boolean,
    specRowMsg: Object,
  },
  setup(props, ctx) {
    const form = reactive({
      loadText: '正在火速加载数据...',
      img1: [],
      loading: false,
    })

    const visible = computed(() => {
      return props.dialogVisible
    })

    const disabledDate = time => {
      var today
      if (props.specRowMsg.isExpired == 0) {
        // 创建一个新的Date对象，默认为当前日期和时间
        today = new Date(props.specRowMsg.onlineTime)

        // 使用getDate方法获取今天的日期
        var todayDate = today.getDate()

        // 使用setDate方法设置为明天的日期
        today.setDate(todayDate + 1)
      } else {
        // 创建一个新的Date对象，默认为当前日期和时间
        today = new Date(props.specRowMsg.expiredTime)

        // 使用getDate方法获取今天的日期
        var todayDate = today.getDate()

        // 使用setDate方法设置为明天的日期
        today.setDate(todayDate + 1)
      }
      return time.getTime() < today.getTime() - 8.64e7
    }

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const changeImage1 = file => {
      let size = file.file.size
      if (size > 2 * 1024 * 1024) {
        ElMessage.info('上传附件小于2M')
        return
      }
      form.loadText = '正在火速上传图片...'
      form.loading = true
      form.img1.push(file)
      props.specRowMsg.voucherPic = ''
      let filesSplit = file.file.name.split('.')
      let fileName =
        file.file.name.substring(0, file.file.name.length - filesSplit[filesSplit.length - 1].length - 1) +
        '_' +
        Date.now() +
        '.' +
        filesSplit[filesSplit.length - 1]
      client()
        .put('/subscription/' + fileName, file.file, { headers })
        .then(res => {
          props.specRowMsg.voucherPic = res.res.requestUrls[0]
        })
        .finally(() => {
          form.loading = false
        })
    }

    const update = params => {
      API.subscription(params)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
            form.loading = false
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const validateFrrAmount = (rule, value, callback) => {
      if (!value || !/^\d{1,6}?(\.\d{1,2})?$/.test(value)) {
        return callback(new Error('缴费金额必须为数字且最多保留两位小数'))
      } else {
        callback()
      }
    }

    const rules = reactive({
      feeAmount: [{ trigger: 'blur', validator: validateFrrAmount }],
      onlineTime: [{ required: true, message: '请选择上线时间', trigger: 'blur' }],
      payExpiredTime: [{ required: true, message: '请选择续费后到期时间', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let params = {
            storeCode: props.specRowMsg.code,
            feeAmount: props.specRowMsg.feeAmount,
            onlineTime: props.specRowMsg.onlineTime,
            payExpiredTime: props.specRowMsg.payExpiredTime,
            voucherPic: props.specRowMsg.voucherPic,
          }
          update(params)
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      handleSubmit,
      form,
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      changeImage1,
      rules,
      elForm,
      disabledDate,
    }
  },
})
</script>
<style lang="scss" scoped>
::v-deep .el-cascader {
  width: 280px;
  .el-tag--light {
    max-width: 150px;
  }
}
::v-deep .el-cascader__search-input {
  min-width: 20px !important;
}
.shopsShow {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
}
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
.el-form-item {
  ::v-deep .el-form-item__label {
    width: 150px !important;
  }
}
</style>
