<template>
  <div class="Xzztree">
    <!-- <div class="level_button">
      <el-tag type="success" style="background:#567DF4;border-color:#567DF4" @click="expandtree(1)">一级</el-tag>
      <el-tag type="info" style="background:#ED4D67;border-color:#ED4D67" @click="expandtree(2)">二级</el-tag>
       <el-tag type="warning" style="background:#34BFA3;border-color:#34BFA3"  @click="expandtree(3)">三级</el-tag>
       <el-tag type="danger" style="background:#FFBE4D;border-color:#FFBE4D"   @click="expandtree(4)">四级</el-tag>
    </div>
     -->
    <el-input :clearable="true" placeholder="输入关键字进行过滤" style="margin:10px 0px;" v-model="filterText">
    </el-input>
    <el-tree
      :data="zzdata"
      ref="tree"
      node-key="id"
      :filter-node-method="filterNode"
      :props="defaultProps"
      @node-drag-end="handleDragEnd"
      @node-drop="handleDrop"
      draggable
      :allow-drop="allowDrop"
      :allow-drag="allowDrag"
    >
      <template #default="{ node, data }">
        <span @click.stop="ViewMd(data)" class="custom-tree-node">
          <span>{{ node.label }}</span>
          <span class="controller">
            <i
              v-show="data.mark != 2"
              style="color: #87E293"
              @click.stop="AddNode(data)"
              class="iconfont el-icon-circle-plus-outline"
            ></i>
            <i
              v-show="data.mark != 2"
              @click.stop="editNode(data)"
              style="color:#577DF4"
              class="iconfont el-icon-edit"
            ></i>
            <i
              @click.stop="deleteNode(node, data)"
              v-show="!data.children?.length && data.mark != 2"
              style="color: #ED4D67"
              class="iconfont el-icon-delete"
            ></i>
          </span>
        </span>
      </template>
    </el-tree>
    <el-dialog :title="title" v-model="treeDialogVisible" width="30%">
      <el-input v-model="nodename" placeholder="请输入节点名称"></el-input>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="treeDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="saveNode">确 定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, ref, onMounted } from 'vue'
import { ElMessage } from 'element-plus'
import xjhzzmdModel from '../../model/xjgzzmd'

export default {
  name: 'XjhZztree',
  props: {
    elMenuCollapse: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    let filterText = ref('')
    let title = ref('新增节点')
    const zzdata = ref([])
    const currentNode = ref({})
    // let idArr = ref([1])
    const treeDialogVisible = ref(false)
    const nodename = ref('')
    const gettrees = async () => {
      const treedata = await xjhzzmdModel.getAllGeo()
      if (treedata.code == 200) {
        zzdata.value = treedata.result
      }
    }
    onMounted(() => {
      gettrees()
    })

    const defaultProps = ref({
      children: 'children',
      label: 'name',
    })
    return {
      filterText,
      defaultProps,
      currentNode,
      treeDialogVisible,
      nodename,
      zzdata,
      // idArr,
      title,
    }
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val)
    },
  },
  methods: {
    ViewMd(node) {
      this.$emit('ViewData', node)
    },
    async saveNode() {
      if (!this.nodename) {
        ElMessage.error('请输入节点名称')
        return
      } else {
        if (this.title == '新增节点') {
          const result = await xjhzzmdModel.addGeo({
            name: this.nodename,
            pid: this.currentNode.id,
            mark: 1,
          })
          if (result.code == 20000) {
            ElMessage.error(result.message)
            return
          } else {
            const newChild = result.result
            this.treeDialogVisible = false
            if (!this.currentNode.children) {
              this.currentNode.children = []
            }

            this.currentNode.children.push(newChild)
            this.zzdata = [...this.currentNode]
          }
        } else {
          const result = await xjhzzmdModel.updateGeo({
            name: this.nodename,
            id: this.currentNode.id,
          })
          if (result.code == 200) {
            this.treeDialogVisible = false
            this.currentNode.name = this.nodename
            this.zzdata = [...this.currentNode]
          }
        }
      }
    },
    async deleteNode(node, data) {
      this.$confirm('此操作将永久删除该节点, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const result = await xjhzzmdModel.deleteGeo({
            id: data.id,
          })
          if (result.code == 200) {
            this.remove(node, data)
            this.$message({
              type: 'success',
              message: '删除成功!',
            })
          }
        })
        .catch(() => {})
    },
    editNode(data) {
      this.title = '编辑节点'
      this.treeDialogVisible = true
      this.currentNode = data
      this.nodename = data.name
    },
    AddNode(data) {
      this.nodename = ''
      this.title = '新增节点'
      this.treeDialogVisible = true
      this.currentNode = data
    },

    remove(node, data) {
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
      this.zzdata = [...this.zzdata]
    },
    filterNode(value, data) {
      if (!value) return true
      return data.name.indexOf(value) !== -1
    },
    // setAllExpand(state){
    //     var nodes=this.$refs.tree.store.nodesMap
    //     for(var i in nodes){
    //         nodes[i].expanded=state;
    //     }
    // },
    // expandtree(level){
    //   this.setAllExpand(false);
    //   this.idArr=[];
    //   switch(level){
    //     case 1:
    //       this.idArr=[1];
    //       break;
    //     case 2:
    //       this.zzdata[0].children.map(item=>{
    //         this.idArr.push(item.id)
    //       })
    //       break;
    //     case 3:
    //       this.zzdata[0].children.map(lel1=>{
    //         lel1.children.map(lel=>{
    //             this.idArr.push(lel.id)
    //         })
    //       })
    //       break;
    //     case 4:
    //       this.zzdata[0].children.map(lel1=>{
    //         lel1.children.map(lel2=>{
    //           lel2.children.map(le3=>{
    //               this.idArr.push(le3.id)
    //           })
    //         })
    //       })
    //     break;
    //   }

    // },

    async handleDrop(draggingNode, dropNode, dropType, ev) {
      const result = await xjhzzmdModel.updateGeo({
        id: draggingNode.data.id,
        pid: dropNode.data.id,
      })
      if (result.code != 200) {
        ElMessage.error(result.message)
      } else {
        this.$message({
          type: 'success',
          message: '组织迁移成功!',
        })
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.mark == 2) {
        return false
      } else {
        return type == 'inner'
      }

      // return false;
    },
    allowDrag(draggingNode) {
      return true
    },
  },
}
</script>

<style lang="scss" scoped>
.Xzztree {
  width: 100%;
  height: calc(100vh - 120px) !important;
  padding: 10px;
  background: #fff;
  .el-tag {
    margin-right: 20px;
    cursor: pointer;
    border-radius: 2px;
  }
  .level_button {
    margin: 10px 0px;
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    .el-tag {
      flex: 1;
    }
  }

  .custom-tree-node {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
    width: 100%;
    .controller {
      width: 120px;
      text-align: right;
      display: flex;
      flex-wrap: nowrap;
    }
    .iconfont {
      display: inline-block;
      width: 30px;
    }
  }
}
</style>
