<!--
 * @Author: luqunyan 710422911@qq.com
 * @Date: 2022-08-10 11:30:11
 * @LastEditors: luqunyan 710422911@qq.com
 * @LastEditTime: 2022-12-14 16:01:51
 * @FilePath: /saas/src/plugin/supplyChain-manager/view/supplier-bill/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="supplier-bill-box">
    <searchTabVue :authMenus="authMenus" :getTableData="getTableData" :model="model" exportName="账单" />
    <div class="line"></div>
    <el-table :data="tableData" style="width: 100%" class="el-table-header-gap">
      <el-table-column prop="storeName" label="门店" width="180" />
      <el-table-column prop="supplierName" label="供应商" width="180" />
      <el-table-column prop="code" label="账单号" />
      <el-table-column prop="billType" label="支付方式">
        <template #default="scope">
          {{ scope.row.billType === 1 ? '现结' : '月结' }}
        </template>
      </el-table-column>
      <el-table-column prop="amount" label="账单总金额" />
      <el-table-column prop="state" label="账单状态">
        <template #default="scope">
          <span :class="'state' + scope.row.state">
            {{ stateList[scope.row.state] }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" />
      <el-table-column label="操作">
        <template #default="scope">
          <el-button type="text" @click="showDetail(scope.$index, scope.row)" v-if="authMenus.query">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-drawer v-model="drawer" :with-header="false" size="70%" :direction="direction" :before-close="handleClose">
      <div class="bill-content-box">
        <div class="content-left">
          <h1>
            账单号：{{ detailData.code }}
            <span :class="'state' + detailData.state" class="status-box">{{ stateList[detailData.state] }}</span>
          </h1>
          <div class="content-box-overflow">
            <div class="content-box">
              <h2>订单信息</h2>
              <div class="mb-10" v-for="(item, index) in orderList">
                <p class="order-title">
                  订单：{{ item.orderCode }}
                  <span>{{ item.createTime }}</span>
                </p>
                <p class="order-money">
                  <span>金额：{{ item.price }}元</span>
                  <span>运费：{{ item.freight }}元</span>
                  <span @click="openMore(item, index)" class="open-more"
                    >{{ item.isShow ? '收起' : '展开' }}
                    <img v-if="item.isShow" src="../../assets/image/down.png" class="down" alt="" />
                    <img v-else src="../../assets/image/up.png" class="down" alt="" />
                  </span>
                </p>
                <ul class="order-list" v-for="elItem in item.itemList" v-if="item.isShow">
                  <li>
                    <span>{{ elItem.skuName }}</span>
                    <span>¥{{ elItem.skuPrice }}/{{ elItem.unit }}</span>
                    <span>✖️{{ elItem.num }}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="total-money">共计：{{ orderMoneyTotal }}元</div>
            <div class="content-box">
              <h2>抵货款</h2>
              <div class="mb-10" v-for="(item, index) in emptyBottleList">
                <p class="order-title">
                  空瓶单：{{ item.orderCode }}
                  <span>{{ item.createTime }}</span>
                </p>
                <p class="order-money">
                  <span>金额：{{ item.price }}元</span>
                  <span style="color: #666666;">运费：{{ item.freight }}元</span>
                </p>
              </div>
            </div>
            <div class="sale-after" v-for="item in content.refundList">
              <p class="order-title">
                售后单：{{ item.orderCode }}
                <span>{{ item.createTime }}</span>
              </p>
              <p class="order-money">
                <span>金额：{{ item.price }}元</span>
              </p>
            </div>
            <div class="total-money">共计：{{ paymentAmount }}元</div>
            <div class="taxation-content">
              <h2>开票税费</h2>
              <div class="category-item" v-for="item in content.receiptList">
                <p class="category">
                  <span>{{ item.categoryCode1Name }}</span>
                  <span>{{ item.totalFee }}元</span>
                </p>
                <p class="money">
                  <span>税费：{{ item.taxation }}元</span>
                  <span>税率：{{ item.taxPoint }}%</span>
                </p>
              </div>
            </div>
            <div class="total-money">共计：{{ taxAmount }}元</div>
          </div>
        </div>
        <div class="content-right">
          <el-icon :size="20" color="#BFBFBF" class="close-icon" @click="drawer = false">
            <close />
          </el-icon>
          <div class="amount-in-total">
            合计账单金额：<span>{{ detailData.amount }}元</span>
          </div>
          <div class="certificate-img-content">
            <h2>发票凭证<span>（最多上传8张）</span></h2>
            <ul class="img-list">
              <li v-for="item in billAttach">
                <el-image class="el-image" :src="item" :preview-src-list="imgfilter(billAttach)" />
              </li>
            </ul>
          </div>
          <div class="certificate-img-content">
            <h2>上传凭证<span>（最多上传8张）</span></h2>
            <ul class="img-list">
              <li v-for="item in paymentAttach">
                <el-image class="el-image" :src="item" :preview-src-list="imgfilter(paymentAttach)" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </el-drawer>
    <Pagination :totalRecords="totalRecords" :totalPage="totalPage" :model="model" :getTableData="getTableData" />
  </div>
</template>

<script>
import operationAuth from '@/lin/util/operationAuth.js'
import { ElMessage } from 'element-plus'
import searchTabVue from '../../component/searchTab.vue'
import Pagination from '../../component/pagination.vue'
import Table from '../purchase-details/component/table.vue'
import api from '../../service/index'
export default {
  components: { searchTabVue, Table, Pagination },
  data() {
    return {
      authMenus: {},
      tableData: [],
      totalRecords: null,
      totalPage: null,
      model: {
        endTime: '',
        pageNum: 1,
        pageSize: 10,
        startTime: '',
        supplierCode: '',
        storeCodeList: [],
      },
      stateList: { 1: '待确定', 2: '待支付', 3: '已结单', 0: '已撤销' },
      drawer: false,
      openOrderList: false,
      payment: false,
      detailData: {},
      orderList: {},
      orderMoneyTotal: null,
      billAttach: [], //发票凭证
      paymentAttach: [],
      content: {},
      emptyBottleList: [], //空瓶但
      paymentAmount: null,
      taxAmount: null, // 税费
      allMoeny: null,
    }
  },
  mounted() {
    operationAuth(this.$route, this.authMenus)
    this.getTableData(this.model)
  },
  methods: {
    getTableData(model) {
      api.getBillListPages(model).then(res => {
        this.tableData = res.data.records
        this.totalRecords = res.data.totalRecords
        this.totalPage = res.data.totalPage
      })
    },
    showDetail(index, row) {
      this.drawer = true
      api.getBillDetail({ code: row.code }).then(res => {
        this.detailData = res.data
        let content = JSON.parse(res.data.content)
        console.log(content, 'content')
        this.content = content
        let orderList = content.orderList
        orderList &&
          orderList.map((item, index) => {
            orderList[index].isShow = false
          })
        this.orderMoneyTotal = orderList.reduce((pre, cur) => {
          return pre + cur.price + cur.freight
        }, 0)
        this.orderList = orderList
        let emptyBottleList = content.emptyBottleList
        emptyBottleList.map((item, index) => {
          orderList[index].isShow = false
        })
        let emptyMoney = emptyBottleList.reduce((pre, cur) => {
          return pre + cur.price + cur.freight
        }, 0)
        let refundListMoney = content.refundList.reduce((pre, cur) => {
          return pre + cur.price
        }, 0)
        this.taxAmount = content.receiptList.reduce((pre, cur) => {
          return pre + cur.taxation
        }, 0)
        this.paymentAmount = emptyMoney + refundListMoney
        this.emptyBottleList = emptyBottleList
        this.billAttach = res.data.billAttach && res.data.billAttach.split(',')
        this.paymentAttach = res.data.paymentAttach && res.data.paymentAttach.split(',')
      })
    },
    openMore(item, index) {
      item.isShow = !item.isShow || false
    },
    openMorePayment(item) {
      item.isShow = !item.isShow || false
    },
    imgfilter(imgobj) {
      let imgs = []
      imgobj.forEach(obj => {
        imgs.push(obj)
      })
      return imgs
    },
  },
}
</script>

<style lang="scss" scoped>
.supplier-bill-box {
  box-sizing: border-box;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .state0 {
    color: #acadb1;
  }

  .state1 {
    color: #f38900;
  }

  .state2 {
    color: #3a84ff;
  }

  .state3 {
    color: #5ac400;
  }

  .line {
    height: 1px;
    background-color: #ebeef5;
    margin: auto;
  }

  .bill-content-box {
    display: flex;

    .content-left,
    .content-right {
      box-sizing: border-box;
      padding: 48px 40px;
      flex: 1;
      position: relative;

      .close-icon {
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
      }

      .amount-in-total {
        height: 40px;
        line-height: 40px;
        padding-left: 24px;
        background: #ffffff;
        border-radius: 4px;
        color: #000000;
        font-size: 14px;

        span {
          color: #ff4242;
          font-size: 22px;
          font-weight: 600;
        }
      }

      .certificate-img-content {
        margin-top: 20px;

        h2 {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #222222;
          margin-bottom: 22px;

          span {
            color: #777777;
            font-size: 14px;
            font-weight: 400;
          }
        }
      }
    }

    .content-left {
      background-color: #fff;
      height: 100vh;
      box-sizing: border-box;
      padding: 0px 40px;

      .content-box-overflow {
        max-height: calc(100vh - 73px);
        overflow: auto;
      }

      h1 {
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        height: 73px;
        line-height: 73px;
        color: #000000;
        border-bottom: 1px solid #ebeef5;
        display: block;

        .state1 {
          background-color: rgba(243, 137, 0, 0.15);
          color: rgba(243, 137, 0, 1);
        }

        .state3 {
          background-color: rgba(58, 132, 255, 0.15);
          color: #3a84ff;
        }

        .state4 {
          background-color: rgba(90, 196, 0, 0.15);
          color: #5ac400;
        }

        .status-box {
          display: inline-block;
          float: right;
          width: 64px;
          margin-top: 20px;
          height: 32px;
          line-height: 32px;
          border-radius: 4px;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
      }

      .total-money {
        font-size: 14px;
        font-weight: 500;
        color: #303133;
        height: 40px;
        background: #f0f2f5;
        border-radius: 4px;
        text-align: right;
        line-height: 40px;
        padding-right: 20px;
        margin: 11px auto;
      }

      .sale-after {
        padding-bottom: 12px;
        border-bottom: 1px solid #ebeef5;

        .order-title {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          margin-bottom: 8px;
          margin-top: 12px;

          span {
            float: right;
            font-weight: 400;
            color: #777777;
          }
        }

        .order-money {
          span {
            display: inline-block;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-right: 22px;
            color: #666666 !important;
          }
        }
      }

      .taxation-content {
        h2 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .category-item {
          border-bottom: 1px solid #ebeef5;
          margin-bottom: 12px;

          .category {
            margin-bottom: 8px;

            span {
              font-size: 14px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #222222;
            }

            span:last-child {
              float: right;
            }
          }

          .money {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #777777;
            margin-bottom: 12px;

            span:last-child {
              float: right;
            }
          }
        }
      }

      .content-box {
        border-bottom: 1px solid #ebeef5;
        padding-bottom: 12px;
        .mb-10 {
          margin-bottom: 20px;
        }

        h2 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          margin-top: 20px;
          margin-bottom: 20px;
        }

        .order-title {
          font-size: 14px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #000000;
          margin-bottom: 8px;

          span {
            float: right;
            font-weight: 400;
            color: #777777;
          }
        }

        .order-money {
          span:not(:last-child) {
            display: inline-block;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            margin-right: 22px;
            color: #666666;
          }

          .open-more {
            text-align: center;
            float: right;
            display: inline-block;
            width: 64px;
            height: 24px;
            line-height: 24px;
            color: #1989fa;
            background-color: rgba(25, 137, 250, 0.15);
            border-radius: 4px;
            cursor: pointer;

            .down {
              display: inline-block;
              width: 12px;
              height: 8px;
            }
          }
        }

        .order-list {
          margin-top: 18px;

          li {
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;

            span:first-child {
              display: inline-block;
              width: 306px;
            }

            span:last-child {
              float: right;
            }
          }
        }
      }
    }

    .content-right {
      background-color: #f8faff;
      height: 100vh;

      .img-list {
        li {
          width: 100px;
          height: 100px;
          display: inline-block;
          border-radius: 8px;
          margin-right: 22px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
</style>
